<template>
  <div class="z-tree-container">
    <!-- 头部插槽 -->
    <slot name="top"></slot>
    <!-- 标题 -->
    <div v-if="treeName" class="z-tree-title">{{ treeName }}</div>
    <!-- 查询 -->
    <slot name="search">
      <div class="z-tree-search" v-if="placeholder || selectOption">
        <el-input v-model="searchObject.input" :placeholder="searchPlaceholder" clearable>
          <template #prepend v-if="selectOption">
            <el-select v-model="searchObject.select" style="width: 110px">
              <el-option v-for="item in selectOption" :key="item.value" :label="item.label" :value="item"></el-option>
            </el-select>
          </template>
          <template #append>
            <el-button icon="el-icon-search" @click="onSearchClicked"></el-button>
          </template>
        </el-input>
        <!-- <el-button icon="el-icon-search" @click="onSearchClicked"></el-button> -->
      </div>
    </slot>
    <el-button v-if="addRootText" size="small" icon="el-icon-plus" link type="text" @click.stop="treeNodeAddClicked">
      {{ addRootText }}
    </el-button>
    <el-tree ref="zTreeRef" highlight-current v-bind="$attrs">
      <!-- <el-tree ref="zTreeRef" highlight-current v-bind="$attrs" :style="{ height: height }"> -->
      <template #default="scope">
        <slot name="childrenNode" :data="scope.data" :node="scope.node">
          <span class="z-tree-node" @mouseenter="mouseenter(scope.data)" @mouseleave="mouseleave(scope.data)">
            {{ scope.node.label }}
            <span>
              <!-- 右侧图标插槽 -->
              <slot name="icon" :data="scope.data" :node="scope.node">
                <span v-if="scope.data?.permission">
                  <i v-if="addButton" v-show="(scope.node.isCurrent || scope.data.show) && scope.data?.permission?.create"
                    class="el-icon-plus" @click.stop="treeNodeAddClicked(scope.node, scope.data)"></i>
                  <i v-if="editIcon" v-show="(scope.node.isCurrent || scope.data.show) && scope.data?.permission?.update"
                    class="el-icon-edit" @click.stop="treeNodeEditClicked(scope.node, scope.data)"></i>
                  <i v-if="deleteIcon"
                    v-show="(scope.node.isCurrent || scope.data.show) && scope.data?.permission?.delete"
                    class="el-icon-delete" @click.stop="treeNodeDeleteClicked(scope.node, scope.data)"></i>
                </span>
                <span v-else>
                  <i v-if="addButton" v-show="scope.node.isCurrent || scope.data.show" class="el-icon-plus"
                    @click.stop="treeNodeAddClicked(scope.node, scope.data)"></i>
                  <i v-if="editIcon" v-show="scope.node.isCurrent || scope.data.show" class="el-icon-edit"
                    @click.stop="treeNodeEditClicked(scope.node, scope.data)"></i>
                  <i v-if="deleteIcon" v-show="scope.node.isCurrent || scope.data.show" class="el-icon-delete"
                    @click.stop="treeNodeDeleteClicked(scope.node, scope.data)"></i>
                </span>
              </slot>
              <i v-show="scope.node.isCurrent" class="el-icon-check"></i>
            </span>
          </span>
        </slot>
      </template>
    </el-tree>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, reactive, ref, watch } from 'vue'
import Node from 'element-plus/es/el-tree/src/model/node'
import { ElTree } from 'element-plus'

import { useI18n } from 'vue-i18n'
import { useLocale } from '../hooks/useLocale'

export interface TreeData {
  id: number
  key?: string
  label: string
  children?: TreeData[]
  show?: boolean
  permission?: Permission // 按钮权限
  [propName: string]: any
}
export interface Permission {
  create: boolean
  delete: boolean
  update: boolean
}

export interface SearchOptions {
  label: string
  value: string
  input?: string
}

export default defineComponent({
  props: {
    // 编辑图标
    editIcon: {
      type: Boolean,
      default: false
    },
    // 删除图标
    deleteIcon: {
      type: Boolean,
      default: false
    },
    // 增加按钮
    addButton: {
      type: Boolean,
      default: false
    },
    // 树组件名称
    treeName: {
      type: String
    },
    // 搜索框提示
    placeholder: {
      type: String
    },
    // 下拉输入框组合
    selectOption: {
      type: Array as PropType<Array<SearchOptions>>,
      default: () => {
        ;[]
      }
    },
    height: {
      type: String
    },
    addRootText: {
      type: String
    }
  },
  setup(props, { emit }) {
    const { t } = useLocale()
    const $t = t
    const zTreeRef = ref<InstanceType<typeof ElTree> | null>(null)
    const searchObject = reactive<any>({
      select: props.selectOption ? props.selectOption[0] : undefined,
      input: ''
    })
 
    const searchPlaceholder = computed(() => {
      if (props.selectOption) {
        return ($t('zws.ZCommon.pleaseEnter') || '请输入') + searchObject.select.label
      } else return props.placeholder
    })

    const onSearchClicked = () => {
      emit('searchClicked', {
        input: searchObject.input,
        select: searchObject.select && searchObject.select.value
      })
    }

    const mouseenter = (nodeData: TreeData) => {
      nodeData.show = true
      emit('treeNodeEnter', { nodeData: nodeData })
    }

    const mouseleave = (nodeData: TreeData) => {
      nodeData.show = false
      emit('treeNodeLeave', { nodeData: nodeData })
    }

    const treeNodeAddClicked = (treeNode?: Node, nodeData?: TreeData) => {
      emit('treeNodeAdd', { treeNode: treeNode, nodeData: nodeData })
    }

    const treeNodeEditClicked = (treeNode: Node, nodeData: TreeData) => {
      emit('treeNodeEdit', { treeNode: treeNode, nodeData: nodeData })
    }

    const treeNodeDeleteClicked = (treeNode: Node, nodeData: TreeData) => {
      emit('treeNodeDelete', { treeNode: treeNode, nodeData: nodeData })
    }

    /**
     * 移动树节点
     * @param nodeData 要移动的子节点的 data
     * @param key 父节点的 data, key 或 node
     */
    const treeNodeRemove = (nodeData: any, key: any) => {
      zTreeRef.value?.remove(nodeData)
      zTreeRef.value?.append(nodeData, key)
      zTreeRef.value?.setCurrentKey(nodeData, true)
    }

    watch(
      () => props.selectOption,
      (newValue) => {
        if (newValue) {
          const _activeOption = newValue.find((item) => item.input)
          if (_activeOption) {
            searchObject.select = _activeOption
            searchObject.input = _activeOption.input
            // emit("searchClicked", {
            //   input: searchObject.input,
            //   select: searchObject.select && searchObject.select.value,
            // })
          } else searchObject.select = newValue[0]
        }
      },
      {
        immediate: true
      }
    )

    return {
      zTreeRef,
      searchObject,
      searchPlaceholder,
      mouseenter,
      mouseleave,
      treeNodeAddClicked,
      treeNodeEditClicked,
      treeNodeDeleteClicked,
      onSearchClicked,
      treeNodeRemove
    }
  }
})
</script>
<style lang="less" scoped>
.z-tree-container {
  text-align: left;

  .z-tree-search {
    display: flex;
    padding: 0 6px;
    margin-bottom: 15px;

    :deep(.el-input-group--prepend .el-input__inner) {
      border-radius: 0;
    }

    :deep(.el-input-group__append) {
      padding-left: 0px;
    }

    .el-button {
      margin-left: 0px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .el-select {
      width: 110px;
    }
  }

  .z-tree-title {
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding-left: 16px;
    background: #f5f7fa;
    border: 1px solid #dadce0;
    border-radius: 3px;
  }

  .el-button {
    margin-left: 8px;
    font-size: 14px;
    min-height: 22px;
    padding-top: 8px;
    padding-bottom: 4px;
  }
}

.z-tree-title {
  height: 40px;
  line-height: 40px;
  text-align: left;
  padding-left: 24px;
  background: #f3f1f1;
}

.el-button {
  margin-left: 8px;
  font-size: 14px;
  min-height: 22px;
  padding-top: 8px;
  padding-bottom: 4px;
}

.z-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  i {
    padding: 0 3px;
    color: #80b33f;
  }

  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #80b33f;
    background-size: 4px 50px;
  }
}

.el-tree {
  overflow-y: auto;
  height: v-bind(height);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f3f1f1;
    background-size: 4px 50px;
  }
}
</style>
