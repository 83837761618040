/**
 * 设备数据 deviceData
 */
export default {
  realTimeData: {
    title: '实时数据',
    dataFormatter: '数据显示格式'
  },
  historyData: {
    title: '历史数据',
    inputMessageId: '请输入报文id',
    showDeleteProcess: '显示删除数据进度',
    quickDeleteTip: '该设备不支持快速删除功能，请前往{0}进行套餐升级。',
    usercenter: '用户中心',
    attention: '注意事项：',
    deleteTip: '历史数据删除为{0}操作，一旦输入用户密码确认后，不能终止，即使取消了，数据也将会继续清除，请谨慎操作！',
    cleanTip:
      '数据清空为{irreversible}操作，默认将该设备全部的{data}全部清空，一旦输入用户密码确认后，不能终止，即使取消了，数据也将会继续清除，请谨慎操作！',
    irreversible: '不可逆',
    data: '历史数据与DBC数据',
    deleteProcess: '删除历史数据进度',
    deleteingTip: '正在删除设备{ deletingProgressTip }从{ deletingProgressTipTime }的数据...',
    hide: '隐藏',
    error: '错误',
    maximumTip: '本次删除超过最大删除量的100万条，将默认删除该时间区间中前100万条数据，是否继续？',
    deleteDeviceErrorTip: '删除设备{tip}的历史数据任务出错，请重新下发删除历史数据任务。',
    deleteingOverTip:
      '正在删除设备{ deletingProgressTip }从{ deletingProgressTipTime }时间段的设备数据，请等待该删除进程完成后再创建新的删除数据任务。',
    cleanDataSuccess: '清空设备数据成功',
    cleanDataError: '清空设备数据失败',
    close: '关闭',
    showDeletingProcess: '显示正在删除进度',
    to: '到',
    channel: '通道',
    deleteDataError: '删除数据失败',
    deleteDataSuccess: '删除设备{0} {1} 到 {2}的历史数据成功',
    editSearchTime: '未检索到数据，请修改查询时间。',
    systemTime: '系统时间',
    timestamp: '时间戳',
    hexadecimal: '十六进制',
    decimalism: '十进制',
    binary: '二进制',
    center: '默认(居中对齐)',
    left: 'SJA1000(靠左对齐)',
    right: '真实ID(靠右对齐)',
    timeFormatter: '时间格式',
    messageIdFormatter: '报文ID格式',
    idJustified: '报文ID对齐',
    dataFormatter: '数据格式',
    messageChannel: '报文通道',
    messageId: '报文id',
    standard: '标准帧',
    expand: '扩展帧',
    dataFrame: '数据帧',
    externalFrame: '远程帧',
    saveSuccess: '保存成功'
  },
  deviceStorageData: {
    title: '设备存储数据',
    search: '查询',
    offlineTip: '设备状态不在线，请在设备列表刷新确认云端设备状态在线，再重试。',
    recall: '召回',
    batchRecall: '批量召回',
    recallProgress: '召回进度',
    can400OversizeTip:
      '你目前的设备固件仅支持60M的文件召回，如需召回更大文件，请前往设备管理页面，选择固件升级到V1.20以上版本并进行套餐升级。',
    can200OversizeTip:
      '你目前的设备固件仅支持60M的文件召回，如需召回更大文件，请前往设备管理页面，选择固件升级到V4.01以上版本并进行套餐升级。',
    oversizeRecallTip: '召回的文件已大于套餐限定的{ device_file_traffic_management } MB，请先到用户中心升级最大召回文件大小后再操作。',
    fileName: '文件名',
    size: '大小',
    date: '日期',
    commandSend: '命令发送',
    commandSending: '命令发送中',
    fileGet: '文件获取',
    timeoutCode1: '文件获取失败，请重试(code: 001)',
    timeoutCode2: '请求超时，请重试(code: 002)',
    timeoutCode4: '请求超时，请重试(code: 004)',
    timeoutCode5: '设备响应超时，请重试(code: 005)',
    overtimeAndRuntimeTip: '命令发送超时，请检查设备在线状态和运行状态后，再重试。',
    recallingTip: '设备正在召回文件，请等待该文件完成召回后再进行下次文件召回操作',
    commandSendError: '命令发送失败，请重试！',
    firmwareVersionError: '固件版本异常，获取文件失败',
    downloadFailedTip: '下载失败，请重试',
    startDownload: '开始下载',
    uploadFileOvertime: '上传文件超时',
    batchRecallNull: '请选择需要批量召回的文件',
    can400BatchRecall:
      '你目前的设备固件不支持批量文件召回，如需召回更大文件，请前往设备管理页面，选择固件升级到V1.20以上版本并进行套餐升级。',
    can200BatchRecall:
      '你目前的设备固件不支持批量文件召回，如需召回更大文件，请前往设备管理页面，选择固件升级到V4.01以上版本并进行套餐升级。',
    batchRecallToBuy: '已超过最大批量召回文件数{number}个，请前往用户中心进行套餐升级',
    fileGetting: '文件获取中...',
    batchRecallProcess: '文件获取进度（{recallFileUrlArray} / {selectionArray}）',
    batchRecallTip: '未支持批量召回，请前往{0}进行套餐升级。',
    batchRecallSingleTip: '第{number}个文件的召回进度 {singleDownloadProcess} %'
  }
}
