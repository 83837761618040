<template>
    <el-container>
        <el-header height="50px" style="padding:0px">
            <z-header-area v-bind="headerAttribute" :homePath="homePath"></z-header-area>
        </el-header>
        <el-container>
            <el-aside width="auto">
                    <z-aside-area>
                        <template #singelLevelMenu="singelLevelMenuProps">
                            <slot name="mySingelLevelMenu" :item="singelLevelMenuProps.item">
                                <span>{{ singelLevelMenuProps.item.title }}</span>
                            </slot>
                        </template>
                        <template #multilevelMenu="multilevelMenuProps">
                            <slot name="myMultilevelMenu" :item="multilevelMenuProps.item">
                                <span>{{ multilevelMenuProps.item.title }}</span>
                            </slot>
                        </template>
                        <template #subMenu="subMenuProps">
                            <slot name="mySubMenu" :v="subMenuProps.v">
                                <span>{{ subMenuProps.v.title }}</span>
                            </slot>
                        </template>
                    </z-aside-area>
            </el-aside>
            <el-container>
                <el-main class="container-main">
                    <router-view v-slot="{ Component }">
                        <keep-alive :include="includeList">
                            <component :is="Component" />
                        </keep-alive>
                    </router-view>
                </el-main>
                <el-footer height="40px">
                    <z-footer-area systemType="cloud" v-bind="footerAttribute"></z-footer-area>
                </el-footer>
            </el-container>
        </el-container>
    </el-container>
</template>
<script lang="ts">
import ZHeaderArea from './ZHeaderArea.vue'
import ZAsideArea from './ZAsideArea.vue'
import ZFooterArea from './ZFooterArea.vue'
import { defineComponent, watch, ref } from 'vue'
import type { PropType } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
    props: {
        homePath: {
            type: String,
            default: ''
        },
        headerAttribute: {
          type: Object as PropType<any>
        },
        footerAttribute: {
          type: Object as PropType<any>
        }
    },
    setup(props) {
        const route = useRoute();
        const includeList: any = ref([]);
        watch(() => route, (newVal) => {
            if (newVal.meta.keepAlive && !includeList.value.includes(newVal.name)) {
                includeList.value.push(newVal.name);
            }
        }, {
            immediate: true,
            deep: true,
        });
        const homePath = props.homePath
        return {
            includeList,
            homePath
        }
    },
    components: {
        ZHeaderArea,
        ZAsideArea,
        ZFooterArea,
    },
})
</script>
<style lang="less" scoped>
.el-footer {
    background-color: #f6f9fc;
    color: #333;
    text-align: center;
    vertical-align: baseline;
    line-height: 40px;
}

.el-aside {
    background-color: var(--aside-theme-background-color);
    color: #333;
    text-align: center;
}

.el-main {
    margin: 8px;
    padding: 0 !important;
    background-color: #ffffff;
    text-align: center;
}
</style>
