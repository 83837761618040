
export interface Body {
  headerHeight: number,
  footerHeight: number,
  remain: number,
  headerFooterChangedListeners?: Array<Handle>
}

export interface Handle {
  (body: Body): void;
}

let body: Body = {
  headerHeight: 0,
  footerHeight: 0,
  remain: 0
};

window.addEventListener("resize", () => {
  resize();
});

function registerSizeListener(handle: Handle) {
  if (!body.headerFooterChangedListeners) {
    body.headerFooterChangedListeners = [];
  }

  body.headerFooterChangedListeners.push(handle);
}

function notifyChanged() {
  let listeners = body.headerFooterChangedListeners;

  if (!listeners) {
    return;
  }

  for (let handle of listeners) {
    handle(body);
  }
}

function resize() {
  body.remain = window.innerHeight - body.headerHeight - body.footerHeight;
  notifyChanged();
}

function remain() {
  return body.remain;
}

function addHeader(h: number) {
  body.headerHeight += h;
  resize();
}

function delHeader(h: number) {
  body.headerHeight -= h;
  resize();
}

function addFooter(h: number) {
  body.footerHeight += h;
  resize();
}

function delFooter(h: number) {
  body.footerHeight -= h;
  resize();
}

function headerHeight() {
  return body.headerHeight;
}

function footerHeight() {
  return body.footerHeight;
}

export default {
  addHeader,
  delHeader,
  addFooter,
  delFooter,
  headerHeight,
  footerHeight,
  registerSizeListener,
  resize,
  remain
}
