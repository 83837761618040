<template>
  <ZAuthPageArea>
    <template v-slot>
      <ZRegisterStepsArea
        platform="CANDTU云数据平台"
        @loginTextClick="loginTextClick"
        @promptlyLogin="promptlyLogin"
        @registerProtocol="registerProtocol"
      >
      </ZRegisterStepsArea>
    </template>
  </ZAuthPageArea>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import ZRegisterStepsArea from '../../../vue_library/components/ZAuthArea/ZRegisterStepsArea/ZRegisterStepsArea.vue'

export default defineComponent({
  components: {
    ZRegisterStepsArea
  },
  setup() {
    const router = useRouter()
    const { locale } = useI18n()
    function loginTextClick() {
      router.push('/Login')
    }
    function promptlyLogin() {
      router.push('/Login')
    }
    function registerProtocol() {
      // let baseUrl = window.location.origin + window.location.pathname
      // baseUrl = baseUrl.substring(0, baseUrl.includes('index.html') ? baseUrl.indexOf('index.html') : baseUrl.length)
      // let url = baseUrl + 'static/register.html'
      // window.open(url, '_blank')
      const url =
        locale.value === 'en'
          ? 'https://zlgcloud-1312712610.cos.ap-guangzhou.myqcloud.com/PrivacyAgreement.html'
          : 'https://zlgcloud-1312712610.cos.ap-guangzhou.myqcloud.com/云平台服务和隐私协议.html'
      window.open(url, '_blank')
    }
    return {
      loginTextClick,
      promptlyLogin,
      registerProtocol
    }
  }
})
</script>
<style lang="less" scoped></style>
