<template>
    <div class="recall-down-process">
        <div class="recall-down-process-dlg">
            <el-dialog
                    :show-close="false"
                    :close-on-press-escape="false"
                    :close-on-click-modal="false"
                    :append-to-body="false"
                    :modelValue="props.isProcessVisible"
                    :title="props.processTitle || getLanguage('zws.ZRecall.recallProgress')" custom-class="download-progress"
                    style="padding:10px;"
                    :close="beforeClose"
            >
                <div>
                    <el-progress style="padding:10px;" :percentage="props.percentage"></el-progress>
                    <div style="margin-bottom: 20px;padding: 0 10px;">
                        <div style="max-height: 360px; overflow-y: auto;padding-bottom: 30px">
                            <div>{{(props.currentSize / 1048576).toFixed(2)}}M ({{getLanguage('zws.ZRecall.receivedSize') || '已接收文件大小'}}) / {{(props.totalSize / 1048576).toFixed(2)}}M ({{getLanguage('zws.ZRecall.fileSize') || '文件总大小'}})</div>
                        </div>
                    </div>
                    <div class="custom_footer">
                        <el-button v-if="props.abruptEnable" size_="mini" class="i-btn cance" @click="buttonCancel">
                            <i v-if="false" class="el-icon-cancel "></i>
                            {{props.processCloseButtonTxt || getLanguage('zws.ZButtonListCtrl.cancel')}}</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
        <!--<ZDialogCtrl title="提示" ref="recallProcessDialog" width="400px"-->
                     <!--@buttonClicked="recallProcessDlgBtnClicked"-->
                     <!--:show-close="false"-->
                     <!--:close-on-press-escape="false"-->
                     <!--:close-on-click-modal="false"-->
        <!--&gt;-->
            <!--{{props.processCloseTipTxt}}-->
        <!--</ZDialogCtrl>-->
        <ZDialogCtrl :title="getLanguage('zws.ZCommon.tips.tip') || '提示'" width="400px"
                     @buttonClicked="recallProcessDlgBtnClicked"
                     :show-close="false"
                     :close-on-press-escape="false"
                     :close-on-click-modal="false"
                     :show="recallProcessDialogVisible"
        >
            {{props.processCloseTipTxt || getLanguage('zws.ZRecall.sureClose')}}
        </ZDialogCtrl>
    </div>
</template>
<script setup lang="ts">
    import { ref, reactive, withDefaults, defineProps, defineEmits, defineExpose, watch } from "vue";
    import { useLocale } from '../../hooks/useLocale'
    interface Props {
        processTitle : string,
        processCloseButtonTxt : string,
        processCloseTipTxt : string
        abruptEnable : boolean,
        isProcessVisible : boolean,
        currentSize : number,
        totalSize : number,
        percentage: number,
    }
    interface RecallProcessInfo {
        opacity: number,
    }
    let lang = ref('')
    let { t } = useLocale()
    let getLanguage = function (data: any) {
        t = useLocale().t
        lang.value = useLocale().locale

        return t(data)
    }
    const props = withDefaults(defineProps<Props>(), {
        processTitle: '',
        processCloseButtonTxt: '',
        processCloseTipTxt: '',
        abruptEnable: false, // 是否可以中断
        isProcessVisible: false,
        currentSize: 0,
        totalSize: 0,
        percentage: 0,
    });
    const emits = defineEmits(['breakRecall', 'interruptRecall', 'continueRecall']);
    // let recallProcessDialog = ref<any>(null);
    let recallProcessDialogVisible = ref<boolean>(false);
    let recallProcessInfo: RecallProcessInfo = reactive({
        opacity: 1,
    });

    function buttonCancel() {
        recallProcessInfo.opacity = 0;
        // console.log(recallProcessDialog.value);
        // recallProcessDialog.value.show(true);
        recallProcessDialogVisible.value = true;
        emits('interruptRecall');
    }
    function beforeClose() {
        recallProcessInfo.opacity = 1;
    }
    function recallProcessDlgBtnClicked(ev: any) {
        recallProcessDialogVisible.value = false;
        if (ev.index == 1) {
            // 确定
            emits('breakRecall');
        } else {
            // 取消
            recallProcessInfo.opacity = 1;
            emits('continueRecall');
        }
    }

    watch(() => props.isProcessVisible, (newVal, oldVal) => {
        if (newVal) {
            recallProcessInfo.opacity = 1;
        }
    })
</script>
<style  lang="less" scoped>
    .recall-down-process {
        &-dlg {
            opacity: v-bind('recallProcessInfo.opacity');
        }
    }
    .custom_footer {
        text-align: center;
    }
</style>
