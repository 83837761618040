import ZWSBaseModule from '../httpBase/ZWSBaseModule'

export interface Group {
  id: number
  name: string
  info_model_id: number
  parent_id: number
  device_count: number
  has_child_node: true
}

export interface GetBatchGroupListResponse extends PaginationResponse {
  list: Group[]
  message: string
  result: boolean
}

interface PaginationResponse {
  pagination: Pagination
}

interface Pagination {
  current_page: number,
  page_size: number,
  total_size: number
}

/**
 * 软件分组
 */
class ZWSOutGroupMgmt extends ZWSBaseModule {


  /*******************************************************  device  *****************************************************/
  /**
   * 获取指定设备分组下已绑定的设备列表，包括其子分组下的绑定设备
   * @param {number} [tenant_id ] - 租户id
   * @param {number} [params.user_id] 用户id
   * @param {number} [group_id] - 设备分组id串，以英文逗号,分隔
   */
  static device_getBatchGroupBindDevs({ tenant_id, user_id, group_id, current_page, page_size = 100 }: { tenant_id: number, user_id: number, group_id: number, current_page: number, page_size: number }) {
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenant_id}/users/${user_id}/groups/${group_id}/devices`,
      method: 'get',
      params: { current_page, page_size }
    })
  }
  /**
   * 绑定设备到指定设备分组
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.user_id] 用户id
   * @param {number} [params.group_id] 设备分组id
   * @param {array} [params.ids] 设备id数组
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static device_addBatchDevsBindToGroup({ tenant_id, user_id, group_id, device_ids }: { tenant_id: number, user_id: number, group_id: number, device_ids: string }) {
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenant_id}/users/${user_id}/groups/${group_id}/bind`,
      method: 'put',
      data: device_ids
    }, "group:device:bind")
  }
  /**
   * 从指定设备分组解绑设备
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.user_id] 用户id
   * @param {number} [params.group_id] 设备分组id
   * @param {array} [params.devIds] 设备ID数组
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static device_deleteBatchDevsOfGroup({ tenant_id, user_id, group_id, devIds }: { tenant_id: number, user_id: number, group_id: number, devIds: string }) {
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenant_id}/users/${user_id}/groups/${group_id}/unbind`,
      method: 'put',
      data: devIds
    }, "group:device:unbind")
  }

  /**
   * 发送命令
   * @param param0 
   * @returns 
   */
  static device_sendGroupDeviceCommand({ tenant_id, user_id, group_id, description, payload }: { tenant_id: number, user_id: number, group_id: number, description: string, payload: string }) {
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenant_id}/users/${user_id}/groups/${group_id}/command`,
      method: 'post',
      data: { description, payload }
    }, "group:device:command")
  }


  /*******************************************************  group  *****************************************************/
  /**
   * 根据设备查询设备分组
   * @param param0 
   * @returns 
   */
  static group_getBatchGroupListByIds({ device_ids, tenant_id, user_id }: { device_ids: string, tenant_id: number, user_id: number }) {
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenant_id}/users/${user_id}/groups/device-ids/${device_ids}`,
      method: 'get'
    })
  }

  /**
   * 查询设备分组列表
   * @param {number} [tenant_id ] - 租户id
   * @param {number} [user_id] - 用户id
   * @param {number} [current_page] - 当前页码
   * @param {number} [info_model_id] - 设备类型id
   * @param {string} [name] - 设备分组名
   * @param {number} [page_size] - 每页记录数
   * @param {number} [parent_id] - 父节点id
   */
  static group_getBatchGroupList({ tenant_id, user_id, current_page, info_model_id, name, name_like, page_size, parent_id }: { tenant_id: number, user_id: number, current_page: number, info_model_id: number, name: string, name_like: string, page_size: number, parent_id: number }) {
    if (!page_size) {
      page_size = 100
    }
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenant_id}/users/${user_id}/groups`,
      method: 'get',
      params: { current_page, info_model_id, name, name_like, page_size: page_size, parent_id }
    }, 'group:group:query')
  }
  /**
   * 获取指定设备分组详情
   * @param {number} [tenant_id ] - 租户id
   * @param {number} [user_id] - 用户id
   * @param {string} [group_id] - 设备分组id串，以英文逗号,分隔
   */
  static group_getSingleGroupDetails({ tenant_id, user_id, group_id }: { tenant_id: number, user_id: number, group_id: number }) {
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenant_id}/users/${user_id}/groups/${group_id}`,
      method: 'get',
    })
  }
  /**
   * 根据指定的设备分组id串获取设备分组列表(批量设备id查询)
   * @param {number} [tenantId ] - 租户id
   * @param {number} [userId] - 用户id
   * @param {string} [groupIds] - 设备分组id串，以英文逗号,分隔
   */
  static group_getBatchGroupsByGroupIds({ tenantId, userId, groupIds }: { tenantId: number, userId: number, groupIds: number }) {
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenantId}/users/${userId}/groups/ids/${groupIds}`,
      method: 'get',
    })
  }
  /**
  * 创建设备分组
  * @param {string} [params.tenant_id] 租户id
  * @param {string} [params.user_id] 用户id
  * @param {string} [params.info_model_id] 设备类型id
  * @param {string} [params.name] 分组名
  * @param {string} [params.parent_id] 父节点id，首层节点时不传
  * @returns 
  */
  static group_addSingleGroup({ tenant_id, user_id, info_model_id, name, parent_id }: { tenant_id: number, user_id: number, info_model_id: number, name: string, parent_id?: number }) {
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenant_id}/users/${user_id}/groups`,
      method: 'post',
      data: { info_model_id, name, parent_id }
    }, 'group:group:create')
  }
  /**
   * 更新指定设备分组
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.user_id] 用户id
   * @param {number} [params.group_id] 设备分组id
   * @param {array} [params.name] 分组名
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static group_updateSingleGroup({ tenant_id, user_id, group_id, name }: { tenant_id: number, user_id: number, group_id: number, name: string }) {
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenant_id}/users/${user_id}/groups/${group_id}`,
      method: 'put',
      data: { name }
    }, 'group:group:update')
  }
  /**
   * 删除设备分组
   * @param {number} [tenant_id ] - 租户id
   * @param {number} [user_id] - 用户id
   * @param {string} [group_ids] - 设备分组id串，以英文逗号,分隔
   */
  static group_deleteBatchGroups({ tenant_id, user_id, group_ids }: { tenant_id: number, user_id: number, group_ids: string | Array<number> }) {
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenant_id}/users/${user_id}/groups/${group_ids}`,
      method: 'delete',
    }, 'group:group:delete')
  }


  /*******************************************************  groupManager  *****************************************************/
  /**
   * 解除指定设备分组下所有已挂载设备的绑定，该接口供租户管理员使用，子用户不可使用
   * @param {number} [tenant_id ] - 租户id
   * @param {number} [user_id] - 用户id
   * @param {string} [group_ids] - 设备分组id串，以英文逗号,分隔
   */
  static group_deleteAllDevsOfGroup({ tenant_id, user_id, group_id }: { tenant_id: number, user_id: number, group_id: number }) {
    return this.fetch({
      url: `/out-group-mgmt/tenants/${tenant_id}/users/${user_id}/manager/groups/${group_id}`,
      method: 'delete',
    })
  }
}

export default ZWSOutGroupMgmt
