import ZWSBaseModule from '../httpBase/ZWSBaseModule'

import { isFnBlockDataFieldIdentifierExistInFnBlocks } from './ZWSIotMappingManagerHelpers'
import ZWSTenant, { Label, GetLabelsResp, AddSingleLabelResp } from './ZWSTenant'
import { generateError } from '../../../exception/ZError'

import type { IotFunctionBlock } from './ZWSIotMappingManagerTypes'
import type { Pagination } from './ZWSPagination'
import type { ThingsNumber } from './ZWSThings'

export type { IotFunctionBlock } from './ZWSIotMappingManagerTypes'

export interface FnBlockField {
  identifier: string
  name: string
  description: string
}

export interface FnBlockEventFieldSet {
  name: string,
  event_type: string
  properties: Array<FnBlockField>,
}

export interface FnBlockOperationsField {
  name: string
  inputs: Array<FnBlockField>
}

export interface IotFnBlock {
  status?: {
    properties: Array<FnBlockField>
  },
  events?: Array<FnBlockEventFieldSet>,
  operations?: Array<FnBlockOperationsField>
}

export interface InfoModelFnBlock {
  id?: number,
  fn_block_id: number   // 功能块的id
  name: string
  description: string
  iot_function_block?: IotFunctionBlock,
  [propName: string]: any
}

export interface GetInfoModelListResp {
  list: Array<InfoModel>,
  [propName: string]: any
}

export interface ModelAttr {
  identifier?: string       // 属性标识
  funcBlockName?: string    // 所属功能块的名字
  required?: boolean        // 是否必须
  name?: string             // 名字
  description?: string      // 描述
  modified?: boolean        // 是否被修改
  type: string              // 类型
  val?: string              // 属性值
  [propName: string]: any   // 其他
}

export enum RuleItemPaddingType {
  None,
  Left,
  Right
}

export enum RuleItemLenEncoding {
  None,
  Big = 1,
  Little,
  ASCII
}

export enum TLVRuleItemValueLenEncoding {
  None,
  Big,
  Little,
}

// 0:default，默认是mappingRuleInfo的设置,。
// 9:影子结点，不参与解析只为为了取值。
// 10:动态节点，例如vs10lr中数据格式与后面数据的组合使用，数据格式的值作为后面数据项的 name。
export enum RuleItemFormat {
  Default = 0,
  Shadow = 9,
  Dynamic = 10,
}

export interface RuleItemBase {
  name: string                                          // 字段名
  name_expr_enable: boolean                             // name是否是表达式
  ignored: boolean                                      // 是否不赋值给物模型，对与物模型无关的数据进行处理
  description: string                                   // 描述
  length_key_name: string                               // 长度取值key，组包使用
  format: RuleItemFormat                                // 字段类型
  save_to_context: boolean                              // 计算出结果立即返回到上下文,会影响性能. 默认false
  wrap_value: string                                    // 默认值
  value_byte_order: RuleItemLenEncoding                 // 数值字节序

  condition_expr: string                                // 条件处理
  additional_process_expr: string                       // 额外处理
  loop_expr: string                                     // 循环处理

  value_mapping_rule_expr_enable: boolean               // 引用规则名称是否开启表达式模式
  value_mapping_rule_name: string                       // 关联规则
}

export interface FixedMappingRuleItem extends RuleItemBase {
  item_size: number                                     // 数据字节数，包含padding
  value_len_control_size_encoding: RuleItemLenEncoding  // 数据长度字节编码方式
  value_len_control_size_expr: string                   // 数据长度控制表达式处理

  padding: string                                       // 数据填充符
  padding_type: RuleItemPaddingType
}

export interface TLVMappingRuleItem extends RuleItemBase {
  tag_len_size: number                      // tag字段长度字节数
  tag_padding: string                       // tag填充符
  tag_padding_type: RuleItemPaddingType

  tag_value: string                         // tag标记值
  tag_value_process_expr: string            // tag标记值处理表达式

  value_len_size: number                            // value长度字节数
  value_len_encoding: TLVRuleItemValueLenEncoding   // value长度编码
  value_len_process_expr: string                    // 长度处理
}

export interface TVMappingRuleItem extends RuleItemBase {
  tag_len_size: number                      // tag字段长度字节数
  tag_padding: string                       // tag填充符
  tag_padding_type: RuleItemPaddingType

  tag_separator: string
  record_separator: string
}

export interface KVMappingRuleItem extends RuleItemBase {
  tag_len_size: number                      // tag字段长度字节数
  value_size: number                        // value字段长度字节数
}

export interface ScriptRuleItem {
  name: string
  type: number
  content: string
}

export enum RuleMappingType {
  parse = 1,
  build
}

export enum RuleFormat {
  fix = 1,
  delimiter,
  tlv,
  tv,
  JavaScript,
  DBC,
  kv,
  bit
}

export enum RuleObjectType {
  placeholder,
  entity,
  status,
  event,
  operation
}

export enum RuleTextEncoding {
  none,
  utf8,
  ascii,
  gb18030
}

export interface MappingRuleInfoBase {
  id: number
  mapping_rule_info_id: number
  mapping_type: RuleMappingType
  name: string
  text_encoding: RuleTextEncoding
  description?: string
  format: RuleFormat
  vorto_block_name: string
  vorto_object_id: number
  vorto_object_type: RuleObjectType
}

export interface MappingRuleInfo extends MappingRuleInfoBase {
  fixed_mapping_rule_items: Array<FixedMappingRuleItem>
  tlv_mapping_rule_items: Array<TLVMappingRuleItem>
  tv_mapping_rule_items: Array<TVMappingRuleItem>
  script_mapping_rule_item: ScriptRuleItem
  kv_mapping_rule_items: Array<KVMappingRuleItem>
  dbc_mapping_rule_items: Array<RuleItemBase>

  record_separator: string
  tag_len_size: number
  tag_padding: string
  tag_padding_type: RuleItemPaddingType
  tag_separator: string
  value_len_encoding: TLVRuleItemValueLenEncoding
  value_len_size: number
}

export interface MappingRuleInfoSimple {
  mapping_rule_info: MappingRuleInfo
  mapping_rule_info_id: number
}

export enum InfoModelMappingUnwrapProcessType {
  none,
  bcd,
  huffman,
  gzip,
  zip
}

export interface InfoModelMapping {
  unwrap_process_expr: string
  unwrap_process_type: InfoModelMappingUnwrapProcessType
  info_model_mapping_parse_rule_infos: Array<MappingRuleInfo>
  info_model_mapping_build_rule_infos: Array<MappingRuleInfo>
}

export interface InfoModelMappingSimple {
  unwrap_process_expr: string
  unwrap_process_type: InfoModelMappingUnwrapProcessType
  wrap_process_expr: string
  wrap_process_type: InfoModelMappingUnwrapProcessType
  info_model_mapping_parse_rule_infos: Array<MappingRuleInfoSimple>
  info_model_mapping_build_rule_infos: Array<MappingRuleInfoSimple>
}

export interface GetRulesResp {
  list: Array<MappingRuleInfo>
  pagination: Pagination
}

// 设备类型Response
export interface DevTypeSResp {
  list: DevType[]
  pagination: Pagination
}

// 标准设备类型
export interface DevType {
  attr: string // 默认属性配置 1000
  author_id: number
  category_id: number
  create_time: number
  description: string
  dif_fwup_args: string // 差分固件升级配置 255
  dif_fwup_support: boolean // 是否支持差分固件升级
  display_name: string
  fw_trans_args: string // 固件转换配置 255
  fw_trans_support: boolean // 是否需要固件包转换
  id: number
  message_trace_id_refer: string
  name: string
  tenant_id: number
  to_public: boolean
}

/************* DBC ***********************************************************/
export interface DBCFile {
  fn_block_id: number
  full_url: string // dbc文件可下载的url

  id: number
  info_model_id: number
  name: string
  url: string

  [propName: string]: any
}

export interface DBCConfigItem {
  name: string
  value_type: string
  [propName: string]: any
}

export interface DBCConfig {
  message_id: number
  message_name: string
  description: string
  pgn_id: number
  items: Array<DBCConfigItem>
  [propName: string]: any
}

export type GetSingleDBCFileResp = DBCFile

export interface GetDBCFileListResp {
  list: DBCFile[],
  pagination: Pagination
}

/************* Category ***********************************************************/

export interface Category {
  description: string,
  id: number,
  name: string,
  templates: string
}

/************* IotEntity ***********************************************************/

export enum EntityObjectType {
  Entity,
  Enum
}

export interface Entity {
  readonly id?: number
  name: string
  display_name: string
  description: string
  json_schema: string
  obj_type: EntityObjectType
  to_public: boolean
  tenant_id?: number
}

export interface GetEntitiesResp {
  list: Array<Entity>
  pagination: Pagination
}

export interface GetPublicEntitiesResp {
  list: Entity[]
  pagination: Pagination
}

export interface GetSingleEntityInfoResp extends Entity { }

/************* IotFunctionBlock ***********************************************************/

export interface GetSingleFunctionBlockResp extends IotFunctionBlock { }

export interface GetPublicFunctionBlocksResp {
  list: IotFunctionBlock[]
  pagination: Pagination
}

export interface GetFunctionBlocksResp {
  list: IotFunctionBlock[]
  pagination: Pagination
}

export interface GetFunctionBlocksByInfoModelIdResp extends Array<IotFunctionBlock> { }

/************* InfoModel ***********************************************************/

export interface InfoModel {
  attr?: string
  name: string
  id: number
  category_id: number
  create_time: number
  description: string
  display_name: string
  dif_fwup_support: boolean // 是否支持差分固件升级
  dif_fwup_args: string     // 差分固件升级配置
  fw_trans_support: boolean // 是否支持转换固件升级
  fw_trans_args: string     // 转换固件升级配置
  info_model_fn_blocks?: Array<InfoModelFnBlock>
  digital_twin_things_status: any
  enable_timeout?: number
  to_public?: boolean
  tenant_id?: number
  thing_type: number        // 设备类型,0 - 普通设备,1 - 网关设备
  [propName: string]: any
}

export interface DBCInfoModelInfo {
  dbc: string
  dbc_id: number
  signal_num: number
}

export interface AddSingleInfoModelResp extends InfoModel { }

export interface GetSingleInfoModelResp extends InfoModel { }

export interface GetPublicInfoModelsResp {
  list: InfoModel[]
  pagination: Pagination
}

export interface GetPrivateInfoModelsResp {
  list: InfoModel[]
  pagination: Pagination
}

export type GetSinglePrivateInfoModelResp = InfoModel

export interface GetSingleInfoModelPasswordResp {
  id: number // 物模型id
  pwd: string
}

export interface AddInfoModelDevtypeResp extends InfoModel { }

export interface GetInfoModelsWithCountResp {
  infoModelList: InfoModel[]
  total: number
}

export interface LabelInfoModel {
  id: number,
  label_id: number,
  info_model_id: number,
  label_value: string
  [propName: string]: any
}

export type GetInfoModelLabelsResp = LabelInfoModel[]

export type AddSingleFunctionBlockResp = IotFunctionBlock

export type IsFnBlockDataFieldIdentifierExistInInfoModelResp = undefined | { type: string, name: string }

export interface AddDevtypeResp {
  id: number
  [propName: string]: any
}

export type GetSingleInfoModelMappingResp = InfoModelMappingSimple

/**
 * 系统管理相关
 */
class ZWSIotMappingManager extends ZWSBaseModule {
  /************* Category ***********************************************************/

  // 获取设备大类列表
  static category_getCategoryList({ tenantId }: { tenantId: number }) {
    return this.fetch({
      url: '/mapping-mgmt/categories',
      method: 'get',
      params: { tenantId },
    })
  }

  /************* IotEntity ***********************************************************/

  // 添加实体
  static iotEntity_addSingleEntity({ tenantId, info }: { tenantId: number, info: any }) {

    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/entities`,
      method: 'post',
      data: info
    },"mapping:entity:create")
  }

  // 获取实体列表
  static iotEntity_getEntities({ tenantId, keyword = '', obj_type, current_page = 1, page_size = 100 }: { tenantId: number, keyword: string, obj_type: EntityObjectType, current_page: number, page_size: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/entities`,
      method: 'get',
      params: { keyword, obj_type, current_page, page_size }
    })
  }

  // 获取通用实体列表
  static iotEntity_getPublicEntities({ keyword = '', obj_type, current_page = 1, page_size = 100 }: { keyword: string, obj_type: EntityObjectType, current_page: number, page_size: number }) {
    return this.fetch({
      url: `/mapping-mgmt/entities/public`,
      method: 'get',
      params: { keyword, obj_type, current_page, page_size }
    })
  }

  // 获取单个实体列表信息
  static iotEntity_getSingleEntityInfo({ tenantId, entityId }: { tenantId: number, entityId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/entities/${entityId}`,
      method: 'get'
    })
  }

  // 更新单个实体列表信息
  static iotEntity_updateSingleEntity({ tenantId, entityId, info }: { tenantId: number, entityId: number, info: any }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/entities/${entityId}`,
      method: 'put',
      data: info
    },"mapping:entity:update")
  }

  // 删除单个实体列表
  static iotEntity_deleteSingleEntity({ tenantId, entityId }: { tenantId: number, entityId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/entities/${entityId}`,
      method: 'delete'
    },"mapping:entity:delete")
  }

  /************* IotFunctionBlock ***********************************************************/

  // 添加功能块
  static iotFunctionBlock_addSingleFunctionBlock({ tenantId, fnBlock }: { tenantId: number, fnBlock: IotFnBlock }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/function-blocks`,
      method: 'post',
      data: fnBlock
    }, "mapping:function-block:create")
  }

  // 获取标准功能块
  static iotFunctionBlock_getPublicFunctionBlocks({ current_page = 1, keyword = '', page_size = 10 }: { current_page: number, keyword: string, page_size: number }) {
    return this.fetch({
      url: `/mapping-mgmt/function-blocks/public`,
      method: 'get',
      params: { current_page, keyword, page_size }
    })
  }

  // 获取自定义功能块
  static iotFunctionBlock_getFunctionBlocks({ tenantId, current_page = 1, name, page_size = 20 }: { tenantId: number, current_page: number, name: string, page_size: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/function-blocks`,
      method: 'get',
      params: { current_page, name, page_size }
    })
  }

  // 根据设备类型ID获取对应功能块列表
  static iotFunctionBlock_getFunctionBlocksByInfoModelId({ tenantId, infoModelId }: { tenantId: number, infoModelId: number }) {
    let funcBlocks: IotFunctionBlock[] = []

    return ZWSIotMappingManager.iotInfoModel_getSingleInfoModel({ tenantId, infoModelId }).then((info: InfoModel) => {
      if (info?.info_model_fn_blocks?.length) {
        info.info_model_fn_blocks.forEach((fb: InfoModelFnBlock) => {
          if (fb.iot_function_block)
            funcBlocks.push(fb.iot_function_block)
        })
      }

      return funcBlocks
    })
  }
  // 获取功能块个性设置信息
  static iotFunctionBlock_getSingleFunctionBlockDisplaySchema({ tenantId, fnBlockId }: { tenantId: number, fnBlockId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/function-blocks/${fnBlockId}/json-schema-initialization?initialization_type=displayJsonSchema`,
      method: 'post'
    },'mapping:function-block:update')
  }
  // 获取功能块事件信息
  static iotFunctionBlock_getSingleFunctionBlockEvent({ tenantId, fnBlockId, event_name , event_type, schema_type, property_name, current_page, page_size}: { tenantId: number, fnBlockId: number, event_name: string, event_type: string, schema_type: string,  property_name: string, current_page: number, page_size: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/function-blocks/${fnBlockId}/event`,
      method: 'get',
      params: {event_name , event_type, schema_type, property_name, current_page, page_size}
    })
  }
  // 更新功能块事件信息
  static iotFunctionBlock_updateSingleFunctionBlockEventProperty({ tenantId, fnBlockId, event_name , event_type, schema_type, property_name,  display_json_schema, new_schema_json, origin_schema_json}: { tenantId: number, fnBlockId: number, event_name: string, event_type: string, schema_type: string,  property_name: string, current_page: number, page_size: number
    ,display_json_schema
    : 
    string
    ,new_schema_json
    : 
    string
    ,origin_schema_json
    : 
    string
  }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/function-blocks/${fnBlockId}/event/property?event_name=${event_name}&event_type=${event_type}&schema_type=${schema_type}`,
      method: 'put',
      data: { display_json_schema, new_schema_json, origin_schema_json}
    },'mapping:function-block:update')
  }

  // 更新功能块事件信息
  static iotFunctionBlock_updateSingleFunctionBlockEvent({ tenantId, fnBlockId, event_name , event_type, schema_type, property_name,  display_json_schema, new_schema_json, origin_schema_json}: { tenantId: number, fnBlockId: number, event_name: string, event_type: string, schema_type: string,  property_name: string, current_page: number, page_size: number
    ,display_json_schema
    : 
    string
    ,new_schema_json
    : 
    string
    ,origin_schema_json
    : 
    string
  }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/function-blocks/${fnBlockId}/event?&schema_type=${schema_type}`,
      method: 'put',
      data: { display_json_schema, new_schema_json, origin_schema_json}
    },'mapping:function-block:update')
  }
  // 获取功能块信息
  static iotFunctionBlock_getSingleFunctionBlock({ tenantId, fnBlockId, simplify_json_schema }: { tenantId: number, fnBlockId: number, simplify_json_schema?: boolean }) {
    
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/function-blocks/${fnBlockId}`,
      method: 'get',
      params: {simplify_json_schema}
    })
  }

  // 删除功能块
  static iotFunctionBlock_deleteSingleFunctionBlock({ tenantId, fnBlockId }: { tenantId: number, fnBlockId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/function-blocks/${fnBlockId}`,
      method: 'delete',
    },"mapping:function-block:delete")
  }

  // 更新功能块
  static iotFunctionBlock_updateSingleFunctionBlock({ tenantId, fnBlockId, fnBlock }: { tenantId: number, fnBlockId: number, fnBlock: IotFunctionBlock }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/function-blocks/${fnBlockId}`,
      method: 'put',
      data: fnBlock
    },"mapping:function-block:update")
  }

  /************* IotInfoModel ***********************************************************/

  // 获取设备模型
  static getInfoModelByID(infoModelId: number, loadRef: boolean = true) {
    return this.fetch({
      url: `/mapping-mgmt/info-models/${infoModelId}`,
      method: 'get',
      params: { load_ref: loadRef }
    })
  }

  // 获取设备模型状态属性
  static getInfoModelStatus(tenantId: number, infoModelId: number) {
    return window.ZWSApi.ZWSIotMappingManager.getInfoModel(tenantId, infoModelId)
      .then((inf: InfoModel) => {
        let statusList: Array<ModelAttr> = []

        let infoModelFnBlocks = inf.info_model_fn_blocks
        if (!infoModelFnBlocks) return

        infoModelFnBlocks.forEach((f) => {
          let iotFunctionBlock = f.iot_function_block
          if (!iotFunctionBlock) return

          let schema = iotFunctionBlock.json_schema
          if (!schema) return

          try {
            let propsInf = JSON.parse(schema)

            let status = propsInf.status
            if (!status) return

            let properties = status.properties;
            if (!Array.isArray(properties)) return

            statusList = statusList.concat(properties)
          } catch (err) {
            return
          }
        })

        return statusList
      })
  }

  // 获取标准设备类型列表
  static getPublicInfoModelList(keyword: string, pagination = { current_page: 1, page_size: 100 }) {
    return this.fetch({
      url: `/mapping-mgmt/info-models/public`,
      method: 'get',
      params: { keyword, current_page: pagination.current_page, page_size: pagination.page_size }
    })
  }

  // 获取自定义设备类型列表
  static getInfoModelList(tenantId: number, queryCondition: {
    key: string,
    keyword?: string,
    label_id?: number,
    label_value?: string
  } = { key: '' }, pagination = {
    current_page: 1,
    page_size: 100
  }) {
    // console.log('queryCondition: ', queryCondition);
    // debugger
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models`,
      method: 'get',
      params: { keyword: queryCondition.keyword, ...queryCondition, ...pagination }
    })
  }

  static getInfoModel(tenantId: number, modelId: number) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${modelId}`,
      method: 'get'
    })
  }

  static iotInfoModel_addTenantFunctionBlock(tenantId: number, modelId: number, info: any) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/public-to-tenant?infoModelId=` + modelId,
      method: 'post',
      data: info
    })
  }
  
  static infoModel_updateSingleInfoModel(tenantId: number, modelId: number, info: any) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${modelId}`,
      method: 'put',
      data: info
    })
  }
  
  static getInfoModelsByIDs(tenantId: number, modelIds: Array<number> | string) {
    if (Array.isArray(modelIds)) {
      modelIds = modelIds.toString()
    }

    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/ids/${modelIds}`,
      method: 'get'
    })
  }
  // 创建物模型映射
  static iotInfoModel_addInfoModelMappingWithItem({ tenantId, infoModelId, mapping }: { tenantId: number, infoModelId: number, mapping: any }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}/mapping-with-item`,
      method: 'post',
      data: mapping
    }, 'mapping:info-model-mapping:create')
  }

  // 更新物模型映射, 暂无'mapping:info-model-mapping:update'
  static iotInfoModel_updateSingleInfoModelMapping({ tenantId, infoModelId, mapping }: { tenantId: number, infoModelId: number, mapping: InfoModelMapping }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}/mapping`,
      method: 'post',
      data: mapping
    }, 'mapping:info-model-mapping:create')
  }

  // 获取物模型映射
  static iotInfoModel_getSingleInfoModelMapping({ tenantId, infoModelId, load_rule_items = 0 }: { tenantId: number, infoModelId: number, load_rule_items: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}/mapping`,
      method: 'get',
      params: { load_rule_items }
    }, 'mapping:info-model-mapping:query')
  }

  // 添加规则
  static iotInfoModel_addSingleRule({ tenantId, info }: { tenantId: number, info: any }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/mapping-rule-infos`,
      method: 'post',
      data: info
    },"mapping:mapping-rule:create")
  }
  // 添加规则
  static iotInfoModel_addBatchRules({ tenantId, info }: { tenantId: number, info: any }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/mapping-rule-infos/batch`,
      method: 'post',
      data: info
    },"mapping:mapping-rule:create")
  }

  // 获取单个规则详情
  static iotInfoModel_getSingleRule({ tenantId, ruleId }: { tenantId: number, ruleId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/mapping-rule-infos/${ruleId}`,
      method: 'get'
    },"mapping:mapping-rule:query")
  }

  // 根据规则名获取规则
  static getRuleByName({ tenantId, ruleName }: { tenantId: number, ruleName: string }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/mapping-rule-infos/name/${ruleName}`,
      method: 'get'
    },"mapping:mapping-rule:query")
  }

  // 获取规则列表
  static iotInfoModel_getRules({ tenantId, current_page = 1, page_size = 20 }: { tenantId: number, current_page: number, page_size: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/mapping-rule-infos`,
      method: 'get',
      params: { current_page, page_size }
    },"mapping:mapping-rule:query")
  }

  // 更新规则信息
  static iotInfoModel_updateSingleRule({ tenantId, ruleId, info }: { tenantId: number, ruleId: number, info: any }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/mapping-rule-infos/${ruleId}`,
      method: 'put',
      data: info
    },"mapping:mapping-rule:update")
  }

  // 删除规则
  static iotInfoModel_deleteSingleRule({ tenantId, ruleId }: { tenantId: number, ruleId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/mapping-rule-infos/${ruleId}`,
      method: 'delete'
    },"mapping:mapping-rule:delete")
  }

  // 更新设备类型信息
  static updateDevtpeInfo({ tenantId, infoModelId, info }: { tenantId: number, infoModelId: number, info: InfoModel }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}`,
      method: 'put',
      data: info
    })
  }

  // 获取用户下的单个类型
  static getDevtype({ tenantId, infoModelId }: { tenantId: number, infoModelId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}`,
      method: 'get'
    })
  }

  // 获取public设备类型
  static iotInfoModel_getPublicInfoModels({ keyword = '', current_page, page_size }: { keyword: string, current_page: number, page_size: number }) {
    return this.fetch({
      url: `/mapping-mgmt/info-models/public`,
      method: 'get',
      params: { keyword, current_page, page_size }
    })
  }

  // 获取private设备类型
  static iotInfoModel_getPrivateInfoModels({ tenantId, label_id, label_value, keyword, current_page = 1, page_size = 100 }: { tenantId: number, label_id?: number, label_value?: string, keyword?: string, current_page?: number, page_size?: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models`,
      method: 'get',
      params: { keyword, current_page, page_size, label_id, label_value }
    })
  }

  // 获取单个private设备类型
  static iotInfoModel_getSinglePrivateInfoModel({ tenantId, name }: { tenantId: number, name: string }) {
    return ZWSIotMappingManager.iotInfoModel_getPrivateInfoModels({ tenantId: tenantId, keyword: name }).then((res: GetPrivateInfoModelsResp) => {
      let list = res.list

      for (let infoModel of list) {
        if (infoModel.name == name) {
          return infoModel
        }
      }
    })
  }

  // 获取设备类型列表并统计设备数
  static iotInfoModel_getInfoModelsWithCount(tenantId: number, pageIndex: number, pageSize: number, toPublic: boolean, queryParams?: {
    keyword?: string
    label_id?: number
    label_value?: number
  }) {
    let infoModelList: InfoModel[] = []
    let total = 0

    return new Promise((resolve, reject) => {
      if (toPublic) {
        ZWSIotMappingManager.iotInfoModel_getPublicInfoModels({
          keyword: '',
          current_page: pageIndex,
          page_size: pageSize,
        }).then((res: GetPublicInfoModelsResp) => {
          resolve(res)
        }).catch(() => {
          reject()
        })
      } else {
        let _queryParams: any = {
          tenantId,
          current_page: pageIndex,
          page_size: pageSize,
        }

        Object.assign(_queryParams, queryParams)

        ZWSIotMappingManager.iotInfoModel_getPrivateInfoModels(_queryParams).then((res: GetPrivateInfoModelsResp) => {
          resolve(res)
        }).catch(() => {
          reject()
        })
      }
    }).then((res: any) => {
      infoModelList = res.list
      total = res.pagination.total_size

      let len = infoModelList.length
      for (let i = 0; i < len; ++i) {
        infoModelList[i].things_number = 0
      }
    }).then(() => {
      if (infoModelList.length <= 0) return Promise.resolve()

      return window.ZWSApi.ZWSThings.things_getBatchThingsNumByDevtype({
        tenantId,
        infoModelIds: infoModelList.map((info) => {
          return info.id
        }),
      }).catch(() => {
        return Promise.reject(generateError({ errorCode: 'iot-things.999991' }))
      })
    }).then((thingsNumList: ThingsNumber[]) => {
      if (infoModelList.length <= 0) return Promise.resolve({ infoModelList, total })

      let len = thingsNumList.length
      let len2 = infoModelList.length

      for (let i = 0; i < len; ++i) {
        for (let j = 0; j < len2; ++j) {
          if (infoModelList[j].id == thingsNumList[i].info_model_id) {
            infoModelList[j].things_number = thingsNumList[i].things_number
            break;
          }
        }
      }

      return { infoModelList, total }
    })
  }

  // 创建设备类型
  static iotInfoModel_addSingleInfoModel({ tenantId, name = '', display_name = '', description = '', category_id = "", thing_type = 0 }: { tenantId: number, name: string, display_name: string, description: string, category_id: string, thing_type: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models`,
      method: 'post',
      data: { name, display_name, description, category_id, thing_type }
    })
  }

  // 删除设备类型
  static iotInfoModel_deleteSingleInfoModel({ tenantId, infoModelId, isDelDBCRule = true }: { tenantId: number, infoModelId: number, isDelDBCRule: boolean }) {
    let delDBCRulePromise

    // 判断是否要同步删除关联的DBC规则，
    // 主要用于当删除一个由导入DBC生成的物模型被删除时，后端没有把与该物模型关联的DBC规则也一并删除的场景，
    // 如果不连带删除该DBC规则会导致DBC文件在该物模型删除后也无法删除的bug

    if (isDelDBCRule) {
      delDBCRulePromise = ZWSIotMappingManager.iotInfoModel_getSingleInfoModelMapping({ tenantId, infoModelId, load_rule_items: 1 }).then((res: GetSingleInfoModelMappingResp) => {
        if (!res) return

        for (let i = 0, infos = res?.info_model_mapping_parse_rule_infos, len = infos?.length; len !== undefined && i < len; ++i) {
          if (infos[i]?.mapping_rule_info?.dbc_mapping_rule_items?.length > 0) {
            return infos[i]?.mapping_rule_info.id
          }
        }
      }).catch(() => {
        return Promise.resolve()
      })
    } else {
      delDBCRulePromise = Promise.resolve()
    }

    return delDBCRulePromise.then((ruleId: number | undefined) => {
      return this.fetch({
        url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}`,
        method: 'delete',
      }).then((res: any) => {
        if (ruleId !== undefined) {
          return ZWSIotMappingManager.iotInfoModel_deleteSingleRule({ tenantId, ruleId })
        }

        return res
      })
    })
  }

  // 更新设备类型信息
  static iotInfoModel_updateSingleInfoModel({ tenantId, infoModelId, info }: { tenantId: number, infoModelId: number, info: InfoModel }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}`,
      method: 'put',
      data: info
    })
  }

  // 部分更新设备类型信息
  static iotInfoModel_updateSingleInfoModelSection({ tenantId, infoModelId, info }: { tenantId: number, infoModelId: number, info: InfoModel }) {
    return ZWSIotMappingManager.iotInfoModel_getSingleInfoModel({ tenantId, infoModelId }).then((_info: InfoModel) => {
      Object.assign(_info, info)
      return ZWSIotMappingManager.iotInfoModel_updateSingleInfoModel({ tenantId, infoModelId, info: _info })
    })
  }

  // 获取用户下的单个类型
  static iotInfoModel_getSingleInfoModel({ tenantId, infoModelId }: { tenantId: number, infoModelId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}`,
      method: 'get'
    })
  }

  // 判断数据字段名是否存在于与物模型关联的某个其他功能块上面
  static iotInfoModel_isFnBlockDataFieldIdentifierExistInInfoModel({ tenantId, infoModelId, fnBlock }: { tenantId: number, infoModelId: number, fnBlock: IotFunctionBlock }) {
    return ZWSIotMappingManager.iotInfoModel_getSingleInfoModel({ tenantId, infoModelId }).then((info: InfoModel) => {
      let fbBlocks = info.info_model_fn_blocks;

      if (!fbBlocks) {
        return false
      }

      return isFnBlockDataFieldIdentifierExistInFnBlocks(fnBlock, fbBlocks.filter((fb) => {
        return fb.fn_block_id != fnBlock.id
      }).map((fb) => {
        return fb?.iot_function_block as IotFunctionBlock
      }))
    })
  }

  // 更新设备类型关联的功能块
  // del 为 false 表示关联一个新的功能块；为 true 时表示取消与一个功能块的关联。
  static iotInfoModel_updateSingleInfoModelFnBlock({ tenantId, infoModelId, fnBlock, del }: { tenantId: number, infoModelId: number, fnBlock: IotFunctionBlock, del: boolean }) {
    return ZWSIotMappingManager.iotInfoModel_getSingleInfoModel({ tenantId, infoModelId }).then((info: InfoModel) => {
      !info.info_model_fn_blocks && (info.info_model_fn_blocks = [])

      let fbBlocks = info.info_model_fn_blocks;

      // 保持功能块信息结构与更新物模型所需功能结构一致
      if (fnBlock && fnBlock.fn_block_id === undefined) {
        fnBlock.fn_block_id = fnBlock.id
      }

      if (!del) {
        fbBlocks.push(fnBlock as InfoModelFnBlock)
      } else {
        let len = fbBlocks.length;
        for (let i = 0; i < len; ++i) {
          if (fbBlocks[i].fn_block_id === fnBlock.fn_block_id) {
            fbBlocks.splice(i, 1)
            break;
          }
        }
      }

      return ZWSIotMappingManager.iotInfoModel_updateSingleInfoModel({ tenantId, infoModelId, info })
    })
  }

  // 根据功能块描述创建设备类型（主要用于DBC导入后的设备类型创建）
  static iotInfoModel_addDevtype({
    tenantId,
    category_id,
    dbc_id,
    fn_block_description,
    fn_block_display_name,
    fn_block_name,
    fn_block_json_schema,
    info_model_description,
    info_model_display_name,
    info_model_name
  }: {
    tenantId: number,
    category_id: number,
    dbc_id: number,
    fn_block_description: string,
    fn_block_display_name: string,
    fn_block_name: string,
    fn_block_json_schema: string,
    info_model_description: string,
    info_model_display_name: string,
    info_model_name: string
  }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models-creating-for-all`,
      method: 'post',
      data: { category_id, dbc_id, fn_block_description, fn_block_name, fn_block_display_name, fn_block_json_schema, info_model_description, info_model_display_name, info_model_name }
    }, 'mapping:info-model:create')
  }

  // 更新由DBC导入生成的物模型
  static iotInfoModel_updateDevtype({
    tenantId,
    infoModelId,
    category_id,
    dbc_id,
    fn_block_id,
    fn_block_description,
    fn_block_display_name,
    fn_block_name,
    fn_block_json_schema,
    info_model_description,
    info_model_display_name,
    info_model_name
  }: {
    tenantId: number,
    infoModelId: number,
    category_id: number,
    dbc_id: number,
    fn_block_id: number,
    fn_block_description: string,
    fn_block_display_name: string,
    fn_block_name: string,
    fn_block_json_schema: string,
    info_model_description: string,
    info_model_display_name: string,
    info_model_name: string
  }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}/update-for-all`,
      method: 'put',
      data: { category_id, dbc_id, fn_block_id, fn_block_description, fn_block_name, fn_block_display_name, fn_block_json_schema, info_model_description, info_model_display_name, info_model_name }
    })
  }

  // 获取物模型密码
  static iotInfoModel_getSingleInfoModelPassword({ tenantId, infoModelId }: { tenantId: number, infoModelId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}/password`,
      method: 'get'
    })
  }

  // 获取物模型密码
  static iotInfoModel_updateSingleInfoModelPassword({ tenantId, infoModelId, pwd }: { tenantId: number, infoModelId: number, pwd: { pwd: string } }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}/password`,
      method: 'put',
      data: pwd
    })
  }

  /************* IotInfoModelLabel ***********************************************************/

  // 给设备类型添加标签
  static iotInfoModelLabel_addSingleInfoModelLabel({ tenantId, infoModelId, labelId, apiLabelData }: { tenantId: number, infoModelId: number, labelId: number, apiLabelData: { labelValue: string } }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}/labels/${labelId}/relation`,
      method: 'post',
      data: apiLabelData
    })
  }

  // 获取单个设备类型的标签
  static iotInfoModelLabel_addInfoModelLabels({ tenantId, infoModelId, labels }: { tenantId: number, infoModelId: number, labels: LabelInfoModel[] }) {
    let labelsAll: Label[] = []

    function ensureLabelNameExist(labels: LabelInfoModel[]) {
      return ZWSTenant.labels_getBatchLabels({ tenant_id: tenantId }).then((res: GetLabelsResp) => {
        let ps: any[] = []

        labelsAll = res.list

        labels.forEach((label1) => {
          if (label1.deleted) return

          if (labelsAll.some((label2) => {
            let result = label1.name == label2.label

            if (result) {
              label1.label_id = label2.id as number
            }

            return result
          })) return

          ps.push(ZWSTenant.labels_addSingleLabel({
            tenant_id: tenantId, label: {
              label: label1.name,
              description: label1.name,
            }
          }).then((res: AddSingleLabelResp) => {
            label1.label_id = res
          }))
        })

        return Promise.all(ps)
      })
    }

    function delLabels(labels: LabelInfoModel[]) {
      let ps: any[] = []

      labels.forEach((label) => {
        if (label.label_id < 0) return
        ps.push(ZWSIotMappingManager.iotInfoModelLabel_deleteSingleInfoModelLabel({ tenantId, infoModelId, labelId: label.label_id }))
      })

      return Promise.all(ps)
    }

    function addLabels(labels: LabelInfoModel[]) {
      let ps: any[] = []

      labels.forEach((label) => {
        if (label.deleted) return

        ps.push(ZWSIotMappingManager.iotInfoModelLabel_addSingleInfoModelLabel({
          tenantId, infoModelId, labelId: label.label_id, apiLabelData: {
            labelValue: label.label_value
          }
        }))
      })

      return Promise.all(ps)
    }

    return ensureLabelNameExist(labels).then(() => {
      delLabels(labels).then(() => {
        addLabels(labels)
      })
    })
  }

  // 获取单个设备类型的标签
  static iotInfoModelLabel_getInfoModelLabels({ tenantId, infoModelId }: { tenantId: number, infoModelId: number }) {
    let labelsAll: Label[] = []
    let labelsInfoModel: LabelInfoModel[] = []

    return ZWSTenant.labels_getBatchLabels({ tenant_id: tenantId }).then((res: GetLabelsResp) => {
      labelsAll = res.list

      return this.fetch({
        url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}/labels`,
        method: 'get'
      })
    }).then((res: GetInfoModelLabelsResp) => {
      labelsInfoModel = res

      function classify(labelsAll: Label[], labelsInfoModel: LabelInfoModel[]) {
        let labelsNotExist: LabelInfoModel[] = []
        let labelsExist: LabelInfoModel[] = []

        if (!labelsAll || !labelsInfoModel) return {
          labelsNotExist,
          labelsExist
        }

        let len = labelsInfoModel.length

        for (let i = 0; i < len; ++i) {
          let labelName = ''

          if (labelsAll.some((label) => {
            labelName = label.label

            return label.id === labelsInfoModel[i].label_id
          })) {
            labelsInfoModel[i].name = labelName
            labelsExist.push(labelsInfoModel[i])
          } else {
            labelsNotExist.push(labelsInfoModel[i])
          }
        }

        return {
          labelsNotExist,
          labelsExist
        }
      }

      let labels = classify(labelsAll, labelsInfoModel)

      function delNotExistLabel(labelsNotExist: LabelInfoModel[]) {
        if (!labelsNotExist) return

        labelsNotExist.forEach((label) => {
          ZWSIotMappingManager.iotInfoModelLabel_deleteSingleInfoModelLabel({ tenantId, infoModelId, labelId: label.label_id })
        })
      }

      delNotExistLabel(labels.labelsNotExist)

      return labels.labelsExist

    })
  }

  // 给设备类型删除标签
  static iotInfoModelLabel_deleteSingleInfoModelLabel({ tenantId, infoModelId, labelId }: { tenantId: number, infoModelId: number, labelId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/${infoModelId}/labels/${labelId}/relation`,
      method: 'delete'
    })
  }

  /************* DBC ***********************************************************/

  // 上传dbc文件
  static dbc_uploadSingleDBCFile({ tenant_id, file, protocol = 2 }: { tenant_id: number, file: any, protocol: number }) {
    let formData = new FormData()
    formData.append('upload_file', file, file.name)
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenant_id}/dbcs`,
      method: 'post',
      params: { name: file.name, protocol },
      data: formData
    })
  }

  // 上传dbc文件
  static dbc_updateSingleDBCFile({ tenantId, upload_file, dbcId, name }: { tenantId: number, upload_file: File, name: string, dbcId: number }) {
    let formData = new FormData()
    formData.append('upload_file', upload_file)
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/dbcs/${dbcId}`,
      method: 'post',
      params: { name },
      data: formData
    })
  }

  // 删除dbc文件
  static dbc_deleteSingleDBCFile({ tenantId, dbcId }: { tenantId: number, dbcId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/dbcs/${dbcId}`,
      method: 'delete',
    })
  }

  // 获取已经上传的一个dbc文件
  static dbc_getSingleDBCFile({ dbcId }: { dbcId: number }) {
    return this.fetch({
      url: `/mapping-mgmt/dbcs/${dbcId}`,
      method: 'get',
    })
  }

  // 获取已经上传的dbc文件列表
  static dbc_getDBCFileList({ tenantId, name = "", page_size = 100, current_page = 1 }: { tenantId: number, name: string, page_size: number, current_page: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/dbcs`,
      method: 'get',
      params: { name, page_size, current_page }
    })
  }

  // 获取dbc解析之后的配置信息
  static dbc_getMessageList({ tenant_id, dbc_id }: { tenant_id: number, dbc_id: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenant_id}/dbcs/${dbc_id}/dbc_message_configs`,
      method: 'get',
    })
  }
  // 获取dbc信息
  static dbc_getInfo({ dbc_id }: { dbc_id: number }) {
    return this.fetch({
      url: `/mapping-mgmt/dbcs/${dbc_id}`,
      method: 'get',
    })
  }
}

export default ZWSIotMappingManager
