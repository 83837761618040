import ZWSBaseModule from '../httpBase/ZWSBaseModule'


class ZWSJobMgmt extends ZWSBaseModule {
  /*******************************************************  handlerAction  *****************************************************/
  /**
   * 查询定时任务操作列表
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static handlerAction_getHandlerActions() {
    return this.fetch({
      url: `/job-mgmt/handler-action`,
      method: 'get',
    })
  }

  /*******************************************************  job  ***************************************************************/

  /**
   * 查询定时任务列表
   * @param {string} [params.tenantId] 租户id
   * @param {string} [params.page_size] 返回任务个数
   * @param {string} [params.current_page] 跳过多少页数据
   * @param {string} [params.name] 任务名称
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static job_getBatchJobs({ tenantId, page_size, current_page, name }: { tenantId: number, page_size: number, current_page: number, name: string }) {
    return this.fetch({
      // url: `job-mgmt/tenants/${tenantId}/jobs`,
      url: `job-mgmt/tenants/${tenantId}/jobs`,
      method: 'get',
      params: { page_size, current_page, name }
    }, 'job-mgmt:job:query')
  }
  /**
     * 创建定时任务
     * @param {number} [params.tenantId ] 租户id
     * @param {string} [params.cron_expression] 定时任务执行的cron表达式
     * @param {number} [params.end_time] 生效时间结束
     * @param {number} [params.execution_method] 执行方式,1云端执行;2,边缘计算; 修改job时不可修改
     * @param {number} [params.handler_action_id] 任务操作id
     * @param {string} [params.handler_custom_param] 接口请求自定义模板的参数
     * @param {string} [params.name] 任务名称
     * @param {number} [params.retry_period] 重试间隔
     * @param {number} [params.retry_times] 重试次数
     * @param {number} [params.start_time] 生效时间开始
     * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
     */
  static job_addJob({ tenantId, cron_expression, end_time, execution_method, handler_action_id, handler_custom_param, name, retry_period, retry_times, start_time }: { tenantId: number, cron_expression: string, custom_fields: string, end_time: number, handler_action_id: number, handler_custom_param: string, name: string, retry_period: number, retry_times: number, start_time: number, execution_method: string }) {
    return this.fetch({
      url: `job-mgmt/tenants/${tenantId}/jobs`,
      method: 'post',
      data: { cron_expression, end_time, execution_method, handler_action_id, handler_custom_param, name, retry_period, retry_times, start_time }
    }, 'job-mgmt:job:create')
  }
  /**
   * 定时任务更新
   * @param {number} [params.tenantId ] 租户id
   * @param {number} [params.jobId  ] 定时任务id
   * @param {string} [params.cron_expression] 定时任务执行的cron表达式
   * @param {number} [params.end_time] 生效时间结束
   * @param {number} [params.execution_method] 执行方式,1云端执行;2,边缘计算; 修改job时不可修改
   * @param {number} [params.handler_action_id] 任务操作id
   * @param {string} [params.handler_custom_param] 接口请求自定义模板的参数
   * @param {string} [params.name] 任务名称
   * @param {number} [params.retry_period] 重试间隔
   * @param {number} [params.retry_times] 重试次数
   * @param {number} [params.start_time] 生效时间开始
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static job_updateSingleJob({ tenantId, jobId, cron_expression, end_time, execution_method, handler_action_id, handler_custom_param, name, retry_period, retry_times, start_time }: { tenantId: number, jobId: number, cron_expression: string, end_time: number, execution_method: number, handler_action_id: number, handler_custom_param: string, name: string, retry_period: number, retry_times: number, start_time: number }) {
    return this.fetch({
      url: `/job-mgmt/tenants/${tenantId}/jobs/${jobId}`,
      method: 'put',
      data: { cron_expression, end_time, execution_method, handler_action_id, handler_custom_param, name, retry_period, retry_times, start_time }
    }, 'job-mgmt:job:edit')
  }

  /**
   * 定时任务的启动和禁用状态更新
   * @param {string} [params.tenantId] 租户id
   * @param {string} [params.jobId] 任务id
   * @param {string} [params.status] 更新状态
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static job_updateJobStatus({ tenantId, job_id, value }: { tenantId: number, job_id: number, value: number }) {
    return this.fetch({
      url: `/job-mgmt/tenants/${tenantId}/jobs/${job_id}/status`,
      method: 'put',
      data: { value }
    }, 'job-mgmt:job:edit-status')
  }
  /**
   * 查询定时任务详情
   * @param {string} [params.tenantId] 租户id
   * @param {string} [params.jobId] 任务id
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static job_getSingleJobDetails({ tenantId, jobId }: { tenantId: number, jobId: number }) {
    return this.fetch({
      url: `job-mgmt/tenants/${tenantId}/jobs/${jobId}`,
      method: 'get',
    }, 'job-mgmt:job:query')
  }
  /**
   * 定时任务的删除
   * @param {string} [params.tenantId] 租户id
   * @param {string} [params.jobId] 任务id
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static job_deleteSingleJob({ tenantId, job_id }: { tenantId: number, job_id: number }) {
    return this.fetch({
      url: `/job-mgmt/tenants/${tenantId}/jobs/${job_id}`,
      method: 'delete'
    }, 'job-mgmt:job:delete')
  }


  /*******************************************************  jobLog  ************************************************************/

  /**
   * 定时任务日志的获取
   * @param {number} [params.job_id] 定时任务id
   * @param {string} [params.job_name] 定时任务名称
   * @param {number} [params.page_size] 返回个数
   * @param {number} [params.current_page] 当前数据页数
   * @param {number} [params.start_time] 日志生成时间起
   * @param {number} [params.end_time] 日志生成时间止
   * @param {number} [params.tenantId 租户id
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static jobLog_getBatchJobLogs({ job_id, page_size, current_page, start_time, end_time, tenantId, job_name }: { job_id: number, page_size: number, current_page: number, start_time: number, end_time: number, tenantId: number, job_name: string }) {
    return this.fetch({
      url: `job-mgmt/tenants/${tenantId}/job-logs`,
      method: 'get',
      params: { job_id, page_size, current_page, start_time, end_time, job_name }
    }, 'job-mgmt:job-log:query')
  }
  /**
   * 定时任务的删除
   * @param {string} [params.job_id] 定时任务id
   * @param {string} [params.tenantId 租户id
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static jobLog_deleteSingleJobLog({ job_id, tenantId }: { job_id: number, tenantId: number }) {
    return this.fetch({
      url: `job-mgmt/tenants/${tenantId}/job/${job_id}/logs`,
      method: 'delete',
    }, 'job-mgmt:job-log:delete')
  }
  /**
   * 清空租户下所有日志
   * @param {string} [params.tenantId 租户id
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static jobLog_deleteAllLog({ tenantId }: { tenantId: number }) {
    return this.fetch({
      url: `/job-mgmt/tenants/${tenantId}/cleanup`,
      method: 'delete',
    }, 'job-mgmt:job-log:delete')
  }
  /*******************************************************  oauth2Test  ********************************************************/

}

export default ZWSJobMgmt