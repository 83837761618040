import { parseFnBlockDisplay } from '../api/http/httpModules/ZWSIotMappingManagerHelpers'

let mapObj: any = {};
let infoModelRes: any = null;
let _targetAttrName: any = null;

/* 公有类型 的字段 用display的i18n 覆盖初始i18n */

export function setDisplay(tableData: any, schema_type: any, e: any, displaySche: any, sort?: any) {
    var data = displaySche.dataDataFieldSetsDisplay || []
    if (e.event_type === 'status') {
        data = displaySche.statusFieldSetsDisplay || []

    } if (e.event_type === 'log') {
        data = displaySche.logDataFieldSetsDisplay || []

    }
    if (e.event_type === 'configuration' || sort === 'configuration') {
        data = displaySche.configurationSetsDisplay || []

    }
    if (sort === 'statusx') {
        data = displaySche.statusxSetsDisplay || []
    }

    if (sort === 'operations') {
        data = displaySche.operationsSetsDisplay || []
    }
    let arr: any = data.find((item: any, index_: any) => {

        if (item.name === e.name || sort === 'configuration' || sort === 'statusx' || sort === 'operations') {

            return true
        }

    }) || {}
    var target: any = []
    if (schema_type == 'operations') {
        target = arr.properties || []
    } else {
        target = arr.properties || []
    }
    arr = target.map((item: any) => {

        return item.identifier
    })

    tableData.value.forEach && tableData.value.forEach((fb: any, index: any) => {
        var idx = arr.indexOf(fb.identifier)
        if ((idx !== -1) && target[idx].i18n) {

            fb.i18n = target[idx].i18n
        }
    })
}

/** 递归 生成中文映射表【3】 */
function recursiveItemGetCh(propItem: any, ref_iot_entities: any, mapObj: any, prefixName: any) {
    if (!propItem || Object.prototype.toString.call(propItem) !== '[object Object]') return;

    let prop_item_ref_entity_id = propItem?.data_type?.specs?.ref_entity_id;

    // 无论是否有 ref_entity_id 都要给自己设置
    let newPrefixName = `${prefixName}-${propItem.identifier}`;
     
    let i18n = propItem?.i18n
    mapObj[newPrefixName] = {
        name_en: i18n?.en_US || i18n?.zh_CN ,//zh_CN紧跟着en_US,高于其它字段
        name_zh: i18n?.zh_CN,
        name: propItem.name,
        type: propItem?.data_type?.type,
        read_only: propItem?.read_only
    };

    if (prop_item_ref_entity_id > -1) {
        // 去 ref_iot_entities 查
        if (!Array.isArray(ref_iot_entities)) return;

        let ref_iot_entities_target = ref_iot_entities.filter(i => i.id == prop_item_ref_entity_id);
        if (ref_iot_entities_target.length === 0) return;

        let ref_iot_entities_target_json_schema = ref_iot_entities_target[0].json_schema;
        if (!ref_iot_entities_target_json_schema) return;

        ref_iot_entities_target_json_schema = JSON.parse(ref_iot_entities_target_json_schema);

        let status_properties = ref_iot_entities_target_json_schema?.properties;
        if (!status_properties || !Array.isArray(status_properties) || status_properties.length === 0) return;

        status_properties.forEach((propItem: any) => {
            recursiveItemGetCh(propItem, ref_iot_entities, mapObj, newPrefixName);
        })
    }
}

/** 把 infoModel 转为中文映射表【2】 */
function dealInfoModelResToMap() {
    if (!infoModelRes) return;

    let info_model_fn_blocks = infoModelRes.info_model_fn_blocks;
    let ref_iot_entities = infoModelRes.ref_iot_entities;
    if (!info_model_fn_blocks || !Array.isArray(info_model_fn_blocks) || info_model_fn_blocks.length === 0) return;

    info_model_fn_blocks.forEach((blockItem: any) => {
        let iot_function_block = blockItem?.iot_function_block;
        if (!iot_function_block) return;
        mapObj[iot_function_block.name] = iot_function_block.display_name;

        let iot_function_block_json_schema = blockItem?.iot_function_block?.json_schema;
        if (!iot_function_block_json_schema) return;

        iot_function_block_json_schema = JSON.parse(iot_function_block_json_schema);
        let status_properties = iot_function_block_json_schema?.[_targetAttrName]?.properties;
        if (!status_properties || !Array.isArray(status_properties) || status_properties.length === 0) return;
        if (blockItem?.iot_function_block?.display_json_schema && 'isOwner') {
            let displaySche = parseFnBlockDisplay(blockItem?.iot_function_block, {value:[]}, {value:[]}, {value:[]}, {value:[]}, {value:[]});
            setDisplay({value: status_properties || []},'configuration', iot_function_block_json_schema?.[_targetAttrName], displaySche, 'statusx')
        }
        status_properties.forEach((propItem: any) => {
            recursiveItemGetCh(propItem, ref_iot_entities, mapObj, blockItem.name);
        })
    });
}

/** 拿 属性模型，对 属性模型 进行 中文映射【1】 */
// 获取设备属性模型
async function getInfoModelRes(tenant_id: any, current_info_model_id: any) {
    // @ts-ignore
    infoModelRes = await window.ZWSApi.ZWSMappingMgmt.iotInfoModel_getSingleInfoModel({
        tenant_id: tenant_id,
        info_model_id: current_info_model_id,
    });
    // 将 模型 转换为 中文映射 表
    dealInfoModelResToMap();
}

export async function getInfoModelObj(tenant_id: any, current_info_model_id: any, targetAttrName: any = 'status') {
    _targetAttrName = targetAttrName;
    await getInfoModelRes(tenant_id, current_info_model_id);
    return [mapObj, infoModelRes];
}