/**
 * 首页 homePage
 */
export default {
  addDev: '添加设备',
  enterSearchTips: '请输入搜索内容',
  functionRecommendation: '特色功能推荐',
  accessDevice: '接入设备',
  online: '在线',
  offline: '离线',
  collect: '收藏',
  isOnline: '是否在线',
  sdStatus: 'SD卡状态',
  firmware: '固件版本',
  server: '连接服务器',
  channelEnable: '通道使能',
  deviceIdTips: '请输入20位大写的十六进制字符',
  invalidDevice: '无效设备ID',
  descriptionValidatorTips: '输入内容仅可包含中文、字母、-、数字和下划线',
  devTypeNullTips: '设备类型不能为空',
  devNameNullTips: '设备名称不能为空',
  devIdNullTip: '设备ID不能为空',
  collectSuccess: '收藏成功',
  cancelCollect: '取消收藏成功',
  addDevSuccess: '添加设备成功',
  allow: '允许',
  forbid: '禁止',
  deleteDeviceSuccess: '删除设备成功',
  deleteChannelError: '删除设备绑定的通道失败',
  howToDbc: '如何在云端进行DBC解析',
  toDbc: '针对CAN报文数据，实时进行DBC解析，可视化曲线，快速分析问题',
  howToEcu: '如何在云端进行ECU升级',
  toEcu: '云端远程进行ECU升级、UDS诊断',
  nonsupport: '不支持',
  notInserted: '未插入',
  withoutRecord: '未记录',
  record: '记录中',
  channelTip: '{key}上云{enable}，数据上云{isUpload}',
  enable: '使能',
  disable: '失能',
  duplicationDevice: '设备ID已被绑定，请确认ID是否有误或联系我们的技术支持'
}
