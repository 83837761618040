/**
 * 工单
 */
export default {
  addWorkOrder: 'Addition sheet',
  deleteOrder: 'Are you sure you want to delete ticket {name}?',
  orderManage: 'Workorder management',
  workName: 'Work order name',
  feedbackType: 'Feedback type',
  createTime: 'Creation time',
  workStatus: 'Work order status',
  deleteOrderSuccess: 'Deleting a work order succeeded.',
  deleteOrderFail: 'Description Failed to delete a work order',
  consult: 'Consult',
  problem: 'Problem',
  suggest: 'Suggest',
  other: 'Other',
  pending: 'Pending',
  process: 'Process',
  wait: 'Wait',
  done: 'Done',
  abandon: 'Abandon',
  refuse: 'Refuse',
  orderDetail: 'Work order details',
  image: 'Pictorial information',
  initiator: 'Initiator：',
  handlingInformation: 'Handling information',
  remark: 'Remark',
  handler: 'Handler：',
  acceptance: 'Acceptance condition',
  finish: 'Accomplish',
  inputRemark: 'Please enter remarks',
  identifier: 'Identifier：',
  belongFunction: 'Attribution function',
  phone: 'Phone',
  updateStatusSuccessfully: 'Status update successful',
  updateStatusFail: 'Failed to update status',
  getOrderDetailFail: 'Error obtaining work order details',
  getInitiatorFail: 'Failed to get sponsor',
  inputOrderName: 'Please enter the name of the ticket',
  inputFeedback: 'Please enter the feedback type',
  inputBelongFunction: 'Please enter the home function',
  inputDesc: 'Please enter a description',
  uploadImgage: 'Upload pictures',
  phoneTip: 'Please enter the correct number of digits, only digits',
  selectFeedback: 'Please select a feedback type',
  phoneNotNull: 'The contact number cannot be empty',
  addOrderSuccess: 'The contact number cannot be empty',
  addOrderFail: 'Failed to add processing order',
  uploadImageFail: 'Upload picture failed'
}
