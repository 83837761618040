<template>
    <el-select v-model="selectValue" class="select-with-input" :placeholder="getLanguage('zws.ZCommon.pleaseSelect') || '请选择'"
        style="width: 100%" @change="emitEvent" @visible-change="visibleChange">
        <template v-for="item in props.options" :key="item.label">
            <div class="select-with-input-content">
                <el-input size="small" v-model="selectValue" v-if="item.label === (getLanguage('zws.ZCommon.custom')||'自定义')" :placeholder="getLanguage('zws.ZCommon.custom')||'自定义'"
                    style="margin-left: 16px"></el-input>
                <el-option v-else :label="item.label" :value="item.value">
                    <span v-if="item.tip">{{ item.tip }}</span>
                    <span>{{ item.label }}</span>
                </el-option>
            </div>
        </template>
    </el-select>
</template>
<script setup lang="ts">
import { ref, withDefaults, defineProps, defineEmits, defineExpose } from "vue";
import { useLocale } from '../hooks/useLocale'
interface optionsProps {
    label: string,
    value: any,
    tip: string
}
interface Props {
    options: Array<optionsProps>,
    modelValue: string
}

const props = withDefaults(defineProps<Props>(), {
    options: () => [
       
    ],
    modelValue: undefined
});
const emits = defineEmits(['update:modelValue']);

const selectValue = ref<string | undefined>(props.modelValue);
let { t } = useLocale()
let resetValue = () => {
    selectValue.value = undefined;
};

function emitEvent(e: any) {
    emits('update:modelValue', e);
}
function visibleChange(isVisible: boolean) {
    if (isVisible) {
        selectValue.value = props.modelValue;
    } else {
        emitEvent(selectValue.value);
    }
}

let getLanguage = function (data:any) {
    
    return t(data)
}
defineExpose({
    resetValue, getLanguage
})


</script>

<style lang="less" scoped>
.select-with-input {
    &-content {
        box-sizing: border-box;
        overflow: hidden;

        :deep(.el-input__inner) {
            width: 80%;
        }
    }
}
</style>
