<template>
    <div class="z-success-tips">
        <img class="success-image" src="../../ZImageArea/user/ic_register_success.png" />
        <p class="success-tips">{{ t("zws.register.tips.success") }}</p>
        <p class="success-content">{{ t("zws.register.tips.successThank") }} </p> 
        <el-button type="primary" style="margin-top: 20px" icon="iconfont icondenglu" @click="promptlyLogin">{{ t("zws.register.tips.backToLogin") }}
        </el-button>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useLocale } from '../../../hooks/useLocale'

export default defineComponent({
    setup(props, context) {
        let { t } = useLocale()
        function promptlyLogin() {
            context.emit('promptlyLogin')
        }
        return {
            t,
            promptlyLogin,
        }
    },
})
</script>
<style lang="less" scoped>
.z-success-tips {
    width: 550px;
    // height: 400px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .success-image {
        width: 150px;
        height: 150px;
    }

    .success-tips {
        font-size: 20px;
        color: #333333;
    }

    .success-content {
        // margin-top: 16px;
        width: 334px;
        font-size: 14px;
        color: #999999;
    }

    .success-thank {
        margin-top: 10px;
        font-size: 14px;
        color: #999999;
    }

    .el-button {
        padding: 0 20px;
    }
}
</style>
