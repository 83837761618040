import common from './common'
import subuserManagement from './subuserManagement'
import handleLog from './handleLog'
import updateLog from './updateLog'
import homePage from './homePage'
import dbcManagement from './dbcManagement'
import ecuManagement from './ecuManagement'
import faultCode from './faultCode'
import workOrder from './workOrder'
import deviceManagement from './deviceManagement'
import deviceData from './deviceData'
import dbcData from './dbcData'
import triggerManagement from './triggerManagement'
import udsDiagnose from './udsDiagnose'
import ecuUpgrade from './ecuUpgrade'
import gpsTrack from './gpsTrack'
import lib from './lib'

export default {
  menu: {
    homePage: '首页',
    dbcManagement: 'DBC管理',
    ecuManagement: 'ECU管理',
    faultCodeManagement: '故障码管理',
    lowCodePlatform: '低代码平台',
    capacityRequest: '容量申请',
    workOrder: '工单',
    usercenter: '个人中心',
    subuserManagement: '子用户管理',
    handleLog: '操作日志',
    updateLog: '更新日志',
    logout: '退出登录'
  },
  detailMenu: {
    deviceManagement: '设备管理',
    deviceData: '设备数据',
    dbcData: 'DBC数据',
    triggerManagement: '触发管理',
    udsDiagnose: 'UDS诊断',
    ecuUpgrade: 'ECU升级',
    gpsTrack: 'GPS轨迹'
  },
  header: {
    statisticsTitle: '云盘存储容量统计',
    remainingCapacity: '剩余容量百分比',
    total: '总容量：',
    used: '已用容量：',
    remain: '剩余容量：',
    system: 'CANDTU云数据平台',
    screen: '大屏',
    monitorScreen: '监控大屏',
    operationScreen: '运维大屏'
  },
  footer: {
    phone: '联系电话：',
    email: '邮箱：',
    company: '广州致远电子股份有限公司'
  },
  common,
  subuserManagement,
  handleLog,
  updateLog,
  homePage,
  dbcManagement,
  ecuManagement,
  faultCode,
  workOrder,
  deviceManagement,
  deviceData,
  dbcData,
  triggerManagement,
  udsDiagnose,
  ecuUpgrade,
  gpsTrack,
  lib
}
