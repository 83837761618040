/**
 * ECU升级
 */
export default {
  obtainECUListFailed: 'Failed to obtain the ECU list',
  obtainDeviceStautsFailed: 'Failed to obtain the device status, {error}',
  tryAgain: 'ECU firmware upgrade failed. Please try again',
  firmwareUpgradeSuccess: 'The ECU firmware is upgraded successfully',
  sendUpgradeCommandFailed: 'Failed to send the upgrade command',
  generateCommandFailed: 'Failed to generate the ECU firmware upgrade command',
  obtainURLFailed: 'Failed to obtain the ECU firmware URL',
  bindChannelFailed: 'Failed to bind the upgrade channel, {error}',
  uploadFirmwareFailed: 'Failed to upload the firmware file',
  responseAddrOutOfRange2: 'The response address is outside the standard frame address range of 7FF',
  physicalAddrOutOfRange2: 'The physical address is outside the standard frame address range of 7FF',
  functionAddrOutOfRange2: 'The functional address is outside the standard frame address range of 7FF',
  responseAddressOutOfRange: 'The response address is outside the extended frame address range of 1FFFFFFF',
  physicalAddrOutOfRange: 'The physical address is outside the extended frame address range of 1FFFFFFF',
  functionAddrOutOfRange: 'The functional address is outside the extended frame address range of 1FFFFFFF',
  pleaseSelectChannel: 'Please select channel',
  repeatOperation: 'Do not repeat operations until the upgrade is complete',
  deviceOffline: 'The ECU cannot be upgraded because the device is offline',
  obtainDevLogFailed: 'Obtaining device logs failed',
  obtainDevStatusFailed: 'Failed to obtain the device status',
  sendResetStatusCommandFailed: 'Failed to send the reset device status command',
  noPermissionToResetStatus: 'Failed to obtain the reset device status because the module user has no permission',
  firmwareUpgrading: 'Firmware Upgrading',
  ecuFirmwareUpgrade: 'ECU Firmware Upgrade',
  outOfExtendedFrame: 'Out of the address range of the extended frame',
  outOfStandardFrame: 'Out of the address range of the standard frame',
  extendedFrame: 'Extended Frame',
  functionAddress: 'Function Address',
  responseAddress: 'Response Address',
  physicalAddress: 'Physical Address',
  address0x: 'Address(0x)',
  pleaseSelectECU: 'Please Select ECU',
  canChannel: 'CAN Channel',
  ecuUpgrade: 'ECU Upgrade',
  debugInfo: 'Debug Information',
  unlockFile: 'Unlock File',
  paramConfig: 'Parameter Configuration',
  firmwareFile: 'Firmware File',
  proversion: 'protocol version',
  fd: 'CANFD acceleration'
}
