<template>
    <div class="z-area-mobile-input-ctrl">
        <el-input class="z-area-mobile-input-ctrl-input" v-model="mobileValue" :placeholder="getLanguage('zws.ZAreaMobileInputCtrl.enterMobilePhone')" @input="mobileInput" v-bind="$attrs">
            <template #prepend>
                <el-select class="z-area-mobile-input-ctrl-select" v-model="areaValue" :placeholder="getLanguage('zws.ZAreaMobileInputCtrl.selectArea')" @change="selectAreaChange">
                    <el-option v-for="item in areaOptions" :key="item.value" :label="item.value" :value="item.value">
                        <div>
                            <span class="z-area-mobile-input-ctrl-select-label">{{ item.label }}</span>
                            <span class="z-area-mobile-input-ctrl-select-value">{{ item.value }}</span>
                        </div>
                    </el-option>
                </el-select>
            </template>
        </el-input>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, watch, getCurrentInstance, computed } from 'vue'
import ZAreaCodeListTool from '../../../sdk/ts/tool/ZAreaCodeListTool'
import { useLocale } from '../hooks/useLocale'
import { useI18n } from 'vue-i18n'
export default defineComponent({
    props: {
        modelValue: {},
        lang: {}
    },
    setup(prop: any, context) {
        let { t } = useLocale()
        const { proxy }: any = getCurrentInstance()
        let getLanguage = function (data:any) {
             t  = useLocale().t
            return t(data)
        }
        t  = useI18n().t
        let lan =  computed(() => (
             useI18n().locale
        ))
        let areaValue = ref('+86')
        let mobileValue = ref('')
        let language = ref('')
        let areaOptions: any = reactive([])
        getCountryList()
        if (prop.modelValue) {
            parseMobileInfo(prop.modelValue)
        }
        function getCountryList() {
            areaOptions.splice(0, areaOptions.length)
            ZAreaCodeListTool.forEach((item: any) => {
                let country = prop.lang === 'zh' ? item.cn : item.en
                areaOptions.push({
                    label: country,
                    value: '+' + item.areaCode,
                })
            })
        }

        watch(
            () => prop.modelValue,
            (newValue: any, oldValue) => {
                parseMobileInfo(newValue)
            }
        )
        watch(
            () => prop.lang,
            (newValue: any, oldValue) => {
                
                getCountryList()
            }
        )
        function selectAreaChange(value: any) {
            context.emit('update:modelValue', value + mobileValue.value)
        }
        function mobileInput(value: any) {
            let mobileInfo = areaValue.value + mobileValue.value
            mobileValue.value = value
            if (value === '') {
                mobileInfo = ''
            }
            context.emit('update:modelValue', mobileInfo)
        }
        function parseMobileInfo(mobile: string) {
            areaOptions.forEach((item: any) => {
                const code = item.value
                if (mobile.indexOf(code) !== -1) {
                    areaValue.value = code
                }
            })
            mobileValue.value = mobile.replace(areaValue.value, '')
        }
        return {
            getLanguage,
            mobileValue,
            areaValue,
            language,
            areaOptions,
            getCountryList,
            selectAreaChange,
            mobileInput,
        }
    },
})
</script>


<style lang="less" scoped>
.z-area-mobile-input-ctrl {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.z-area-mobile-input-ctrl-input {
    width: 100%;
}
.z-area-mobile-input-ctrl-select {
    width: 100px;
}
.z-area-mobile-input-ctrl-select-label {
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.z-area-mobile-input-ctrl-select-value {
    float: right;
    font-size: 13px;
}
</style>
