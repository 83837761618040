<template>
    <div class="recall-list">
        <div class="recall-list-content" v-loading="recallListInfo.loading">
            <ZTablePaginationCtrl v-model="tablePaginationValue" :tableHeader="tableHeader" :tableData="recallListInfo.tableData" @paginationChange="paginationChange">
                <template v-slot:deal="scope">
                    <el-button @click="recallClick(scope.data)" type="primary" size="mini" plain :disabled="(scope.data.size == 0)">{{getLanguage('zws.ZRecall.fileBack')}}</el-button>
                </template>
            </ZTablePaginationCtrl>
        </div>
        <ZStepWithProcessCtrl
                :stepProcessTitle="getLanguage('zws.ZRecall.recallProgress')||'召回进度'"
                v-model="stepWithProcessInfo.stepWithProcessVisible"
                :stepInfoList="stepWithProcessInfo.stepInfoList"
                :stepActive="stepWithProcessInfo.stepActive"
                :sizeUnit="stepWithProcessInfo.sizeUnit"
                :sizeTotal="stepWithProcessInfo.sizeTotal"
                :percentage="stepWithProcessInfo.percentage"
                @midwayCancelClick="midwayCancelClick"
        >
        </ZStepWithProcessCtrl>
    </div>
</template>

<script setup lang="ts">
    import { ref, reactive, withDefaults, defineProps, defineEmits, defineExpose, watch, nextTick, onMounted } from "vue";
    import { ElMessage } from 'element-plus'
    import ZDateTool from '../../../../sdk/ts/tool/ZDateTool'
    import ZFormatConversionTool from '../../../../sdk/ts/tool/ZFormatConversionTool'
    import ZFileTool from '../../../../sdk/ts/tool/ZFileTool'
    import { hexadecimalToU8 } from '../../../../sdk/ts/tool/candtuRaw/candtuRaw'
    import { useRoute } from 'vue-router'
    import { useI18n } from 'vue-i18n'
    import { useLocale } from '../../hooks/useLocale'


    export interface RecallListPropsInfo {
        devtype: string,
        info_model_id: number | string,
        tenant_id: number | string,
        things_type: number | string,
        things_id: number | string,
        third_things_id: number | string,
        startTime: number,
        stopTime: number,
        category_id: number,
        getListCommand: string,
        recallCommand: string
    }
    interface Props {
        initFlagValue: any
        recallListPropsInfo: RecallListPropsInfo
        currentPath: string
        currentPart: string
    }
    interface RecallListInfo {
        loading : boolean,
        tableData: Array<any>,
    }
    interface StepInfoList {
        title: string,
        description?: string,
    }
    interface StepWithProcessInfo {
        sizeTotal: number,
        sizeUnit: string | undefined,
        stepInfoList: Array<StepInfoList>,
        stepActive: number,
        percentage: number,
        stepWithProcessVisible: boolean
    }
    interface RecallDateInfo {
        offset: number,
        recallContinueFlag : boolean, // 中途取消 标识
        errRepeat: number
    }

    const route = useRoute();

    const props = withDefaults(defineProps<Props>(), {
        initFlagValue: null,
    });
    const emits = defineEmits(['recallListTimeOut', 'recallListOk']);
    let { t } = useI18n()
    const $t = t
    t = useLocale().t
    let getLanguage = function (data: any, tip?: any) {
       
      return t(data)
    }
    // 文件召回列表
    let allTableDataList: any = [];
    let tablePaginationValue: any = ref('');
    let tableHeader: any = reactive([
        { label: '序号', prop: 'index', width: '60', type: '', align: 'center', },
        { label: '文件名', prop: 'filename', type: '', align: 'center', },
        { label: '大小', prop: 'size', width: '180', align: 'center', },
        { label: '日期', prop: 'time', type: '', align: 'center',  },
        { label: '操作', prop: 'deal', width: '180', type: 'custom', align: 'center',  },
    ]);
    let recallListInfo: RecallListInfo = reactive({
        loading: false,
        tableData: [],
    });
    let thisTableRow: any = reactive({ size: 0, filename: '' });

    // 列表中召回事件， 1-发送设备命令 Info
    let recallSendMessagesRep: any = {
        rep: null,
        repTime: null
    };

    // 召回进度弹窗配置
    let stepWithProcessInfo: StepWithProcessInfo = reactive({
        sizeTotal: 0,
        sizeUnit: '',
        stepInfoList: [
            { title: '命令发送', tip: '命令发送中' },
            { title: '文件获取', tip: '文件获取中' },
        ],
        stepActive: 0, // 当前步骤
        percentage: 0, // 当前任务进度
        stepWithProcessVisible: false
    });

    let findThingsListInterval: any = null;
    let findThingsListIntervalCount: any = 0;

    let commandCheckCount: any = 0;
    let fileCheckCount: any = 0;
    let deviceLogParamInfo: any = props.recallListPropsInfo;

    let recallDateInfo: RecallDateInfo = reactive({
        offset: 0,
        recallContinueFlag: true,
        errRepeat: 0
    });

    /** 点击文件召回， 1-发送设备命令 */
    function sendMessagesToDevice() {
        let payload = {"operation_name": `${deviceLogParamInfo.devtype?.replaceAll('-', '_')}_fn.${deviceLogParamInfo.getListCommand}`, "operation_params":{
                StartTime: deviceLogParamInfo.startTime,
                StopTime: deviceLogParamInfo.stopTime
            }};
        const info = {
            description: '',
            "headers": "{}",
            "info_model_id": deviceLogParamInfo.info_model_id,
            "message_type": 0,
            "payload": JSON.stringify(payload),
            "tenant_id": deviceLogParamInfo.tenant_id,
            "things_id": deviceLogParamInfo.things_id,
            "things_type": deviceLogParamInfo.things_type,
            "third_things_id": deviceLogParamInfo.third_things_id,
        };

        window.ZWSApi.ZWSThings.messages_sendMessagesToDevice({ tenant_id: deviceLogParamInfo.tenant_id, things_id: deviceLogParamInfo.things_id, info })
            .then((res: any) => {
                let sendCommandTime = Date.now();
                findThingsListInterval = setInterval(() => {
                    findThingsListIntervalCount += 1;
                    findThingsList(res, sendCommandTime);
                }, 1000)
            })
            .catch((err: any) => {
                recallListInfo.loading = false;
                ElMessage.error('请求超时，请重试(code: 002)');
                emits('recallListTimeOut');
            })
    }

    /** 点击文件召回，2-轮询获取列表 */
    function findThingsList(res: any, sendMessagesToDeviceTime: any) {
        let currentRouterPath = route.path;
        let currentPartName = sessionStorage.getItem('currentPartName');
        // 切换页面后不再沦陷接口
        let isInSelfPage = currentRouterPath.includes(props.currentPath) && currentPartName === props.currentPart;

        if (findThingsListIntervalCount > 10 || !isInSelfPage) {
            clearInterval(findThingsListInterval);
            if (isInSelfPage) {
                ElMessage.error('请求超时，请重试(code: 003)');
            }
            findThingsListIntervalCount = 0;
            recallListInfo.loading = false;
            emits('recallListTimeOut');
        } else {
            tablePaginationValue.value.total = 0;

            window.ZWSApi.ZWSThings.messages_getMessagesResponse({
                tenant_id: deviceLogParamInfo.tenant_id,
                message_ids: res.messageId,
                // start_date: sendMessagesToDeviceTime,
                start_date: res.timestamp - 1000,
        })
                .then((res: any) => {

                    if(deviceLogParamInfo.devtype && res && res[0] && res[0].messages) {
                        let responseList = JSON.parse(res[0].messages)?.list;
                        if (responseList && responseList[0] && responseList[0].data && responseList[0].data.msg) {
                            clearInterval(findThingsListInterval);
                            findThingsListIntervalCount = 0;
                            recallListInfo.loading = false;
                            emits('recallListOk');

                            let msgData = JSON.parse(responseList[0].data.msg);
                            if (msgData.FileInfo && Array.isArray(msgData.FileInfo) && msgData.FileInfo.length > 0) {
                                let tableDataTem: any = [];
                                recallListInfo.tableData = [];
                                allTableDataList = [];
                                msgData.FileInfo.forEach((item: any) => {
                                    let pushItem = {
                                        size: ZFormatConversionTool.converByteUnit(item.Size),
                                        time: ZDateTool.timestampToFormatDate(item.Time * 1000),
                                        filename: item.Name
                                    };
                                    tableDataTem.push(pushItem);
                                });
                                
                                allTableDataList = tableDataTem;

                                // recallListInfo.tableData = tableDataTem;
                                setTableByPagination();
                                tablePaginationValue.value.total = allTableDataList.length;
                            }
                        }
                    }
                })
                .catch((err: any) => {
                    ElMessage.error('请求超时，请重试(code: 004)');
                    emits('recallListTimeOut');
                })
        }

    }

    const getInfo = (RecType: number) => {
        const payload = {
            "operation_name": `${deviceLogParamInfo.devtype?.replaceAll('-', '_')}_fn.${deviceLogParamInfo.getListCommand}`,
            "operation_params":{
                StartTime: deviceLogParamInfo.startTime,
                StopTime: deviceLogParamInfo.stopTime,
                RecType: RecType
            }
        }
        return {
            description: '',
            "headers": "{}",
            "info_model_id": deviceLogParamInfo.info_model_id,
            "message_type": 0,
            "payload": JSON.stringify(payload),
            "tenant_id": deviceLogParamInfo.tenant_id,
            "things_id": deviceLogParamInfo.things_id,
            "things_type": deviceLogParamInfo.things_type,
            "third_things_id": deviceLogParamInfo.third_things_id,
        }
    }

    /**
     * 支持旧设备can200和400
     */
    const sendMessagesToOldDevice = () => {
        const requestArray: any[] = []
        for (let i = 0; i < Object.keys(deviceLogParamInfo.canInfo).length; i++) {
            requestArray.push(
                window.ZWSApi.ZWSThings.messages_sendMessagesToDevice({ 
                    tenant_id: deviceLogParamInfo.tenant_id,
                    things_id: deviceLogParamInfo.things_id,
                    info: getInfo(i)
                })
            )
        }
        // 合并通道
        requestArray.push(
            window.ZWSApi.ZWSThings.messages_sendMessagesToDevice({ 
                tenant_id: deviceLogParamInfo.tenant_id,
                things_id: deviceLogParamInfo.things_id,
                info: getInfo(240)
            })
        )
        Promise.all(requestArray).then((result: any[]) => {
            const messageIdArr: number[] = []
            const timestampArr: number[] = []
            result.forEach(item => {
                messageIdArr.push(item.messageId)
                timestampArr.push(item.timestamp)
            })
            const messageIds = messageIdArr.join(',')
            const requestTimestamp = Math.min(...timestampArr) - 1000
            findThingsListInterval = setInterval(() => {
                findThingsListIntervalCount += 1;
                findOldThingsList(messageIds, requestTimestamp);
            }, 1000)
        }).catch((err) => {
            console.log(err)
            recallListInfo.loading = false;
            ElMessage.error('请求超时，请重试(code: 002)');
            emits('recallListTimeOut');
        });
    }

    /**
     * 支持旧设备文件列表
     */
    const findOldThingsList = (messageIds: string, requestTimestamp: number) => {
        let currentRouterPath = route.path;
        let currentPartName = sessionStorage.getItem('currentPartName');
        let isInSelfPage = currentRouterPath.includes(props.currentPath) && currentPartName === props.currentPart;
        if (!isInSelfPage) {
            clearInterval(findThingsListInterval)
            findThingsListIntervalCount = 0;
            recallListInfo.loading = false;
            emits('recallListTimeOut');
        }

        // if (findThingsListIntervalCount > 10 || !isInSelfPage) {
        //     clearInterval(findThingsListInterval);
        //     if (isInSelfPage) {
        //         ElMessage.error('请求超时，请重试(code: 003)');
        //     }
        //     findThingsListIntervalCount = 0;
        //     recallListInfo.loading = false;
        //     emits('recallListTimeOut');
        // } else {
            tablePaginationValue.value.total = 0;
            let finishIndex = 0
            let tableDataTem: any[] = []
            window.ZWSApi.ZWSThings.messages_getBatchMessagesResponse({message_ids: messageIds, start_date: requestTimestamp}).then((result: any[]) => {
                result.forEach((item: any) => {
                    if (item?.messages) {
                        let responseList = JSON.parse(item.messages)?.list;
                        if (responseList && responseList.length && responseList[0].data && responseList[0].data.msg) {
                            finishIndex++
                            // candtu200空文件的时候{"StopTime":1665734884,"FileInfo":]}
                            try {
                                let msgData = JSON.parse(responseList[0].data.msg);
                                if (msgData.FileInfo && Array.isArray(msgData.FileInfo) && msgData.FileInfo.length > 0) {
                                    const _payload = JSON.parse(item?.payload)
                                    const operation_params = JSON.parse(_payload?.payload)
                                    msgData.FileInfo.forEach((item: any) => {
                                        let time = ""
                                        if (item.Name.indexOf('_') !== -1) {
                                            time = item.Name.split('.')[0]
                                            time = time.slice(time.length - 19, time.length)
                                            let hours = time.split('_')[1].replace(/-/g, ':')
                                            time = time.split('_')[0] + ' ' + hours
                                        } else {
                                            time = item.Name.split('.')[0]
                                            time = time.slice(0, 4) + '-' + time.slice(4, 6) + '-' + time.slice(6, 8) + ' ' +
                                            time.slice(8, 10) + ':' + time.slice(10, 12) + ':' + time.slice(12, 14)
                                        }
                                        let pushItem = {
                                            size: ZFormatConversionTool.converByteUnit(item.Size),
                                            // time: ZDateTool.timestampToFormatDate(item.Time * 1000),
                                            time,
                                            filename: item.Name,
                                            RecType: operation_params?.operation_params?.RecType
                                        };
                                        tableDataTem.push(pushItem);
                                    })
                                }
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }
                })
                if (finishIndex === result.length) {
                    clearInterval(findThingsListInterval);
                    findThingsListIntervalCount = 0;
                    recallListInfo.loading = false;
                    emits('recallListOk');
                    allTableDataList = tableDataTem
                    setTableByPagination();
                    tablePaginationValue.value.total = allTableDataList.length;
                }
            }).catch((err: any) => {
                console.log(err);
                ElMessage.error('请求超时，请重试(code: 004)');
                emits('recallListTimeOut');
            })

            if (findThingsListIntervalCount > 10 || !isInSelfPage) {
                clearInterval(findThingsListInterval);
                if (finishIndex) {
                    emits('recallListOk');
                    allTableDataList = tableDataTem
                    setTableByPagination();
                    tablePaginationValue.value.total = allTableDataList.length;
                } else {
                    ElMessage.error('请求超时，请重试(code: 003)');
                }
                findThingsListIntervalCount = 0;
                recallListInfo.loading = false;
                emits('recallListTimeOut');
            }
    }

   /** 获取设备召回的文件 */
   function getRecallFile() {
       return new Promise((resolve, reject) => {
           window.ZWSApi.ZWSOutFileManager.file_getFilesUrl({
               tenant_id: deviceLogParamInfo.tenant_id,
               name: thisTableRow.filename,
               type: 'dev'
           })
               .then((res: any) => {
                   resolve(res);
               })
               .catch((error: any) => {
                   reject(error);
               })
       })
   }

    /** 列表中召回事件， 1-发送设备命令 */
    function sendRecallMessagesToDevice() {
        let host = window.location.host.includes('localhost') ? 'http://192.168.24.16:30880' : window.location.origin;
        let Url = `${host}/v1/filemanager/upload/commonfile/${thisTableRow.filename}`;
        
        // let Url = `http://${host}/v1/out-filemanager/tenants/${deviceLogParamInfo.tenant_id}/deviceUploadPart/${thisTableRow.filename}`;
        let payload = {"operation_name": `${deviceLogParamInfo.devtype?.replaceAll('-', '_')}_fn.${deviceLogParamInfo.recallCommand}`,"operation_params":{"Name": thisTableRow.filename, "Offset": recallDateInfo.offset,RecType: handleRow?.RecType, Url }};
        const info = {
            description: '',
            "headers": "{}",
            "info_model_id": deviceLogParamInfo.info_model_id,
            "message_type": 0,
            "payload": JSON.stringify(payload),
            "tenant_id": deviceLogParamInfo.tenant_id,
            "things_id": deviceLogParamInfo.things_id,
            "things_type": deviceLogParamInfo.things_type,
            "third_things_id": deviceLogParamInfo.third_things_id,
        };

        window.ZWSApi.ZWSThings.messages_sendMessagesToDevice({ tenant_id: deviceLogParamInfo.tenant_id, things_id: deviceLogParamInfo.things_id, info })
            .then((res: any) => {
                recallSendMessagesRep.res = res;
                recallSendMessagesRep.resTime = Date.now();
                if ((deviceLogParamInfo.devtype.includes('200') && deviceLogParamInfo.currentfmVersion < '4.00') || deviceLogParamInfo.devtype?.includes('400')) {
                    oldFileRecall()
                } else {
                    checkCommand()
                }
            })
            .catch((err: any) => {
                recallListInfo.loading = false;
                ElMessage.error(err?.err?.data?.message);
            })
    }

    async function loopGetFileProcessOrUrl() {
        const resMsg: any = await getFileProcessOrUrl();
        if (resMsg === 'oss-404' && fileCheckCount < 600 && recallDateInfo.recallContinueFlag) {
            setTimeout(() => {
                loopGetFileProcessOrUrl();
            }, 1000)
        }
    }
    /** 列表中召回事件， 2-1 确认命令发送的状态 */
    function checkCommand() {
        commandCheckCount += 1;
        if (commandCheckCount >= 15) {
            commandCheckCount = 15
        }
        stepWithProcessInfo.percentage = Number((commandCheckCount * (100 / 15.1)).toFixed(2)); // 确认命令的进度
        window.ZWSApi.ZWSThings.messages_getMessagesResponse({
            tenant_id: deviceLogParamInfo.tenant_id,
            message_ids: recallSendMessagesRep.res.messageId,
            start_date: recallSendMessagesRep.res.timestamp - 1000,
            offset: recallDateInfo.offset,
        })
            .then((res: any) => {
                // debugger
                if (!recallDateInfo.recallContinueFlag) return;

                let isCommandOk = dealFragmentFile(res);
                // debugger
                if (isCommandOk) {
                    stepWithProcessInfo.percentage = 100;
                    // 2-2 获取上传进度，上传完毕返回下载地址
                    setTimeout(async () => {
                        setStepInfo(1, 0);
                        commandCheckCount = 0;
                        loopGetFileProcessOrUrl();
                    }, 1000)
                } else {
                    recallDateInfo.offset += 8192;
                    setTimeout(() => {
                        recallDateInfo.recallContinueFlag && checkCommand()
                    }, 1000);

                    recallDateInfo.errRepeat = 0;
                }
            })
            .catch((err: any) => {
                if (recallDateInfo.errRepeat <= 3) {
                    checkCommand();
                    recallDateInfo.errRepeat += 1
                } else {
                    // 命令发送失败
                    ElMessage.error('命令发送失败，请重试！');
                    stepWithProcessControl(false);
                }
            })
    }

    let oldFileRecallBeginTime = 0
    let CRFFileData = ""
    let numArray: number[] = []
    const oldFileRecall = () => {
        commandCheckCount += 1;
        if (commandCheckCount >= 15) {
            commandCheckCount = 15
        }
        // stepWithProcessInfo.percentage = Number((commandCheckCount * (100 / 15.1)).toFixed(2)); // 确认命令的进度
        window.ZWSApi.ZWSDataMgmt.thingsDataController_getThingsData({
            tenant_id: deviceLogParamInfo.tenant_id,
            info_model_id: deviceLogParamInfo.info_model_id,
            things_id:  deviceLogParamInfo.things_id,
            category_id: deviceLogParamInfo.category_id,
            event_name: `${deviceLogParamInfo.devtype?.replaceAll('-', '_')}_fn.file`,
            begin_time: oldFileRecallBeginTime ? oldFileRecallBeginTime : recallSendMessagesRep.res.timestamp - 1000,
            data_type: 'data',
            current_page: 1,
            page_size: 100,    
        }).then((result: any) => {
            let goOnFlag = false
            if (result.list && Array.isArray(result.list) && result.list.length) {
                oldFileRecallBeginTime = result.list[0]?.timestamp + 1
                result.list.reverse().forEach((item: any) => {
                    if (!numArray.includes(item.data.num)) {
                        stepWithProcessInfo.percentage = Number((item.data.num / item.data.totalNum * 100).toFixed(2)); // 确认命令的进度
                        numArray.push(item.data.num)
                        const _data = item?.data?.data
                        if (_data) {
                            CRFFileData += _data.slice(8 ,_data.length - 1)
                        }
                        if (item.data.num === item.data.totalNum) {
                            goOnFlag = true
                        }
                    }
                })
            }
            if (goOnFlag) {
                stepWithProcessInfo.percentage = 100;
                const CRFFile = hexadecimalToU8(CRFFileData)
                ZFileTool.exportData(CRFFile, thisTableRow.filename)
                setStepInfo(0, 0, false);
                commandCheckCount = 0;
                oldFileRecallBeginTime = 0
                numArray = []
            } else {
                setTimeout(() => {
                    recallDateInfo.recallContinueFlag && oldFileRecall()
                }, 1000);
            }
        }).catch((err: any) => {
            console.log(err);
            
        });
    }

    /** 列表中召回事件，2-2 获取文件上传进度，上传完毕会返回下载 URL */
    async function getFileProcessOrUrl() {
        fileCheckCount += 1;
        let errMsg: any = 'ok';
        let fileRes: any = await getRecallFile().catch((err: any) => {
            console.log('oss-404', err);
            errMsg = 'oss-404';
        });
        if (errMsg !== 'ok' && fileCheckCount < 600) return errMsg;

        if (!fileRes) {
            ElMessage.error('文件获取失败，请重试(code: 001)');
            setStepInfo(0, 0, false);
            return;
        }

        let targetUrl = fileRes?.url;
        if (!targetUrl && !fileRes.curPart && !fileRes.totalPart) {
            ElMessage.error('文件获取失败，请重试(code: 002)');
            setStepInfo(0, 0, false);
            return;
        }

        if (targetUrl && targetUrl.length > 0) {
            getFinishAndDownFile(targetUrl);
        } else {
            if (stepWithProcessInfo.stepWithProcessVisible) {
                stepWithProcessInfo.percentage = fileRes.curPart / fileRes.totalPart * 100;
                setTimeout(() => {
                    recallDateInfo.recallContinueFlag && getFileProcessOrUrl(); // 每个2秒获取一次进度
                }, 2000)
            }
        }
    }


    function init () {
        recallListInfo.loading = true;
        recallListInfo.tableData = [];
        allTableDataList = [];
        if ((deviceLogParamInfo.devtype.includes('200') && deviceLogParamInfo.currentfmVersion < '4.00') || deviceLogParamInfo.devtype?.includes('400')) {
            sendMessagesToOldDevice();
        } else {
            sendMessagesToDevice();
        }
        // if (deviceLogParamInfo.devtype === "can-common" || deviceLogParamInfo.devtype === "can_common") {
        //     sendMessagesToDevice();
        // } else {
        //     sendMessagesToOldDevice();
        // }
        // sendMessagesToOldDevice();
    }


    // 确认是否已经存在该文件，既存在 URL
    async function checkIsFileExist() {
        let fileRes: any = null;
        try {
            fileRes = await getRecallFile()
        } catch (e) {
            
        }
        return fileRes && fileRes.url ? fileRes.url : '';
    }

    function getFinishAndDownFile(targetUrl: string) {
        stepWithProcessInfo.percentage = 100;
        if (!targetUrl) {
            ElMessage.error('下载失败，请重试');
            setStepInfo(0, 0, false);
            return;
        }
        ElMessage.success('开始下载...');
        ZFileTool.downFileFormBlobUrl({
            url: targetUrl,
            fileName: thisTableRow.filename,
            cb: (() => {
                stepWithProcessInfo.stepActive = 2;
                setTimeout(() => {
                    setStepInfo(0, 0, false);
                }, 1000)
            })
        });

    }

    function setStepInfo(stepActive: number, percentage: number, visible?: boolean) {
        stepWithProcessInfo.stepActive = stepActive;
        stepWithProcessInfo.percentage = percentage;
        if (visible != null){
            stepWithProcessControl(visible);
        }
    }

    function dealFragmentFile(res: any) {
        let result = null;
        if(res && res[0] && res[0].messages) {
            let responseList = JSON.parse(res[0].messages).list;
            if (responseList && responseList[0] && responseList[0].data && responseList[0].data.msg) {
                result = responseList[0].data.result;
            }
        }
        return result;
    }

    function stepWithProcessControl(type: boolean) {
        stepWithProcessInfo.stepWithProcessVisible = type;
        if (type) {
            recallDateInfo.recallContinueFlag = true;
        }
    }

    // 召回/删除 点击按钮设置当前行信息
    function setTableRow(row: any) {
        let thisTableRowKeys = Object.keys(thisTableRow);
        for (let item of thisTableRowKeys) {
            thisTableRow[item] = row[item]
        }
    }

    let handleRow: any = {}
    // 日志-召回点击事件
    async function recallClick (row: any) {
        fileCheckCount = 0;
        setTableRow(row);
        handleRow = row
        const targetUrl: any = await checkIsFileExist();
        if (targetUrl) {
            getFinishAndDownFile(targetUrl);
        } else {
            stepWithProcessControl(true);
            sendRecallMessagesToDevice();
        }
    }

    // 召回中途 关闭弹窗 退出
    function midwayCancelClick() {
        recallDateInfo.recallContinueFlag = false;
        setStepInfo(0, 0, false);
        oldFileRecallBeginTime = 0
        resetRecallData();
    }

    function resetRecallData() {
        recallDateInfo.offset = 0;
        recallDateInfo.errRepeat = 0;
        commandCheckCount = 0;
    }

    function paginationChange() {
        setTableByPagination();
    }

    function setTableByPagination() {
        let { currentPage, pageSize } = tablePaginationValue.value;
        recallListInfo.tableData = allTableDataList.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    }

    watch(() => props.initFlagValue, (newVal, oldVal) => {
        newVal && init()
    }, {
        immediate: true
    })

</script>

<style lang="less" scoped>
    .recall-list {
        &-content {
            label {
                width: 60px;
                text-align: right;
                margin-right: 8px;
            }
            &-footer {
                text-align: center;
                margin-top: 20px;
            }
        }
    }
</style>
