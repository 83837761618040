/**
 * 跟随鼠标移动的 tooltips
 * */
type MouseFunction = {
    _oldOnMouseEnter: any
    _oldOnMouseMove: any
    _oldOnMouseLeave: any
    _oldOnMouseUp: any
    _tooltipDom: any
};

export const tooltips = {
    mounted: function(el: HTMLElement & MouseFunction, binding: any) {
        setTimeout(() => {
            el.onmouseenter && (el._oldOnMouseEnter = el.onmouseenter);
            el.onmousemove && (el._oldOnMouseMove = el.onmousemove);
            el.onmouseleave && (el._oldOnMouseLeave = el.onmouseleave);
            el.onmouseup && (el._oldOnMouseUp = el.onmouseup);

            // 鼠标移入 => 将 tooltip 插入 DOM 中
            el.onmouseenter = function() {
                typeof el._oldOnMouseEnter === "function" && el._oldOnMouseEnter();

                // set tooltip style
                const tooltipDom: HTMLElement = document.createElement('div');

                const lightType = `
                      overflow: auto;
                      position: absolute;
                      background: #fff;
                      color: #777777;
                      box-shadow: 2px 2px 8px #909090;
                      border-radius: 5px;
                      padding: 10px 16px;
                      display:inline-block;
                      font-size: 14px;
                      z-index: 2;
                `;
                const darkType = `
                      overflow: auto;
                      position: absolute;
                      background: rgba(0,0,0,0.6);
                      color: #fff;
                      box-shadow: rgba(168, 168, 168, 0.295) 1px 2px 10px;
                      border-radius: 5px;
                      padding: 10px 16px;
                      display: inline-block;
                      font-size: 14px;
                      z-index: 2;
                `;
                tooltipDom.style.cssText = binding.arg === 'dark' ? darkType : lightType;

                // 将 tooltip 插入
                document.body.appendChild(tooltipDom);

                // 缓存 DOM
                el._tooltipDom = tooltipDom;

                // tooltip content
                el._tooltipDom && (el._tooltipDom.innerHTML = el.getAttribute('vTooltipsValue'));
            };
            // 鼠标移动 => 动态修改 tooltip 位置
            el.onmousemove = function(e: any) {
                // 执行开发者原先设置的 mousemove，这里相当于函数劫持
                typeof el._oldOnMouseMove === 'function' && el._oldOnMouseMove();

                if (!el._tooltipDom) return;
                el._tooltipDom.style.top = e.clientY + 20 + 'px';
                el._tooltipDom.style.left = e.clientX + 15 + 'px';

                el._tooltipDom.style.opacity =  e.target.getAttribute('vTooltipsExclude') === 'true' ? '0' : '1';

                el._tooltipDom.innerHTML = el.getAttribute('vTooltipsValue');
            };
            // 鼠标移出 => 移除 游离 DOM
            el.onmouseleave = function() {
                typeof el._oldOnMouseLeave === 'function' && el._oldOnMouseLeave();

                el._tooltipDom && document.body.removeChild(el._tooltipDom);
            };

            // 鼠标点击 => 重新赋值
            el.onmouseup = function() {
                typeof el._oldOnMouseUp === 'function' && el._oldOnMouseUp();

                setTimeout(() => {
                    if (!el._tooltipDom) return;
                    el._tooltipDom.innerHTML = el.getAttribute('vTooltipsValue');
                }, 100)
            }
        }, 100);
    }
};