export interface Monitoring {
  platform: string
  module: string
  action: string
  params?: string
}

export const remarkOperationMonitoring = (monitor: Monitoring) => {
  let userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo') as string)
  if (userInfo) {
    let keysArr = Object.keys(monitor)
    let base = ['platform', 'module', 'action']
    for (let i = 0; i < base.length; i++) {
      if (!keysArr.includes(base[i])) {
        return
      }
    }
    (window as any).ZWSApi.ZWSOutWrapper.behavior_addBehavior({ ...monitor, tenant_id: userInfo.tenant_id, user_id: userInfo.id })
      .catch((err: any) => {
        console.log(err)
      })
  }
}

/**
 * 记录用户行为监控  v-monitoring="Obj"
 * @param Monitoring Obj 行为属性
 */
export default {
  mounted(el: HTMLElement, binding: any) {
    el.onclick = () => {
      if (binding.value && typeof binding.value === "object") {
        remarkOperationMonitoring(binding.value)
      }
    }
  },
  beforeUpdate(el: HTMLElement, binding: any) {
    el.onclick = () => {
      if (binding.value && typeof binding.value === "object") {
        remarkOperationMonitoring(binding.value)
      }
    }
  }
}