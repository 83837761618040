<template>
    <el-select v-model="thingSelect" :placeholder="t('zws.ZCommon.tips.devSelect')||'请选择设备'" value-key="name" @change="thingSelectChange" v-bind="$attrs">
        <el-option-group v-for="group in thingsOptions" :key="group.label" :label="group.infoModelLabel">
            <el-option v-for="item in group.options" :key="item.id" :label="item.name" :value="item">
            </el-option>
        </el-option-group>
    </el-select>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useLocale } from '../../hooks/useLocale'
import ZWSApi from '../../../../sdk/ts/api/http/ZWSApi'
export default defineComponent({
    props: {
        // 父组件v-model的入参
        modelValue: {},
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        let { t } = useLocale()
        
        let api: any = ZWSApi
        let store = useStore()
        let userInfo = store.getters.userInfo
        let thingSelect: any = ref('')
        let thingsOptions: any = reactive([])
        let thingsIds: any = reactive([])

        if (props.modelValue) {
            thingSelect.value = props.modelValue
        }

        watch(
            () => props.modelValue,
            (newValue, oldValue) => {
                thingSelect.value = newValue
            }
        )
        getThingsList(userInfo.tenant_id)

        function getThingsList(tenantId: number) {
            // 获取设备列表
            api.ZWSThings.things_getThings({
                tenant_id: tenantId,
                current_page: 1,
                page_size: 9999
            }).then((res: any) => {
                res.list.forEach((item: any) => {
                    thingsIds.push(item.info_model_id)
                })
                thingsIds = Array.from(new Set(thingsIds))
                thingsIds.forEach((id: number) => {
                    thingsOptions.push({
                        infoModelId: id,
                        infoModelLabel: id + '',
                        options: [],
                    })
                })
                res.list.forEach((item: any) => {
                    thingsOptions.forEach((option: any) => {
                        if (item.info_model_id === option.infoModelId) {
                            option.options.push(item)
                        }
                    })
                })
                addDeviceTypeInfo(thingsIds, thingsOptions)
            }).catch((error: any) => { })
        }
        function addDeviceTypeInfo(thingsIds: any, thingsOptions: any) {
            api.ZWSMappingMgmt.iotInfoModel_getBatchInfomodelIds({
                tenantId: userInfo.tenant_id,
                infoModelIds: thingsIds,
            })
                .then((res: any) => {
                    res.forEach((type: any) => {
                        thingsOptions.forEach((option: any) => {
                            if (type.id === option.infoModelId) {
                                option.infoModelLabel = type.name
                            }
                        })
                    })
                })
                .catch((error: any) => { })
        }
        function thingSelectChange(value: any) {
            emit('update:modelValue', value)
            emit('thingSelectChange', value)
        }
        function reset() {
            thingSelect.value = ''
        }
        return {
            t,
            thingsOptions,
            thingSelect,
            reset,
            thingSelectChange,
        }
    },
})
</script>