import ZWSBaseModule from '../httpBase/ZWSBaseModule'

export type getBatchHmisReq = {
  current_page?: number,
  desc?: string,
  desc_like?: string,
  index?: number,
  name?: string,
  name_like?: string,
  need_auth?: boolean
  page_size?: number,
  tenantId: number,
  userId: number,
}
export type getBatchHmisWebReq = {
  current_page?: number,
  desc?: string,
  desc_like?: string,
  hmiId: number,
  index?: number,
  name?: string,
  name_like?: string,
  nodata?: boolean,
  page_size?: number,
  tenant_id: number,
  type?: string,
  user_id: number
}
class ZWSOutHmi extends ZWSBaseModule {
  /******************************************************** hmi ************************************************************/
  /**
   * 查询组态列表
   * @param {Object} [params]
   * @param {number} params.current_page - 当前页。从第1页开始,Default value : 1
   * @param {number} params.page_size - 每页数量,Default value : 20
   * @param {string} params.desc - 项目描述
   * @param {string} params.desc_like - 项目描述，模糊查询
   * @param {string} params.index - 项目索引
   * @param {string} params.name - 项目名
   * @param {string} params.name_like - 项目名，模糊查询
   * @param {string} params.tenantId - * 租户id
   * @param {string} params.userId  - * 用户id
   * @param {boolean} params.need_auth  - 是否用户权限验证
   * @return {Promise<Response, Error>} - response: { result: true, message: '', jwt: '', data: [{@link Subuser}] }
   */
  static userResource_getBatchHmis({ current_page, page_size, desc, desc_like, index, name, name_like, tenantId, userId, need_auth }: getBatchHmisReq) {
    return this.fetch({
      url: `/out-hmi/tenants/${tenantId}/users/${userId}/hmis`,
      method: 'get',
      params: { current_page, page_size, desc, desc_like, index, name, name_like, need_auth }
    })
  }

  /**
  * 创建组态项目
  * @param {string} tenantId - 租户id
  * @param {string} userId - 用户id
  * @param {Object} body - 组态项目信息
  * @param {string} body.name - 组态名
  * @param {string} body.desc - 组态描述
  * @param {array} body.index - 组态权限
  * @param {array} body.data - 组态数据
  * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
  */
  static createConfiguration({ tenantId, userId, body }: any) {
    return this.fetch({
      url: `/out-hmi/tenants/${tenantId}/users/${userId}/hmis`,
      method: 'post',
      data: body
    })
  }
  /******************************************************** userResource ************************************************************/

  /**
  * 获取用户的组态资源树
  * @param {number} params.tenant_id - 租户id
  * @param {number} params.user_id - 子用户id
  * @param {boolean} params.self - 是否获取当前登录用户的资源
  * @returns 
  */
  static userResource_getUserResourceTree({ tenant_id, user_id, self }: any) {
    return this.fetch({
      url: `/out-hmi/tenants/${tenant_id}/users/${user_id}/resource-tree`,
      method: 'get',
      params: { self }
    }, 'out-hmi:user:query')
  }


  /**
  * 获取指定用户已授权的组态资源
  * @param {number} params.tenantId - 租户id
  * @param {number} params.userId - 子用户id
  * @param {number} params.kind - 资源类型：10-组态项目 20-组态web 30-组态app
  * @returns 
  */
  static userResource_getBindConfiguration({ tenantId, userId, kind }: any) {
    return this.fetch({
      url: `/out-hmi/tenants/${tenantId}/users/${userId}/resource`,
      method: 'get',
      params: { kind }
    }, 'out-hmi:user:query')
  }

  /**
  * 更新指定用户已授权的组态资源
  * @param {number} params.tenantId - 租户id
  * @param {number} params.userId - 子用户id
  * @param {number} params.kind - 资源类型：10-组态项目 20-组态web 30-组态app
  * @param {Array} params.ids - 设备id组成的数组
  * @returns 
  */
  static userResource_updateBindConfiguration({ tenantId, userId, ids, kind }: any) {
    return this.fetch({
      url: `/out-hmi/tenants/${tenantId}/users/${userId}/resource?kind=${kind}`,
      method: 'PUT',
      data: ids,
      // params: { kind }
    }, 'out-hmi:user:update')
  }
  /**
  * 获取指定用户已授权的web工程资源列表
  * @param {number} params.tenantId - 租户id
  * @param {number} params.userId - 子用户id
  * @param {number} params.kind - 资源类型：10-组态项目 20-组态web 30-组态app
  * @returns 
  */
  static userResource_getBindWebs({ tenantId, userId, kind }: any) {
    return this.fetch({
      url: `/out-hmi/tenants/${tenantId}/users/${userId}/resource/webs`,
      method: 'get',
      params: { kind }
    })
  }

  /**
  * 更新指定用户已授权的web工程资源列表
  * @param {number} params.tenantId - 租户id
  * @param {number} params.userId - 子用户id
  * @param {number} params.kind - 资源类型：10-组态项目 20-组态web 30-组态app
  * @param {Array} params.data - 设备id组成的数组
  * @returns 
  */
  static userResource_updateBindWebs({ tenantId, userId, ids, kind }: any) {
    return this.fetch({
      url: `/out-hmi/tenants/${tenantId}/users/${userId}/resource/webs`,
      method: 'PUT',
      data: { ids },
      params: { kind }
    })
  }

  /******************************************************** web ************************************************************/
  /**
  * 枚举web应用
  * @param {number} params.tenantId - 租户id
  * @param {number} params.userId - 子用户id
  * @param {string} params.desc - 应用描述
  * @param {string} params.desc_like - 应用描述，模糊查询
  * @param {number} params.hmiId - 组态工程id
  * @param {number} params.index - 应用显示索引
  * @param {string} params.name - 应用名
  * @param {string} params.name_like - 应用名模糊查询
  * @param {boolean} params.nodata - 裁剪data字段
  * @param {string} params.type - 应用类型标识，web或app
  * @returns 
  */
  static web_getBatchHmisWeb({ current_page, desc, desc_like, hmiId, index, name, name_like, nodata, page_size, tenant_id, type, user_id }: getBatchHmisWebReq) {
    return this.fetch({
      url: `/out-hmi/tenants/${tenant_id}/users/${user_id}/hmis/${hmiId}/web`,
      method: 'get',
      params: { current_page, desc, desc_like, hmiId, index, name, name_like, nodata, page_size, type }
    })
  }

}

export default ZWSOutHmi
