import ZWSHttp from './ZWSHttp'
import ZWSHttpErrorsModule from '../ZWSHttpErrorsModule'
// import { Promise } from 'es6-promise'
// import ZWSApi from '../ZWSApi'

class ZWSBaseModule {
  static errorHandler: any;
  static noHandlerError: any;

  static init(baseURL: any, timeout: any, header: any) {
    ZWSHttp.getInstance(baseURL, timeout, header)
  }

  static fetch(params: any, roleName?: string) {
    if (window && roleName) {
      const userApiRoleListMap = localStorage.getItem('userApiRoleListMap') ? JSON.parse(localStorage.getItem('userApiRoleListMap') as string) : undefined
      if (userApiRoleListMap && !userApiRoleListMap[roleName]) {
        return new Promise((resolve: Function, reject: Function) => {
          reject({
            data: {
              code: "http.403",
              error: "Forbidden",
              message: "不允许访问",
              status: 403
            }
          })
        }).catch((err: any) => {
          return this.handleError(err)
        })
      }
    }

    // 放在实例中设置单个请求超时时间
    // const http = ZWSHttp.getInstance(undefined, params?.timeout || '30000');
    const http = ZWSHttp.getInstance();
    return http.fetch(params).catch((err: any) => {
      return this.handleError(err)
    })
  }

  static setErrorHandler(handler: any, noHandlerError: any) {
    if (typeof handler === 'function') {
      this.errorHandler = handler
      this.noHandlerError = noHandlerError
    } else {
      throw new Error('Error Handler Must Be Function')
    }
  }
  static handleError(err: any) {
    let errDataArr: Array<any> = []
    let errorArr: Array<{
      errorItemCode: string
      errorItemMsg: string
      errItem: any
    }> = []
    if (Array.isArray(err.data?.data)) {
      errDataArr = err.data.data
    } else {
      errDataArr = [err.data]
    }
    errDataArr.forEach((errItem: any) => {
      let errorItemCode = errItem?.code || 'http.' + err.status
      let errorItemMsg = errItem?.message || errorItemCode
      if (errorItemCode && typeof errorItemCode === 'string') {
        let errorModule = errorItemCode.split('.')[0]
        if (errorModule.indexOf('iot-') === -1) {
          errorModule = 'iot-' + errorModule
          errorItemCode = 'iot-' + errorItemCode
        }
        // ${window.languageLocale === 'en' ? '-en' : ''}
        errorModule +=  (localStorage.getItem('language') == 'English' ? '-en' : '')
        errorItemMsg = ZWSHttpErrorsModule[`${errorModule}`]?.[errorItemCode] || errorItemCode
      }
      errorArr.push({
        errorItemCode,
        errorItemMsg,
        errItem
      })
    })
    const errorCode = errorArr[0]?.errorItemCode
    const errorMsg = errorArr[0]?.errorItemMsg
    let output = () => {
      if (this.errorHandler) {
        this.errorHandler(errorCode, err, this.noHandlerError, errorArr)
      }
      return new Promise((resolve, reject) => {
        /* eslint-disable-next-line */
        reject({
          errorCode,
          errorMsg,
          errorArr,
          err
        })
      })
    }
    return output()
  }
}

export default ZWSBaseModule
