/**
    * 世界国家区号列表
     * @param formatDate 标准时间格式
     * @returns 
     */
const ZAreaCodeListTool: any = [
    {
        cn: '中国 ',
        en: 'China',
        areaCode: '86'
    },
    {
        cn: '中国香港 ',
        en: 'Hong Kong',
        areaCode: '852'
    },
    {
        cn: '中国澳门 ',
        en: 'Macau',
        areaCode: '853'
    },
    {
        cn: '中国台湾',
        en: 'Taiwan',
        areaCode: '886'
    },
    {
        cn: '日本 ',
        en: 'Japan',
        areaCode: '81'
    },
    {
        cn: '韩国 ',
        en: 'South Korea',
        areaCode: '82'
    },
    {
        cn: '美国 ',
        en: 'USA or Canada',
        areaCode: '1'
    },
    {
        cn: '阿富汗 ',
        en: 'Afghanistan',
        areaCode: '93'
    },
    {
        cn: '阿尔巴尼亚 ',
        en: 'Albania',
        areaCode: '355'
    },
    {
        cn: '阿尔及利亚 ',
        en: 'Algeria',
        areaCode: '213'
    },
    {
        cn: '萨摩亚 ',
        en: 'American Samoa',
        areaCode: '684'
    },
    {
        cn: '安道尔共和国 ',
        en: 'Andorra',
        areaCode: '376'
    },
    {
        cn: '安哥拉 ',
        en: 'Angola',
        areaCode: '244'
    },
    {
        cn: '安圭拉岛 ',
        en: 'Anguilla',
        areaCode: '1264'
    },
    {
        cn: '南极洲 ',
        en: 'Antarctica',
        areaCode: '672'
    },
    {
        cn: '安提瓜和巴布达 ',
        en: 'Antigua and Barbuda',
        areaCode: '1268'
    },
    {
        cn: '阿根廷 ',
        en: 'Argentina',
        areaCode: '54'
    },
    {
        cn: '亚美尼亚 ',
        en: 'Armenia',
        areaCode: '374'
    },
    {
        cn: '阿鲁巴 ',
        en: 'Aruba',
        areaCode: '297'
    },
    {
        cn: '澳大利亚 ',
        en: 'Australia',
        areaCode: '61'
    },
    {
        cn: '奥地利 ',
        en: 'Austria',
        areaCode: '43'
    },
    {
        cn: '阿塞拜疆 ',
        en: 'Azerbaijan',
        areaCode: '994'
    },
    {
        cn: '巴哈马 ',
        en: 'Bahamas',
        areaCode: '1242'
    },
    {
        cn: '巴林 ',
        en: 'Bahrain',
        areaCode: '973'
    },
    {
        cn: '孟加拉国 ',
        en: 'Bangladesh',
        areaCode: '880'
    },
    {
        cn: '巴巴多斯 ',
        en: 'Barbados',
        areaCode: '1246'
    },
    {
        cn: '白俄罗斯 ',
        en: 'Belarus',
        areaCode: '375'
    },
    {
        cn: '比利时 ',
        en: 'Belgium',
        areaCode: '32'
    },
    {
        cn: '伯利兹城 ',
        en: 'Belize',
        areaCode: '501'
    },
    {
        cn: '贝宁 ',
        en: 'Benin',
        areaCode: '229'
    },
    {
        cn: '百慕大 ',
        en: 'Bermuda',
        areaCode: '1441'
    },
    {
        cn: '不丹 ',
        en: 'Bhutan',
        areaCode: '975'
    },
    {
        cn: '玻利维亚 ',
        en: 'Bolivia',
        areaCode: '591'
    },
    {
        cn: '波斯尼亚和黑塞哥维那 ',
        en: 'Bosnia and Herzegovina',
        areaCode: '387'
    },
    {
        cn: '博茨瓦纳 ',
        en: 'Botswana',
        areaCode: '267'
    },
    {
        cn: '巴西 ',
        en: 'Brazil',
        areaCode: '55'
    },
    {
        cn: '英属印度洋领地 ',
        en: 'British Indian Ocean Territory',
        areaCode: '246'
    },
    {
        cn: '文莱达鲁萨兰国 ',
        en: 'Brunei Darussalam',
        areaCode: '673'
    },
    {
        cn: '保加利亚 ',
        en: 'Bulgaria',
        areaCode: '359'
    },
    {
        cn: '布基纳法索 ',
        en: 'Burkina Faso',
        areaCode: '226'
    },
    {
        cn: '布隆迪 ',
        en: 'Burundi',
        areaCode: '257'
    },
    {
        cn: '柬埔寨 ',
        en: 'Cambodia',
        areaCode: '855'
    },
    {
        cn: '喀麦隆 ',
        en: 'Cameroon',
        areaCode: '237'
    },
    {
        cn: '佛得角 ',
        en: 'Cape Verde',
        areaCode: '238'
    },
    {
        cn: '开曼群岛 ',
        en: 'Cayman Islands',
        areaCode: '1345'
    },
    {
        cn: '中非共和国 ',
        en: 'Central African Republic',
        areaCode: '236'
    },
    {
        cn: '乍得 ',
        en: 'Chad',
        areaCode: '235'
    },
    {
        cn: '智利 ',
        en: 'Chile',
        areaCode: '56'
    },
    {
        cn: '哥伦比亚',
        en: 'Colombia',
        areaCode: '57'
    },
    {
        cn: '科摩罗 ',
        en: 'Comoros',
        areaCode: '269'
    },
    {
        cn: '刚果 ',
        en: 'Congo',
        areaCode: '242'
    },
    {
        cn: '刚果民主共和国',
        en: 'Congo,The Democratic Republic Of The',
        areaCode: '243'
    },
    {
        cn: '库克群岛 ',
        en: 'Cook Islands',
        areaCode: '682'
    },
    {
        cn: '哥斯达黎加 ',
        en: 'Costa Rica',
        areaCode: '506'
    },
    {
        cn: '科特迪瓦 ',
        en: 'Cote D Ivoire',
        areaCode: '225'
    },
    {
        cn: '克罗地亚 ',
        en: 'Croatia',
        areaCode: '385'
    },
    {
        cn: '古巴 ',
        en: 'Cuba',
        areaCode: '53'
    },
    {
        cn: '塞浦路斯 ',
        en: 'Cyprus',
        areaCode: '357'
    },
    {
        cn: '捷克 ',
        en: 'Czech Republic',
        areaCode: '420'
    },
    {
        cn: '丹麦 ',
        en: 'Denmark',
        areaCode: '45'
    },
    {
        cn: '吉布提 ',
        en: 'Djibouti',
        areaCode: '253'
    },
    {
        cn: '多米尼克国 ',
        en: 'Dominica',
        areaCode: '1767'
    },
    {
        cn: '多米尼加共和国 ',
        en: 'Dominican Republic',
        areaCode: '1849'
    },
    {
        cn: '东帝汶 ',
        en: 'East Timor',
        areaCode: '670'
    },
    {
        cn: '厄瓜多尔 ',
        en: 'Ecuador',
        areaCode: '593'
    },
    {
        cn: '埃及 ',
        en: 'Egypt',
        areaCode: '20'
    },
    {
        cn: '萨尔瓦多 ',
        en: 'El Salvador',
        areaCode: '503'
    },
    {
        cn: '赤道几内亚 ',
        en: 'Equatorial Guinea',
        areaCode: '240'
    },
    {
        cn: '爱沙尼亚 ',
        en: 'Estonia',
        areaCode: '372'
    },
    {
        cn: '埃塞俄比亚 ',
        en: 'Ethiopia',
        areaCode: '251'
    },
    {
        cn: '福克兰群岛',
        en: 'Falkland Islands ',
        areaCode: '500'
    },
    {
        cn: '法罗群岛 ',
        en: 'Faroe Islands',
        areaCode: '298'
    },
    {
        cn: '斐济 ',
        en: 'Fiji',
        areaCode: '679'
    },
    {
        cn: '芬兰 ',
        en: 'Finland',
        areaCode: '358'
    },
    {
        cn: '法国 ',
        en: 'France',
        areaCode: '33'
    },
    {
        cn: '法属圭亚那 ',
        en: 'French Guiana',
        areaCode: '594'
    },
    {
        cn: '法属玻里尼西亚 ',
        en: 'French Polynesia',
        areaCode: '689'
    },
    {
        cn: '加蓬 ',
        en: 'Gabon',
        areaCode: '241'
    },
    {
        cn: '冈比亚 ',
        en: 'Gambia',
        areaCode: '220'
    },
    {
        cn: '格鲁吉亚 ',
        en: 'Georgia',
        areaCode: '995'
    },
    {
        cn: '德国 ',
        en: 'Germany',
        areaCode: '49'
    },
    {
        cn: '加纳 ',
        en: 'Ghana',
        areaCode: '233'
    },
    {
        cn: '直布罗陀 ',
        en: 'Gibraltar',
        areaCode: '350'
    },
    {
        cn: '希腊 ',
        en: 'Greece',
        areaCode: '30'
    },
    {
        cn: '格林纳达 ',
        en: 'Grenada',
        areaCode: '1473'
    },
    {
        cn: '瓜德罗普岛 ',
        en: 'Guadeloupe',
        areaCode: '590'
    },
    {
        cn: '关岛 ',
        en: 'Guam',
        areaCode: '1671'
    },
    {
        cn: '危地马拉 ',
        en: 'Guatemala',
        areaCode: '502'
    },
    {
        cn: '几内亚 ',
        en: 'Guinea',
        areaCode: '224'
    },
    {
        cn: '几内亚比绍 ',
        en: 'Guinea-Bissau',
        areaCode: '245'
    },
    {
        cn: '圭亚那 ',
        en: 'Guyana',
        areaCode: '592'
    },
    {
        cn: '海地 ',
        en: 'Haiti',
        areaCode: '509'
    },
    {
        cn: '洪都拉斯 ',
        en: 'Honduras',
        areaCode: '504'
    },
    {
        cn: '匈牙利 ',
        en: 'Hungary',
        areaCode: '36'
    },
    {
        cn: '冰岛 ',
        en: 'Iceland',
        areaCode: '354'
    },
    {
        cn: '印度 ',
        en: 'India',
        areaCode: '91'
    },
    {
        cn: '印度尼西亚 ',
        en: 'Indonesia',
        areaCode: '62'
    },
    {
        cn: '伊朗 ',
        en: 'Iran ',
        areaCode: '98'
    },
    {
        cn: '伊拉克 ',
        en: 'Iraq',
        areaCode: '964'
    },
    {
        cn: '爱尔兰 ',
        en: 'Ireland',
        areaCode: '353'
    },
    {
        cn: '以色列 ',
        en: 'Israel',
        areaCode: '972'
    },
    {
        cn: '意大利 ',
        en: 'Italy',
        areaCode: '39'
    },
    {
        cn: '牙买加 ',
        en: 'Jamaica',
        areaCode: '1876'
    },
    {
        cn: '约旦 ',
        en: 'Jordan',
        areaCode: '962'
    },
    {
        cn: '肯尼亚 ',
        en: 'Kenya',
        areaCode: '254'
    },
    {
        cn: '科威特 ',
        en: 'Kuwait',
        areaCode: '965'
    },
    {
        cn: '吉尔吉斯 ',
        en: 'Kyrgyzstan',
        areaCode: '996'
    },
    {
        cn: '拉脱维亚 ',
        en: 'Latvia',
        areaCode: '371'
    },
    {
        cn: '黎巴嫩 ',
        en: 'Lebanon',
        areaCode: '961'
    },
    {
        cn: '莱索托 ',
        en: 'Lesotho',
        areaCode: '266'
    },
    {
        cn: '利比里亚 ',
        en: 'Liberia',
        areaCode: '231'
    },
    {
        cn: '利比亚 ',
        en: 'Libyan Arab Jamahiriya',
        areaCode: '218'
    },
    {
        cn: '列支敦士登 ',
        en: 'Liechtenstein',
        areaCode: '423'
    },
    {
        cn: '立陶宛 ',
        en: 'Lithuania',
        areaCode: '370'
    },
    {
        cn: '卢森堡 ',
        en: 'Luxembourg',
        areaCode: '352'
    },
    {
        cn: '马达加斯加 ',
        en: 'Madagascar',
        areaCode: '261'
    },
    {
        cn: '马拉维 ',
        en: 'Malawi',
        areaCode: '265'
    },
    {
        cn: '马来西亚 ',
        en: 'Malaysia',
        areaCode: '60'
    },
    {
        cn: '马尔代夫 ',
        en: 'Maldives',
        areaCode: '960'
    },
    {
        cn: '马里 ',
        en: 'Mali',
        areaCode: '223'
    },
    {
        cn: '马尔他 ',
        en: 'Malta',
        areaCode: '356'
    },
    {
        cn: '马提尼克岛 ',
        en: 'Martinique',
        areaCode: '596'
    },
    {
        cn: '毛里塔尼亚 ',
        en: 'Mauritania',
        areaCode: '222'
    },
    {
        cn: '毛里求斯',
        en: 'Mauritius',
        areaCode: '230'
    },
    {
        cn: '马约特 ',
        en: 'Mayotte',
        areaCode: '262'
    },
    {
        cn: '墨西哥 ',
        en: 'Mexico',
        areaCode: '52'
    },
    {
        cn: '密克罗尼西亚 ',
        en: 'Micronesia',
        areaCode: '691'
    },
    {
        cn: '摩尔多瓦 ',
        en: 'Moldova',
        areaCode: '373'
    },
    {
        cn: '摩纳哥 ',
        en: 'Monaco',
        areaCode: '377'
    },
    {
        cn: '外蒙古 ',
        en: 'Mongolia',
        areaCode: '976'
    },
    {
        cn: '黑山共和国 ',
        en: 'Montenegro',
        areaCode: '382'
    },
    {
        cn: '蒙特塞拉特 ',
        en: 'Montserrat',
        areaCode: '1664'
    },
    {
        cn: '摩洛哥 ',
        en: 'Morocco',
        areaCode: '212'
    },
    {
        cn: '莫桑比克 ',
        en: 'Mozambique',
        areaCode: '258'
    },
    {
        cn: '缅甸 ',
        en: 'Myanmar',
        areaCode: '95'
    },
    {
        cn: '那米比亚 ',
        en: 'Namibia',
        areaCode: '264'
    },
    {
        cn: '瑙鲁 ',
        en: 'Nauru',
        areaCode: '674'
    },
    {
        cn: '尼泊尔 ',
        en: 'Nepal',
        areaCode: '977'
    },
    {
        cn: '荷兰 ',
        en: 'Netherlands',
        areaCode: '31'
    },
    {
        cn: '荷兰安的列斯群岛 ',
        en: 'Netherlands Antilles',
        areaCode: '599'
    },
    {
        cn: '新喀里多尼亚 ',
        en: 'New Caledonia',
        areaCode: '687'
    },
    {
        cn: '新西兰 ',
        en: 'New Zealand',
        areaCode: '64'
    },
    {
        cn: '尼加拉瓜 ',
        en: 'Nicaragua',
        areaCode: '505'
    },
    {
        cn: '尼日尔 ',
        en: 'Niger',
        areaCode: '227'
    },
    {
        cn: '尼日利亚 ',
        en: 'Nigeria',
        areaCode: '234'
    },
    {
        cn: '诺福克岛 ',
        en: 'Norfolk Island',
        areaCode: '6723'
    },
    {
        cn: '朝鲜 ',
        en: 'North Korea',
        areaCode: '850'
    },
    {
        cn: '北马里亚纳群岛 ',
        en: 'Northern Mariana Islands',
        areaCode: '1670'
    },
    {
        cn: '挪威 ',
        en: 'Norway',
        areaCode: '47'
    },
    {
        cn: '阿曼 ',
        en: 'Oman',
        areaCode: '968'
    },
    {
        cn: '巴基斯坦 ',
        en: 'Pakistan',
        areaCode: '92'
    },
    {
        cn: '帛琉 ',
        en: 'Palau',
        areaCode: '680'
    },
    {
        cn: '巴勒斯坦 ',
        en: 'Palestine',
        areaCode: '970'
    },
    {
        cn: '巴拿马 ',
        en: 'Panama',
        areaCode: '507'
    },
    {
        cn: '巴布亚新几内亚 ',
        en: 'Papua New Guinea',
        areaCode: '675'
    },
    {
        cn: '巴拉圭 ',
        en: 'Paraguay',
        areaCode: '595'
    },
    {
        cn: '秘鲁 ',
        en: 'Peru',
        areaCode: '51'
    },
    {
        cn: '菲律宾共和国 ',
        en: 'Philippines',
        areaCode: '63'
    },
    {
        cn: '波兰 ',
        en: 'Poland',
        areaCode: '48'
    },
    {
        cn: '葡萄牙 ',
        en: 'Portugal',
        areaCode: '351'
    },
    {
        cn: '波多黎各 ',
        en: 'Puerto Rico',
        areaCode: '1787'
    },
    {
        cn: '卡塔尔 ',
        en: 'Qatar',
        areaCode: '974'
    },
    {
        cn: '罗马尼亚 ',
        en: 'Romania',
        areaCode: '40'
    },
    {
        cn: '俄罗斯联邦 ',
        en: 'Russian Federation',
        areaCode: '7'
    },
    {
        cn: '卢旺达 ',
        en: 'Rwanda',
        areaCode: '250'
    },
    {
        cn: '圣马力诺共和国 ',
        en: 'San Marino',
        areaCode: '378'
    },
    {
        cn: '沙特阿拉伯 ',
        en: 'Saudi Arabia',
        areaCode: '966'
    },
    {
        cn: '塞内加尔 ',
        en: 'Senegal',
        areaCode: '221'
    },
    {
        cn: '塞尔维亚共和国 ',
        en: 'Serbia',
        areaCode: '381'
    },
    {
        cn: '塞舌尔 ',
        en: 'Seychelles',
        areaCode: '248'
    },
    {
        cn: '塞拉利昂 ',
        en: 'Sierra Leone',
        areaCode: '232'
    },
    {
        cn: '新加坡 ',
        en: 'Singapore',
        areaCode: '65'
    },
    {
        cn: '斯洛伐克 ',
        en: 'Slovakia ',
        areaCode: '421'
    },
    {
        cn: '斯洛文尼亚 ',
        en: 'Slovenia',
        areaCode: '386'
    },
    {
        cn: '索罗门群岛 ',
        en: 'Solomon Islands',
        areaCode: '677'
    },
    {
        cn: '索马里 ',
        en: 'Somalia',
        areaCode: '252'
    },
    {
        cn: '南非 ',
        en: 'South Africa',
        areaCode: '27'
    },
    {
        cn: '西班牙 ',
        en: 'Spain',
        areaCode: '34'
    },
    {
        cn: '斯里兰卡 ',
        en: 'Sri Lanka',
        areaCode: '94'
    },
    {
        cn: '苏丹 ',
        en: 'Sudan',
        areaCode: '249'
    },
    {
        cn: '苏里南 ',
        en: 'Suriname',
        areaCode: '597'
    },
    {
        cn: '斯威士兰 ',
        en: 'Swaziland',
        areaCode: '268'
    },
    {
        cn: '瑞典 ',
        en: 'Sweden',
        areaCode: '46'
    },
    {
        cn: '瑞士 ',
        en: 'Switzerland',
        areaCode: '41'
    },
    {
        cn: '叙利亚 ',
        en: 'Syrian Arab Republic',
        areaCode: '963'
    },
    {
        cn: '塔吉克 ',
        en: 'Tajikistan',
        areaCode: '992'
    },
    {
        cn: '坦桑尼亚 ',
        en: 'Tanzania',
        areaCode: '255'
    },
    {
        cn: '泰国 ',
        en: 'Thailand',
        areaCode: '66'
    },
    {
        cn: '多哥 ',
        en: 'Togo',
        areaCode: '228'
    },
    {
        cn: '汤加 ',
        en: 'Tonga',
        areaCode: '676'
    },
    {
        cn: '特立尼达和多巴哥 ',
        en: 'Trinidad and Tobago',
        areaCode: '1868'
    },
    {
        cn: '突尼斯 ',
        en: 'Tunisia',
        areaCode: '216'
    },
    {
        cn: '土耳其 ',
        en: 'Turkey',
        areaCode: '90'
    },
    {
        cn: '土库曼 ',
        en: 'Turkmenistan',
        areaCode: '993'
    },
    {
        cn: '土克斯及开科斯群岛 ',
        en: 'Turks and Caicos Islands',
        areaCode: '1809'
    },
    {
        cn: '乌干达 ',
        en: 'Uganda',
        areaCode: '256'
    },
    {
        cn: '乌克兰 ',
        en: 'Ukraine',
        areaCode: '380'
    },
    {
        cn: '阿拉伯联合酋长国 ',
        en: 'United Arab Emirates',
        areaCode: '971'
    },
    {
        cn: '英国 ',
        en: 'United Kingdom',
        areaCode: '44'
    },
    {
        cn: '乌拉圭 ',
        en: 'Uruguay',
        areaCode: '598'
    },
    {
        cn: '乌兹别克斯坦 ',
        en: 'Uzbekistan',
        areaCode: '998'
    },
    {
        cn: '瓦努阿图 ',
        en: 'Vanuatu',
        areaCode: '678'
    },
    {
        cn: '委内瑞拉 ',
        en: 'Venezuela',
        areaCode: '58'
    },
    {
        cn: '越南 ',
        en: 'Vietnam',
        areaCode: '84'
    },
    {
        cn: '西撒哈拉 ',
        en: 'Western Sahara',
        areaCode: '685'
    },
    {
        cn: '也门 ',
        en: 'Yemen',
        areaCode: '967'
    },
    {
        cn: '赞比亚 ',
        en: 'Zambia',
        areaCode: '260'
    },
    {
        cn: '津巴布韦 ',
        en: 'Zimbabwe',
        areaCode: '263'
    },
    {
        cn: '老挝 ',
        en: 'Lao People s Democratic Republic',
        areaCode: '856'
    },
    {
        cn: '马其顿 ',
        en: 'The Republic of Macedonia',
        areaCode: '389'
    },
    {
        cn: '圣基茨和尼维斯',
        en: 'The Federation of Saint Kitts and Nevis',
        areaCode: '1869'
    },
    {
        cn: '圣卢西亚岛 ',
        en: 'Santa Luzia Island',
        areaCode: '1758'
    },
    {
        cn: '圣文森特和格林纳丁斯',
        en: 'Saint Vincent and the Grenadines',
        areaCode: '1784'
    },
    {
        cn: '圣多美和普林西比 ',
        en: 'Sao Tome and Principe',
        areaCode: '239'
    },
    {
        cn: '南苏丹共和国 ',
        en: 'The Republic of South Sudan',
        areaCode: '211'
    }
]

export default ZAreaCodeListTool