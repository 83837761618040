<template>
  <div v-if="showContent && notice?.content" class="z-announcement-container">
    <div>
      <slot name="announcement-icon">
        <el-icon :size="18" color="#80b33f">
          <BellFilled />
        </el-icon>
      </slot>
      <span class="title">{{ notice.title }}：</span>
    </div>
    <ZTextScroll class="announcement-content" :text="notice.content" v-bind="$attrs" @click="handleAnnouncementClicked">
    </ZTextScroll>
    <div>
      <el-icon @click="closeAnnouncementDialogShow = true" class="circle-close" :size="18">
        <CircleClose />
      </el-icon>
    </div>
  </div>
  <ZDialogCtrl title="提示" v-model="closeAnnouncementDialogShow" width="400px" :buttonList="buttonList"
    @button-clicked="handleCloseButtonClicked">
    请选择{{ notice.title }}的关闭方式
  </ZDialogCtrl>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { CircleClose, BellFilled } from '@element-plus/icons-vue'
import ZDialogCtrl from '../../ctrl/ZDialogCtrl.vue'
import ZTextScroll from '../../ctrl/ZTextScroll.vue'

const props = defineProps({
  // 回显公告是否展示
  modelValue: {
    type: Boolean
  },
  // 平台
  platform: {
    type: String
  },
  // 公告长度
  announcementWidth: {
    text: String,
    default: '1000px'
  },
  // 公告高度
  announcementHeight: {
    text: String,
    default: "40px"
  },
  // 鼠标悬浮的背景色
  hoverBackgroundColor: {
    text: String,
    default: '#f2f7ec'
  }
})
const store = useStore()
const userInfo = computed(() => store.getters.userInfo)
const emit = defineEmits(['update:modelValue', 'onAnnouncementClicked'])

const showContent = ref<boolean>(true)
const notice = ref<any>()
const getUserNotice = () => {
  if (props.platform) {
    window.ZWSApi.ZWSOutWeb.notice_getUserNoticeByUser({
      currentTime: Date.now(),
      platform: props.platform,
      userId: userInfo.value.id
    })
      .then((result: any) => {
        notice.value = {
          ...result,
          content: result.content?.replace(/(\r)|(\n)|(\t)/g, '')
        }
        emit('update:modelValue', Boolean(showContent.value && notice.value?.content))
      })
      .catch((err: any) => {
        console.log(err)
      })
  }
}
const handleAnnouncementClicked = () => {
  emit('onAnnouncementClicked')
}

const buttonList = [
  {
    text: '暂时关闭'
  },
  {
    text: '此次公告不再提示',
    type: 'danger'
  }
]
const closeAnnouncementDialogShow = ref<boolean>(false)
const handleCloseButtonClicked = (val: any) => {
  if (val.index === 1) {
    window.ZWSApi.ZWSOutWeb.notice_updateUserNotice({ noticeId: notice.value.id, userId: userInfo.value.id, noLongerShow: true })
  }
  showContent.value = false
  closeAnnouncementDialogShow.value = false
  emit('update:modelValue', Boolean(showContent.value && notice.value?.content))
}

getUserNotice()
</script>

<style lang="less" scoped>
.z-announcement-container {
  display: flex;
  padding: 0 1.5em;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  line-height: 24px;
  width: v-bind(announcementWidth);
  border-radius: 6px;
  // background-color: rgba(0, 0, 0, 0.1);
  background-color: #F0F2F5;
  color: #333333;
  font-size: 12px;

  .title {
    margin-left: 0.5em;
  }

  .el-icon {
    vertical-align: text-bottom;
  }

  .circle-close {
    cursor: pointer;

    & :hover {
      color: #f23c3c;
    }
  }

  .announcement-content {
    flex: 1;
    margin: 0 1.5em 0 0.5em;
    cursor: pointer;
  }
}

.z-announcement-container:hover {
  background-color: v-bind(hoverBackgroundColor);
}
</style>
