
import ZWSConnection from './httpModules/ZWSConnection'
import ZWSHttp from './httpBase/ZWSHttp'
import ZWSTenant from './httpModules/ZWSTenant'
import ZWSIotMappingManager from './httpModules/ZWSIotMappingManager'
import ZWSFunctionTest from './httpModules/ZWSFunctionTest'
import ZWSThings from './httpModules/ZWSThings'
import ZWSDataMgmt from './httpModules/ZWSDataMgmt'
import ZWSAlertMgmt from './httpModules/ZWSAlertMgmt'
import ZWSAlertDataMgmt from './httpModules/ZWSAlertDataMgmt'
import ZWSMappingMgmt from './httpModules/ZWSMappingMgmt'
import ZWSOutProject from './httpModules/ZWSOutProject'
import ZWSOutGroupMgmt from './httpModules/ZWSOutGroupMgmt'
import ZWSOutWeb from './httpModules/ZWSOutWeb'
import ZWSOutFirmwareMgmt from './httpModules/ZWSOutFirmwareMgmt'
import ZWSJobMgmt from './httpModules/ZWSJobMgmt'
import ZWSControl from './httpModules/ZWSControl'
import ZWSOutHmi from './httpModules/ZWSOutHmi'
import ZWSNotification from './httpModules/ZWSNotification'
import ZWSSingleZigbee from './httpModules/ZWSSingleZigbee'
import ZWSOutWrapper from './httpModules/ZWSOutWrapper'
import ZWSSingleCandtu from './httpModules/ZWSSingleCandtu'
import ZWSSingleLoRaWAN from './httpModules/ZWSSingleLoRaWAN'
import ZWSSingleOpcua from './httpModules/ZWSSingleOpcua'
import ZWSOutBilling from "./httpModules/ZWSOutBilling";
import ZWSOutFileManager from './httpModules/ZWSOutFileManager'

declare global {
    interface Window {
        ZWSApi: any
    }
}

let ZWSApi = {
    init: function (baseURL: any, timeout?: any, header?: any, adapter?: any, tokenStorage?: string) {
        ZWSHttp.getInstance(baseURL, timeout, header, adapter, tokenStorage)
    },
    setErrorHandler: function (handler: any, noHandlerError: any = {}) {
        ZWSConnection.setErrorHandler(handler, noHandlerError.ZWSFirmware || false)
        ZWSTenant.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSIotMappingManager.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSFunctionTest.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSThings.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSDataMgmt.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSAlertMgmt.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSMappingMgmt.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSOutGroupMgmt.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSOutWeb.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSOutFirmwareMgmt.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSJobMgmt.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSControl.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSOutHmi.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSNotification.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSSingleZigbee.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSOutWrapper.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSOutProject.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSAlertDataMgmt.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSSingleCandtu.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSSingleLoRaWAN.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSSingleOpcua.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSOutBilling.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
        ZWSOutFileManager.setErrorHandler(handler, noHandlerError.ZWSWebApp || false)
    },
    ZWSConnection,
    ZWSTenant,
    ZWSIotMappingManager,
    ZWSFunctionTest,
    ZWSThings,
    ZWSDataMgmt,
    ZWSAlertMgmt,
    ZWSMappingMgmt,
    ZWSOutProject,
    ZWSOutGroupMgmt,
    ZWSOutWeb,
    ZWSOutFirmwareMgmt,
    ZWSJobMgmt,
    ZWSControl,
    ZWSOutHmi,
    ZWSNotification,
    ZWSSingleZigbee,
    ZWSOutWrapper,
    ZWSAlertDataMgmt,
    ZWSSingleCandtu,
    ZWSSingleLoRaWAN,
    ZWSSingleOpcua,
    ZWSOutBilling,
    ZWSOutFileManager
}

export default ZWSApi