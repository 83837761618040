<template>
  <el-config-provider :locale="eleLocale">
    <router-view></router-view>
  </el-config-provider>
</template>
<script lang="ts">
import { defineComponent, computed, watch } from 'vue'
import { ElConfigProvider } from 'element-plus'
import { useStore } from 'vuex'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import lang_en from 'element-plus/lib/locale/lang/en'
import { useI18n } from 'vue-i18n'
import { setBaseUrl } from '@zws/axios-fetch'

export default defineComponent({
  name: 'App',
  components: {
    ElConfigProvider
  },
  setup() {
    const store = useStore()
    // 要改
    let baseURL = '/api'
    if (window.location.host.indexOf('localhost') >= 0) {
      baseURL = '/api'
    } else {
      // baseURL = 'http://' + window.location.host + '/v1'
      // baseURL = window.location.origin + '/v1'
      baseURL = '/v1'
    }
    window.ZWSApi.init(baseURL)
    const { locale, t } = useI18n()
    const eleLocale = computed(() => {
      return locale.value === 'en' ? lang_en : zhCn
    })

    // setErrorHandler((errorCode: any, err: any) => {
    //   remarkOperationMonitoring({
    //     platform: 'ZWS-Candtu',
    //     module: '异常监控',
    //     action: '接口异常',
    //     params: JSON.stringify(err)
    //   })
    // }, undefined)

    function init() {
      setBaseUrl(baseURL)
    }

    init()

    watch(
      [() => locale.value, () => store.getters.globalPermissionInfo, () => store.getters.tenantCustomizationInfo],
      () => {
        const globalPermissionInfo = store.getters.globalPermissionInfo
        const tenantCustomizationInfo = store.getters.tenantCustomizationInfo
        // 全局监听设置title
        document.title = tenantCustomizationInfo?.systemName || globalPermissionInfo?.cloudname || t('header.system')
        // 设置 icon
        // const link = document.querySelector<HTMLLinkElement>('link[rel="icon"]')
        // if (link && tenantCustomizationInfo?.systemLogo) {
        //   link.href = tenantCustomizationInfo.systemLogo
        // } else if (link && globalPermissionInfo?.homepageLogo) {
        //   link.href = globalPermissionInfo.homepageLogo
        // }
      },
      {
        immediate: true
      }
    )
    return {
      // locale: zhCn
      eleLocale
    }
  }
})
</script>
<style type="text/css">
/*
	找到html标签、body标签，和挂载的标签
	都给他们统一设置样式
*/
html,
body,
div,
span,
input,
textarea,
#app,
.el-container {
  font-family: 'microsoftYaHei', Avenir, Helvetica, Arial, sans-serif !important;
}

html,
body,
#app,
.el-container {
  font-family: 'microsoftYaHei', Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  background-color: #99b4ce;
  /* background: url('../../assets/images/global/bg.png') repeat; */
  /*设置内部填充为0，几个布局元素之间没有间距*/
  padding: 0px;
  /*外部间距也是如此设置*/
  margin: 0px;
  /*统一设置高度为100%*/
  height: 100%;
  overflow: hidden;
}
</style>
