<template>
    <div class="popover-wrapper">
        <el-popover v-model:visible="popoverVisible" placement="bottom" trigger="click" :width="320">
            <template #reference>
                <el-icon v-if="type == 'default'" class="check-table-ctrl-point">
                    <CaretBottom />
                </el-icon>
                <slot v-else="type=='custom'"></slot>
            </template>
            <div>
                <el-input style="margin-bottom: 10px;" v-model="searchInput"
                    :placeholder="$t_('zws.ZTablePaginationCtrl.search')" @input="searchValueChange" />
                <el-table ref="multipleTableRef" :data="tableData" :width="320" :height="350" :show-header="false"
                    @select="selectCheckBox">
                    <el-table-column type="selection" width="42" />
                    <el-table-column property="label" label="label" />
                </el-table>
                <el-pagination :hide-on-single-page="true" small layout="prev, pager, next" :total="totalDatas.length"
                    :current-page="currentPage" :page-size="props.pageSize" @current-change="currentPageChange" />
            </div>
            <div class="check-table-ctrl-save">
                <slot name="btnGroupPrefix"></slot>
                <el-button type="primary" plain size="small" @click="showSelected">{{
                    isShowSelected ? ($t_('zws.ZCheckTablePaginationCtrl.showAll') || '显示全部') :
                    ($t_('zws.ZCheckTablePaginationCtrl.showSelected') || '显示选中')
                }}</el-button>
                <el-button type="primary" plain size="small" @click="save" v-if="showSaveBtn">{{
                    $t_('zws.ZTablePaginationCtrl.save') }}</el-button>
                <slot name="btnGroupSuffix"></slot>
            </div>
        </el-popover>
    </div>
</template>

<script lang="ts" setup>
import {
    ref, defineProps, reactive, watch, onMounted, nextTick, defineEmits,
    defineExpose
} from 'vue'
import { ElTable, ElIcon } from 'element-plus'
import { CaretBottom } from "@element-plus/icons-vue"
import { useLocale } from '../hooks/useLocale'


const emit = defineEmits(['checkBoxChange', 'save']);

const { t } = useLocale()
let $t_ = t
const props = defineProps({
    type: {
        type: String,
        default: 'default'
    },
    data: {
        type: Array,
        default: []
    },
    channels: {
        type: Array,
        default: []
    },
    pageSize: {
        type: Number,
        default: 10
    },
    showSaveBtn: {
        type: Boolean,
        default: true
    }
})

const multipleTableRef = ref<InstanceType<typeof ElTable>>()
const popoverVisible = ref<boolean>(false)
const tableData: any = reactive([])
const currentPage = ref<number>(1)
var sourceDatas = <any>[]
var totalDatas = ref(<any>[])

watch(() => [...props.channels], (now, old) => {
    let start = (currentPage.value - 1) * props.pageSize
    let end = currentPage.value * props.pageSize >= now.length ? now.length : currentPage.value * props.pageSize
    setRowSelection(start, end)
})

watch(() => props.data, (newVal) => {
    nextTick(() => {
        sourceDatas.splice(0, sourceDatas.length)
        for (let i = 0; i < newVal.length; i++) {
            let item: any = newVal[i]
            item.index = i
            sourceDatas.push(item)
        }
        totalDatas.value = [...sourceDatas]
        setTableData()
    })
}, {
    immediate: true,
    deep: true
})

onMounted(() => {
    nextTick(() => {
        sourceDatas.splice(0, sourceDatas.length)
        for (let i = 0; i < props.data.length; i++) {
            let item: any = props.data[i]
            item.index = i
            sourceDatas.push(item)
        }
        totalDatas.value = [...sourceDatas]
        setTableData()
    })
})

const setTableData = () => {
    tableData.splice(0, tableData.length)
    let start = (currentPage.value - 1) * props.pageSize
    let end = currentPage.value * props.pageSize >= totalDatas.length ? totalDatas.length : currentPage.value * props.pageSize
    tableData.push(...totalDatas.value.slice(start, end))
    setRowSelection(start, end)
}

const setRowSelection = (s: number, e: number) => {
    for (let i = s; i < e; i++) {
        let row = totalDatas.value[i]
        if (row) {
            let index = row.index
            let select = props.channels[index] ? true : false
            multipleTableRef.value!.toggleRowSelection(row, select)
        }
    }
}

const currentPageChange = (value: any) => {
    currentPage.value = value
    setTableData()
}

const selectCheckBox = (selection: any, row: any) => {
    let select = selection.indexOf(row) > -1
    props.channels[row.index] = select
    emit('checkBoxChange', select, row.index);
}

const isShowSelected = ref<boolean>(false)
const showSelected = () => {
    isShowSelected.value = !isShowSelected.value
    let datas = <any>[]
    if (isShowSelected.value) {
        for (let i = 0; i < sourceDatas.length; i++) {
            let item = sourceDatas[i] as any
            if (props.channels[i]) {
                datas.push(item)
            }
        }
    } else {
        datas.push(...sourceDatas)
    }
    searchInput.value = ''
    currentPage.value = 1
    totalDatas.value = [...datas]
    setTableData()
}

const save = () => {
    popoverVisible.value = false
    emit('save');
}

const searchInput = ref<String>('')

const searchValueChange = (value: any) => {
    let datas = <any>[]
    for (let i = 0; i < sourceDatas.length; i++) {
        let item = sourceDatas[i] as any
        if (item.label.indexOf(value) > -1) {
            datas.push(item)
        }
    }
    currentPage.value = 1
    totalDatas.value = [...datas]
    setTableData()
}

defineExpose({ $t_ })
</script>

<style lang="less" scoped>
.popover-wrapper {
    .check-table-ctrl-point {
        position: absolute;
        left: 5px;
        top: 5px;
        font-size: 12px;
        color: #767778;
        cursor: pointer;
        border: 1px solid #e9e9eb;
        z-index: 9;
    }
}

.check-table-ctrl-save {
    text-align: center;
    margin-top: 12px;
}
</style>