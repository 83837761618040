/**
 * 操作日志
 */
export default {
  logContent: '日志内容',
  handleLog: '操作日志',
  owningUser: '所属用户',
  operatingMode: '操作方式',
  logTime: '日志时间'
}
