<template>
    <div v-for="(item,index) in data" :key="index" :label="item.label">
        <div class="z-form-list" :style="item?.style">
            <div class="z-form-list-label" :style="{width: labelWidth}">
                <span>{{item.label+"："}}</span>
            </div>
            <div v-if="!item.isSlot" class="z-form-list-content">
                <span>{{ item.content || dataValue?.[item.prop] }}</span>
                <span v-if="item.hasEditBtn" class="z-form-list-content-edit">
                    <el-button type="text" icon="el-icon-edit" @click="editClicked(index, item)"></el-button>
                </span>
                
            </div>
            <div v-else class="z-form-list-content">
                <slot :name="item.prop" :data="item"></slot>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType, reactive, watch } from 'vue'
interface ListItem {
    label: string
    prop: string
    content: string
    hasEditBtn: boolean
    isSlot: boolean
    style?: string
}
export default defineComponent({
    emits: ['editClicked'],
    props: {
        data: {
            type: Array as PropType<any[]>,
        },
        labelWidth: {
            type: String,
            default: '60px',
        },
        dataValue: {
            type: Object
        }
    },
    setup(props, context) {
        let listData = reactive<Array<ListItem>>([])
        let labelWidth = props.labelWidth
        // 直接用props就行，不需要watch
        // watch(
        //     () => props.data,
        //     (newValue: any, oldValue) => {
        //         listData.push(...newValue)
        //     },
        //     {
        //         immediate: true,
        //     }
        // )
        const editClicked = (index: number, item: any) => {
            context.emit('editClicked', { index, item })
        }
        return {
            listData,
            labelWidth,
            editClicked,
        }
    },
})
</script>
<style lang="less" scoped>
.z-form-list {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 23px;
    // width: 300px;
    text-align: left;
    font-size: 14px;
    color: #999999;
}

.z-form-list-label {
    text-align: left;
    font-size: 14px;
    color: #999999;
}

.z-form-list-content {
    text-align: left;
    font-size: 14px;
    color: #2c3e50;
    display: flex;
    align-items: center;
}

.z-form-list-content-edit {
    margin-left: 10px;
    :deep(.el-button) {
        min-height: 0;
        padding: 0;
    }
}
</style>
