import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { routes } from './routes'
import store from '@/store'
const router = createRouter({
  // // 指定路由模式
  // history: createWebHistory(),
  // // 路由地址
  // routes: routes
  history: createWebHashHistory(),
  // 路由地址
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  console.log(to.path, 'path')
  if (Object.keys(store.state.globalPermissionInfo).length === 0) {
    try {
      await store.dispatch('getSystemConfig') // 等待异步获取平台配置完成
    } catch (error) {
      console.log(error)
    }
  }
  const token = localStorage.getItem('token')
  if (!['/Login', '/Register', '/ForgetPassword', '/doc'].includes(to.path) && !token) next({ path: '/Login' })
  else next()
})

export { router }
