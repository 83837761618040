<template>
    <div class="z-input-email-info">
        <el-form ref="inputEmailInfoRef" class="z-input-email-info-from" label-width="120px" label-position="left"
            :model="inputUserInfo" :rules="rules">
            <el-form-item prop="email" required>
                <template #label>
                    <span class="z-input-email-info-from-label">{{ t('zws.ZCommon.info.email') }}</span>
                </template>
                <el-input class="z-input-email-info-from-input" v-model="inputUserInfo.email"
                    :placeholder="t('zws.ZCommon.tips.email')">
                </el-input>
            </el-form-item>
            <el-form-item required>
                <template #label>
                    <!-- <span class="required-asterisk">*</span> -->
                    <span class="z-input-email-info-from-label">{{ t('zws.ZCommon.info.emailCode') }}</span>
                </template>
                <div class="z-input-email-info-from-code">
                    <div class="z-input-email-info-from-code-input">
                        <ZSixDigitPasswordCtrl v-model="inputUserInfo.email_verification_code" v-model:errorText="errorText"
                            backgroundColor="#ffffff"></ZSixDigitPasswordCtrl>
                        <ZCountDownCtrl v-model:isSendCode="isSendCode"
                            @getVerificationCodeClicked="getVerificationCodeClicked"></ZCountDownCtrl>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <div class="z-input-email-info-skip">
            <el-button link type="text" @click="skip">{{ t('zws.register.skipEmail') }}</el-button>
        </div>
        <div class="z-input-email-info-buttons">
            <el-button type="primary" icon="iconfont iconshangyibu" @click="backStep">{{ t('zws.register.back')
            }}</el-button>
            <el-button type="primary" icon="iconfont iconxiayibu" @click="nextStep">{{ t('zws.register.bindEmail')
            }}</el-button>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import ZCountDownCtrl from '../../../ctrl/ZCountDownCtrl.vue'
import ZSixDigitPasswordCtrl from '../../../ctrl/ZSixDigitPasswordCtrl.vue'
import { ZValidateDataTool as ZValidateTool } from '@zws/tools'
import { ElMessage } from 'element-plus'
import { useLocale } from '../../../hooks/useLocale'
import { checkUserTenantValidate, getVerificationCodes } from '@zws/axios-fetch'

export default defineComponent({
    components: {
        ZSixDigitPasswordCtrl,
        ZCountDownCtrl,
    },
    setup(props, context) {
        let { t } = useLocale()

        const store = useStore()
        const inputUserInfo = reactive(store.getters.registerInputUserInfo)

        inputUserInfo.email = ''
        let errorText: any = ref('')
        let inputEmailInfoRef: any = ref(null)
        let isSendCode: any = ref(false)
        let validateEmail = async (rule: any, value: any, callback: Function) => {
            if (!ZValidateTool.emailValidator(value)) {
                return callback(new Error(t('zws.login.error.015') || '请输入正确格式的邮箱地址'))
            } else {
                await checkUserTenantValidate({
                    field: 'email',
                    value: value,
                })
                    .then((res: any) => {
                        if (!res) {
                            return callback(new Error(t('zws.login.error.016') || '邮箱已存在'))
                        } else {
                            return callback()
                        }
                    })
                    .catch((error: any) => {
                        ElMessage.error(error.err.data.message)
                    })
            }
        }
        const rules = {
            email: [
                // { required: true, message: '邮箱不能为空', trigger: 'change' },
                { validator: validateEmail, trigger: 'change' },
            ],
        }
        function onClickEmailCode() {
            getVerificationCodes({
                type: 2,
                destination: inputUserInfo.email,
                purpose: 'register',
            })
                .then((res: any) => { })
                .catch((error: any) => { })
        }
        function getMailCodeValue(value: string) {
            inputUserInfo.email_verification_code = value
        }
        function skip() {
            errorText.value = ''
            inputEmailInfoRef.value.resetFields()
            inputUserInfo.email = undefined
            inputUserInfo.email_verification_code = undefined
            store.commit('registerInputUserInfo', inputUserInfo)
            context.emit('skip')
        }
        function nextStep() {
            inputEmailInfoRef.value.validate((validate: any) => {
                if (inputUserInfo.email_verification_code === undefined || inputUserInfo.email_verification_code === '') {
                    errorText.value = t('zws.login.error.014') || '请输入6位验证码'
                    return
                }
                if (validate) {
                    store.commit('registerInputUserInfo', inputUserInfo)
                    context.emit('nextStep')
                }
            })
        }
        function getVerificationCodeClicked() {
            isSendCode.value = false
            inputEmailInfoRef.value.validate((validate: any) => {
                if (validate) {
                    getVerificationCodes({
                        type: 2,
                        destination: inputUserInfo.email,
                        purpose: 'register',
                    })
                        .then((res: any) => {
                            isSendCode.value = true

                            ElMessage.success(t('zws.register.tips.getEmailCodeSuccess') || '获取邮箱验证码成功')
                        })
                        .catch((error: any) => {
                            ElMessage.error(error.err.data.message)
                        })
                }
            })
        }
        function setEmailErrorText(text: string) {
            errorText.value = text
        }
        return {
            t,
            inputUserInfo,
            rules,
            isSendCode,
            errorText,
            inputEmailInfoRef,
            onClickEmailCode,
            getMailCodeValue,
            skip,
            nextStep,
            getVerificationCodeClicked,
            setEmailErrorText,
        }
    },
    props: {
        propsList: {
            type: Object,
        },
        items: {
            type: Array,
        },
    },
    data() {
        return {
            pushCheckBoxValue: true,
            allowGetCode: false,
            isValidEmail: false,
            inputUserInfo: {
                email: '',
                mailCode: '',
            },
        }
    },
    methods: {
        backStep() {
            this.$emit('backStep')
        },
        getCount(value: boolean) {
            this.isValidEmail = value
        },
        onClickEmailCode() { },
    },
})
</script>
<style lang="less" scoped>
.z-input-email-info {
    width: 550px;
}

.z-input-email-info-from {
    padding: 60px;
}

.z-input-email-info-from-label {
    font-size: 14px;
    color: #999999;
}

.z-input-email-info-from-input {
    width: 300px;
}

.z-input-email-info-from-code {
    text-align: left;
}

.required-asterisk {
    padding-right: 4px;
    color: #f56c6c;
}

.z-input-email-info-from-code-input {
    display: flex;
    flex-direction: row;
    padding-left: 5px;
}

.z-input-email-info-skip {
    width: 550px;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.z-input-email-info-skip-clicked {
    cursor: pointer;
}

.z-input-email-info-skip-text {
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #80b33f;
}

.z-input-email-info-buttons {
    width: 550px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}</style>
