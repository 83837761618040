/**
 * 首页
 */
export default {
  addDev: 'Add Equipments',
  enterSearchTips: 'Please enter your search',
  functionRecommendation: 'Feature function recommendation',
  accessDevice: 'access',
  online: 'online',
  offline: 'offline',
  collect: 'Coll',
  isOnline: 'Online',
  sdStatus: 'SD Card',
  firmware: 'Firmware',
  server: 'Server',
  channelEnable: 'Channel',
  deviceIdTips: 'Please enter 20 uppercase hexadecimal characters',
  invalidDevice: 'Invalid device ID',
  descriptionValidatorTips: 'The value can contain only letters, hyphens (-), digits, and underscores (_)',
  devTypeNullTips: 'The device type cannot be empty',
  devNameNullTips: 'The device name cannot be empty',
  devIdNullTip: 'The device ID cannot be empty',
  collectSuccess: 'Successful collection',
  cancelCollect: 'Uncollect successfully',
  addDevSuccess: 'Succeeded in adding a device',
  allow: 'allow',
  forbid: 'forbid',
  deleteDeviceSuccess: 'Succeeded in deleting the device.',
  deleteChannelError: 'Failed to delete the channel bound to the device',
  howToDbc: 'How to do DBC parsing in the cloud',
  toDbc: 'For CAN message data, real-time DBC analysis, visualization curve, rapid problem analysis ',
  howToEcu: 'How to upgrade ECU in the cloud',
  toEcu: 'Remote ECU upgrade and UDS diagnosis in the cloud',
  nonsupport: 'nonsupport',
  notInserted: 'not inserted',
  withoutRecord: 'without record',
  record: 'record',
  channelTip: '{enable} cloud on { key } and { isUpload } cloud on data',
  enable: 'enable',
  disable: 'disable',
  duplicationDevice: 'The device ID has been bound, please confirm whether the ID is incorrect or contact our technical support '
}
