<template>
    <el-dialog :title="dialogTitle" v-model="dialogVisible" width="450px" :close-on-click-modal="false"
        :before-close="cancelClicked" center>
        <el-form ref="selectTransFormRef" class="dialog-form" :model="selectTransFormData" :rules="rules">
            <el-form-item :label="t('zws.ZCommon.fomat')" prop="selectTrans">
                <el-select class="dialog-form-select" v-model="selectTransFormData.selectTrans" placeholder="请选择文件格式">
                    <!-- <el-option v-for="item in transFormOptions" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option> -->
                    <el-option-group v-for="group in transFormOptions" :key="group.label" :label="group.label">
                        <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>
        </el-form>
        <div style="text-align: center;">
            <ZButtonListCtrl @buttonClicked="buttonClicked"></ZButtonListCtrl>
        </div>
    </el-dialog>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, watch } from 'vue'
import ZButtonListCtrl from 'ui/ctrl/ZButtonListCtrl.vue'
import { useStore } from 'vuex'
import { useLocale } from '../../hooks/useLocale'

export default defineComponent({
    emits: ['cancelClicked', 'confirmClicked'],
    components: {
        ZButtonListCtrl,
    },
    props: {
        visible: {
            type: Boolean,
        },
        title: {
            type: String,
        },
        infoModelId: {
            type: Number,
        },
    },
    setup(prop, context) {
        let { t } = useLocale()
        let selectTransFormRef: any = ref(null)
        let rules = {
            selectTrans: [{ required: true, message: '数据转换插件不能为空', trigger: 'change' }],
        }
        let store = useStore()
        let userInfo = store.getters.userInfo
        let dialogVisible: any = ref(false)
        let dialogTitle: any = ref('')
        let selectTransFormData: any = reactive({
            selectTrans: -1,
        })
        // let transFormOptions: any = reactive([
        //     {
        //         // 默认的意思就是，数据不需转换，直接是.csv格式下载
        //         label: 'csv',
        //         value: -1,
        //     },
        // ])
        const transFormOptions = ref([
            {
                label: "csv",
                options: [
                    {
                        // 默认的意思就是，数据不需转换，直接是.csv格式下载
                        label: t('zws.ZCommon.csv')||'csv默认格式',
                        value: -1,
                    }
                ]
            },
        ])
        watch([() => prop.visible, () => prop.title, () => prop.infoModelId], (newValue, oldValue) => {
            dialogVisible.value = newValue[0]
            dialogTitle.value = newValue[1]
            const infoModelId: any = newValue[2]
            if (newValue[0]) {
                getTransFormList(infoModelId)
            }
            if (!prop.visible) {
                selectTransFormRef.value?.resetFields()
            }
        })
        function getTransFormList(infoModelId: number) {
            transFormOptions.value = [
                {
                    label: "csv",
                    options: [
                        {
                            // 默认的意思就是，数据不需转换，直接是.csv格式下载
                            label: t('zws.ZCommon.csv')||'csv默认格式',
                            value: -1,
                        }
                    ]
                }
            ]
            window.ZWSApi.ZWSDataMgmt.dataTransform_getPluginsByType({
                tenant_id: userInfo.tenant_id,
                info_model_id: infoModelId,
                type: 2,
            })
                .then((res: any) => {
                    let _allDataObj = {}
                    res.forEach((item: any) => {
                        if (_allDataObj[item.file_suffix] && Array.isArray(_allDataObj[item.file_suffix])) {
                            _allDataObj[item.file_suffix].push({
                                label: item.name,
                                value: item.id
                            })
                        } else {
                            _allDataObj[item.file_suffix] = [
                                {
                                    label: item.name,
                                    value: item.id
                                }
                            ]
                        }
                    })
                    Object.keys(_allDataObj).forEach(item => {
                        if (item === 'csv') {
                            transFormOptions.value[0].options.push(..._allDataObj[item])
                        } else {
                            transFormOptions.value.push({
                                label: item,
                                options: _allDataObj[item]
                            })
                        }
                    })
                })
                .catch((error: any) => {
                    console.log(error);
                })
        }
        function cancelClicked() {
            selectTransFormRef.value?.resetFields()
            context.emit('cancelClicked')
        }
        function buttonClicked(value: any) {
            var index = value.index + ''
            switch (index) {
                case '0':
                    selectTransFormRef.value?.resetFields()
                    context.emit('cancelClicked')
                    break
                case '1':
                    selectTransFormRef.value.validate((validate: any) => {
                        if (validate) {
                            context.emit('confirmClicked', selectTransFormData.selectTrans)
                        }
                    })
                    break
                default:
                    break
            }
        }
        return {
            t,
            rules,
            selectTransFormRef,
            dialogVisible,
            dialogTitle,
            selectTransFormData,
            transFormOptions,
            cancelClicked,
            buttonClicked,
        }
    },
})
</script>
<style lang="less" scoped>
.dialog-form {
    margin-left: 20px;
    margin-right: 20px;
}

.dialog-form-select {
    width: 100%;
}

:deep(.el-select-group .el-select-dropdown__item) {
    padding-left: 20px;
}
</style>
