/**
 * 触发管理
 */
export default {
  ge: '个',
  importResult: '导入结果',
  remoteFrame: '远程帧',
  dataFrame: '数据帧',
  extendedFrame: '扩展帧',
  standardFrame: '标准帧',
  frameFormat: '帧格式',
  frameType: '帧类型',
  frameId: '帧ID',
  errorCode: '错误码',
  lastModifyTime: '最后修改时间',
  processFailed: '处理失败',
  processSuccess: '处理成功',
  pleaseInput128Chars: '请输入1-128个字符串',
  pleaseInputChars: '请输入{0}个字符串',
  pleaseInputProcessMethod: '请输入处理方式',
  pleaseInputProcessor: '请输入处理人',
  triggerTime: '触发时间',
  triggerContent: '触发内容',
  triggerRuleName: '触发规则名称',
  triggerRuleDetail: '触发规则详情',
  processMode: '处理方式',
  processor: '处理人',
  triggerProcess: '触发处理',
  alarmRule: '告警规则',
  pleaseSelectTimeRange: '请先选择时间段',
  eventContent: '事件内容',
  dataIn5Min: '前后5分钟数据',
  triggerEvent: '触发事件',
  triggerRule: '触发规则',
  upTo20Rules: '最多20条规则',
  ruleNameExists: '规则名称已存在',
  contactAdmin: '导入失败，请联系管理员',
  importFailByRepeatRuleName: '导入失败，规则名称已被使用',
  importFailByUserRuleLimit: '导入失败，超过用户规则上限数',
  importFailBySystemLimit: '导入失败，超过系统规则上限数',
  importSuccess: '成功导入 {num} 条触发规则',
  noTriggerRule: '暂无触发规则',
  importLimit: '还可导入 {number} 条触发规则，当前默认上限为 {max} 条',
  enterRawOrDBCAsTriggerData: '请输入raw或dbc作为触发数据',
  cannotAddDBCSignalTriggerRule: '设备{channel}通道无法添加dbc信号的触发规则',
  cannotAddTriggerRule: '无法添加原始数据触发规则，{channel}通道不存在',
  parseFileContentFailed: '文件内容无法正常解析',
  deleteAlarmRuleSuccess: '告警规则 {ruleName} 删除成功',
  disableRuleFirst: '请先禁用触发规则',
  triggerState: '触发状态',
  triggerCondition: '触发条件',
  conditionsLimit: '最多只能添加{0}个触发条件',
  ruleName: '规则名称',
  noTriggerToExport: '暂无触发规则可导出',
  dbcSignal: 'DBC信号',
  sureToDeleteRule: '确定要删除触发规则 { ruleName } 吗？',
  deleteTriggerRule: '删除触发规则',
  beenDisable: '已禁用',
  beenEnable: '已启用',
  pleaseInputRuleName: '请输入规则名称',
  pleaseSelectStatus: '请选择状态',
  pleaseSelectCANChannel: '请选择CAN通道',
  pleaseSelectTriggerData: '请选择触发数据',
  triggerData: 'Trigger Data',
  ruleMax: '最多20条规则',
  addTriggerRule: '添加触发规则',
  editTriggerRule: '编辑触发规则',
  triggerLevel: '触发等级',
  share: '子用户共享',
  shareTips: '启用共享，该账号下的子用户，将会接收到该告警规则触发的告警事件',
  alertDes: '告警描述',
  alertRequirement: '告警条件',
  customText: '自定义文本',
  alertAction: '告警动作',
  recoverAction: '恢复动作',
  addAction: '添加动作',
  level: {
    tip: '提示',
    ordinary: '一般',
    urgent: '紧急'
  },
  triggerDescription: '触发描述',
  add: '新增{0}{1}',
  edit: '编辑{0}{1}',
  actionType: '动作类型',
  details: '详情',
  action: {
    email: '邮件通知',
    sms: '短信通知',
    vms: '语音告警'
  },
  actionSimple: {
    email: '邮件',
    sms: '短信',
    vms: '语音'
  },
  tips: {
    emailNotNull: '请输入邮箱地址',
    phoneNotNull: '请输入手机号码',
    emailError: '邮箱格式错误',
    phoneError: '手机号码格式错误',
    unbindDbc: '该通道未绑定DBC文件',
    noAtts: '当前通道无该属性'
  }
}
