<template>
<div class="zws-footer">
    <span v-if="footerConfig.systemWebBottomCompany" class="zws-footer-text">{{ footerConfig.systemWebBottomCompany }}</span>
    <span v-if="footerConfig.systemWebBottomPhone" class="zws-footer-text zws-footer-margin">{{ t('zws.login.footerPhone' || '联系电话：') }} {{ footerConfig.systemWebBottomPhone }}</span>
    <span v-if="footerConfig.systemWebBottomEmail" class="zws-footer-text">{{ t('zws.login.footerEmail' || '邮箱：') }} {{ footerConfig.systemWebBottomEmail }}</span>
    <!-- <span class="zws-footer-text">©2022 广州致远电子股份有限公司</span>
        <span class="zws-footer-text zws-footer-margin">联系电话：400-888-4005</span>
        <span class="zws-footer-text">邮箱：zws@zlg.cn</span> -->
</div>
</template>
<script setup lang='ts'>
import { PropType, watch, reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useLocale, setLanguage } from '../../hooks/useLocale'
const store = useStore()

export type FooterCfg = {
    systemWebBottomCompany: string
    systemWebBottomPhone: string
    systemWebBottomEmail: string
}

const props = defineProps({
    systemType: {
        type: String
    }
})

let { t } = useLocale()
let footerConfig = reactive<FooterCfg>({
    systemWebBottomCompany: '©2022 广州致远电子股份有限公司',
    systemWebBottomPhone: '400-888-4005',
    systemWebBottomEmail: 'zws@zlg.cn',
})
onMounted(() => {
    // setPlatformInfo()
    if (props?.systemType === 'billing') {
        Object.assign(footerConfig, store.getters.tenantCustomizationInfo)
    } else {
        setPlatformInfo()
    }
})
watch(
    () => store.getters.tenantCustomizationInfo,
    () => {
        if (props?.systemType === 'billing') {
            Object.assign(footerConfig, store.getters.tenantCustomizationInfo)
        } else {
            setPlatformInfo()
        }
    }
)
function setPlatformInfo() {
    let globalPermissionInfo = store.getters.globalPermissionInfo
    if (!globalPermissionInfo) return
    Object.assign(footerConfig, store.getters.tenantCustomizationInfo)
}
</script>
<style lang = 'less' scoped>
.zws-footer-text {
    font-size: 12px;
    color: #909399;
    margin: 0 20px;
}

.zws-footer-margin {
    // margin-left: 40px;
    // margin-right: 40px;
}
</style>
    