/**
 * GPS轨迹
 */
export default {
  endTime: 'End Time: ',
  startTime: 'Start Time: ',
  realtimeSpeed: 'Realtime Speed: ',
  averageSpeed: 'Average Speed: ',
  totalDistance: 'Total Distance: ',
  endPoint: 'End',
  startPoint: 'Start',
  obtainHistoryTrackFailed: 'Failed to obtain history track',
  historyDataLimit: 'Displays data up to 200,000 points from the search start time',
  pleaseInputTimeRange: 'Please enter a time range',
  noDataInTheTime: 'There is no data in that time frame',
  systemUpdateTip:
    'Due to the system upgrade, there may be compatibility issues with GPS data before {date}, which will affect the track painting. It is recommended that you filter the data display from this time.',
  errorTimeTip: 'If the device time is abnormal in this time range, the average speed of historical track calculation may be abnormal ',
  dataDisplayLimit: 'Displays data up to 5000 points from the search start time',
  obtainTrackFailed: 'Failed to obtain track, trying to obtain again',
  obtainingTrackData: 'Obtaining track data',
  realtimeTrack: 'Realtime Track',
  historicalTrack: 'Historical Track'
}
