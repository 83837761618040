import ZWSBaseModule from '../httpBase/ZWSBaseModule'

interface LorawanThingsInfo {
    attributes?: string,
    description: string,
    desired_properties?: string,
    desired_status?: string,
    full_things_id?: string,
    gw_things_id?: number,
    id?: number,
    info_model_id: number,
    info_model_name?: string,
    maximum_duration_online?: number,
    name: string,
    parent_things_id?: number,
    revsion?: string,
    status?: number,
    thing_type: number,
    things_key: string,
    third_things_id: string,
    twin_things_status?: string,
    vendor_tenant_id?: number
}
/**
 * LoRaWAN组播
 */
class ZWSSingleLoRaWAN extends ZWSBaseModule {

    /***************************lorawanGroup********************************** */
    /**
     * Create lorawan group
     */
    static lorawanGroup_addLorawanGroup({ tenantId, body }: any) {
        return this.fetch({
            url: `/single-lorawan/tenants/${tenantId}/multicast-group`,
            method: 'post',
            data: body
        })
    }

    /**
     * Returns lorawan group
     */
    static lorawanGroup_getLorawanGroups({ current_page, name, page_size, tenantId, third_things_id }: any) {
        return this.fetch({
            url: `/single-lorawan/tenants/${tenantId}/multicast-group`,
            method: 'get',
            params: { current_page, name, page_size, tenantId, third_things_id }
        })
    }

    /**
     * Delete lorawan Multicast Group
     */
    static lorawanGroup_deleteBatchLorawanGroup({ ids, tenantId }: any) {
        return this.fetch({
            url: `/single-lorawan/tenants/${tenantId}/multicast-group/${ids}`,
            method: 'delete',
        })
    }

    /**
     * Updates the zigbee group
     */
    static lorawanGroup_updateLorawanGroup({ tenantId, ids, body }: any) {
        return this.fetch({
            url: `/single-lorawan/tenants/${tenantId}/multicast-group/${ids}`,
            method: 'put',
            data: body
        })
    }

    /**
     * List devices from Multicast Group
     */
    static lorawanGroup_getLorawanGroupsDvice({ current_page, multicastGroupId, page_size, tenantId }: any) {
        return this.fetch({
            url: `/single-lorawan/tenants/${tenantId}/multicast-group/${multicastGroupId}/devices`,
            method: 'get',
            params: { current_page, page_size }
        })
    }

    /**
     * Add devices to Multicast Group
     */
    static lorawanGroup_addLorawanGroupDevice({ tenantId, multicastGroupId, body }: any) {
        return this.fetch({
            url: `/single-lorawan/tenants/${tenantId}/multicast-group/${multicastGroupId}/devices`,
            method: 'post',
            data: body
        })
    }

    /**
     * Delete devices from Multicast Group
     */
    static lorawanGroup_deleteBatchLorawanGroupDevice({ multicastGroupId, tenantId, body }: any) {
        return this.fetch({
            url: `/single-lorawan/tenants/${tenantId}/multicast-group/${multicastGroupId}/devices`,
            method: 'delete',
            data: body
        })
    }

    /**
     * Send Message to Multicast Group
     */
    static lorawanGroup_issueLorawanGroupOrder({ tenantId, multicastGroupId, body }: any) {
        return this.fetch({
            url: `/single-lorawan/tenants/${tenantId}/multicast-group/${multicastGroupId}/message`,
            method: 'post',
            data: body
        })
    }

    /*************************** things ********************************** */

    static things_addLorawanThings({ tenant_id, body }: {
        tenant_id: number, body: LorawanThingsInfo
    }) {
        return this.fetch({
            url: `/single-lorawan/tenants/${tenant_id}/things`,
            method: 'post',
            data: body
        })
    }

}

export default ZWSSingleLoRaWAN