<template>
    <div ref="scrollContainerRef"
         class="virtual-scroll-contain"
         @scroll.passive="containScroll"
         @mouseenter="handleMouseenter"
         @mouseleave="handleMouseleave"
    >
        <div ref="listContentRef" class="list-content" :style="paddingStyle">
            <template v-for="item in renderList" :key="index">
                <div :class="[props.isHorizontal ? 'list-content-row' : 'list-content-column']">
                    <slot :itemData="item">
                        <template v-for="subItem in listKeys">
                            <div :class="[props.isHorizontal ? 'list-content-row-item' : 'list-content-column-item']">
                                <span :title="item[subItem]">{{item[subItem]}}</span>
                            </div>
                        </template>
                    </slot>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, reactive, withDefaults, defineProps, defineEmits, computed, nextTick, watch, onMounted, onBeforeUnmount, defineExpose } from "vue";

    declare global {
        interface Window {
            requestAnimationFrame: Function
            webkitRequestAnimationFrame: Function
            mozRequestAnimationFrame: Function
            msRequestAnimationFrame: Function
        }
    }

    interface Props {
        dataList: any[]
        isHorizontal: boolean
        virtualCacheSize: number
        virtualSingleLength: number
        isAutoScroll: boolean
        isHoverStopScroll: boolean
        scrollSpeed: number
        isStepScroll: boolean
        stepScrollInterval: number
        isConnectScroll: boolean
    }
    const props = withDefaults(defineProps<Props>(), {
        dataList: () => [],
        isHorizontal: false,
        virtualCacheSize: 15,
        virtualSingleLength: 36,
        isAutoScroll: true,
        isHoverStopScroll: true,
        scrollSpeed: 1,
        isStepScroll: false,
        stepScrollInterval: 1000,
        isConnectScroll: false,
    });
    const emits = defineEmits(['onReachBottom']);

    // List Common Attr
    const _dataList = ref<Array<any>>([]);
    const _dataNativeList = ref<Array<any>>([]);
    const _isHorizontal: boolean = props.isHorizontal;
    const scrollContainerRef = ref();
    const listContentRef = ref();
    const listKeys = ref<Array<any>>([]);
    let listLastReachBottomTime: number | null = null;
    let isMouseEnter: boolean = false;

    // Virtual Attr
    let _virtualCacheSize: number = props.virtualCacheSize;
    let _virtualSingleLength: number = props.virtualSingleLength;
    const virtualContainMaxCount = ref<number>(10);
    const virtualItemCurrentIndex = ref<number>(0);

    // Scroll Attr
    let _isConnectScroll = ref<Boolean>(props.isConnectScroll);
    let _isStepScroll = ref<Boolean>(props.isStepScroll);
    let _scrollAddLength: number = props.scrollSpeed;
    let _stepScrollInterval: number = props.stepScrollInterval;
    let _isHoverStopScroll: boolean = props.isHoverStopScroll;
    let _isAutoScroll: boolean = props.isAutoScroll;
    let scrollAnimationInterval: any = null;
    let scrollRafTimeCount: number = 0;
    let scrollStepOneCount: number = 0;
    let nextStepLength: number = 0;
    let preOrNextStepLength: number = 0;
    let isNextClick: boolean = true;
    let isPreOrNextMoving: boolean = false;

    /* ---------- computed -----------*/
    const virtualSingleLengthWithPxComputed = computed(() => _virtualSingleLength + 'px');
    const flexDirectionComputed = computed(() => _isHorizontal ? 'row' : 'column');

    const offsetTopPadding = computed(() => {
        if ((virtualItemCurrentIndex.value as number) < _virtualCacheSize) return 0;
        return ((virtualItemCurrentIndex.value as number) - _virtualCacheSize) * _virtualSingleLength;
    });
    const offsetBottomPadding = computed(() => {
        if ((virtualItemCurrentIndex.value as number) < _virtualCacheSize) {
            return (allDataListLength.value - renderWithCatchSize.value) * _virtualSingleLength;
        } else {
            const bottomLength = allDataListLength.value - renderWithCatchSize.value - virtualItemCurrentIndex.value + _virtualCacheSize;
            return (bottomLength <= 0 ? 0 : bottomLength) * _virtualSingleLength;
        }
    });
    const paddingStyle = computed(() => {
        if(_isHorizontal) {
            return {
                "paddingLeft": offsetTopPadding.value + "px",
                "paddingRight": offsetBottomPadding.value + "px"
            };
        } else {
            return {
                "paddingTop": offsetTopPadding.value + "px",
                "paddingBottom": offsetBottomPadding.value + "px"
            };
        }
    });

    const allDataListLength = computed(() => _dataList.value.length);

    const renderWithCatchSize = computed(() => {
        if (virtualItemCurrentIndex.value < _virtualCacheSize) {
            return virtualItemCurrentIndex.value + virtualContainMaxCount.value + _virtualCacheSize;
        } else {
            return virtualContainMaxCount.value + _virtualCacheSize * 2;
        }
    });

    const renderList = computed(() => {
        let resultArr = [];
        if (virtualItemCurrentIndex.value < _virtualCacheSize) {
            resultArr = _dataList.value.slice(0, renderWithCatchSize.value);
        } else {
            resultArr = _dataList.value.slice(
                virtualItemCurrentIndex.value - _virtualCacheSize,
                virtualItemCurrentIndex.value - _virtualCacheSize + renderWithCatchSize.value
            );
        }
        return [...resultArr];
    });

    /* -------- method common ------------ */
    const containScroll = () => {
        let currentExecuteTime = null;
        let lastExecuteTime = Date.now();
        let timeInterval = 1000 / 30;
        window.requestAnimationFrame(function containScrollRaf() {
            currentExecuteTime = Date.now();
            lastExecuteTime = currentExecuteTime;
            const diffTime = currentExecuteTime - lastExecuteTime;
            getRenderList();
            if (diffTime >= timeInterval) {
                // window.requestAnimationFrame(arguments.callee);
                window.requestAnimationFrame(containScrollRaf);
            }
        });
    };

    const resetReachBottomThing = () => {
        if(_isHorizontal) {
            listContentRef.value.style.paddingLeft = (allDataListLength.value - _virtualCacheSize) * _virtualSingleLength;
            listContentRef.value.style.paddingRight = "0px";
        } else {
            listContentRef.value.style.paddingTop = (allDataListLength.value - _virtualCacheSize) * _virtualSingleLength;
            listContentRef.value.style.paddingBottom = "0px";
        }
    };

    const preClick = () => {
        if(isPreOrNextMoving) return;
        isNextClick = false;
        calcPreOrNextStepLength();
        handleScrollByOneStep();
    };
    const nextClick = () => {
        if(isPreOrNextMoving) return;
        isNextClick = true;
        calcPreOrNextStepLength();
        handleScrollByOneStep();
    };

    /* -------- method virtual ------------ */
    const handleMouseenter = () => {
        isMouseEnter = true;
        if (_isHoverStopScroll) {
            resetScrollTimeThing();
        }
    };
    const handleMouseleave = (e: any) => {
        // if ([].includes.call(e?.toElement?.classList, 'el-popper')) return; // 进入 tooltips
        isMouseEnter = false;
        if (_isHoverStopScroll) {
            initScrollAnimation();
        }
    };

    const calcNextStepLength = () => {
        if (!scrollContainerRef.value) return
        if (_isStepScroll.value) {
            const currentScrollLength = _isHorizontal ? scrollContainerRef.value.scrollLeft : scrollContainerRef.value.scrollTop;
            const currentCount = ~~(currentScrollLength / _virtualSingleLength);
            nextStepLength = (currentCount + 1) * _virtualSingleLength - currentScrollLength;
        }
    };
    const calcPreOrNextStepLength = () => {
        if (!scrollContainerRef.value) return
        const currentScrollLength = _isHorizontal ? scrollContainerRef.value.scrollLeft : scrollContainerRef.value.scrollTop;
        const currentCount = isNextClick
            ? ~~(currentScrollLength / _virtualSingleLength)
            : Math.round(currentScrollLength / _virtualSingleLength);
        preOrNextStepLength = isNextClick
            ? (currentCount + 1) * _virtualSingleLength - currentScrollLength
            : (currentCount - 1) * _virtualSingleLength - currentScrollLength;
    };
    const resetScrollTimeThing = () => {
        calcNextStepLength();

        window.cancelAnimationFrame(scrollAnimationInterval);
        scrollAnimationInterval = null;
        scrollRafTimeCount = 0;
        scrollStepOneCount = 0;
    };

    const getRenderList = () => {
        if (!scrollContainerRef.value) return
        const scrollLength = _isHorizontal ? scrollContainerRef.value.scrollLeft : scrollContainerRef.value.scrollTop;
        const virtualItemCurrentIndexTem = ~~(scrollLength / _virtualSingleLength);
        if (virtualItemCurrentIndex.value == virtualItemCurrentIndexTem) return;
        virtualItemCurrentIndex.value = virtualItemCurrentIndexTem;
        if (virtualItemCurrentIndexTem + virtualContainMaxCount.value >= allDataListLength.value) {
            resetReachBottomThing();
            if (!listLastReachBottomTime || Date.now() - listLastReachBottomTime > 1000) {
                listLastReachBottomTime = Date.now();
                reachBottomSetConnectData();
                emits("onReachBottom");
            }
        }
    };

    /* -------- method scroll ------------ */
    const isScrollBottom = () => {
        if (!scrollContainerRef.value) return
        const clientHeight = _isHorizontal ? scrollContainerRef.value.clientWidth : scrollContainerRef.value.clientHeight;
        const scrollTop = _isHorizontal ? scrollContainerRef.value.scrollLeft : scrollContainerRef.value.scrollTop;
        const scrollHeight = _isHorizontal ? scrollContainerRef.value.scrollWidth : scrollContainerRef.value.scrollHeight;
        return clientHeight + scrollTop === scrollHeight;
    };

    const connectLongDataReset = () => {
        if (!scrollContainerRef.value) return
        _dataList.value.splice(0, _dataList.value.length);
        if(_isHorizontal) {
            listContentRef.value.style.paddingLeft = "0px";
            listContentRef.value.style.paddingRight = "0px";
            scrollContainerRef.value.scrollLeft = 0;
        } else {
            listContentRef.value.style.paddingTop = "0px";
            listContentRef.value.style.paddingBottom = "0px";
            scrollContainerRef.value.scrollTop = 0;
        }
        initConnectData(_dataNativeList.value);
    };
    const reachBottomSetConnectData = () => {
        if (_isConnectScroll.value && !isMouseEnter) {
            if(_dataList.value.length >= 10000) {
                connectLongDataReset();
            } else {
                _dataList.value = _dataList.value.concat(_dataNativeList.value);
            }
        }
    };

    const scrollSetLengthAndRender = () => {
        if (!scrollContainerRef.value) return
        if (_isHorizontal) {
            scrollContainerRef.value.scrollLeft += _scrollAddLength;
        } else {
            scrollContainerRef.value.scrollTop += _scrollAddLength;
        }
        getRenderList();
    };
    const preOrNextScrollSetLengthAndRender = () => {
        if (!scrollContainerRef.value) return
        if (_isHorizontal) {
            scrollContainerRef.value.scrollLeft += isNextClick ? _scrollAddLength : (-_scrollAddLength);
        } else {
            scrollContainerRef.value.scrollTop += isNextClick ? _scrollAddLength : (-_scrollAddLength);
        }
        getRenderList();
    };

    const oneByOneScroll = () => {
        autoScrollOneStep();
    };
    const autoScrollOneStep = () => {
        if (!_isStepScroll.value) {
            resetScrollTimeThing();
        }
        if (scrollStepOneCount > nextStepLength) {
            resetScrollTimeThing();
            if(!_isAutoScroll) return;
            setTimeout(() => {
                if (!_isHoverStopScroll) return autoScrollOneStep();
                if (!isMouseEnter) return autoScrollOneStep();
            }, _stepScrollInterval)
        } else {
            scrollStepOneCount += 1;
            scrollSetLengthAndRender();
            scrollAnimationInterval = window.requestAnimationFrame(autoScrollOneStep);
        }
    };
    const handleScrollByOneStep = () => {
        isPreOrNextMoving = true;
        if (scrollStepOneCount >= Math.abs(preOrNextStepLength)) {
            isPreOrNextMoving = false;
            resetScrollTimeThing();
        } else {
            scrollStepOneCount += _scrollAddLength;
            preOrNextScrollSetLengthAndRender();
            scrollAnimationInterval = window.requestAnimationFrame(handleScrollByOneStep);
        }
    };
    const autoScrollLoop = () => {
        scrollSetLengthAndRender();
        scrollAnimationInterval = window.requestAnimationFrame(autoScrollLoop);
    };


    /* -------- method init ------------ */
    const initContainItemSize = () => {
        window.requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame;

        if (!scrollContainerRef.value) return
        const containHeight = scrollContainerRef.value.clientHeight;
        if (containHeight) {
            virtualContainMaxCount.value = ~~(containHeight / _virtualSingleLength) + 2;
        }
    };

    const initFinalDataList = (newList: any) => {
        _dataNativeList.value = _dataNativeList.value.concat(newList);
        if (_isConnectScroll.value) {
            initConnectData(newList);
        } else {
            _dataList.value = _dataNativeList.value;
        }
    };
    const initConnectData = (newList: any) => {
        _dataList.value = _dataList.value.concat(newList);
        if(_dataList.value.length < virtualContainMaxCount.value) {
            _dataList.value = _dataList.value.concat(newList);
        }
    };

    const initListKeys = () => {
        if (_dataList.value.length === 0) return;
        listKeys.value = Object.keys(_dataList.value[0]);
    };

    const initScrollAnimation = () => {
        if (isMouseEnter && _isHoverStopScroll) return;
        resetScrollTimeThing();
        if(!_isAutoScroll) return;
        if (_isStepScroll.value) {
            oneByOneScroll();
        } else {
            autoScrollLoop();
        }
    };

    const init = (newList: any) => {
        initContainItemSize();
        initFinalDataList(newList);
        initListKeys();
        initScrollAnimation();
    };
    onMounted(() => {
    });
    onBeforeUnmount(() => {
        window.cancelAnimationFrame(scrollAnimationInterval);
    })

    watch(() => props.dataList, (newList) => {
        if (!newList || !Array.isArray(newList) || newList.length === 0) return;
        init(newList);
    }, {
        deep: true,
        immediate: true
    });

    watch(() => props.isAutoScroll, (newVal) => {
        _isAutoScroll = newVal;
        initScrollAnimation();
    });

    watch(() => props.isHoverStopScroll, (newVal) => {
        _isHoverStopScroll = newVal;
    });

    watch(() => props.scrollSpeed, (newVal) => {
        _scrollAddLength = newVal <= 1 ? 1 : newVal >= 5 ? 5 : newVal;
    });

    watch(() => props.isStepScroll, (newVal) => {
        _isStepScroll.value = newVal;
        initScrollAnimation();
    });

    watch(() => props.stepScrollInterval, (newVal) => {
        _stepScrollInterval = newVal;
    });

    watch(() => props.isConnectScroll, (newVal) => {
        _isConnectScroll.value = newVal;

        if(newVal && isScrollBottom()) {
            reachBottomSetConnectData();
        }
        initScrollAnimation();
    });

    defineExpose({
        preClick,
        nextClick
    })
</script>


<style lang="less" scoped>
    .virtual-scroll-contain {
        width: 100%;
        height: inherit;
        position: relative;
        overflow: auto;
        /*scroll-behavior: v-bind(scrollBehaviorComputed);*/
        scroll-behavior: auto;

        .list-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: v-bind(flexDirectionComputed);
            &-row {
                display: flex;
                flex-direction: column;
                height: 100%;
                /*border-right: 1px solid red;*/
                /*box-sizing: border-box;*/
                &-item {
                    [title]{
                        color: black;
                    }
                    flex: 1;
                    width: v-bind(virtualSingleLengthWithPxComputed);
                    padding-left: 6px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            &-column {
                display: flex;
                &-item {
                    [title]{
                        color: black;
                    }
                    flex: 1;
                    height: v-bind(virtualSingleLengthWithPxComputed);
                    line-height: v-bind(virtualSingleLengthWithPxComputed);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: center;
                }
            }
        }
    }

    /* 滚动槽 */
    .virtual-scroll-contain::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
    }
    /*鼠标移动上去再显示滚动条*/
    .virtual-scroll-contain:hover ::-webkit-scrollbar-track-piece {
        background-color: #fff;
        border-radius: 6px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #B2B2B2 !important;
    }
    .virtual-scroll-contain:hover::-webkit-scrollbar-thumb {
        border-radius: 8px;
        /*background: rgba(0, 0, 0, 0.12);*/
        background-color:  #dedfe0;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
        transition: all .2s ease-in-out;
    }

    // 避免项目有自定义修改，影响滚动条样式
    .virtual-scroll-contain::-webkit-scrollbar-track {
        border-radius: unset;
        background-color: transparent;
        box-shadow: unset;
    }

    /* 滚动条滑块 */
    .virtual-scroll-contain::-webkit-scrollbar-thumb {
        border-radius: unset;
        background-color: transparent;
        box-shadow: unset;
    }
</style>