import ZWSBaseModule from '../httpBase/ZWSBaseModule'

import type { Pagination } from './ZWSPagination'

export interface TriggerRule {
  id: number
}

export interface GetBatchRulesResp {
  list: TriggerRule[],
  pagination: Pagination
}

class ZWSAlertMgmt extends ZWSBaseModule {

  /*******************************************************  alertEvents  *****************************************************/
  // /**
  //  * 查询告警事件列表
  //  * @param {number} [params.tenant_id] 租户id
  //  * @param {number} [params.start_time] 开始时间（毫秒）
  //  * @param {number} [params.end_time] 结束时间（毫秒）
  //  * @param {number} [params.page_size] 每页条数
  //  * @param {number} [params.current_page] 跳过多少页数据
  //  * @param {string} [params.name] 规则名称
  //  * @param {number} [params.rule_type] 规则类型,1数据推送，2告警
  //  * @param {number} [params.last_update_time] 最后一次更新时间
  //  * @param {number} [params.info_model_id] 模型id
  //  * @param {number} [params.rule_id] 规则id
  //  * @param {string} [params.rule_ids] 规则id列表，逗号分隔
  //  * @param {string} [params.rule_name] 规则名称（模糊匹配）
  //  * @param {number} [params.things_id] 设备id
  //  * @param {number} [params.third_things_id] 设备串号
  //  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
  //  */
  // static alertEvents_getBatchAlertEvents({ tenant_id, start_time, end_time, page_size, current_page, name, rule_type, last_update_time, info_model_id, rule_id, rule_ids, rule_name, things_id, third_things_id }: any) {
  //   return this.fetch({
  //     url: `/alert-mgmt/tenants/${tenant_id}/alert-events`,
  //     method: 'get',
  //     params: { start_time, end_time, page_size, current_page, name, rule_type, last_update_time, info_model_id, rule_id, rule_ids, rule_name, things_id, third_things_id }
  //   })
  // }
  // /**
  //  * 告警事件的删除
  //  * @param {number} [params.tenant_id] 租户id
  //  * @param {number} [params.end_time] 结束时间（毫秒）
  //  * @param {string} [params.event_id] 事件id,多个使用逗号分隔
  //  * @param {number} [params.info_model_id] 模型id
  //  * @param {number} [params.rule_id] 规则id
  //  * @param {number} [params.rule_name] 规则id
  //  * @param {number} [params.start_time] 开始时间（毫秒）
  //  * @param {number} [params.things_id] 设备ID
  //  * @param {string} [params.third_things_id] 设备串号
  //  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
  //  */
  // static alertEvents_deleteAlertEvents({ tenant_id, end_time, event_id, info_model_id, rule_id, start_time, things_id, third_things_id }: { tenant_id: number, end_time: number, event_id: string, info_model_id: number, rule_id: number, start_time: number, things_id: number, third_things_id: string }) {
  //   return this.fetch({
  //     url: `/alert-mgmt/tenants/${tenant_id}/alert-events`,
  //     method: 'delete',
  //     params: { end_time, info_model_id, rule_id, event_id, start_time, things_id, third_things_id }
  //   })
  // }

  /*******************************************************  edgeComputing  **************************************************/
  /**
   * 查询边缘计算规则列表（分页），创建时间倒序排列
   * @param {number} [params.tenantId] 租户id
   * @param {number} [params.current_page] 当前页
   * @param {number} [params.page_size] 每页条数
   * @param {string} [params.rule_name] 规则名称，模糊匹配
   * @param {number} [params.rule_type] 规则类型 - 1 映射 - 2 告警 - 3 定时
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static edgeComputing_getBatchEdgeComputingRules({ tenantId, rule_type, rule_name, page_size, current_page }: { tenantId: number, rule_type: number, rule_name: string, page_size: number, current_page: number }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/edge-computing-rules`,
      method: 'get',
      code: 'ZWSAlertMgmt.edgeComputing_getBatchEdgeComputingRules',
      params: { rule_type, rule_name, page_size, current_page }
    }, 'alert:edge-computing-rule:query')
  }
  /**
   * 获取边缘计算规则(单条)
   * @param {number} [params.tenantId] 租户id
   * @param {number} [params.current_page] 当前页
   * @param {number} [params.page_size] 每页条数
   * @param {string} [params.rule_id] 规则id
   * @param {number} [params.rule_type] 规则类型 - 1 映射 - 2 告警 - 3 定时
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static edgeComputing_getSingleEdgeRule({ tenantId, ruleId }: { tenantId: number, ruleId: number }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/edge-computing-rules/${ruleId}`,
      method: 'get',
    }, 'alert:edge-computing-rule:query')
  }
  /**
  * 创建边缘计算规则
  * @param {number} [params.tenantId] 租户id
  * @param {object} [params.info] 边缘计算信息
  * @param {string} [params.info.rule_name] 规则名称
  * @param {number} [params.info.rule_type] 规则类型 - 1 映射 - 2 告警 - 3 定时
  * @param {string} [params.info.rule_content_off] 规则内容（停用），格式根据类型不同而不同。 示例：{"operation_name":"lampFnBlock.doCmd","operation_params":{"data":"1"}}
  * @param {string} [params.info.rule_content_on] 规则内容（启用），格式根据类型不同而不同。 示例：{"operation_name":"lampFnBlock.doCmd","operation_params":{"data":"1"}}
  * @param {Array} [params.info.things_filter] 触发的过滤条件，用于设定设备范围，示例 - 对设备模型id为100的所有设备生效：[{"field":"info_model_id","op":"=","value":"100"}] - 对标签id为21，标签值为test的所有设备生效：[{"field":"label-21","op":"=","value":"test"}] - 对标签id为21的所有设备生效：[{"field":"label-21","op":"=~","value":".+"}] - 对串号为s001的单个设备生效：[{"field":"third_things_id","op":"=","value":"s001"}] - 对分组id为113的设备生效：[{"field":"group-113","op":"=","value":"1"}] - 对网关id为87的设备生效：[{"field":"parent-87","op":"=","value":"1"}]
   表示满足数据条件 (a>=2 and b=40)
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
  */
  static edgeComputing_addSingleEdgeComputingRule({ tenantId, rule_name, rule_type, rule_content_off, rule_content_on, things_filter }: { tenantId: number, rule_name: string, rule_type: number, rule_content_off: string, rule_content_on: string, things_filter: object }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/edge-computing-rules`,
      method: 'post',
      data: { rule_name, rule_type, rule_content_off, rule_content_on, things_filter }
    }, 'alert:edge-computing-rule:create')
  }
  /**
  * 下发边缘计算规则
  * @param {string} [params.tenantId] 租户id
  * @param {string} [params.ruleId] 规则id
  * @param {string} [params.issuance_type] 指令类型 - 0 停用  - 1 启用
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
  */
  static edgeComputing_issueSingleEdgeComputingRule({ tenantId, ruleId, issuance_type }: { tenantId: number, ruleId: number, issuance_type: number }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/edge-computing-rules/${ruleId}/issuance`,
      method: 'post',
      data: { issuance_type }
    }, 'alert:edge-computing-rule:issuance')
  }
  /**
   * 更新边缘计算规则
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static edgeComputing_updateSingleEdgeComputingRule({ tenantId, ruleId, rule_name, rule_type, things_filter, rule_content_on, rule_content_off, attachment }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/edge-computing-rules/${ruleId}`,
      method: 'put',
      data: { rule_name, rule_type, things_filter, rule_content_on, rule_content_off, attachment }
    }, 'alert:edge-computing-rule:update')
  }
  /**
  * 删除边缘计算规则
  * @param {number} [tenantId ] - 租户id
  * @param {number} [userId] - 用户id
  * @param {string} [groupIds] - 设备分组id串，以英文逗号,分隔
  */
  static edgeComputing_deleteSingleEdgeComputingRule({ tenantId, ruleId }: { tenantId: number, ruleId: number }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/edge-computing-rules/${ruleId}`,
      method: 'delete',
    }, 'alert:edge-computing-rule:delete')
  }
  /**
   * 查询边缘计算规则下发历史（分页），创建时间倒序排列
   * @param {number} [params.tenantId] 租户id
   * @param {number} [params.current_page] 当前页
   * @param {number} [params.page_size] 每页条数
   * @param {string} [params.rule_id] 规则id
   * @param {number} [params.rule_type] 规则类型 - 1 映射 - 2 告警 - 3 定时
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static edgeComputing_getBatchEdgeRuleIssuanceHistory({ tenantId, rule_type, rule_id, page_size, current_page }: { tenantId: number, rule_type: number, rule_id: string, page_size: number, current_page: number }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/edge-computing-rule-issuance-histories`,
      method: 'get',
      params: { rule_type, rule_id, page_size, current_page }
    })
  }
  /**
   * 删除边缘计算规则下发历史
   * @param {number} [params.tenantId] 租户id
   * @param {number} [params.rule_type] rule_type
   * @param {array} [params.ids] 逗号分隔的id列表
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static edgeComputing_deleteEdgeIssuanceHistories({ tenantId, rule_type, ids }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/edge-computing-rule-issuance-histories`,
      method: 'delete',
      params: { rule_type, ids }
    }, 'alert:edge-computing-rule-issuance-history:delete')
  }
  /*******************************************************  httpAction  *****************************************************/
  /*******************************************************  management  *****************************************************/


  /*******************************************************  ruleActions  *****************************************************/
  /**
   * 查询告警规则的后续操作
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.ruleId] 规则id
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static ruleActions_getBatchRuleActions({ tenant_id, ruleId }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${ruleId}/action`,
      method: 'get',
      // params: {  }
    })
  }
  /**
   * 生成payload_script
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.build_type] 转换的类型，目前暂时写死'simple'
   * @param {object} [params.post_message_param]
   * @param {number} [params.post_message_param.headers] 写死成空字符串
   * @param {string} [params.post_message_param.payload] 业务参数格式不同，例如告警规则中，此处放置触发告警后的动作，即sms、email、command等
   * @param {array} [params.post_message_param.things_selector] 设备范围，例如：“[{“field”:“info_model_id”, “value”:“2"}]表示设备类型id为2的所有设备
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static ruleActions_getSinglePayloadsciprt({ tenant_id, build_type, post_message_param }: any) {
    return this.fetch({
      url: `​/alert-mgmt​/tenants​/${tenant_id}​/rule-actions​/payload-sciprt`,
      method: 'post',
      data: { build_type, post_message_param }
    })
  }
  /**
   * 告警规则下新建后续操作
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.ruleId ] 规则ID
   * @param {object} [params.build_payload_param] 操作内容
   * @param {string} [params.build_payload_param.build_type] 转换的类型，目前暂时写死'simple'
   * @param {string} [params.build_payload_param.post_message_param] 业务参数
   * @param {number} [params.build_payload_param.post_message_param.headers] 写死成空字符串
   * @param {string} [params.build_payload_param.post_message_param.payload] 业务参数格式不同，例如告警规则中，此处放置触发告警后的动作，即sms、email、command等
   * @param {array} [params.build_payload_param.post_message_param.things_selector] 设备范围，例如：“[{“field”:“info_model_id”, “value”:“2"}]表示设备类型id为2的所有设备

   * @param {object} [params.config] 转发的目标，短信格式："config": {"phone_list":["1283109312","1239909132]}，邮件格式："config": {"mail_list":["123@qq.com","jjj@google.com"]}，命令格式："config": {"send_message_action_id":1}
   * @param {object} [params.name] 操作名称
   * @param {object} [params.payload_script] 脚本要求，置空，由后端使用build_payload_param中的参数生成groovy脚本后，自行填充
   * @param {object} [params.payload_script_engine] 置空
   * @param {object} [params.payload_type] 0使用本地脚本,1使用远程代码
   * @param {number} [params.status] 启用状态，写死1，默认直接启用
   * @param {object} [params.type] 转发类型-现在支持:> - kafka 转发给kafka; - things-message 下发数据给things; - sms 发短信; - email 发邮件; - mqtt 转发mqtt； - mongo 存储mongo； - http 请求http(s) post/put - zlg-cloud zlg云内部http api - app 应用消息
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static ruleActions_addBatchAlertAction({ tenant_id, ruleId, build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${ruleId}/action`,
      method: 'post',
      data: { build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }
    }, 'alert:rule-action:update')
  }
  /**
   * 修改单个操作
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.ruleId] 规则id
   * @param {number} [params.actionId] 操作id
   * @param {object} [params.info] 告警规则信息
   * @param {number} [params.info.data_type] 数据类型,1设备数据，2设备日志，3设备状态
   * @param {string} [params.info.name] 告警规则名称
   * @param {number} [params.info.status] 规则状态,1启用，2禁用
   * @param {object} [params.info.time_condition] 告警方式,“在m秒内达到n次则告警“：{“times”: 10, “duration”: 60}
   * @param {object} [params.info.trigger_action] 告警动作
   * @param {object} [params.info.trigger_condition] 告警条件，例如：“[{“field”:“a”, “op”:“>=“, “value”:“2"}, {“field”:“b”, “op”:“=”, “value”:“40"}]
   * @param {object} [params.info.trigger_filter] 触发的过滤条件，用于设定规则匹配范围
   * @param {string} [params.info.trigger_way] 触发方式：1云端触发，2边缘计算
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static ruleActions_updateSingleAlertAction({ tenant_id, ruleId, actionId, build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${ruleId}/action/${actionId}`,
      method: 'put',
      data: { build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }
    }, 'alert:rule-action:update')
  }
  /**
   * 批量删除告警动作
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.ruleId] 规则id
   * @param {string} [params.idList ] 告警动作id，"id,id,id"
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static ruleActions_deleteBatchAlertActions({ tenant_id, ruleId, idList }: { tenant_id: number, ruleId: number, idList: string }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${ruleId}/action`,
      method: 'delete',
      params: { idList }
    }, 'alert:rule-action:delete')
  }

  /**
   * 转发规则新增action
   * @param tenant_id 租户id
   * @param rule_id 规则id
   * @param build_payload_param 写死{"build_type": "simple","post_message_param": {}}
   * @param config 转发配置信息，根据转发类型填写
   * kafka
           {"servers":"192.168.24.11:9092","topic":"froward-topic-tenant-#{#ctxInfo['tenantName']}","dynamic-topic":true}
    mqtt
            {"brokerurl":"tcp://192.168.24.19:1884","username":"","password":"","clientid":"testasdasda","topic":"test","dynamic-topic":false}
    http(s)
            {"url":"http(s)://172.16.17.193:3000","method":"post/put"}
    mongodb
            {"uri":"mongodb://admin:123456@192.168.24.19:27018","db":"test","collection":"test123"}
   * @param name 操作名称
   * @param payload_script 过滤功能块选项脚本 #data['data'].?[{'voltage','electricity'}.contains(key)]
   * @param payload_script_engine 脚本引擎 SpEL
   * @param payload_type 脚本类型（0使用本地脚本,1使用远程代码） 写死0
   * @param status 启用状态（0：停止，1：启用）
   * @param type 转发类型
   * @returns
   */
  static ruleActions_addThingsForwardAction({ tenant_id, rule_id, build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }: any) {

    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/things-forward/${rule_id}/action`,
      method: 'post',
      data: { build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }
    }, 'alert:things-forward-action:create')
  }

  /**
   * 转发规则修改action
   * @param tenant_id 租户id
   * @param rule_id 规则id
   * @param build_payload_param 写死{"build_type": "simple","post_message_param": {}}
   * @param config 转发配置信息，根据转发类型填写
   * kafka
           {"servers":"192.168.24.11:9092","topic":"froward-topic-tenant-#{#ctxInfo['tenantName']}","dynamic-topic":true}
    mqtt
            {"brokerurl":"tcp://192.168.24.19:1884","username":"","password":"","clientid":"testasdasda","topic":"test","dynamic-topic":false}
    http(s)
            {"url":"http(s)://172.16.17.193:3000","method":"post/put"}
    mongodb
            {"uri":"mongodb://admin:123456@192.168.24.19:27018","db":"test","collection":"test123"}
   * @param name 操作名称
   * @param payload_script 过滤功能块选项脚本 #data['data'].?[{'voltage','electricity'}.contains(key)]
   * @param payload_script_engine 脚本引擎 SpEL
   * @param payload_type 脚本类型（0使用本地脚本,1使用远程代码） 写死0
   * @param status 启用状态（0：停止，1：启用）
   * @param type 转发类型
   * @returns
   */
  static ruleActions_updateThingsForwardAction({ tenant_id, action_id, rule_id, build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/things-forward/${rule_id}/action/${action_id}`,
      method: 'put',
      data: { build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }
    }, 'alert:things-forward-action:update')
  }

  /**
   * 删除数据转发操作
   * @param param0
   * @returns
   */
  static ruleActions_deleteThingsForwardAction({ tenant_id, rule_id, action_id }: any) {
    return this.fetch({
      url: `alert-mgmt/tenants/${tenant_id}/things-forward/${rule_id}/action/${action_id}`,
      method: 'delete'
    }, 'alert:rule-action:delete')
  }

  /**
   * 更新规则action状态
   * @param param0
   * @returns
   */
  static ruleActions_updateThingsForwardActionStatus({ tenant_id, rule_id, action_id, status }: any) {
    return this.fetch({
      url: `alert-mgmt/tenants/${tenant_id}/things-forward/${rule_id}/action/${action_id}/status/${status}`,
      method: 'put'
    }, 'alert:things-forward-action:update')
  }

  /*******************************************************  ruleActionsRecovery  *****************************************************/
  /**
  * 查询告警规则的恢复告警动作
  * @param {number} [params.tenant_id] 租户id
  * @param {number} [params.rule_id] 规则id
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
  */
  static ruleActions_getBatchRuleActionRecovery({ tenant_id, rule_id }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${rule_id}/action-recovery`,
      method: 'get',
    })
  }

  /**
   * 告警规则下新建恢复告警动作
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.ruleId ] 规则ID
   * @param {object} [params.build_payload_param] 操作内容
   * @param {string} [params.build_payload_param.build_type] 转换的类型，目前暂时写死'simple'
   * @param {string} [params.build_payload_param.post_message_param] 业务参数
   * @param {number} [params.build_payload_param.post_message_param.headers] 写死成空字符串
   * @param {string} [params.build_payload_param.post_message_param.payload] 业务参数格式不同，例如告警规则中，此处放置触发告警后的动作，即sms、email、command等
   * @param {array} [params.build_payload_param.post_message_param.things_selector] 设备范围，例如：“[{“field”:“info_model_id”, “value”:“2"}]表示设备类型id为2的所有设备

   * @param {object} [params.config] 转发的目标，短信格式："config": {"phone_list":["1283109312","1239909132]}，邮件格式："config": {"mail_list":["123@qq.com","jjj@google.com"]}，命令格式："config": {"send_message_action_id":1}
   * @param {object} [params.name] 操作名称
   * @param {object} [params.payload_script] 脚本要求，置空，由后端使用build_payload_param中的参数生成groovy脚本后，自行填充
   * @param {object} [params.payload_script_engine] 置空
   * @param {object} [params.payload_type] 0使用本地脚本,1使用远程代码
   * @param {number} [params.status] 启用状态，写死1，默认直接启用
   * @param {object} [params.type] 转发类型-现在支持:> - kafka 转发给kafka; - things-message 下发数据给things; - sms 发短信; - email 发邮件; - mqtt 转发mqtt； - mongo 存储mongo； - http 请求http(s) post/put - zlg-cloud zlg云内部http api - app 应用消息
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static ruleActions_addBatchAlertActionRecovery({ tenant_id, ruleId, build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${ruleId}/action-recovery`,
      method: 'post',
      data: { build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }
    }, 'alert:rule-action:update')
  }

  /**
   * 批量删除恢复告警动作
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.ruleId] 规则id
   * @param {string} [params.idList ] 告警动作id，"id,id,id"
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static ruleActions_deleteBatchAlertActionsRecovery({ tenant_id, ruleId, idList }: { tenant_id: number, ruleId: number, idList: string }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${ruleId}/action-recovery`,
      method: 'delete',
      params: { idList }
    }, 'alert:rule-action:delete')
  }

  /**
   * 修改单个恢复告警动作
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.ruleId] 规则id
   * @param {number} [params.actionId] 操作id
   * @param {object} [params.info] 告警规则信息
   * @param {number} [params.info.data_type] 数据类型,1设备数据，2设备日志，3设备状态
   * @param {string} [params.info.name] 告警规则名称
   * @param {number} [params.info.status] 规则状态,1启用，2禁用
   * @param {object} [params.info.time_condition] 告警方式,“在m秒内达到n次则告警“：{“times”: 10, “duration”: 60}
   * @param {object} [params.info.trigger_action] 告警动作
   * @param {object} [params.info.trigger_condition] 告警条件，例如：“[{“field”:“a”, “op”:“>=“, “value”:“2"}, {“field”:“b”, “op”:“=”, “value”:“40"}]
   * @param {object} [params.info.trigger_filter] 触发的过滤条件，用于设定规则匹配范围
   * @param {string} [params.info.trigger_way] 触发方式：1云端触发，2边缘计算
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static ruleActions_updateSingleAlertActionRecovery({ tenant_id, ruleId, actionId, build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${ruleId}/action-recovery/${actionId}`,
      method: 'put',
      data: { build_payload_param, config, name, payload_script, payload_script_engine, payload_type, status, type }
    }, 'alert:rule-action:update')
  }
  /******************************************************** tenants ************************************************************/
  /**
   *  查询全部租户的用量统计
   */
  static tenants_getAllUsageStatistics() {
    return this.fetch({
      url: '/alert-mgmt/tenants/all/usage-statistics',
      method: 'get'
    })
  }
  /**
   *  查询某租户的用量统计
   */
  static tenants_getUserUsageStatistics({ tenant_id }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/all/usage-statistics`,
      method: 'get'
    })
  }
  /*******************************************************  thingsForwardRules  *****************************************************/

  /**
   * 新增转发规则
   * @param tenant_id 租户id
   * @param custom_fields json
   * @param forward_condition 功能块 {  "engine": "SpEL", "script": "#data['event_name']=='LampV3FnBlock.uploadStatus'" }
   * @param name 规则名称
   * @param things_selector 触发的数据条件 { [{"field":"info_model_id","op": "=",,"value":"91"},{"field":"third_things_id","op": "=","value":"rrrrrrrr"}] }
   * @returns
   */
  static thingsForwardRules_addThingsForwardRules({ tenant_id, custom_fields, forward_condition, name, things_selector, type }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/things-forward`,
      method: 'post',
      data: { custom_fields, forward_condition, name, things_selector, type }
    })
  }

  /**
   * 修改转发规则
   * @param tenant_id 租户id
   * @param custom_fields json
   * @param forward_condition 功能块 {  "engine": "SpEL", "script": "#data['event_name']=='LampV3FnBlock.uploadStatus'" }
   * @param name 规则名称
   * @param things_selector 触发的数据条件 { [{"field":"info_model_id","op": "=",,"value":"91"},{"field":"third_things_id","op": "=","value":"rrrrrrrr"}] }
   * @param type 1普通数据推送，兼容现有的功能；当指定type为2时为raw数据推送
  * @returns
   */
  static thingsForwardRules_updateThingsForwardRules({ tenant_id, rule_id, custom_fields, forward_condition, name, things_selector, type }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/things-forward/${rule_id}`,
      method: 'put',
      data: { custom_fields, forward_condition, name, things_selector, type }
    })
  }

  /**
   * 分页查询数据转发
   * @param param0
   * @returns
   */
  static thingsForwardRules_getThingsForwardRules({ tenant_id, status, name, page_size, current_page }: any) {
    return this.fetch({
      url: `alert-mgmt/tenants/${tenant_id}/things-forward`,
      method: 'get',
      params: { status, name, page_size, current_page }
    }, "alert:things-forward-rules:query")
  }

  /**
   * 删除转发规则
   * @param param0
   * @returns
   */
  static thingsForwardRules_deleteThingsForwardRules({ tenant_id, rule_id }: any) {
    return this.fetch({
      url: `alert-mgmt/tenants/${tenant_id}/things-forward/${rule_id}`,
      method: 'delete'
    })
  }

  /**
   * 更新规则状态
   * @param param0
   * @returns
   */
  static thingsForwardRules_updateThingsForwardRulesStatus({ tenant_id, rule_id, status }: any) {
    return this.fetch({
      url: `alert-mgmt/tenants/${tenant_id}/things-forward/${rule_id}/status/${status}`,
      method: 'put'
    })
  }

  /**
   * 获取单个转发规则
   * @param param0
   * @returns
   */
  static thingsForwardRules_getSingleThingsForwardRules({ tenant_id, rule_id }: any) {
    return this.fetch({
      url: `alert-mgmt/tenants/${tenant_id}/things-forward/${rule_id}`,
      method: 'get'
    }, "alert:things-forward-rules:query")
  }


  /*******************************************************  triggerRules  *****************************************************/
  /**
   * 查询告警规则列表
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.page_size] 返回任务个数
   * @param {string} [params.ids] id列表(逗号分隔)
   * @param {number} [params.current_page] 跳过多少页数据
   * @param {string} [params.name] 规则名称
   * @param {number} [params.last_update_time] 最后一次更新时间
   * @param {number} [params.rule_type] 规则类型,1数据推送，2告警
   * @param {number} [params.status] 规则状态：1启用，2禁用
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static triggerRules_getBatchRules({ tenant_id, page_size, current_page, name, last_update_time, rule_type, status, ids, things_selector_key, things_selector_value, things_selector_type
    , condition_field, condition_op, condition_value, thingsIds
  }: any): Promise<GetBatchRulesResp | any> {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules`,
      method: 'get',
      params: { page_size, current_page, name, last_update_time, rule_type, status, ids, things_selector_key, things_selector_value, things_selector_type, condition_field, condition_op, condition_value, thingsIds }
    }, "alert:trigger-rule:query")
  }
  /**
   * 根据规则id查询告警规则详情
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.page_size] 返回任务个数
   * @param {number} [params.current_page] 跳过多少页数据
   * @param {string} [params.name] 规则名称
   * @param {number} [params.last_update_time] 最后一次更新时间
   * @param {number} [params.rule_type] 规则类型,1数据推送，2告警
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static triggerRules_getSingleAlertRule({ tenant_id, rule_id }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${rule_id}`,
      method: 'get',
    })
  }
  /**
   * 告警规则的启动和禁用状态更新
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.rule_id] 规则id
   * @param {number} [params.status] 更新状态,1启用,2禁用
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static triggerRules_updateRuleStatus({ tenant_id, rule_id, status }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${rule_id}/status/${status}`,
      method: 'put',
    }, 'alert:trigger-rule:update')
  }
  /**
   * 创建告警规则
   * @param {number} [params.tenant_id] 租户id
   * @param {object} [params.info] 告警规则信息
   * @param {number} [params.info.data_type] 数据类型,1设备数据，2设备日志，3设备状态
   * @param {string} [params.info.name] 告警规则名称
   * @param {number} [params.info.status] 规则状态,1启用，2禁用
   * @param {object} [params.info.time_condition] 告警方式,“在m秒内达到n次则告警“：{“times”: 10, “duration”: 60}
   * @param {object} [params.info.trigger_action] 告警动作，已废弃参数。使用rule-actions模块中的接口单独设置告警规则的后续操作
   * @param {object} [params.info.trigger_condition] 告警条件，例如：“[{“field”:“a”, “op”:“>=“, “value”:“2"}, {“field”:“b”, “op”:“=”, “value”:“40"}]
   * @param {object} [params.info.trigger_filter] 触发的过滤条件，用于设定规则匹配范围
   * @param {string} [params.info.trigger_way] 触发方式：1云端触发，2边缘计算
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static triggerRules_addSingleAlertRule({ tenant_id, data_type, name, status, time_condition, trigger_action, trigger_condition, trigger_filter, trigger_way }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules`,
      method: 'post',
      data: { data_type, name, status, time_condition, trigger_action, trigger_condition, trigger_filter, trigger_way }
    }, 'alert:trigger-rule:create')
  }
  /**
   * 更新告警规则
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.ruleId] 规则id
   * @param {object} [params.info] 告警规则信息
   * @param {number} [params.info.data_type] 数据类型,1设备数据，2设备日志，3设备状态
   * @param {string} [params.info.name] 告警规则名称
   * @param {number} [params.info.status] 规则状态,1启用，2禁用
   * @param {object} [params.info.time_condition] 告警方式,“在m秒内达到n次则告警“：{“times”: 10, “duration”: 60}
   * @param {object} [params.info.trigger_action] 告警动作
   * @param {object} [params.info.trigger_condition] 告警条件，例如：“[{“field”:“a”, “op”:“>=“, “value”:“2"}, {“field”:“b”, “op”:“=”, “value”:“40"}]
   * @param {object} [params.info.trigger_filter] 触发的过滤条件，用于设定规则匹配范围
   * @param {string} [params.info.trigger_way] 触发方式：1云端触发，2边缘计算
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static triggerRules_updateSingleAlertRule({ tenant_id, ruleId, data_type, name, status, time_condition, trigger_action, trigger_condition, trigger_filter, trigger_way }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${ruleId}`,
      method: 'put',
      data: { data_type, name, status, time_condition, trigger_action, trigger_condition, trigger_filter, trigger_way }
    }, 'alert:trigger-rule:update')
  }
  /**
   * 告警规则的删除
   * @param {string} [params.tenant_id] 租户id
   * @param {string} [params.jobId] 任务id
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static triggerRules_deleteSingleRule({ tenant_id, rule_id }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/rules/${rule_id}`,
      method: 'delete'
    }, 'alert:trigger-rule:delete')
  }

  /*******************************************************  twinsRules  *****************************************************/

  /**
   * 影子规则-获取规则列表
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.page_size] 返回任务个数
   * @param {string} [params.ids] id列表(逗号分隔)
   * @param {number} [params.current_page] 跳过多少页数据
   * @param {string} [params.name] 规则名称
   * @param {number} [params.shadow_type] 影子类型（1.状态、2.固件）
   * @param {number} [params.status] 规则状态：1启用，2禁用
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static twinsRules_getBatchTwinsRules({ tenant_id, page_size, current_page, name, shadow_type, status, ids }: {
    tenant_id: number,
    page_size?: number,
    current_page?: number,
    name?: string,
    shadow_type?: number,
    status?: number,
    ids?: string
  }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/twins-rules`,
      method: 'get',
      params: { page_size, current_page, name, shadow_type, status, ids }
    })
  }

  /**
   * 影子规则-根据多个设备ID反查最后生效的规则
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.shadow_type] 影子类型（1.状态、2.固件）
   * @param {string} [params.things_ids] things_ids格式 things_id,things_id
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static twinsRules_getBatchLatestTwinsRules({ tenant_id, shadow_type, things_ids }: {
    tenant_id: number,
    shadow_type?: number,
    things_ids?: string,
  }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/twins-rules/latestRules`,
      method: 'get',
      params: { shadow_type, things_ids }
    })
  }

  /**
   * 影子规则-创建告警规则
   * @param {number} [params.tenant_id] 租户id
   * @param {object} [params.info] 告警规则信息
   * @param {number} [params.info.shadow_type]  影子类型（1.状态、2.固件）
   * @param {string} [params.info.name] 影子规则名称
   * @param {number} [params.info.status] 规则状态,1启用，2禁用
   * @param {object} [params.info.trigger_condition] 告警条件，例如：“[{“field”:“a”, “op”:“>=“, “value”:“2"}, {“field”:“b”, “op”:“=”, “value”:“40"}]
   * @param {object} [params.info.trigger_filter] 触发的过滤条件，用于设定规则匹配范围
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static twinsRules_addSingleAlertTwinsRule({ tenant_id, shadow_type, name, status, trigger_condition, trigger_filter }: {
    tenant_id: number,
    name: string,
    shadow_type: number,
    status: number,
    trigger_condition: Array<any>,
    trigger_filter: Array<any>
  }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/twins-rules`,
      method: 'post',
      data: { shadow_type, name, status, trigger_condition, trigger_filter }
    })
  }

  /**
   * 根据影子规则id查询告警规则详情
   * @param {number} [params.tenant_id] 租户id
   * @param {string} [params.name] 规则名称
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static twinsRules_getSingleAlertTwinsRule({ tenant_id, rule_id }: {
    tenant_id: number,
    rule_id: number
  }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/twins-rules/${rule_id}`,
      method: 'get',
    })
  }

  /**
   * 更新影子规则
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.ruleId] 规则id
   * @param {object} [params.info] 告警规则信息
   * @param {number} [params.info.shadow_type] 影子类型（1.状态、2.固件）
   * @param {string} [params.info.name] 告警规则名称
   * @param {number} [params.info.status] 规则状态,1启用，2禁用
   * @param {object} [params.info.trigger_action] 告警动作
   * @param {object} [params.info.trigger_condition] 告警条件，例如：“[{“field”:“a”, “op”:“>=“, “value”:“2"}, {“field”:“b”, “op”:“=”, “value”:“40"}]
   * @param {object} [params.info.trigger_filter] 触发的过滤条件，用于设定规则匹配范围
   * @param {string} [params.info.trigger_way] 触发方式：1云端触发，2边缘计算
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static twinsRules_updateSingleAlertTwinsRule({ tenant_id, ruleId, shadow_type, name, status, trigger_condition, trigger_filter }: {
    tenant_id: number,
    ruleId: number,
    name: string,
    shadow_type: number,
    status: number,
    trigger_condition: Array<any>,
    trigger_filter: Array<any>
  }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/twins-rules/${ruleId}`,
      method: 'put',
      data: { shadow_type, name, status, trigger_condition, trigger_filter }
    })
  }

  /**
   * 删除影子规则
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.rule_id] 规则id
   */
  static twinsRules_deleteSingleAlertTwinsRule({ tenant_id, rule_id }: { tenant_id: number, rule_id: number }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenant_id}/twins-rules/${rule_id}`,
      method: 'delete',
    })
  }
}

export default ZWSAlertMgmt
