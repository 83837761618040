import ZWSBaseModule from '../httpBase/ZWSBaseModule'


/**
 * 固件管理
 */
class ZWSOutFileManager extends ZWSBaseModule {

  /************* file ********************************************************************************************/

  /**
   * 获取上传的文件列表
   * @param 
   * @returns 
   */
  static file_getFileList({ tenant_id, begin_time, end_time, current_page, page_size, type }: { tenant_id: number, begin_time: number, end_time: number, current_page: number, page_size: number, type: string }) {
    return this.fetch({
      url: `/out-filemanager/tenants/${tenant_id}/files/list`,
      method: 'get',
      params: { tenant_id, begin_time, end_time, current_page, page_size, type }
    })
  }
  /**
   * 上传完毕的,获取文件信息, 没上传完毕的,获取文件当前状态
   * @param
   * @returns
   */
  static file_getFileUrl({ name, }: { name: string }) {
    return this.fetch({
      url: `/out-filemanager/files`,
      method: 'get',
      params: { name }
    })
  }

  /**
   * 上传完毕的,获取文件信息, 没上传完毕的,获取文件当前状态
   * @param
   * @returns
   */
  static file_getFilesUrl({ tenant_id, name, type }: { tenant_id: number, name: string, type: string }) {
    return this.fetch({
      url: `/out-filemanager/tenants/${tenant_id}/files`,
      method: 'get',
      params: { name, type }
    })
  }

  /************* package ********************************************************************************************/

  /**
   * 获取单个安装包
   * @param {string} [params.pkgtype] 安装包型号, 仅限填写数字，字母，小数点
   * @param {string} [params.version ] 安装包版本号, 仅限填写数字，字母，小数点
   * @param {string} [params.suffix] 安装包文件类型后缀

   * @returns
   */
  static package_getSinglePackage({ tenant_id, pkgtype, version, suffix }: { tenant_id: number, pkgtype: string, version: string, suffix?: string }) {
    return this.fetch({
      url: `/out-filemanager/tenants/${tenant_id}/package/${pkgtype}/info`,
      method: 'get',
      params: { version, suffix }
    })
  }


  /**
   * 删除单个安装包
   * @param {string} [params.pkgtype] 安装包型号, 仅限填写数字，字母，小数点
   * @param {string} [params.version ] 安装包版本号, 仅限填写数字，字母，小数点
   * @param {string} [params.suffix] 安装包文件类型后缀
   */
  static package_deleteSinglePackage({ tenant_id, pkgtype, version, suffix }: { tenant_id: number, pkgtype: string, version: string, suffix?: string }) {
    return this.fetch({
      url: `/out-filemanager/tenants/${tenant_id}/package/${pkgtype}/remove`,
      method: 'delete',
      params: { version, suffix }
    })
  }

  /**
   * 上传安装包
   * @param {string} [params.pkgtype] 安装包型号, 仅限填写数字，字母，小数点
   * @param {File} [params.file ] formData
   * @param {string} [params.version] formData 安装包版本号
   * @param {string} [params.desc] formData 描述
   */
  static package_addSinglePackage({ tenant_id, pkgtype, file, version, desc }: {
    tenant_id: number,
    pkgtype: string,
    file: File
    version: string
    desc?: string
  }) {
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('version', version);
    if (desc) {
      formData.append('desc', desc)
    }

    return this.fetch({
      url: `/out-filemanager/tenants/${tenant_id}/package/${pkgtype}/upload`,
      method: 'post',
      data: formData,
      timeout: 999999999999,
      needCancelObj: {
        name: 'package_addSinglePackage'
      }
    }, 'filemanager:package:upload')
  }

  /**
   * 获取全部安装包
   * @returns
   */
  static package_getBatchPackage({ tenant_id }: {
    tenant_id: number,
  }) {
    return this.fetch({
      url: `/out-filemanager/tenants/${tenant_id}/package/allPackages`,
      method: 'get',
    })
  }

  /**
   * 获取最新安装包
   * @param {string} [params.pkgtype] 安装包型号, 仅限填写数字，字母，小数点
   * @returns
   */
  static package_getLastPackage({ tenant_id, pkgtype }: { tenant_id: number, pkgtype: string }) {
    return this.fetch({
      url: `/out-filemanager/tenants/${tenant_id}/package/lastPackage`,
      method: 'get',
      params: { pkgtype }
    })
  }
}

export default ZWSOutFileManager
