<template>
  <div class="company-wrapper">
    <ZInputPersonalInfoFormArea ref="inputPersonalInfoForm" @returnInputInfo="returnInputInfo"> </ZInputPersonalInfoFormArea>
    <div class="button-wrapper">
      <el-button type="primary" icon="iconfont iconshangyibu" @click="backStep">{{ t('lib.register.back') }}</el-button>
      <el-button class="registButton" type="primary" icon="iconfont iconzhuce" @click="register">{{ t('lib.register.name') }}</el-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import ZInputPersonalInfoFormArea from '../../ZCommonArea/ZInputPersonalInfoFormArea.vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
// import { useLocale } from '../../../hooks/useLocale'
export default defineComponent({
  components: {
    ZInputPersonalInfoFormArea
  },
  setup(props, context) {
    // let { t } = useLocale()
    let { t } = useI18n()
    const store = useStore()
    const inputPersonalInfoForm = ref()
    function register() {
      inputPersonalInfoForm.value.returnInputInfo()
    }
    function returnInputInfo(value: any) {
      context.emit('register', value)
    }
    function backStep() {
      context.emit('backStep')
    }
    return {
      t,
      inputPersonalInfoForm,
      returnInputInfo,
      backStep,
      register
    }
  }
})
</script>
<style lang="less" scoped>
.company-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 550px;
  .input-wrapper {
    width: 305px;
    height: 44px;
  }
  .label-text {
    font-size: 14px;
    color: #999999;
  }
  .tips-wrapper {
    display: flex;
    flex-direction: row;
    width: 550px;
    margin-top: 10px;
    margin-left: 15px;
    .tips {
      font-size: 14px;
      color: #333333;
      width: 80px;
      margin-left: 55px;
      margin-top: 1px;
    }
    .tips-content {
      width: 425px;
      margin-left: 0px;
    }
  }
}
.button-wrapper {
  width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
