
import ZWSBaseModule from '../httpBase/ZWSBaseModule'
import ZWSTenant from './ZWSTenant'
import { Pagination } from './ZWSPagination'
import ZWSOutWrapper from './ZWSOutWrapper'

import type { GetBatchSubUsersResp } from './ZWSTenant'

/************* things **************************************************************************************************/

export interface ThingsNumber {
    info_model_id: number
    things_number: number
}

export interface ThingsBase {
    attributes: string
    info_model_id: number
    third_things_id: string
    tenant_id?: number
    thing_type?: number
    digital_twin_things_status?: any
    [propsName: string]: any
}

export interface Things extends ThingsBase {
    id: number
}

export interface GetSingleThingResp extends Things { }
export interface GetThingsResp {
    list: Things[],
    pagination: Pagination
}

export enum ChannelStatus {
    NONE,
    ADD,
    DELETE,
    MODIFIED
}

export interface Channel {
    name?: string
    index: number
    fullname: string
    id: number                  // 通道ID
    typeId: number              // 通道类型ID
    deviceId: number            // 通道所属设备的ID
    signalStrategy: string      // 信号策略
    status?: ChannelStatus      // 通道的状态（ADD：增加、DELETE：删除、MODIFIED：改变）
    [propName: string]: any
}

export interface GetSingleThingChannelsResp extends Array<Channel> { }
export type GetChannelListFromThingsResp = Channel[]

export interface GetGPSResp {
    GPSTime: number
    latitude: number
    longitude: number
}
export type GetBatchThingsNumByDevtypeResp = {
    info_model_id: number,
    things_number: number
}[]

/************* messages ************************************************************************************************/

// export type SendMessagesToDeviceResp = number
export interface SendMessagesToDeviceResp {
    messageId: number
    timestamp: number
}

/************* userResource ********************************************************************************************/

export type GetSingleUserBindThings = number[]

/**
  * 设备管理
  */
class ZWSThings extends ZWSBaseModule {

    /************* usageStatistics *****************************************************************************************/

    /** 
     *  查询全部租户的用量统计
     */
    static usageStatistics_getAllUsageStatistics() {
        return this.fetch({
            url: '/things/tenants/all/usage-statistics',
            method: 'get'
        })
    }
    /************* userResource ********************************************************************************************/
    /**
     * 获取指定用户绑定的设备id列表
     * @param {*} param0 
     * @returns 
     */
    static userResource_getSingleUserBindThings({ tenant_id, user_id, include_create, is_manager }: {
        tenant_id: number,
        user_id: number,
        include_create?: boolean,
        is_manager?: boolean
    }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/users/${user_id}/things`,
            method: 'get',
            params: { is_manager, include_create }
        }, 'things:user-things:query')
    }
    /**
    * 更新指定用户绑定的设备id列表
    * @param {number} params.tenantId - 租户id
    * @param {number} params.userId - 子用户id
    * @param {Array} params.data - 设备id组成的数组
    * @returns 
    */
    static userResource_updateSingleUserBindThings({ tenantId, userId, data }: { tenantId: number, userId: number, data: any }) {
        return this.fetch({
            url: `/things/tenants/${tenantId}/users/${userId}/things`,
            method: 'put',
            data: data
        }, 'things:user-things:update')
    }

    // 根据父设备权限绑定子设备权限   
    static userResource_updateSingleUserBindThingsByParentThing({ tenantId, userId, parentThingId, thingIds }: { tenantId: number, userId: number, parentThingId: number, thingIds: number[] }) {
        return ZWSThings.userResource_getSingleUserBindThings({ tenant_id: tenantId, user_id: userId }).then((res: GetSingleUserBindThings) => {
            let pid = res?.find((id) => {
                return parentThingId == id
            })

            if (!pid) return

            return ZWSThings.userResource_updateSingleUserBindThings({ tenantId, userId, data: thingIds })
        })
    }

    /************* digitalTwinThings ***************************************************************************************/

    // 通过双生设备状态查询双生设备

    static digitalTwinThings_getDigitalTwinThings({ tenant_id, status_name, status_value, info_model_ids, current_page, page_size, things_ids }: { tenant_id: number, status_name: string, status_value: string, info_model_ids: number, current_page: number, page_size: number, things_ids: number }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/digital-twin-things`,
            method: 'get',
            params: { status_name, status_value, info_model_ids, current_page, page_size, things_ids }
        })
    }
    // 批量更新设备可配置属性
    static digitalTwinThings_updateBatchAttributes({ info_model_id, third_things_id, body }: any) {
        return this.fetch({
            url: `/things/info-models/${info_model_id}/digital-twin-things/${third_things_id}/batch-update`,
            method: 'post',
            data: body
        })
    }
    // 更新设备可配置属性
    static digitalTwinThings_updateAttributes({ tenant_id, things_id, api_update_digital_twin_device }: any) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/digital-twin-things/${things_id}`,
            method: 'post',
            data: api_update_digital_twin_device
        })
    }
    /************* infoModel ***********************************************************************************************/
    /**
    * 根据标签名和标签值，查询其绑定的所有设备的设备类型列表
    * @param {number} params.tenant_id 
    * @param {number} params.label_id 
    * @param {string} params.label_value 
    * @returns 
    */
    static infoModel_getInfomodelByValue({ tenant_id, label_id, label_value }: { tenant_id: number, label_id: number, label_value: string }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/info-model-list`,
            method: 'get',
            params: { label_id, label_value }
        })
    }

    /************* messages ************************************************************************************************/
    /**
      * 给单个设备发命令
      * @param {*} param
      * @returns 
      */
    static messages_sendMessagesToDevice({ tenant_id, things_id, info }: {
        things_id: number,
        tenant_id: number,
        info: any
    }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/${things_id}/messages`,
            method: 'post',
            data: info
        })
    }

    // 生成设备通用命令
    static messages_genCommonMessage({ things, cmdName, cmdArgs }: { things: Things, cmdName: string, cmdArgs: any }) {
        let status = things?.digital_twin_things_status

        if (!status) return

        let operation_name

        for (let key in status) {
            if (!/can_\S+_fn/.test(key) && !/candtu_\S+_fn/.test(key)) continue
            operation_name = `${key}.${cmdName}`
            break
        }

        if (!operation_name) return

        let operation: any = { operation_name, operation_params: cmdArgs }

        try {
            operation = JSON.stringify(operation)
        } catch {
            return
        }

        let msg: any = {}

        msg.headers = '{}'
        msg.info_model_id = things.info_model_id
        msg.message_type = "0"
        msg.payload = operation
        msg.tenant_id = things.tenant_id
        msg.things_id = things.id
        msg.things_type = things.thing_type
        msg.third_things_id = things.third_things_id

        return msg
    }

    /**
     * 批量设备发送命令
     * @param param0 
     * @returns 
     */
    static messages_sendBatchMessages({ tenant_id, headers, payload, things_selector }: {
        headers: string,
        tenant_id: number,
        payload: string,
        things_selector: any[]
    }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/messages`,
            method: 'post',
            data: { headers, payload, things_selector }
        })
    }

    /**
     * 获取指定批次的消息
     * @param param0 
     * @returns 
     */
    static messages_getBatchMessagesData({ tenant_id, batchNo, current_page = 1, page_size = 100 }: {
        batchNo: string,
        tenant_id: number,
        current_page: number,
        page_size: number
    }) {

        return this.fetch({
            url: `/things/tenants/${tenant_id}/message-batches/${batchNo}/messages`,
            method: 'get',
            params: { current_page, page_size }
        })
    }

    /**
      * 获取消息响应
      * @param {*} param
      * @returns 
      */
    static messages_getMessagesResponse({ tenant_id, message_ids, start_date, file_index, offset }: any) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/messages/${message_ids}/response`,
            method: 'get',
            params: {
                startDate: start_date,
                file_index,
                offset
            }
        })
    }

    /**
     * 批量查询设备消息的响应
     * @param param0 
     * @returns 
     */
    static messages_getBatchMessagesResponse({ message_ids, start_date }: { message_ids: string, start_date: number }) {
        return this.fetch({
            url: `/things/messages/response`,
            method: 'get',
            params: { startDate: start_date, messageIds: message_ids }
        })
    }

    static messages_getMessagesResponseByCorrelation({ correlation_id, tenant_id }: { correlation_id: string, tenant_id: number }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/correlation-id/${correlation_id}/message`,
            method: 'get'
        })
    }

    /************* prometheusMetricsExporter *******************************************************************************/
    /************* standByMessages *****************************************************************************************/
    /************* things **************************************************************************************************/
    /**
      * 添加设备
      * @param {*} param
      * @returns 
      */
    static things_addThings({ tenant_id, api_base_things_request }: { tenant_id: number, api_base_things_request: any }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things`,
            method: 'post',
            data: api_base_things_request
        },"things:things:create")
    }
    /**
     * 查询用户下的设备列表
     * @param {*} param
     * @returns 
     */
    static things_getThings({ tenant_id, current_page, info_model_ids, label_id, label_value, is_online, namespace, page_size, prefix, things_type, third_things_id, connection_status, order_param, order_type }: {
        tenant_id: number,
        current_page: number,
        info_model_ids?: string,
        label_id?: number,
        label_value?: string,
        is_online?: number,
        namespace?: string,
        page_size: number,
        prefix?: string,
        things_type?: number,
        third_things_id?: string,
        connection_status?: number,
        order_type?: number,
        order_param?: string
    }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things`,
            method: 'get',
            params: { current_page, info_model_ids, label_id, label_value, is_online, namespace, page_size, prefix, things_type, third_things_id, connection_status, order_type, order_param }
        })
    }
    /**
     * 查找单个设备信息
     * @param {*} param
     * @returns 
     */
    static things_getSingleThing({ id, tenant_id }: {
        tenant_id: number,
        id: number
    }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/${id}`,
            method: 'get'
        })
    }

    // 获取单个设备的通道信息
    static things_getSingleCandtuThingChannels({ id, tenant_id }: {
        tenant_id: number,
        id: number
    }) {
        return ZWSThings.things_getSingleThing({ tenant_id, id }).then((res: GetSingleThingResp) => {

            let attrs: any = res.attributes

            if (!attrs) {
                return []
            }

            try {
                attrs = JSON.parse(attrs)
            } catch {
                return Promise.reject()
            }

            return attrs.channels ?? []
        })
    }

    static things_getThingsDigitalInfo(things: Things, pattern?: any) {
        let info
        let status = things?.digital_twin_things_status

        if (!status) return

        for (let key in status) {
            let match = false

            if (pattern) {
                if (pattern.test(key)) match = true
            }
            else if (/^can_\S+_fn/.test(key) || /^candtu_\S+_fn/.test(key)) match = true

            if (match) {
                info = status[key]
                break;
            }
        }

        return info
    }

    static things_getECUUpdateStatus(things: Things) {
        let info = ZWSThings.things_getThingsDigitalInfo(things)
        return info?.FlashStat
    }

    static things_getECUUpdateResult(things: Things) {
        let info = ZWSThings.things_getThingsDigitalInfo(things)
        return info?.FlashOk
    }

    static things_getGPS({ tenantId, thingsId }: { tenantId: number, thingsId: number }) {
        return ZWSThings.things_getSingleThing({
            tenant_id: tenantId,
            id: thingsId,
        }).then((res: GetSingleThingResp) => {
            let info = ZWSThings.things_getThingsDigitalInfo(res)
            return info?.gps
        })
    }

    // 从设备信息中心获取通道信息
    static things_getChannelListFromThings(things: Things) {
        let channels: Channel[] = []

        let info = ZWSThings.things_getThingsDigitalInfo(things)
        if (!info) return []
        let canInfo = info.CANInfo || info.CfgInfo?.CAN
        if (!canInfo) return []

        for (let can in canInfo) {
            channels.push({
                fullname: can,
                index: parseInt(can.substring(3)),
                id: -1,
                typeId: -1,
                deviceId: things.id,
                signalStrategy: '',
            })
        }

        return channels.sort((ch1: Channel, ch2: Channel) => {
            return ch1.index - ch2.index
        })
    }

    // 根据设备ID获取通道信息
    static things_getChannelListFromThingsId({ tenant_id, things_id }: { tenant_id: number, things_id: number }) {
        return ZWSThings.things_getSingleThing({ tenant_id, id: things_id }).then((res: GetSingleThingResp) => {
            return ZWSThings.things_getChannelListFromThings(res)
        })
    }

    // 更新单个设备的通道信息
    static things_updateSingleCandtuThingChannels({ id, tenant_id, channels }: {
        tenant_id: number,
        id: number,
        channels: Channel[]
    }) {
        return ZWSThings.things_getSingleThing({ tenant_id, id }).then((res: GetSingleThingResp) => {
            let thing = res
            let attrs: any = thing.attributes

            if (attrs == '') {
                attrs = {}
            } else {
                try {
                    attrs = JSON.parse(attrs)
                } catch {
                    return Promise.reject()
                }
            }

            // 记录删除掉的通道设备ID和新增的通道设备ID
            let channelsDel = [] as number[]
            let channelsAdd = [] as number[]

            function buildChannels(channels: Channel[]) {
                let ps: any[] = []

                channels = channels.filter((channel: Channel) => {
                    return channel.typeId >= 0
                })

                channels.forEach((channel) => {
                    function generateChannelID(channel: Channel, old?: boolean) {
                        return !old && (channel.lastTypeId > -1 || channel.typeId > -1) ? `${channel.deviceId}_${channel.typeId}_${channel.index}` : `${channel.deviceId}_${channel.lastTypeId}_${channel.index}`
                    }

                    function buildThing() {
                        let name = `CANDTU_CHANNEL_${generateChannelID(channel)}`
                        let last_name = `CANDTU_CHANNEL_${generateChannelID(channel, true)}`

                        return {
                            parent_things_id: channel.deviceId,
                            attributes: '' + JSON.stringify({ channel: channel.index }),
                            name,
                            last_name,
                            info_model_id: channel.typeId,
                            thing_type: 0,
                            things_key: 'CANDTU_CHANNEL',
                            third_things_id: name,
                            last_third_things_id: last_name,
                        }
                    }

                    let subThing = buildThing()

                    function buildChannel(thing: ThingsBase) {
                        function delOldChannel(name?: string) {
                            if (name === undefined) return Promise.resolve()
                            if (channel.status === ChannelStatus.ADD) return Promise.resolve()

                            return ZWSThings.things_getThings({ tenant_id, current_page: 1, page_size: 2, prefix: name }).then((res: GetThingsResp) => {
                                if (res.list.length == 0) return Promise.resolve()

                                let thing = res.list[0]

                                return ZWSThings.things_deleteBatchThings({ tenant_id, ids: `${thing.id}` }).then(() => {
                                    channelsDel.push(channel.id)
                                })
                            })
                        }

                        if (channel.status === ChannelStatus.NONE) return Promise.resolve()

                        return delOldChannel(thing.last_name).then(() => {
                            if (channel.status === ChannelStatus.DELETE) return Promise.resolve()
                            if (!channel.signalStrategy || channel.typeId < 0) return Promise.resolve()

                            return ZWSOutWrapper.things_addThings({ tenant_id, api_base_things_request: thing }).then((id: number) => {
                                // return ZWSSingleCandtu.things_addThings({ tenant_id, api_base_things_request: thing }).then((id: number) => {
                                // return ZWSThings.things_addThings({ tenant_id, api_base_things_request: thing }).then((id: number) => {
                                channelsAdd.push(channel.id)

                                return channel.id = id
                            })
                        })
                    }

                    ps.push(buildChannel(subThing))
                })

                return ps
            }

            return Promise.all(buildChannels(channels)).then((things: Things[]) => {
                // 让子用户获得新增的通道设备权限
                ZWSTenant.tenantUsers_getBatchSubUsers({ tenant_id, current_page: 1, page_size: 1000 }).then((res: GetBatchSubUsersResp) => {
                    res.list?.forEach((user) => {
                        ZWSThings.userResource_getSingleUserBindThings({ tenant_id, user_id: user.id }).then((things: number[]) => {
                            things = things.filter((thing) => {
                                return !channelsDel.includes(thing)
                            })
                            things = Array.from(new Set(things.concat(channelsAdd)))
                            ZWSThings.userResource_updateSingleUserBindThings({ tenantId: tenant_id, userId: user.id, data: things })
                        })
                    })
                })

                attrs.channels = channels

                try {
                    thing.attributes = JSON.stringify(attrs)
                } catch {
                    return Promise.reject()
                }

                return thing
            })
        }).then((thing: Things) => {
            return ZWSThings.things_updateThings({ tenant_id, devid: id, api_base_things_request: thing })
        })
    }

    /**
     * 批量查询设备信息（通过third_things_id和info_model_id 或 单独使用 things_id） 
     * @param tenantId 租户ID
     * @param things_ids 设备ID信息
     * @param type 查询类型，1为根据第三方id和设备类型id查询，things_ids拼接方式为info_model_id,third_things_id|info_model_id,third_things_id；2为根据设备id查询, things_ids拼接方式为 things_id,things_id
     * @returns 
     */
    static things_getBatchThingswithoutPage({ tenant_id, things_ids, type, query_label }: { tenant_id: number, things_ids: string, type: number, query_label?: boolean }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/without-page`,
            method: 'get',
            params: { things_ids, type, query_label }
        })
    }
    /**
     * 查询子设备列表
     * @param infoModelId
     * @param thirdThingsId
     * @returns 
     */
    static things_getSubThings({ infoModelId, thirdThingsId, page_size, current_page }: any) {
        return this.fetch({
            url: `/things/info-models/${infoModelId}/things/${thirdThingsId}/children-things`,
            method: 'get',
            params: { page_size, current_page }
        })
    }

    /**
     * 查询设备的上层设备
     * @param param0 
     * @returns 
     */
    static things_getThingParents({thirdThingsId, infoModelId }: any) {
        return this.fetch({
            url: `/things/info-models/${infoModelId}/things/${thirdThingsId}/parents`,
            method: 'get'
        })
    }

    /**
    * 批量查询多个父设备下的子设备列表
    * @param infoModelId
    * @param thirdThingsId
     * @returns 
    */
    static things_getBatchSubThings({ tenant_id, parent_things_ids, current_page, page_size }: {
        tenant_id: number, parent_things_ids: string, current_page: number, page_size: number
    }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/parent-things/${parent_things_ids}/children-things`,
            method: 'get',
            params: { page_size, current_page }
        })
    }
    /**
     * 查询网关下挂载的设备列表
     * @returns 
     */
    static things_getBatchThingsOfGateway({ tenant_id, things_id, page_size, current_page }: { tenant_id: number, things_id: number, page_size: number, current_page: number }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/gw-tings/${things_id}`,
            method: 'get',
            params: { page_size, current_page }
        })
    }

    /**
     * 导出全部设备
     * @returns
     */
    static things_getOutPutAllThings({ tenant_id, infoModel_id }: { tenant_id: number, infoModel_id?: number }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/download-things-file`,
            method: 'get',
            responseType: 'arraybuffer',
            params: { infoModelId: infoModel_id }
        },"things:things:export")
    }

    /**
     * 导出设备
     * @returns
     */
    static things_getOutPutThings({ tenant_id, infoModel_id }: { tenant_id: number, infoModel_id: number }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/${infoModel_id}/download-things-file`,
            method: 'get',
            responseType: 'arraybuffer'
        },"things:things:export")
    }

    /**
     *  导入设备
     * @param param
     * @returns 
     */
    static things_addImportThings({ tenant_id, file }: any) {
        let formData = new FormData()
        formData.append('file', file, file.name)
        return this.fetch({
            url: `/things/tenants/${tenant_id}/import-things`,
            method: 'post',
            data: formData
        }, "things:things:import")
    }

    /** 
     *  更新设备信息
     */
    static things_updateThings({ tenant_id, devid, api_base_things_request }: any) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/${devid}`,
            method: 'put',
            data: api_base_things_request
        },"things:things:update")
    }
    /** 
     *  批量更新设备的属性-设备列表
     */
    static things_updateBatch({ tenant_id, info_model_id, api_batch_update_things_request }: any) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/${info_model_id}/batch-update`,
            method: 'post',
            data: api_batch_update_things_request
        },"things:things:batch-update")
    }
    /** 
     *  批量更新设备的配置
     */
    static things_updateBatchConfiguration({ tenant_id, info_model_id, api_batch_update_things_request }: any) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/${info_model_id}/batch-update-configuration`,
            method: 'post',
            data: api_batch_update_things_request
        })
    }
    /**
     * 批量删除设备
     */
    static things_deleteBatchThings({ ids, tenant_id }: { ids: string, tenant_id: number }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/${ids}`,
            method: 'delete',
        },"things:things:delete")
    }

    // 获取设备类型下设备总数
    static things_getBatchThingsNumByDevtype({ tenantId, infoModelIds, show_hidden_device }: {
        tenantId: number,
        infoModelIds: string | number[],
        show_hidden_device?: boolean
    }) {
        if (Array.isArray(infoModelIds)) {
            infoModelIds = infoModelIds.toString()
        }

        return this.fetch({
            url: `/things/tenants/${tenantId}/info-model-ids/${infoModelIds}/things/number`,
            method: 'get',
            params: { show_hidden_device }
        })
    }

    /**
     * 通过第三方设备id和设备类型id查询设备信息
     * @param param0 
     */
    static things_getThingsInfo({ current_page = 1, infoModelId, page_size = 20, thirdThingsId }: { current_page: number, infoModelId: string, page_size: number, thirdThingsId: string }) {
        return this.fetch({
            url: `/things/things/${thirdThingsId}/things-info`,
            method: 'get',
            params: { current_page, infoModelId, page_size }
        })
    }

    /**
     * 更新设备连接状态
     * @param param0 
     * @returns 
     */
    static things_updateThingsConnectionStatus({ connection_status, tenant_id, things_id }: { connection_status: number, tenant_id: number, things_id: number }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/${things_id}/connection-status/${connection_status}`,
            method: 'put'
        })
    }

    // 获取设备的GPS地理位置名
    static things_getGPSLocationName({ lat, lng, apiKey }: { lat: number, lng: number, apiKey: string }) {
        return new Promise((resolve, reject) => {
            let gt: any = globalThis
            let script = document.createElement('script')
            let isDeleted = false

            gt.renderReverse = (loc: any) => {
                if (!isDeleted) document.body.removeChild(script)
                resolve(loc?.result?.formatted_address)
                isDeleted = true
            }

            script.src = `https://api.map.baidu.com/geocoder/v2/?callback=renderReverse&location=${lat},${lng}&output=json&pois=1&ak=${apiKey}`
            document.body.appendChild(script)
        })
    }

    /************* things-maintenance *********************************************************************************************/
    /**查询设备下线原因 */
    static thingsMaintenance_getMaintenanceThings({ current_page = 1, end_date, info_model_id, info_model_keywords, is_online, label_id, label_value, namespace, offline_reasons, page_size = 20, prefix, start_date, tenant_id, tenant_name, things_type, third_things_id }: {
        current_page: number, end_date: number, info_model_id: number, info_model_keywords: string, is_online: number, label_id: number, label_value: string, namespace: string, offline_reasons: string, page_size: number, prefix: string, start_date: number, tenant_id: number, tenant_name: string, things_type: number, third_things_id: string
    }) {
        return this.fetch({
            url: `/things/maintance/things`,
            method: 'get',
            params: { current_page, end_date, info_model_id, info_model_keywords, is_online, label_id, label_value, namespace, offline_reasons, page_size, prefix, start_date, tenant_id, tenant_name, things_type, third_things_id }
        })
    }

    /**
     * mqtt实际设备
     * @param param0 
     * @returns 
     */
    static thingsMaintenance_getMaintenanceThingsByMqtt({current_page, info_model_name, page_size, q, tenant_name, third_things_id}: any) {
        return this.fetch({
            url: '/things/maintance/mqtt/things',
            method: 'get',
            params: {current_page, info_model_name, page_size, q, tenant_name, third_things_id}
        })
    }

    static thingsMaintenance_getLogoutHistory({ current_page = 1, page_size = 20, start_date, end_date, offline_reason, thingsId }: any) {
        return this.fetch({
            url: `/things/maintance/${thingsId}/logout-history`,
            method: 'get',
            params: { current_page, end_date, offline_reason, page_size, start_date }
        })
    }

    /************* thingsLabel *********************************************************************************************/
    /**
     * 给设备添加标签
     * 
     */
    static thingsLabel_addThingsLabels(
        {
            tenant_id,
            things_id,
            label_id,
            labels
        }:
            {
                tenant_id: number,
                things_id: number,
                label_id: number,
                labels: any
            }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/${things_id}/labels/${label_id}/relations`,
            method: 'post',
            data: labels
        },"things:things-label:create")
    }

    /**
     * 查询指定标签关联的标签值
     * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
     */
    static thingsLabel_getThingsLabelValues({ tenant_id, label_id, page_size, current_page, fuzzy }: { tenant_id: number, label_id: number, page_size: number, current_page: number, fuzzy: string }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/labels/${label_id}/things-label-values`,
            method: 'get',
            params: { page_size, current_page, fuzzy }
        })
    }
    /**
 * 获取设备关联的标签
 * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
 */
    static thingsLabel_getThingsLabels({ tenant_id, things_id, page_size, labelId, labelValue, current_page }: { tenant_id: number, things_id: number, page_size: number, labelId: number, labelValue: string, current_page: number }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/${things_id}/labels`,
            method: 'get',
            params: { page_size, current_page, labelId, labelValue, things_id }
        })
    }
    /**
* 删除设备下的所有标签
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
*/
    static thingsLabel_deleteThingsLabels({ tenant_id, things_id }: { tenant_id: number, things_id: number }) {
        return this.fetch({
            url: `/things/tenants/${tenant_id}/things/${things_id}/labels`,
            method: 'delete',
        },"things:things-label:delete-things")
    }
    /************* thingsMqtt **********************************************************************************************/

}
export default ZWSThings
