export enum ReadStatus {
  UnRead,
  Read,
  Delete,
  All
}

export enum ReminderMode {
  Icon = 1,
  Dialog = 2
}
