import { info } from 'console'
import ZWSBaseModule from '../httpBase/ZWSBaseModule'
class ZWSMappingMgmt extends ZWSBaseModule {

  /*******************************************************  category  *****************************************************/

  /**
 * 设备大类添加
 * @param {string} name - 设备大类的名称
 * @param {string} description - 设备大类的描述
 * @param {string} templates - Elasticsearch 动态mapping的语法的json表示。
 * @return {Promise<ZWSDeviceCategory[], Error>} - response: [{@link ZWSDeviceCategory}]
 */
  static category_addDeviceCategory({ name, description, templates }: {
    name: string, description: string, templates: string
  }) {
    return this.fetch({
      url: '/mapping-mgmt/categories',
      method: 'post',
      data: { name, description, templates }
    })
  }
  /**
   * 查询设备大类信息
   * @param {string} tenantId - 租户ID
   */
  static category_queryDeviceCategory({ current_page, page_size, prefix }: {
    current_page: number, page_size: number, prefix: string
  }) {
    return this.fetch({
      url: '/mapping-mgmt/categories',
      method: 'get',
      params: { current_page, page_size, prefix },
    })
  }

  /**
 * 设备大类的修改
 * @param {Object} params
 * @param {string} category_id - 设备大类ID
 */
  static category_editDeviceCategory({ category_id, name, description, templates }: {
    category_id: number, name: string, description: string, templates: string
  }) {
    return this.fetch({
      url: `/mapping-mgmt/categories/${category_id}`,
      method: 'put',
      data: { name, description, templates }
    })
  }
  /**
*  删除设备大类
*  @param {Object} params
*  @param {string} category_id - 设备大类ID
*/
  static category_deleteDeviceCategory({ category_id }: {
    category_id: number
  }) {
    return this.fetch({
      url: `/mapping-mgmt/categories/${category_id}`,
      method: 'delete'
    })
  }

  /**
  * 查询设备大类Template信息
  * @param {string} tenantId - 租户ID
  */
  static category_queryDeviceCategoryTemplate({ category_id }: {
    category_id: number
  }) {
    return this.fetch({
      url: `/mapping-mgmt/categories/${category_id}/template`,
      method: 'get',
      params: {},
    })
  }

  /**
 * 设备大类Template的修改
 * @param {Object} params
 * @param {string} category_id - 设备大类ID
 */
  static category_editDeviceCategoryTemplate({ category_id, templates }: {
    category_id: number, templates: string
  }) {
    return this.fetch({
      url: `/mapping-mgmt/categories/${category_id}/template`,
      method: 'put',
      data: templates
    })
  }

  /**
  * 查询设备大类Template信息
  * @param {string} tenantId - 租户ID
  */
  static category_queryDeviceGlobalTemplate({ category_id }: {
    category_id: number
  }) {
    return this.fetch({
      url: `/mapping-mgmt/categories/global/template`,
      method: 'get',
      params: {},
    })
  }

  /**
 * 设备大类Template的修改
 * @param {Object} params
 * @param {string} category_id - 设备大类ID
 */
  static category_editDeviceGlobalTemplate({ category_id, templates }: {
    category_id: number, templates: string
  }) {
    return this.fetch({
      url: `/mapping-mgmt/categories/global/template`,
      method: 'put',
      data: templates
    })
  }
  /**
   * 查询告警事件列表
   * @param {number} [params.tenantId] 租户id
   * @param {number} [params.start_time] 开始时间（毫秒）
   * @param {number} [params.end_time] 结束时间（毫秒）
   * @param {number} [params.page_size] 每页条数
   * @param {number} [params.current_page] 跳过多少页数据
   * @param {string} [params.name] 规则名称
   * @param {number} [params.rule_type] 规则类型,1数据推送，2告警
   * @param {number} [params.last_update_time] 最后一次更新时间
   * @param {number} [params.info_model_id] 模型id
   * @param {number} [params.rule_id] 规则id
   * @param {string} [params.rule_name] 规则名称（模糊匹配）
   * @param {number} [params.things_id] 设备id  
   * @param {number} [params.third_things_id] 设备串号  
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static getAlertLogs({ tenantId, start_time, end_time, page_size, current_page, name, rule_type, last_update_time, info_model_id, rule_id, rule_name, things_id, third_things_id }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/alert-events`,
      method: 'get',
      params: { start_time, end_time, page_size, current_page, name, rule_type, last_update_time, info_model_id, rule_id, rule_name, things_id, third_things_id }
    })
  }
  /**
   * 告警事件的删除
   * @param {number} [params.tenantId] 租户id
   * @param {number} [params.end_time] 结束时间（毫秒）
   * @param {number} [params.info_model_id] 模型id
   * @param {number} [params.rule_id] 规则id
   * @param {string} [params.rule_name] 规则名称（模糊匹配）
   * @param {number} [params.start_time] 开始时间（毫秒）
   * @param {number} [params.things_id] 设备ID
   * @param {number} [params.third_things_id] 设备串号
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static alertEvents_deleteAlertEvents({ tenantId, end_time, info_model_id, rule_id, rule_name, start_time, things_id, third_things_id }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/alert-events`,
      method: 'delete',
      data: { end_time, info_model_id, rule_id, rule_name, start_time, things_id, third_things_id }
    })
  }

  /*******************************************************  forwardInfo  **************************************************/
  /**
  * 下发边缘计算规则
  * @param {string} [params.tenantId] 租户id
  * @param {string} [params.ruleId] 规则id
  * @param {string} [params.issuance_type] 指令类型 - 0 停用  - 1 启用
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
  */
  static edgeComputing_issueSingleEdgeComputingRule({ tenantId, ruleId, issuance_type }: { tenantId: number, ruleId: number, issuance_type: number }) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/edge-computing-rules/${ruleId}/issuance`,
      method: 'post',
      data: { issuance_type }
    })
  }
  /**
   * 更新边缘计算规则
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static edgeComputing_updateSingleEdgeComputingRule({ tenantId, ruleId, rule_name, rule_type, things_filter, rule_content_on, rule_content_off, attachment }: any) {
    return this.fetch({
      url: `/alert-mgmt/tenants/${tenantId}/edge-computing-rules/${ruleId}`,
      method: 'put',
      data: { rule_name, rule_type, things_filter, rule_content_on, rule_content_off, attachment }
    })
  }
  /*******************************************************  infoModelForwardRule  *****************************************************/
  /*******************************************************  infoModelMapping  *****************************************************/


  /*******************************************************  iotEntity  *****************************************************/
  /**
   * 获取单个实体
   * @param param0 
   * @returns 
   */
  static iotEntity_getSingleEntity({ entity_id, tenant_id }: { entity_id: number, tenant_id: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenant_id}/entities/${entity_id}`,
      method: 'get',
    })
  }


  /******************************************************** iotFunctionBlock ************************************************************/
  /**
   * 获取公开的功能块
   */
  static iotFunctionBlock_getPublicFB({ current_page, keyword, page_size }: { current_page: number, keyword: string, page_size: number }) {
    return this.fetch({
      url: `/mapping-mgmt/function-blocks/public`,
      method: 'get',
      params: { current_page, keyword, page_size }
    })
  }

  /*******************************************************  iotInfoModel  *****************************************************/

  /**
  * 获取public的类型列表
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
  */
  static iotInfoModel_getBatchPublicInfomodels({ current_page = 1, page_size = 20, keyword, clean_type, names }: { current_page: number, page_size: number, keyword: string, clean_type?: number, names?: string }) {
    return this.fetch({
      url: `/mapping-mgmt/info-models/public`,
      method: 'get',
      params: { current_page, keyword, page_size, clean_type, names }
    })
  }

  /**
  * 获取租户下的设备类型
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
  */
  static iotInfoModel_getBatchTenantInfomodels({ tenant_id, keyword, label_id, current_page = 1, page_size = 20 }: { tenant_id: number, keyword: string, label_id: number, current_page: number, page_size: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenant_id}/info-models`,
      method: 'get',
      params: { keyword, label_id, current_page, page_size }
    })
  }
  /**
  * 查询单个设备类型详情
  * @param {number} [params.tenant_id] 租户id
  * @param {number} [params.infoModelId] 设备类型id
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
  */
  static iotInfoModel_getSingleInfoModel({ tenant_id, info_model_id }: {
    tenant_id: number, info_model_id: number
  }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenant_id}/info-models/${info_model_id}`,
      method: 'get',
    })
  }

  /**
  * 通过多个id获取多个设备类型(只返回基本信息)，此接口传id用 ','分隔
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
  */
  static iotInfoModel_getBatchInfomodelIds({ tenantId, infoModelIds }: { tenantId: number, infoModelIds: string }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenantId}/info-models/ids/${infoModelIds}`,
      method: 'get',
    })
  }
  /**
   *  更新设备类型
   * @param param
   * @returns 
   */
  static iotInfoModel_updateInfoModel({ tenant_id, info_model_id, info }: any) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenant_id}/info-models/${info_model_id}`,
      method: 'put',
      data: info
    })
  }
  /**
   * 通过多个名称获取设备类型(只返回基本信息),普通租户无权限调用，此接口传 name 用 ',' 分隔
   * @param param0 
   * @returns 
   */
  static iotInfoModel_getBatchInfoModelBayName({ names }: { names: string }) {
    return this.fetch({
      url: `/mapping-mgmt/info-models/names/${names}`,
      method: 'get'
    })
  }
  /**
   * 通过多个名称获取设备类型(只返回基本信息)，普通租户可调用。此接口传 name 用 ',' 分隔
   * @param param0 
   * @returns 
   */
  static iotInfoModel_getBatchInfoModelByNameForTenant({ infoModelNames, tenant_id }: { infoModelNames: string, tenant_id: number }) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenant_id}/info-models/names/${infoModelNames}`,
      method: 'get',
    })
  }
  /**
 * 获得设备类型密钥
 * @param 
 * @returns 
 */
  static iotInfoModel_getInfoModelPassword({ tenant_Id, info_model_id }: any) {
    return this.fetch({
      url: `/mapping-mgmt/tenants/${tenant_Id}/info-models/${info_model_id}/password`,
      method: 'get'
    })
  }

  /**通过info_model_id获取设备类型信息 */
  static iotInfoModel_getSingleInfoModelInfo({ infoModelId, load_ref }: any) {
    return this.fetch({
      url: `/mapping-mgmt/info-models/${infoModelId}`,
      method: 'get',
      params: { load_ref }
    })
  }

  /*******************************************************  iotInfoModelDetail  *****************************************************/
  /*******************************************************  iotInfoModelLabel  *****************************************************/
  /*******************************************************  mappingRuleInfo  *****************************************************/
  /*******************************************************  syncToChangelog  *****************************************************/
  /*******************************************************  tools  *****************************************************/
  /*******************************************************  userResource  *****************************************************/
  /*******************************************************  dbc  *****************************************************/
}

export default ZWSMappingMgmt
