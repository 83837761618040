/**
 * 判断数组是否包含某项值  v-hasPermission="{list, item}"
 * @param [array] list 数组
 * @param [string] item 某项值
 */
export const hasPermission = {
    parentNode: <any> null,
    dealHasPermission(el: HTMLElement, binding: any) {
        let hasPermissionState: boolean = false;
        let list: Array<any> = binding.value.list;
        let item: any = binding.value.item;

        if (list && item) {
            hasPermissionState = list.indexOf(item) > -1;
        }

        if (!hasPermission.parentNode) return;
        if(!hasPermissionState){
            hasPermission.parentNode.contains(el) && hasPermission.parentNode.removeChild(el);
        } else {
            hasPermission.parentNode && !hasPermission.parentNode.contains(el) && hasPermission.parentNode.appendChild(el);
        }
    },
    mounted (el: HTMLElement, binding: any) {
        hasPermission.parentNode = el.parentNode;
        hasPermission.dealHasPermission(el, binding);
    },
    beforeUpdate(el: HTMLElement, binding: any) {
        hasPermission.dealHasPermission(el, binding);
    },
};