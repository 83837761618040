/**
 * DBC管理 dbcManagement
 */
export default {
  dbcFile: 'DBC文件',
  addDBCFile: '添加DBC文件',
  effectOfDeletion: '删除DBC文件会对相应模块产生如下影响：',
  clearingCurveAnalysis: '1、清除曲线分析中与该DBC文件相关的看板',
  clearHistoricalData: '2、清除DBC数据中与该DBC文件相关解析的历史数据',
  clearTheTriggering: '3、清除触发规则中对应通道的DBC触发规则',
  sureToContinue: '确定是否继续?',
  updateFile: '更新文件',
  overlayFile: '更新成功后将会覆盖当前文件信息，是否更新？',
  obtainDBCFileListFailed: '获取DBC文件列表失败',
  selectFileNameBy: '需选择名称为 {name} 的文件',
  updateDBCFileSuccess: '更新DBC文件成功',
  updateDBCFileFailed: '更新DBC文件失败，{error}',
  downloadDBCFileSuccess: '下载DBC文件成功',
  deleteDBCFileSuccess: '删除DBC文件成功',
  deleteDBCFileFailed: '删除DBC文件失败，{error}',
  mustBeDBCFile: '文件类型必须是 *.dbc',
  existsDBCFileName: 'DBC文件名称已存在',
  uploadDBCFileSuccess: '上传DBC文件成功',
  uploadDBCFileFailed: '上传DBC文件失败',
  checkDBCFileNameFailed: 'DBC文件名称检查失败',
  notEmptyDBCFile: 'DBC文件不能为空',
  selectDBCFile: '请选择DBC文件',
  loadMore: '加载更多',
  Strategic: '信号策略',
  addStrategic: '添加信号策略',
  deleteStrategic: '删除信号策略',
  deleteStrategicTip: '确定要删除该信号策略吗？',
  strategicUsedTip: '信号策略在使用中，不可删除。',
  getStrategicFunctionError: '获取信号策略功能块失败',
  deleteStrategicSuccess: '删除信号策略成功',
  deleteStrategicError: '删除信号策略功能块失败',
  strategicName: '策略名称',
  associateDBC: '关联DBC',
  signalNumber: '信号数',
  remark: '备注',
  signalStrategicName: '信号策略名称',
  signalStrategicNameInputTip: '请输入信号策略名称，默认前缀为DBCSIG_',
  signalStrategicDisplayName: '信号策略昵称',
  signalStrategicDisplayNameInputIip: '请输入信号策略昵称',
  signalStrategicRemark: '信号策略描述',
  signalStrategicRemarkInputTip: '请输入信号策略描述',
  signalStrategicFunctionDeficiency: '信号策略功能块信息缺失。',
  signalStrategicMessageDeficiency: '信号策略信息缺失。',
  signalStrategicMessageError: '信号策略信息错误。',
  createSignalStrategicSuccess: '创建信号策略成功',
  signalStrategicComplementError: '信号策略信息补全失败',
  associateDefaultFunctionFailed: '关联默认功能块失败，',
  signalStrategicExist: '信号策略已存在',
  createSignalStrategicError: '创建信号策略失败',
  signalStrategicLoss: '无法保存，信号策略物模型完整信息丢失。',
  setSignalStrategicFailed: '无法保存，设置信号策略信息失败。',
  saveSignalStrategicFailed: '无法保存，设置信号策略信息失败。',
  updateStrategicSuccess: '修改信号策略成功',
  updateStrategicMessageFeail: '更新信号策略信息失败，',
  updateStrategicFailed: '修改信号策略失败，',
  selectOneDBCFile: '请选择一个DBC文件',
  signalNumberBetween: '信号点个数须在1和100之间',
  signalStrategicNameInput: '请输入信号策略名称',
  signalStrategicNameInputValidator: '由英文、数字和"_"组成，不能以数字开头',
  signalStrategicDisplayInput: '请输入信号策略昵称',
  create: '创建',
  getDBCFailed: '获取DBC配置失败',
  getDbCFileFailed: '获取DBC文件列表失败',
  script: '脚本处理'
}
