/**
 * 故障码管理
 */
export default {
  pleaseEnterDTCDisplayName: '请输入DTC显示名称',
  pleaseEnterFaultCode: '请输入故障码',
  faultCode: '故障码',
  dtcDisplayName: 'DTC显示名称',
  downloadTemplate: '下载模板',
  DTCFile: '故障码文件',
  deleteDTCFileFailed: '删除故障码文件失败，{error}',
  deleteDTCFileSuccess: '删除故障码文件成功',
  obtainDTCConfigFailed: '获取DTC配置失败',
  updateDTCFileFailed: '更新故障码文件失败',
  fileUpdatedSuccess: '更新故障码文件成功',
  fileNameInconsistent: '故障码文件名称不一致',
  fileAbnormal: '文件异常，添加文件失败',
  addDTCFileFailed2: '添加故障码文件失败，{error}',
  addDTCFileSuccess: '添加故障码文件成功',
  addDTCFileFailed: '添加失败，故障码文件内容不正确',
  downloadTemplateFailed: '下载故障码模板失败',
  obtainDTCFileListFailed: '获取故障码文件列表失败',
  sureToDeleteDTCFile: '确定要删除该故障码文件吗？'
}
