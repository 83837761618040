<template>
    <!-- 默认引用的是iot-standard-library标准库中 -->
    <ZContainerArea></ZContainerArea>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
// import ZAnnouncementArea from 'ui/area/ZCommonArea/ZAnnouncementArea.vue'

// 如果不使用标准版，需自定义，引用内置模块来修改
import ZContainerArea from '../../vue_library/components/ZContainerArea/ZContainerArea.vue'

export default defineComponent({

    // 如果不使用标准版，需自定义，引用内置模块来修改
    components: {
        ZContainerArea,
    },

    setup() { },
})
</script>
