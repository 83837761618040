<template>
    <el-input class="z-input-password-area" v-model="value" :placeholder="placeholder" auto-complete="new-password"
        show-password @input="getInputValue" :clearable="clearable">
        <!-- :type="passwordSecrecy?'text':'password'" -->
        <template v-if="hasPrefix" #prefix>
            <slot name="prefix"></slot>
        </template>
        <!-- <template #suffix>
            <i :style="{'color':passwordSecrecy?'#1c92f3':'','paddingRight':'10px'}" class="password-secrecy el-icon-view" @click="passwordSecrecy = !passwordSecrecy"></i>
        </template> -->
    </el-input>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        hasPrefix: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: '',
            passwordSecrecy: false,
        }
    },
    methods: {
        getInputValue() {
            this.$emit('getInputValue', this.value)
        },
        empty() {
            this.value = ''
        },
    },
}
</script>
<style lang="less" scoped>
/** 限制输入*/
.z-input-password-area {
    :deep(.el-input__inner) {
        /* padding: 0 60px 0 15px; */
        padding-right: 60px;
    }

    :deep(.el-input__inner[type='text'] ~ .el-input__suffix .el-icon-view) {
        // color: #1c92f3;
        color: #80b33f;
    }
}

// z-input-password-area
</style>