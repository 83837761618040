import ZWSBaseModule from '../httpBase/ZWSBaseModule'

export interface Notice {
  content: string
  endTime: number
  isShow: boolean
  platform: string
  startTime: number
  title: string
  version: string
} 

export interface UpdateNotice extends Notice {
  id: number
}

/**
 * 部署管理
 */
class ZWSOutWeb extends ZWSBaseModule {

  /************* config ********************************************************************************************/

  /**
   * 获取基础配置信息
   * @returns
   */
  static config_getConfigInfo({ resourcePath, isDebug }: {
    resourcePath: string,
    isDebug: number,
  }) {
    return this.fetch({
      url: `/out-web/deploy/config/info`,
      method: 'get',
      params: { resourcePath, isDebug }
    })
  }

  /**
   * 保存基础配置信息
   * @param {string} [params.cloudname] 平台名称
   * @param {File} [params.loginLogo ] 登录Logo
   * @param {File} [params.homepageLogo] 主页Logo
   * @param {File} [params.menu] 菜单JSON
   * @param {File} [params.registerLogo]
   * @param {string} [params.link] 应用链接json
   */
  static config_updateConfigInfo({ resourcePath, isDebug, cloudname, loginLogo, homepageLogo, menu, registerLogo, link }: {
    resourcePath: string,
    isDebug: string,
    cloudname?: string,
    loginLogo?: File,
    homepageLogo?: File,
    menu?: string,
    registerLogo?: File,
    link?: string
  }) {
    let formData = new FormData();
    loginLogo && formData.append('loginLogo', loginLogo, loginLogo.name);
    homepageLogo && formData.append('homepageLogo', homepageLogo, homepageLogo.name);
    menu && formData.append('menu', menu);
    registerLogo && formData.append('registerLogo', registerLogo, registerLogo.name);
    cloudname && formData.append('cloudname', cloudname);
    link && formData.append('link', link);

    return this.fetch({
      url: `/out-web/deploy/config/save`,
      method: 'post',
      data: formData,
      params: { resourcePath, isDebug }
    })
  }

  /************* deploy ********************************************************************************************/

  /**
   * 获取部署信息
   * @returns
   */
  static deploy_getWebInfo() {
    return this.fetch({
      url: `/out-web/deploy/web/info`,
      method: 'get',
    })
  }

  /**
   * 获取部署列表
   * @param {string} [params.isDebug] 是否测试版本
   * @returns
   */
  static deploy_getWebList({ currentPage, pageSize, isDebug }: { currentPage?: string, pageSize?: string, isDebug?: string }) {
    return this.fetch({
      url: `/out-web/deploy/web/list`,
      method: 'get',
      params: { currentPage, pageSize, isDebug }
    })
  }

  /**
   * web部署发布
   * @param {string} [params.resourcePath] 资源路径
   * @param {string} [params.version] 版本号
   * @returns
   */
  static deploy_issueWebInfo({ resourcePath, version, isDebug }: {
    resourcePath: string,
    version: string,
    isDebug: number
  }) {
    return this.fetch({
      url: `/out-web/deploy/web/release`,
      method: 'post',
      params: { resourcePath, version, isDebug }
    })
  }

  /**
   * web部署保存
   * @param {string} [params.isDebug] 是否测试版本
   * @param {File} [params.resourcefile ] 上传的资源文件
   * @param {string} [params.resourcePath ] 资源路径
   * @param {string} [params.version ] 版本号
   * @param {string} [params.remark ] 备注
   * @returns
   */
  static deploy_updateWebInfo({ isDebug, resourcefile, resourcePath, version, remark }: {
    isDebug: string,
    resourcefile: File,
    resourcePath: string,
    version: string,
    remark: string,
  }) {
    let formData = new FormData();
    formData.append('isDebug', isDebug);
    formData.append('resourcefile', resourcefile, resourcefile.name);
    formData.append('resourcePath', resourcePath);
    formData.append('version', version);
    formData.append('remark', remark);

    return this.fetch({
      url: `/out-web/deploy/web/save`,
      method: 'post',
      data: formData,
    })
  }

  /**
   * web部署删除
   * @param {string} [params.resourcePath] 资源路径
   * @param {string} [params.version] 版本号
   */
  static deploy_deleteWebInfo({ resourcePath, version, isDebug }: {
    resourcePath: string,
    version: string,
    isDebug: number
  }) {
    return this.fetch({
      url: `/out-web/deploy/web/remove`,
      method: 'delete',
      params: { resourcePath, version, isDebug }
    })
  }

  /************* log ********************************************************************************************/

  /**
   * 获取日志列表
   * @param {string} [params.operation] 搜索内容
   * @param {string} [params.username ] 搜索用户名
   * @returns
   */
  static log_getLogList({ currentPage, pageSize, operation, username, startTime, endTime }: {
    currentPage: number,
    pageSize: number,
    operation: string,
    username: string,
    startTime: number
    endTime: number
  }) {
    return this.fetch({
      url: `/out-web/log/list`,
      method: 'get',
      params: { currentPage, pageSize, operation, username, startTime, endTime }
    })
  }

  /************* serverConfig ************************************************************************************/
  /**
   * 获取服务器配置
   * @returns 
   */
  static serverConfig_getConfig () {
    return this.fetch({
      url: '/out-web/server/config',
      method: 'get'
    })
  }

  /**
   * 新增或更新服务器配置
   * @param param0 
   * @returns 
   */
  static serverConfig_updateConfig({ each_es_db_size, es_db_num }: {each_es_db_size: number,es_db_num: number}) {
    return this.fetch({
      url: '/out-web/server/config',
      method: 'put',
      data: { each_es_db_size, es_db_num }
    })
  }

  /************* user ********************************************************************************************/

  /**
   * 创建用户
   * @param {string} [params.password] 密码
   * @param {string} [params.remark ] 备注
   * @param {string} [params.roleId] 角色
   * @param {string} [params.username ] 用户名
   * @returns
   */
  static user_addUser({ password, remark, roleId, username }: {
    password: string,
    remark: string,
    roleId: string,
    username: string,
  }) {
    return this.fetch({
      url: `/out-web/user/create`,
      method: 'post',
      params: { password, remark, roleId, username },
    })
  }

  /**
   * 删除创建用户
   * @param {string} [params.username] 用户名
   */
  static user_deleteSingleUser({ username }: { username: string }) {
    return this.fetch({
      url: `/out-web/user/delete`,
      method: 'delete',
      params: { username }
    })
  }

  /**
   * 获取用户列表
   * @returns
   */
  static user_getUserList({ currentPage, pageSize }: { currentPage: number, pageSize: number }) {
    return this.fetch({
      url: `/out-web/user/list`,
      method: 'get',
      params: { currentPage, pageSize }
    })
  }

  /**
   * 修改用户密码
   * @param {string} [params.newPassword ] 新密码
   * @param {string} [params.username] 用户名
   * @returns
   */
  static user_updateUserPwd({ newPassword, username }: { newPassword: string, username: string }) {
    return this.fetch({
      url: `/out-web/user/password/update`,
      method: 'put',
      params: { newPassword, username }
    })
  }

  /**
   * 登录
   * @param {string} [params.password ] 密码
   * @param {string} [params.username] 用户名
   * @returns
   */
  static user_login({ password, username }: { password: string, username: string }) {
    return this.fetch({
      url: `/out-web/user/login`,
      method: 'post',
      params: { password, username }
    })
  }

  /**
   * 退出
   * @returns
   */
  static user_logout() {
    return this.fetch({
      url: `/out-web/user/logout`,
      method: 'post',
    })
  }

  /************* notice ******************************************************************************************/

  /**
   * 分页查询系统公告
   * @param param0 
   * @returns 
   */
  static notice_getNotice({ current_page, currentTime, isShow, page_size, platform, title, version }: {
    current_page?: number,
    currentTime?: number,
    isShow?: boolean,
    page_size?: number,
    platform?: string,
    title?: string,
    version?: string
 }) {
    return this.fetch({
      url: '/out-web/notice',
      method: 'get',
      params: { current_page, currentTime, isShow, page_size, platform, title, version }
    })
  }

  /**
   * 创建系统公告
   * @param param0 
   */
  static notice_addNotice({ content, endTime, isShow, platform, startTime, title, version }: Notice) {
    return this.fetch({
      url: '/out-web/notice',
      method: 'post',
      data: { content, endTime, isShow, platform, startTime, title, version }
    })
  }

  /**
   * 获取系统公告详情
   * @param param0 
   * @returns 
   */
  static notice_getNoticeById({ id }: { id: number }) {
    return this.fetch({
      url: `/out-web/notice/${id}`,
      method: 'get',
    })
  }

  /**
   * 修改系统公告
   * @param param0 
   * @returns 
   */
  static notice_updateNotice({ id, content, endTime, isShow, platform, startTime, title, version }: UpdateNotice) {
    return this.fetch({
      url: `/out-web/notice/${id}`,
      method: 'put',
      data: { content, endTime, isShow, platform, startTime, title, version }
    })
  }

  /**
   * 删除系统公告
   * @param param0 
   * @returns 
   */
  static notice_deleteNoticeById({ id }: { id: number }) {
    return this.fetch({
      url: `/out-web/notice/${id}`,
      method: 'delete',
    })
  }

  /**
   * 更新用户公告配置
   * @param param0 
   * @returns 
   */
  static notice_updateUserNotice({ noticeId, userId, noLongerShow }: { noticeId: number, userId: number, noLongerShow: boolean }) {
    return this.fetch({
      url: `/out-web/notice/${noticeId}/user/${userId}`,
      method: 'put',
      params: { noLongerShow }
    })
  }
  
  /**
   * 查询用户公告详情
   * @param param0 
   * @returns 
   */
  static notice_getUserNoticeByUser({ currentTime, platform, userId, version }: {currentTime: number, platform: string, userId: number, version: string}) {
    return this.fetch({
      url: `/out-web/notice/user/${userId}`,
      method: 'get',
      params: { currentTime, platform, version }
    })
  }

}

export default ZWSOutWeb;
