import App from './App.vue'
import { createApp } from 'vue'
import { router } from './router/index'
import '@/assets/css/css_base.css'
import '@/assets/less/base.less'
import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfont/inside_iconfont/iconfont.css'
import '../element-variables.scss'
import '@/assets/iconfont/iconfont.js'
// import '@/assets/iconfont/iconfont2.js'
import '@/assets/iconfont/inside_iconfont/iconfont.js'
import 'json-viewer-awesome/lib/json-formatter.css'
import store from './store/index'
import ElementPlus from 'element-plus'
import ZWSElementPlus from 'ui/index'
import ZWSApi from 'api/http/ZWSApi'
import i18n from './language/index'
import './assets/css/scrollbar.css'
import '@/assets/ts/api/index'
import { remarkOperationMonitoring } from 'tool/ZDirectives/directives/monitoring'
import '@zws/vue-next/dist/style.css'
import { ElMessage } from 'element-plus'
import Msg, { MsgHandler } from 'ui/ctrl/ZMessage'
import ZMainBody from 'ui/ctrl/ZMainBody'
import { setLibLocale } from '@/assets/ts/Common/useLocaleHelper'

ZMainBody.addFooter(60)
ZMainBody.addHeader(110)

const app = createApp(App)

window.ZWSApi = ZWSApi
// window.baiduMapApiKey = '0Ylw7nN3CGLp27qSVL25imNx8ShWtpa9'

Msg.setHandle(ElMessage as MsgHandler)
// app.directive('loadmore', {
//     bind(el, binding) {
//         // 获取element-ui定义好的scroll盒子
//         const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
//         SELECTWRAP_DOM.addEventListener('scroll', function () {
//             const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
//             if (CONDITION) {
//                 binding.value()
//             }
//         })
//     }
// })
app.use(ZWSElementPlus)
app.use(ElementPlus as any)
app.use(router)
app.use(store)
app.use(i18n)
app.mount('#app')

const lsLanguage = localStorage.getItem('language')
if (lsLanguage) {
  setLibLocale(lsLanguage === '中文' ? 'zh' : 'en')
}

// app.config.errorHandler = (err: any, vm, info) => {
//   remarkOperationMonitoring({
//     platform: 'ZWS-Candtu',
//     module: '异常监控',
//     action: 'Vue异常',
//     params: JSON.stringify({
//       err: err?.stack || err.message,
//       info
//     })
//   })
//   throw new Error(err?.stack || err.message)
// }

// window.addEventListener('error', error => {
//   console.log(error, 'error')
//   remarkOperationMonitoring({
//     platform: 'ZWS-Candtu',
//     module: '异常监控',
//     action: 'Javascript异常',
//     params: JSON.stringify({
//       type: error.message ? 'js执行异常' : 'js加载异常',
//       info: error.message || error.filename
//     })
//   })
//   return false
// })
