
import ZWSBaseModule from '../httpBase/ZWSBaseModule'

export type AsyncMessageBody = {
  category_id?: string
  category_name?: string
  correlation_id?: string
  description: string
  gw_things_id?: number
  headers: string
  info_model_id: number
  label_id?: number
  message_type?: number
  parent_info?: string
  payload: string
  tenant_id: number
  things_id: number
  things_type: number
  third_things_id: string
  timestamp?: number
}

/**
 * 软件分组
 */
class ZWSOutWrapper extends ZWSBaseModule {

  /*******************************************************  messages  *****************************************************/

  /**
  * 下发同步消息(命令)
  * @param {string} [params.tenant_id] 租户id
  * @param {string} [params.things_id] 设备id
  * @param {string} [params.timeout] 超时时间
  * @param {string} [params.body] 业务参数
  * @param {string} [params.body.message_type] 消息类型：0 - 指令下发  1 - 设备升级
  * @returns 
  */
  static messages_issueAsyncMessage({ tenant_id, things_id, timeout, body }: { tenant_id: number, things_id: number, timeout: number, body: AsyncMessageBody }) {
    return this.fetch({
      url: `/out-wrapper/tenants/${tenant_id}/things/${things_id}/sync-message?timeout=${timeout}`,
      method: 'post',
      data: body,
    })
  }

  /**
   * 查询系统当前时间戳
   * @returns
   */
  static messages_getSysTimestamp() {
    return this.fetch({
      url: `/out-wrapper/timestamp`,
      method: 'get',
    })
  }

  /*******************************************************  tenants  ****************************************************/

  /**
   * 查询租户
   * @param param0 
   * @returns 
   */
  static tenants_getTenantList({ belong_platform, currentPage, email, pageSize, name, organization_name, mobile, audited, status, sort_property, sort_order, platform, priority_expression, ids, return_visit_time_start, return_visit_time_end }: any) {
    return this.fetch({
      url: "/out-wrapper/tenant/tenants",
      method: "get",
      params: {currentPage, belong_platform, email, pageSize, name, organization_name, mobile, audited, status, sort_property, sort_order, platform, priority_expression, ids, return_visit_time_start, return_visit_time_end}
    })
  }

  /*******************************************************  behavior  ***************************************************/

  /**
   * 获取行为记录
   * @param param0 
   * @returns 
   */
  static behavior_getBehavior({action, current_page, end_date, module, page_size, params, platform, start_date, tenantId, userId}: {
    action?: string,
    current_page?: number,
    end_date?: number,
    module?: string,
    page_size?: number,
    params?: string,
    platform?: string,
    start_date?: number,
    tenantId?: number,
    userId?: number
  }) {
    return this.fetch({
      url: "/out-wrapper/user/behavior",
      method: "get",
      params: { action, current_page, end_date, module, page_size, params, platform, start_date, tenantId, userId }
    })
  }

  /**
   * 创建一条行为
   * @param param0 
   * @returns 
   */
  static behavior_addBehavior({action, module, params, platform, tenant_id, user_id}: {
    action: string,
    module: string,
    params?: string,
    platform: string,
    tenant_id: number,
    user_id: number
  }) {
    return this.fetch({
      url: "/out-wrapper/user/behavior",
      method: "post",
      data: { action, module, params, platform, tenant_id, user_id }
    })
  }

  /**
   * 获取按动作分类的行为排行
   * @param param0 
   * @returns 
   */
  static behavior_getBehaviorSortByAction ({end_date, current_page, page_size, module, platform, start_date, tenantId, userId, action}: {
    end_date?: number,
    page_size?: number,
    current_page?: number,
    module?: string,
    platform?: string,
    start_date?: number,
    tenantId?: number,
    userId?: number,
    action?: string
  }) {
    return this.fetch({
      url: "/out-wrapper/user/behavior/sort-by-action",
      method: "get",
      params: {end_date, current_page, page_size, module, platform, start_date, tenantId, userId, action}
    })
  }

  static behavior_getBehaviorSortByModule ({end_date, page_size, current_page, platform, start_date, tenantId, userId, module}: {
    end_date?: number,
    page_size?: number,
    current_page?: number,
    platform?: string,
    start_date?: number,
    tenantId?: number,
    userId?: number,
    module?: string
  }) {
    return this.fetch({
      url: "/out-wrapper/user/behavior/sort-by-module",
      method: "get",
      params: {end_date, page_size, current_page, platform, start_date, tenantId, userId, module}
    })
  }

  static behavior_getBehaviorSortByTenants ({end_date, platform, start_date, action, current_page, page_size, module}: {
    end_date?: number,
    platform?: string,
    start_date?: number,
    current_page?: number,
    page_size?: number,
    action?: string,
    module?: string
  }) {
    return this.fetch({
      url: "/out-wrapper/user/behavior/sort-by-tenant",
      method: "get",
      params: {end_date, module, platform, start_date, current_page, page_size, action }
    })
  }

  /*******************************************************  things  *****************************************************/

  /**
    * 添加设备
    * @param {*} param
    * @returns 
  */
  static things_addThings({ tenant_id, api_base_things_request }: { tenant_id: number, api_base_things_request: any }) {
    return this.fetch({
      url: `/out-wrapper/tenants/${tenant_id}/things`,
      method: 'post',
      data: api_base_things_request
    })
  }

}

export default ZWSOutWrapper
