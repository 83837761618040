/**
 * 触发管理
 */
export default {
  ge: '',
  importResult: 'Import Result',
  remoteFrame: 'Remote Frame',
  dataFrame: 'Data Frame',
  extendedFrame: 'Extended Frame',
  standardFrame: 'Standard Frame',
  frameFormat: 'Frame Format',
  frameType: 'Frame Type',
  frameId: 'Frame ID',
  errorCode: 'Error Code',
  lastModifyTime: 'Last Modification Time',
  processFailed: 'Process failed',
  processSuccess: 'Process success',
  pleaseInput128Chars: 'Please enter a string of 1-128 characters',
  pleaseInputChars: 'Please enter a string of {0} characters',
  pleaseInputProcessMethod: 'Please enter the processing method',
  pleaseInputProcessor: 'Please enter a processor',
  triggerTime: 'Trigger Time',
  triggerContent: 'Trigger Content',
  triggerRuleName: 'Trigger Rule Name',
  triggerRuleDetail: 'Trigger Rule Details',
  processMode: 'Process Method',
  processor: 'Processor',
  triggerProcess: 'Trigger Process',
  alarmRule: 'Alarm Rule',
  pleaseSelectTimeRange: 'Please select the time period first',
  eventContent: 'Event Content',
  dataIn5Min: 'Data before and after 5 minutes',
  triggerEvent: 'Trigger Event',
  triggerRule: 'Trigger Rule',
  upTo20Rules: 'Up to 20 rules',
  ruleNameExists: 'The rule name already exists',
  contactAdmin: 'The import fails. Contact the administrator',
  importFailByRepeatRuleName: 'Import failed. The rule name is already in use',
  importFailByUserRuleLimit: 'Failed to import because the number of users exceeded the upper limit',
  importFailBySystemLimit: 'Failed to import because the number of entries exceeded the upper limit',
  importSuccess: '{num} trigger rules are successfully imported',
  noTriggerRule: 'No trigger rule',
  importLimit: 'You can also import {num} triggers the rule. The default limit is {max}',
  enterRawOrDBCAsTriggerData: 'Enter raw or dbc as trigger data',
  cannotAddDBCSignalTriggerRule: 'The device {channel} channel cannot add trigger rules for dbc signals',
  cannotAddTriggerRule: 'Cannot add raw data trigger rule, {channel} channel does not exist',
  parseFileContentFailed: 'File content cannot be parsed properly',
  deleteAlarmRuleSuccess: 'Alarm rule {ruleName} is deleted successfully',
  disableRuleFirst: 'Disable the trigger rule first',
  triggerState: 'Trigger State',
  triggerCondition: 'Trigger Condition',
  conditionsLimit: 'Only {0} trigger conditions can be added at most',
  ruleName: 'Rule Name',
  noTriggerToExport: 'No triggering rules can be exported',
  dbcSignal: 'DBC Signal',
  sureToDeleteRule: 'Are you sure you want to delete the trigger rule {ruleName}?',
  deleteTriggerRule: 'Delete Trigger Rule',
  beenDisable: 'Disable',
  beenEnable: 'Enable',
  pleaseInputRuleName: 'Please enter a rule name',
  pleaseSelectStatus: 'Please select status',
  pleaseSelectCANChannel: 'Please select the CAN channel',
  pleaseSelectTriggerData: 'Please select trigger data',
  triggerData: 'Trigger Data',
  ruleMax: 'Up to 20 rules',
  addTriggerRule: 'Add Trigger Rule',
  editTriggerRule: 'Edit Trigger Rule',
  triggerLevel: 'Trigger Level',
  share: 'Sub user sharing',
  shareTips: 'Enable sharing, and sub users under this account will receive alert events triggered by this alert rule',
  alertDes: 'Alarm description',
  alertRequirement: 'Alarm conditions',
  customText: 'Custom Text',
  alertAction: 'Alarm action',
  recoverAction: 'Recovery Action',
  addAction: 'Add Action',
  details: 'Details',
  level: {
    tip: 'Tips',
    ordinary: 'Ordinary',
    urgent: 'Urgent'
  },
  triggerDescription: 'Trigger Description',
  add: 'Add {0} {1}',
  edit: 'Edit {0} {1}',
  actionType: 'Action Type',
  action: {
    email: 'Email Notification',
    sms: 'SMS Notification',
    vms: 'Voice Alarm'
  },
  actionSimple: {
    email: 'Email',
    sms: 'SMS',
    vms: 'Voice'
  },
  tips: {
    emailNotNull: 'Please enter your email address',
    phoneNotNull: 'Please enter your phone number',
    emailError: 'Invalid email address',
    phoneError: 'Mobile phone number format error',
    unbindDbc: 'This channel is not bound to a DBC file',
    noAtts: 'The current channel does not have this attribute'
  }
}
