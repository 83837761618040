import ZWSBaseModule from '../httpBase/ZWSBaseModule'

/**
 * Zigbee组播
 */
class ZWSSingleZigbee extends ZWSBaseModule {

    /***************************zigbeeGroup********************************** */
    /**
     * Create zigbee group
     */
    static zigbeeGroup_addZigbeeGroup({ tenantId, info }:
        { tenantId: number, info: any }) {
        return this.fetch({
            url: `/single-zigbee/tenants/${tenantId}/multicast/zigbee_group`,
            method: 'post',
            data: info
        })
    }

    /**
     * Returns zigbee group
     */
    static zigbeeGroup_getZigbeeGroups({ current_page, gw_info_model_id, gw_things_id, name, page_size, sub_info_model_id, tenantId }: {
        current_page: number, gw_info_model_id: number, gw_things_id: number, name: string, page_size: number, sub_info_model_id: number, tenantId: number
    }) {
        return this.fetch({
            url: `/single-zigbee/tenants/${tenantId}/multicast/zigbee_group`,
            method: 'get',
            params: { current_page, gw_info_model_id, gw_things_id, name, page_size, sub_info_model_id }
        })
    }

    /**
     * Delete the zigbee group based on multicast_zigbee_group_id
     */
    static zigbeeGroup_deleteZigbeeGroup({ multicast_zigbee_group_id, tenantId }: { multicast_zigbee_group_id: number, tenantId: number }) {
        return this.fetch({
            url: `/single-zigbee/tenants/${tenantId}/multicast/zigbee_group/${multicast_zigbee_group_id}`,
            method: 'delete',
        })
    }

    /**
     * Updates the zigbee group
     */
    static zigbeeGroup_updateZigbeeGroup({ tenantId, multicast_zigbee_group_id, info }: { tenantId: number, multicast_zigbee_group_id: number, info: any }) {
        return this.fetch({
            url: `/single-zigbee/tenants/${tenantId}/multicast/zigbee_group/${multicast_zigbee_group_id}`,
            method: 'put',
            data: info
        })
    }

    static zigbeeGroup_issueZigbeeGorupMessage({ multicast_zigbee_group_id, payload, tenantId }: any) {
        return this.fetch({
            url: `/single-zigbee/tenants/${tenantId}/multicast/zigbee_group/${multicast_zigbee_group_id}/messages`,
            method: 'put',
            data: payload
        })
    }
    /***************************zigbeeMember********************************** */
    /**添加zigbee组播分组子设备 */
    static zigbeeMember_addSingleSubDevice({ tenantId, info }: { tenantId: number, info: any }) {
        return this.fetch({
            url: `/single-zigbee/tenants/${tenantId}/multicast/zigbee_member`,
            method: 'post',
            data: info
        })
    }

    /**查询zigbee组播分组子设备 */
    static zigbeeMember_getSubDevice({ tenantId, current_page, page_size, sub_things_id, zigbee_group_id }: { tenantId: number, current_page: number, page_size: number, sub_things_id: number, zigbee_group_id: number }) {
        return this.fetch({
            url: `/single-zigbee/tenants/${tenantId}/multicast/zigbee_member`,
            method: 'get',
            params: { current_page, page_size, sub_things_id, zigbee_group_id }
        })
    }

    /**删除zigbee组播分组子设备 */
    static zigbeeMember_deleteSingleSubDevice({ tenantId, sub_things_id, zigbee_group_id }: { tenantId: number, sub_things_id: number, zigbee_group_id: number }) {
        return this.fetch({
            url: `/single-zigbee/tenants/${tenantId}/multicast/zigbee_member`,
            method: 'delete',
            params: { sub_things_id, zigbee_group_id },
        })
    }

}

export default ZWSSingleZigbee