/**
 * 日期相关的工具类
 */
class ZDateTool {
    /**
     * 标准时间转换时间戳
     * @param formatDate 标准时间格式
     * @returns 
     */
    static formatDateTimeToTimestamp(formatDate: string) {
        let time = new Date(formatDate);
        let dateTime = time.getTime();
        return dateTime;
    }
    /**
     * 时间戳转换普通时间格式
     * @param utcTimestamp 时间戳
     * @returns 
     */
    static timestampToFormatDate(utcTimestamp: number, format?: string) {
        let time = new Date(utcTimestamp)
        let year = time.getFullYear()
        let month = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
        let date = time.getDate() > 9 ? time.getDate() : '0' + time.getDate()
        let hour = time.getHours() > 9 ? time.getHours() : '0' + time.getHours()
        let minute = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes()
        let second = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds()
        let resultData = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
        switch (format) {
            case 'hhmmss':
                resultData = hour + ':' + minute + ':' + second
                break;
            case 'yy-mm-dd':
                resultData = year + '-' + month + '-' + date
                break;
            default:
                break;
        }
        return resultData

    }
    /**
     * UTC时间戳转换当地普通时间格式
     * @param time utc时间戳
     * @returns 
     */
    static utcTimestampToFormatLocalDate(time: number) {
        let timeZone = (0 - new Date().getTimezoneOffset() / 60)
        let utcTimesDate = new Date(time)
        let utcTimestamp = utcTimesDate.getTime();
        utcTimestamp = utcTimestamp / 1000;
        utcTimestamp = utcTimestamp + timeZone * 60 * 60;
        let localDate = this.timestampToFormatDate(utcTimestamp * 1000)
        return localDate
    }
    /**
     * UTC时间戳转换当地时间戳
     * @param time utc时间戳
     * @returns 
     */
    static utcTimestampToLocalTimestamp(time: number) {
        let timeZone = (0 - new Date().getTimezoneOffset() / 60)
        let utcTimesDate = new Date(time)
        let utcTimestamp = utcTimesDate.getTime()
        utcTimestamp = utcTimestamp / 1000
        let localTimestamp = (utcTimestamp + timeZone * 60 * 60) * 1000
        return localTimestamp
    }
    /**
     * 本地时间戳转UTC时间戳
     * @param time 本地时间戳
     * @returns 
     */
    static localTimstampToUTCTimstamp(time: number) {
        let timeZone = (0 - new Date().getTimezoneOffset() / 60)
        let localTimesDate = new Date(time)
        let localTimestamp = localTimesDate.getTime()
        localTimestamp = (localTimestamp / 1000 - timeZone * 60 * 60) * 1000
        return localTimestamp
    }
}
export default ZDateTool