/**
 * 操作日志
 */
export default {
  logContent: 'Log Content',
  handleLog: 'Operation Log',
  owningUser: 'Owning User',
  operatingMode: 'Operating Mode',
  logTime: 'Log Time'
}
