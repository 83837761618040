interface Msg {
  type: string,   // 消息类型
  message: string // 消息内容
  zIndex: number
}

interface MsgHandlerWithType {
  (message: string, single?: boolean): void
}

export interface MsgHandler {
  (msg: string | Msg): void;
  success: MsgHandlerWithType;
  warning: MsgHandlerWithType;
  info: MsgHandlerWithType;
  error: MsgHandlerWithType;
}

var msgHandle: MsgHandler;
var msg: any;

function singleMessage(type: string, message: string, _single = true) {
  if (!msgHandle) {
    return;
  }

  if (_single && msg) {
    if (typeof msg.close == 'function') { msg.close(); }
    msg = undefined;
  }

  msg = msgHandle({ type: type, message: message, zIndex: 9999 });
}

function success(message: string, single = true) {
  singleMessage('success', message, single);
}

function warning(message: string, single = true) {
  singleMessage('warning', message, single);
}

function info(message: string, single = true) {
  singleMessage('info', message, single);
}

function error(message: string, single = true) {
  singleMessage('error', message, single);
}

function setHandle(handle: MsgHandler) {
  msgHandle = handle;
}

function message(options: string | Msg) {
  if (typeof options == 'string') {
    info(options);
  } else if (typeof options == 'object') {
    singleMessage(options.type, options.message);
  }
}

message.success = success;
message.warning = warning;
message.info = info;
message.error = error;

export default {
  setHandle: setHandle,
  message: message
}
