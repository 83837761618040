<template>
  <div class="left-wrapper">
    <el-dropdown @command="dropdownCommand">
      <div class="dropdown-content">
        <img class="image-style" :src="image">
        <span class="el-dropdown-link">{{ languageTitle }}<i class="el-icon-arrow-down el-icon--right"></i></span>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="中文">中文</el-dropdown-item>
          <el-dropdown-item command="English">English</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>
<script setup lang="ts">

import { useStore } from 'vuex'
import { ref, getCurrentInstance, withDefaults, defineProps, defineEmits, onMounted, watch } from "vue";
import avatarImgUrl from '../ZImageArea/auth/languages_gray.png'
import { useI18n } from 'vue-i18n'
import { setLanguage } from '../../hooks/useLocale'
import { setFetchLocale, FetchLocale, updateSingleCustomization } from '@zws/axios-fetch'


const { t, locale } = useI18n()
const $t = t
const store = useStore()

const { proxy }: any = getCurrentInstance()
interface Props {
  titleColor: string
  titlePosition: 'flex-start' | 'center' | 'flex-end',
  image: string
}
const props = withDefaults(defineProps<Props>(), {
  dataList: () => [],
  titleColor: '#2c3e50',
  titlePosition: 'flex-start',
  image: avatarImgUrl
})

var languageTitle = ref('中文')

onMounted(() => {
  setPlatformInfo()
})

const $emit = defineEmits(['dropdownCommand', 'recallListOk']);

function dropdownCommand(value: any) {
  proxy.$i18n.locale = value === '中文' ? 'zh' : 'en'
  languageTitle.value = value
  localStorage.setItem('language', value)
  var windows: any = window
  windows.languageLocale = proxy.$i18n.locale
  setFetchLocale(windows.languageLocale as FetchLocale)
  setLanguage(value === '中文' ? 'zh' : 'en')

  $emit('dropdownCommand', value)
}
function setPlatformInfo() {
  var getLanguageSetting = localStorage.getItem('language')
  if (getLanguageSetting) {
    languageTitle.value = getLanguageSetting
    proxy.$i18n.locale = languageTitle.value === '中文' ? 'zh' : 'en'

  } else {
    /* 如果本地无custom自定义 */
    let globalPermissionInfo = store.getters.globalPermissionInfo;
    if (!globalPermissionInfo) return;
    if (globalPermissionInfo.link) {
      const link = JSON.parse(globalPermissionInfo.link)
      languageTitle.value = link.systemLanguage === 'en' ? 'English' : '中文'
      proxy.$i18n.locale = link.systemLanguage
    }

  }
}

watch(
  () => store.getters.globalPermissionInfo,
  () => {
    setPlatformInfo()
  }
)
</script>
<style lang="less" scoped>
.left-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dropdown-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    .image-style {
      width: 20px;
      height: 20px;
    }

    .el-dropdown-link {
      margin-left: 10px;
      color: #333333;
      cursor: pointer;
    }
  }
}
</style>
