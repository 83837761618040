/**
 * ECU管理
 */
export default {
  obtainDTCFileListFailed: '获取故障码文件列表失败',
  fileAbnormal: '文件异常，添加文件失败',
  addDTCFileFailed2: '添加故障码文件失败，{error}',
  addDTCFileSuccess: '添加故障码文件成功',
  addDTCFileFailed: '添加失败，故障码文件内容不正确',
  ecuNameNotBeEmpty: 'ECU名称不能为空',
  needUnlockFile: '该ECU固件包需要上传解锁文件',
  pleaseInputHexNum: '请输入16进制数',
  pleaseSelectUnlockFile: '请选择解锁文件',
  unlockFile: '解锁文件',
  addECU: '添加ECU',
  editECU: '编辑ECU',
  sureToDeleteECU: '是否删除该ECU？',
  deleteECUSuccess: '删除ECU成功',
  deleteECUFailed: '删除ECU失败',
  ecuName: 'ECU名称',
  pleaseInputECUName: '请输入ECU名称',
  physicalAddress0: '物理地址（0x）',
  pleaseInputPhysicalAddress: '请输入物理地址（0x）',
  responseAddress0: '响应地址（0x)',
  pleaseInputResponseAddress: '请输入响应地址（0x）',
  functionAddress0: '功能地址（0x)',
  pleaseInputFunctionAddress: '请输入功能地址（0x）',
  frameType: '帧类型',
  dtcFile: '故障码文件',
  pleaseSelectDTCFile: '请选择故障码文件',
  ecuFirmware: 'ECU固件包',
  pleaseSelectECUFirmware: '请选择ECU固件包',
  standardFrame: '标准帧',
  extendedFrame: '扩展帧',
  addECUConfigSuccess: '添加ECU配置成功',
  modifyECUConfigSuccess: '修改ECU配置成功',
  uploadECUUnlockFileFailed: '上传ECU解锁文件失败',
  uploadECUFirwareFailed: '上传ECU固件升级包失败',
  addECUConfigFailed: '添加ECU配置失败，{error}'
}
