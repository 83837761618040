/**
 * ECU管理
 */
export default {
  obtainDTCFileListFailed: 'Failed to obtain the DTC file list',
  fileAbnormal: 'The file is abnormal and failed to be added',
  addDTCFileFailed2: 'Failed to add the DTC file, {error}',
  addDTCFileSuccess: 'Adding the DTC file succeeded',
  addDTCFileFailed: 'Add failed, The DTC file content is incorrect',
  ecuNameNotBeEmpty: 'The ECU name cannot be empty',
  needUnlockFile: 'The ECU firmware requires an unlocking file to be uploaded',
  pleaseInputHexNum: 'Please enter a hexadecimal number',
  pleaseSelectUnlockFile: 'Please select unlock file',
  unlockFile: 'Unlock File',
  addECU: 'Add ECU',
  editECU: 'Edit ECU',
  sureToDeleteECU: 'Do you want to delete the ECU?',
  deleteECUSuccess: 'Deleting the ECU succeeded',
  deleteECUFailed: 'Failed to delete the ECU',
  ecuName: 'ECU Name',
  pleaseInputECUName: 'Please enter the ECU name',
  physicalAddress0: 'Physical Address(0x)',
  pleaseInputPhysicalAddress: 'Please enter your physical address (0x)',
  responseAddress0: 'Response Address(0x)',
  pleaseInputResponseAddress: 'Please enter the response address (0x)',
  functionAddress0: 'Function Address(0x)',
  pleaseInputFunctionAddress: 'Please enter function address (0x)',
  frameType: 'Frame Type',
  dtcFile: 'DTC File',
  pleaseSelectDTCFile: 'Please select the DTC file',
  ecuFirmware: 'ECU Firmware',
  pleaseSelectECUFirmware: 'Please select the ECU firmware',
  standardFrame: 'Standard Frame',
  extendedFrame: 'Extended Frame',
  addECUConfigSuccess: 'The ECU configuration is added successfully',
  modifyECUConfigSuccess: 'The ECU configuration is modified successfully',
  uploadECUUnlockFileFailed: 'Failed to upload the ECU unlock file',
  uploadECUFirwareFailed: 'Failed to upload the ECU firmware upgrade package',
  addECUConfigFailed: 'Failed to add the ECU configuration, {error}'
}
