<template>
    <div class="upload-wrap">
        <div class="desc-wrap" v-if="showDesc">
            <span class="desc">
                <slot></slot>
            </span>
        </div>
        <el-input :title="fileNameStr" class="upload-file-path" v-model="file.name" v-if="showEditor" readonly
            v-bind="$attrs"></el-input>
        <el-upload class="upload-browser-btn" ref="uploader" action='' :disabled="disable" :auto-upload="false" :limit="1"
            :show-file-list="false" :on-change="fileChange" :on-exceed="fileListExceed" v-bind="$attrs">
            <el-button type="primary" :disabled="disable" v-show="showBrowser" plain v-bind="$attrs">{{ browserBtnText ?
                browserBtnText : auto ? (getLanguage('zws.ZFileSelectorCtrl.upload') || '上传') :
                    (getLanguage('zws.ZFileSelectorCtrl.browser') || '浏览')
            }}</el-button>
        </el-upload>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef, watch, watchEffect } from 'vue'
import { useLocale } from '../hooks/useLocale'
import ZMessage from './ZMessage'

export interface File {
    name: string
    [propName: string]: any
}

export default defineComponent({
    props: {
        modelValue: {},
        fileName: {
            type: String,
            default: '',
        },
        disable: {
            type: Boolean,
            default: false,
        },
        showDesc: {
            type: Boolean,
            default: true,
        },
        showEditor: {
            type: Boolean,
            default: true,
        },
        showBrowser: {
            type: Boolean,
            default: true,
        },
        auto: {
            type: Boolean,
            default: false,
        },
        browserBtnText: {
            type: String,
            default: undefined,
        },
        fileType: {
            type: Array,
        },
        fileSize: {
            type: Number,
        }
    },
    setup(props, { emit }) {
        let { t } = useLocale()
         let getLanguage = function (data: any) {
            
            return t(data)
        }
        let fileName = toRef(props, 'fileName')
        let file = ref<File>({ name: fileName.value })
        let fileNameStr = ref('')
        watch(fileName, (filename: string) => {
            file.value = { name: filename }
        })
         
        const checkFile = (file: File) => {
       
       
            if (props.fileType && props.fileType.length ) {
                const nameArr = file.name.split('.')
                if (!props.fileType.includes(nameArr[nameArr.length - 1]) ) {
                    
                    ZMessage.message.error(getLanguage('zws.ZFileSelectorCtrl.onlySupport') + `${props.fileType}` + getLanguage('zws.ZFileSelectorCtrl.formatAfter'));
                    
                    return false
                }
            }
            if (props.fileSize && file.size / 1024 / 1024 > props.fileSize ) {
                
                ZMessage.message.error(getLanguage('zws.ZFileSelectorCtrl.fileSizeSmaller') + `${props.fileSize}MB`);
                
                return false
            }
            return true
        }

        function emitChange(file: any) {
            fileNameStr.value = file.name
            emit('update:fileName', file.name)
            emit('selectFileChange', file)
            emit('update:modelValue', file)

        }

        let fileChange = (fileInfo: any) => {
            if (checkFile(fileInfo)) {
                file.value = fileInfo.raw
                emit('input', file.value)
                emitChange(file.value)
            }
        }

        let fileListExceed = (files: Array<any>) => {
            if (checkFile(files[0])) {
                file.value = files[0]
                emit('input', file.value)
                emitChange(file.value)
            }
        }

        let uploader = ref<any>(null)

        let clear = () => {
            file.value = { name: '' }
            uploader.value.clearFiles()
            emit('update:fileName', file.value.name)
            emit('update:modelValue', file.value)
        }

        let content = () => {
            return new Promise((resolve, reject) => {
                if (file.value.name) {
                    let reader = new FileReader()
                    reader.readAsText(file.value as any, 'UTF-8')
                    reader.onload = (evt) => {
                        if (evt.target) resolve({ res: true, content: evt.target.result })
                    }
                    reader.onerror = () => {
                        resolve({ res: false, content: '' })
                    }
                }
            })
        }
 
        return {
            getLanguage,
            fileNameStr,
            file,
            uploader,
            fileChange,
            fileListExceed,
            clear,
            content,
        }
    },
})
</script>

<style lang="less" scoped>
.upload-wrap {
    display: flex;
    /*gap: 8px;*/

    .desc-wrap {
        display: flex;
        align-items: center;

        .desc {
            display: inline-block;
            white-space: nowrap;
        }
    }
}
</style>

<style lang="less">
.upload-file-path {
    display: inline-block;
    flex-grow: 1;
    max-height: 100%;
    margin-right: 8px;

    .el-input__inner {
        height: 100%;
    }
}

.upload-browser-btn {
    height: 100%;

    .el-upload {
        height: 100%;

        button {
            height: 100%;
        }
    }
}
</style>
