import axios from 'axios'
import ZWSHttpSuccessModule from '../ZWSHttpSuccessModule'
const CancelToken = axios.CancelToken;

declare global {
  interface Window {
    httpCancelList: any
  }
}

/**
 * 处理 http 网络请求
 */
class ZWSHttp {
  static instance: any

  /**
   * 保证 Http 只有一个实例
   * @param {string} [baseURL] url 前缀
   * @param {number} [timeout] 超时时间
   * @return {ZWSHttp}
   */
  // https://zlab.zlgcloud.com/v1
  // http://192.168.13.2/v1
  static getInstance(baseURL = '', timeout = 30000, header?: any, adapter?: any, tokenStorage?: string) {
    if (!ZWSHttp.instance) {
      ZWSHttp.instance = new ZWSWebHttp(baseURL, timeout, header, adapter, tokenStorage)
    }
    // 接口自定义超时时间
    // if (ZWSHttp?.instance?.http?.defaults?.timeout) {
    //   ZWSHttp.instance.http.defaults.timeout = timeout;
    // }
    return ZWSHttp.instance

  }
}

// /**
//  * 序列化url参数
//  */
// function addParams(params: any) {
//   if (params) {
//     var paramStr = '?'
//     var keys = Object.keys(params)
//     for (var i = 0; i < keys.length; i++) {
//       paramStr += `${keys[i]}=${params[keys[i]]}`
//     }
//     return paramStr
//   } else {
//     return ''
//   }
// }

class ZWSWebHttp {
  http: any
  // http = null
  /**
   * 调用{@link ZWSHttp.getInstance}创建唯一实例
   * @param {string} [baseURL] url 前缀
   * @param {number} [timeout] 超时时间
   */
  constructor(baseURL: any, timeout: any, header: any, adapter: any, tokenStorage?: string) {
    // const headers = {
    //   'Accept': 'application/json',
    //   'Content-Type': 'application/json'
    // }
    // const returnedTarget =Object.assign(headers, header)
    let _tokenStorage = tokenStorage || 'token'
    let options: any = {
      baseURL: baseURL || '',
      timeout: timeout || 10000,
      // headers: returnedTarget
      headers: header || {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
    if (adapter) {
      options.adapter = adapter
    }
    this.http = axios.create(options)
    this.setRequestIntercepter((config: any) => {
      if (config.url === '/control/sessions') {
        config.headers = undefined
      } else {
        // localStorage在jest中会报错；如果jest中为undefined，所以用这个来区分jest单元测试和项目正常使用的情况
        if (typeof window !== 'undefined') {
          config.headers = localStorage.getItem(_tokenStorage) ? { "Authorization": `Bearer ${localStorage.getItem(_tokenStorage)}` } : undefined
        }
      }
      return config

    })
    this.setResponseIntercepter((response: any) => {
      return response
    })
  }
  /**
   * 发送请求
   * @param {Object} request
   * @param {string} request.url - 相对路径，完整路径为 baseUrl + url
   * @param {string} [request.method] - 请求方式，分为GET、POST、HEAD、PUT、DELETE、PATCH
   * @param {Object} [request.data] - 请求的数据，只适用于这些请求方法: 'PUT', 'POST', 'PATCH'
   * @param {string} [request.params] - url 参数
   * @return {Promise<ZWSHttp~resolve, ZWSHttp~reject>}
   */

  fetch({
    url = '',
    method = 'get',
    data = undefined,
    params = undefined,
    responseType = undefined,
    headers = undefined,
    needCancelObj = false,
    code = undefined,
    timeout = undefined
  }: any) {
    return this.http.request({
      url,
      method,
      data,
      params,
      responseType,
      headers,
      // 设置单次请求的超时时间
      timeout,
      // CancelToken 构造函数接收一个 executor 函数参数，并且该函数接收一个取消函数 c 用于取消该次请求
      cancelToken: new CancelToken(function executor(c: any) {
        if (!needCancelObj) return;

        // 将取消函数赋值到 window，方便从外部取消请求
        if (!window.httpCancelList || !(Array.isArray(window.httpCancelList))) {
          window.httpCancelList = [];
        }

        const targetIndex = window.httpCancelList.findIndex((item: any) => {
          return item.name == needCancelObj.name;
        });
        if (targetIndex == -1) {
          needCancelObj.cancelHttpRequest = c;
          window.httpCancelList.push(needCancelObj);
        } else {
          window.httpCancelList[targetIndex].cancelHttpRequest = c;
        }
      }),
    }).then((res: any) => {
      if (code && res.data instanceof Object) {
        let strArr = code.split(".")
        let module = strArr[0]
        let apiName = strArr[1]
        res.data.successMsg = ZWSHttpSuccessModule[module][apiName]
      }
      if (responseType) {
        return res
      }
      return res.data
    }).catch((error: any) => {

      let result: any = {}
      if (axios.isCancel(error)) {
        result = {
          status: '(canceled)',
          errorMsg: error.message,
        }
      } else if (error.response) {
        result = {
          status: error.response.status,
          errorMsg: error.response.statusText,
          data: error.response.data
        }
      } else if (error.request) {
        result = {
          status: 'NO_RESPONSE',
          errorMsg: 'NO_RESPONSE'
        }
      } else {
        result.errorMsg = 'Something happened in setting up the request that triggered an Error'
      }
      return Promise.reject(result)
    })
  }

  setRequestIntercepter(callback: any) {
    this.http.interceptors.request.use(callback, (error: any) => {
      return Promise.reject(error)
    })
  }

  setResponseIntercepter(callback: any) {
    this.http.interceptors.response.use(callback, (error: any) => {
      if (error?.response?.status === 401) {
        window.location.replace(`${window.location.origin}${window.location.pathname}#/Login`)
      }
      return Promise.reject(error)
    })
  }
}

export default ZWSHttp
