import Login from '../components/Auth/Login.vue'
import Register from '../components/Auth/Register.vue'
import ForgetPassword from '../components/Auth/ForgetPassword.vue'
import Container from '../components/Container.vue'
// import HomePage from '../components/Home/HomePage.vue'
// import DeviceDetails from '../components/Home/DeviceDetails/DeviceDetails.vue'
// import UserCenter from '../components/User/UserCenter.vue'
// import SubuserManagement from '../components/User/SubuserManagement.vue'
// import Log from '../components/User/HandleLog.vue'
// import DBCManager from 'comp/DBCManager/DBCManager.vue'
// import ECUManager from 'comp/ECUManager/ECUManager.vue'
// import BreakdownCodeManager from 'comp/BreakdownCodeManager/BreakdownCodeManager.vue'
// import DBCImportPage from 'comp/Common/DBC/DBCImportPage.vue'

const routes = [
  {
    path: '/Login',
    component: Login
  },
  {
    path: '/Register',
    component: Register
  },
  {
    path: '/ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '/doc',
    component: () => import('../components/Doc/Doc.vue')
  },
  {
    path: '/',
    component: Container,
    redirect: { path: '/Container/HomePage' }
  },
  {
    path: '/Container',
    component: Container,
    children: [
      {
        path: 'HomePage',
        component: () => import('../components/Home/HomePage.vue')
        // component: HomePage
      },
      // {
      //     path: 'HomePage/DeviceDetails',
      //     component: () => import('../components/Home/DeviceDetails/DeviceDetails.vue')
      //     // component: DeviceDetails
      // },
      {
        name: 'DeviceDetails',
        path: 'HomePage/DeviceDetails',
        component: () => import('../components/Home/DeviceDetails/DeviceDetails.vue')
      },
      {
        path: 'DBCManager',
        component: () => import('comp/DBCManager/DBCManager.vue')
        // component: DBCManager
      },
      {
        path: 'ECUManager',
        component: () => import('comp/ECUManager/ECUManager.vue')
      },
      {
        path: 'BreakdownCodeManager',
        component: () => import('comp/BreakdownCodeManager/BreakdownCodeManager.vue')
        //  component: BreakdownCodeManager
      },
      {
        path: 'EditTriggerRule',
        component: () => import('../components/Home/DeviceDetails/TriggerManagement/TriggerRules/EditTriggerRule.vue')
      },
      {
        path: 'WorkOrderList',
        component: () => import('comp/WorkOrder/WorkOrderList.vue')
      },
      {
        path: 'HandleWorkOrder',
        component: () => import('comp/WorkOrder/HandleWorkOrder.vue')
      },
      {
        path: 'WorkOrderDetail',
        component: () => import('comp/WorkOrder/WorkOrderDetail.vue')
      },
      // 应该没用
      // {
      //     path: 'UserManager/UserCenter',
      //     component: () => import('../components/User/UserCenter.vue')
      //     //  component: UserCenter
      // },
      {
        path: 'UserManager/SubuserManagement',
        component: () => import('../components/User/SubuserManagement/SubuserManagement.vue')
        //  component: SubuserManagement
      },
      {
        path: 'UserManager/Log',
        component: () => import('../components/User/HandleLog.vue')
        //  component: Log
      },
      {
        path: 'UserManager/SystemConfig',
        component: () => import('../components/User/SystemConfig.vue')
      },
      {
        name: 'DBCImportPage',
        path: 'DBCImportPage',
        component: () => import('comp/Common/DBC/DBCImportPage.vue')
        // component: DBCImportPage
      }
    ]
  }
]
export { routes }
