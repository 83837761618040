// 在 main.ts 下 或者 自定义文件下
import { registerApi } from '@zws/axios-fetch'

export type GetTenantDatamgmtUsagePercentageReq = {
  tenant_id: number
}

export type IssueRequestDownloadDeviceDataReq = {
  begin_time?: number
  category_id?: number
  data_transform_id?: number
  end_time?: number
  event_name?: string
  group_id?: number
  info_model_id: number
  op?: string
  property?: string
  query_by_group?: boolean
  sort_property?: string
  sort_property_order?: string
  sort_property_type?: string
  download_template_id?: number
  tenant_id: number
  things_id: number
  user_id?: number
  value?: string
}

registerApi([
  {
    apiName: 'deleteSingleInfoModel',
    apiFunc: ({ tenant_id, info_model_id, force }: any, fetch: any) => {
      return fetch({
        url: `/mapping-mgmt/tenants/${tenant_id}/info-models/${info_model_id}`,
        method: 'delete',
        params: { force }
      })
    }
  },
  {
    apiName: 'getUserMessageByUser',
    apiFunc: ({ tenant_id, user_id, platform, read_status, current_page, page_size }: any, fetch: any) => {
      return fetch({
        url: `/tenant/tenants/${tenant_id}/users/${user_id}/message`,
        method: 'get',
        params: { platform, read_status, current_page, page_size }
      })
    }
  },
  {
    apiName: 'deleteSingleTenantFile',
    apiFunc: ({ isPub, name, type, tenant_id }: any, fetch: any) => {
      return fetch({
        url: `/out-filemanager/tenants/${tenant_id}/files`,
        method: 'delete',
        params: { isPub, name, type }
      })
    }
  }
])

// // 单接口注册
// registerApi({
//   apiName: "getSinglePackageTenant",
//   apiFunc: ({ tenant_id, type, package_id }: any, fetch: any) => {
//     return fetch({
//       url: `/out-billing/tenants/${tenant_id}/package`,
//       method: "get",
//       params: { type, packageId: package_id },
//     });
//   },
// });
// // 多接口注册
// registerApi([
//   {
//     apiName: "getSinglePackageTenant",
//     apiFunc: ({ tenant_id, type, package_id }: any, fetch: any) => {
//       return fetch({
//         url: `/out-billing/tenants/${tenant_id}/package`,
//         method: "get",
//         params: { type, packageId: package_id },
//       });
//     },
//   },
//   {
//     apiName: "deleteSingleCoupon",
//     apiFunc: ({ id }: any, fetch: any) => {
//       return fetch({
//         url: `/out-billing/sys/coupon`,
//         method: "delete",
//         params: { id },
//       });
//     },
//   },
// ]);

// // 接口使用
// import { apiModule, FetchError } from "@zws/axios-fetch";
// apiModule["getSinglePackageTenant"]({
//   tenant_id: userInfo.value.tenant_id,
// })
//   .then((res: any) => {
//     // 逻辑处理
//   })
//   .catch((error: FetchError) => {
//     console.log(error);
//   });
