export default {
  skipSetChannelSignal(state: { skipSetChannelSignal: boolean }, payload: boolean) {
    state.skipSetChannelSignal = payload
  },
  currentDashbordInfo(state: { currentDashbordInfo: any }, payload: any) {
    state.currentDashbordInfo = payload
    localStorage.setItem('currentDashbordInfo', JSON.stringify(payload))
  },
  deviceDetailsCurrentDeviceInfo(state: { deviceDetailsCurrentDeviceInfo: any }, payload: any) {
    state.deviceDetailsCurrentDeviceInfo = payload
    localStorage.setItem('deviceDetailsCurrentDeviceInfo', JSON.stringify(payload))
  },
  tenantCustomizationInfo(state: { tenantCustomizationInfo: any }, payload: any) {
    state.tenantCustomizationInfo = payload
    localStorage.setItem('tenantCustomizationInfo', JSON.stringify(payload))
  },
  userInfo(state: { userInfo: any }, payload: any) {
    state.userInfo = payload
    localStorage.setItem('userInfo', JSON.stringify(payload))
  },
  registerInputUserInfo(state: { registerInputUserInfo: any }, payload: any) {
    state.registerInputUserInfo = payload
  },
  channelsInfo(state: { channelsInfo: any }, payload: any) {
    state.channelsInfo = payload
  },
  headerMenuActiveIndex(state: { headerMenuActiveIndex: string }, payload: string) {
    state.headerMenuActiveIndex = payload
    localStorage.setItem('headerMenuActiveIndex', payload)
  },
  getCapacity(state: { getCapacity: any }, payload: string) {
    state.getCapacity = payload
  },
  tenantPermissionInfo(state: { tenantPermissionInfo: any }, payload: any) {
    state.tenantPermissionInfo = payload
    localStorage.setItem('tenantPermissionInfo', JSON.stringify(payload))
    if (!payload.tenant_manager && !payload.system_manager) {
      const userApiRoleListMap = {}
      payload.apis?.forEach((item: any) => {
        userApiRoleListMap[item.name] = true
      })
      localStorage.setItem('userApiRoleListMap', JSON.stringify(userApiRoleListMap))
    } else {
      localStorage.removeItem('userApiRoleListMap')
    }
  },
  setCommandMessage(state: { tenantPermissionInfo: any }, payload: any) {
    state[payload.name] = new Date().getTime() || payload.value
    state[payload.name + 'Value'] = payload.value
  },
  channelListChanged(state: { channelListChanged: any; [propName: string]: any }, payload: any) {
    state.channelListChanged = payload
  },
  setUserPackage(state: { userPackage: any }, payload: any) {
    state.userPackage = payload
  },
  globalPermissionInfo(state: { globalPermissionInfo: any }, payload: any) {
    state.globalPermissionInfo = payload
  },
  setEchartsListChange(state: any, payload: boolean) {
    state.echartsListChange = payload
  },
  setRuleListChange(state: any, payload: boolean) {
    state.ruleListChange = payload
  },
  setDevDetailLoading(state: any, payload: boolean) {
    state.devDetailLoading = payload
  },
  setRefreshDevList(state: any, payload: boolean) {
    state.refreshDevList = payload
  },
  setDetailCurPart(state: any, payload: string) {
    state.detailCurrentPart = payload
  },
  setEcuListChange(state: any, payload: boolean) {
    state.ecuListChange = payload
  },
  setAnnouncementShow(state: any, payload: boolean) {
    state.announcementShow = payload
  }
}
