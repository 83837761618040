import ZWSBaseModule from '../httpBase/ZWSBaseModule'

interface OpcuaThingsInfo {
    attributes?: string,
    description: string,
    desired_properties?: string,
    desired_status?: string,
    full_things_id?: string,
    gw_things_id?: number,
    id?: number,
    info_model_id: number,
    info_model_name?: string,
    maximum_duration_online?: number,
    name: string,
    parent_things_id?: number,
    revsion?: string,
    status?: number,
    thing_type: number,
    things_key: string,
    third_things_id: string,
    twin_things_status?: string,
    vendor_tenant_id?: number
}
/**
 * LoRaWAN组播
 */
class ZWSSingleOpcua extends ZWSBaseModule {

    /*************************** things ********************************** */

    static things_addOpcuaThings({ tenant_id, body }: {
        tenant_id: number, body: OpcuaThingsInfo
    }) {
        return this.fetch({
            url: `/single-opcua/tenants/${tenant_id}/things`,
            method: 'post',
            data: body
        })
    }

}

export default ZWSSingleOpcua