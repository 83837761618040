<template>
    <el-select v-model="curDevType" placeholder="请选择设备类型" @change="gatewayDevChange">
        <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { useStore } from 'vuex'
import Message from '../../ctrl/ZMessage'
import { getSingleInfoModel } from '@zws/axios-fetch'

interface UserInfo {
    avatar: string,
    create_time: number,
    department: string,
    description: string,
    email: string,
    gender: string,
    id: number,
    job: string,
    mobile: string,
    name: string,
    nick_name: string,
    status: number,
    tenant_id: number,
}
export default defineComponent({
    props: {
        typeOptions: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
        gatewayArray: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const store = useStore()
        const curDevType = ref<number | null>()

        const userInfo = computed<UserInfo>(() => store.getters.userInfo)
        const gatewayDevChange = (val: number) => {
            getSingleInfoModel({ tenant_id: userInfo.value.tenant_id, info_model_id: val })
                .then((result: any) => {
                    let getwayDev: any[] = []
                    if (props.gatewayArray && props.gatewayArray.length > 0) {
                        console.log('props2', props.gatewayArray)
                        getwayDev = props.gatewayArray.filter((item) => {
                            return item.info_model_id === val
                        })
                    }
                    emit('typeChange', { devTypeId: val, devType: result, getwayDevs: getwayDev })
                })
                .catch((err: any) => {
                    console.log(err)
                    Message.message.error('服务器异常，获取选中的设备类型失败')
                })
        }

        watch(
            () => props.typeOptions,
            () => {
                if (props.typeOptions?.length > 0) {
                    curDevType.value = props.typeOptions[0].id
                    gatewayDevChange(curDevType.value as number)
                } else {
                    curDevType.value = null
                    emit('typeChange', { devTypeId: null, devType: null, getwayDevs: [] })
                }
            },
            {
                immediate: true,
            }
        )

        return {
            curDevType,
            gatewayDevChange,
        }
    },
})
</script>

<style lang="less" scoped>
.el-select {
    width: 300px;
}
</style>
