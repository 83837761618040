import { parse } from 'querystring'
import type { FunctionBlockSchema, IotFunctionBlock, DataFieldSet } from './ZWSIotMappingManagerTypes'

export function parseFnBlock(fnBlock: IotFunctionBlock, schemaName?: string): { [dataFieldSetType: string]: DataFieldSet[] } {
    let schemaObj: FunctionBlockSchema = fnBlock && fnBlock.json_schema ? JSON.parse(fnBlock.json_schema) : {}
    if (schemaName) {
        schemaObj = fnBlock && fnBlock[schemaName] ? JSON.parse(fnBlock[schemaName]) : {}
    }
    !schemaObj?.events && [(schemaObj['events'] = [])]
    !schemaObj?.status && [(schemaObj['status'] = { description: '', properties: [] })]
    !schemaObj?.operations && [(schemaObj['operations'] = [])]
    !schemaObj?.configuration && [(schemaObj['configuration'] = { description: '', properties: [] })]

    let dataFieldSets: {
        data: DataFieldSet[],
        log: DataFieldSet[],
        condition: DataFieldSet[],
        status: DataFieldSet[],
        operation: DataFieldSet[],
        configuration: DataFieldSet[]
    } = {
        data: [],
        log: [],
        condition: [],
        status: [],
        operation: [],
        configuration: []
    }

    let dataDataFieldSets = dataFieldSets.data
    let operationDataFieldSets: any = dataFieldSets.operation
    let logDataFieldSets = dataFieldSets.log
    let statusDataFieldSets = dataFieldSets.condition
    if (Object.keys(schemaObj).length) {
        for (let item of schemaObj.events) {
            if (item.event_type === 'data') {
                dataDataFieldSets.push(item)
            } else if (item.event_type === 'log') {
                logDataFieldSets.push(item)
            } else if (item.event_type === 'status') {
                statusDataFieldSets.push(item)
            }
        }
    }

    dataFieldSets.status = [{ name: 'Status', description: schemaObj.status.description, properties: schemaObj.status.properties }]
    schemaObj.operations.forEach((oper: any) => {
        operationDataFieldSets.push({ name: oper.name, description: oper.description, properties: oper.inputs, i18n: oper.i18n })
    })
    dataFieldSets.configuration = [
        { name: 'Configuration', description: schemaObj.configuration.description, properties: schemaObj.configuration.properties },
    ]

    return dataFieldSets
}

export function parseFnBlockDisplay(fnBlock: any, dataDataFieldSetsDisplay: any, statusFieldSetsDisplay: any, logDataFieldSetsDisplay?: any
    , configurationSetsDisplay?: any, statusxSetsDisplay?: any) {

    let dataFieldSetsDisplay = parseFnBlock(fnBlock, 'display_json_schema')

    dataDataFieldSetsDisplay.value = dataFieldSetsDisplay.data
    statusFieldSetsDisplay.value = dataFieldSetsDisplay.condition
    if (logDataFieldSetsDisplay?.value) {
        logDataFieldSetsDisplay.value = dataFieldSetsDisplay.log
    }
    if (configurationSetsDisplay?.value) {
        configurationSetsDisplay.value = dataFieldSetsDisplay.configuration
    }

    if (statusxSetsDisplay?.value) {
        statusxSetsDisplay.value = dataFieldSetsDisplay.status
    }
    function watchDisplay(newVal?: any) {

        let arrAll: any = []
        let arr: any = []
        newVal.filter(
            (item: any) => {

                if (item.is_show === false) {
                    arr.push(item.name)
                    return item.identifier
                } else {
                    let indx = arrAll.indexOf(item.name)
                    arrAll.splice(indx, 1)
                }
            }
        )
        return arr
    }
    var dataHideArr = watchDisplay(dataDataFieldSetsDisplay.value)
    var statusHideArr = watchDisplay(statusFieldSetsDisplay.value)
    var logHideArr = []
    var configHideArr = []
    var statusxHideArr = []
    if (logDataFieldSetsDisplay?.value) {
        logHideArr = watchDisplay(logDataFieldSetsDisplay.value)
    }
    if (configurationSetsDisplay?.value) {
        configHideArr = watchDisplay(configurationSetsDisplay.value)
    }

    if (statusxSetsDisplay?.value) {
        statusxHideArr = watchDisplay(statusxSetsDisplay.value)
    }
    return {
        dataHideArr, statusHideArr, logHideArr, configHideArr, statusxHideArr, dataDataFieldSetsDisplay: dataDataFieldSetsDisplay.value, statusFieldSetsDisplay: statusFieldSetsDisplay.value, logDataFieldSetsDisplay: logDataFieldSetsDisplay ? logDataFieldSetsDisplay.value : [],
        configurationSetsDisplay: configurationSetsDisplay ? configurationSetsDisplay.value : [],
        statusxSetsDisplay: statusxSetsDisplay ? statusxSetsDisplay.value : []
    }
}
export function checkVisible(item_: any, displaySche: any, e: any, sort?: any) {
    var data = displaySche.dataDataFieldSetsDisplay || []
    if (e.event_type === 'status') {
        data = displaySche.statusFieldSetsDisplay || []

    } if (e.event_type === 'log') {
        data = displaySche.logDataFieldSetsDisplay || []

    }
    if (e.event_type === 'configuration' || sort === 'configuration') {
        data = displaySche.configurationSetsDisplay || []

    }
    if (sort === 'statusx') {
        data = displaySche.statusxSetsDisplay || []

    }
    var arrNew = JSON.parse(JSON.stringify(e.properties))


    data = data.find((item: any, index_: any) => {


        if (item.name === e.name || sort === 'configuration' || sort === 'statusx') return true
    }) || {}

    var sets = data.properties || []
    var idsVisible: any = []
    var arr = sets.filter((item2: any) => {

        if (item2.is_show === false) {
            return !false
        } else {
            idsVisible.push(item2.identifier)
            return false
        }


    }) || []
    var ids = arr.map((item: any) => {

        return item.identifier
    })

    arrNew = arrNew.filter((item3: any) => {

        if (ids.includes(item3.identifier)) {
            return false
        } else {
            return !false
        }


    })
    /* 排序 */

    arrNew.sort((a: any, b: any) => {

        var left = 0
        var right = 0

        left = idsVisible.indexOf(a.identifier)
        right = idsVisible.indexOf(b.identifier)
        // 根据id 倒序
        return left - right
    }
    )
    return arrNew
}

export function sortAsDisplay(arrNew: any, displaySche: any, event_type: any) {

    /* 排序 */
    var data = [].concat(displaySche.dataDataFieldSetsDisplay || [])

    data = data.concat(displaySche.statusFieldSetsDisplay || [])
    if (event_type == 'log') {
        data = [].concat(displaySche.logDataFieldSetsDisplay || [])
    }
    if (event_type == 'config') {
        data = [].concat(displaySche.configurationSetsDisplay || [])
    }
    var ids = data.map((item: any) => {

        return item.name
    })

    arrNew.sort((a: any, b: any) => {


        var left = 0
        var right = 0

        left = ids.indexOf(a.name)
        right = ids.indexOf(b.name)
        // 根据id 倒序
        return left - right
    }
    )
    return arrNew
}
export function extractDataFieldIdentifier(fnBlock: IotFunctionBlock): { [dataFieldSetType: string]: string[] } {
    let dataFieldSets = parseFnBlock(fnBlock)
    let dataFieldSetPropertyNames: { [dataFieldSetType: string]: string[] } = {}

    for (let type in dataFieldSets) {
        let fieldSets = dataFieldSets[type]
        if (!fieldSets?.length) continue

        dataFieldSetPropertyNames[type] = []
        for (let fieldSet of fieldSets) {

            if (type == 'data' || type == 'operation' || type == 'log') {
                dataFieldSetPropertyNames[type].push(fieldSet.name)
            } else {
                dataFieldSetPropertyNames[type] = dataFieldSetPropertyNames[type].concat(fieldSet.properties.map((props) => {
                    return props.identifier
                }))
            }
        }
    }

    return dataFieldSetPropertyNames
}

export function isDataFieldIdentifierExist(fnBlock: IotFunctionBlock, type: string, name: string) {
    if (fnBlock) return false

    let dataFieldSetPropertyNames = extractDataFieldIdentifier(fnBlock)

    if (!dataFieldSetPropertyNames[type].length) {
        return false
    }

    return dataFieldSetPropertyNames[type].some((_name) => {
        if (name == _name) return true
        else return false
    })
}

export function isDataFieldIdentifierExistsInFnBlocks(fnBlocks: IotFunctionBlock[], type: string, name: string) {
    for (let fb of fnBlocks) {
        if (isDataFieldIdentifierExist(fb, type, name)) return true
    }

    return false
}

export function isFnBlockDataFieldIdentifierExistInFnBlocks(fnBlock: IotFunctionBlock, fnBlocks: IotFunctionBlock[]) {
    let dataFieldSetPropertyNames1 = extractDataFieldIdentifier(fnBlock)

    for (let fb of fnBlocks) {
        let dataFieldSetPropertyNames2 = extractDataFieldIdentifier(fb)

        for (let type in dataFieldSetPropertyNames1) {
            if (!dataFieldSetPropertyNames2[type]) continue

            let names = dataFieldSetPropertyNames1[type]

            for (let name of names) {
                if (name == 'sysMsgId') continue
                if (dataFieldSetPropertyNames2[type].some((n) => {
                    return n == name
                })) {
                    return { type, name }
                }
            }
        }
    }
}