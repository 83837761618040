<template>
  <div class="z-dialog-ctrl">
    <el-dialog :title="title" v-model="display" :width="width" :close-on-click-modal="false" center v-bind="dialogAttrs">
      <div class="content">
        <slot> </slot>
      </div>
      <template v-if="needElementFooter && needFooter" #footer>
        <ZButtonListCtrl v-bind="buttonListAttrs" @buttonClicked="buttonClicked">
        </ZButtonListCtrl>
      </template>
      <div class="footer" v-if="!needElementFooter && needFooter">
        <ZButtonListCtrl v-bind="buttonListAttrs" @buttonClicked="buttonClicked">
        </ZButtonListCtrl>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef, watch, computed } from 'vue'

import ZButtonListCtrl from './ZButtonListCtrl.vue'
import { useI18n } from 'vue-i18n'

export interface ShowDialogSetting {
  [propName: string]: any
}

export interface ClickedButtonEvent {
  index: number // 按钮序号
  text: string // 按钮文本
  [propName: string]: any
}

export default defineComponent({
  emits: ['buttonClicked', 'update:show'],
  setup(props, { emit, attrs }) {
    const { t } = useI18n()
    const $t = t
    let _show = toRef(props, 'show')
    let display = ref(props.show)

    watch(_show, (_display: boolean) => {
      display.value = _display
    })

    watch(display, (_display: boolean) => {
      emit('update:show', _display)
    })

    let setting: ShowDialogSetting | undefined
    // let buttonList = computed(() => ([
    //   {
    //     text: $t("common.button.cancel") != "common.button.cancel" ? $t("common.button.cancel") : "取消",
    //   },
    //   {
    //     text: $t("common.button.sure") != "common.button.sure" ? $t("common.button.sure") : "确定",
    //     type: "primary",
    //   },
    // ])
    // )
    function show(_show: boolean, _setting?: ShowDialogSetting) {
      setting = _setting
      display.value = _show
    }

    let buttonClickedClosed = props.buttonClickedClosed

    function buttonClicked(button: ClickedButtonEvent) {
      if (buttonClickedClosed) display.value = false

      Object.assign(button, setting)
      emit('buttonClicked', button)
    }

    let headerPadding = '20px 20px 10px 20px'
    let bodyPadding = '30px 40px'
    let footerPadding = '10px 20px 20px 20px'

    function initPadding(standard: boolean, needBodyPadding: boolean) {
      if (standard) {
        headerPadding = '20px 20px 35px 20px'
        bodyPadding = '0px 40px'
        footerPadding = '30px 20px 40px 20px'
      }

      if (!needBodyPadding) {
        headerPadding = '20px 20px 0px 20px'
        bodyPadding = '0px'
        footerPadding = '0px 20px 40px 20px'
      }
    }

    initPadding(props.useStandard, props.needBodyPadding)

    let dialogAttrs = ref({}) as any
    let buttonListAttrs = ref({}) as any

    function filterAttrs() {
      function buildRef(refAttrs: any, key: string) {
        refAttrs[key] = attrs[key]
        watch(
          () => attrs[key],
          v => {
            refAttrs[key] = v
          }
        )
      }

      for (let key in attrs) {
        switch (key) {
          case 'buttonList':
            buildRef(buttonListAttrs.value, key)
            break
          default:
            buildRef(dialogAttrs.value, key)
            break
        }
      }
    }

    filterAttrs()

    return {
      // buttonList,
      display,
      show,
      buttonClicked,
      headerPadding,
      bodyPadding,
      footerPadding,
      dialogAttrs,
      buttonListAttrs
    }
  },
  props: {
    title: {},
    // 点击按钮后关闭
    buttonClickedClosed: {
      type: Boolean,
      default: true
    },
    // 是否使用原 element-ui 页脚
    needElementFooter: {
      type: Boolean,
      default: true
    },
    needFooter: {
      type: Boolean,
      default: true
    },
    needBodyPadding: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: '400px'
    },
    show: {
      type: Boolean,
      default: false
    },
    useStandard: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ZButtonListCtrl
  }
})
</script>

<style lang="less" scoped>
.z-dialog-ctrl {
  .content {
    text-align: center;
  }

  :deep(.el-dialog__header) {
    padding: v-bind(headerPadding);
  }

  :deep(.el-dialog__body) {
    padding: v-bind(bodyPadding);

    .content {
      .el-form {
        .el-form-item:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  :deep(.el-dialog__footer) {
    padding: v-bind(footerPadding);
  }

  .footer {
    text-align: center;
  }
}
</style>

<style lang="less">
.el-message {
  z-index: 9999 !important;
}
</style>
