// 日期转换格式
export function dateFormat(time: number, format: string): string {
  if (!time || !format) {
    return ''
  }
  // 减14个小时
  // time = time - 14 * 60 * 60 * 1000
  let date = new Date(time)
  let o: any = {
    "M+": date.getMonth() + 1,                 //月份
    "d+": date.getDate(),                    //日
    "h+": date.getHours(),                   //小时
    "m+": date.getMinutes(),                 //分
    "s+": date.getSeconds(),                 //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds().toString().padStart(3, '0')             //毫秒
  };
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length))
  }
  let k: any
  for (k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
    }
  }
  return format
}

// 清空对象空属性
export function clearNull(obj: any) {
  let notNull: any = {}
  for (let i in obj) {
    if (obj[i] || obj[i] === 0) {
      notNull[i] = obj[i]
    }
  }
  return notNull
}

// 前端分页
export function getArrayByPagination(dataArr: any[], current_page: number, page_size: number) {
  return dataArr.slice((current_page - 1) * page_size, current_page * page_size)
}

/**
 * 数据类型检测
 * */
export function toType(obj: any) {
  const class2type: any = {};
  const toString: any = class2type.toString; // Object.prototype.toString 检测数据类型

  // 构建数据类型检测映射表
  // Boolean Number String Symbol 是为了处理基于构造函数创建的基本类型的引用类型值，最后检测出来的结果依然是 "Boolean" "Number" "String" "Symbol"
  // 比如 toString.call(new Numnber(10))  // =>  [object Number]
  [
    "Boolean",
    "Number",
    "String",
    "Symbol",
    "Function",
    "Array",
    "Date",
    "RegExp",
    "Object",
    "Error",
    "GeneratorFunction"  // JQ缺点 无法判断 Generator 函数: toType(function *() {}) => [object Object]
    // {}.toString.call(function *() {})  => [object GeneratorFunction]
  ].forEach(function(name){
    class2type["[object " + name + "]"] = name.toLowerCase();
  });

  // == null 有两种情况: null / undefined
  if (obj == null) {
    return obj + "";
  }

  // 如果是引用数据类型（包含: new Number(10) 这种），则基于 Object.prototype.toString 来检测
  // typeof obj 是 object 或者 function，则为引用类型，使用 toString.call 处理
  return typeof obj === "object" || typeof obj === "function" ?
      // 检测结果从 class2type 映射表中查找，拿到对应小写的数据类型，找不到就返回 object
      class2type[toString.call(obj)] || "object" :
      // 剩下的情况用 typeof 就可以搞定
      typeof obj;
}

/**
 * 检测是否为一个纯粹的对象
 * 纯对象就是普通对象{}，数组对象/正则对象/日期对象 等其他都不是纯对象
 * */
export function isPlainObject(obj: any) {
  // 原理：__proto__ 下的 constructor 直接是 ƒ Object()
  const class2type = {};
  const toString = class2type.toString;
  const hasOwn = class2type.hasOwnProperty;
  const fnToString = hasOwn.toString;
  const ObjectFunctionString = fnToString.call(Object); // 'function Object() { [native code] }'

  // proto: prototype 简写
  // Ctor: constructor 简写
  let proto, Ctor;

  // 一定不是对象：不存在 或 数据类型检测不是 Object 的，连对象都不是，更不可能是纯对象
  if (!obj || toString.call(obj) !== "[object Object]") {
    return false;
  }

  // 不存在原型对象 prototype 的时候，也是 纯对象
  // Object.create(null) 这样创建的是一个 没有原型的 {}
  proto = Object.getPrototypeOf(obj); // 获取 obj 的原型对象
  if (!proto) {
    return true;
  }

  // 获取当前原型对象上的 constructor 构造函数
  Ctor = hasOwn.call(proto, "constructor") && proto.constructor;
  // typeof Ctor === "function"  =>  有构造函数，
  // 且构造函数需要直接是 Object 才可以，这样就排除了 NodeList / 自定义类的实例 等
  // 只有它的原型直接是 Object.prototype 才可以是纯对象
  return typeof Ctor === "function" && fnToString.call(Ctor) === ObjectFunctionString;
}

/**
 * 合并两个对象：可用于插件组件封装中：用户传递的参数配置，覆盖默认的参数配置
 * */
// 插件组件封装中：用户传递的参数配置，覆盖默认的参数配置（合并两个对象）
// 情况1：obj1是对象，obj2是对象：依次遍历 obj2，把 obj2 中的每一项替换为 obj1 中的每一项
// 情况2：obj1是对象，obj2不是对象：不进行任何处理，返回的还是 obj1
// 情况3：obj1不是对象，obj2是对象：obj2 直接替换 obj1
export function deepMergeObj(obj1: any, obj2: any) {
  let isPlain1 = isPlainObject(obj1),
      isPlain2 = isPlainObject(obj2);
  if(!isPlain1) return obj2; // 情况3
  if(!isPlain2) return obj1; // 情况2
  // 情况1处理：遍历 obj2 中的每一项，让其替换 obj1 中的每一项
  [
    ...Object.getOwnPropertyNames(obj2),
    ...Object.getOwnPropertySymbols(obj2)
  ].forEach(key => {
    // obj1[key] = obj2[key];
    // 如果 obj2[key] 里面又套了一层，需要递归
    obj1[key] = deepMergeObj(obj1[key], obj2[key]);
  });
  return obj1;
}

// 比较版本号
export function comparativeVersion(first: string, second: string) {
  let firstArr = first.split('.')
  let secondArr = second.split('.')
  let result = ""
  let minLength = 0
  if (firstArr.length === secondArr.length) {
    result = "equal"
    minLength = firstArr.length
  } else {
    if (firstArr.length > secondArr.length) {
      result = "greater"
      minLength = secondArr.length
    } else {
      result = "less"
      minLength = firstArr.length
    }
  }
  for (let i = 0; i < minLength; i++) {
    let _first = Number(firstArr[i])
    let _second = Number(secondArr[i])
    if (isNaN(_first) || isNaN(_second)) {
      throw Error('错误数据')
    }
    if (_first !== _second) {
      if (_first > _second) {
        return "greater"
      } else {
        return "less"
      }
    }
  }
  return result
}


// 防抖函数
export function debounce(fn: Function, delay: number | undefined) {
  let timer: NodeJS.Timeout
  // let timer: any
  return function (this: any, ...params: any[]) {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.call(this, ...arguments)
    }, delay)
  }
}