/**
 * 判断当前用户是否有该dom节点的权限  v-domPerm:type="key"
 * @param [string] key 权限
 * 修饰符为 domPerm：根据结果决定是否展示该 el
 */
export const domPerm = {
    parentNode: <any>null,
    dealHasPermission(el: HTMLElement, binding: any) {
        let hasPermissionState: boolean = true;
        const userApiRoleListMap = localStorage.getItem('userApiRoleListMap') ? JSON.parse(localStorage.getItem('userApiRoleListMap') as string) : undefined
        let item: any = binding.value;
        if (userApiRoleListMap && item) {
            hasPermissionState = userApiRoleListMap[item];
        }

        if (!domPerm.parentNode) return;
        if (!hasPermissionState) {
            domPerm.parentNode.contains(el) && domPerm.parentNode.removeChild(el);
        } else {
            domPerm.parentNode && !domPerm.parentNode.contains(el) && domPerm.parentNode.appendChild(el);
        }
    },
    mounted(el: HTMLElement, binding: any) {
        domPerm.parentNode = el.parentNode;
        domPerm.dealHasPermission(el, binding);
    },
    beforeUpdate(el: HTMLElement, binding: any) {
        domPerm.dealHasPermission(el, binding);
    },
};