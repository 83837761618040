<template>
  <el-dialog top="20vh" custom-class="notice" v-model="dialogShowVisible" width="780px" :append-to-body="true"
    :close-on-click-modal="false" :close-on-press-escape="false" :title="title" :before-close="onKnowButtonClicked"
    v-bind="$attrs">
    <div class="notice-container">
      <div class="notice-title">尊敬的用户：</div>
      <div class="notice-top-tips">感谢您长期的以来信任和支持，为了向您提供更优质的服务，<span class="time">{{ system }}</span> 计划于<span
          class="time">{{
            time
          }}</span>进行业务系统升级，主要包括以下内容：</div>
      <div class="notice-content">
        <div v-for="(item, index) in upgradeContent">{{ index + 1 }}、{{ item }}</div>
      </div>
      <div class="notice-bottom-tips">
        届时平台功能将无法使用，给您带来不便，敬请谅解！如有任何问题，可发邮件至zws@zlg.cn沟通咨询，我们将尽快回复，谢谢！
      </div>
    </div>
    <div class="notice-bottom">
      <el-button type="text" @click="onKnowButtonClicked">我知道了</el-button>
      <!-- <div>
        <span class="no-tips" style="color: #80b33f;" @click="onKnowButtonClicked">我知道了</span>
      </div> -->
      <div>
        <span class="no-tips" @click="onNoTipsClicked">不再提示</span>
      </div>
    </div>
  </el-dialog>
</template>

<script setup lang='ts'>
import { computed, ref, watch } from 'vue'

interface Props {
  modelValue: boolean
  upgradeTime: string[]
  upgradeContent: string[]
  version: string
  title: string
  system: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  upgradeContent: () => [],
  upgradeTime: () => [],
  version: "1.0",
  title: "系统升级公告",
  system: ""
})
const emit = defineEmits(['update:modelValue'])

const dialogShowVisible = ref<boolean>(false)

const time = computed(() => {
  if (props.upgradeTime.length && props.upgradeTime.length === 2) {
    return ` ${props.upgradeTime[0]} 至 ${props.upgradeTime[1]} `
  } else {
    return ""
  }
})

const onKnowButtonClicked = () => {
  dialogShowVisible.value = false
  emit('update:modelValue', dialogShowVisible.value)
}

const onNoTipsClicked = () => {
  localStorage.setItem(`${props.system}${props.version}noshow`, "true")
  dialogShowVisible.value = false
  emit('update:modelValue', dialogShowVisible.value)
}

watch(() => props.modelValue, newVal => {
  let show = localStorage.getItem(`${props.system}${props.version}noshow`)
  if (show && show === "true") {
    dialogShowVisible.value = false
  } else {
    dialogShowVisible.value = newVal
  }
}, {
  immediate: true
})

</script>
<style lang="less">
.notice {
  .el-dialog__header {
    background-color: rgb(112, 170, 64);
    padding-top: 20px;
    padding-bottom: 20px;
    // font-weight: bold;
    font-size: 40px;
    text-align: center;

    .el-dialog__headerbtn {
      top: 40px;
    }

    .el-dialog__title {
      color: #fff;
    }

    .el-icon-close:before {
      color: #fff;
      font-size: 20px;
    }
  }

  .el-dialog__body {
    border: 1px solid;
    border-top: none;
  }
}
</style>
<style lang='less' scoped>
.notice-container {
  line-height: 1.5em;

  .notice-title {
    margin-bottom: 1.5em;
  }

  .notice-top-tips {
    margin-bottom: 1em;
    text-indent: 2em;
    word-wrap: break-word;
    word-break: break-all;

    .time {
      font-weight: bold;
    }
  }

  .notice-content {
    padding: 1em 6px;
    background-color: #f5f7fa;
    border-radius: 6px;
    margin-bottom: 1em;
  }

  .notice-bottom-tips {
    text-indent: 2em;
  }
}

.notice-bottom {
  margin-top: 50px;
  text-align: center;

  .no-tips {
    cursor: pointer;
    color: #bbb;
  }
}
</style>