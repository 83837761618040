const state = {
  skipSetChannelSignal: false,
  currentDashbordInfo: {},
  deviceDetailsCurrentDeviceInfo: {},
  tenantCustomizationInfo: {},
  userInfo: {},
  registerInputUserInfo: {
    avatar: '',
    mobile: '',
    mobile_verification_code: '',
    password: '',
    name: '',
    namespace: '',
    description: '',
    organization_name: '',
    industry: '',
    address: '',
    country: '',
    province: '',
    city: '',
    username: '',
    email: undefined,
    email_verification_code: undefined,
    department: '',
    gender: '',
    job: '',
    tenant_type: 0
  },
  getCapacity: 0,
  channelsInfo: {},
  channelListChanged: [],
  headerMenuActiveIndex: '',
  tenantPermissionInfo: {},
  ruleCapacity: 0,
  ruleCapacityValue: 20,
  setFileDataTriggerRuleImport: 0,
  setFileDataTriggerRuleImportValue: [],
  userPackage: {
    content: {
      info_model: 50,
      things_num: 2,
      can_things_num: null,
      single_data_export: 0,
      maximum_total_number_documents_stored: 0,
      database_storage_space: 2,
      device_file_traffic_management: 0,
      alert_sms: 1000,
      alert_tps: 0,
      sub_user_num: 100,
      label_num: 100,
      maximum_data_retention_days: 180,
      number_simultaneous_connections: 0,
      up_down_stream_connections: 0,
      edge_computing: 10,
      push_rule: 50,
      push_tps: 0,
      alert_rule: 50,
      hmi: 10,
      data_analysis: 10,
      twins_rule_on: null
    }
  },
  globalPermissionInfo: {},
  echartsListChange: false,
  ruleListChange: false,
  devDetailLoading: false,
  refreshDevList: false,
  detailCurrentPart: '',
  ecuListChange: false,
  announcementShow: false
}
export default state
