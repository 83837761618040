/**
 * DBC数据 dbcData
 */
export default {
  realTimeCurve: {
    title: '实时曲线',
    downloadSuccess: '下载成功'
  },
  historyCurve: {
    title: '历史曲线'
  },
  historyData: {
    title: '历史数据',
    searchChannelTip: '请先选择CAN通道，再进行操作'
  },
  dataPayback: {
    title: '数据回放',
    left: '左移',
    right: '右移',
    mostRight: '移动到最右',
    mostLeft: '移动到最左',
    oneOnce: '1条/次',
    tenOnce: '10条/次',
    fiftyOnce: '50条/次',
    hundredOnce: '100条/次',
    noDataPayback: '该时间段内没有可以回放的数据',
    paybackFinish: '该时间段内所有数据回放结束',
    paybackFail: '数据回放失败'
  },
  highLevelFunction: {
    title: '高阶功能',
    attention: '注意事项：',
    tip1: '高阶功能用于开启、暂停和解除DBC解析：',
    tip2: '1. 开启：开启会持续解析DBC数据；',
    tip3: '2. 暂停：暂停DBC解析功能，停止占用云端容量，适用于后期会再次使用的场景；',
    tip4: '3. 解除：解除通道DBC解析功能，将会清空相关看板和数据，适用于长期不使用的场景。',
    unbind: '解除',
    pauseTip: '暂停，将不再解析并存储通道{channel}的DBC数据，是否继续？',
    unbindTip: '解除通道DBC文件会对相应模块产生如下影响：',
    sureCancel: '确定要解除吗？',
    dbcUnbindSuccess: 'DBC解绑成功',
    dbcUnbindFail: 'DBC解绑失败',
    switchSuccess: '切换成功',
    switchFail: '切换失败'
  },
  selectCanChannel: '请选择CAN通道',
  signalCycle: '信号周期',
  signalCurve: '信号曲线',
  thousand: '千',
  million: '百万',
  billion: '十亿',
  multipleY: '多Y轴',
  singleY: '单Y轴',
  regionSelection: '区域选定',
  retreat: '后退',
  advance: '前进',
  dbcFile: 'DBC文件',
  selectDBCFileTip: '请选择DBC文件',
  addDBCFile: '添加DBC文件',
  protocolType: '协议类型',
  selectFile: '选择文件',
  uploadAndSelect: '上传并选定',
  curveName: '曲线名称',
  inputCurveNameTip: '请输入曲线名称',
  dataItem: '数据项',
  curveNameTip: '请输入2-32位，包含中文、字母、数字和下划线',
  dbcFileNotNullTip: 'DBC文件不能为空',
  curveNameNotNullTip: '曲线名称不能为空',
  dbcFileUploadSuccess: 'DBC文件上传成功',
  dataItemNotNull: '数据项不能为空',
  curveOverTip: '单个曲线最多只能选中十个数据项',
  selected: '已选',
  message: '消息',
  description: '描述',
  messageId: '消息ID',
  signal: '信号',
  type: '类型',
  addCurve: '添加曲线',
  editCurve: '编辑曲线',
  deleteCurve: '删除曲线',
  deleteTip: '确定要删除曲线{channel}吗？',
  deletePannel: '删除看板成功',
  changeDBCFileTip1: '更换通道DBC文件会对相应模块产生如下影响：',
  changeDBCFileTip2: '1、清除曲线分析中与通道{channel}相关的看板',
  changeDBCFileTip3: '2、清除触发规则中对应通道{channel}的DBC触发规则',
  changeDBCFileTip4: '确定是否更换DBC文件并编辑看板吗？',
  signalCycleOverTip: '信号周期只支持十万条数据进行抽样，现展示{time1} - {time2}的数据',
  getSignalCycleFail: '获取信号周期失败',
  getHistoryCurveFail: '获取历史曲线失败',
  startTimeYoungerEndTime: '开始时间必须小于结束时间',
  noDataDownload: '时间范围内图表暂无数据，无法下载文件',
  downloadSuccess: '下载成功',
  createChannelModel: '创建通道模型失败',
  clearChannelContent: '清空该通道下的图表和触发规则失败',
  addCurveFail: '新增曲线失败',
  addPannel: '添加看板成功',
  addPannelFail: '添加看板曲线失败',
  duplicationNameTip: '已存在相同名称的曲线，请求修改曲线名称后重新添加',
  editPannel: '编辑看板成功',
  editPannelFail: '编辑看板曲线失败',
  model: '配置模式',
  strategy: 'DBC策略',
  selectStrategicTip: '请选择信号策略',
  selectDataPointsTip: '请选择数据点',
  changeModelTip1: '更换通道配置模式会对相应模块产生如下影响：',
  changeModelTip2: '确定是否更换配置模式并编辑看板吗？',
  changeStrategicTip1: '更换通道DBC策略会对相应模块产生如下影响：',
  changeStrategicTip2: '确定是否更换DBC策略并编辑看板吗？'
}
