<template>
  <div>
    <div :class="['z-forget-password', $i18n.locale]">
      <div class="z-forget-password-logo">
        <!-- <img :src="activePlatformInfo.loginLogo" /> -->
        <span class="z-forget-password-logo-text">{{ activePlatformInfo.projectName }}</span>
      </div>
      <div class="z-forget-password-form">
        <span class="z-forget-password-explain">{{ t('lib.CommonValidTipOrText.forgotPwd') }}</span>
        <el-form label-width="80px" ref="forgetPasswordFormRef" :model="forgetPasswordForm" label-position="left" :rules="rules">
          <el-form-item label-width="100px" prop="source">
            <template class="z-forget-password-form-text" #label>{{ t('lib.ZPersonalInformation.mobile') }}</template>
            <ZAreaMobileInputCtrl v-model="forgetPasswordForm.source" :lang="$i18n.locale"> </ZAreaMobileInputCtrl>
          </el-form-item>
          <el-form-item label-width="100px">
            <template class="z-forget-password-form-text" #label>
              <span class="required-asterisk">*</span>{{ t('lib.CommonValidTipOrText.verificationCode') }}
            </template>
            <div class="z-forget-password-form-code">
              <ZSixDigitPasswordCtrl v-model="forgetPasswordForm.verification_code" backgroundColor="#f2f2f2"> </ZSixDigitPasswordCtrl>
              <ZCountDownCtrl v-model:isSendCode="isSendCode" @getVerificationCodeClicked="getVerificationCodeClicked"></ZCountDownCtrl>
            </div>
          </el-form-item>
          <!-- 用来处理账号填充，不然账户名会填充至验证码框 -->
          <el-input style="width: 0; height: 0; overflow: hidden; position: absolute; left: 0" type="text"> </el-input>
          <el-form-item label-width="100px" prop="new_password" class="long-tips">
            <template #label class="z-forget-password-form-text">{{ t('lib.CommonValidTipOrText.password') }}</template>
            <!-- <el-input class="z-forget-password-form-input" type="password" v-model="forgetPasswordForm.new_password" placeholder="请输入新密码" :readonly="inputReadonly" @focus="inputFocus" clearable show-password></el-input> -->
            <ZInputPasswordArea
              class="z-forget-password-form-input"
              type="password"
              v-model="forgetPasswordForm.new_password"
              :placeholder="t('lib.CommonValidTipOrText.pwdEmptyTip')"
              :readonly="inputReadonly"
              @focus="inputFocus"
              clearable
              show-password
            ></ZInputPasswordArea>
          </el-form-item>
          <el-form-item label-width="100px" prop="confirmPassword" class="long-tips">
            <template #label class="z-forget-password-form-text">{{ t('lib.CommonValidTipOrText.confirmPwd') }}</template>
            <!-- <el-input class="z-forget-password-form-input" type="password" v-model="forgetPasswordForm.confirmPassword" placeholder="请再次输入新密码" :readonly="inputReadonly" @focus="inputFocus" clearable show-password></el-input> -->
            <ZInputPasswordArea
              class="z-forget-password-form-input"
              type="password"
              v-model="forgetPasswordForm.confirmPassword"
              :placeholder="t('lib.CommonValidTipOrText.confirmPwdEmptyTip')"
              :readonly="inputReadonly"
              @focus="inputFocus"
              clearable
              show-password
            ></ZInputPasswordArea>
          </el-form-item>
        </el-form>
        <div class="z-forget-password-form-reset">
          <el-button class="z-forget-password-form-reset-button" type="primary" icon="el-icon-refresh" @click="resetPassword">{{
            t('lib.CommonValidTipOrText.resetPwd')
          }}</el-button>
          <p>
            <span class="z-forget-password-form-reset-tips">{{ t('lib.login.clickThisBack') }}</span>
            <el-button link type="text" @click="returnToLogin">{{ t('lib.CommonValidTipOrText.login') }}</el-button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
// import ZAreaMobileInputCtrl from '../../ctrl/ZAreaMobileInputCtrl.vue'
// import ZSixDigitPasswordCtrl from '../../ctrl/ZSixDigitPasswordCtrl.vue'
// import ZCountDownCtrl from '../../ctrl/ZCountDownCtrl.vue'
// import ZInputPasswordArea from '../ZCommonArea/ZInputPasswordArea.vue'
import { defineComponent, reactive, ref, watch } from 'vue'
// import { useLocale } from '../../hooks/useLocale'
import { ElMessage } from 'element-plus'
import { projectConfig } from '@/assets/ts/project_config'
import { ZValidateDataTool as ZValidateTool } from '@zws/tools'
import { useStore } from 'vuex'
import defaultLoginLogo from '@/assets/images/login/login_logo.png'
import { getVerificationCodes, updateResetPassword } from '@zws/axios-fetch'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    // ZAreaMobileInputCtrl,
    // ZSixDigitPasswordCtrl,
    // ZCountDownCtrl,
    // ZInputPasswordArea
  },
  setup(porps, context) {
    const store = useStore()
    const { t } = useI18n()
    // let { t } = useLocale()

    let forgetPasswordForm: any = reactive({
      source: '',
      verification_code: '',
      new_password: '',
      confirmPassword: '',
      type: 1,
      user_type: -1
    })

    let inputReadonly: any = ref(true)
    let forgetPasswordFormRef: any = ref(null)
    let isSendCode: any = ref(false)
    let validateSource = (rule: any, value: any, callback: Function) => {
      if (!ZValidateTool.phoneNumberValidator(value)) {
        return callback(new Error(t('lib.login.error.004') || '请输入正确位数的手机号码，只能是数字'))
      } else callback()
    }
    let validateNewPassword = (rule: any, value: any, callback: Function) => {
      if (!ZValidateTool.passwordValidator(value)) {
        return callback(
          new Error(t('lib.login.error.010') || '密码必须包含8-16位大小写字母、数字和指定特殊符号(!@#$%^&*0)，且至少包含2种不同类型的字符')
        )
      } else callback()
    }
    let validateConfirmPassword = (rule: any, value: any, callback: Function) => {
      if (!ZValidateTool.passwordValidator(value)) {
        return callback(
          new Error(t('lib.login.error.010') || '密码必须包含8-16位大小写字母、数字和指定特殊符号(!@#$%^&*0)，且至少包含2种不同类型的字符')
        )
      } else if (forgetPasswordForm.confirmPassword !== forgetPasswordForm.new_password) {
        return callback(new Error(t('lib.login.error.011') || '两次输入的密码不一致'))
      } else callback()
    }
    const rules = {
      source: [
        { required: true, message: t('lib.login.error.002') || '手机号码不能为空', trigger: 'change' },
        { validator: validateSource, trigger: 'change' }
      ],
      new_password: [
        { required: true, message: t('lib.login.error.006') || '密码不能为空', trigger: 'change' },
        { validator: validateNewPassword, trigger: 'change' }
      ],
      confirmPassword: [
        { required: true, message: t('lib.login.error.012') || '确认密码不能为空', trigger: 'change' },
        { validator: validateConfirmPassword, trigger: 'change' }
      ]
    }
    let activePlatformInfo: any = reactive({
      loginLogo: defaultLoginLogo,
      projectName: projectConfig.projectName
    })
    initPlatformInfo()

    function getVerificationCodeClicked() {
      forgetPasswordFormRef.value.validateField(['source'], (error: any) => {
        if (!error) {
          getVerificationCodes({
            type: 1,
            destination: forgetPasswordForm.source,
            purpose: 'modify'
          })
            .then((res: any) => {
              isSendCode.value = true
              ElMessage.success(t('lib.register.tips.getCodeSuccess') || '获取手机验证码成功')
            })
            .catch((error: any) => {
              ElMessage.error(error?.data?.error)
            })
        }
      })
    }
    function returnToLogin() {
      context.emit('returnToLogin')
    }
    function resetPassword() {
      forgetPasswordFormRef.value.validate((validate: any) => {
        if (validate) {
          if (forgetPasswordForm.verification_code === '') {
            ElMessage.error(t('lib.login.error.014') || '请输入6位验证码')
            return
          }

          updateResetPassword(forgetPasswordForm)
            .then((res: any) => {
              ElMessage.success(t('lib.CommonValidTipOrText.resetPwdTip') || '重置密码成功')
              context.emit('returnToLogin')
            })
            .catch((error: any) => {
              error?.errorMsg && ElMessage.error(error?.errorMsg)
              error?.data?.error && ElMessage.error(error?.data?.error)
              // ElMessage.error(error.err.data)
              // if (error.err.data.indexOf('cannot be found')) {
              //     ElMessage.error('当前系统不存在该手机号码')
              // } else if (error.err.data === 'invalid SMS verification code') {
              //     ElMessage.error('验证码错误，请重新输入')
              // }
            })
        }
      })
    }
    function inputFocus() {
      inputReadonly.value = false
    }

    function initPlatformInfo() {
      setPlatformInfo()
    }
    function setPlatformInfo() {
      let globalPermissionInfo = store.getters.globalPermissionInfo
      if (!globalPermissionInfo) return
      activePlatformInfo.loginLogo = globalPermissionInfo.loginLogo ? globalPermissionInfo.loginLogo : defaultLoginLogo
      activePlatformInfo.projectName = globalPermissionInfo.cloudname ? globalPermissionInfo.cloudname : projectConfig.projectName
    }

    watch(
      () => store.getters.globalPermissionInfo,
      () => {
        setPlatformInfo()
      }
    )

    return {
      t,
      projectConfig,
      forgetPasswordForm,
      forgetPasswordFormRef,
      isSendCode,
      rules,
      inputReadonly,
      activePlatformInfo,
      inputFocus,
      getVerificationCodeClicked,
      returnToLogin,
      resetPassword
    }
  }
})
</script>
<style lang="less" scoped>
.z-forget-password.en {
  :deep(.el-form-item.is-error) {
    margin-bottom: 30px;
  }
}

.z-forget-password {
  width: 550px;
  // height: 536px;
  height: 480px;
  background-color: #f2f2f2;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-268px, -275px);
}

.z-forget-password-logo {
  width: 550px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-left: 22px;
}

.z-forget-password-logo-text {
  font-size: 22px;
  margin-left: 5px;
  font-weight: bold;
  color: #333333;
}

.z-forget-password-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #f2f2f2;
}

.z-forget-password-explain {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 21px;
  color: #333333;
}

.z-forget-password-form-text {
  font-size: 14px;
  color: #999999;
}

.label-optional-text {
  padding-left: 10px;
  font-size: 14px;
  color: #999999;
}

.z-forget-password-form-input {
  width: 305px;
}

.z-forget-password-form-code {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.z-forget-password-form-code-tips {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.required-asterisk {
  padding-right: 4px;
  color: #f56c6c;
}

.dropdown-wrapper {
  padding-right: 12px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #999999;
}

.el-dropdown-asterisk {
  color: #f56c6c;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.z-forget-password-form-reset {
  display: flex;
  flex-direction: row;
  // margin-top: 30px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.z-forget-password-form-reset-button {
  height: 44px;
  margin-left: 140px;
}

.z-forget-password-form-reset-tips {
  font-size: 14px;
  margin-left: 20px;
  margin-right: 5px;
  color: #999999;
}
.long-tips {
  margin-bottom: 30px;
}
</style>
<style lang="less">
body .form-item-username .el-form-item__error {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 24%;
}
</style>
