/**
 * 正则校验类工具
 * 分配后缀命名空间Validator
 */
class ZWSValidateTool {
  /**
   * 手机号校验
   * @param value 手机
   * @param analog 模拟手机号格式
   * @returns
   */
  static phoneNumberValidator(value: string, analog: string = '123123') {
    // +86
    let chinaValue = value.substring(0, 3)
    if (chinaValue === '+86') {
      let mobile = value.substring(3, value.length)
      // 模拟手机号格式 123123 + 任意5位数
      // let val = mobile.substring(0, 6);
      // if (val === '123123') {
      if (mobile.includes(analog)) {
        const reg = new RegExp(`^${analog}\\d{${11 - analog.length}}$`)
        return reg.test(mobile)
      } else {
        return /^1[3456789]\d{9}$/.test(mobile)
      }
    } else {
      // 国际手机号码，校验长度4-20和是否全部为数字
      if (value.length < 4 || value.length > 20) {
        return false
      } else {
        let otherMobile = value.replace('+', '')
        return /^[0-9]*$/.test(otherMobile)
      }
    }
  }
  /**
   * 邮箱校验
   * @param {String} value
   */
  static emailValidator(value: any) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  }
  /* 半角字符 */
  static notChineseJapanOtherSpacesValidator(value: any) {
    const reg = /[^\u0000-\u00ff]/g
    if (reg.test(value)) {
      return false
    }
    return !/\s/g.test(value)
  }
  /**
   * 不能包含中文和空格 不处理 '文字の取得を行う
   * @param value
   */
  static notChineseSpacesValidator(value: any) {
    const reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g
    if (reg.test(value)) {
      return false
    }
    return !/\s/g.test(value)
  }

  /**
   * 不能以数字开头校验
   * @param value
   */
  static notNumberHeadValidator(value: any) {
    return !/^\d/.test(value)
  }

  /**
   * 仅以字母开头校验
   * @param value
   */
  static onlyLetterHeadValidator(value: any) {
    return /^[a-zA-Z]/.test(value)
  }

  /**
   * 自定义长度名称校验（默认2-20位，只可以包含字母数字下划线）
   * 
   * hyphen: true 扩展到允许中横线
   * @param {String} value
   */
  static nameValidator(value: any, minlength = 2, maxlength = 20, modification?: any) {
    let reg = new RegExp(`^\\w{${minlength},${maxlength}}$`)
    if (modification && modification.hyphen) {
      reg = new RegExp(`^[\\w\-]{${minlength},${maxlength}}$`)
    }
    return reg.test(value)
  }

  /**
   * 自定义长度名称校验（默认2-20位，只可以包含字母数字下划线和点）
   * @param {String} value
   */
  static nameWithPointValidator(value: any, minlength = 2, maxlength = 20) {
    let reg = new RegExp(`^[_a-zA-Z0-9.]{${minlength},${maxlength}}$`)
    return reg.test(value)
  }

  /**
   * 自定义长度名称校验（默认2-20位，只可以包含字母数字下划线,子用户名需包含@）
   * @param {String} value
   */
  static subNameValidator(value: any, minlength = 2, maxlength = 20) {
    let reg = new RegExp(`^\\w{${minlength},${maxlength}}(@\\w{${minlength},${maxlength}})?$`)
    return reg.test(value)
  }

  /**
   * 自定义长度名称校验（默认2-20位，包含中文、字母、数字和下划线）
   * @param {String} value
   */
  static chineseNameValidator(value: any, minlength = 2, maxlength = 20) {
    // \u4e00-\u9fa5  \u2E80-\uFE4F
    let reg = new RegExp(`^[\u4e00-\u9fa5_a-zA-Z0-9]{${minlength},${maxlength}}$`)
    return reg.test(value)
  }

  /**
   * 自定义长度名称校验（默认2-20位，包含中文、字母、-、数字和下划线）
   * @param {String} value
   */
  static chineseNameAcrossValidator(value: any, minlength = 2, maxlength = 20) {
    // \u4e00-\u9fa5  \u2E80-\uFE4F
    let reg = new RegExp(`^[\u4e00-\u9fa5_a-zA-Z0-9-]{${minlength},${maxlength}}$`)
    return reg.test(value)
  }

  /**
   * 自定义设备id校验（默认2-20位，包含字母、-、数字和下划线）
   * @param {String} value
   */
  static deviceIdValidator(value: any, minlength = 2, maxlength = 20) {
    // \u4e00-\u9fa5  \u2E80-\uFE4F
    let reg = new RegExp(`^[_a-zA-Z0-9-]{${minlength},${maxlength}}$`)
    return reg.test(value)
  }

  /**
   * 判断 哈希 十六进制
   * */
  static haxValidator(value: any, minlength: any = 2, maxlength: any = 32, caseLetters: string = 'none') {
    let reg: any
    if (caseLetters === 'none') {
      reg = new RegExp(`^[a-fA-F0-9]{${minlength},${maxlength}}$`)
    } else if (caseLetters === 'lowercase') {
      reg = new RegExp(`^[a-f0-9]{${minlength},${maxlength}}$`)
    } else if (caseLetters === 'capital') {
      reg = new RegExp(`^[A-F0-9]{${minlength},${maxlength}}$`)
    }
    return reg.test(value)
  }
  /**
   * 密码格式校验（默认8-16位，非中文字符和空格）
   * @param {*} value
   */
  static passwordValidator(value: any, minlength = 8, maxlength = 16) {
    let reg = new RegExp(`^[^\u4e00-\u9fa5 ]{${minlength},${maxlength}}$`)
    return reg.test(value)
  }

  /**
   * 校验设备的密钥格式,只能输入小写字母，短横线，数字
   */
  static devsecret(val: any) {
    return /^([a-z\d]{8}-[a-z\d]{4}-[a-z\d]{4}-[a-z\d]{4}-[a-z\d]{12})$/.test(val)
  }

  /**
   * 数字长度限制（默认6位，致远电子的验证码为6位的数字）
   * @param {String} value
   */
  static numbermaxlengthValidator(value: any, minlength = 6, maxlength = 6) {
    let reg = new RegExp(`^\\d{${minlength},${maxlength}}$`)
    return reg.test(value)
  }

  /**
   * 数值范围限制(默认不等于)
   * @param value
   * @param start
   * @param end
   * @param equal 是否等于
   * @returns
   */
  static numberLimit(value: any, min = 0, max = 100, equal = false) {
    if (isNaN(value)) {
      return false
    }
    const valueNumber = parseFloat(value)
    if (equal) return valueNumber >= min && valueNumber <= max
    else return valueNumber > min && valueNumber < max
  }

  /**
   * 任意字符串，只是限制长度，用于用户信息相关的，比如个性签名、公司、地址等等。
   * @param {String} value
   */
  static anyStringValidator(value: any, minlength = 2, maxlength = 64) {
    if (isNaN(minlength) || isNaN(maxlength)) {
      return value.length >= 2 && value.length <= 10
    } else return value.length >= minlength && value.length <= maxlength
  }

  /**
   * 固件版本号（1.1.1）
   * @param value
   * @returns
   */
  static firmwareVersionCheck(value: any) {
    return /^\d{1,2}\.\d{1,2}\.\d{1,2}$/.test(value)
  }

  /**
   * 数值比较，第一个数大于（或大于等于）第二个数（校验失效时间必须在生效时间之后）
   * @param firstNumber
   * @param secondNumber
   * @param equal 是否等于
   * @returns
   */
  static numericComparisonValidator(firstNumber: any, secondNumber: any, equal = false) {
    if (isNaN(firstNumber) || isNaN(secondNumber)) {
      return false
    }
    if (equal) return parseFloat(firstNumber) >= parseFloat(secondNumber)
    else return parseFloat(firstNumber) > parseFloat(secondNumber)
  }

  /**
   * 校验小数点位数（默认校验两位小数，decimals传0校验是否整数）
   * @param value
   * @param decimals
   * @returns
   */
  static decimalCheck(value: number, decimals = 2) {
    if (isNaN(value) || isNaN(decimals)) {
      return false
    }
    if (decimals < 0) return false
    value = value - 0
    let valueString: string = value + ''
    let valueArray: string[] = valueString.split('.')

    if (valueArray.length <= 1) return true
    else if (valueArray.length === 2) {
      if (valueArray[1].length <= decimals) return true
      else return false
    } else return false
  }

  /**
   * 校验整数
   * @param value
   * @param type 1 => integer整数；2 => positiveInteger正整数；3 => 负整数； 4 => floatNumber浮点数
   * */
  static checkNumber(value: any, type: any = 'integer') {
    const regExpList: any = {
      'integer': `^-?\\d+$`,
      'positiveInteger': `^[1-9]\\d*$`,
      'negativeInteger': `^-[0-9]*[1-9][0-9]*$`,
      'floatNumber': `^(-?\\d+)(\\.\\d+)?$`
    };
    return new RegExp(regExpList[type]).test(value)
  }

  /**
   * 检查某个值是否为 长度>0 的数组
   * @param value
   * @returns boolean
   * */
  static checkArrayAndLength(value: any) {
    return value && Array.isArray(value) && value.length > 0
  }

  /**
   *
   * @param value 校验值
   * @param filter 进制值
   * @returns
   */
  static checkMacPhysic(value: any[], filter: string = '1FFFFFFF') {
    let _filter = filter.toLowerCase()
    let hexArr: any[] = []
    hexToNum(hexArr, value, true)
    let _str = hexArr.join(',')
    return _str < _filter
  }

  /*****************************************************/

  //   /**
  //    * 角色名校验，因为创建角色的逻辑关系，默认去掉下划线。
  //    * @param {String} value
  //    */
  //   static rolenameValidator(value) {
  //     return /^\w{2,32}$/.test(value)
  //   }

  //   /* ------------------- */

  //   /**
  //  * 使用方法举例
  //  * import validate from validate.js
  //  * validate.isPhoneNumber('19129321') // false
  //  * validate.isPhoneNumber('138001380000') // true
  //  */

  //   /**
  //    * 用户名格式校验（1-32位，只可以包含字母数字下划线,子用户名需包含@）
  //    * @param {*} value
  //    */
  //   static isLoginUsername(value) {
  //     return /^\w{2,20}(@\w{2,20})?$/.test(value)
  //   }
  //   /**
  //    * 密码格式校验（8-16位，并且不能有空格）
  //    * @param {*} value
  //    */
  //   static isPassword(value) {
  //     return /^([a-zA-Z]|\d|_){8,16}$/.test(value)
  //   }
  //   /**
  //    * 个性签名校验
  //    * @param {*} value
  //    */
  //   static isDesc(value) {
  //     return value.length >= 2 && value.length <= 64
  //   }
  //   /**
  //    * 邮箱格式校验码
  //    * @param {*} value
  //    */
  //   static isEmail(value) {
  //     // return /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/.test(value)
  //     // eslint-disable-next-line no-useless-escape
  //     return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
  //   }
  //   /**
  //    * 十六进制校验
  //    * @param {*} value
  //    */
  //   static isHex(value) {
  //     return /^[A-Fa-f0-9]+$/.test(value)
  //   }
  //   /**
  //    * 设备名格式校验（长度1-64，只包含字母、数字、下划线、中横线）
  //    * 注：这里设备端中中文占3个字节
  //    * @param {*} value
  //    */
  //   static isDevname(value) {
  //     // eslint-disable-next-line
  //     return /^[a-zA-Z0-9_\-]{1,31}$/.test(value)
  //   }
  //   /**
  //    * 昵称格式校验（长度1-32位，只包含汉字、字母、数字、下划线）
  //    * @param {*} value
  //    */
  //   static isNickname(value) {
  //     return /^[a-zA-Z0-9_\u4e00-\u9fa5]{2,32}$/.test(value)
  //   }
  /*
  * ip地址校验
  * @param {*} value
  */
  static isIpAddress(value: any) {
    if (value === '') {
      return false
    } else if (value.length > 63) {
      return false
    } else {
      var filter1 = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
      return filter1.test(
        value
      )

    }
  }
  //   /**
  //    * 子网掩码
  //    * @param {*} value
  //    */
  //   static isNetMask(value) {
  //     if (value === '') {
  //       return false
  //     } else if (value.length > 63) {
  //       return false
  //     } else {
  //       return /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])(\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])){3}$/.test(
  //         value
  //       )
  //     }
  //   }

  //   /**
  //    * DNS域名服务器
  //    * @param {*} value
  //    */
  //   static isDNS(value) {
  //     if (value === '') {
  //       return false
  //     } else if (value.length > 63) {
  //       return false
  //     } else {
  //       return /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/.test(
  //         value
  //       )
  //     }
  //   }
  //   /**
  //    * 搜索框输入内容，值允许汉字字母数字下划线
  //    * @param {*} value
  //    */
  //   static isNormalChar(value) {
  //     return /^[\w\d_\-\u4e00-\u9fa5]+$/.test(value)
  //   }/* */
  //   /**
  //    * 设备配置页面，网络连接配置，端口校验
  //    * @param {*} value
  //    */
  //   static isPort(value) {
  //     if (
  //       /^[0-9]*$/.test(value) &&
  //       parseInt(value) <= 65535 &&
  //       parseInt(value) > 0
  //     ) {
  //       return true
  //     } else if (value === '') {
  //       return false
  //     } else {
  //       return false
  //     }
  //   }
  //   /**
  //    * 设备配置页面，经度校验
  //    * @param {*} value
  //    */
  //   static maxlengthitude(value) {
  //     if (value === '0' || value === 0) {
  //       return true
  //     } else if (
  //       /^[+-]?\d+(.[0-9]{1,6})?$/.test(value) &&
  //       Math.ceil(Math.abs(value)) >= 0 &&
  //       Math.ceil(Math.abs(value)) <= 180
  //     ) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   }
  //   /**
  //    * 设备配置页面，纬度校验
  //    * @param {*} value
  //    */
  //   static latitude(value) {
  //     if (value === '0' || value === 0) {
  //       return true
  //     } else if (
  //       /^[+-]?\d+(.[0-9]{1,6})?$/.test(value) &&
  //       Math.ceil(Math.abs(value)) >= 0 &&
  //       Math.ceil(Math.abs(value)) <= 90
  //     ) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   }

  //   static isHexFor8AndSpace(value) {
  //     return /^[a-fA-F0-9]{2}( [a-fA-F0-9]{2})?( [a-fA-F0-9]{2})?( [a-fA-F0-9]{2})?( [a-fA-F0-9]{2})?( [a-fA-F0-9]{2})?( [a-fA-F0-9]{2})?( [a-fA-F0-9]{2})?$/.test(value)
  //   }
  //   static devtypeName(value) {
  //     return /^[A-Za-z0-9_]{1,}$/.test(value)
  //   }
  //   static devtypeAttributeName(value) {
  //     return /^[A-Za-z0-9_]{1,}$/.test(value) && !(/^\d{1,}$/.test(value))
  //   }
  //   /**
  //    * 校验合法输入，大小写、数字、下划线
  //    */
  //   static normalInput(val) {
  //     return /^[a-zA-Z0-9_]{2,}$/.test(val)
  //   }

  //   static allStringValidator(value) {
  //     return value.length <= 64
  //   }

  //   /**
  //    * 只能输入数字、字母、下划线、中横线
  //    * @param {String} value
  //    */
  //   static devidValidator(value) {
  //     return /^[\w\d-_]{2,32}$/.test(value)
  //   }
}

function hexToNum(arrOrigin: any[], arr: any[], isInt?: any) {
  for (let i in arr) {
    if (isInt) {
      arrOrigin[i] = parseInt('' + new Number(('0x' + arr[i]) as any))
    } else {
      arrOrigin[i] = '' + parseInt('' + new Number('0x' + arr[i]))
    }
  }
}
export default ZWSValidateTool
