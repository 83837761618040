<template>
    <div class="table-pagination-area-wrapper">

        <div :class="[isShowTablePagination ? 'pagination-wrapper' : '']">
            <div class="pagination-content">
                <div class="pagination">
                    <el-pagination :total="tablePaging.total" :small="tablePaging.small"
                        :current-page="tablePaging.currentPage" :page-sizes="tablePaging.pageSizes"
                        :page-size="tablePaging.pageSize" :layout="tablePaging.layout" :pager-count="tablePaging.pagerCount"
                        @current-change="paginationCurrentChange" @size-change="paginationSizeChange" background>
                    </el-pagination>
                </div>
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, watch, PropType, nextTick, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { Monitoring } from '../../../sdk/ts/tool/ZDirectives/directives/monitoring'

export interface TablePagination {
    total: number
    pageSizes?: number[]
    layout?: string[]
    currentPage?: number
    pageSize?: number
}
export default defineComponent({
    props: {
        modelValue: {},
        // 表格表头

        // 是否支持分页，传入即开启，表格分页参数，参数有total、pageSizes、layout
        tablePagination: {
            type: Object,
            // default: {
            //     small: false,
            //     total: 0,
            //     currentPage: 1,
            //     pageSize: 10,
            //     pageSizes: [10, 20, 30, 40, 50],
            //     layout: 'prev,pager, next, jumper, total,sizes',
            // },
        },
        // 表格数据与原始数据分离，修改表格的数据不会影响原始数据。
        detach: {
            type: Boolean,
            default: false,
        },
        autoTablePagination: {
            type: Boolean,
            default: false
        },
        // 超过一万条隐藏页数
        exceedHide: {
            type: Boolean,
            default: true
        },
        displayPropArray: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    setup(prop: any, context) {
        let loading = ref<boolean>(false)
        let tableList: any = reactive([])
        let tablePaging: any = reactive({
            total: 0,
            currentPage: 1,
            pageSize: 10,
            pageSizes: [10, 20, 30, 40, 50],
            layout: 'prev, pager, next, total, sizes',
            small: false,
            pagerCount: 7,
        })
        let tablePagingDefaultParams: any = {
            total: 0,
            currentPage: 1,
            pageSize: 10,
            pageSizes: [10, 20, 30, 40, 50],
            layout: 'prev, pager, next, total, sizes',
            small: false,
            pagerCount: 7,
        }
        let isShowTablePagination: any = ref(true)
        let totaldata: any = []

        let paginationCurrentPage: any = ref(1)


        init()

        function init() {
            if (prop.tablePagination) {
                // 1. 如果传入tablePagination，便先加载默认的样式。
                // 2. 将传入参数，覆盖默认样式
                tablePaging = Object.assign(tablePaging, tablePagingDefaultParams)
                tablePaging = Object.assign(tablePaging, prop.tablePagination)
            }

            if (prop.modelValue) {
                // 1. 如果传入tablePagination，便先加载默认的样式。
                // 2. 将传入参数，覆盖默认样式
                tablePaging = Object.assign(tablePaging, tablePagingDefaultParams)
                tablePaging = Object.assign(tablePaging, prop.modelValue)
            }

            if (prop.modelValue === undefined) {
                isShowTablePagination.value = false
            }

            if (prop.autoTablePagination) {
                isShowTablePagination.value = true
            }

            context.emit('update:modelValue', tablePaging)

        }

        watch(
            () => prop.modelValue,
            (newValue: any, oldValue) => {
                if (newValue !== undefined) {
                    tablePaging.total = newValue.total
                }
                // 如果外部设置layout为空，意味着分页没有显示，将边框去掉
                if (newValue !== undefined && newValue.layout === '') {
                    isShowTablePagination.value = false
                }
            },
            {
                deep: true,
            }
        )


        function checkIsAutoTablePagination() {
            if (prop.autoTablePagination) {
                if (tableList.length > 0) {
                    tableList.splice(0, tableList.length)
                }
                let index = (tablePaging.currentPage - 1) * tablePaging.pageSize
                tableList.push(...totaldata.slice(index, index + tablePaging.pageSize))
            }
        }
        function handleSelectionChange(value: any) {
            context.emit('handleSelectionChange', value)
            context.emit('update:modelValue', tablePaging)
        }
        function tableButtonClicked(buttonIndex: number, buttonText: string, tableIndex: number, row: any) {
            context.emit('update:modelValue', tablePaging)
            context.emit('tableButtonClicked', {
                buttonIndex: buttonIndex,
                buttonText: buttonText,
                tableIndex: tableIndex,
                row: row,
            })
        }
        function paginationCurrentChange(value: number) {

            tablePaging.currentPage = value
            context.emit('update:modelValue', tablePaging)
            context.emit('paginationCurrentChange', tablePaging)
            paginationChange()
            checkIsAutoTablePagination()
        }
        function paginationSizeChange(value: number) {
            // 如果修改了条数，默认从第1页开始
            tablePaging.currentPage = 1
            tablePaging.pageSize = value
            context.emit('update:modelValue', tablePaging)
            context.emit('paginationSizeChange', tablePaging)
            paginationChange()
            checkIsAutoTablePagination()
        }
        function paginationChange() {
            context.emit('paginationChange', tablePaging)
        }




        const liShow = computed(() => prop.exceedHide && prop.modelValue?.total > 10000 ? 'none' : 'inline-block')

        return {
            loading,
            tablePaging,
            isShowTablePagination,
            paginationCurrentPage,
            handleSelectionChange,
            tableButtonClicked,
            paginationCurrentChange,
            paginationSizeChange,
            liShow,

        }
    },
})
</script>

<style lang="less" scoped>
.pagination-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    border-left: 1px solid #dadde0;
    border-right: 1px solid #dadde0;
    border-bottom: 1px solid #dadde0;

    .pagination-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 11px;
    }

    .pagination {
        margin-right: 12px;
        text-align: left;

        :deep(.el-pagination .btn-prev .el-icon) {
            display: inline-block;
        }

        :deep(.el-pagination .btn-next .el-icon) {
            display: inline-block;
        }

        :deep(.el-pagination.is-background .btn-prev) {
            background-color: #f0f2f5;
        }

        :deep(.el-pagination.is-background .btn-next) {
            background-color: #f0f2f5;
        }

        :deep(.el-pagination.is-background .el-pager li) {
            background-color: #f0f2f5;
        }

        :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
            background-color: var(--el-color-primary);
        }

        :deep(.el-pager li:last-child) {
            display: v-bind(liShow);
        }
    }
}

.data-real-time-container {
    max-height: 300px;
    min-width: 100px;
    overflow-y: auto;
}

.data-real-time-table-popover-save {
    text-align: center;
    margin-top: 12px;
}

.el-button--text.is-disabled {
    opacity: 0.56 !important;
}

// 按照致远UI设计的按钮风格，晚点再完善

// .el-button--danger.is-plain {
//     background-color: #fff;
//     border-color: #fde2e2;
// }
// .el-button--danger {
//     --el-button-font-color: #f75555;
//     --el-button-border-color: #f75555;
// }
// .el-button--danger.is-plain:hover {
//     color: #f56c6c;
//     border-color: #f75555;
//     background: #fff;
// }
// .el-button--primary.is-plain {
//     background-color: #fff;
// }

// .el-button--primary.is-plain:hover {
//     color: #80b33f;
//     border-color: #80b33f;
//     background: #fff;
// }
</style>
<style>
/* table设置全局样式 */
.el-table th {
    padding: 5.5px 0 !important;
    background-color: #f5f7fa !important;
    color: #8a9199;
    height: 40px !important;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #8a9199;
}

.el-table td {
    padding: 5.5px 0 !important;
    width: 144px;
    height: 14px;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #46494d;
}

.pagination-wrapper .pagination-content .pagination .el-pagination__total {
    margin-left: 15px !important;
}
</style>