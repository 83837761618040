<template>
    <div>
        <span
            ref="numberGrowRef"
            :data-value="value"
        >
            0
        </span>
    </div>
</template>
<script setup lang="ts">
    import { ref, reactive, computed, onMounted, watch } from "vue";

    interface Props {
        value : number
    }

    const props = withDefaults(defineProps<Props>(), {
        value: 0
    });

    const stepList = reactive({
        step1: 1,   // 0-100
        step5: 5,   // 100-1000
        step10: 10,  // 1000-3000
        step25: 25,  // 3000-5000
        step50: 50,  // 5000-10000
        step180: 180,  // 10000-30000
        step230: 230,  // 30000-50000
        step270: 350,  // 50000-70000
        step550: 1000,  // 70000+
        step10500: 2300,  // 150000+
    });

    const myStep = computed(() => {
        if (props.value < 100) return stepList.step1;
        if (props.value >= 100 && props.value < 1000) return stepList.step5;
        if (props.value >= 1000 && props.value < 3000) return stepList.step10;
        if (props.value >= 3000 && props.value < 5000) return stepList.step25;
        if (props.value >= 5000 && props.value < 10000) return stepList.step50;
        if (props.value >= 10000 && props.value < 30000) return stepList.step180;
        if (props.value >= 30000 && props.value < 50000) return stepList.step230;
        if (props.value >= 50000 && props.value < 70000) return stepList.step270;
        if (props.value >= 70000 && props.value < 100000) return stepList.step550;
        if (props.value >= 100000) return stepList.step10500;
    });
    const numberGrowRef = ref<any>(null);

    const numberGrow = (ele: any) => {
        // let isNaNFlag = isNaN(_this.value)
        // if (isNaNFlag === 'NaN'){
        //     return false
        // }
        // let step = parseInt((props.value * 10) / (props.time * 1000));

        let step: any = myStep.value,
            current: any = 0,
            start: any = 0;

        let timeInterval: any = setInterval(function () {
            start += step;

            if (start > props.value) {
                clearInterval(timeInterval);
                start = props.value;
                timeInterval = null
            }
            // if (current === start) return;

            current = start;
            ele.innerHTML = current?.toString().replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1,');
        }, 10)
    };

    onMounted(() => {
        numberGrow(numberGrowRef.value);
    });

    watch(() => props.value, () => {
        numberGrow(numberGrowRef.value)
    })

</script>
<style lang="less" scoped>
</style>
