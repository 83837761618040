
import ZWSBaseModule from '../httpBase/ZWSBaseModule'
import { Pagination } from './ZWSPagination'

/************* binding *****************************************************************************************/

export interface ECUBinding {
    channel_id: number
    dev_id: number
    ecu_id: number
    model_id: number
}

/************* dtc **************************************************************************************************/

export interface DTCCode {
    advices: string
    description: string
    dtc_code: string
    show_code: string
}

export interface DTCConfig {
    file_contents: DTCCode[],
    file_name: string
}

export declare type DTC = string
export declare type GetDTCListResp = string[]
export interface GetDTCConfigResp extends Array<DTCCode> { }

/************* ecu **************************************************************************************************/

export enum ECUFrameType {
    standard,
    extension
}

export interface ECUBase {
    dtc_file_name: string
    ecu_file_md5?: string
    ecu_name: string
    firmware_name: string
    frame_type: ECUFrameType
    function_address: string
    physical_address: string
    response_address: string
    unlock_file_name: string
    [propsName: string]: any
}

export interface ECU extends ECUBase {
    id: number
    tenant_id: number
    channel?: string
    [propsName: string]: any
}

export declare type AddECUResp = string;

export interface GetECUListResp {
    list: ECU[],
    pagination: Pagination
}

export type GetSingleECUFirmwareUrlResp = string

export enum ECUFileType {
    UpgradePatch,
    DeblockingPatch
}

/**
  * 设备管理
  */
class ZWSSingleCandtu extends ZWSBaseModule {
    /************* binding *****************************************************************************************/

    // 通道与ECU绑定
    static ecu_addSingleBinding({ tenantId, binding }: {
        tenantId: number,
        binding: ECUBinding
    }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/ecu/binding`,
            method: 'post',
            data: binding
        })
    }

    /************* dtc *****************************************************************************************/

    // 下载DTC文件模板
    static ecu_downloadDTCTemplate({ tenantId }: { tenantId: number }) {
        let token = localStorage.getItem('token')
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/dtc/template-file`,
            method: 'get',
            headers: {
                'Accept': 'application/xlsx', "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            responseType: 'blob'
        })
    }

    // 添加DTC故障码配置
    static ecu_addSingleDTCConfig({ tenantId, config }: { tenantId: number, config: DTCConfig }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/dtc/file`,
            method: 'post',
            data: config
        })
    }

    // 下载DTC文件
    static ecu_downloadDTC({ tenantId }: { tenantId: number }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/dtc/template-file`,
            method: 'get',
            headers: {
                'Accept': 'application/xls', "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token') as string)}`
            }
        })
    }

    // 添加DTC故障码配置
    static ecu_deleteSingleDTCConfig({ tenantId, file_name }: { tenantId: number, file_name: string }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/dtc/file`,
            method: 'delete',
            params: { file_name }
        })
    }

    // 添加DTC故障码配置
    static ecu_updateSingleDTCConfig({ tenantId, config }: { tenantId: number, config: DTCConfig }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/dtc/file`,
            method: 'put',
            data: config
        })
    }

    // 获取DTC故障码配置
    static ecu_getSingleDTCConfig({ tenantId, file_name }: { tenantId: number, file_name: string }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/dtc/file`,
            method: 'get',
            params: { file_name }
        })
    }

    // 获取DTC故障码文件列表
    static ecu_getDTCList({ tenantId }: { tenantId: number }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/dtc/file-list`,
            method: 'get',
        })
    }

    /************* ecu *****************************************************************************************/

    // 添加ECU
    static ecu_addSingleECU({ tenantId, ecu }: {
        tenantId: number,
        ecu: ECU
    }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/ecu/configuration`,
            method: 'post',
            data: ecu
        })
    }

    // 更新ECU
    static ecu_updateSingleECU({ tenantId, ecu }: {
        tenantId: number,
        ecu: ECU
    }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/ecu/configuration`,
            method: 'put',
            data: ecu
        })
    }

    // 删除ECU
    static ecu_deleteSingleECU({ tenantId, id }: {
        tenantId: number,
        id: number
    }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/ecu/configuration`,
            method: 'delete',
            params: { id }
        })
    }

    // 获取ECU列表
    static ecu_getECUList({ tenantId, current_page, page_size }: {
        tenantId: number,
        current_page: number,
        page_size: number
    }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/ecu/configuration-list`,
            method: 'get',
            params: { current_page, page_size }
        })
    }

    // 获取ECU升级固件URL
    static ecu_getSingleECUFirmwareUrl({ tenantId, channel_id, dev_id, file_type, model_id }: {
        tenantId: number,
        channel_id: number,
        dev_id: number,
        file_type: ECUFileType,
        model_id: number
    }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/ecu/file`,
            method: 'get',
            params: { channel_id, dev_id, file_type, model_id }
        })
    }

    // 上传ECU固件升级文件
    static ecu_uploadSingleECUUpgradePatch({ tenantId, ecu_id, file, file_name, file_type, md5 }: {
        tenantId: number,
        ecu_id: number,
        file: any,
        file_name: string,
        file_type: ECUFileType,
        md5: string
    }) {
        let formData = new FormData()

        formData.append('ecu_id', `${ecu_id}`)
        formData.append('file', file, file_name ? file_name : file.name)
        formData.append('file_type', `${file_type}`)
        formData.append('md5', `${md5}`)

        return this.fetch({
            url: `/single-candtu/tenants/${tenantId}/ecu/file`,
            method: 'post',
            data: formData
        })
    }

    /************* things **************************************************************************************************/
    /**
      * 添加设备
      * @param {*} param
      * @returns 
      */
    static things_addThings({ tenant_id, api_base_things_request }: { tenant_id: number, api_base_things_request: any }) {
        return this.fetch({
            url: `/single-candtu/tenants/${tenant_id}/things`,
            method: 'post',
            data: api_base_things_request
        })
    }
}

export default ZWSSingleCandtu
