/**
 * DBC数据 dbcData
 */
export default {
  realTimeCurve: {
    title: 'Real-time Curve',
    downloadSuccess: 'Download successfully'
  },
  historyCurve: {
    title: 'Historical Curve'
  },
  historyData: {
    title: 'Historical Data',
    searchChannelTip: 'Select a CAN channel and then perform operations'
  },
  dataPayback: {
    title: 'Data Playback',
    left: 'left',
    right: 'right',
    mostRight: 'Move to the far right',
    mostLeft: 'Move to the far left',
    oneOnce: '1 pieces per time',
    tenOnce: '10 pieces per time',
    fiftyOnce: '50 pieces per time',
    hundredOnce: '100 pieces per time',
    noDataPayback: 'No data can be played back in this period',
    paybackFinish: 'All data playback stops within this period',
    paybackFail: 'Data playback failure'
  },
  highLevelFunction: {
    title: 'High Level Function',
    attention: 'attention:',
    tip1: 'Higher-level functions are used to enable, pause, and disable DBC parsing:',
    tip2: '1. Enable: If this function is enabled, DBC data will be parsed continuously.',
    tip3: '2. Pause: Pause the DBC resolution function to stop occupying the cloud capacity, which is suitable for scenarios that will be used again in the future.',
    tip4: '3. Disable: Disable the channel DBC resolution function to clear Kanban and data. This function applies to scenarios that are not used for a long time. ',
    unbind: 'Unbind',
    pauseTip: 'Paused, will not parse and store the DBC data of the {channel}. Do you want to continue? ',
    unbindTip: 'Removing the channel DBC file has the following effects on the corresponding modules:',
    sureCancel: 'Are you sure you want to disarm?',
    dbcUnbindSuccess: 'The DBC is unbound successfully',
    dbcUnbindFail: 'The DBC fails to be unbound',
    switchSuccess: 'Switchover successful',
    switchFail: 'Switchover failure'
  },
  selectCanChannel: 'Please select the CAN channel',
  signalCycle: 'signal cycle',
  signalCurve: 'signal curve',
  thousand: 'thousand',
  million: 'million',
  billion: 'billion',
  multipleY: 'Multiple Y',
  singleY: 'Single Y',
  regionSelection: 'Region selection',
  retreat: 'retreat',
  advance: 'advance',
  dbcFile: 'DBC File',
  selectDBCFileTip: 'Select the DBC file',
  addDBCFile: 'Add a DBC file ',
  protocolType: 'protocol type',
  selectFile: 'Select file',
  uploadAndSelect: 'Upload and select',
  curveName: 'Curve Name',
  inputCurveNameTip: 'Please enter a curve name',
  dataItem: 'Data Item',
  curveNameTip: 'Please enter 2-32 characters, including Chinese characters, letters, numbers, and underscores',
  dbcFileNotNullTip: 'The DBC file cannot be empty',
  curveNameNotNullTip: 'The curve name cannot be empty',
  dbcFileUploadSuccess: 'The DBC file is uploaded successfully.',
  dataItemNotNull: 'The data item cannot be empty',
  curveOverTip: 'A maximum of ten data items can be selected for a single curve',
  selected: 'Selected',
  message: 'message',
  description: 'description',
  messageId: 'messageID',
  signal: 'signal',
  type: 'type',
  addCurve: 'Addition Curve',
  editCurve: 'Edit Curve',
  deleteCurve: 'Deleted Curve',
  deleteTip: 'Are you sure you want to delete the curve {channel}?',
  deletePannel: 'The panel is deleted successfully.',
  changeDBCFileTip1: 'Replacing the DBC file for a channel has the following impacts on the corresponding modules:',
  changeDBCFileTip2: '1. Clear panel related to channel {channel} in curve analysis',
  changeDBCFileTip3: '2. Clear the DBC triggering rule corresponding to the channel {channel} in the triggering rule ',
  changeDBCFileTip4: 'Are you sure you want to replace the DBC file and edit the panel?',
  signalCycleOverTip: 'The signal period only supports 100,000 data samples. The data of {time1} - {time2} is displayed ',
  getSignalCycleFail: 'Failed to obtain the signal period.',
  getHistoryCurveFail: 'Failed to obtain the history curve.',
  startTimeYoungerEndTime: 'The start time must be younger than the end time',
  noDataDownload: 'The chart has no data in the time frame, and the file cannot be downloaded',
  downloadSuccess: 'Download successfully',
  createChannelModel: 'Failed to create a channel model.',
  clearChannelContent: 'Failed to create a channel model Failed to clear the chart and trigger the rule under the channel',
  addCurveFail: 'New curve failure',
  addPannel: 'Adding pannel succeeded',
  addPannelFail: 'Failed to add pannel curve.',
  duplicationNameTip: 'If a curve with the same name exists, you can add it again after the curve name is changed ',
  editPannel: 'Edit pannel successfully',
  editPannelFail: 'Failed to edit curve',
  model: 'Mode',
  strategy: 'DBC strategy',
  selectStrategicTip: 'Please select a signal policy',
  selectDataPointsTip: 'Please select a data point',
  changeModelTip1: 'Changing the channel configuration mode has the following effects on the corresponding module:',
  changeModelTip2: 'Are you sure to change the configuration mode and edit the panel?',
  changeStrategicTip1: 'Replacing the DBC policy for a channel has the following impacts on the corresponding modules:',
  changeStrategicTip2: 'Are you sure you want to change the DBC policy and edit the panel?'
}
