<template>
    <div style="margin-bottom: 15px;">
        <span style="font-size:14px; margin:0 15px 0 0;" v-if="groupType === 'gatewayGroup'">{{ $t('common.keyWord.devType')
            || '设备类型' }}：
            <GatewayTypeSelect :typeOptions="typeOptions" @typeChange="devTypeSelect">
            </GatewayTypeSelect>
        </span>
    </div>
    <div v-loading.fullscreen.lock="pageLoading" class="device-high-order" :style="{ height: height || '300px' }">
        <div class="device-high-order-collapse-buttons">
            <el-button :disabled="schemaStatusList.length == 0 || disabledSave || isChild " size="mini" type="primary" plain
                @click="saveClicked" v-monitoring="saveMonitoring">{{ $t('common.button.save') }}</el-button>
            <el-tooltip v-if="true" class="item" effect="light"
                :content="$t('device_list.config.shadowDeviceCfg') || '影子设备配置'" placement="top">
                <i class="el-icon-money twinsAlertBtn" @click="handleShadowDialog(true)"></i>
            </el-tooltip>
        </div>
        <div class="high-order-tree-contain" ref="orderRef" style="margin-top: -1360px;">
            <div :style="{ height: topHeightShadow >= 0 ? (1360 + topHeightShadow + 'px') : '1360px' }"></div>
            <el-tree :data="schemaStatusListShadow" node-key="id" :key="0" @node-expand="handleNodeExpand" ref="treeRef"
                @node-collapse="handleNodeCollapse" style="margin-top: -1378px;"
                :default-expanded-keys="defaultCheckedKeys">
                <template #default="{ node, data }" style="border:1px solid #cfcc">
                    <span class="expanded" v-if="!data.ghost"></span>
                    <div :class="['high-order-tree-contain-node', { 'invisi': data.invisible }]" v-if="!data.ghost">
                        <div class="high-order-tree-contain-node-title">
                            <ZToolTipPlusCtrl width="120px" :defaultContent="$i18n.locale === 'en' ? data.refObj.label_en : data.refObj.label_zh" v-if="!data?.children" />
                            <span v-else>{{ $i18n.locale === 'en' ? data.refObj.label_en : data.refObj.label_zh }}</span>
                        </div>
                        <div class="high-order-tree-contain-node-input" v-if="isLoading">
                            <ZActiveRenderCtrl v-if="!data.children" :type="valueTypeMap[data?.type || 'anyStringInput']"
                                v-model="data.value" @getRenderFormRefList="getRenderFormRefList"
                                v-model:refObj="data.refObj.value"
                                :placeholder="(valueTypeMap[data?.type || 'anyStringInput'])?.includes('Select') ? getLanguage('zws.ZCommon.pleaseSelect') + `${$i18n.locale === 'en' ? data.refObj.label_en : data.refObj.label_zh}` : getLanguage('zws.ZCommon.pleaseEnter') + `${$i18n.locale === 'en' ? data.refObj.label_en : data.refObj.label_zh}`"
                                :customValidateRequired="data.required" :updateTime="updateTime" clearable
                                :maxlength="data.maxlength" show-word-limit>
                            </ZActiveRenderCtrl>
                        </div>
                    </div>
                </template>
            </el-tree>

            <div :style="{ height: bottomHeight >= 0 ? (bottomHeight + 'px') : 0 }" v-if="bottomHeightVisible"></div>

        </div>
    </div>
    <ZDialogCtrl ref="showShadowDialog" :title="$t('device_list.config.shadowDeviceCfgTitle') || '影子设备配置'" width="700px"
        :close-on-click-modal="false" :show-close="true" :needFooter="false" :useStandard="true" destroy-on-close>
        <div class="high-order-tree-contain-node-container" id="shadow_cfg" style="margin-top: 0px;">

            <div style="margin-top: -1360px;">
                <div :style="{ height: topHeightShadow_ >= 0 ? (1360 + topHeightShadow_ + 'px') : '1360px' }"></div>
                <el-tree :data="schemaStatusForShadowListVirtual" node-key="id" :key="20" style="margin-top: -1360px;"
                    :default-expanded-keys="defaultCheckedShadowKeys" @node-expand="handleNodeExpand_" ref="treeShadowRef"
                    @node-collapse="handleNodeCollapse_">
                    <template #default="{ node, data }">
                        <span class="expanded" v-if="!data.ghost"></span>
                        <div :class="['high-order-tree-contain-node', { 'invisi': data.invisible }]" v-if="!data.ghost">
                            <div class="high-order-tree-contain-node-title">
                                <ZToolTipPlusCtrl width="120px" :defaultContent="$i18n.locale === 'en' ? data.refObj.label_en : data.refObj.label_zh" v-if="!data?.children" />
                                <span v-else>{{ $i18n.locale === 'en' ? data.refObj.label_en : data.refObj.label_zh }}</span>
                            </div>
                            <div class="high-order-tree-contain-node-input">
                                <ZActiveRenderCtrl v-if="!data.children" :hasToolTip="data.englishLabel=='whitelist'" type="anyStringInput" v-model="data.value"
                                    :customValidateRequired="data.required" :placeholder="getLanguage('zws.ZCommon.notSet')"
                                    :disabled="true">
                                </ZActiveRenderCtrl>
                            </div>
                        </div>
                    </template>
                </el-tree>
                <div :style="{ height: bottomHeight_ >= 0 ? (bottomHeight_ + 'px') : 0 }" v-if="bottomHeightVisible_"></div>
            </div>
        </div>
        <el-button type="primary" style="margin: 25px 0 40px 0" @click="handleShadowDialog(false)">{{
            getLanguage('zws.ZButtonListCtrl.confirm') }}</el-button>
    </ZDialogCtrl>
</template>
<script lang="ts">
import Message from '../../ctrl/ZMessage'
import { ElMessage } from 'element-plus'
import { defineComponent, ref, reactive, PropType, watch, computed, defineExpose } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import {
    createCommonFnSetConfigActionByPayload,
    getDigitalTwinThingsByShadow,
    getShadowEchoAttrToHighOrderAttr,
    queryShadowRule,
    setInfoModelThirdThingsRuleInfo,
    setTriggerConditionRuleInfo,
    updateCommonFnSetConfigActionByPayload
} from '../../../../sdk/ts/tool/ZAreaAlertShadow'
import { ZValidateDataTool as ZValidateTool, ZComJsTool } from '@zws/tools'

import ZFormatConversionTool from '../../../../sdk/ts/tool/ZFormatConversionTool';
import { getInfoModelObj } from '../../../../sdk/ts/tool/ZAreaGetInfoModelObj'
import { remarkOperationMonitoring, Monitoring } from '../../../../sdk/ts/tool/ZDirectives/directives/monitoring'
import GatewayTypeSelect from './GatewayTypeSelect.vue'
import { hasPermission } from '@zws/axios-fetch'
import { useLocale } from '../../hooks/useLocale'
import { issueAsyncMessage, addSingleTwinsRule, issueGroupDeviceCommand, getSingleThing, sendBatchMessagesByThingsSelector, updateSingleTwinsRule } from '@zws/axios-fetch'

interface UserInfo {
    avatar: string,
    create_time: number,
    department: string,
    description: string,
    email: string,
    gender: string,
    id: number,
    job: string,
    mobile: string,
    name: string,
    nick_name: string,
    status: number,
    tenant_id: number,
}
interface TriggerFilter {
    field: string,
    op: string,
    value: string | number,
    value_type?: number,
    join?: string
}

export default defineComponent({
    components: {
        GatewayTypeSelect
    },
    props: {
        /* 控制按钮 */
        disabledSaveKey: {
            type: String,
            default: '',
        },
        devInfo: {
            type: Object,
        },
        platform: {
            type: String,
            default: '',
        },
        groupType: {
            type: String,
            default: 'single',
        },
        minusHeight: {
            type: Number,
            default: 160,
        },
        // 软件分组对象
        groupInfo: {
            type: Object,
        },
        // 类型分组对象
        typeInfo: {
            type: Object,
        },
        // 网关分组对象
        gatewayInfo: {
            type: Object,
        },
        typeOptions: {
            type: Array as PropType<Array<any>>,
        },
        saveMonitoring: {
            type: Object as PropType<Monitoring>
        }
    },
    setup(props, context) {
        let getLanguage = function (data: any) {
            let { t } = useLocale()
            return t(data)
        }
        const defaultCheckedKeys = ref<number[]>([])
        const defaultCheckedShadowKeys = ref<number[]>([])

        let { t } = useI18n()
        const $t = t
        let store = useStore()
        let selectDeviceStatus: any = ref('')
        let deviceInfo: any = props.devInfo
        let ruleSearchObj: any = null;
        let isLoading: any = ref(true);
        let disabledSave: any = ref(false);
        if (props.disabledSaveKey) {

            disabledSave.value = !hasPermission(props.disabledSaveKey)
        }
        let height = ref('300px')
        let currentInfo = reactive<any>({
            currentThingsId: undefined,
            currentNameKey: undefined,
            currentInfoModelId: undefined,
            currentInfoModel: undefined
        });

        const valueTypeMap: any = {
            'int': 'anyIntInput',
            'long': 'positiveIntInput',
            'float': 'anyNumberInput',
            'double': 'anyNumberInput',
            'string': 'anyStringInput',
            'boolean': 'booleanSelect',
            'array': 'anyStringInput',
            'object': 'anyStringInput',
            'enum': 'enumSelect'
        };
        let renderFormRefList: any = [];
        function getRenderFormRefList(e: any) {
            renderFormRefList.push(e);
        }
        let freshDate = ref<any>(0)
        let resultData = ref('{}')
        let curDevType = ref<any>()
        let deviceStatus: any = {}
        let mapObj = reactive<any>({});
        let schemaStatusList: { label: any; value: any; children: any }[] = reactive([])
        let schemaStatusListShadow: { label: any; value: any; children: any }[] = reactive([])
        let schemaStatusForShadowList: { label: any; value: any; children: any }[] = reactive([]);
        let schemaStatusForShadowListVirtual: { label: any; value: any; children: any }[] = reactive([]);
        let showShadowAttrDisabled = ref<boolean>(true);
        let showShadowDialog: any = ref(null);

        let userInfo = computed<UserInfo>(() => store.getters.userInfo)
        let childNames = ['gw_zigbee_node', 'gw_wireless_rs485', 'LoRaWAN_CLASSA_ABP', 'LoRaWAN_CLASSA_OTAA', 'LoRaWAN_CLASSB_ABP', 'LoRaWAN_CLASSB_C_ABP'
        , 'LoRaWAN_CLASSB_C_OTAA', 'LoRaWAN_CLASSB_OTAA', 'LoRaWAN_CLASSC_ABP', 'LoRaWAN_CLASSC_OTAA']
        let isChild = computed<any>(() => {
            var node = !true
            if (props.typeInfo?.name) {
                if (childNames.includes(props.typeInfo?.name)) {
                node = true
                }
            }
            return node 
        })
            
        let infoModelRes = ref<any>();
        let pageLoading = ref<boolean>(false)

        let orderRef = ref<any>();
        let treeRef = ref<any>();
        let treeShadowRef = ref<any>();
        let updateTime = ref<any>();
        let lastScroll = 0
        let lastclickTime = 0
        let topHeightShadow = ref(0)
        let topHeightShadow_ = ref(0)
        let lock = !true
        function move(e: any) {
            if (lock) {
                setTimeout(() => {

                    lock = false
                }, 300)
                return
            }
            lock = true
            topHeightShadow_.value = e.target.scrollTop - 0

            if (topHeightShadow_.value > showLengthHeight_.value - 50 * blockHeight) {
                topHeightShadow_.value = showLengthHeight_.value - 50 * blockHeight
            }
            if (topHeightShadow_.value < 0) {
                topHeightShadow_.value = 0
            }

            if (new Date().getTime() - lastScroll > 500) {
                if (new Date().getTime() - lastclickTime < 550) {

                    return
                }
                lastScroll = new Date().getTime()
            } else {
                return
            }
            let winH = window.innerHeight


            topHeight_.value = topHeightShadow_.value

            schemaStatusForShadowListVirtual.splice(0, schemaStatusForShadowListVirtual.length, ...getschemaStatusList(true, 13))
            setTimeout(() => {

                lock = false
            }, 300)
        }

        window.addEventListener("click", clickHandle, true)
        function clickHandle(e: any) {

        }
        let scrollTimer: any
        let target = document.querySelector('.el-main.container-main') as HTMLElement
        target && target.addEventListener("scroll", scrollHandler, true); // 监听 监听元素
        function scrollHandler(e: any) {
            clearTimeout(scrollTimer)
            scrollTimer = setTimeout(() => {
                scrollHandle(e)
            }, 300)
        }
        let lock_ = false
        function scrollHandle(e: any) {
            if (initing.value) {
                return
            }

            if (showShadowDialog.value && showShadowDialog.value.display) {
                if (e.target.id == "shadow_cfg") {
                    move(e)
                }
                return
            }
            if (lock_) {
                setTimeout(() => {

                    lock_ = false
                }, 300)
                return
            }
            lock_ = true
            topHeightShadow.value = e.target.scrollTop - 150
            if (topHeightShadow.value > showLengthHeight.value - 50 * blockHeight) {
                topHeightShadow.value = showLengthHeight.value - 50 * blockHeight
            }
            if (topHeightShadow.value < 0) {
                topHeightShadow.value = 0
            }

            if (new Date().getTime() - lastScroll > 500) {
                if (new Date().getTime() - lastclickTime < 550) {

                    return
                }
                lastScroll = new Date().getTime()
            } else {
                return
            }
            let winH = window.innerHeight


            topHeight.value = topHeightShadow.value

            schemaStatusListShadow.splice(0, schemaStatusListShadow.length, ...getschemaStatusList(0, 14))
            updateTime.value = new Date().getTime()
            setTimeout(() => {

                lock_ = false
            }, 300)
            return

        }

        function handleShadowDialog(visible: boolean) {
            schemaStatusForShadowList.splice(0, schemaStatusForShadowList.length);
            goShadowSearch();
            showShadowDialog?.value.show(visible)

            schemaStatusForShadowListVirtual.splice(0, schemaStatusForShadowListVirtual.length, ...getschemaStatusList(true, 5))
        }
        let initing:any = ref(false)
        let loading: any = ref(false)
        
        function init() {
            if (loading.value) {
                return
            }
 
            loading.value = true
            setTimeout(() => {
                loading.value = false
                initAction()
            }, 500)
        }
        async function initAction() {
            initing.value = true;
            showLengthHeight.value = 0;
            showLengthHeight_.value = 0;
            topHeightShadow.value = 0;
            topHeightShadow_.value = 0;
            bottomHeight.value = 0;  
            bottomHeight_.value = 0;
            clickKey = [];
            clickExpanded = [];
            clickKey_ = [];
            clickExpanded_ = [];
            // 拿 属性模型，对 属性模型 进行 中文映射
            [mapObj, infoModelRes.value] = await getInfoModelObj(userInfo.value.tenant_id, currentInfo.currentInfoModelId);
            // 2. 获取 高阶属性 函数主入口
            getDigitalTwinThingsEntry();
        }

        async function goShadowSearch() {
            // 影子Func1【看有没有影子属性】： 返回 [是否有影子属性, 影子信息]
            let [isHasShadowAttr, ruleSearchObjTem]: any = await getDigitalTwinThingsByShadow({
                type: `high_order_${props.groupType}`,
                name_key: currentInfo.currentNameKey,
                tenant_id: userInfo.value.tenant_id,
            });

            ruleSearchObj = ruleSearchObjTem;

            // 影子逻辑
            if (isHasShadowAttr) {
                showShadowAttrDisabled.value = false;

                // 如果影子有值，将影子属性转换为 高阶列表属性
                let deviceStatusTem = getShadowEchoAttrToHighOrderAttr(ruleSearchObj); // 中转格式

                deviceStatusTem.tenantId = userInfo.value.tenant_id;
                deviceStatusTem.infoModelId = currentInfo?.infoModel?.id;

                // 将 deviceStatusTem 二次处理
                let resultItem = dealNormalStyle(deviceStatusTem);

                // 把值设进 schemaStatusForShadowList，并且 将 label 转换为 中文
                setStatusListAndChinese(resultItem, schemaStatusForShadowList, 'shadow_');
                schemaStatusForShadowListVirtual.splice(0, schemaStatusForShadowListVirtual.length, ...getschemaStatusList(true, 6))
            }
        }
        // 入口：先找影子属性，没有再走默认逻辑（分组不获取属性值，只生成 key 即可）
        async function getDigitalTwinThingsEntry() {
            isLoading.value = true;

            // 影子逻辑
            schemaStatusForShadowList.splice(0, schemaStatusForShadowList.length);
            goShadowSearch();

            // 默认逻辑
            schemaStatusList.splice(0, schemaStatusList.length);
            getDigitalTwinThingsByNormal();

            schemaStatusListShadow.splice(0, schemaStatusListShadow.length, ...getschemaStatusList(0, 17))
            /* 【原先逻辑：影子 => 设备属性 => 模型属性】
            if (!isHasShadowAttr) {
                console.log('[order] no shadow default logic');
                // console.log('没有影子规则 ----- 走默认的逻辑');
                schemaStatusList.splice(0, schemaStatusList.length);
                getDigitalTwinThingsByNormal();
            } else {
                console.log('[order] shadow');
                // console.log('有影子规则 ----- 将影子属性转换为 高阶列表属性');

                schemaStatusList.splice(0, schemaStatusList.length);
                // 如果影子有值，将影子属性转换为 高阶列表属性
                let deviceStatusTem = getShadowEchoAttrToHighOrderAttr(ruleSearchObj); // 中转格式

                deviceStatusTem.tenantId = userInfo.value.tenant_id;
                deviceStatusTem.infoModelId = currentInfo?.infoModel?.id;

                // 将 deviceStatusTem 二次处理
                let resultItem = dealNormalStyle(deviceStatusTem);

                // 把值设进 schemaStatusList，并且 将 label 转换为 中文
                setStatusListAndChinese(resultItem);
            }
            */
        }

        // 作用：查影子 和 set 本地 ruleSearchObj
        async function searchShadowRule() {
            ruleSearchObj = await queryShadowRule({
                type: `high_order_${props.groupType}`,
                name_key: currentInfo.currentNameKey,
                tenant_id: userInfo.value.tenant_id,
            });
        }

        // 单个设备 【保存】影子入口
        async function sendShadowInit(shadowbody: string, things_selector: any[], payload: string) {
            await searchShadowRule();
            if (ruleSearchObj && ruleSearchObj.type) {
                if (ruleSearchObj.type === 'update') {
                    updateShadowRule(JSON.parse(shadowbody), things_selector, payload, ruleSearchObj.ruleId, ruleSearchObj.action?.id);
                } else {
                    createShadowRule(JSON.parse(shadowbody), things_selector, payload);
                }
            }
        }
        // 单个设备 【保存】创建影子
        async function createShadowRule(attrs: any, things_selector: any, payload: any) {
            if (!props.devInfo) return;
            let info: any = setInfoModelThirdThingsRuleInfo({
                type: `high_order_${props.groupType}`,
                tenant_id: userInfo.value.tenant_id,
                name_key: currentInfo.currentNameKey,
                info_model_id: currentInfo.currentInfoModelId,
                attrs,
                thirdThingsIdList: [props.devInfo.third_things_id],
                mapObj
            });

            if (!info) return;

            let ruleRes: any = await addSingleTwinsRule(info).catch((err: any) => { });
            if (!ruleRes) return;

            // 创建告警动作
            createCommonFnSetConfigActionByPayload({ tenant_id: userInfo.value.tenant_id, rule_id: ruleRes.id, things_selector, payload });

            init()
        }
        // 单个设备 【保存】更新影子
        async function updateShadowRule(attrs: any, things_selector: any, payload: string, ruleId: any, action_id: number) {
            if (!props.devInfo) return;
            let info: any = setInfoModelThirdThingsRuleInfo({
                type: `high_order_${props.groupType}`,
                tenant_id: userInfo.value.tenant_id,
                name_key: currentInfo.currentNameKey,
                info_model_id: currentInfo.currentInfoModelId,
                attrs,
                thirdThingsIdList: [props.devInfo.third_things_id],
                mapObj
            });

            if (!info) return;
            info.rule_id = ruleId;

            let ruleRes: any = await updateSingleTwinsRule(info).catch((err: any) => { });

            if (!ruleRes) return;

            // 更新告警动作
            action_id
                ? updateCommonFnSetConfigActionByPayload({ tenant_id: userInfo.value.tenant_id, rule_id: ruleId, things_selector, payload, action_id: action_id })
                : createCommonFnSetConfigActionByPayload({ tenant_id: userInfo.value.tenant_id, rule_id: ruleRes.id, things_selector, payload })

            init()
        }

        function getDigitalTwinThingsByNormal() {
            return getDefaultAttr();
        }

        // 把值设进 schemaStatusList，并且 将 label 转换为 中文
        function setStatusListAndChinese(resultItem: any, targetArr: any, shadowIdDec?: any) {
            if (!resultItem || !Array.isArray(resultItem)) return;

            resultItem.forEach((item: any, index: any) => {
                item.id = shadowIdDec + 'high_' + index
                targetArr.push(item);
            });
            englishLabelToChineseLabel(targetArr, '', 0, shadowIdDec);
            setSortInit(targetArr);
            filterEmpty(targetArr);
            initing.value = false;
        }

        /** 【入口函数】将设备信息对象 转换为 [{label: undefined, children: undefined, value: undefined}] */
        function dealThingsResToSchemaStatusList(res: any) {
            let resultItem = dealNormalStyle(res.list[0].digital_twin_things_status);
            setStatusListAndChinese(resultItem, schemaStatusList);
        }

        /** 【核心函数】将设备信息对象 转换为 [{label: undefined, children: undefined, value: undefined, required: false}] */
        function dealNormalStyle(target: any) {
            let whiteNameList = ['infoModelId', 'tenantId', 'userId'];
            let resultArr: any = [];
            for (let key in target) {
                let templateObj: any = {
                    label: undefined,
                    children: undefined,
                    value: undefined,
                    required: totalStatusObj[key]?.required || undefined
                };
                if (!whiteNameList.includes(key)) {
                    templateObj.label = key;
                    if (Object.prototype.toString.call(target[key]) == '[object Object]') {
                        templateObj.children = dealNormalStyle(target[key])
                    } else {
                        templateObj.value = target[key];
                        if (Array.isArray(templateObj.value)) {
                            templateObj.value = JSON.stringify(templateObj.value)
                        }
                    }
                    resultArr.push(templateObj);
                }
            }
            return resultArr;
        }

        function filterEmpty(targetArr: any) {
            if (!targetArr || !Array.isArray(targetArr)) return;
            let newArr = targetArr.filter((item: any) => {
                return item.children && item.children.length > 0
            });
            targetArr.splice(0, targetArr.length);
            if (ZValidateTool.checkArrayAndLength(newArr)) {
                newArr.forEach((item: any) => {
                    targetArr.push(item);
                });
            }
        }
        /** 排序 + 对象后置 */
        function setSortInit(targetArr: any) {
            if (!targetArr || !Array.isArray(targetArr)) return;
            let newArr = targetArr.map((item: any) => {
                return {
                    ...item,
                    children: Array.isArray(item.children) ? setSort(item.children) : item.children
                }
            });
            if (ZValidateTool.checkArrayAndLength(newArr)) {
                targetArr.splice(0, targetArr.length);
                newArr.forEach((item: any) => {
                    targetArr.push(item);
                });
            }
            schemaStatusListShadow.splice(0, schemaStatusListShadow.length, ...getschemaStatusList(0, 18))
        }
        function setSort(nativeArr: any) {
            if (!nativeArr || !Array.isArray(nativeArr)) return;

            let normalArr: any = [];
            let objArr: any = []; // objArr 需要额外排序

            nativeArr.forEach((item: any) => {
                if (item.children && Array.isArray(item.children)) {
                    objArr.push({
                        ...item,
                        children: setSort(item.children)
                    });
                } else {
                    normalArr.push(item);
                }
            });

            objArr = objArr.sort(ZFormatConversionTool.compare('label'));
            return [...normalArr, ...objArr];
        }
        /** 将 label 转换为 中文 */

        function englishLabelToChineseLabel(targetArr: any, prefixName?: any, nodeLevel?: any, shadowIdDec?: any) {
            if (!targetArr || !Array.isArray(targetArr)) return;

            let labelName = '';

            targetArr.forEach((item: any, index: any) => {
                item.id = (shadowIdDec || '') + 'high_' + nodeLevel + '_' + index

                if (prefixName) {
                    labelName = `${prefixName}-${item.label}`;
                } else {
                    labelName = `${item.label}`;
                }
                let chineseLabel = mapObj[labelName]?.name || mapObj[labelName];
                
                item.label_en = mapObj[labelName]?.name_en || item.label
                item.label_zh = mapObj[labelName]?.name_zh || mapObj[labelName]?.name_en || item.label
                
                if (chineseLabel) {
                    item.englishLabel = item.label;
                    item.label = chineseLabel;
                    item.type = mapObj[labelName]?.type;
                }

                if (item.children && Array.isArray(item.children)) {
                    nodeLevel++
                    englishLabelToChineseLabel(item.children, labelName, nodeLevel, shadowIdDec);
                }
            })
        }

        function selectDeviceStatusChange(value: any) {
            let selectData: any = {}
            value.forEach((item: any) => {
                selectData[item.label] = item.value
            })
            resultData.value = JSON.stringify(selectData, null, 5)
        }

        /** 把 schemaStatusList 转为接口参数格式 deviceStatusInfo */
        function setDeviceStatusInfo(statusArr: any, deviceStatusInfo: any) {
            if (statusArr && Array.isArray(statusArr)) {
                statusArr.forEach((item: any) => {
                    let label = item.englishLabel || item.label;
                    deviceStatusInfo[label] = {};
                    if (item.children && Array.isArray(item.children)) {
                        setDeviceStatusInfo(item.children, deviceStatusInfo[label]);
                    } else {
                        // deviceStatusInfo[label] = (item.value || item.value === false || item.value === 0) ? item.value : '';
                        if (item.value || item.value === false || item.value === 0) {
                            if (item.type === 'array') {
                                deviceStatusInfo[label] = JSON.parse(item.value)
                            } else if (item.type === 'int' || item.type === 'float' || item.type === 'long') {
                                deviceStatusInfo[label] = +item.value
                            } else {
                                deviceStatusInfo[label] = item.value
                            }
                        } else {
                            deviceStatusInfo[label] = ''
                        }
                    }
                })
            }
        }
        function validateLoop() {
            let isValidateOk = true;
            if (ZValidateTool.checkArrayAndLength(renderFormRefList)) {
                renderFormRefList.forEach((item: any) => {
                    item.validate && item.validate((validate: any) => {
                        if (!validate) isValidateOk = false;
                    })
                })
            }
            return isValidateOk;
        }
        function saveClicked() {
            let isValidateOk = validateLoop();
            if (!isValidateOk) return;

            // 将用户界面输入值更新到 deviceStatus 对象中
            deviceStatus = {};
            setDeviceStatusInfo(schemaStatusList, deviceStatus);
            //调整入参格式，去掉功能块名称，并将operation_params内key/value的value全部转为字符串
            let data: any = {}
            for (let key in deviceStatus) {
                for (let i in deviceStatus[key]) {
                    let v = deviceStatus[key][i]
                    let val: any = null
                    if (Object.prototype.toString.call(v) === '[object Object]') {    //若值为对象，则转化为JSON字符串
                        val = JSON.stringify(deviceStatus[key][i])
                    } else {
                        val = v
                    }
                    data[i] = val
                }
            }
            let shadowbody: string = JSON.stringify(deviceStatus);
            sendMessage(props.groupType)(data, shadowbody)
        }

        let totalStatusObj: any = {}
        function dealJsonSchema(properties: any, targetObj: any, entitiesList: any) {
            if (ZValidateTool.checkArrayAndLength(properties)) {
                properties.forEach((statusItem: any) => {
                    let specs_ref_entity_id = statusItem?.data_type?.specs?.ref_entity_id;

                    if (specs_ref_entity_id > -1) {
                        if (!Array.isArray(entitiesList)) return;
                        let ref_iot_entities_target = entitiesList.filter(i => i.id == specs_ref_entity_id);

                        if (ref_iot_entities_target.length === 0) return;

                        let ref_iot_entities_target_json_schema = ref_iot_entities_target[0].json_schema;
                        targetObj[statusItem.identifier || statusItem.name] = {};

                        if (ref_iot_entities_target_json_schema) {
                            ref_iot_entities_target_json_schema = JSON.parse(ref_iot_entities_target_json_schema);
                            dealJsonSchema(ref_iot_entities_target_json_schema.properties, targetObj[statusItem.identifier || statusItem.name], entitiesList);
                        }
                    } else {
                        // 给默认空值， key: default_value || undefined
                        targetObj[statusItem.identifier] = statusItem.default_value || undefined
                        totalStatusObj[statusItem.identifier] = {
                            read_only: statusItem.read_only,
                            required: statusItem.required || undefined,
                            description: statusItem.description,
                            identifier: statusItem.identifier,
                            default_value: statusItem.default_value || undefined,
                        }
                    }
                })
            }
        }
        async function getDefaultAttr() {
            let mixinThingsStatus: any = {}; // 拿模型 和 默认值 合并过后的  objForDefaultValue 覆盖 objForModel = mixinThingsStatus

            let objForModel: any = {}; // 模型的值

            let thingRes: any = {}; // 设备的数据
            let objForDefaultValue: any = {}; // 设备数据 digital_twin_things_status 的默认值

            let targetResult: any = {
                list: [{
                    digital_twin_things_status: mixinThingsStatus
                }]
            };

            // 分组默认值只拿 key，不拿 vue，分组没有 things_id，不走这里
            if (currentInfo.currentThingsId) {

                thingRes = await getSingleThing({ id: currentInfo.currentThingsId, tenant_id: userInfo.value.tenant_id });

                objForDefaultValue = thingRes?.digital_twin_things_status;
            }

            if (!infoModelRes.value) return;
            let info_model_fn_blocks = infoModelRes.value.info_model_fn_blocks;
            let ref_iot_entities = infoModelRes.value.ref_iot_entities;
            if (!ZValidateTool.checkArrayAndLength(info_model_fn_blocks)) return;
            info_model_fn_blocks.forEach((item: any) => {
                objForModel[item.name] = {};
                if (item?.iot_function_block?.json_schema) {
                    let iot_function_block_json_schema = JSON.parse(item.iot_function_block.json_schema);
                    let status_properties = iot_function_block_json_schema?.status?.properties;
                    dealJsonSchema(status_properties, objForModel[item.name], ref_iot_entities);
                }
            });
            let objForModelKeys = Object.keys(objForModel); // 功能块名字

            if (ZValidateTool.checkArrayAndLength(objForModelKeys)) {
                objForModelKeys.forEach((blockItem: any) => {
                    // 拿 设备默认值 objForDefaultValue 的某个功能块 去覆盖 模型的某个功能块
                    let objForDefaultValueBlockItem = objForDefaultValue[blockItem];
                    let mergeItem = objForModel[blockItem];
                    if (objForDefaultValueBlockItem) {
                        mergeItem = ZComJsTool.deepMergeObj(objForModel[blockItem], objForDefaultValueBlockItem)
                    }
                    mixinThingsStatus[blockItem] = mergeItem
                });
            }

            dealThingsResToSchemaStatusList(targetResult);
        }

        const sendMessage: Function = (type: string) => {
            const map = new Map()
            map.set('single', singleSend)
            map.set('softGroup', softGroupSend)
            map.set('typeGroup', typeGroupSend)
            map.set('gatewayGroup', gatewayGroupSend)
            return map.get(type)
        }

        const softGroupSend = (body: string, shadowbody: string) => {
            const payloadInfo = {
                // 示例
                operation_name: 'common_fn.set_config',
                operation_params: body/* {
                    args: body,
                }, */
            }
            const things_selector: any[] = [
                {
                    field: `group_${props.groupInfo?.id}`,
                    value: 1,
                },
            ]
            const triggerFilter: TriggerFilter[] = [
                {
                    field: `group_${props.groupInfo?.id}`,
                    op: '==',
                    value: 1,
                    value_type: 1
                }
            ]
            hasPermission("group:device:command")
            let monitor = {
                platform: props.platform||'ZWS-Cloud',
                module: '设备分组',
                action: '下发分组配置',
            }
            remarkOperationMonitoring(monitor)
            issueGroupDeviceCommand({
                tenant_id: userInfo.value.tenant_id,
                user_id: userInfo.value.id,
                group_id: props.groupInfo?.id,
                description: '',
                payload: JSON.stringify(payloadInfo),
            })
                .then((result: any) => {
                    Message.message.success($t('device_group.tips.saveConfigSuccess') || '分组配置成功')
                })
                .catch((err: any) => {
                    Message.message.error(err?.data?.error)
                })
            sendShadowInitAll(JSON.parse(shadowbody), triggerFilter, things_selector, JSON.stringify(payloadInfo))
        }

        const typeGroupSend = (body: string, shadowbody: string) => {
            const payloadInfo = {
                // 示例
                operation_name: 'common_fn.set_config',
                operation_params: body/* {
                    args: body,
                }, */
            }
            const things_selector: any[] = [
                {
                    field: 'info_model_id',
                    value: props.typeInfo?.id,
                },
            ]
            const triggerFilter: TriggerFilter[] = [
                {
                    field: 'info_model_id',
                    op: '==',
                    value: props.typeInfo?.id,
                    value_type: 1
                }
            ]
            groupSend(shadowbody, things_selector, JSON.stringify(payloadInfo), triggerFilter)
        }

        const gatewayGroupSend = (body: string, shadowbody: string) => {
            const payloadInfo = {
                // 示例
                operation_name: 'common_fn.set_config',
                operation_params: body/* {
                    args: body,
                }, */
            }
            const things_selector: any[] = [
                {
                    field: 'info_model_id',
                    value: curDevType.value.id,
                },
                {
                    field: `parent_${props.gatewayInfo?.id}`,
                    value: 1,
                },
            ]
            const triggerFilter: TriggerFilter[] = [
                {
                    field: 'info_model_id',
                    op: '==',
                    value: curDevType.value.id,
                    join: 'and',
                    value_type: 1
                },
                {
                    field: `parent_${props.gatewayInfo?.id}`,
                    op: '==',
                    value: 1,
                    value_type: 1
                }
            ]
            groupSend(shadowbody, things_selector, JSON.stringify(payloadInfo), triggerFilter)
        }

        const groupSend = (body: string, things_selector: any[], payload: string, triggerFilter: TriggerFilter[]) => {
            let monitor = {
                platform: props.platform||'ZWS-Cloud',
                module: '设备分组',
                action: '下发分组配置',
            }
            remarkOperationMonitoring(monitor)
            sendBatchMessagesByThingsSelector({ tenant_id: userInfo.value.tenant_id, headers: '{}', payload, things_selector })
                .then(() => {
                    Message.message.success($t('device_group.tips.saveConfigSuccess') || '分组配置成功')
                    // sendShadowInit(JSON.parse(body));
                })
                .catch((err: any) => {
                    Message.message.error(err?.data?.error)
                })
            sendShadowInitAll(JSON.parse(body), triggerFilter, things_selector, payload)
        }

        // 单个设备发送
        const singleSend = (body: string, shadowbody: string) => {

            pageLoading.value = true
            const payloadInfo = {
                operation_name: 'common_fn.set_config',
                operation_params: body//obj对象
            };
            let info = {
                correlation_id: "",
                description: 'string',
                headers: '{}',
                info_model_id: deviceInfo.info_model_id,
                payload: JSON.stringify(payloadInfo),
                tenant_id: userInfo.value.tenant_id,
                things_id: deviceInfo.id,
                things_type: (deviceInfo.thing_type == 0 || deviceInfo.thing_type == $t('device.deviceDetail.deviceCommon', '普通设备')) ? 0 : 1,  // thing_type === 0 ? '普通设备' : '网关设备'
                third_things_id: deviceInfo.third_things_id,
            };
            //下发同步命令

            issueAsyncMessage({ tenant_id: userInfo.value.tenant_id, things_id: deviceInfo.id, body: info, timeout: 10000 }).then((res: any) => {
                ElMessage.success($t('device_list.tips.configSetSuccess', '成功下发配置命令'))
                pageLoading.value = false
            }).catch((err: any) => {
                pageLoading.value = false
            })
            const things_selector: any[] = [
                {
                    "field": "info_model_id",
                    "value": deviceInfo.info_model_id
                },
                {
                    "field": "third_things_id",
                    "value": deviceInfo.third_things_id
                }
            ];
            //设置影子配置
            sendShadowInit(shadowbody, things_selector, JSON.stringify(payloadInfo))
        }

        const devTypeSelect = ({ devType }: { devType: any }) => {
            curDevType.value = devType
            selectDeviceStatus.value = ''
            resultData.value = ''
            if (devType) {
                curDevType.value = devType
                currentInfo.currentNameKey = props.gatewayInfo?.id + '_' + curDevType.value?.id
                currentInfo.currentInfoModelId = curDevType.value?.id;
                currentInfo.currentInfoModel = curDevType.value;
                init()
            } else {
                curDevType.value = null
                currentInfo.currentNameKey = ''
                currentInfo.currentInfoModelId = null;
                currentInfo.currentInfoModel = null;
                schemaStatusList.splice(0, schemaStatusList.length);
            }
        }

        async function sendShadowInitAll(funcBlock: any, triggerFilter: TriggerFilter[], things_selector: any, payload: string) {
            await searchShadowRule();
            if (ruleSearchObj && ruleSearchObj.type) {
                if (ruleSearchObj.type === 'update') {
                    updateShadowRuleAll(funcBlock, triggerFilter, things_selector, payload, ruleSearchObj.ruleId, ruleSearchObj.action?.id);
                } else {
                    createShadowRuleAll(funcBlock, triggerFilter, things_selector, payload);
                }
            }
        }
        async function createShadowRuleAll(attrs: any, triggerFilter: TriggerFilter[], things_selector: any, payload: string) {
            // if (!props.devInfo) return;
            let info: any = {
                name: `high_order_${props.groupType}-twins-rule-id-${currentInfo.currentNameKey}`,
                shadow_type: 1,
                status: 1,
                trigger_condition: setTriggerConditionRuleInfo(attrs, mapObj),
                trigger_filter: triggerFilter,
                tenant_id: userInfo.value.tenant_id
            }

            let ruleRes: any = await addSingleTwinsRule(info).catch((err: any) => { });
            // 创建告警动作
            ruleRes && createCommonFnSetConfigActionByPayload({ tenant_id: userInfo.value.tenant_id, rule_id: ruleRes.id, things_selector, payload });

            init()
        }
        async function updateShadowRuleAll(attrs: any, triggerFilter: TriggerFilter[], things_selector: any, payload: string, ruleId: any, action_id: number) {
            // if (!props.devInfo) return;
            let info: any = {
                name: `high_order_${props.groupType}-twins-rule-id-${currentInfo.currentNameKey}`,
                shadow_type: 1,
                status: 1,
                trigger_condition: setTriggerConditionRuleInfo(attrs, mapObj),
                trigger_filter: triggerFilter,
                tenant_id: userInfo.value.tenant_id,
                rule_id: ruleId
            }

            let ruleRes: any = await updateSingleTwinsRule(info).catch((err: any) => { });
            action_id ?
                updateCommonFnSetConfigActionByPayload({ tenant_id: userInfo.value.tenant_id, rule_id: ruleId, things_selector, payload, action_id: action_id })
                :
                createCommonFnSetConfigActionByPayload({ tenant_id: userInfo.value.tenant_id, rule_id: ruleRes.id, things_selector, payload })
            // if (!ruleRes) return;

            init()
        }

        watch(
            [() => props.typeInfo, () => props.devInfo],
            () => {
                if (props.groupType) {
                    selectDeviceStatus.value = ''
                    resultData.value = ''
                    if (props.groupType === 'single') {
                        deviceInfo = props.devInfo;
                        if (deviceInfo) {
                            currentInfo.currentThingsId = deviceInfo.id;
                            currentInfo.currentNameKey = deviceInfo.id;
                            currentInfo.currentInfoModelId = deviceInfo.info_model_id;
                            currentInfo.currentInfoModel = deviceInfo.infoModel;
                            deviceInfo && init()
                        }
                    } else {
                        isLoading.value = false;
                        curDevType.value = props.typeInfo;
                        // getTypeStatus()
                        currentInfo.currentThingsId = null;
                        currentInfo.currentNameKey = props.groupType === 'softGroup' ? props.groupInfo?.id : curDevType.value?.id;
                        currentInfo.currentInfoModelId = curDevType.value?.id;
                        currentInfo.currentInfoModel = curDevType.value;
                        // 网关分组单独走逻辑
                        if (curDevType.value) {
                            init()
                        }
                    }
                }
            },
            {
                immediate: true,
            }
        );
        function setListener() {
            let mainDiv = document.getElementsByClassName('el-main')[0]
            if (mainDiv) {
                height.value = (mainDiv.clientHeight - props.minusHeight) + 'px'
            }
            window.addEventListener('resize', () => {
                let mainDiv = document.getElementsByClassName('el-main')[0]
                if (mainDiv) {
                    height.value = (mainDiv.clientHeight - props.minusHeight) + 'px'
                }
            })
        }
        setListener()

        let showLengthHeight = ref(0)
        let showLengthHeight_ = ref(0)
        let topHeight = ref(0)
        let topHeight_ = ref(0)
        let bottomHeight = ref(0)
        let bottomHeight_ = ref(0)
        let fresh = 0
        let bottomHeightVisible_ = ref(true)
        let bottomHeightVisible = ref(true)
        let blockHeight = 68
        let getschemaStatusList = function (forShadow?: any, tag?: any, tailNode?: any) {

            let growUp = 0
            if (tailNode && tailNode.isTail) {
                growUp = 20
            }
            lastclickTime = new Date().getTime()
            let countsH = 0
            var ampH: any = []
            var deepCopyH = (obj: any) => {

                var amp: any = []
                var result = Array.isArray(obj) ? [] : {}
                for (var key = 0; key < obj.length; key++) {


                    fresh++


                    if (forShadow) {
                        countsH++
                        deepCopyH(obj[key].children || [])
                    } else {
                        if (obj[key].hide) {
                            countsH++
                            //objNode.children =  []
                        } else {
                            if (!obj[key].ghost) {
                                countsH++
                            }

                            deepCopyH(obj[key].children || [])

                        }
                    }
                }

                return ampH
            }
            if (forShadow) {
                deepCopyH(schemaStatusForShadowList || [])
            } else {
                deepCopyH(schemaStatusList || [])
            }
            let showLength = (countsH + 21) * blockHeight

            let arr: any = []

            var keepList = (obj: any) => {
                arr.push(obj)
                if (arr.length > 10) {
                    arr.shift(obj)
                }

            }

            let counts = 0
            var openKeys: any = []
            var skipNodeCount = topHeight.value / blockHeight - 20

            if (forShadow) {
                skipNodeCount = topHeight_.value / blockHeight - 20
            }
            if (skipNodeCount < 0) {

                for (let i = skipNodeCount; i < 0; i++) {

                    arr.push({
                        type: 'int',
                        invisible: true,
                        refObj: { value: '' }
                    })
                }
                skipNodeCount = 0
            }
            var headList = []
            var deepCopy = (obj: any, tag: any) => {


                if (counts > (49 + growUp)) {
                    return []
                }
                var amp: any = []
                var result = Array.isArray(obj) ? [] : {}
                for (var key = 0; key < obj.length; key++) {
                    if (counts > (49 + growUp)) {
                        return amp
                    }

                    fresh++

                    let objNode = JSON.parse(JSON.stringify(obj[key]))
                    objNode.refObj = obj[key]
                    skipNodeCount--
                    if (skipNodeCount >= 1 && !tag && !obj[key].children) {
                        /* 消失在视野的，有高度的 */

                    } else {
                        /* 留在视野的，有高度的 */
                        if (skipNodeCount >= 1 && !tag) {
                            /* 无高度的 */
                            objNode.ghost = true
                        }
                        if (skipNodeCount >= 1) {
                            //
                            objNode.ghost = true
                        }
                        amp.push(objNode)
                        if (!objNode.ghost) {
                            counts++
                        }

                    }

                    if (objNode.children) {
                        objNode.children = []
                    }

                    if (obj[key].hide) {
                        objNode.children = [{ ghost: true }]
                        if (counts > 49) {

                            objNode.isTail = true
                            return amp
                        }

                    } else {

                        if (counts >= (50 + growUp)) {

                            objNode.isTail = true
                            return amp
                        }
                        openKeys.push(objNode.id)
                        if (obj[key].children) {
                            objNode.children = deepCopy(obj[key].children || [], '')
                        }
                    }
                }

                return amp
            }
            /* 获取key */


            let rst = []
            if (forShadow) {
                rst = deepCopy(schemaStatusForShadowList || [], 'root')
            } else {
                rst = deepCopy(schemaStatusList || [], 'root')
            }

            if (forShadow) {

                showLengthHeight_.value = showLength
                if (counts < 50) {

                    topHeightShadow_.value += (counts - 50) * blockHeight
                    if (topHeightShadow_.value < 0) {
                        topHeightShadow_.value = 0
                    }
                    bottomHeight_.value = showLength - topHeightShadow_.value - counts * blockHeight
                    bottomHeightVisible_.value = false
                } else {

                    bottomHeightVisible_.value = true
                    bottomHeight_.value = showLength - topHeightShadow_.value - counts * blockHeight
                }
            } else {
                showLengthHeight.value = showLength
                if (counts < 50) {

                    topHeightShadow.value += (counts - 50) * blockHeight
                    if (topHeightShadow.value < 0) {
                        topHeightShadow.value = 0
                    }

                    bottomHeight.value = showLength - topHeightShadow.value - counts * blockHeight
                    bottomHeightVisible.value = false
                } else {
                    bottomHeightVisible.value = true
                    bottomHeight.value = showLength - topHeightShadow.value - counts * blockHeight


                }

            }


            if (forShadow) {
                defaultCheckedShadowKeys.value = openKeys
            } else {
                defaultCheckedKeys.value = openKeys
            }
            var expandUpdate = (forShadow?: any) => {
                let clickKeyArr = clickKey
                let clickExpandedArr = clickExpanded
                let treeRefValue = treeRef.value
                if (forShadow) {
                    clickKeyArr = clickKey_
                    clickExpandedArr = clickExpanded_
                    treeRefValue = treeShadowRef.value
                }

                if (clickKeyArr && treeRefValue && treeRefValue.getNode) {
                    clickKeyArr.forEach((clickKey_: any, index_: any) => {

                        var nod = treeRefValue.getNode(clickKey_) || {}

                        nod.expanded = clickExpandedArr[index_]

                    })
                }

                freshDate.value = new Date().getTime()
            }
            setTimeout(() => {
                expandUpdate()
                expandUpdate(true)
            }, 200)
            return arr.concat(rst)
        }
        let clickKey: any = []

        let clickExpanded: any = []
        let clickKey_: any = []

        let clickExpanded_: any = []

        function storeNodeExpand(node: any, expand: any, forShadow?: any) {
            let clickKeyArr = clickKey
            let clickExpandedArr = clickExpanded

            if (forShadow) {
                clickKeyArr = clickKey_
                clickExpandedArr = clickExpanded_
            }
            if (!clickKeyArr.includes(node.id)) {
                clickKeyArr.push(node.id)
                clickExpandedArr.push(expand)
            } else {
                var idx = clickKeyArr.indexOf(node.id)
                clickKeyArr[idx] = node.id
                clickExpandedArr[idx] = expand
            }


        }
        function handleNodeExpand(node: any, nodeProp: any, treeNode: any, forShadow?: any) {
            node.refObj.hide = false
            storeNodeExpand(node, true, forShadow)

            fresh = new Date().getTime()
            if (!forShadow) {
                schemaStatusListShadow.splice(0, schemaStatusListShadow.length, ...getschemaStatusList(forShadow, 1, node))
            } else {
                schemaStatusForShadowListVirtual.splice(0, schemaStatusForShadowListVirtual.length, ...getschemaStatusList(true, 6, node))
            }
        }
        function handleNodeCollapse(node: any, nodeProp: any, treeNode: any, forShadow?: any) {
            fresh = new Date().getTime()

            node.refObj.hide = true
            storeNodeExpand(node, false, forShadow)

            if (node?.children?.[0].ghost) {

                node.refObj.hide = false
                storeNodeExpand(node, true, forShadow)

            }
            if (!forShadow) {
                schemaStatusListShadow.splice(0, schemaStatusListShadow.length, ...getschemaStatusList(forShadow, 12, node))
            } else {
                schemaStatusForShadowListVirtual.splice(0, schemaStatusForShadowListVirtual.length, ...getschemaStatusList(true, 6, node))
            }
        }

        function handleNodeCollapse_(node: any, nodeProp: any, treeNode: any) {

            handleNodeCollapse(node, nodeProp, treeNode, true)
        }
        function handleNodeExpand_(node: any, nodeProp: any, treeNode: any) {

            handleNodeExpand(node, nodeProp, treeNode, true)
        }
        function mockScroll() {

            let target = document.querySelector('.el-main.container-main') as HTMLElement
            topHeightShadow.value = target.scrollTop - 150
            if (topHeightShadow.value > showLengthHeight.value - 50 * 68) {
                topHeightShadow.value = showLengthHeight.value - 50 * 68
            }
            if (topHeightShadow.value < 0) {
                topHeightShadow.value = 0
            }
        }

        return {
            isChild,
            getLanguage,
            disabledSave,
            treeRef,
            treeShadowRef,
            freshDate,
            defaultCheckedKeys,
            defaultCheckedShadowKeys,
            height,
            bottomHeightVisible,
            bottomHeightVisible_,
            topHeightShadow,
            topHeightShadow_,
            topHeight,
            bottomHeight,
            bottomHeight_,
            showLengthHeight,
            handleNodeCollapse,
            handleNodeExpand,
            handleNodeCollapse_,
            handleNodeExpand_,
            updateTime,
            orderRef,
            selectDeviceStatus,
            schemaStatusList,
            schemaStatusListShadow,
            schemaStatusForShadowList,
            schemaStatusForShadowListVirtual,
            resultData,
            curDevType,
            deviceInfo,
            valueTypeMap,
            renderFormRefList,
            showShadowAttrDisabled,
            showShadowDialog,
            isLoading,
            handleShadowDialog,
            getRenderFormRefList,
            selectDeviceStatusChange,
            saveClicked,
            devTypeSelect,
            pageLoading,
            getschemaStatusList
        }
    },
})
</script>
<style lang="less" scoped>
.high-order-tree-contain {
    width: 600px;
    padding: 70px 20px 20px 20px;
    border: 1px #dcdfe6 solid;

    &-node {
        width: 100%;
        display: flex;
        align-items: center;

        &-title {
            font-size: 14px;
            width: 84px;
        }

        &-input {
            flex: 1;
            margin: 0 16px;

            .input-item {
                width: 100%
            }
        }
    }
}

/deep/ .el-tree-node__content {
    height: 68px;
}

.device-high-order {
    overflow: auto;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.device-high-order-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.device-high-order-head-title {
    font-size: 14px;
    color: #999999;
}



.device-high-order-input {
    width: 600px;
}

.device-high-order-collapse {
    width: 600px;
    padding: 0px 20px 20px 20px;
    border: 1px #dcdfe6 solid;
}

.device-high-order-collapse-buttons {

    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    position: absolute;
    z-index: 9;
    left: 0px;
    background: #fff;
    width: 600px;
    padding: 20px 20px 5px;
    border: 1px solid #dcdfe6;
    border-bottom: none;


}

.el-icon-sort {
    transform: rotate(90deg);
    margin-right: 20px;
    color: #606266;
}

.twinsAlertBtn {
    cursor: pointer;
}

.high-order-tree-contain-node-container {
    height: 500px;
    overflow-y: auto;
}

/*  */
/deep/ .el-tree-node__content {
    height: auto !important;
}

.high-order-tree-contain-node {
    height: 68px !important;
}

.high-order-tree-contain-node.invisi {
    visibility: hidden !important;
}

/deep/ .el-tree-node__expand-icon.is-leaf {
    display: none;
}

/deep/ .expanded {
    display: none;
}

/deep/ .el-tree-node__content .expanded:nth-of-type(1):nth-last-of-type(2) {
    display: block;
}
</style>