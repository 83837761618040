export default {
    name: 'en',
    zws: {

        ZCommon: {
            desc: 'Description',
            upgrade: 'Upgrade',
            delete: 'Delete',
            inputContent: 'Input content',
            'standard': 'Standard',
            custom: 'Custom',
            pleaseEnter: 'please enter ',
            pleaseSelect: 'Please select ',
            noData: 'No data',
            yes: 'Yes',
            no: 'No',

            fomat: 'Fomat',
            csv: 'csv Fomat',
            notSet: 'not set',
            total: 'Total',
            'standard': 'Standard',
            custom: 'Custom',
            all: "All",
            number: 'Serial',
            time: 'Time',
            devId: 'Device ID',
            devName: 'Device Name',
            devType: 'Device Type',
            uploadTime: 'Upload Time',
            dataTime: 'Data Time',
            "fresh": "Fresh",
            status: 'Status',
            offline: 'Offline',
            online: 'Online',
            operate: 'Actions',
            tips: {
                tip: 'Tip',
                username: 'Please input username',
                password: 'Please input password',
                confirmPassword: 'Please confirm password',
                email: 'Please input email',
                pwdTitle: 'User Password',
                pwdNotNull: 'Password can not be empty',
                pwdTip: '8-16 chars, without Chinese',
                noDataToSave: 'No Data To Save',
                pwdError: "Password error",

                devSelect: 'Select the device ',
                searchContent: 'Input search content',
                "selectDevType": "Select Device Type",
                fail: 'Failed',
                cancelOperate: 'Sure cancel operate',
                toUserCenterBuy: 'The limit has been reached. Please  to the user center upgrade before proceeding.',

                buy: 'Purchase',
            },
            info: {
                mobile: 'Mobile',
                smsCode: 'Code',
                username: 'Username',
                userPwd: 'User Password',
                password: 'Password',
                confirmPassword: 'Confirm',
                email: 'Email',
                emailCode: 'Verify Code',
                flag: 'Flag'
            },
        },

        login: {
            name: 'Login',
            loginSuccess: 'Login Success',
            logining: 'Logining',
            experiment: 'Experiment',
            pwdLogin: 'Password Login',
            codeLogin: 'Code Login',
            smsCode: 'SMS Code',
            remember: 'Remember',
            forgetPassword: 'Forget password?',

            thirdLogin: 'Third-party Login',
            help: 'Help',
            footerPhone: 'Contact: ',
            footerEmail: 'Email: ',
            error: {
                '001': 'Username/area code+mobile phone number cannot be empty',
                '002': 'Mobile phone number cannot be empty',
                '003': "Please enter 2-65 digits, which can only contain {'@'} alphanumeric underscore",
                '004': 'Please enter the mobile phone number with correct digits, which can only be numbers',
                '005': 'Please enter 8-16 non Chinese characters without spaces',
                '006': 'Password cannot be empty',
                '007': 'Mobile number already exists',
                '008': 'Please enter 2-32 digits, which can only contain alphanumeric underscores',
                '009': 'Username already exists',
                '010': 'Please enter 8-16 non Chinese characters without spaces',
                '011': 'The passwords entered two times are inconsistent',
                '012': 'Confirm password cannot be empty',
                '013': 'Username cannot be empty',
                '014': 'Please enter 6-digit verification code',
                '015': 'Please enter the correct email address',
                '016': 'Email already exists',
                '017': 'Email cannot be empty',
                '018': 'Mobile phone verification code is invalid or expired, please get it again',
                '019': 'The email verification code is invalid or expired, please get it again',
                '020': 'Invalid merchant ID',
                '021': 'Please enter 6-digit verification code',
                '022': 'The login password cannot be empty',

                409: "Account conflict, please contact the administrator for resolution"
            },
            resetPassword: 'Reset password',
            clickThisBack: 'Return to ',
            uncheckedTipsTitle: 'login prompt',
            uncheckedTips: 'Dear user, your account is under review, we will call you during the review, please pay attention to answer.  If you have questions about the progress of the account, you can also directly call 0202815699 to transfer extension 8919 for consultation, or contact Zhiyuan electronic sales  ',
            uncheckedButton: 'close'
        },
        register: {
            fillInInformation: 'Information',
            emailAddress: 'Email',
            informationRegistration: 'Registration',
            finishRegistration: 'Finish',

            toRegist: 'To Regist',
            toRegistTips: 'This phone number does not have an account yet. Please register an account first',
            toActiveTips: 'This account is not activated. Please log in first',
            name: 'Register',
            bind: 'Bind',
            bindTips: 'For the first time using a third-party login, you need to complete the following information and bind your cloud platform account',
            back: 'Back',
            next: 'Next',
            login: 'sign in now',
            isExist: 'Registered ?',
            role: 'Role',
            account: 'Account',
            email: 'Email',
            finish: 'Finished',
            userType: 'User Type',
            businessId: 'Business ID',
            merchant: 'Merchant(Device manufacturer)',
            owner: 'Owner(Distributors and end owners, etc)',
            ownerTip: 'Please enter merchant ID',
            agree: 'I agree',
            protocol: 'Platform Service & Privacy Policy',
            bindEmail: 'Bind email',
            skipEmail: 'Skip email',
            info: {
                mobile: 'Mobile',
                smsCode: 'Code',
                username: 'Username',
                userPwd: 'User Password',
                password: 'Password',
                confirmPassword: 'Confirm',
                email: 'Email',
                emailCode: 'Verify Code',
                flag: 'Flag'
            },
            tips: {
                phoneUnRegist: 'Mobile number not registered',
                userType: 'User type is required',
                businessId: 'Please enter merchant ID',
                limitMerchantRegister: "This role is temporarily closed. Please send an email to zws{'@ '}zlg.cn if necessary.",
                username: 'Please input username',
                password: 'Please input password',
                confirmPassword: 'Please confirm password',
                email: 'Please input email',
                getCodeSuccess: 'Successfully obtained mobile phone verification code',
                getEmailCodeSuccess: 'Succeeded in obtaining mailbox verification code',
                checkAgreement: 'Please read and check the cloud platform service and privacy agreemen',
                success: 'Register successfully',
                subSuccess: 'Hello! Your account has been successfully registered. You can log in and try it now.',
                userSuccess: 'Hello! Your account registration has been submitted, and you can log in successfully after being approved by the administrator.',

                successThank: 'Thank you very much for registering the cloud platform account, we will call you in 1~3 working days to review, please pay attention to answer.  If you have questions about the progress of your account, you can also directly call 0202815699 to transfer extension 8919 for consultation, or contact Zhiyuan Electronic sales/technical support. ',

                "backToLogin": "Back To Login",
            }
        },

        ZPersonalInformation: {
            personalInformationTabTitle: 'Personal Information',
            edit: 'Edit',
            editUserInformation: 'Edit user information',
            name: 'Name',
            uid: 'UId',
            company: 'Company',
            industry: 'Industry',
            department: 'Department',
            position: 'Position',
            country: 'Country',
            address: 'Address',
            namePlaceholder: 'Please enter your real name',
            companyPlaceholder: 'Please enter company name',

            industryPlaceholder: 'Please enter your Industry',
            departmentPlaceholder: 'Please enter department name',
            positionPlaceholder: 'Please enter job name',
            addressPlaceholder: 'Please enter company address',
            accountSecurityTabTitle: 'Account Security',
            changePassword: 'Change Password',
            changePasswordSucced: 'Change Password Successfully',
            currentPassword: 'Current password',
            newPassword: 'New password',
            confirmPassword: 'Re Password',
            passwordPlaceholder: 'Please input a password',
            currentPasswordPlaceholder: 'Please enter the current password',
            newPasswordPlaceholder: 'Please input a new password',
            confirmPasswordPlaceholder: 'Please enter the new password again',
            userAuthentication: 'User Authentication',
            userPhoneAuthentication: 'Verify Current Phone Number',
            changeMobile: 'Modify Mobile Number',
            changeMobileSucced: 'Modify mobile number successfully',
            changeEmail: 'Modify Email',
            changeEmailSucced: 'Modify email successfully',
            newMobilePlaceholder: 'Please input a new mobile number',
            accountSecurityTips: 'After the first successful binding of the mailbox, an additional 2 months of  designer life can be obtained.',

            "username": "UserName",
            "mobile": "Mobile",
            "smscode": "Sms Code",
            "getSmscode": "Get Sms Code And Fill In Verification Code",
            "password": "Password",
            "confirmPassword": "Re Password",
            "nickname": "Nickname",
            "gender": "Gender",
            "signature": "Signature",
            "coLtd": "Company",
            "company": "Inc.(Univ)",
            "trade": "Industry",
            "department": "Department",
            "position": "Position",
            "country": "Country/region",
            "address": "Address",
            "detailedAddress": "Detailed Address",
            "cannotBeEmpty": " can not be empty",
            sysHelp: 'Sys Help',
            male: 'Male',
            female: 'Female'
            ,
            genderScope: [
                {
                    value: "男",
                    label: "Male",
                },
                {
                    value: "女",
                    label: "Female",
                },
            ],
            consistOfChars: '2-32 chars，consist of Chinese、 Characters、Numbers、_',
        },
        ZButtonListCtrl: {
            confirm: 'Confirm',
            cancel: 'Cancel'
        },
        ZSelectWithInputCtrl: {
            pleaseSelect: 'please select',
            custom: 'custom'
        },
        ZActiveRenderCtrl: {
            pleaseSelect: 'please select',
            noData: 'no data',
            yes: 'yes',
            no: 'no',
            pleaseEnterInteger: 'please enter integer',
            pleaseEnterPositiveInteger: 'please enter positive integer',
            pleaseEnterNumber: 'please enter number',
            pleaseEnterTheCorrectContent: 'please enter the correct content'
        },
        ZAreaMobileInputCtrl: {
            enterMobilePhone: "Enter your mobile phone",
            selectArea: "Please select an area code"
        },
        ZCountDownCtrl: {
            getCode: "Get code",
            resend: "resend"
        },
        ZTreeCtrl: {
            input: "Please input"
        },
        ZDatePickerCtrl: {
            lasthour: "Last hour",
            today: "Today",
            lastweek: "Last week",
            lastMonth: "Last month",
            lastThreeMonths: "Last three months",
            lastSixMonths: "Last six months",
            nearlyYear: "Nearly year",
            startDate: "Start date",
            endDate: "End date",
            to: "To"
        },
        ZStep: {
            done: 'Done',
            wait: 'Wait',
            total: 'Total'
        },
        ZRecall: {

            "sureDelete": "Sure to delete?",            
            sureClose: "Sure to close?",
            fileBack: 'File Recall',
            recallProgress: "Recall Progress",
            orderSend: 'Order Send',
            orderSending: 'Order Sending',
            fileFetch: 'File Fetch',
            fileFetching: 'File Fetching',

            document: 'Document',
            size: "Size",            
            receivedSize: 'Received Size',
            fileSize: 'File Size'

        },
        ZFirmware: {
            firmwareVersion: 'Version',
            firmwareType: 'Firmware Type',
            "deleteFirmware": "Sure to delete firmware",
            deleteFirwareSuccess: " Delete firmware successfully",
            deleteFirwareFail: "Delete firmware failed",

            editSuccess: 'Edited successfully',
            "selfFirmware": "Self Firmware",
            "outerFirmware": "Outer Firmware",
            issueUpdateSuccess: 'Notify upgrade successfully. Please wait for the device to upgrade automatically',
            addScheduUpdateSuccess: 'Add schedued update task successfully',
            addScheduUpdateFail: 'Failed to add schedued update task',


            "firmwareUpgrade": "Firmware Upgrade",

            "upgradeVersion": "Upgrade Version",
            "upgradeScope": "Upgrade Scope",
            "upgradeTarget": "Upgrade Target",
            "upgradeTargetNotNull": "Upgrade target cannot be empty",
            upgradeExtraInfo: 'Upgrade ExtraInfo',
            "upgradeWay": "Upgrade Way",
            "upgradeImmediate": "Upgrade Immediatelly",
            "upgradeScheduled": "Upgrade Scheduled",
            "upgradeTime": "Upgrade Time",
            upgradeTimeNotNull: "Upgrade Time cannot be empty",
            "upgradeDiff": "Differential",
            softGroup: 'Soft Group',
            typeGroup: 'Type Group',
            singleDevice: 'Single device',
            gateway: 'Gateway',
            gatewayGroup: "Gateway Group",


            addFirmwares: "Add Firmware",
            uploadFirmware: "Firmware",
            pleaseInputVersion: 'Please input version',
            tipsForVersionFormat: 'Please input xx.xx.xx （xx is 1-2 numbers）',
            "descNotNull": "Description cannot be empty",
            description: 'Description',
            descriptionInput: "Enter firmware description information",
            chooseFile: "Select File",
            "firmwareFileNotNull": "Firmware file cannot be empty",
            "firmwareVersionNotNull": "Firmware version cannot be empty",
        },
        ZFileSelectorCtrl: {
            browser: "Browser",
            upload: "Upload",
            onlySupport: 'Only support ',

            chooseFile: "Select File",
            formatAfter: " format",

            fileSizeSmaller: 'File size smaller than ',
        },
        ZFileUploaderCtrl: {
            fileNotEmpty: 'File can not be empty',
            uploadFileSuccess: 'Upload file successfully',
            uploadFailError: 'Upload file fail,error',
            deleteFileSuccess: 'Delete file successfully',
            deleteFailError: 'Delete file fail,error',
        },
        ZBaiduMapCtrl: {
            searchAddrPlaceholder: 'Please input address'
        },
        ZCheckTablePaginationCtrl: {
            showAll: 'Show All',
            showSelected: 'Show Selected'
        },
        ZTablePaginationCtrl: {

            over10thou: 'The data has exceeded 10000 entries, please provide precise query criteria',
            saveSuccessfully: "Save Successfully",
            saveMax: "Save a maximum of 30 option points",
            search: "Search",
            save: "Save",
            noData: "No data"
        },

        ValidableInput: {
            enterCorrectHexData: 'Please enter the correct hexadecimal data',

            consistOfCharsNum: 'consist of Characters、Numbers',
            consistOfChars: 'consist of Characters、Numbers、_',

            pleaseEnterNumber: 'please enter number',
            minValue: 'min value is ',
            maxValue: 'max value is ',
            pleaseInput: 'Please input',
            countChars: ' chars',
            pleaseInputVersion: 'Please input version',
        },

        ZDevInfoLabelsArea: {
            selectLabel: 'Select label',
            inputLabel: 'Input label',
        },
        CommonValidTipOrText: {
            usernameEmptyTip: 'User name or area code plus mobile phone number cannot be empty',

            addZoneForPhone: 'Add zone for phone，like 86187xxxxxxxx',
            usernameValidTip: "Please enter 2-65 digits, which can only contain {'@'} alphanumeric underscore",
            phoneValidTip: 'Please enter the mobile phone number with correct digits, which can only be numbers',
            phoneValiErr: 'The mobile phone number is wrong, please enter it again',
            pwdValidTip: 'Please enter 8-16 digits and cannot have spaces or Chinese characters',
            pwdEmptyTip: 'Password cannot be empty',
            smsLogin: 'SMS login',
            pwdLogin: 'password login',
            newPwdValidTip: 'Please enter 8-16 digits and cannot have spaces or Chinese characters',
            pwdNotSameTip: 'The passwords entered two times are inconsistent',
            phoneEmptyTip: 'Mobile phone number cannot be empty',
            confirmPwdEmptyTip: 'Confirm password cannot be empty',
            getPhoneCodeTip: 'Successfully obtained mobile phone verification code',
            sixNumValidTip: 'Please enter 6-digit verification code',
            resetPwdTip: 'Password reset succeeded',
            forgotPwd: 'Forgot password',
            phone: 'phone number',
            verificationCode: 'Code',
            password: 'Password',
            confirmPwd: 'Confirm',
            inputNewPwd: 'Please enter a new password',
            inputNewPwdAg: 'Please enter the new password again',
            resetPwd: 'Reset password',
            clickHereToReturn: 'Click here to return',
            login: 'login',
            username: 'user name',
            inputUsernameTip: 'enter one user name',
            inputPwdTip: 'Please input a password',
            rememberPwd: 'Remember password',
            noAccountTip: 'No account? Register now',
            logining: 'Signing in',
            register: 'register',
            help: 'help',
            thirdAaccountLogin: 'Third-party account login'
        },
        ZDataTransformDialogArea: {
            cancelDownload: 'Sure to cancel download',
            statusIni: 'Initing',

            statusQuery: 'Querying',

            statusProcess: 'Processing',

            statusGenerate: 'Generating',

            statusDone: 'Done',
        },
        ZHeaderArea: {
            lowCode: 'IoT Low Code Platform',
            monitor: 'Monitor screen',
            help: 'Help',
            userCenter: 'User Center',
            sysSetting: 'System Setting',
            logout: 'Logout',

        },
        ZUserCenterArea: {
            capacityInfo: 'Capacity Info',
            personalInfo: 'Personal Info',
            accountSecurity: 'Account Security',

        },
        ZDiskManagementArea: {
            beenUsed: 'Have been used',
            upperLimit: 'Upper limit',
            showDetails: 'Show details',
            canDevNum: 'CAN Device Num.',
            iesDevNum: 'IES Device Num.',
            iotDevNum: 'IoT Device Num.',
            dataStorageSpace: 'Data Storage Space',
            limitNumDown: 'Download the limit number',
            storageCycle: 'Storage cycle',
            maxRecallFileSize: 'Maximum recall file size',
            maxBatchRecallSize: 'Maximum number of recalled files',
            quickDelDevNum: 'Number of quick-delete data device',
            dataStorageService: 'Data storage service',
            months: 'Months',
            tenThousand: 'Ten thousand',
            days: ' Days',
            ge: '',
            tiao: '',
            fileDownloadProgress: 'The file download progress is displayed',
            attention: 'attention',
            deleteDataTo: 'Delete data to',
            irreversible: 'irreversible',
            passwordEntered: 'Once the user password is entered, the operation cannot be terminated. Even if the operation is canceled, the data will continue to be cleared. Please operate with caution! ',
            loading: 'Loading',
            from: 'from',
            createTask: 'note wait until the download process completes and create a new download task. ',
            tip: 'Tips',
            loopDelete: 'Delete the loop when the space is full',
            dataStorage: 'Data storage service',
            general: 'General practice',
            only: 'user-only',
            deviceFastDelete: 'Device fast delete',
            fileName: 'file name',
            storageStartTime: 'Storage start time',
            storageEndTime: 'Storage end time',
            download: 'download',
            delete: 'delete',
            deviceDeleteSuccess: 'The device data is deleted successfully. Procedure',
            deviceDeleteFail: 'Failed to delete device data. Procedure',
            to: 'to',
            downloadFail: 'Failed to download device data',
            notSupportedFormat: 'This format is not supported. Please select another format',
            close: 'close',
            showDownload: 'The download progress is displayed',
            deviceDownloadSuccess: 'The data file of the device is downloaded successfully',
            cancelDownloadSuccess: 'Failed to download the data file of the device.',
            switchSuccess: 'Failed to switch the automatic deletion status.',
            data: 'data',
            cancelDownload: 'Cancel download',
            hide: 'hide',
            initialize: 'initialize',
            dataCollation: 'Data collation',
            finish: 'finish',
            query: 'In the query',
            formation: 'In formation',
            waitDownload: 'Waiting to download',
            cancelDownload: 'User cancels download',
            compressing: 'Be compressing',
            downloadProcess: 'Download progress',
            storage: 'storage',
            deviceFastDeleteValue: 'Device data is stored in an independent database by device.',
            deviceFastDeleteTips: 'It is suitable for scenarios where the number of devices is small but the data volume of a single device is large.  If a user has five candtu devices under their name, a single device can store 80 million pieces of data in one day. ',
            deviceFastDeleteStrong: 'Quickly clear all data from a single device to free up space immediately. ',
            generalValue: 'Device data is stored in the unified management of the public database, supporting routine query and deletion operations. ',
            generalTips: 'It is suitable for users with a small number of devices and a small amount of data on a single device.  If the number of devices under a user name is less than 1000, the interval between the devices reporting a data message is more than 10 minutes. ',
            onlyValue: 'The data of all the devices of the user is stored in multiple databases independently by the user. ',
            onlyTips: "Suitable for users who need automatic cycle deletion function, or large customers with a large number of devices and a large amount of data, you can easily back up and delete early data, such as a user name with 20,000 devices, 1 piece of data per minute. ",
            onlyStrong: "When the user's storage space is full, the function of automatically deleting early data can be realized. ",
            cyclicDeletionTips: "If cyclic data deletion is enabled, the system automatically deletes data in a cycle without sending a data deletion alarm message when the data is full. ",
            showTipsTitle: "Description of the three data storage modes",
            featureIntroduction: "Feature introduction:",
            applicableScene: "applicable scene:",
            selected: 'Selected equipment',
            selectDevice: 'Choice Device',
            iotTip: 'Note: Only common IoT devices are included, excluding CAN devices and energy storage devices'
        },
        ZUserInfoArea: {
            lessThan2M: 'Pictures cannot be larger than 2M',
            updateUserSuccess: 'User information is updated successfully. Procedure',
            updateAvatarS: 'Update profile picture successfully',
        },
        ZCapacitystatisticsTableArea: {
            notQuickDel: 'The device does not support the quick deletion function, please go to the ',
            userCenter: 'User Center',
            updatePlan: ' to upgrade the plan',
            attention: 'matters need attention:',
            emptyHistory: 'This operation will empty the historical stored data of the device, and empty it into an ',
            irreversibleOp: 'irreversible ',
            cantCancel: 'operation. Once the user password is confirmed, it cannot be terminated. Even if it is cancelled, the data will continue to clear. Please operate carefully!',
            emptyFail: 'Failed to empty the device data',
            order: 'Order',
            devName: 'Device Name',
            devType: 'Device Type',
            devId: 'Device ID',
            usedCapacity: 'Used Capacity',
            updateTime: 'Update Time',
            getDevFail: "Failed to get the device list",

        },
        ZComboFormArea: {
            devClass: 'Device Class',
            canDev: 'CAN Device',
            iotDev: 'I oT Device(not CAN)',
            currentPackage: 'Current Package',
            upgradeTo: 'Upgrade to',

        },
        ZAsideArea: {
            showAside: 'Show the sidebar',
            hideAside: 'Hide the sidebar',

        },
        ZUserMessageIconArea: {
            unread: 'Unread',
            allRead: 'Read All',
            allDelete: 'Delete All',
            noData: 'No Data',
            message: 'Message',
            getMessageFail: 'Failed to obtain the user message.',
            sureAllDelete: 'Are you sure to delete all messages?',
            sureAllRead: 'Are you sure to mark all messages as read?',
            close: 'close'
        }
    },

}