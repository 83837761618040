import { useRoute, useRouter } from "vue-router"
export const useZThirdLoginHook = (ZWSApi: any = window.ZWSApi) => {

    const getValueFromHref = (key: string) => {
        const search = window.location.search.split('?')[1]
        const hash = window.location.hash.split('?')[1]
        const searchQuery = search?.split('&') || hash?.split('&')
        let value = ''
        if (searchQuery) {
            const valueQuery = searchQuery.find((item: string) => item.indexOf(`${key}=`) !== -1)
            if (valueQuery) {
                value = valueQuery.split('=')[1].replace('/', '')
            }
        }
        return value
    }

    const getUjwtFromHref = () => {
        return getValueFromHref('ujwt')
    }

    const loginWithUjwt = (ujwt: string) => {
        return new Promise((resolve, reject) => {
            ZWSApi.ZWSControl.session_addSessionsForTenantManager({
                ujwt
            }).then((res: any) => {
                resolve(res)
            }).catch((err: any) => {
                reject(err)
            })
        })
    }

    const getTokenFromHref = () => {
        return getValueFromHref('token')
    }

    const useThirdLogin = () => {
        return new Promise((resolve, reject) => {
            const ujwt = getUjwtFromHref()
            const token = getTokenFromHref()
            const jwt = getValueFromHref('jwt')
            const isManger = getValueFromHref('isManager')
            if (ujwt.length > 0 && ujwt !== 'undefined') {
                loginWithUjwt(ujwt).then((res: any) => {
                    resolve(res)
                }).catch((err: any) => {
                    reject(err)
                })
            } else if (token && jwt && isManger) {
                resolve({
                    token,
                    ujwt: '',
                    isManger: isManger === 'true'
                })
            } else if (token) {
                resolve({
                    token: token,
                    ujwt: ''
                })
            } else {
                reject()
            }
        })
    }

    const checkFromThird = () => {
        const ujwt = getUjwtFromHref()
        const token = getTokenFromHref()
        return !!ujwt || !!token
    }

    const currencyRouter = useRouter()
    const getPreQuery = () => {
        const list: Array<string> = []
        const search = window.location.search.split('?')[1]
        const hash = window.location.hash.split('?')[1]
        const searchQuery = search?.split('&') || hash?.split('&')
        if (searchQuery) {
            searchQuery.forEach((item: string) => {
                if (item.indexOf('token=') === -1 && item.indexOf('ujwt=') === -1 && item.indexOf('loginUrl=') === -1 && item.indexOf('belongPlatform=')) {
                    list.push(item)
                }
            })
        }
        console.log(list, 'list');

        return encodeURIComponent(list.join('&'))
    }
    const getQueryStrToObj = (query: string) => {
        const list = decodeURIComponent(query).split('&')
        const obj = {}
        list.forEach((item: string) => {
            const itemSplit = item.split('=')
            obj[itemSplit[0]] = obj[itemSplit[1]]
        })
        return obj
    }
    const useThirdLoginInterceptor = ({ router, path, }: {
        router?: any,
        path: string,
    }) => {
        if (checkFromThird()) {
            const token = getTokenFromHref()
            const ujwt = getUjwtFromHref()
            const loginUrl = getValueFromHref('loginUrl')
            const belongPlatform = getValueFromHref('belongPlatform')
            const routerInst = router ? router : currencyRouter
            if (loginUrl) {
                sessionStorage && sessionStorage.setItem('loginUrl', loginUrl)
            }
            const prePath = encodeURIComponent(window.location.hash.split('?')[0].replace('#', ''))
            console.log(getPreQuery(), 'getPreQuery');

            if (window.location.search) {
                // 当参数出现在 search 的时候，如不调整 url 结构会导致ujwt或token常驻 url 中
                const query = `token=${token}&ujwt=${ujwt}&prePath=${prePath}&preQuery=${getPreQuery()}&loginUrl=${loginUrl}&belongPlatform=${belongPlatform}`
                window.location.replace(`${window.location.origin}${window.location.pathname}#${path}?${query}`)
            } else {
                routerInst.replace({
                    path: path,
                    query: {
                        token,
                        ujwt,
                        loginUrl: loginUrl,
                        belongPlatform: belongPlatform,
                        prePath: prePath,
                        preQuery: getPreQuery()
                    }
                })
            }
        }
    }

    return {
        getUjwtFromHref,
        loginWithUjwt,
        useThirdLogin,
        getTokenFromHref,
        checkFromThird,
        getQueryStrToObj,
        useThirdLoginInterceptor
    }
}