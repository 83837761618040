import { computed, ref, watch } from 'vue'
import { useI18n, createI18n } from 'vue-i18n'
import zh from '../locale/zh'
import en from '../locale/en'

var localeWatcher = false

const i18n = createI18n({
  locale: 'zh',
  messages: {
    zh: zh as any,
    en: en as any
  }
})

export const setLanguage = (lan: string) => {
  i18n.global.locale = lan
}

// let curLocale: any = ref(en)
export const useLocale = (lan?: string) => {
  const { t, locale } = useI18n()

  // 由于 i18n.global 获取的是该库中的 i18n 实例，所以当外部全局的 locale 变化时本地的 i18n.global 不会响应式更新语言，
  // 所以这里要手动设置本地 i18n.global 的语言
  if (!localeWatcher) {
    watch(
      locale,
      l => {
        setLanguage(l)
      },
      {
        immediate: true
      }
    )
    localeWatcher = true
  }

  return i18n.global as any
}

// export const setLocale = (name: string) => {
//     if (name === 'zh') {
//         curLocale.value = zh
//     } else if (name === 'en') {
//         curLocale.value = en
//     }
// }
