 
<template>
        <div class="transfer">
          <div class="leftTransfer">
            <div class="leftTransfer_title">
              <el-checkbox
                :indeterminate="left.isIndeterminate"
                v-model="left.checkAll"
                @change="leftHandleCheckAllChange"
                >{{ titles[0] }}</el-checkbox
              >
              <span
                >{{ left.transferDataChecked.length }}/{{
                  left.transferData.length
                }}</span
              >
            </div>
            <!-- 注释掉搜索  -->
            <el-input
              @input="transferInputLeft"
              class="transfer_input" 
              :placeholder="(getLanguage('zws.ZCommon.inputContent')||'请输入内容')"
              prefix-icon="el-icon-search"
              v-model="left.inputValue"
            >
            </el-input>
            <div style="margin: 5px 0"></div>
            <el-checkbox-group
              class="transfer_group"
              v-model="left.transferDataChecked"
              @change="leftHandleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="item in left.transferData"
                :label="item" :disabled="item.disabled"
                :key="item[defaultProps.id]"
                >{{ item[defaultProps.label] }}</el-checkbox
              >
            </el-checkbox-group>
            <ZPaginationCtrl v-model="tablePags" @paginationChange="paginationChange"></ZPaginationCtrl>
            
          </div>
          <div class="transferButton">
            <el-button type="primary" @click="push">></el-button>
            <el-button type="primary" @click="del">{{ "<" }}</el-button>
          </div>
          <div class="rightTransfer">
            <div class="rightTransfer_title">
              <el-checkbox
                :indeterminate="right.isIndeterminate"
                v-model="right.checkAll"
                @change="rightHandleCheckAllChange"
                >{{ titles[1] }}</el-checkbox
              >
              <span
                >{{ right.transferDataChecked.length }}/{{
                  right.transferData.length
                }}</span
              >
            </div>
            <!-- <el-input
              @input="transferInputRight"
              class="transfer_input"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="right.inputValue"
            >
            </el-input> -->
            <div style="margin: 5px 0"></div>
            <el-checkbox-group
              class="transfer_group group-right"
              v-model="right.transferDataChecked"
              @change="rightHandleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="item in right.transferData"
                :label="item"
                :key="item[defaultProps.id]"
                >{{ item[defaultProps.label] }}</el-checkbox
              >
            </el-checkbox-group> 
            <!-- <ZPaginationCtrl v-model="tablePags_" @paginationChange="paginationChange_"></ZPaginationCtrl> -->
            
          </div>
        </div>
      </template>
       
      <script>
      import ZPaginationCtrl from './ZPaginationCtrl.vue'
      import { useLocale } from '../hooks/useLocale'
      export default {
        components: {
                ZPaginationCtrl,
    },
        name: "transferPage",
        props: {
          value: {
                type: Object,
                default: [
                        
                ]
          },   
          data: {
                type: Object,
                default: [
                        
                ]
          },
          leftDefaultChecked: {
                type: Object,
                default: []
          },
          titles: {
            type: Array,
            default: () => [],
          },
          clearUpdate: {
            type: Number,
            
          },
          customLabel: {
            label: "name",
            id: "identifier",
          }
        },
        data() {
          return {
                tablePags: {small: true,layout: "prev, pager, next",pagerCount:4,pageSize:20},
                tablePags_: {small: true,layout: "prev, pager, next",pagerCount:4,pageSize:20},

                dataListParams: {
                current_page: 1,
                
                page_size: 20,
                
            },
            arrR: [],
            left: {
              inputValue: "",
              isIndeterminate: false, //半选按钮
              checkAll: false, //是否点击全选
              transferData: [], //穿梭框左侧全部数据
              transferDataAll: [], //穿梭框左侧全部数据记录
              transferDataChecked: [], //穿梭框左侧选中数据
            },
            right: {
              inputValue: "",
              isIndeterminate: false, //半选按钮
              checkAll: false, //是否点击全选
              transferData: [], //穿梭框右侧全部数据
              transferDataAll: [], //穿梭框左侧全部数据记录
              transferDataChecked: [], //穿梭框右侧选中数据
            },
            total: 0,
            queryParams: {
              current: 1,
              size: 10,
            },
            defaultProps: {
              label: "name",
              id: "identifier",
            },
          };
        },
        created() {
          this.initTransferData();
        },
        methods: {
          getLanguage  (data, any) {
            let { t } = useLocale()
            return t(data)
          },
          getDataMethod(dataListParams, any) {
       
                var skip = (dataListParams.current_page-1) * dataListParams.page_size
                let dat = this.data.slice(skip, skip + dataListParams.page_size)
                dat = dat.filter((item) => {
                   return !this.value.includes(item[this.defaultProps.id])
                   
                })
                var data1 = this.left.transferData
                data1.splice(0, data1.length, ...dat)
                 
        },
          paginationChange(value, any) {
                this.dataListParams.current_page = value.currentPage
                this.dataListParams.page_size = value.pageSize
                this.getDataMethod(this.dataListParams)
           
          },
          // 初始化数据
          initTransferData() {
              this.getDataMethod(this.dataListParams)
               
              this.left.transferData.map((item, index) => {
                item.current = this.queryParams.current;
              });
              this.total = this.data.total;
              this.left.transferDataAll = this.left.transferData;
              // 获得一个右边的数据，只有纯id
              let checkedIdArr = this.right.transferData.map((item) => {
                return item[this.defaultProps.id];
              });
              // 左侧数据删除
              this.left.transferData = this.left.transferData.filter((item) => {
                if (!checkedIdArr.includes(item[this.defaultProps.id])) {
                  return item;
                }
              });
            
          },
          // 左侧是否点击全选
          leftHandleCheckAllChange(val) {
            this.left.transferDataChecked = val ? this.left.transferData : [];
            this.left.isIndeterminate = false;
          },
          // 左侧勾选数据
          leftHandleCheckedCitiesChange(value) {
                
            let checkedCount = value.length;
            this.left.checkAll = checkedCount === this.left.transferData.length;
            this.left.isIndeterminate =
              checkedCount > 0 && checkedCount < this.left.transferData.length;
              let valueArr = value.map((item) => {
              return item[this.defaultProps.id];
            });
              this.$emit('left-check-change', valueArr  )
          },
          // 左侧是否点击全选
          rightHandleCheckAllChange(val) {
            this.right.transferDataChecked = val ? this.right.transferData : [];
            this.right.isIndeterminate = false;
          },
          // 左侧勾选数据
          rightHandleCheckedCitiesChange(value) {
                
            let checkedCount = value.length;
            this.right.checkAll = checkedCount === this.right.transferData.length;
            this.right.isIndeterminate =
              checkedCount > 0 && checkedCount < this.right.transferData.length;
          },
          // 穿梭框选中数据
          push() {
            // 数据移到右侧
            this.right.transferData = [
              ...this.right.transferData,
              ...this.left.transferDataChecked,
            ].sort((a, b) => {
              return a[this.defaultProps.id] - b[this.defaultProps.id];
            });
            this.right.transferDataAll = this.right.transferData;
            // 获得一个右边的数据，只有纯id
            let checkedIdArr = this.right.transferData.map((item) => {
              return item[this.defaultProps.id];
            });
            // 左侧数据删除
            this.left.transferData = this.left.transferData.filter((item) => {
              if (!checkedIdArr.includes(item[this.defaultProps.id])) {
                return item;
              }
            });
            this.left.transferDataAll = this.left.transferData;
            // 每次推送完之后，重置选中框
            this.left.transferDataChecked = [];
            this.left.isIndeterminate = false;
            this.left.checkAll = false;
          },
          // 穿梭框删除数据 回显的时候根据current回显到当前
          del() {
            //过滤当前页选中的数据
            let arr = this.right.transferDataChecked.filter((item) => {
              if (this.queryParams.current == item.current) {
                return item;
              }
            });
            // 数据移到左侧
            this.left.transferData = [...this.left.transferData, ...arr].sort(
              (a, b) => {
                return a[this.defaultProps.id] - b[this.defaultProps.id];
              }
            );
            this.left.transferDataAll = this.left.transferData;
            // 获得一个右边的数据，只有纯id
            let checkedIdArr = this.right.transferDataChecked.map((item) => {
              return item[this.defaultProps.id];
            });
            // 删除选中的数据
            this.right.transferData = this.right.transferData.filter((item) => {
              if (!checkedIdArr.includes(item[this.defaultProps.id])) {
                return item;
              }
            });
            this.right.transferDataAll = this.right.transferData;
            // 每次推送完之后，重置选中框
            this.right.transferDataChecked = [];
            this.right.isIndeterminate = false;
            this.right.checkAll = false;
          },
          handleCurrentChange(val) {
            this.queryParams.current = val;
            this.initTransferData();
          },
          transferInputLeft() {
            this.queryParams.current = 1
            this.$emit('left-search-change', this.left.inputValue  )
          },
          transferInputLeft_() {
            if (this.left.inputValue) {
              this.left.transferData = this.left.transferDataAll.filter(
                (p) => p[this.defaultProps.label].indexOf(this.left.inputValue) !== -1
              );
            } else {
              this.left.transferData = this.left.transferDataAll;
            }
          },
          transferInputRight() {
            if (this.right.inputValue) {
              this.right.transferData = this.right.transferDataAll.filter(
                (p) =>
                  p[this.defaultProps.label].indexOf(this.right.inputValue) !== -1
              );
            } else {
              this.right.transferData = this.right.transferDataAll;
            }
          },
          initTransferDataRight(){
               
                var arr = this.data.filter((item) => {
                return this.value.includes(item[this.defaultProps.id])
              });
                this.right.transferData = arr//.splice(0, this.right.transferData.length, ...arr)
          }
        },
        watch: {
            customLabel: {
            handler(newVal) {
                this.defaultProps.label = newVal.label || this.defaultProps.label
                this.defaultProps.id = newVal.id || this.defaultProps.id
            },
            deep: true,
            setImmediate: true
            },
            clearUpdate() {
              this.left.inputValue = ''
              this.right.inputValue = ''
            },
            value: {
            handler(newVal) { 
                
                this.initTransferDataRight()
                
            },
            deep: true,
            setImmediate: true
        },
        data: {
            handler(newVal) {
                this.tablePags.total = newVal.length
                
                this.initTransferData()
                this.initTransferDataRight()
            },
            deep: true
        },
        right: {
            handler(newVal) {
                this.oldArr = this.arrR
                var arr = this.right.transferData.map((item) => {
                return item[this.defaultProps.id]
              });
              this.arrR = arr
              
              
            },
            deep: true
        },
        arrR: {
            handler(arr,old) {
               
                if(this.oldArr.length == this.arrR.length) {
                        return
                }
                this.$emit('update:value', arr  )
              
              setTimeout(()=>{

                this.$emit('left-check-change', []  )
                
              },200)
            },
            deep: true
        },
    },
      };
      </script>
       
      <style lang="scss" scoped>
      .transfer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        .transferButton {
          .el-button {
            display: block;
            margin: 10px 0;
          }
        }
        .el-checkbox {
          line-height: 30px;
        }
        .transfer_input {
          margin: 10px 15px 0 15px;
          width: 85%;
          .el-input--medium .el-input__inner {
            height: 36px;
            line-height: 36px;
            border-radius: 16px;
          }
        }
        .leftTransfer,
        .rightTransfer {
          height: 425px;
          border: 1px solid #e6ebf5;
          border-radius: 4px;
          overflow: hidden;
          background: #ffffff;
          display: inline-block;
          vertical-align: middle;
          width: 250px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          position: relative;
          margin: 0 0px;
       
          .el-checkbox {
            display: block;
          }
          .pagination {
            position: absolute;
            bottom: -8px;
            right: 0;
          }
          .leftTransfer_title,
          .rightTransfer_title {
            display: flex;
            justify-content: space-between;
            height: 40px;
            line-height: 40px;
            background: #f5f7fa;
            margin: 0;
            padding-left: 15px;
            border-bottom: 1px solid #e6ebf5;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: #000000;
            padding-right: 15px;
            align-items: center;
          }
        }
      }
      .transfer_group {
        padding-left: 15px;
        height: 285px;
        overflow: auto;
      }
      .group-right.transfer_group {
        height: 365px;
      }
      :deep(.pagination-wrapper) {
        border-left: 0px;
        border-right: 0px;
      }
      .transfer_input :deep(.el-input__inner) {
        border:1px solid #e6ebf5!important;
      }
      </style> 