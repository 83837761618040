<template>
    <el-button class="z-countdown-button" @click="getVerificationCodeClicked" :disabled="isSendCodeValue">{{ tips }}</el-button>
</template>
<script lang="ts">
import { defineComponent, watch, ref, computed } from 'vue'
import { useLocale } from '../hooks/useLocale'
export default defineComponent({
    props: {
        isSendCode: {
            type: Boolean,
        },
    },
    setup(prop, context) {
        let { t } = useLocale()
        let isSendCodeValue: any = ref(false)
        let timeValue: any = ref(60)
        let intervalNumber: any = undefined
        const tips = computed(() => `${!isSendCodeValue.value ? t('zws.ZCountDownCtrl.getCode') : (t('zws.ZCountDownCtrl.resend') + `(${timeValue.value})`)}`)

        watch(
            () => prop.isSendCode,
            (newValue, oldValue) => {
                isSendCodeValue.value = newValue
                if (intervalNumber) {
                    window.clearInterval(intervalNumber)
                }
                if (newValue) {
                    intervalNumber = setInterval(() => {
                        if (timeValue.value === 0) {
                            clearInterval()
                            return
                        }
                        timeValue.value--
                    }, 1000)
                }
            }
        )
        function clearInterval() {
            isSendCodeValue.value = false
            context.emit('update:isSendCode', isSendCodeValue.value)
            timeValue.value = 60
            window.clearInterval(intervalNumber)
        }
        function getVerificationCodeClicked() {
            context.emit('getVerificationCodeClicked')
        }
        return {
            tips,
            isSendCodeValue,
            timeValue,
            intervalNumber,
            clearInterval,
            getVerificationCodeClicked,
        }
    },
})
</script>

<style lang="less" scoped>
.z-countdown-button.el-button {
    width: 95px;
    height: 40px !important;
    text-align: center;
    padding: 0px;
    font-size: 14px;
}
</style>
