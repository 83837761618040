/**
 * uds诊断
 */
export default {
  delayMS: '延时（ms）',
  sendCommandError: '命令发送错误',
  unitMillisecond: '单位：毫秒',
  delay: '延时',
  pleaseInputName: '请输入名称',
  editCommand: '编辑指令',
  stopSend: '停止发送',
  listSend: '列表发送',
  addDelay: '添加延时',
  addCommand: '添加指令',
  debugInfo: '调试信息',
  pleaseInputSingleByteHex: '请按单字节输入16进制数并用空格作为分隔',
  pleaseInputHexOrSpace: '请输入16进制数或空格',
  requestDelay: '请求PDU / 延时（ms）',
  name: '名称',
  bindUpgradeChannelFailed: '绑定升级通道失败，{error}',
  pleaseSelectECU: '请选择ECU',
  responseAddrOutOfRange2: '响应地址超出7FF的标准帧地址范围',
  physicalAddrOutOfRange2: '物理地址超出7FF的标准帧地址范围',
  functionAddrOutOfRange2: '功能地址超出7FF的标准帧地址范围',
  responseAddressOutOfRange: '响应地址超出1FFFFFFF的扩展帧地址范围',
  physicalAddrOutOfRange: '物理地址超出1FFFFFFF的扩展帧地址范围',
  functionAddrOutOfRange: '功能地址超出1FFFFFFF的扩展帧地址范围',
  sendUDSRequestFailed: '发送UDS请求失败，{error}',
  genUDSCommandFailed: '生成UDS指令失败',
  suppressResponse: '抑制响应',
  functionAddress: '功能地址',
  physicalAddress: '物理地址',
  requestAddress: '请求地址',
  pleaseInputPDU: '请输入PDU',
  UDSDiag: 'UDS诊断',
  requestPDU: '请求PDU',
  diagServ: '诊断服务',
  paramConfig: '参数配置',
  tranConfig: '传输配置',
  proVersion: '协议版本',
  fillByte: '填充字节(hex)',
  fCTimeout: '流控超时(ms)',
  p2timeout: 'P2超时(ms)',
  p2_timeout: 'P2*超时(ms)',
  fillByteTips: '填充字节范围为0x00-0xFF',
  RemoteStMinTips: 'RemoteStMin范围为0ms-127ms',
  fCTimeoutTips: '流控超时(ms)范围为0-2147483647',
  p2timeoutTips: 'P2超时(ms)范围为0-2147483647',
  p2_timeoutTips: 'P2*超时(ms)范围为0-2147483647'
}
