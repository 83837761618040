<template>
  <div ref="textScrollRef" class="text-scroll-container" @mouseenter="handleMouseEnter" @mouseleave="handlemouseleave">
    <span class="text" ref="textRef">{{ text }}</span>
    <!-- 连续滚动 -->
    <span class="text" v-if="isScroll">{{ text }}</span>
  </div>
</template>

<script setup lang='ts'>
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
  // 显示的文字
  text: {
    type: String
  },
  // 滚动速度
  scroll: {
    type: Number,
    default: 0.5
  },
  // 鼠标悬浮文字停止滚动
  hoverStop: {
    type: Boolean,
    default: false
  }
})

const isScroll = ref<boolean>(false)
const textRef = ref<HTMLElement>()
const textScrollRef = ref<HTMLAreaElement>()

let _width: number = 0
let animationFrameId: number
const textScrollFunction = () => {
  if (textRef.value) {
    _width += 0.5
    if (_width > textRef.value.offsetWidth) _width = 0
    textRef.value.style.marginLeft = -_width + 'px'
  }
  animationFrameId = requestAnimationFrame(textScrollFunction)
}

const initScroll = () => {
  if (textRef.value && textScrollRef.value) {
    isScroll.value = textRef.value.offsetWidth > textScrollRef.value.offsetWidth
    textRef.value.style.marginLeft = '0px'
    if (isScroll.value) {
      setTimeout(() => {
        animationFrameId = requestAnimationFrame(textScrollFunction)
      }, 1000);
    }
  }
}

const handleMouseEnter = () => {
  if (props.hoverStop) {
    cancelAnimationFrame(animationFrameId)
  }
}

const handlemouseleave = () => {
  if (props.hoverStop && isScroll.value) {
    animationFrameId = requestAnimationFrame(textScrollFunction)
  }
}

onMounted(() => {
  initScroll()
})

watch(() => props.text, newVal => {
  if (newVal) {
    initScroll()
  }
})
</script>

<style lang='less' scoped>
.text-scroll-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
</style>