/**
 * 通用
 */
export default {
  trigger: {
    onlyOneCodeAllow: '只允许1个{code}',
    val1Val2: '[值1, 值2]',
    inputLen: '输入长度',
    condition: '条件',
    addAtLeastOneCondition: '至少添加1个条件',
    pleaseInputValidId: '请输入有效id值。标准帧ID0x0~0x7ff、扩展帧ID0x0~0x1fffffff',
    pleaseInputValidLen: '请输入有效长度值 0 ~ 64',
    betweenOperationFormat: '运算符 between 的输入格式参考：[0,5] 或 (0,5)',
    leftMustLowerThanRight: '左侧信号值不能大于右侧',
    signalValRange: '信号值有效范围',
    pleaseSelectSignal: '请选择信号',
    pleaseSelectOperator: '请选择运算符',
    ruleNameLenLimit: '规则名称长度在1到50个字符',
    dbcSignal: 'DBC信号',
    max1DBCSigCondition: '最多1个dbc信号条件',
    frameFormat: '帧格式',
    frameType: '帧类型',
    frameId: '帧ID',
    addTriggerCondition: '添加触发条件',
    triggerCondition: '触发条件',
    pleaseSelectCANChannel: '请选择CAN通道',
    triggerData: '触发数据',
    pleaseInputRuleName: '请输入规则名称',
    ruleName: '规则名称',
    editTriggerRule: '编辑触发规则',
    addTriggerRule: '添加触发规则'
  },
  calculate: '计算',
  pleaseInput: '请输入',
  deleteConfirm: '确定删除吗？',
  deleteSuccess: '删除成功',
  deleteFailed: '删除失败',
  startTime: '起始时间',
  endTime: '结束时间',
  custom: '自定义',
  uploadTime: '上传时间',
  ge: '个',
  enableSuccess: '启用成功',
  disableSuccess: '禁用成功',
  number: '数值',
  editFail: '编辑失败',
  editSuccess: '编辑成功',
  nameInUse: 'The name is already in use',
  addFail: '添加失败',
  addSuccess: '添加成功',
  data: '数据',
  dataName: '数据名称',
  length: '长度',
  process: '处理',
  unprocessed: '未处理',
  processed: '已处理',
  systemTime: '系统时间',
  downloadSuccess: '下载成功',
  saveSuccess: '保存成功',
  lastWeek: '最近一周',
  disable: '禁用',
  enable: '启用',
  exportSuccess: '导出成功',
  rawData: '原始数据',
  content: '内容',
  status: '状态',
  channel: '通道',
  export: '导出',
  import: '导入',
  fail: '失败',
  success: '成功',
  service: '服务',
  copySuccess: '复制成功',
  copy: '复制',
  addDataPointFirst: '自定义下载模板数据点不能为空,请先添加数据点',
  deviceTypeName: '设备类型名称',
  eventName: '事件名称',
  eventType: '事件类型',
  deviceTypeId: '设备类型ID',
  deviceId: '设备ID',
  tenantName: '租户名称',
  tenantId: '租户ID',
  suffix: '后缀',
  pleaseInputSuffix: '请输入后缀',
  pleaseInputCharacters10: '请输入最大长度为十个字符',
  prefix: '前缀',
  pleaseInputPrefix: '请输入前缀',
  binaryToHexadecimal: '二进制转十六进制',
  binaryToDecimal: '二进制转十进制',
  hexadecimalToBinary: '十六进制转二进制',
  hexadecimalToDecimal: '十六进制转十进制',
  decimalToBinary: '十进制转二进制',
  decimalToHexadecimal: '十进制转十六进制',
  format: '格式',
  pleaseSelectConversionFormat: '请选择转换格式',
  pleaseInputCalculationFormula: '请输入运算公式',
  year: '年',
  month: '月',
  day: '日',
  hour: '时',
  minute: '分',
  Second: '秒',
  microsecond: '微秒',
  pleaseSelectDateFormat: '请选择时间格式',
  dateFormat: '时间格式',
  rename: '重命名',
  moveDown: '下移',
  moveUp: '上移',
  pleaseInputCharacters20: '请输入最大长度为二十个字符',
  pleaseInputTemplateName: '请输入模板名称',
  starndardDataPoint: '标准数据点',
  pleaseSelectDataPoint: '请选择数据点',
  dataPoint: '数据点',
  editCustomTemplateFailed: '编辑自定义模板失败',
  editCustomTemplateSuccess: '编辑自定义模板成功',
  deleteCustomTemplateFailed: '删除自定义模板失败',
  deleteCustomTemplateSuccess: '删除自定义模板成功',
  addCustomTemplateFailed: '新增自定义模板失败',
  addCustomTemplateSuccess: '新增自定义模板成功',
  obtainCustomTemplateFailed: '获取自定义模板失败',
  downloadDeviceDataFailed: '下载设备数据失败',
  selectStandardTemplate: '请选择标准下载模板',
  selectCustomTemplate: '请选择自定义下载模板',
  csvDefaultFormat: 'csv默认格式',
  fileFormat: '文件格式',
  templateName: '模板名称',
  waitingDownloadDeviceData: '正在下载设备 { devName } 从 { startTime } 时间段的数据，请等待该下载进程完成后再创建新的下载任务。',
  sureToDeleteTemplate: '确定删除模板 {templateName} 吗?',
  addTemplate: '添加模板',
  customTemplate: '自定义模板',
  standardTemplate: '标准模板',
  selectDataDownloadTemplate: '选择数据下载模板',
  userCancelsDownload: '用户取消下载',
  waitToDownload: '等待下载',
  generating: '生成中',
  querying: '查询中',
  finish: '完成',
  dataCollation: '数据整理中',
  Initialize: '初始化',
  downloadingDeviceData: '正在下载设备 {name} 从 {time} 的数据...',
  cancelDownload: '取消下载',
  downloadProgress: '下载进度',
  cancelDataFileDownloadFailed: '取消{name} 设备的数据文件下载失败',
  cancelDataFileDownloadSuccess: '取消{name} 设备的数据文件下载成功',
  downloadDataFileSuccess: '设备的数据文件下载成功',
  downloadFormatNotSupport: '不支持该格式下载，请选择其他格式',
  downloadFileSuccess: '文件下载成功',
  displayDownloadProgress: '显示文件下载进度',
  dateFromTo: '{date1} 到 {date2}',
  delHistoryDataFromToSuccess: '删除从 {date1} 到 {date2} 的历史数据成功',
  whoseChannel: '{parentId} 的 CAN{channel} 通道',
  showDeletionProgress2: '显示正在删除进度',
  close: '关闭',
  confirm: '确定',
  deleteDataFailed: '删除数据失败',
  noDataInQueryTime: '未检索到数据，请修改查询时间',
  deletingDeviceData: '正在删除设备 {tip} 从 {tipTime} 的数据...',
  deletionProgress: '删除进度',
  hide: '隐藏',
  showDeletionProgress: '显示删除数据进度',
  blockDeletion:
    '正在删除设备 <span class="bold">{tip}</span> 从 <span class="bold">{tipTime}</span>时间段的设备数据，请等待该删除进程完成后再创建新的删除数据任务。',
  error: '错误',
  deleteHistoryDataError: '删除通道 {channel} 的历史数据任务出错，请重新下发删除历史数据任务。',
  deleteHistoryDataLimit: '本次删除超过最大删除量的100万条，将默认删除该时间区间中前100万条数据，是否继续？',
  deleteHistoryDataTip:
    '历史数据删除为<span style="color: red">不可逆</span>操作，一旦输入用户密码确认后，不能终止，即使取消了，数据也将会继续清除，请谨慎操作！',
  deleteHistoryDataTip1:
    '1.历史数据删除为<span style="color: red">不可逆</span>操作，一旦输入用户密码确认后，不能终止，即使取消了，数据也将会继续清除，请谨慎操作！',
  deleteHistoryDataTip2: '2.此删除功能为慢速删除，若想快速清空设备全部数据，可点击【清空】按钮。',
  colon: '：',
  announcements: '注意事项',
  searchHistoryDataFirst: '当前历史数据条数为0，请先查询您想要保存的数据',
  downloadLimit: '下载限制{limit}条',
  yes: '是',
  no: '否',
  start: '开始',
  suggestion: '建议',
  addFile: '添加文件',
  begin: '开始',
  pause: '暂停',
  username: '用户名',
  cellPhoneNumber: '手机号码',
  email: '邮箱',
  creationTime: '创建时间',
  operation: '操作',
  edit: '编辑',
  delete: '删除',
  resetPasswords: '重置密码',
  homePage: '首页',
  desc: '描述',
  permission: '权限',
  inputUsername: '请输入用户名',
  inputDescription: '请输入描述',
  inputEmail: '请输入邮箱',
  inputDevice: '请输入搜索的设备',
  device: '设备',
  pleaseSelect: '请选择',
  devicePermissionNotEmpty: '设备权限不能为空',
  readable: '可读',
  readWrite: '可读写',
  viewDevice: '查看设备',
  viewDeviceLogAndStatus: '查看设备日志和状态',
  viewAndDownloadDeviceData: '查看和下载设备数据',
  viewAndDownloadDBCCurves: '查看和下载DBC曲线',
  viewTrigger: '查看触发规则管理',
  viewGPSTrack: '查看GPS轨迹',
  viewDBCAndSignal: '查看DBC和信号策略',
  viewECUFile: '查看ECU文件',
  viewFaultCodeFile: '查看故障码文件',
  viewAndRemoveDevices: '查看、添加和删除设备',
  issueDeviceCommand: '下发设备命令',
  viewDownloadAndDeleteDeviceData: '查看、下载和删除设备数据',
  viewDownlaodAndDeleteDBCCurves: '查看、下载、新增和删除DBC曲线',
  viewAddRemoveRule: '查看、添加和删除触发规则管理',
  performUDSAndECUOperation: '进行UDS诊断和ECU升级',
  allPermissionForDBC: '查看、新增、更新和删除DBC和信号策略',
  allPermissionForECU: '查看、新增、更新和删除ECU文件',
  allPermissionForFaultCodeFile: '查看、新增、更新和删除故障码文件',
  all: '全部',
  obtainDeviceTypeFailed: '获取设备类型失败',
  serialNumber: '序号',
  deviceName: '设备名称',
  deviceType: '设备类型',
  deviceLoadingFailure: '加载设备失败',
  checkAll: '全选',
  clear: '清空',
  update: '更新',
  refresh: '刷新',
  inputDevName: '请输入设备名称',
  devId: '设备ID',
  inputDevId: '请输入设备ID',
  devDesc: '设备描述',
  inputDevDesc: '请输入设备描述',
  selectDevType: '请选择设备类型',
  user: '用户',
  allUser: '所有用户',
  masterUser: '主用户',
  subUser: '子用户',
  pleaseSelectUser: '请选择用户',
  time: '时间',
  pleaseInputSearchContent: '请输入搜索内容',
  view: '查看',
  search: '搜索',
  devTypeNullTips: '设备类型不能为空',
  version: '版本',
  download: '下载',
  tip: '提示',
  file: '文件',
  protocolType: '协议类型',
  message: '消息',
  msgID: '消息ID',
  signal: '信号',
  type: '类型',
  back: '返回',
  add: '添加',
  modify: '修改',
  save: '保存',
  cancel: '取消',
  uploadImageLimitTip: '可上传{limit}张图片',
  selected: '已选'
}
