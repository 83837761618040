<template>
    <div class="company-wrapper">
        <el-form ref="personalInfoFormRef" :model="inputUserInfo" label-position="left" label-width="80px" :rules="rules">
            <el-form-item label-width="110px" prop="nick_name">
                <template #label>
                    <span class="label-text">{{
                        t("zws.ZPersonalInformation.name")
                    }}</span>
                </template>
                <div id="register-personalInfo-name" class="input-wrapper">
                    <el-input class="input" v-model="inputUserInfo.nick_name"
                        :placeholder="t('zws.ZPersonalInformation.namePlaceholder')" show-word-limit clearable></el-input>
                </div>
            </el-form-item>
            <el-form-item label-width="110px" prop="gender">
                <template #label>
                    <span class="label-text">{{
                        t("zws.ZPersonalInformation.gender")
                    }}</span>
                </template>
                <el-select class="gender-select" v-model="inputUserInfo.gender"
                    :placeholder="t('zws.ZPersonalInformation.positionPlaceholder')">
                    <el-option :label="t('zws.ZPersonalInformation.male')" value="男"> </el-option>
                    <el-option :label="t('zws.ZPersonalInformation.female')" value="女"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label-width="110px" prop="job">
                <template #label>
                    <span class="label-text">{{
                        t("zws.ZPersonalInformation.position")
                    }}</span>
                </template>
                <div id="register-personalInfo-department" class="input-wrapper">
                    <el-input v-model="inputUserInfo.job" :placeholder="t('zws.ZPersonalInformation.positionPlaceholder')"
                        show-word-limit clearable></el-input>
                </div>
            </el-form-item>
            <el-form-item label-width="110px" prop="industry">
                <template #label>
                    <span class="label-text">{{
                        t("zws.ZPersonalInformation.industry")
                    }}</span>
                </template>
                <div id="register-personalInfo-industry" class="input-wrapper">
                    <el-input v-model="inputUserInfo.industry" :disabled="isSub"
                        :placeholder="t('zws.ZPersonalInformation.industryPlaceholder')" show-word-limit
                        clearable></el-input>
                </div>
            </el-form-item>
            <el-form-item label-width="110px" prop="organization_name">
                <template #label>
                    <span class="label-text">{{
                        t("zws.ZPersonalInformation.company")
                    }}</span>
                </template>
                <div id="register-personalInfo-company" class="input-wrapper">
                    <el-input v-model="inputUserInfo.organization_name" :disabled="isSub"
                        :placeholder="t('zws.ZPersonalInformation.companyPlaceholder')" show-word-limit
                        clearable></el-input>
                </div>
            </el-form-item>
            <el-form-item label-width="110px" prop="address">
                <template #label>
                    <span class="label-text">{{
                        t("zws.ZPersonalInformation.address")
                    }}</span>
                </template>
                <div id="register-personalInfo-position" class="input-wrapper">
                    <el-input v-model="inputUserInfo.address" :disabled="isSub"
                        :placeholder="t('zws.ZPersonalInformation.addressPlaceholder')" show-word-limit
                        clearable></el-input>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, watch } from 'vue'
import { ZValidateDataTool as ZValidateTool } from '@zws/tools'
import { useLocale } from '../../hooks/useLocale'

export default defineComponent({
    props: {
        info: {
            type: Object,
        },
        isSub: {
            type: Boolean,
            default: false,
        },
    },
    setup(prop, context) {

        let { t } = useLocale()
        let personalInfoFormRef: any = ref(null)
        let inputUserInfo = reactive({
            nick_name: '',
            organization_name: '',
            industry: '',
            address: '',
            gender: '男',
            job: '',
        })
        const isSub = prop.isSub
        let validateName = (rule: any, value: any, callback: Function) => {
            if (!ZValidateTool.chineseNameValidator(value, 2, 32)) {

                return callback(new Error(t('zws.ZPersonalInformation.consistOfChars') || '请输入2-32位，只可以包含中文字母数字下划线'))
            } else callback()
        }
        let rules = {
            nick_name: [
                { required: true, message: t('zws.ZPersonalInformation.name') + t('zws.ZPersonalInformation.cannotBeEmpty'), trigger: 'change' },
                {
                    validator: (rule: any, value: any, callback: Function) => {
                        if (!ZValidateTool.chineseNameValidator(value, 2, 20)) {
                            return callback(new Error(t('zws.ZPersonalInformation.consistOfChars') || '请输入2-20位，只可以包含中文字母数字下划线'))
                        } else callback()
                    },
                    trigger: 'change',
                },
            ],
            gender: [{ required: true, message: t('zws.ZPersonalInformation.gender') + t('zws.ZPersonalInformation.cannotBeEmpty'), trigger: 'change' }],
            job: [
                { required: true, message: t('zws.ZPersonalInformation.position') + t('zws.ZPersonalInformation.cannotBeEmpty'), trigger: 'change' },
                { validator: validateName, trigger: 'change' },
            ],
            industry: [
                { required: true, message: t('zws.ZPersonalInformation.trade') + t('zws.ZPersonalInformation.cannotBeEmpty'), trigger: 'change' },
                { validator: validateName, trigger: 'change' },
            ],
            organization_name: [
                { required: true, message: t('zws.ZPersonalInformation.company') + t('zws.ZPersonalInformation.cannotBeEmpty'), trigger: 'change' },
                { validator: validateName, trigger: 'change' },
            ],
            address: [
                { required: true, message: t('zws.ZPersonalInformation.address') + t('zws.ZPersonalInformation.cannotBeEmpty'), trigger: 'change' },
                { validator: validateName, trigger: 'change' },
            ],
        }
        inputUserInfo = Object.assign(inputUserInfo, prop.info)
        function returnInputInfo() {
            personalInfoFormRef.value.validate((validate: any) => {
                if (validate) {
                    context.emit('returnInputInfo', inputUserInfo)
                }
            })
        }
        function cancel() {
            inputUserInfo = Object.assign(inputUserInfo, prop.info)
            personalInfoFormRef.value.clearValidate()
        }
        return {
            t,
            isSub,
            rules,
            inputUserInfo,
            personalInfoFormRef,
            cancel,
            confirm,
            returnInputInfo,
        }
    },
})
</script>
<style lang="less" scoped>
.company-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 550px;

    .input-wrapper {
        width: 350px;
        height: 44px;
    }

    .el-select {
        width: 350px;
    }

    .label-text {
        font-size: 14px;
        color: #999999;
    }

    .tips-wrapper {
        display: flex;
        flex-direction: row;
        width: 550px;
        margin-top: 10px;
        margin-left: 15px;

        .tips-content {
            width: 410px;
        }
    }
}

.button-wrapper {
    width: 550px;
    margin-top: 40px;

    .button {
        height: 44px;
    }
}
</style>

<style lang="less" scoped>
#register-personalInfo-name {
    .el-input__inner {
        padding-right: 80px;
    }
}

#register-personalInfo-company {
    .el-input__inner {
        padding-right: 80px;
    }
}

#register-personalInfo-industry {
    .el-input__inner {
        padding-right: 80px;
    }
}

#register-personalInfo-department {
    .el-input__inner {
        padding-right: 80px;
    }
}

#register-personalInfo-position {
    .el-input__inner {
        padding-right: 80px;
    }
}

#register-personalInfo-contry {
    .el-input__inner {
        padding-right: 90px;
    }
}

#register-personalInfo-address {
    .el-input__inner {
        padding-right: 90px;
    }
}</style>

