<template>
  <el-container>
    <el-header :height="headerHeight" style="padding: 0px">
      <ZHeaderArea></ZHeaderArea>
      <div class="announcement-container">
        <div class="announcement">
          <ZAnnouncementArea
            v-model="announcementShow"
            announcementWidth="1350px"
            platform="candtu"
            hoverStop
            @onAnnouncementClicked="handleAnnouncementClicked"
          >
          </ZAnnouncementArea>
        </div>
      </div>
    </el-header>
    <el-container>
      <el-container>
        <el-main height="50px">
          <!-- keepalive 缓存 -->
          <router-view v-slot="{ Component }">
            <keep-alive :include="cachePage">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </el-main>
        <el-footer height="40px">
          <ZFooterArea></ZFooterArea>
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>
<script lang="ts">
import ZHeaderArea from './ZHeaderArea.vue'
import ZAsideArea from './ZAsideArea.vue'
import ZFooterArea from './ZFooterArea.vue'
// import ZAnnouncementArea from '@/components/Common/ZAnnouncementArea.vue'
import ZAnnouncementArea from '../../../third_party/iot-standard-library/component/vue/area/ZCommonArea/ZAnnouncementArea.vue'
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()
    const cachePage = ['HomePage']
    const announcementShow = ref<boolean>(false)

    const headerHeight = computed(() => (announcementShow.value ? '74px' : '50px'))

    const handleAnnouncementClicked = () => {
      router.push('/Container/UserManager/SystemConfig')
    }

    watch(announcementShow, newVal => {
      store.commit('setAnnouncementShow', newVal)
    })

    return {
      cachePage,
      announcementShow,
      headerHeight,
      handleAnnouncementClicked
    }
  },
  components: {
    ZHeaderArea,
    ZAsideArea,
    ZFooterArea,
    ZAnnouncementArea
  }
})
</script>
<style lang="less" scoped>
.el-footer {
  background-color: #4f5254;
  color: #333;
  // text-align: center;
  // vertical-align: baseline;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-aside {
  background-color: #ffffff;
  // color: #333;
  text-align: center;
}

.announcement-container {
  background: url('@/assets/images/global/bg.png') repeat;

  .announcement {
    width: 1350px;
    margin: 0 auto;
  }
}

.el-main {
  width: 100%;
  margin-bottom: 0px;
  padding: 0px 0px 0px calc((100vw - var(--project-page-width)) / 2);
  box-sizing: border-box;
  background: url('@/assets/images/global/bg.png') repeat;
}
</style>
