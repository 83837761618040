<template>
  <div>
    <ZTablePaginationCtrl v-loading="tableLoading" v-model="tablePaginationValue" :tableHeader="tableHeader"
      :tableData="tableData" @sort-change="handleSortChange" @paginationChange="handlePaginationSizeChange">
      <template #things_usage="{ data }">
        <div class="usage-container">
          <span class="usage">{{ data.things_usage ? ZFormatConversionTool.converByteUnit(data.things_usage) : '0 B' }}</span>
          <!-- <el-icon :class="{'delete-disabled': data.disableClean}" :color="data.color" :size="20" @click="handleCleanDataClicked(data)">
            <Delete />
          </el-icon> -->
          <el-popover
            v-if="isQuickDelete"
            :disabled="!data.disableClean"
            placement="top-start"
                :title="t('zws.ZCommon.tips.tip' || '提示')"
              :width="200"
              trigger="hover"
            >
              <template #reference>
                <el-icon :class="{ 'delete-disabled': data.disableClean }" :color="data.color" :size="20" @click="handleCleanDataClicked(data)">
                  <Delete />
                </el-icon>
              </template>
              <div>
                <div>
                  {{ t('zws.ZCapacitystatisticsTableArea.notQuickDel' || '该设备不支持快速删除功能，请前往') }}<span class="to-user-center" @click="toUserCenter">{{ t('zws.ZCapacitystatisticsTableArea.userCenter' || '用户中心') }}</span>{{ t('zws.ZCapacitystatisticsTableArea.updatePlan' || '进行套餐升级') }}
                </div>
              </div>
            </el-popover>
            <!-- 进度条逻辑 -->
            <!-- <el-tooltip v-if="data.deleteTotal && data.remaining_document" effect="light" content="显示删除数据进度"
            placement="top">
            <el-button class="hide-button" v-show="showHideDeleteData" v-buttonBlur type="info" plain
              @click="onHideDeleteDataButtonClicked($event, data)">
              <el-progress :stroke-width="10"
                :percentage="Number(((data.deleteTotal - data.remaining_document) / data.deleteTotal * 100).toFixed(2))"
                :show-text="false"></el-progress>
            </el-button>
          </el-tooltip>
          <el-icon v-else :size="20" @click="handleCleanDataClicked(data)">
            <Delete />
          </el-icon> -->
          </div>
        </template>
      </ZTablePaginationCtrl>
      <ZValidatePasswordDialogArea class="password-dialog" :loading="deleteLoading" v-model="deleteThingsDataDialogVisible"
        @confirm="deleteThingsDataConfirm" @cancel="onDeleteThingsDataCancel">
        <template #tips>
          <div class="confirm-container">
            <div class="confirm-title">{{ t('zws.ZCapacitystatisticsTableArea.attention' || '注意事项：') }}</div>
            <div class="confirm-tips">
                {{ t('zws.ZCapacitystatisticsTableArea.emptyHistory' || '此操作将会清空该设备的历史存储数据，清空为') }}<span style="color: red">{{ t('zws.ZCapacitystatisticsTableArea.irreversibleOp' || '不可逆') }}</span>{{ t('zws.ZCapacitystatisticsTableArea.cantCancel' || '操作，一旦输入用户密码确认后，不能终止，即使取消了，数据也将会继续清除，请谨慎操作！') }}
            </div>
          </div>
        </template>
      </ZValidatePasswordDialogArea>
      <!-- <el-dialog center width="800px" v-model="deleteDataDialogVisible" title="删除历史数据进度"
      :before-close="onDeleteDataButtonClicked" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="delete-data-tips">
        正在清空设备 <span class="bold">{{ deleteProgressObj?.third_things_id }}</span> 下的所有设备数据...
      </div>
      <el-progress :text-inside="true" :stroke-width="20"
        :percentage="Number(((deleteProgressObj.deleteTotal - deleteProgressObj.remaining_document) / deleteProgressObj.deleteTotal * 100).toFixed(2))"></el-progress>
      <template #footer>
        <el-button @click="onDeleteDataButtonClicked">隐藏</el-button>
      </template>
    </el-dialog> -->
      <!-- <ZDialogCtrl title="错误" v-model="tipsDialogShow" :buttonList="tipsDialogButton" @button-clicked="tipsDialogShow = false">
      <div>清空设备 {{ deleteFailureDev?.name }} 或其子设备任务出错，请重新下发清空设备数据任务。</div>
    </ZDialogCtrl> -->
    </div>
</template>

<script setup lang="ts">
import { computed, ref, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import ZTablePaginationCtrl from '../../ctrl/ZTablePaginationCtrl.vue'
import ZMessage from '../../ctrl/ZMessage'
import { dateFormat } from '../../../../sdk/ts/tool/ZFirmwareTool'
import ZFormatConversionTool from '../../../../sdk/ts/tool/ZFormatConversionTool'
import ZDialogCtrl from '../../ctrl/ZDialogCtrl.vue'
import { Delete } from '@element-plus/icons-vue'
import { remarkOperationMonitoring } from '../../../../sdk/ts/tool/ZDirectives/directives/monitoring'
import { useLocale } from '../../hooks/useLocale'
import {
  createQuickDeleteTask,
  getBatchThings,
} from '@zws/axios-fetch'

interface Prop {
  userPackage: any
  isQuickDelete: boolean
  queryObject?: any
  monitoringObject?: any
}

let { t } = useLocale()
const sotre = useStore()
const emit = defineEmits(['onCleanDevData', 'onToUserCenter'])
const props = withDefaults(defineProps<Prop>(), {
  isQuickDelete: true
})

const userInfo = computed(() => sotre.getters.userInfo)
const tablePaginationValue = ref<any>({
  currentPage: 1,
  pageSize: 5,
  pageSizes: [5, 10, 15, 20, 25],
})

let deleteDevData: any
const deleteLoading = ref<boolean>(false)
const deleteThingsDataDialogVisible = ref<boolean>(false)
const handleCleanDataClicked = (val: any) => {
  if (val.disableClean) return
  deleteDevData = val
  console.log(deleteDevData, 'deleteDevData');

  deleteLoading.value = false
  deleteThingsDataDialogVisible.value = true
}
const onDeleteThingsDataCancel = () => {
  deleteLoading.value = false
  deleteThingsDataDialogVisible.value = false
}
// 确认删除
const deleteThingsDataConfirm = () => {
  deleteLoading.value = true
  createQuickDeleteTask({ tenant_id: userInfo.value.tenant_id, delete_children: 1, things_id: deleteDevData.id })
    // window.ZWSApi.ZWSDataMgmt.dataCleaning_createQuickDeleteTask({ tenant_id: userInfo.value.tenant_id, delete_children: 1, things_id: deleteDevData.id })
    .then((result: any) => {
      emit('onCleanDevData')
      getTableData(tablePaginationValue.value.currentPage, tablePaginationValue.value.pageSize)
      deleteThingsDataDialogVisible.value = false
    }).catch((err: any) => {
      console.log(err);
      ZMessage.message.error(t('zws.ZCapacitystatisticsTableArea.emptyFail' || '清空设备数据失败'))
    }).finally(() => {
      deleteLoading.value = false
      if (props.monitoringObject) {
        remarkOperationMonitoring({
          action: "快速删除",
          params: JSON.stringify({
            things_id: deleteDevData.id,
            third_things_id: deleteDevData.third_things_id,
            delete_time: dateFormat(Date.now(), 'yyyy-MM-dd hh:mm:ss'),
            delete_usage: deleteDevData.things_usage ? ZFormatConversionTool.converByteUnit(deleteDevData.things_usage) : '0 B'
          }),
          ...props.monitoringObject
        })
      }
    })
}

const deleteProgressObj = ref<any>()
const deleteDataDialogVisible = ref<boolean>(false)
const showHideDeleteData = ref<boolean>(true)
const onHideDeleteDataButtonClicked = (e: any, data: any) => {
  let target = e.target
  if (target.nodeName == 'SPAN' || target.nodeName == 'I') {
    target = target.parentNode
  }
  target.blur()
  deleteProgressObj.value = data
  deleteDataDialogVisible.value = true
}
const onDeleteDataButtonClicked = () => {
  deleteDataDialogVisible.value = false
}

const tableHeader = [
  {
    label: t('zws.ZCapacitystatisticsTableArea.order' || '序号'),
    prop: 'index',
    width: '80',
    type: '',
    align: 'center',
    fixed: 'left'
  },
  {
    label: t('zws.ZCapacitystatisticsTableArea.devName' || '设备名称'),
    prop: 'name',
    width: '',
    type: '',
    align: 'center'
  },
  {
    label: t('zws.ZCapacitystatisticsTableArea.devType' || '设备类型'),
    prop: 'info_model_name',
    width: '120',
    type: '',
    align: 'center'
  },
  {
    label: t('zws.ZCapacitystatisticsTableArea.devId' || '设备ID'),
    prop: 'third_things_id',
    width: '',
    type: '',
    align: 'center'
  },
  {
    label: t('zws.ZCapacitystatisticsTableArea.usedCapacity' || '已用容量'),
    prop: 'things_usage',
    width: '140',
    type: 'custom',
    align: 'center',
    sortable: "custom",
    formatter: (row: any, col: any, cellValue: any) => {
      let value = (cellValue / 1024 / 1024).toFixed(0)
      return value
    }
  },
  {
    label: t('zws.ZCapacitystatisticsTableArea.updateTime' || '更新时间'),
    prop: 'last_cal_usage_time',
    width: '160',
    type: '',
    align: 'center',
    formatter: (row: any, col: any, cellValue: any) => {
      let value = cellValue ? dateFormat(cellValue, 'yyyy-MM-dd hh:mm:ss') : '--'
      return value
    }
  }
]
const tableData = ref<any[]>([])
const handlePaginationSizeChange = (val: any) => {
  // console.log(val)
  getTableData(val.currentPage, val.pageSize)
}
let sortObj = {}
const handleSortChange = (val: any) => {
  if (val.prop && val.order) {
    sortObj = {
      order_param: val.prop,
      order_type: val.order === "descending" ? 1 : 0
    }
  } else sortObj = {}
  tablePaginationValue.value.currentPage = 1
  getTableData(tablePaginationValue.value.currentPage, tablePaginationValue.value.pageSize)
}
const tableLoading = ref<boolean>(false)
const getTableData = (current_page: number = 1, page_size: number = 5) => {
  tableLoading.value = true
  getBatchThings({ current_page, page_size, tenant_id: userInfo.value.tenant_id, ...sortObj, ...props.queryObject })
    // window.ZWSApi.ZWSThings.things_getThings({ current_page, page_size, tenant_id: userInfo.value.tenant_id, ...sortObj, ...props.queryObject })
    .then((result: any) => {
      tablePaginationValue.value.total = result.pagination.total_size
      // if (result.list?.length) {
      //   getSubDevData(result.list)
      // } else {
      //   tableData.value = []
      // }
      
      tableData.value = result.list.map((item: any) => {
        if (props.userPackage?.content?.quick_delete_things_list && props.userPackage?.content?.quick_delete_things_list) {
          const disableObj = props.userPackage.content.quick_delete_things_list.find((thing: any) => {
            return thing.info_model_id === item.info_model_id && thing.third_things_id === item.third_things_id
          })
          return {
            ...item,
            disableClean: !disableObj,
            color: disableObj ? "" : '#c0c4cc'
          }
        } else {
          return {
            ...item,
            disableClean: true,
            color: '#c0c4cc'
          }
        }
      })
    })
    .catch((err: any) => {
      tablePaginationValue.value.total = 0
      tableData.value = []
      // tableLoading.value = false
      ZMessage.message.error(t('zws.ZCapacitystatisticsTableArea.getDevFail' || '获取设备列表失败'))
    }).finally(() => {
      tableLoading.value = false
    })
}

const toUserCenter = () => {
  emit('onToUserCenter')
}

watch(() => props.userPackage, newVal => {
  if (newVal.userPackage?.content?.quick_delete_things_list && newVal.userPackage?.content?.quick_delete_things_list.length) {
    tableData.value = tableData.value.map((item: any) => {
      if (props.userPackage?.content?.quick_delete_things_list && props.userPackage?.content?.quick_delete_things_list) {
        const disableObj = props.userPackage.content.quick_delete_things_list.find((thing: any) => {
          return thing.info_model_id === item.info_model_id && thing.third_things_id === item.third_things_id
        })
        return {
          ...item,
          disableClean: !disableObj,
          color: disableObj ? "" : '#c0c4cc'
        }
      } else {
        return {
          ...item,
          disableClean: true,
          color: '#c0c4cc'
        }
      }
    })
  }
})

  ; (() => {
    getTableData()
  })()
</script>

<style lang="less" scoped>
.usage-container {
  .usage {
    display: inline-block;
    width: 80px;
    // margin-left: -40px;
    text-align: right;
  }

  .el-icon {
    vertical-align: text-bottom;
    margin-left: 0.5em;
    cursor: pointer;
    & :hover {
      color: red;
    }
  }
  .delete-disabled {
    cursor: not-allowed;
    & :hover {
      color: #c0c4cc;
    }
  }
  
  :deep(.el-button) {
    min-height: 30px;
    padding: 8px 20px 8px 20px;
  }
}

.confirm-container {
  display: flex;
  padding: 0 10px;

  .confirm-title {
    margin-left: 10px;
    width: 80px;
  }

  .confirm-tips {
    font-size: 14px;
    line-height: 1.5em;
    text-align: left;
    margin-bottom: 1em;
    width: 330px;
  }
}

.hide-button {
  margin-left: 10px;

  .el-progress {
    width: 80px;

    :deep(.el-progress-bar__inner) {
      background-size: 15px 15px;
      -moz-animation: move 30s linear infinite;
      -webkit-animation: move 30s linear infinite;
    }
  }
}

@-webkit-keyframes move {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: 50px 50px;
  }
}

@-moz-keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

:deep(.el-progress-bar__inner) {
  // background-image: linear-gradient(-45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
  background-image: -webkit-linear-gradient(-45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent);
  background-image: -moz-linear-gradient(-45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent);
  background-size: 40px 40px;
  -moz-animation: move 10s linear infinite;
  -webkit-animation: move 10s linear infinite;
}

.delete-data-tips {
  font-size: 14px;
  // font-weight: bold;
  line-height: 1.5em;
  text-align: left;
  margin-bottom: 1em;

  .bold {
    font-weight: bold;
  }
}

// :deep(.confirm-container .confirm-title) {
//   width: 160px;
// }
:deep(.el-button) {
    min-height: 40px;
    padding: 12px 20px;
}
.to-user-center {
  color: #99c265;
  cursor: pointer;
  display: inline-block;
  margin: 0 2px ;
  border-bottom: 1px solid;
}
</style>
