<template>
  <ZAuthPageArea>
    <template v-slot>
      <ZForgetPasswordArea @returnToLogin="returnToLogin"></ZForgetPasswordArea>
    </template>
  </ZAuthPageArea>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import ZForgetPasswordArea from '../../../vue_library/components/ZAuthArea/ZForgetPasswordArea.vue'

export default defineComponent({
  components: {
    ZForgetPasswordArea
  },
  setup() {
    const router = useRouter()
    function returnToLogin() {
      router.push('/Login')
    }
    return {
      returnToLogin
    }
  }
})
</script>
<style lang="less" scoped></style>
