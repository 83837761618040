<template>
    <div class="table-pagination-area-wrapper">
        <div class="table-wrapper">
            <ZCheckTable v-if="dynamicTableHeader" :data="dataPointList" :channels="channels"
                @checkBoxChange="onCheckboxChange" @save="saveDataPointsClicked">
                <template v-slot:btnGroupPrefix>
                    <slot name="headerFilterBtnGroupPrefix"></slot>
                </template>
                <template v-slot:btnGroupSuffix>
                    <slot name="headerFilterBtnGroupSuffix"></slot>
                </template>
            </ZCheckTable>
            <el-table ref="multipleTable" v-loading="loading" :data="tableList" @selection-change="handleSelectionChange"
                border tooltip-effect="light" v-bind="$attrs" @sort-change="sortChange" :height="outHeight"
                :key="fnBlockTag">
                <template v-for="(item, columnIndex) in curTableHeader" :key="item.label" v-bind="item">
                    <el-table-column v-bind="item" :show-overflow-tooltip="!item.useCustomTip">
                        <template v-if="item.type === 'custom'" #default="scope">
                            <div v-if="item.prop === 'buttons'">
                                <el-button class="operation-button"
                                    :style="{ color: button.isText ? getButtonTextColor(button.type) : '' }"
                                    v-for="(button, index) in tableButtons" :key="index"
                                    :type="button.isText ? 'text' : button.type" :size="button.size" :icon="button.icon"
                                    :disabled="button.disabled" :plain="button.plain" v-buttonPermission="button.permission"
                                    v-monitoring="button.monitoring"
                                    @click="tableButtonClicked(index, button.text, scope.$index, scope.row)">
                                    <template #default v-if="button.text">
                                        {{ button.text }}
                                    </template>
                                </el-button>
                            </div>
                            <slot v-else :name="item.prop" :index="scope.$index" :data="scope.row">
                                <el-button class="operation-button"
                                    :style="{ color: button.isText ? getButtonTextColor(button.type) : '' }"
                                    v-for="(button, index) in scope.row.buttons" :key="index"
                                    :type="button.isText ? 'text' : button.type" :size="button.size" :icon="button.icon"
                                    :disabled="button.disabled" :plain="button.plain" v-buttonPermission="button.permission"
                                    v-monitoring="button.monitoring"
                                    @click="tableButtonClicked(index, button.text, scope.$index, scope.row)">
                                    <template #default v-if="button.text">
                                        {{ button.text }}
                                    </template>
                                </el-button>
                            </slot>
                        </template>
                        <template v-else-if="item.type === 'expand'" #default="props">
                            <slot name="expand" :data="props.row"></slot>
                        </template>
                    </el-table-column>
                </template>
                <template #append>
                    <slot name="append"></slot>
                </template>
            </el-table>
        </div>
        <div v-if="isShowTablePagination" :class="[isShowTablePagination ? 'pagination-wrapper' : '']">
            <div class="pagination-content">
                <div class="pagination">
                    <el-pagination :total="tablePaging.total" :small="tablePaging.small"
                        :current-page="tablePaging.currentPage" :page-sizes="tablePaging.pageSizes"
                        :page-size="tablePaging.pageSize" :layout="tablePaging.layout" :pager-count="tablePaging.pagerCount"
                        @current-change="paginationCurrentChange" @size-change="paginationSizeChange" background>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, watch, nextTick, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { Monitoring } from '../../../sdk/ts/tool/ZDirectives/directives/monitoring'
import { useLocale } from '../hooks/useLocale'
import ZCheckTable from './ZCheckTableCtrl.vue'
import { addSingleUserSelfConfig, getUserSelfConfig } from '@zws/axios-fetch'

import type { PropType } from 'vue'

export interface TableHeader {
    label: string
    prop: string
    align?: string
    width?: string
    type?: string
    fixed?: string | boolean
    monitoring?: Monitoring
    [propName: string]: any
}
export interface TablePagination {
    total: number
    pageSizes?: number[]
    layout?: string[]
    currentPage?: number
    pageSize?: number
}
export default defineComponent({
    components: {
        ZCheckTable
    },
    props: {
        modelValue: {},
        // 表格表头
        tableHeader: {
            type: Array as PropType<Array<TableHeader>>,
            default: () => [
                {
                    label: '',
                    prop: '',
                    width: '60',
                    type: 'selection',
                    align: 'center',
                },
                {
                    label: '序号',
                    prop: 'index',
                    width: '60',
                    type: '',
                    align: 'center',
                },
                {
                    label: '设备名称',
                    prop: 'devname',
                    width: '',
                    align: 'center',
                    type: '',
                },
                {
                    label: '设备id',
                    prop: 'devid',
                    width: '',
                    align: 'center',
                    type: '',
                },
            ],
        },
        // 表格数据
        tableData: {
            type: Array as any,
            default: () => [
                {
                    devname: 'invert_name1',
                    devid: '12345678',
                    buttons: [
                        {
                            text: '查看',
                            type: 'primary',
                            size: 'mini',
                            icon: 'el-icon-view',
                        },
                        {
                            text: '编辑',
                            type: 'success',
                            size: 'mini',
                            icon: 'el-icon-edit',
                        },
                        {
                            text: '删除',
                            type: 'danger',
                            size: 'mini',
                            icon: 'el-icon-delete',
                        },
                    ],
                },
            ],
        },
        // 按钮组定义
        tableButtons: {
            type: Array as any,
            default: () => [
                {
                    text: '查看',
                    type: 'primary',
                    size: 'mini',
                    icon: 'el-icon-view',
                },
                {
                    text: '编辑',
                    type: 'success',
                    size: 'mini',
                    icon: 'el-icon-edit',
                },
                {
                    text: '删除',
                    type: 'danger',
                    size: 'mini',
                    icon: 'el-icon-delete',
                },
            ],
        },
        // 是否支持分页，传入即开启，表格分页参数，参数有total、pageSizes、layout
        tablePagination: {
            type: Object,
            // default: {
            //     small: false,
            //     total: 0,
            //     currentPage: 1,
            //     pageSize: 10,
            //     pageSizes: [10, 20, 30, 40, 50],
            //     layout: 'prev,pager, next, jumper, total,sizes',
            // },
        },
        // 表格数据与原始数据分离，修改表格的数据不会影响原始数据。
        detach: {
            type: Boolean,
            default: false,
        },
        // 是否开启动态表头
        dynamicTableHeader: {
            type: Boolean,
            default: false,
        },
        // 传入动态表头唯一key可以自动开启服务端缓存表头功能，必须确保key为唯一值，否则会相互覆盖
        dynamicTableKey: {
            type: String
        },
        // 固定表头
        outHeight: {
            type: String,
        },
        // 加载动画
        tableLoading: {
            type: Boolean,
            default: false,
        },
        autoTablePagination: {
            type: Boolean,
            default: false
        },
        // 超过一万条隐藏页数
        exceedHide: {
            type: Boolean,
            default: true
        },
        fnBlockTag: {
            type: String,
            default: ''
        },
        dataFn: {
            type: String,
            default: ''
        },
        saveToDatabase: {
            type: Boolean,
            default: false/*  true 表示用户的设置写入后台数据库  */
        },
        txts: {
            type: Array,
            required: false,
            default: () => {
                return []
            }
        },
        keys: {
            type: Array,
            required: false,
            default: () => {
                return []
            }
        },
        asInverse: {
            type: Boolean,
            default: false/*  true 表示 channelsArray [] 的实际值为undefined  */
        },
        channelsArray: {
            type: Array as PropType<string[]>,
            default: () => [] // 配置策略为存入需要显示的列的列名，比如['column1','column2','column6',...]，默认[]
        },
        displayPropArray: {
            type: Array,
            default: () => {
                return []
            }
        },
        localeSortable: {
            type: Boolean,
            default: true
        },
        onepage: {
            type: Boolean,
            default: false
        },
        weakArray: {
            type: Array,
            default: () => {
                return []
            }
        },
        protectArray: {
            type: Array,
            default: () => {
                return []
            }
        },
        freshUpdate: {
            type: Number,
            default: false
        }
    },
    setup(prop: any, context) {
        let { t }: any = useLocale()
        let loading = ref<boolean>(false)
        let tableList: any = reactive([])
        let tablePaging: any = reactive({
            total: 0,
            currentPage: 1,
            pageSize: 10,
            pageSizes: [10, 20, 30, 40, 50],
            layout: 'prev, pager, next, total, sizes',
            small: false,
            pagerCount: 7,
        })
        let tablePagingDefaultParams: any = {
            total: 0,
            currentPage: 1,
            pageSize: 10,
            pageSizes: [10, 20, 30, 40, 50],
            layout: 'prev, pager, next, total, sizes',
            small: false,
            pagerCount: 7,
        }
        let isShowTablePagination: any = ref(true)
        let totaldata: any = []
        // 动态表头
        const popoverVisible = ref<boolean>(false)
        const selectDataPointsNameList = ref<string[]>([])
        const dataPointList = ref<any[]>([])
        // const curTableHeader = ref(prop.tableHeader)
        const curTableHeader = ref<any[]>([])
        let paginationCurrentPage: any = ref(1)
        let multipleTable = ref<any>(null)
        let saveToDatabase = ref(prop.saveToDatabase)
        let channelsArray = ref(prop.channelsArray)
        let channels: any = reactive([])
        let keys = ref(prop.keys)
        let txts = ref(prop.txts)
        let showTxts: any = reactive([])
        watch(curTableHeader, (headers: TableHeader[]) => {
            initTableHeader(headers)
        })

        const originTableHeader = () => {
            let index = 0
            channels.splice(0, channels.length)
            curTableHeader.value = []
            dataPointList.value = []
            selectDataPointsNameList.value = []
            prop.tableHeader.forEach((item: any) => {
                let _headItem = Object.assign({}, item)

                if ((_headItem.label === '操作') || _headItem.prop === 'index' || prop.protectArray.includes(_headItem.prop)) {
                    curTableHeader.value.push(_headItem)
                }
                if ((_headItem.prop !== 'index') && (_headItem.label !== '序号')) {
                    if (prop.protectArray.includes(_headItem.prop)) {
                    } else {
                        dataPointList.value.push(_headItem)
                    }
                }
                if (index < 30 && (_headItem.prop!=='index') && (_headItem.label!=='序号') && !curTableHeader.value.includes(_headItem)) {
                    if (!prop.weakArray.includes(_headItem.prop)) {
                    curTableHeader.value.push(_headItem)
                    }
                    if (prop.protectArray.includes(_headItem.prop)) {
                    } else {
                        if (prop.weakArray.includes(_headItem.prop)) {
                            channels.push(false)
                        } else {
                            selectDataPointsNameList.value.push(_headItem.label)
                            index++
                            channels.push(true)
                        }
                    }
                }
                //有问题，这样30个以后的点一律无法在表格显示
                // if ((_headItem.label === '操作') || _headItem.prop === 'index') {
                //     curTableHeader.value.push(_headItem)
                // } else {
                //     dataPointList.value.push(_headItem)
                // }
                // if (index < 30 && !curTableHeader.value.includes(_headItem)) {
                //     curTableHeader.value.push(_headItem)
                //     selectDataPointsNameList.value.push(_headItem.label)
                //     index++
                // }
            })

            // weakArray 自定义默认不勾选
            /* dataPointList.value.forEach((_headItem: any, index: any) => {
                if (prop.weakArray.includes(_headItem.prop)) {
                    channels[index] = false
                }
            }) */
        }

        let customData: any[] = []
        let userInfo: any
        let fnBlockTagOld: any
        watch(
            [() => prop.tableHeader, () => prop.fnBlockTag],
            async (newValue) => {
                if (!prop.fnBlockTag) {
                    fnBlockTagOld = ''
                    return
                }
                  
                if (fnBlockTagOld == prop.fnBlockTag) {
                    return
                } else {
                    fnBlockTagOld = prop.fnBlockTag
                }

                if (prop.freshUpdate) {
                    return
                }
                let rst = await getCloudStorage()
            },
            {
                deep: true,
                immediate: true
            }
        )
        watch(
            [() => prop.tableHeader, () => prop.dynamicTableKey],
            (newValue) => {
                try {
                    if (prop.fnBlockTag) {
                        return
                    }
                    userInfo = JSON.parse(localStorage.getItem('userInfo') as string)
                    if (userInfo?.tenant_id && prop.dynamicTableKey) {
                        (window as any).ZWSApi.ZWSTenant.tenants_getCustomization({ tenant_id: userInfo?.tenant_id }).then((result: any) => {
                            customData = []
                            customData = result?.data
                            let _showHeader: any = customData.find((item: any) => item.key === prop.dynamicTableKey)
                            if (_showHeader) {
                                curTableHeader.value = []
                                dataPointList.value = []
                                selectDataPointsNameList.value = []
                                _showHeader = JSON.parse(_showHeader.value)
                                let showIndex = 0
                                prop.tableHeader.forEach((item: any) => {
                                    let _headItem = Object.assign({}, item)
                                    if ((_headItem.label === '操作') || _headItem.prop === 'index' || prop.protectArray.includes(_headItem.prop)) {
                                        curTableHeader.value.push(_headItem)
                                    } else {
                                        dataPointList.value.push(_headItem)
                                    }
                                    if (_showHeader.includes(_headItem.label) && showIndex < 30 && !curTableHeader.value.includes(_headItem)) {
                                        curTableHeader.value.push(_headItem)
                                        selectDataPointsNameList.value.push(_headItem.label)
                                        showIndex++
                                    }
                                })
                            } else {
                                originTableHeader()
                            }
                        }).catch((error: any) => {
                            console.log(error);
                            originTableHeader()
                        });
                    } else {
                        originTableHeader()
                    }
                } catch (error) {
                    console.log(error);
                    originTableHeader()
                }
            },
            {
                deep: true,
                immediate: true
            }
        )
        watch(
            () => prop.tableLoading,
            (newValue, oldValue) => {

                loading.value = newValue

            }
        )

        watch(
            () => prop.freshUpdate,
            (newValue, oldValue) => {
                let rst = getCloudStorage()

            }
        )

        init()

        function init() {
            if (prop.tablePagination) {
                // 1. 如果传入tablePagination，便先加载默认的样式。
                // 2. 将传入参数，覆盖默认样式
                tablePaging = Object.assign(tablePaging, tablePagingDefaultParams)
                tablePaging = Object.assign(tablePaging, prop.tablePagination)
            }

            if (prop.modelValue) {
                // 1. 如果传入tablePagination，便先加载默认的样式。
                // 2. 将传入参数，覆盖默认样式
                tablePaging = Object.assign(tablePaging, tablePagingDefaultParams)
                tablePaging = Object.assign(tablePaging, prop.modelValue)
            }

            if (prop.modelValue === undefined) {
                isShowTablePagination.value = false
            }

            if (prop.autoTablePagination) {
                isShowTablePagination.value = true
            }

            context.emit('update:modelValue', tablePaging)
            initTableHeader(curTableHeader.value)
            initTableList(prop.tableData)
        }
        function load() {
            showTxts.push(...txts.value.slice(showTxts.length, showTxts.length + 10))
        }
        watch(
            () => prop.modelValue,
            (newValue: any, oldValue) => {
                if (newValue !== undefined) {
                    tablePaging.total = newValue.total
                }
                // 如果外部设置layout为空，意味着分页没有显示，将边框去掉
                if (newValue !== undefined && newValue.layout === '') {
                    isShowTablePagination.value = false
                }
            },
            {
                deep: true,
            }
        )
        watch(
            () => prop.tableData,
            (newValue: any, oldValue) => {

                // 如果tableData数据有变化，就停止加载动画
                // loading.value = false
                if (newValue !== undefined) initTableList(newValue)
            },
            {
                deep: true,
            }
        )
        watch(
            () => prop.keys,
            (newValue: any, oldValue) => {
                let _keys = newValue ?? keys.value
                let showKeys = prop.txts.slice(0, 15)
                showTxts.splice(0, showTxts.length, ...showKeys)

                if (channelsArray.value.length > 0) {
                    for (let j in _keys) {
                        if (channelsArray.value.includes(_keys[j]) || prop.displayPropArray.includes(_keys[j])) {
                            channels[j] = true
                        } else channels[j] = false
                    }
                } else {
                    for (let j in _keys) {
                        if (parseInt(j) < 15) {
                            channels[j] = true
                        } else channels[j] = false
                    }
                }
            },
            {
                deep: true,
                immediate: true
            }
        )

        watch(
            () => prop.channelsArray,
            (value: any, oldValue: string) => {
                if (!value || value.length == 0) return

                let valueString = value.toString()

                // 使用keys属性来控制哪些表头可以显示时，就用keys里的列名来判断当前需要显示哪些列
                if (keys.value && keys.value.length > 0) {
                    if (valueString.length > 0 || !prop.asInverse && valueString.length == 0) {
                        for (let j in keys.value) {
                            if (valueString.includes(keys.value[j]) || prop.displayPropArray.includes(keys.value[j])) {
                                channels[j] = true
                            } else channels[j] = false
                        }
                    } else {
                        for (let j in keys.value) {
                            if (parseInt(j) < 15) {
                                channels[j] = true
                            } else channels[j] = false
                        }
                    }
                }
                // 不使用keys属性来控制哪些表头可以显示时，默认用表格所有列的列名来判断需要显示哪些列
                else {
                    if (valueString.length > 0) {
                        let headers = controllableHeaders()

                        headers.forEach((h: string, index: number) => {
                            if (valueString.includes(h) || prop.displayPropArray.includes(h)) channels[index] = true
                            else channels[index] = false
                        })
                    }
                }

                setTableHeader(value)
            },
            {
                immediate: true
            }
        )

        function controllableHeaders() {
            let headers = []

            if (keys.value && keys.value.length > 0) headers = keys.value.filter((k: string) => k != '序号' && k != 'Index')
            else headers = prop.tableHeader.map((h: TableHeader) => h.label).filter((k: string) => k != '序号' && k != 'Index')

            return headers
        }

        function getButtonTextColor(themeColor: string) {
            let colorValue = ''
            switch (themeColor) {
                case 'primary':
                    colorValue = '#80b33f'
                    break;
                case 'success':
                    colorValue = '##67C23A'
                    break;
                case 'warning':
                    colorValue = '#E6A23C'
                    break;
                case 'danger':
                    colorValue = '#F56C6C'
                    break;
                case 'info':
                    colorValue = '#909399'
                    break;
                default:
                    colorValue = '#606266'
                    break;
            }
            return colorValue

        }
        function initTableHeader(headers: TableHeader[]) {
            headers.forEach((h: TableHeader) => {
                if (!h.formatter) {
                    h.formatter = (row: any, col: any, cellValue: any) => {
                        return cellValue === undefined || cellValue === null || cellValue === '' ? '--' : cellValue
                    }
                }
            })
        }

        function initTableList(data: any) {
            if (prop.detach) {
                try {
                    data = JSON.parse(JSON.stringify(data))
                } catch {
                    data = []
                }
            }
            if (tableList.length > 0) {
                tableList.splice(0, tableList.length)
            }
            if (totaldata.length > 0) {
                totaldata.splice(0, totaldata.length)
            }

            let index = (tablePaging.currentPage - 1) * tablePaging.pageSize
            for (let i = 0; i < data.length; i++) {
                data[i].index = (!prop.onepage ? index : 0) + i + 1
                if (data[i].show !== false) totaldata.push(data[i])
                nextTick(() => {
                    // toggleRowSelection(row, selected)方法接受两个参数，row传递被勾选行的数据，selected设置是否选中
                    data[i].checked && multipleTable.value && multipleTable.value.toggleRowSelection(data[i], data[i].checked)
                })
            }

            if (prop.autoTablePagination) {
                tablePaging.total = totaldata.length
                tableList.push(...totaldata.slice(index, index + tablePaging.pageSize))
            } else {
                tableList.push(...totaldata)
            }
        }
        function checkIsAutoTablePagination() {
            if (prop.autoTablePagination) {
                if (tableList.length > 0) {
                    tableList.splice(0, tableList.length)
                }
                let index = (tablePaging.currentPage - 1) * tablePaging.pageSize
                tableList.push(...totaldata.slice(index, index + tablePaging.pageSize))
            }
        }
        function handleSelectionChange(value: any) {
            context.emit('handleSelectionChange', value)
            context.emit('update:modelValue', tablePaging)
        }
        function tableButtonClicked(buttonIndex: number, buttonText: string, tableIndex: number, row: any) {
            context.emit('update:modelValue', tablePaging)
            context.emit('tableButtonClicked', {
                buttonIndex: buttonIndex,
                buttonText: buttonText,
                tableIndex: tableIndex,
                row: row,
            })
        }
        function paginationCurrentChange(value: number) {
            if (prop.exceedHide && tablePaging.pageSize * value > 10000) {
                return ElMessage.warning(t('zws.ZTablePaginationCtrl.over10thou'))// '数据已超过一万条，请精确查询条件')
            }
            tablePaging.currentPage = value
            context.emit('update:modelValue', tablePaging)
            context.emit('paginationCurrentChange', tablePaging)
            paginationChange()
            checkIsAutoTablePagination()
        }
        function paginationSizeChange(value: number) {
            // 如果修改了条数，默认从第1页开始
            tablePaging.currentPage = 1
            tablePaging.pageSize = value
            context.emit('update:modelValue', tablePaging)
            context.emit('paginationSizeChange', tablePaging)
            paginationChange()
            checkIsAutoTablePagination()
        }
        function paginationChange() {
            context.emit('paginationChange', tablePaging)
        }

        // 打开弹出框
        function openDataPointClicked() {
            // selectDataPointsNameList.value = []
            // curTableHeader.value.forEach((item: { label: any }) => {
            //     selectDataPointsNameList.value.push(item.label)
            // })
            popoverVisible.value = !popoverVisible.value
        }

        function onCheckboxChange(val: any, index: any) {
            if (val) {
                let selected = channels.filter((item: any) => item)
                if (selected.length > 30) {
                    ElMessage.info(t('zws.ZTablePaginationCtrl.saveMax'))
                    channels[index] = !val
                }
            }
        }

        function setCloudStorage(data: any) {
            function purifyData(data_: any) {
                return data_.replaceAll(/[:|-]/g, '')
            }
            addSingleUserSelfConfig({
                key: purifyData(prop.fnBlockTag),
                value: JSON.stringify(data)
            })
                .then((res: any) => {

                })
            return

        }

        async function getCloudStorage() {
            function purifyData(data_: any) {
                return data_.replaceAll(/[:|-]/g, '')
            }
            let rst = await getUserSelfConfig({
                keys: purifyData(prop.fnBlockTag),

            })

            curTableHeader.value = []
            dataPointList.value = []
            selectDataPointsNameList.value = []
            channels.splice(0, channels.length)
            let _showHeader: any = []
            if (rst.length > 0) {
                _showHeader = JSON.parse(rst[0].value) || { value: '[]' }
                _showHeader = JSON.parse(_showHeader.value) || []
            }
            if (!_showHeader.length) {

                originTableHeader()
                return
            }
            let showIndex = 0
            prop.tableHeader.forEach((item: any, index: any) => {
                let _headItem = Object.assign({}, item)
                if ((_headItem.label === '操作') || _headItem.prop === 'index' || prop.protectArray.includes(_headItem.prop)) {
                    curTableHeader.value.push(_headItem)
                } else {

                    if (prop.protectArray.includes(_headItem.prop)) {
                    } else {
                        dataPointList.value.push(_headItem)
                    }
                }
                if (_showHeader.includes(_headItem.prop) && showIndex < 30 && !curTableHeader.value.includes(_headItem)) {
                    curTableHeader.value.push(_headItem)
                    selectDataPointsNameList.value.push(_headItem.label)
                    showIndex++

                    let allLabel = dataPointList.value.map((item: any, index: number) => {
                        return item.label

                    })
                    var location = allLabel.indexOf(_headItem.label)
                    channels[location] = true
                }
            })

            return rst
        }

        function setLocalStorage(data: any) {
            window.localStorage.setItem('custom-' + prop.fnBlockTag, JSON.stringify({
                'customtype': 'columns' + prop.dataFn,
                info: { 'content': JSON.stringify(data) }

            }))
        }

        function getLocalStorage() {
            let info = window.localStorage.getItem('custom-' + prop.fnBlockTag) as any

            if (!info) return

            info = JSON.parse(info)

            if (typeof info?.info?.content != 'string') return info

            info.info.content = JSON.parse(info.info.content)

            return info
        }

        function getLocalSelectDataPointsNameList(): string[] | undefined {
            return getLocalStorage()?.info?.content
        }

        // 用本地配置初始化可显示的表头和表头筛选项
        function initLocalTableHeader() {
            if (prop.dynamicTableHeader) {
                let dataPointsNameList = getLocalSelectDataPointsNameList()
                setTableHeader(dataPointsNameList as string[])

                // 未设置自定义可显示的表头时的默认处理
                if (dataPointsNameList) {
                    for (let i = 0, tableHeader = prop.tableHeader, len = tableHeader.length; i < len; ++i)
                        if (dataPointsNameList.find((name) => { return name == tableHeader[i].label })) channels[i] = true
                        else channels[i] = false
                }
            }
        }

        // 监听保存按钮

        function saveDataPointsClickedNew() {
            let selected = channels.filter((item: any) => item)
            if (selected.length > 30) return ElMessage.info(t('zws.ZTablePaginationCtrl.saveMax'))
            if (saveToDatabase.value) {
                var columns = channels.slice()
                var content: any = []

                for (let i = 0; i < dataPointList.value.length; i++) {
                    if (columns[i]) {
                        if (keys.value && keys.length > 0) content.push((keys.value[i] as string))
                        else content.push(dataPointList.value[i].prop)
                    }
                }

                context.emit('setCustoms', content)
                setTableHeader(content)
                setCloudStorage(content)

            } else {
                setTableHeader(selectDataPointsNameList.value)
            }

            ElMessage.success(t('zws.ZTablePaginationCtrl.saveSuccessfully'))
            popoverVisible.value = false
        }

        function saveDataPointsClicked() {
            if (prop.fnBlockTag) {
                saveDataPointsClickedNew()
                // 新版接口流程
                return
            }
            let selected = channels.filter((item: any) => item)
            if (selected.length > 30) return ElMessage.info(t('zws.ZTablePaginationCtrl.saveMax'))
            if (saveToDatabase.value) {
                var columns = channels.slice()
                var content: any = []
                var headers = controllableHeaders()

                for (let i in columns) if (columns[i]) content.push(headers[i])

                context.emit('setCustoms', content)
                setTableHeader(content)
                setLocalStorage(content)
            } else {
                setTableHeader(selectDataPointsNameList.value)
            }

            ElMessage.success(t('zws.ZTablePaginationCtrl.saveSuccessfully'))
            popoverVisible.value = false
        }

        // 设置动态表头
        function setTableHeader(pointNameList: string[]) {
            if (!pointNameList) return

            setOriginTableHeader(pointNameList)
            if (userInfo?.tenant_id && prop.dynamicTableKey) {
                let _headData = customData.find((item: any) => item.key === prop.dynamicTableKey)
                if (_headData) {
                    _headData.value = JSON.stringify(pointNameList)
                } else {
                    customData.push({ key: prop.dynamicTableKey, value: JSON.stringify(pointNameList) })
                }
                (window as any).ZWSApi.ZWSTenant.tenants_updateCustomization({ tenant_id: userInfo.tenant_id, body: { data: customData } })
                    .catch((error: any) => {
                        console.log(error);
                    });
            }
        }

        function setOriginTableHeader(pointNameList: string[]) {
            curTableHeader.value = []
            prop.tableHeader.forEach((item: any) => {
                if (pointNameList.includes(item.prop) || pointNameList.includes(item.label) || item.label === '操作' || item.prop === 'index' || prop.protectArray.includes(item.prop)) {
                    curTableHeader.value.push(Object.assign({}, item))
                }
            })
        }

        // 排序
        function sortChange(value: { column: number; prop: string; order: string }) {
            if (!prop.localeSortable) return

            // 排序
            tableList.sort(compare(value.prop, value.order))
            // 拷贝
            let tableListValue = JSON.parse(JSON.stringify(tableList))
            // 情况数组
            tableList.splice(0, tableList.length)
            // 新增index排序
            tableListValue.forEach((item: any, index: number) => {
                item.index = index + 1
                tableList.push(item)
            })
        }
        // 比较
        function compare(key: string, order: string) {
            return function (value1: any, value2: any) {
                var val1 = value1[key]
                var val2 = value2[key]
                switch (order) {
                    // 升序
                    case 'ascending':
                        if (typeof val1 === 'number') {
                            return val1 - val2
                        } else if (typeof val1 === 'string') {
                            return val1.localeCompare(val2)
                        }
                        break
                    // 降序
                    case 'descending':
                        if (typeof val1 === 'number') {
                            return val2 - val1
                        } else if (typeof val1 === 'string') {
                            return val2.localeCompare(val1)
                        }
                        break
                    default:
                        break
                }
            }
        }

        const liShow = computed(() => prop.exceedHide && prop.modelValue?.total > 10000 ? 'none' : 'inline-block')

        function showTableHeaders(headerNames: string[]) {
            setTableHeader(headerNames)
        }

        return {
            loading,
            tableList,
            tablePaging,
            multipleTable,
            dataPointList,
            popoverVisible,
            curTableHeader,
            isShowTablePagination,
            paginationCurrentPage,
            selectDataPointsNameList,
            getButtonTextColor,
            sortChange,
            initTableList,
            handleSelectionChange,
            tableButtonClicked,
            paginationCurrentChange,
            paginationSizeChange,
            openDataPointClicked,
            saveDataPointsClicked,
            load,
            channels,
            onCheckboxChange,
            liShow,
            keys,
            txts,
            channelsArray,
            showTxts,
            saveToDatabase,
            showTableHeaders
        }
    },
})
</script>

<style lang="less" scoped>
// 滚动条bug
:deep(.is-scrolling-none + .el-table__fixed) {
    height: 100% !important; // 固定列样式bug
}

:deep(.is-scrolling-none + .el-table__fixed-left) {
    height: 100% !important; // 固定列样式bug
}

:deep(.is-scrolling-none + .el-table__fixed-right) {
    height: 100% !important; // 固定列样式bug
}

.table-wrapper {
    position: relative;

    .operation-wrapper .el-button+.el-button {
        margin-left: 5px !important;
    }

    .data-real-time-table-point {
        position: absolute;
        left: 5px;
        top: 5px;
        font-size: 12px;
        color: #767778;
        cursor: pointer;
        border: 1px solid #e9e9eb;
        z-index: 9;
    }

    :deep(.el-table--border) {
        border-color: #dadde0;
    }

    :deep(.el-table--border.el-table::before) {
        background-color: #dadde0;
    }

    :deep(.el-table--border::after) {
        background-color: #dadde0;
    }

    :deep(.el-table--border th) {
        border-color: #dadde0;
    }

    :deep(.el-table--border td) {
        border-color: #dadde0;
    }

    :deep(.el-table th.is-leaf) {
        border-color: #dadde0;
    }

    :deep(.el-table td) {
        border-color: #dadde0;
    }
}

.operation-button {
    height: 26px;
}

.pagination-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    border-left: 1px solid #dadde0;
    border-right: 1px solid #dadde0;
    border-bottom: 1px solid #dadde0;

    .pagination-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 11px;
    }

    .pagination {
        margin-right: 12px;
        text-align: left;

        :deep(.el-pagination .btn-prev .el-icon) {
            display: inline-block;
        }

        :deep(.el-pagination .btn-next .el-icon) {
            display: inline-block;
        }

        :deep(.el-pagination.is-background .btn-prev) {
            background-color: #f0f2f5;
        }

        :deep(.el-pagination.is-background .btn-next) {
            background-color: #f0f2f5;
        }

        :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
            background-color: var(--el-color-primary);
        }

        :deep(.el-pager li:last-child) {
            display: v-bind(liShow);
        }
    }
}

.data-real-time-container {
    max-height: 300px;
    min-width: 100px;
    overflow-y: auto;
}

.data-real-time-table-popover-save {
    text-align: center;
    margin-top: 12px;
}

.el-button--text.is-disabled {
    opacity: 0.56 !important;
}

// 按照致远UI设计的按钮风格，晚点再完善

// .el-button--danger.is-plain {
//     background-color: #fff;
//     border-color: #fde2e2;
// }
// .el-button--danger {
//     --el-button-font-color: #f75555;
//     --el-button-border-color: #f75555;
// }
// .el-button--danger.is-plain:hover {
//     color: #f56c6c;
//     border-color: #f75555;
//     background: #fff;
// }
// .el-button--primary.is-plain {
//     background-color: #fff;
// }

// .el-button--primary.is-plain:hover {
//     color: #80b33f;
//     border-color: #80b33f;
//     background: #fff;
// }
</style>
<style>
/* table设置全局样式 */
.el-table th {
    padding: 5.5px 0 !important;
    background-color: #f5f7fa !important;
    color: #8a9199;
    height: 40px !important;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #8a9199;
}

.el-table td {
    padding: 5.5px 0 !important;
    width: 144px;
    height: 14px;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #46494d;
}

.pagination-wrapper .pagination-content .pagination .el-pagination__total {
    margin-left: 15px !important;
}
</style>