import ZWSBaseModule from '../httpBase/ZWSBaseModule'
// const qs = require('qs')
import qs from 'axios'

/**
 * 项目管理
 */
class ZWSOutProject extends ZWSBaseModule {
  /************* project ********************************************************************************************/
  /**
   * 查询项目列表
   * @param {number} [params.current_page] - 当前页数
   * @param {number} [params.name] - 项目名
   * @param {string} [params.page_size] - 每页数量
   * @param {boolean} [params.tenantId] - 租户id
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: [{@link Project}] }
   */
  static project_getBatchProject({ current_page, name, page_size, tenantId }: any) {
    return this.fetch({
      url: `out-project/tenants/${tenantId}/project`,
      method: 'get',
      params: { current_page, name, page_size }
    }, 'project:project:query')
  }
  /**
   * 获取单个项目
   * @param {boolean} [params.tenantId] - 租户id
   * @param {boolean} [params.projectId] -项目id
   * @return {Promise<Response, Error>} -
   */
  static project_getSingleProject({ tenantId, projectId }: any) {
    return this.fetch({
      url: `out-project/tenants/${tenantId}/project/${projectId}`,
      method: 'get'
    }, 'project:project:queryone')
  }
  /**
   * 添加新项目
   * @param {Object} params
   * @param {Object} params.info - 配置信息
   * @example
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {@link Project} }
   */
  static project_addSingleProject({ tenantId, info }: any) {
    return this.fetch({
      url: `out-project/tenants/${tenantId}/project`,
      method: 'post',
      data: info
    }, 'project:project:create')
  }
  /**
   * 通过项目ID删除项目
   * @param {Object} params
   * @param {string} params.projectid - 项目ID
   * @return {Promise<Response, Error>} -
   */
  static deleteProjectById({ tenantId, projectId }: any) {
    return this.fetch({
      url: `out-project/tenants/${tenantId}/project/${projectId}`,
      method: 'delete'
    }, 'project:project:delete')
  }
  /**
   * 更新项目
   * @param {Object} params
   * @param {Object} params.info - 配置信息
   * @example
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {@link Project} }
   */
  static project_updateSingleProject({ tenantId, projectid, name, desc }: { tenantId: number, projectid: number, name: string, desc: string }) {
    return this.fetch({
      url: `out-project/tenants/${tenantId}/project/${projectid}`,
      method: 'put',
      data: { name, desc }
    }, 'project:project:update')
  }
  /************* projectResource ********************************************************************************************/
  /**
 * 添加项目资源（单个）
 * @param {Object} params
 * @param {Object} params.info - 配置信息
 * @example
 * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {@link Project} }
 */
  static addProjectResource({ tenantId, info }: any) {
    return this.fetch({
      url: `out-project/tenants/${tenantId}/projectResource`,
      method: 'post',
      data: info
    }, 'project:project-resource:update')
  }
  /**
  * 添加项目资源（批量）
  * @param {Object} params
  * @param {Object} params.info - 配置信息
  * @example
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {@link Project} }
  */
  static projectResource_addBatchProjectResources({ tenantId, body }: { tenantId: number, body: Array<object>, }) {
    return this.fetch({
      url: `/out-project/tenants/${tenantId}/projectResource/batch`,
      method: 'post',
      data: body
    }, 'project:project-resource:create')
  }
  /**
 * 更新项目资源
 * @param {string} [resourceIds ] 资源id
 * @param {string} [resourceType] 资源类型，device-设备；databoard-数据大盘；trigger-告警规则
 * @returns
 */
  static projectResource_updateProjectResources({ tenantId, projectId, resourceIds, resourceType }: { tenantId: number, projectId: number, resourceIds: string, resourceType: string }) {
    return this.fetch({
      url: `/out-project/tenants/${tenantId}/projectResource/batch`,
      method: 'put',
      data: { projectId, resourceIds, resourceType }
    }, 'project:project-resource:update')
  }
  /**
 * 查询项目资源
 * @param {Object} params
 * @param {Object} params.info - 配置信息
 * @param {Object} params.info.resourceType - “device”表示设备，“databoard”表示数据大盘，“trigger”表示告警规则
 * @example
 * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {@link Project} }
 */
  static projectResource_getSingleProjectResources({ tenantId, current_page, page_size, projectId, resourceId, resourceType }: {
    tenantId: number,
    current_page: number,
    page_size: number,
    projectId: number,
    resourceId: number,
    resourceType: any
  }) {
    return this.fetch({
      url: `out-project/tenants/${tenantId}/projectResource`,
      method: 'get',
      params: { current_page, page_size, projectId, resourceId, resourceType }
    }, 'project:project-resource:queryone')
  }
  /**
 * 批量查询项目资源
 * @param {Object} params
 * @example
 * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {@link Project} }
 */
  static projectResource_getBatchProjectResources({ tenantId, current_page, page_size, resourceIds, projectIds }: {
    tenantId: number,
    current_page: number,
    page_size: number,
    resourceIds: string,
    projectIds: string,
  }) {
    return this.fetch({
      url: `out-project/tenants/${tenantId}/projectResource/batch`,
      method: 'get',
      params: { current_page, page_size, resourceIds, projectIds }
    }, 'project:project-resource:queryone')
  }
  /**
   * 删除项目资源
   * @param {Object} params
   * @param {Object} param.info - 配置信息
   * @return {Promise<Response, Error>}
   */
  static projectResource_deleteProjectResources({ tenantId, projectId, resourceIds, resourceType }: {
    tenantId: number,
    projectId: number,
    resourceIds: number,
    resourceType: any
  }) {
    return this.fetch({
      url: `out-project/tenants/${tenantId}/projectResource`,
      method: 'delete',
      params: { projectId, resourceIds, resourceType }
    }, 'project:project-resource:update')
  }
}

export default ZWSOutProject
