<template>
    <div :class="['z-login-area', $i18n.locale]">
        <img class="z-login-left" src="@/assets/images/login/login_content.png" />
        <div class="z-login-right">
            <div class="z-login-project">
                <!-- <img :src="activePlatformInfo.loginLogo" /> -->
                <span class="z-login-project-text">{{ activePlatformInfo.projectName }}</span>
            </div>
            <!-- <span class="z-login-explain">登录</span> -->
            <el-form class="z-login-right-form" ref="loginFormRef" label-width="0px" :model="loginInfo" :rules="rules"
                :validate-on-rule-change="validateOnRuleChange">
                <el-tabs v-model="loginMode" @tab-click="loginModeClicked" stretch>
                    <el-tab-pane :label="getLanguage('zws.login.pwdLogin')" name="pwdLogin">
                        <!-- v-if 是处理调用 form validate 重复执行的问题 -->
                        <el-tooltip v-if="isPwdLogin" tabindex="-1" :content="getLanguage('zws.ZCommon.info.username')"
                            placement="left" effect="light">
                            <el-form-item prop="username">
                                <el-input class="z-login-input" tabindex="1" v-model="loginInfo.username"
                                    :placeholder="getLanguage('zws.ZCommon.tips.username')" auto-complete="off"
                                    :lang="$i18n.locale">
                                    <template #prefix>
                                        <i class="iconfont z-login-icon-setting zlg-icon-phone"></i>
                                    </template>
                                </el-input>
                            </el-form-item>
                        </el-tooltip>
                        <el-tooltip tabindex="-1" :content="getLanguage('zws.ZCommon.info.password')" placement="left"
                            effect="light">
                            <el-form-item prop="password">
                                <ZInputPasswordArea tabindex="2" class="z-login-input" v-model="loginInfo.password"
                                    :placeholder="getLanguage('zws.ZCommon.tips.password')" auto-complete="off"
                                    :hasPrefix="true">
                                    <template #prefix>
                                        <i class="iconfont z-login-icon-setting zlg-icon-password"></i>
                                    </template>
                                </ZInputPasswordArea>
                            </el-form-item>
                        </el-tooltip>
                    </el-tab-pane>
                    <el-tab-pane :label="getLanguage('zws.login.codeLogin')" name="codeLogin">
                        <el-tooltip v-if="!isPwdLogin" tabindex="-1" :content="getLanguage('zws.ZCommon.info.mobile')"
                            placement="left" effect="light">
                            <el-form-item prop="username">
                                <ZAreaMobileInputCtrl v-model="loginInfo.username" :lang="$i18n.locale">
                                </ZAreaMobileInputCtrl>
                            </el-form-item>
                        </el-tooltip>
                        <el-tooltip tabindex="-1" :content="getLanguage('zws.ZCommon.info.smsCode')" placement="left"
                            effect="light">
                            <el-form-item prop="verificationCode">
                                <div class="z-login-smscode">
                                    <ZSixDigitPasswordCtrl ref="ZSixDigitPasswordCtrlComponent" v-model="loginInfo.password"
                                        backgroundColor="#eaecf1" :lang="$i18n.locale">
                                    </ZSixDigitPasswordCtrl>
                                    <!-- 切换时，销毁组件 -->
                                    <ZCountDownCtrl v-if="!isPwdLogin" v-model:isSendCode="isSendCode"
                                        @getVerificationCodeClicked="getVerificationCodeClicked">
                                    </ZCountDownCtrl>
                                </div>
                            </el-form-item>
                        </el-tooltip>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <div class="z-login-password">
                <div v-show="!isPwdLogin"></div>
                <el-checkbox v-show="isPwdLogin" v-model="isRememberPasswordChecked" @change="checkboxChange">{{
                    t('zws.login.remember') }}
                </el-checkbox>
                <span class="z-login-password-text" @click="forgetClicked">{{ t('zws.login.forgetPassword') }}</span>
            </div>
            <div>
                <ZButtonWithLoadingCtrl :class="['z-login-button', { ' experience': showExperienceButton }]" type="primary"
                    :loadingTip="getLanguage('zws.login.logining')" :isLoading="loginBtnInfo.isLogin"
                    @btnClick="loginClicked">
                    {{ t('zws.login.name') }}
                </ZButtonWithLoadingCtrl>
                <el-button v-if="showExperienceButton" class="button-experience" type="primary" @click="experienceTextClick"
                    plain>{{ t('zws.login.experiment') || '体验账号' }}</el-button>
            </div>
            <div class="z-login-bottom">
                <!-- 中英文切换 -->
                <div>
                    <z-language-switch-area v-if="isShowLanguage" @dropdownCommand="dropdownCommand">
                    </z-language-switch-area>
                </div>
                <div>


                    <span class="z-login-register" @click="registerClicked">{{ t('zws.register.name') }}</span>
                    <span class="line">|</span>
                    <span class="z-login-register" @click="systemHelpClicked">
                        {{ t('zws.login.help') }}
                    </span>
                </div>
            </div>
            <ZThirdLoginArea></ZThirdLoginArea>

        </div>
        <ZDialogCtrl :title="t('zws.login.uncheckedTipsTitle')" width="500px" v-model:show="uncheckedDialog" :buttonList="uncheckedButton" @buttonClicked="handleUncheckedButtonClicked">
            <div class="unchecked-tips-container">
                {{ t('zws.login.uncheckedTips') }}
            </div>
        </ZDialogCtrl>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, watch, computed, nextTick } from 'vue'
import ZAreaMobileInputCtrl from '../../ctrl/ZAreaMobileInputCtrl.vue'
import ZSixDigitPasswordCtrl from '../../ctrl/ZSixDigitPasswordCtrl.vue'
import ZCountDownCtrl from '../../ctrl/ZCountDownCtrl.vue'
import { ZValidateDataTool as ZValidateTool } from '@zws/tools'
import ZInputPasswordArea from '../ZCommonArea/ZInputPasswordArea.vue'
import ZThirdLoginArea from './ZThirdLoginArea.vue'
import ZDialogCtrl from '../../ctrl/ZDialogCtrl.vue'
import { Base64 } from 'js-base64'
import { ElMessage } from 'element-plus'
import { useLocale } from '../../hooks/useLocale'
import { useStore } from 'vuex'
import { projectConfig } from '@/assets/ts/project_config'
import { useZThirdLoginHook } from '../../../../sdk/ts/tool/ZThirdLoginHook'
import defaultLoginLogo from '@/assets/images/login/login_logo.png'
import ZLanguageSwitchArea from '../ZCommonArea/ZLanguageSwitchArea.vue'
import { getSelfUserInfo, getUsersSelfTenant, getSingleCustomization, checkTenantIsManager, addSessionsForTenantManager, addSessions, getUCVerificationCodes, getConfigInfo, getPrivilegeSelf, getVerificationCodes } from '@zws/axios-fetch'

interface LoginBtnInfo {
    isLogin: boolean
}
export default defineComponent({
    components: {
        ZAreaMobileInputCtrl,
        ZSixDigitPasswordCtrl,
        ZCountDownCtrl,
        ZInputPasswordArea,
        ZThirdLoginArea,
        ZLanguageSwitchArea,
    },
    props: {
        helpDocument: {
            type: String,
            default: 'https://www.zlgcloud.com:20000/web/#/19/185'
        },
        isShowLanguage: {
            type: Boolean,
            default: true
        },
        resourcePath: {
            type: String,
            default: '/web'
        }
    },
    setup(prop, context) {
        let validateOnRuleChange = ref(false)
        let lang = ref('')
        let { t } = useLocale()
        let getLanguage = function (data: any) {
            t = useLocale().t
            lang.value = useLocale().locale

            return t(data)
        }
        const store = useStore()
        const isRememberPasswordChecked = ref(true)
        let loginInfo: any = reactive({
            username: '',
            password: '',
            type: 1,
        })
        let loginInfo_: any = reactive({
            username: '',
            password: '',
            type: 1,
        })
        let showExperienceButton = ref(true)
        let loginBtnInfo: LoginBtnInfo = reactive({
            isLogin: false,
        })

        let activePlatformInfo: any = reactive({
            loginLogo: defaultLoginLogo,
            projectName: projectConfig.projectName,
        })
        let isExperence = false
        init()
        function init() {

            initPlatformInfo()

            initISRememberPassword()
            initZWSJumpLogin()


        }

        function initZWSJumpLogin() {
            let str = window.location.hash
            if (str.indexOf('?ujwt=website') > -1) {//此时是从官网跳转的,将自动使用体验账号登录
                experienceTextClick()
            }
        }

        const { useThirdLogin } = useZThirdLoginHook()
        useThirdLogin()
            .then((res: any) => {
                successLogin(res.token, res.ujwt, res?.isManager)
            })
            .catch(() => { })

        const loginMode = ref('pwdLogin')
        const isPwdLogin = computed(() => loginMode.value === 'pwdLogin')
        const ZSixDigitPasswordCtrlComponent = ref()
        const nameValidator = (value: any, minlength = 2, maxlength = 20) => {
            let reg = new RegExp(`^[@\\w]{${minlength},${maxlength}}$`)
            return reg.test(value)
        }
        let validateUsername = (rule: any, value: any, callback: Function) => {

            validateOnRuleChange.value = true
            if (isExperence) {
                callback()
            }
            if (/^1\d{10}$/.test(value)) {
                ElMessage.warning(t('zws.CommonValidTipOrText.addZoneForPhone') || '请在手机号码前加区号，如86187xxxxxxxx')
            }
            if (value === '') {
                return callback(new Error(isPwdLogin.value ? t('zws.login.error.001') : t('zws.login.error.002')))
            } else if (isPwdLogin.value && !nameValidator(value, 2, 65)) {
                return callback(new Error(t('zws.CommonValidTipOrText.usernameValidTip') || '请输入2-65位，只可以包含@字母数字下划线'))
            } else if (!isPwdLogin.value && !ZValidateTool.phoneNumberValidator(value)) {
                return callback(new Error(t('zws.login.error.004') || '请输入正确位数的手机号码，只能是数字'))
            } else callback()
        }
        let validatePassword = (rule: any, value: any, callback: Function) => {

            validateOnRuleChange.value = true
            // if (!ZValidateTool.passwordValidator(value)) {
            //     return callback(new Error(t('zws.login.error.010') || '请输入8-16位非中文和空格的密码'))
            // } else callback()
            if (!value) {
                return callback(new Error(t('zws.login.error.022') || '请输入8-16位非中文和空格的密码'))
            } else callback()
        }

        let rules: any = ref({

        })

        let loginFormRef: any = ref(null)
        function checkboxChange(value: any) { }
        function forgetClicked() {
            context.emit('forgetClicked')
        }
        const getInputCode = ref('')
        const getCodeValue = (val: string) => {
            getInputCode.value = val
        }
        function registerClicked() {
            context.emit('registerClicked')
        }
        function loginModeClicked() {
            loginInfo.username = ''
            loginInfo.password = ''
            loginInfo.type = isPwdLogin.value ? 1 : 2
            isPwdLogin.value && initISRememberPassword()
            nextTick(() => {
                loginFormRef.value.clearValidate()
                ZSixDigitPasswordCtrlComponent.value.clear()
            })
        }

        let isSendCode: any = ref(false)
        let isSending = false
        function getVerificationCodeClicked() {
            if (isSending) return
            isSending = true
            loginFormRef.value && loginFormRef.value.validateField(['username'], (error: any) => {
                if (!error) {

                    checkTenantIsManager({
                        type: 2,
                        username: loginInfo.username,
                    })
                        .then((flag: any) => {
                            if (flag) {
                                const region = /^\+86/.test(loginInfo.username) ? 'cn' : 'intl'

                                getUCVerificationCodes({
                                    type: 1,
                                    destination: loginInfo.username,
                                    region: region,
                                })
                                    .then((res: any) => {
                                        isSendCode.value = true
                                        ElMessage.success(t('zws.register.tips.getCodeSuccess') || '获取手机验证码成功')
                                    })
                                    .catch((err: any) => {
                                        ElMessage.error(err?.data?.error)
                                    })
                            } else {
                                getVerificationCodes({
                                    type: 1,
                                    destination: loginInfo.username,
                                    purpose: 'login',
                                })
                                    .then((res: any) => {
                                        isSendCode.value = true
                                        ElMessage.success(t('zws.register.tips.getCodeSuccess') || '获取手机验证码成功')
                                    })
                                    .catch((err: any) => {
                                        ElMessage.error(err?.data?.error)
                                    })
                            }
                        })
                        .catch((err: any) => {
                            ElMessage.error(err?.data?.error)
                        })
                        .finally(() => {
                            isSending = false
                        })
                } else {
                    isSending = false
                }
            })
        }
        function loginClicked() {

            validateOnRuleChange.value = true
            if (isPwdLogin.value) {
                passwordLogin()
            } else {
                codeLogin()
            }
        }
        function passwordLogin() {
            loginFormRef.value && loginFormRef.value.validate((validate: any) => {
                if (validate) {
                    login()
                }
            })
        }
        function codeLogin() {
            if (loginInfo.username !== '' && loginInfo.password !== '') {
                login()
            } else if (loginInfo.username === '') {
                ElMessage.error(t('zws.login.error.004') || '请输入手机号码')
            } else {
                ElMessage.error(t('zws.login.error.014') || '请输入6位验证码')
            }
        }
        function successLogin(token: string, ujwt: string, flag: boolean) {
            localStorage.setItem('token', token)
            localStorage.setItem('ujwt', ujwt)
            isPwdLogin.value && isRememberPassword(loginInfo)
            let isDebugFlag = window.location.pathname.includes('debug')
            getBasicConfigInfo(prop.resourcePath, Number(isDebugFlag))
            getPrivilegeSelfLocal()
            getUserInfo(flag)
        }

        const uncheckedButton = computed(() => [
            {
                text: t('zws.login.uncheckedButton')
            }
        ])
        const handleUncheckedButtonClicked = () => {
            uncheckedDialog.value = false
        }
        const uncheckedDialog = ref<boolean>(false)
        function login(experience?: any) {
            loginBtnInfo.isLogin = true
            checkTenantIsManager({
                type: experience ? 1 : isPwdLogin.value ? 1 : 2,
                username: experience ? loginInfo_.username : loginInfo.username,
            })
                .then((flag: any) => {
                    var fn = flag ? addSessionsForTenantManager : addSessions
                    fn(experience ? loginInfo_ : loginInfo)
                        .then((res: any) => {
                            if (res.token) {
                                successLogin(res.token, res.ujwt, flag)
                            } else if (res.message === 'phone cannot be found') {
                                loginBtnInfo.isLogin = false
                                ElMessage.error('手机号码未注册')
                            } else if (!isPwdLogin.value) {
                                loginBtnInfo.isLogin = false
                                ElMessage.error('验证码错误，请重新输入')
                            } else {
                                loginBtnInfo.isLogin = false
                                ElMessage.error('用户名或者密码错误，请重新输入')
                            }
                            experience = false
                        })
                        .catch((err: any) => {
                            loginBtnInfo.isLogin = false
                            experience = false
                            console.log(err);
                            if (err.errorCode === 'iot-tenant.10016') {
                                return uncheckedDialog.value = true
                            }
                            if (err.errorMsg) {
                                ElMessage.error(err.errorMsg)
                                return
                            }
                            if (err.errorCode === "iot-http.409") {
                                ElMessage.error(t('zws.login.error.409') || "账号冲突，请联系管理员进行处理！")
                                // } else ElMessage.error(err?.data?.error)
                            } else ElMessage.error(err?.errorMsg)
                        })
                })
                .catch((err: any) => {
                    // ElMessage.error('服务端错误，请重试！')
                    loginBtnInfo.isLogin = false
                })
        }

        /** 获取更新的系统基本配置 */
        function getBasicConfigInfo(resourcePath: string, isDebug: number) {

            getConfigInfo({
                resource_path: resourcePath,
                is_debug: isDebug,
            })
                .then((res: any) => {
                    if (res.data) {
                        store.commit('globalPermissionInfo', res.data)
                    }
                })
                .catch((error: any) => { })
        }
        // 获取用户权限信息
        function getPrivilegeSelfLocal() {

            getPrivilegeSelf({ platform: '' })
                .then((res: any) => {
                    store.commit('tenantPermissionInfo', res)
                })
                .catch((err: any) => { })
        }
        function initISRememberPassword() {
            // 如果有记住密码
            if (document.cookie.indexOf('USER_INFO') !== -1) {
                let cookieList = document.cookie.split(';')
                cookieList.forEach((item: any) => {
                    if (item.indexOf('USER_INFO') !== -1) {
                        let cookieUserInfo = item.split('=')
                        // 如果有用户信息
                        if (cookieUserInfo[1] !== '') {
                            let info = Base64.decode(cookieUserInfo[1])
                            let userLoginInfo = JSON.parse(info)
                            loginInfo.username = userLoginInfo.username
                            loginInfo.password = userLoginInfo.password
                            isRememberPasswordChecked.value = true
                        } else {
                            isRememberPasswordChecked.value = false
                            loginInfo.username = ''
                            loginInfo.password = ''
                        }
                    }
                })
            } else {
                isRememberPasswordChecked.value = false
                loginInfo.username = ''
                loginInfo.password = ''
            }
        }
        function isRememberPassword(loginInfo: any) {
            if (isRememberPasswordChecked.value) {
                let encode = Base64.encode(JSON.stringify(loginInfo), true)
                document.cookie = `USER_INFO=${encode}`
            } else {
                document.cookie = 'USER_INFO='
            }
        }
        function getUserInfo(isManager?: boolean) {
            getSelfUserInfo()
                .then((user: any) => {
                    getUsersSelfTenant()
                        .then((tenant: any) => {
                            // 将租户信息加到用户信息上面
                            user.tenantInfo = tenant
                            user.isManager = isManager ?? true
                            if (user.avatar) user.avatar = user.avatar + '?' + Math.random()
                            store.commit('userInfo', user)
                            getTenantCustomization(user)
                        })
                        .catch((err: any) => {
                            loginBtnInfo.isLogin = false
                        })
                })
                .catch((err: any) => { })
        }
        function getTenantCustomization(userInfo: any) {
            getSingleCustomization({
                tenant_id: userInfo.tenant_id,
            })
                .then((res: any) => {
                    let systemInfo: any = {}
                    res.data.forEach((item: any) => {
                        systemInfo[item.key] = item.value
                    })
                    store.commit('tenantCustomizationInfo', systemInfo)
                    ElMessage.success(t('zws.login.loginSuccess') || '登录成功')
                    context.emit('loginClicked', userInfo)
                    loginBtnInfo.isLogin = false
                })
                .catch((error: any) => {
                    loginBtnInfo.isLogin = false
                })
        }

        function initPlatformInfo() {
            setPlatformInfo()
        }
        function setPlatformInfo() {
            let globalPermissionInfo = store.getters.globalPermissionInfo
            if (!globalPermissionInfo) return
            activePlatformInfo.loginLogo = globalPermissionInfo.loginLogo ? globalPermissionInfo.loginLogo : defaultLoginLogo
            activePlatformInfo.projectName = globalPermissionInfo.cloudname ? globalPermissionInfo.cloudname : projectConfig.projectName
        }
        function dropdownCommand(value: any) { }

        function experienceTextClick() {
            isExperence = true

            loginInfo_.username = "demo@zlg008"
            loginInfo_.password = 'Zlg123456'
            loginInfo_.type = 1

            login(true)
            // $emit('experience')
        }

        function changeWord() {
            rules.value = {
                username: [{ validator: validateUsername, trigger: 'change' }],
                password: [
                    { required: true, message: t('zws.login.error.006') || '密码不能为空', trigger: 'change' },
                    { validator: validatePassword, trigger: 'change' },
                ],
            }

        }


        watch(
            () => store.getters.globalPermissionInfo,
            () => {
                setPlatformInfo()
            }
        )
        watch(
            () => lang.value,
            (v) => {
                changeWord()

            },
            {
                immediate: true
            }
        )
        function systemHelpClicked() {
            // window.open(prop.helpDocument, '_blank')
            const baseUrl = window.location.origin + window.location.pathname
            window.open(baseUrl + '#/doc?type=help')
        }

        return {
            t,
            getLanguage,
            validateOnRuleChange,
            dropdownCommand,
            loginFormRef,
            projectConfig,
            isSendCode,
            rules,
            getInputCode,
            loginInfo,
            isRememberPasswordChecked,
            loginMode,
            isPwdLogin,
            ZSixDigitPasswordCtrlComponent,
            activePlatformInfo,
            getCodeValue,
            loginBtnInfo,
            checkboxChange,
            forgetClicked,
            loginClicked,
            experienceTextClick,
            showExperienceButton,
            registerClicked,
            loginModeClicked,
            getVerificationCodeClicked,
            systemHelpClicked,
            uncheckedDialog,
            uncheckedButton,
            handleUncheckedButtonClicked
        }
    },
})
</script>
<style lang="less" scoped>
.z-login-area.en {
    :deep(.el-form-item.is-error) {
        margin-bottom: 30px;
    }
}

.z-login-area {
    display: flex;
    flex-direction: row;
    -moz-box-shadow: 2px 2px 10px #909090;
    -webkit-box-shadow: 2px 2px 10px #909090;
    box-shadow: 2px 2px 10px #909090;
}

.z-login-left {
    width: 250px;
    height: 412px;
}

.z-login-right {
    width: 340px;
    height: 412px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eaecf1;
    border-radius: 0px 5px 5px 0;
}

.en .z-login-right-form {
    height: auto;
    min-height: 190px;
}

.z-login-right-form {
    height: 190px;
}

.z-login-project {
    height: 30px;
    display: flex;
    // align-items: center;
    margin-top: 20px;
    padding-bottom: 10px;
}

.z-login-project-text {
    font-size: 22px;
    margin-left: 10px;
    color: #2e3033;
    font-weight: bold;
}

.z-login-explain {
    font-size: 18px;
    color: #2e3033;
    margin-top: 10px;
    margin-bottom: 20px;
}

.z-login-input {
    width: 304px;
    height: 45px;
}

.z-login-icon-setting {
    color: #8a8f99;
    margin-left: 5px;
}

.z-login-smscode {
    display: flex;
    flex-direction: row;
}

.z-login-password {
    width: 304px;
    display: flex;
    justify-content: space-between;
}

.z-login-password-text {
    font-size: 14px;
    cursor: pointer;
}

.z-login-button {
    width: 304px;
    margin-top: 20px;
}

.z-login-button.experience {
    width: 192px;
}

.z-login-bottom {
    width: 304px;
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.z-login-mode {
    cursor: pointer;
    border-bottom: 1px solid #2c3e50;
}

.line {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    color: #d2d2d2;
}

.z-login-register {
    cursor: pointer;
}
.unchecked-tips-container {
    line-height: 1.5em;
    text-align: left;
    text-indent: 2em;
}
</style>