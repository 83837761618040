/**
 * 影子设备规则
*/
import ZValidateTool from './ZValidateTool'
import { getBatchTwinsRules, getSingleInfoModel, addBatchAlertAction, updateSingleAlertAction } from '@zws/axios-fetch'

/* --------- common ----------- */

interface SearchShadowRuleInfo {
    type: string,
    name_key: string,
    tenant_id: number,
}

export interface RuleSearchObj {
    type: 'create' | 'update',
    ruleId: number | null,
    resData: any
}

const valueTypeMap: any = {
    'int': 1,
    'long': 1,
    'float': 1,
    'double': 1,
    'string': 2,
    'array': 4,
    'object': 5,
    'boolean': 7,
};
/**
 * 获取影子列表，根据 name 去筛选是否有此条影子，有则更新，无则创建
 * */
export function queryShadowRule({ type, name_key, tenant_id, current_page = 1, page_size = 99999 }: {
    type: string, // batch（批量属性更新） / term（属性修改） / high_order_${groupType}
    name_key: any,
    tenant_id: number,
    current_page?: number,
    page_size?: number,
}) {

    return new Promise((resolve, reject) => {
        let ruleName = `${type}-twins-rule-id-${name_key}`;
        // @ts-ignore
        getBatchTwinsRules({ tenant_id, current_page, page_size, name: ruleName }).then((res: any) => {
            let result: { type: 'create' | 'update', ruleId: number | null, resData: any, action: any } = {
                type: 'create',
                ruleId: null,
                resData: null,
                action: null
            };
            if (ZValidateTool.checkArrayAndLength(res.list)) {
                result.type = 'update';
                result.ruleId = res.list[0].id;
                result.action = res.list[0].rule_action_list.length ? res.list[0].rule_action_list[0] : null;
                result.resData = res.list[0];
            }
            resolve(result);
        })
    });
}

// 递归把属性列表转为 condition 格式
export  function deepSetObjValue(prefixName: string, obj: any, targetList: any, mapObj: any, _prefixName: string) {
    if (Object.prototype.toString.call(obj) === '[object Object]') {
        // => triggerCondition
        for (let key in obj) {
            let prefixNameMixin = prefixName;
            prefixNameMixin = prefixNameMixin ? `${prefixNameMixin}['${key}']` : `${key}`;

            let _prefixNameMixin = _prefixName;
            _prefixNameMixin = `${_prefixNameMixin ? _prefixNameMixin + '.' : ''}${key}`;

            if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
                deepSetObjValue(prefixNameMixin, obj[key], targetList, mapObj, _prefixNameMixin);
            } else {
                const mapObjKey = _prefixNameMixin.replace(/\./g, '-');
                const targetValueTypeStr = mapObj?.[mapObjKey]?.type;
                const value_type = valueTypeMap?.[targetValueTypeStr] || 2;

                // const mapObjKeyArr = mapObjKey.split('-');
                // let value_type: number = 5;
                // if (mapObjKeyArr.length <= 2) {
                //     value_type = valueTypeMap?.[targetValueTypeStr] || 2;
                // }

                targetList.push({
                    field: prefixNameMixin,
                    op: `!=`,
                    value: targetValueTypeStr == "array" ? JSON.stringify(obj[key]) : obj[key],
                    value_type,
                    join: 'or'
                });
            }
        }
    }
}

  
/* --------- 单个设备 ----------- */
/**
 * 对设备模型id（info_model_id）为 xx 【单个或多个设备】，生成 入参
 *   1. 情况A: 对其下所有设备生效
 *   2. 情况B：对其下 某些 三方设备（third_things_id） 生效
 * attrs: 编辑的属性（支持 两层 对象嵌套情况）
 * thirdThingsIdList: 第三方设备Id 集合
 * */
export function setInfoModelThirdThingsRuleInfo({ type, tenant_id, name_key, info_model_id, attrs, thirdThingsIdList = [], mapObj = {} }: {
    type: string, // batch / single
    name_key: any,
    tenant_id: number,
    info_model_id: number,
    attrs: any,
    thirdThingsIdList: any,
    mapObj: any
}) {

    let triggerCondition: any = [];
    let triggerFilter: any = [
        { "field": "info_model_id", "op": "==", "value": info_model_id, value_type: 1 }, // 根据批量状态-默认是全部
    ];

    if (Object.prototype.toString.call(attrs) === '[object Object]') {
        // => triggerCondition
        deepSetObjValue('', attrs, triggerCondition, mapObj, '');
        // => triggerFilter

        triggerFilter[0].join = 'and';
        triggerFilter[1] = {
            field: "third_things_id",
            value_type: 2,
            op: '==',
            value: thirdThingsIdList?.[0]
        }

        // 多个 third_things_id 处理【暂时废弃】
        if (ZValidateTool.checkArrayAndLength(thirdThingsIdList) && false) {
            // 根据批量状态-修改多台设备，triggerFilter 中【0】才有 join，【1】才有值
            triggerFilter[0].join = 'and';
            triggerFilter[1] = {
                field: "third_things_id",
                value_type: 2,
                op: '==',
                group: [
                    // {"field":"third_things_id", "op":"==", "value":"lamp", "value_type": 2, "join":"or"},
                ]
            };
            thirdThingsIdList.forEach((id: any, index: number) => {
                let groupTemplate: any = { field: 'third_things_id', op: '==', value: id, value_type: 2 };
                if (thirdThingsIdList.length > 1) {
                    groupTemplate.value_type = 2;
                    groupTemplate.join = 'or';
                }
                triggerFilter[1].group.push(groupTemplate);
            })
        }
    }

    return {
        "name": `${type}-twins-rule-id-${name_key}`,
        "shadow_type": 1,
        "status": 1,
        "trigger_condition": triggerCondition,
        "trigger_filter": triggerFilter,
        "tenant_id": tenant_id
    }
}


/**
 * 获取设备属性信息
 * */
export function getSingleInfoModelPropers({ tenant_id, info_model_id }: {
    tenant_id: number,
    info_model_id: number,
}) {
    return new Promise((resolve, reject) => {
        let propertiesInfo: any = {};
        // @ts-ignore
        getSingleInfoModel({ tenant_id: tenant_id, info_model_id: info_model_id }).then((res: any) => {
            res.info_model_fn_blocks.forEach((item: any) => {
                const jsonSchema = JSON.parse(item.iot_function_block.json_schema);
                if (jsonSchema?.configuration?.properties) {
                    const properties = jsonSchema.configuration.properties;
                    if (ZValidateTool.checkArrayAndLength(properties)) {
                        properties.forEach((attrItem: any) => {
                            propertiesInfo[attrItem.identifier] = attrItem.name;
                        })
                    }
                }
            });
            resolve(propertiesInfo);
        })
    })
}

// 大函数1【看有没有影子属性】
export const getDigitalTwinThingsByShadow = async({ type, name_key, tenant_id }: SearchShadowRuleInfo) => {
    let ruleSearchObj = await queryShadowRule({ type, name_key, tenant_id }) as RuleSearchObj;
    let isHasShadowAttr = false;
    if (ruleSearchObj?.resData?.trigger_condition) {
        isHasShadowAttr = true;
    }
    return [isHasShadowAttr, ruleSearchObj];
};
// 大函数2【将影子属性转换为 高阶列表属性】
const deepSetHighOrderAttr = (targetObj: any, attrList: any[], item: any) => {
    if (attrList && attrList.length > 0 && Object.prototype.toString.call(targetObj[attrList[0]]) !== '[object Object]') {
        targetObj[attrList[0]] = {}
    }
    const setKey = attrList.shift();
    if (attrList.length > 0) {
        deepSetHighOrderAttr(targetObj[setKey], attrList, item)
    } else {
        targetObj[setKey] = item?.value;
    }
};
export const getShadowEchoAttrToHighOrderAttr = (ruleSearchObj: RuleSearchObj) => {
    let deviceStatusTem: any = {};

    if (ruleSearchObj?.resData?.trigger_condition) {
        let nativeConditionList = ruleSearchObj.resData.trigger_condition;

        if (nativeConditionList.length > 0) {
            nativeConditionList.forEach((conditionItem: any) => {
                let transferField = conditionItem.field.replace(/]/g, '').replace(/'/g, '').replace(/\[/g, '.');
                let fieldArr = transferField.split('.');

                deepSetHighOrderAttr(deviceStatusTem, fieldArr, conditionItem);
            });
        }
    }

    return deviceStatusTem;
};

/* --------- 分组 ----------- */

/**
 * 影子规则的triggerCondition
 * @param attrs
 * @param mapObj
 */
export function setTriggerConditionRuleInfo(attrs: any, mapObj: any) {
    let triggerCondition: any = [];
    deepSetObjValue('', attrs, triggerCondition, mapObj, '');
    return triggerCondition;
}

/**
 * 设备列表 - 影子创建后，下发告警动作
 * */
export function createCommonFnSetConfigActionByPayload({ tenant_id, rule_id, things_selector, payload }: {
    tenant_id: number,
    rule_id: number,
    things_selector: any,
    payload: any
}) {
    if (!tenant_id || !rule_id) return;
    // let payload = { operation_name: 'common_fn.set_config', operation_params: { sysMsgId: '', args: '' } };
    let createActionInfo = {
        build_payload_param: {
            build_type: 'simple',
            post_message_param: {
                things_selector,
                payload
            }
        },
        config: {
            send_message_action_id: 1
        },
        name: `${rule_id}-COMMAND-common_fn.set_config`,
        payload_script: '',
        payload_script_engine: '',
        payload_type: 0,
        status: 1,
        type: 'things-message',
        rule_id: rule_id,
        tenant_id: tenant_id
    };
    // console.log(createActionInfo);
    // @ts-ignore 
    addBatchAlertAction(createActionInfo).catch((err: any) => {
    });
}

/**
 * 设备列表 - 更新告警动作
 * */
export function updateCommonFnSetConfigActionByPayload({ tenant_id, rule_id, action_id, things_selector, payload }: {
    tenant_id: number,
    rule_id: number,
    action_id: number,
    things_selector: any,
    payload: any
}) {
    if (!tenant_id || !rule_id || !action_id) return;
    // let payload = { operation_name: 'common_fn.set_config', operation_params: { sysMsgId: '', args: '' } };
    let updateActionInfo = {
        build_payload_param: {
            build_type: 'simple',
            post_message_param: {
                things_selector,
                payload
            }
        },
        config: {
            send_message_action_id: 1
        },
        name: `${rule_id}-COMMAND-common_fn.set_config`,
        payload_script: '',
        payload_script_engine: '',
        payload_type: 0,
        status: 1,
        type: 'things-message',
        rule_id: rule_id,
        action_id: action_id,
        tenant_id: tenant_id,
    };
    // console.log(createActionInfo);
    // @ts-ignore
    updateSingleAlertAction(updateActionInfo).catch((err: any) => {
    });
}
