/**
 * 设备管理 deviceManagement
 */
export default {
  currentDevice: '当前设备',
  devStatus: {
    title: '设备状态',
    basic: '基本信息',
    edit: '修改',
    input: '请输入',
    collect: '收藏',
    notCollect: '未收藏',
    devClass: '设备类别',
    notNull: '不能为空',
    startRec: '开始记录',
    syncTime: '同步时间',
    clrDev: '清空SD卡记录数据',
    conDevice: '普通设备',
    gateDevice: '网关设备',
    stopRec: '停止记录',
    sendOrder: '正在发送设备命令...',
    responseOvertime: '设备响应超时，请稍候重试！',
    sendSuccess: '设备命令发送成功',
    sendFailed: '设备命令发送失败',
    leisure: '空闲',
    busy: '忙碌，设备刷新固件中',
    invalid: '无效',
    swiSuccess: '刷写成功',
    swiFailed: '刷写失败',
    getBasicFailed: '获取设备基本信息失败',
    editSuccess: '修改成功',
    editFailed: '修改失败',
    channelError: '获取通道信号失败'
  },
  command: {
    title: '命令发送',
    send: '立即发送',
    add: '添加到列表',
    sendList: '列表发送',
    idInvalid: '请输入有效帧ID，标准帧ID：0x0 ~ 0x7FF、扩展帧：ID0x0 ~ 0x1FFFFFFF',
    upToNum: '列表内最多可添加100条数据',
    tipForEven: '数据内容必须为偶数个字符',
    tipForCanLen: '请输入有效长度的数据，CAN范围0~8',
    tipForCanfdLen: '请输入有效长度的数据，CANFD范围0~8， 12， 16， 20， 24， 32， 48， 64'
  },
  devLog: {
    title: '设备日志',
    selectTimeTip: '请选择时间段',
    online: '在线',
    offline: '离线'
  },
  firmwareUpgrade: {
    selectFile: '选择文件',
    obtainSoftwareGroupFailed: '获取软件分组失败',
    descCannotBeEmpty: '描述信息不能为空',
    uploadFileCannotBeEmpty: '上传文件不能为空',
    firmwareVersionCannotBeEmpty: '固件版本不能为空',
    pleaseInputXX: '请输入xx.xx.xx （xx为1至2位数字）',
    pleaseInput2256: '请输入2-256',
    pleaseUploadDeviceFirmware: '请上传设备固件',
    uploadFirmware: '上传固件',
    pleaseInputFirmwareVersion: '请输入固件版本',
    addTimeNoticUpgradeFail: '新增定时通知固件升级失败',
    addTimeNoticUpgradeSuccess: '新增定时通知固件升级成功',
    waitDeviceUpdate: '通知升级成功，请等待设备自动升级成功',
    deleteFirmwareFailed: '固件删除失败',
    deleteFirmwareSuccess: '固件删除成功',
    modifyStatusSuccess: '成功修改状态',
    selfDevicefirmware: '自身设备固件',
    externaldevicefirmware: '外接设备固件',
    firmwareType: '固件类型',
    firmwareVersion: '固件版本',
    sureToDeleteFirmware: '确定删除固件版本为{version}的固件吗？',
    deleteFirmware: '确定删除固件',
    informFirmwareUpgrade: '{version}通知固件升级{time}',
    subDeviceType: '{gateway}的子设备（类型：{type}）',
    gatewayGroup: '网关分组',
    upgradeTimeCannotBeEmpty: '升级时间不能为空',
    upgradeObjCannotBeEmpty: '升级对象不能为空',
    additionalInformation: '附加信息',
    pleaseSelectWhetherDifferentialUpgrade: '请选择是否差分升级',
    differentialUpgrade: '差分升级',
    pleaseSelectDate: '请选择日期',
    upgradeTime: '升级时间',
    scheduledUpgrade: '定时升级',
    upgradeNow: '立即升级',
    pleaseSelectUpgradeMode: '请选择升级方式',
    upgradeMode: '升级方式',
    pleaseSelectUpgradeObject: '请选择升级对象',
    upgradeObject: '升级对象',
    typeGroup: '类型分组',
    softwareGroup: '软件分组',
    singleDevice: '单个设备',
    upgradeRange: '升级范围',
    upgradeVersion: '升级版本',
    upgradeFirmware: '升级固件',
    title: '固件升级',
    standardFirmware: '标准固件',
    customFirmware: '自定义固件',
    addFirmware: '添加固件',
    addFirmwareSuccess: '新增固件成功'
  },
  devConfig: {
    condition: '条件',
    frameIdCannotBeEmpty: '帧ID不能为空',
    acceptCodeCannotBeEmpty: '验收码不能为空',
    max8BitHex: '请输入最多8位十六进制',
    maskCodeCannotBeEmpty: '屏蔽码不能为空',
    extFrameIdOutRange: '输入的扩展帧ID超出范围，正常范围[0-0x1fffffff]',
    standardFrameIdOutRange: '输入的标准帧ID超出范围，正常范围[0-0x7ff]',
    customData: '自定义数据',
    networkParamConfig: '网络参数配置',
    flipRecordConfig: '翻转记录配置',
    saveDevConfigFail: '设备配置保存失败',
    saveDevConfigSuccess: '设备配置保存成功',
    devTimeout: '设备响应超时，请稍候重试',
    deliveredDevConfigError: '下发的设备配置项有误，请重新输入',
    exportDevConfigFail: '导出设备配置失败',
    exportDevConfigSuccess: '导出设备配置成功',
    realtimeDataConfig: '实时数据配置',
    netTransmissConfig: '网络传输配置',
    netConnectConfig: '网络连接配置',
    smsAlarmConfig: '短信报警配置',
    gpsRecordConfig: 'GPS记录配置',
    storageSpaceConfiguration: '存储空间配置',
    canRecordModeConfig: 'CAN记录模式配置',
    devRecordConfig: '设备记录配置',
    diChannelConfig: 'DI通道配置',
    doChannelConfig: 'DO通道配置',
    linChannelConfig: 'LIN通道配置',
    canChannelConfig: 'CAN通道配置',
    radiusRange: '半径范围[50,10000]',
    pleaseInputRadius: '请输入半径',
    latitudeRange: '纬度范围[-90,90]',
    pleaseInputLatitude: '请输入纬度',
    longitudeRange: '经度范围[-180,180]',
    pleaseInputLongitude: '请输入经度',
    outsideSpecifyArea: '指定区域外',
    withinSpecifyArea: '指定区域内',
    searchLoc: '搜索定位',
    centerPointRadius: '中心点半径(m)',
    gpsLatitude: 'GPS纬度(°)',
    gpsLongitude: 'GPS经度(°)',
    gpsAlarmRange: 'GPS报警范围',
    pleaseInputEndFrameId: '请输入结束帧ID',
    endFrameId: '结束帧ID',
    pleaseInputStartFrameId: '请输入起始帧ID',
    startFrameId: '起始帧ID',
    specifiedId: '指定ID',
    filterComputer: '滤波计算器',
    importFileContentEmpty: '导入的文件内容为空',
    pleaseImportCorrectFile: '请导入正确的文件内容，JSON转换错误',
    devConfigImportSuccess: '设备配置导入成功',
    uploadFileSizeExceed: '上传的文件大小不能超过10MB',
    uploadFileFormatError: '格式错误，仅支持JSON文件格式，请重新上传',
    dontModifyExportFileContent: '请勿擅自更改导出后的文件内容，否则导入时可能出错',
    periodRecordIdConfig: '定时记录ID配置',
    saveCustomData: '保存自定义数据',
    saveLatstData: '保存最近一次数据',
    pleaseInputHexIn16Char: '请输入十六个字符以内的十六进制数',
    pleaseInputCustomData: '请输入自定义数据',
    timerPeriodRange: '定时时间周期范围[10, 86400000]',
    pleaseInputTimerPeriod: '请输入定时时间周期',
    conditonNotSupportEdit: '此条件不支持编辑',
    fallingEdgeTrigger: '下降沿触发',
    riseEdgeTrigger: '上升沿触发',
    idDupicated: '配置的ID与已经配置的ID有重复，请重新添加',
    pretriggerIDConfig: '预触发ID配置',
    diTrigger: 'DI触发',
    keyTrigger: '按键触发',
    errFrameTrigger: '错误帧触发',
    idTrigger: 'ID触发',
    recordTimeRange: '记录时间范围[1, 8640000]',
    pretriggerRecordFrameCountRange: '预触发记录帧数范围[10, 100000]',
    pleaseInputPretriggerFrameCount: '请输入预触发记录帧数',
    stopRecordEndId: '停止记录结束ID',
    stopRecordingStartId: '停止记录起始ID',
    stopRecordCondition: '停止记录条件',
    stopRecordFrameType: '停止记录帧类型',
    stopRecordChannel: '停止记录通道',
    stopRecordingTrigger: '停止记录触发器',
    startRecordEndID: '开始记录结束ID',
    startRecordStartID: '开始记录起始ID',
    startRecordCondition: '开始记录条件',
    startRecordFrameType: '开始记录帧类型',
    startRecordChannel: '开始记录通道',
    startRecordTrigger: '开始记录触发器',
    noRecording: '不记录',
    scheduledRecording: '定时记录',
    pretriggerRecording: '预触发记录',
    conditionRecording: '条件记录',
    longTermRecording: '长时间记录',
    triggerRecordEndId: '触发记录结束ID',
    triggerRecordStartId: '触发记录起始ID',
    triggerRecordFrameType: '触发记录帧类型',
    triggerRecordCondition: '触发记录条件',
    triggerMode: '触发方式',
    triggerRecordChannel: '触发记录通道',
    diTriggerConfig: 'DI触发配置',
    recordingChannel: '记录通道',
    errFrameTriggerConfig: '错误帧触发配置',
    noDataInScheduledTime: '定时内无数据时',
    timingTimePeriod: '定时时间周期(ms)',
    recordingDuration: '记录时间(ms)',
    preTriggerRecordFrames: '预触发记录帧数',
    recordMode: '记录模式',
    pleaseInputCorrectMobileNumber: '请输入正确的手机号码',
    pleaseInputAlarmPhoneNumber: '请输入报警手机号码',
    gpsAreaWarning: 'GPS 区域告警',
    sdCardException: 'SD 卡异常',
    canBusError: 'CAN 总线错误',
    triggerAlarmFunction: '触发报警功能',
    alarmPhoneNumber: '报警手机号码',
    smsChannel: '短信通道',
    recordFileSizeRange: '记录文件大小范围[1,512]',
    pleaseInputRecordFileSize: '请输入记录文件大小',
    merge: '合并',
    noMerge: '不合并',
    record: '记录',
    doNotRecord: '不记录',
    stopRecording: '停止记录',
    loopRecord: '循环记录',
    canChannelDataMerge: 'CAN通道数据合并',
    whetherLogCANErr: '是否记录CAN错误',
    recordFileSize: '记录文件大小(MB)',
    storageSpaceFullHandle: '存储空间满处理',
    enableTransparentTransmission: '数据透传使能',
    storageTotalRange: '储存空间总分配范围[0,100]',
    clickAdjustTo100per: '点击按比例调整到100%',
    curTotalAlloc: '当前总分配',
    pleaseInputCorrectAddress: '请输入正确的地址',
    domainNameServer: '域名服务器',
    defaultGateway: '默认网关',
    subnetMask: '子网掩码',
    ipAddress: 'IP地址',
    automaticIPAcquisition: '自动获取IP',
    manuallySetIP: '手动设置IP',
    enableLTE: '启用LTE',
    pleaseInputTimeout: '请输入500-60000的超时时间',
    pleaseInputTransCompressTimeout: '请输入传输压缩超时时间',
    pleaseInputResumableCacheSize: '请输入1-100的续存缓存',
    pleaseInputOfflineResumableCacheSize: '请输入断网续传缓存大小',
    transmissionCompressionTimeout: '传输压缩超时时间',
    enableTransmissionCompression: '传输压缩使能',
    resumableUploadCacheSize: '断网续传缓存大小',
    enableContinuousTrans: '续传使能',
    protocolNumber: '协议编号：001',
    unencryption: '不加密',
    times: '次',
    nonContinuousTransmission: '不续传',
    enableNetworkDebugLog: '启用网络调试日志',
    enableAbsoluteTimestamp: '数据携带设备ID和绝对时间戳使能',
    encryptionConfig: '加密配置',
    successivePassesNumber: '续传次数',
    startTimeLessThanEndTime: '起始时间必须小于结束时间',
    pleaseInputCorrectExtFrameEndId: '请输入正确的扩展帧结束ID范围[0-0x1FFFFFFF]',
    pleaseInputCorrectStandardFrameEndId: '请输入正确的标准帧结束ID范围[0-0x7FF]',
    pleaseInputEndId: '请输入结束ID',
    pleaseInputCorrectExtFrameStartId: '请输入正确的扩展帧起始ID范围[0-0x1FFFFFFF]',
    pleaseInputCorrectStandardFrameStartId: '请输入正确的标准帧起始ID范围[0-0x7FF]',
    pleaseInputCorrectHexNum: '请输入正确的十六进制数',
    pleaseInputStartId: '请输入起始ID',
    timePeriodFilter: '时间段滤波',
    idFilter: 'ID 滤波',
    extendedFrame: '扩展帧',
    standardFrame: '标准帧',
    outsideRange: '在范围外',
    withinRange: '在范围内',
    greaterOrEqual: '大于等于',
    greater: '大于',
    lessOrEqual: '小于等于',
    less: '小于',
    unequal: '不等于',
    equal: '等于',
    outsideTimeRange: '时间范围外',
    withinTimeRange: '时间范围内',
    filterEndID: '滤波结束ID',
    filterStartID: '滤波起始ID',
    filterFrameType: '滤波帧类型',
    filterCondition: '滤波条件',
    filterChannel: '滤波通道',
    filterType: '过滤类型',
    triggerCondition: '触发条件',
    addFilterCondition: '添加过滤条件',
    uploadDataFiltering: '上传数据过滤',
    remotePortRange: '远程端口范围[1,65535]',
    pleaseInputRemotePort: '请输入远程端口',
    pleaseInputCorrectAddr: '请输入正确的地址',
    localPortRange: '本机端口号范围[0,65535]',
    pleaseInputLocalPort: '请输入本机端口',
    remotePort: '远程端口',
    remoteAddress: '远程地址',
    localPort: '本机端口',
    connect: '连接',
    dataLength: '数据长度',
    recordPeriodRange: '记录周期的范围是[1,1800]',
    pleaseInputRecordPeriod: '请输入记录周期',
    bigDipper: '北斗',
    autoSelect: '自动选择',
    recordCycle: '记录周期(s)',
    positionSystemType: '定位系统类型',
    max64Filters: '最多只能设置64条过滤项，请删除部分条目后再试',
    enableRolloverLog: '启用翻转记录',
    close: '闭合',
    break: '断开',
    gpsArea: 'GPS区域',
    memoryEtatusException: '存储状态异常',
    busError: '总线错误',
    recordFull: '记录满',
    doAlarmOutputFunction: 'DO报警输出功能',
    doAlarmOutputValue: 'DO报警输出值',
    recordDIInput: '记录DI输入',
    simulateKeyboard: '模拟按键',
    recordGap: '记录间隔',
    diFunction: 'DI功能',
    standardFrameGroupId: '标准帧组ID',
    standardFrameId: '标准帧单ID',
    extendedFrameGroupId: '扩展帧组ID',
    extendedFrameId: '扩展帧单ID',
    endId: '结束 ID',
    startId: '起始ID',
    filterFormat: '过滤格式',
    blackList: '黑名单',
    whiteList: '白名单',
    editFilterId: '编辑滤波ID配置',
    addFilterId: '添加滤波ID配置',
    filterMode: '滤波模式',
    enableFiltering: '开启滤波',
    max8Filters: '最多只能设置8条过滤项，请删除部分条目后再试',
    customBaudRate: '自定义波特率',
    standardFrameIdFreeSetting: '标准帧 ID REM自由设定',
    extendedFrameIdFreeSetting: '扩展帧 ID REM自由设定',
    extendedFrameIdId: '扩展帧 单ID ID',
    standardFrameIdId: '标准帧 单ID ID',
    acceptAllData2: '接受所有数据',
    acceptAllData: '接受所有数据',
    maskCode: '屏蔽码(HEX)',
    acceptanceCode: '验收码(HEX)',
    onlyListen: '只听',
    normal: '正常',
    addFilterRule: '添加滤波规则',
    enableTerminalResistance: '终端电阻使能',
    baudRate: '波特率',
    canWorkMode: 'CAN工作模式',
    channelEnable: '通道使能',
    pleaseSelectBaudRate: '请选择波特率',
    error: '误差',
    actualValue: '实际值',
    samplePoint: '采样点',
    complyWithTSEG2: '符合TSEG2 >= SJW规则',
    tertiarySampling: '三次采样',
    syncJumpWidth: '同步跳转宽度',
    mhzValue: 'MHz值',
    canClock: 'CAN时钟',
    calculate: '计算',
    expectedBaudRate: '波特率期待值',
    baudrateCalculator: '波特率计算器',
    title: '设备配置'
  }
}
