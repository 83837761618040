import ZWSBaseModule from '../httpBase/ZWSBaseModule'

/**
 * 子用户管理
 */
class ZWSControl extends ZWSBaseModule {

  /******************************************************** clientToken ************************************************************/
  /******************************************************** myPrivilege ************************************************************/
  /**
   * 获取当前用户的授权信息
   * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
   */

  static myPrivilege_getPrivilegeSelf() {
    return this.fetch({
      url: `/control/privileges/self`,
      method: 'get',
    })
  }

  /******************************************************** session ************************************************************/
  /**
    * 子用户、管理员登录
    * @param {Object} type - 1 账户密码登录 2 手机验证码登录
    * @param {string} tenant - 租户名。当使用手机验证码登录时组户名可以为空
    * @param {string} username - 用户名或手机号
    * @param {string} password - 密码或手机验证码
    * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
    */
  static session_addSessions({ tenant, username, password, type }: { tenant?: string, username: string, password: string, type: number }) {
    return this.fetch({
      url: '/control/sessions',
      method: 'post',
      data: { tenant, password, type, username }
    })
  }
  /**
   * 普通租户管理员登录（ujwt优先于body）
   * @param {number} type - 1 账户密码登录 2 手机验证码登录
   * @param {string} username - 用户名或手机号
   * @param {string} password - 密码或手机验证码
   * @param {string} ujwt - 第三方登录
   */
  static session_addSessionsForTenantManager({ password = '', type = 1, username = '', ujwt }: { password?: string, type?: number, username?: string, ujwt?: string }) {
    const ujwtQuery = ujwt ? `?ujwt=${ujwt}` : ''
    return this.fetch({
      url: `/control/sessions/tenant-manager${ujwtQuery}`,
      method: 'post',
      data: { password, type, username }
    })
  }
  /**
   * 退出登录
   * @param {string} sessionId
   */
  static session_deleteSessions({ sessionId, ujwt }: { sessionId: string, ujwt?: string }) {
    return this.fetch({
      url: `/control/sessions/${sessionId}`,
      method: 'delete',
      params: { ujwt }
    })
  }

  /**
   * 只允许管理员调用接口
   * @param param0 
   * @returns 
   */
  static session_getSuperSession({ username }: { username: string }) {
    return this.fetch({
      url: '/control/sesssions/super-session',
      method: 'get',
      params: { username }
    })
  }

  /******************************************************** tenantAPI ************************************************************/
  /**
 * 查询租户API列表
 * @param {number} params.current_page - 当前页。从第1页开始,Default value : 1
 * @param {number} params.page_size - 每页数量,Default value : 20
 * @param {string} params.search - 关键字查询，模糊匹配。涉及名称和描述
 * @param {string} params.service - 所属服务，模糊匹配
 * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
 */
  static tenantAPI_getTenantApis({ current_page, page_size, search, service }: { current_page: number, page_size: number, search?: string, service?: string }) {
    return this.fetch({
      url: `/control/tenant-apis`,
      method: 'get',
      params: { current_page, page_size, search, service }
    })
  }

  /******************************************************** tenantRole ************************************************************/
  /**
 * 查询租户下全部角色信息
 * @param {number} params.current_page - 当前页。从第1页开始,Default value : 1
 * @param {number} params.page_size - 每页数量,Default value : 20
 * @param {string} params.prefix - 模糊匹配的关键字
 * @param {number} params.tenantId  - 租户id
 * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
 */
  static tenantRole_getBatchRolesList({ current_page, page_size, prefix, tenant_id }: { current_page: number, page_size: number, prefix?: string, tenant_id: number }) {
    return this.fetch({
      url: `/control/tenants/${tenant_id}/roles`,
      method: 'get',
      params: { current_page, page_size, prefix }
    }, 'control:tenant-role:query')
  }
  /**
 * 查询租户下单个角色信息
 * @param {string} params.platform - 所属系统key；为空则默认通用云
 * @param {number} params.tenantId  - 租户id
 * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
 */
  static tenantRole_getSingleRole({ platform, tenant_id, role_id }: { platform: string, tenant_id: number, role_id: number }) {
    return this.fetch({
      url: `/control/tenants/${tenant_id}/roles/${role_id}`,
      method: 'get',
      params: { platform }
    }, 'control:tenant-role:query')
  }

  /**
  * 在租户下创建角色
  * @param {string} tenantId - 租户id
  * @param {string} body.name - 角色名
  * @param {string} body.description - 角色描述
  * @param {array} body.access - 角色权限
  * @param {number} body.auth_flag - 授权标志，默认0；0-默认，1-自动；当该参数设置为1时，注册或添加子用户后，子用户将自动拥有该角色；该字段创建后不允许修改
  * @param {string} body.platform - 所属系统key；为空则默认通用云
  * @param {number} body.type - 角色类别 对应用户的用户类别 1 普通子用户的角色 2 权限子用户的角色（用于单独做权限管理的）
  * @param {object} body.menu - 菜单权限
  * @param {string} body.menu.attachment - 菜单附加信息（可选）
  * @param {string} body.menu.menu - 角色菜单
  * @param {string} body.menu.platform - 所属系统key；为空则默认通用云
  * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
  */
  static tenantRole_addSingleRole({ tenantId, name, description, access ,auth_flag, menu, platform, type }: { tenantId: number, name: string, description: string, access: Array<number>, auth_flag: number, menu: object, platform: string, type: number,  }) {
    return this.fetch({
      url: `/control/tenants/${tenantId}/roles`,
      method: 'post',
      data: { name, description, access,auth_flag, menu, platform, type }
    }, 'control:tenant-role:create')
  }
  /**
  * 更新角色信息
  * @param {number} tenantId - 租户id
  * @param {number} roleId - 角色id
  * @param {string} body.name - 角色名
  * @param {string} body.description - 角色描述
  * @param {array} body.access - 角色权限
  * @param {number} body.auth_flag - 授权标志，默认0；0-默认，1-自动；当该参数设置为1时，注册或添加子用户后，子用户将自动拥有该角色；该字段创建后不允许修改
  * @param {string} body.platform - 所属系统key；为空则默认通用云
  * @param {number} body.type - 角色类别 对应用户的用户类别 1 普通子用户的角色 2 权限子用户的角色（用于单独做权限管理的）
  * @param {object} body.menu - 菜单权限
  * @param {string} body.menu.attachment - 菜单附加信息（可选）
  * @param {string} body.menu.menu - 角色菜单
  * @param {string} body.menu.platform - 所属系统key；为空则默认通用云
  * @return {Promise<Response, Error>} - response: { result: true, message: '', jwt: '', data: {@link UserAccess} }
  */
  static tenantRole_updateSingleRole({ roleId, tenantId, name, description, access, auth_flag, menu, platform, type }: { roleId: number, tenantId: number, name: string, description: string, access: Array<number>, auth_flag: number, menu: object, platform: string, type: number, }) {
    return this.fetch({
      url: `/control/tenants/${tenantId}/roles/${roleId}`,
      method: 'put',
      data: { name, description, access,auth_flag, menu, platform, type }
    }, 'control:tenant-role:update')
  }
  /**
   * 删除角色
   * @param {Object} params
   * @param {string} params.ids - id列表，多个用逗号分隔
   * @param {number} params.tenantId - 租户id
   * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link Subuser} }
   */
  static tenantRole_deleteBatchRoles({ tenantId, ids }: { tenantId: number, ids: string }) {
    return this.fetch({
      url: `/control/tenants/${tenantId}/roles/${ids}`,
      method: 'delete'
    }, 'control:tenant-role:delete')
  }

  /**
   * 查询租户下的全部角色
   * @param param0 
   * @returns 
   */
  static tenantRole_getCommonRoles({ current_page, page_size, platform, prefix, type }: { current_page: number, page_size: number, platform: string, prefix: string, type: number }) {
    return this.fetch({
      url: '/control/tenants/common-roles',
      method: 'get',
      params: { current_page, page_size, platform, prefix, type }
    })
  }

  /******************************************************** userPrivilege ************************************************************/
  /**
  * 获取租户下用户已授权菜单
  * @param {string} params.userId  - 用户id
  * @param {string} params.tenantId  - 租户id
  * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
  */
  static userPrivilege_getSingleSubuserMenus({ userId, tenantId }: { userId: number, tenantId: number }) {
    return this.fetch({
      url: `/control/tenants/${tenantId}/users/${userId}/menus`,
      method: 'get',
    }, 'control:user-menu:query')
  }
  /**
  * 更新租户下用户的菜单信息
  * @param {Object} data - 角色信息
  * @param {string} tenantId - 租户id
  * @param {array} userId - 用户id
  * @param {array} data - 菜单内容
  * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
  */
  static userPrivilege_updateSingleUserBindMenus({ tenantId, userId, menu, attachment }: { tenantId: number, userId: number, menu: string, attachment?: string }) {
    return this.fetch({
      url: `/control/tenants/${tenantId}/users/${userId}/menus`,
      method: 'put',
      data: [{ menu, attachment }]
    }, 'control:user-menu:update')
  }
  /**
  * 获取租户下用户已授权信息（授权角色列表）
  * @param {string} params.userId  - 用户id
  * @param {string} params.tenantId  - 租户id
  * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
  */
  static userPrivilege_getBatchSubuserRoles({ userId, tenantId }: { userId: number, tenantId: number }) {
    return this.fetch({
      url: `/control/tenants/${tenantId}/users/${userId}/roles`,
      method: 'get',
    }, 'control:user-role:query')
  }
  /**
  * 更新租户下用户的角色信息
  * @param {Object} data - 角色信息
  * @param {string} tenantId - 租户id
  * @param {array} userId - 用户id
  * @param {array} role_ids - 角色id
  * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
  */
  static userPrivilege_updateSingleBindRoles({ tenantId, userId, role_ids }: { tenantId: number, userId: number, role_ids: Array<number> }) {
    return this.fetch({
      url: `/control/tenants/${tenantId}/users/${userId}/roles`,
      method: 'put',
      data: role_ids
    }, 'control:user-role:update')
  }
  /**
  * 租户下用户批量授权角色
  * @param {Object} data - 角色信息
  * @param {string} tenantId - 租户id
  * @param {array} role_ids - 角色id
  * @param {array} user_ids - 用户id
  * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
  */
  static userPrivilege_updateBatchBindPrivileges({ tenantId, role_ids, user_ids }: { tenantId: number, role_ids: Array<number>, user_ids: Array<number> }) {
    return this.fetch({
      url: `/control/tenants/${tenantId}/privileges`,
      method: 'put',
      data: { role_ids, user_ids }
    })
  }
  /******************************************************** userCenter ************************************************************/
  /**
   * 获取用户中心支持的第三方登录方式
   * @param { string } backPage - 子系统最终回调页url,url后需要带sysType参数控制用户中心页面模式,0默认,1简单(例:https://developer.zlg.cn/zStation/?sysType=1)
   */
  static userCenter_getThirdSupport({ backPage }: { backPage: string }) {
    return this.fetch({
      url: `/control/usercenter/third-support`,
      method: 'get',
      params: { backPage }
    })
  }
  /**
   * 通过UC获取验证码(目前仅用于租户管理员验证码登录的场景)
   * @param { string } destination - minLength: 4 maxLength: 100
   * @param { string } region - cn - 国内短信 intl - 国际短信
   * @param { number } type - 1 短信，2 邮件(预留)
   */
  static userCenter_getVerificationCodes(data: { destination: string, region: string, type: number }) {
    return this.fetch({
      url: `/control/usercenter/verification-codes`,
      method: 'post',
      data
    })


  }

}

export default ZWSControl
