/**
 * DBC管理
 */
export default {
  dbcFile: 'Signaling File',
  addDBCFile: 'Add File',
  effectOfDeletion: 'Deleting the DBC file has the following impacts on the corresponding modules:',
  clearingCurveAnalysis: '1. Clear the panel associated with the DBC file from the curve analysis',
  clearHistoricalData: '2. Clear historical data related to DBC file parsing from DBC data',
  clearTheTriggering: '3. Clear the DBC triggering rule for the corresponding channel in the triggering rule',
  sureToContinue: 'Are you sure you want to continue?',
  updateFile: 'Update File',
  overlayFile: 'After the successful update, the current file information will be overwritten. Do you want to update it?',
  obtainDBCFileListFailed: 'Failed to obtain the DBC file list',
  selectFileNameBy: 'Select the file whose name must be {name}',
  updateDBCFileSuccess: 'The DBC file is updated successfully',
  updateDBCFileFailed: 'Failed to update the DBC file, {error}',
  downloadDBCFileSuccess: 'Downloading the DBC file succeeds',
  deleteDBCFileSuccess: 'Deleting the DBC file succeeds',
  deleteDBCFileFailed: 'Failed to delete the DBC file, {error}',
  mustBeDBCFile: 'The file type must be *.dbc',
  existsDBCFileName: 'The DBC file name already exists',
  uploadDBCFileSuccess: 'Uploading the DBC file succeeds',
  uploadDBCFileFailed: 'Failed to upload the DBC file',
  checkDBCFileNameFailed: 'Failed to check the DBC file name',
  notEmptyDBCFile: 'The DBC file cannot be empty',
  selectDBCFile: 'Please select the DBC file',
  loadMore: 'Load More',
  Strategic: 'Signaling Strategies',
  addStrategic: 'Add signal strategy',
  deleteStrategic: 'Deletion signal policy',
  deleteStrategicTip: 'Are you sure you want to remove this signaling policy?',
  strategicUsedTip: 'A signal policy is in use and cannot be deleted.',
  getStrategicFunctionError: 'Failed to obtain the signal policy function block.',
  deleteStrategicSuccess: 'Deleting a signal policy succeeded.',
  deleteStrategicError: 'Description Failed to delete the signal policy function block',
  strategicName: 'Policy Name',
  associateDBC: 'Associated DBC ',
  signalNumber: 'Signal Number',
  remark: 'Remark',
  signalStrategicName: 'Policy Name',
  signalStrategicNameInputTip: 'Please enter the signal policy name, the default prefix is DBCSIG',
  signalStrategicDisplayName: 'Policy Nickname',
  signalStrategicDisplayNameInputIip: 'Please enter a signal policy nickname',
  signalStrategicRemark: 'Strategy Description',
  signalStrategicRemarkInputTip: 'Please enter a signal policy description',
  signalStrategicFunctionDeficiency: 'The signal policy function block information is missing.',
  signalStrategicMessageDeficiency: 'Signal policy information is missing.',
  signalStrategicMessageError: 'The signal policy information is incorrect.',
  createSignalStrategicSuccess: 'Creating a signal policy succeeded.',
  signalStrategicComplementError: 'Failed to complete signal policy information.',
  associateDefaultFunctionFailed: 'Failed to associate the default function block.',
  signalStrategicExist: 'The signal policy already exists',
  createSignalStrategicError: 'Failed to create a signal policy.',
  signalStrategicLoss: 'Unable to save, complete information of signal strategy object model is lost.',
  setSignalStrategicFailed: 'Failed to save. Failed to set signal policy information.',
  saveSignalStrategicFailed: 'Failed to save. Failed to set signal policy information.',
  updateStrategicSuccess: 'Modifying the signal policy succeeded.',
  updateStrategicMessageFeail: 'Failed to update signal policy information.',
  updateStrategicFailed: 'Failed to modify the signal policy.',
  selectOneDBCFile: 'Please select a DBC file',
  signalNumberBetween: 'Select a DBC file. The number of signal points must be between 1 and 100',
  signalStrategicNameInput: 'Please enter a signal policy name',
  signalStrategicNameInputValidator: 'The name contains letters, digits, and hyphens (") and cannot start with a digit',
  signalStrategicDisplayInput: 'Please enter a signal policy nickname',
  create: 'Create',
  getDBCFailed: 'Failed to obtain the DBC configuration.',
  getDbCFileFailed: 'Failed to obtain the DBC file list.',
  script: 'Script'
}
