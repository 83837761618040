import ZWSBaseModule from '../httpBase/ZWSBaseModule'
import { Pagination } from './ZWSPagination'


export enum DataTransformEngine {
    Groovy = "groovy",
    Javascript = "javascript"
}

export enum DataTransformStatus {
    Disable,
    Enable,
}

export enum DataTransformType {
    Query = 1,
    Export
}

export interface DataTransform {
    id: number
    engine_name: DataTransformEngine
    export_template: string // 导出模板,类型导出有意义
    file_suffix: string     // 文件后缀,类型导出有意义
    name: string,
    status: DataTransformStatus | boolean
    type: DataTransformType
    script: string
    [propName: string]: any
}

export interface GetPagePluginsResp {
    list: DataTransform[],
    pagination: Pagination
}
export interface GetThingsDataResp {
    list: any[]
    pagination: Pagination
}

export interface Dashboard {
    business_type: string // 业务类型
    dashboard_id: number  // 所属面板ID，一个面板可包含多个看板
    id: number            // 看板自身ID
    [propName: string]: any
}

export interface DeviceData {
    begin_time?: number,
    category_id: number,
    _id?: string,
    deal_user: string,
    deal_way: string,
    timestamp: number,
    end_time?: number,
    event_name: string,
    info_model_id: number,
    tenant_id: number,
    things_id: number,
    third_things_id: number,
    property?: string,
    value?: string,
    op?: string
}
export type EsRequestItem = {
    id: string
    timestamp: string
}
export type DeviceDataReq = {
    
    group_id?: number
    query_by_group?: boolean
    batch_es_requests: EsRequestItem[]

} & DeviceData
export type GetDataDashbordPanelResp = Dashboard[]

/**
  * 设备管理
  */
class ZWSDataMgmt extends ZWSBaseModule {

    /************* customDataDownloadTemplate ***************************************************************/

    /**
     * 获取自定义导出模板
     * @param param0 
     * @returns 
     */
    static customDataDownloadTemplate_getCustomDownloadTemplate({ event_name, info_model_id, tenantId, current_page, page_size }: { event_name: string, info_model_id: number, tenantId: number, current_page: number, page_size: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/custom-download-template`,
            method: 'get',
            params: { event_name, info_model_id, tenantId, current_page, page_size }
        })
    }

    /**
     * 获取自定义导出模板详情
     * @param param0 
     * @returns 
     */
    static customDataDownloadTemplate_getCustomDownloadTemplateById({ id, tenantId }: { id: number, tenantId: number }){
        return this.fetch({
            url: `/data-mgmt/tenants/${id}/custom-download-template/${tenantId}`,
            method: 'get'
        })
    }
    
    /**
     * 创建自定义导出模板
     * @param param0 
     * @returns 
     */
    static customDataDownloadTemplate_addCustomDownloadTemplate({ tenantId, body }: { tenantId: number, body: any }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/custom-download-template`,
            method: 'post',
            data: body
        })
    }

    /**
     * 修改自定义导出模板
     * @param param0 
     * @returns 
     */
    static customDataDownloadTemplate_updateCustomDownloadTemplateById({ id, body, tenantId }: { tenantId: number, body: any, id: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/custom-download-template/${id}`,
            method: 'put',
            data: body
        })
    }

    /**
     * 删除自定义导出模板
     * @param param0 
     * @returns 
     */
    static customDataDownloadTemplate_deleteCustomDownloadTemplateById({ id, tenantId }: { id: number, tenantId: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/custom-download-template/${id}`,
            method: 'delete'
        })
    }

    /************* dataCleaning ******************************************************************************/

    /**
     * 当前执行的删除任务
     * @param tenant_id 
     * @returns 
     */
    static dataCleaning_getCurrentCleanTask(tenant_id: number) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/current_clean_task`,
            method: 'get'
        })
    }

    // 清理things数据 (异步删除)
    static dataCleaning_deleteThingsData({ tenant_id, info_model_id, things_id, start_date, end_date, event_name, property, value, op, cleanChild, limitDeleteMaxDoc }: { tenant_id: number, info_model_id: number, things_id: number, start_date: number, end_date: number, event_name?: string, property?: string, value?: string, op?: string, cleanChild?: boolean, limitDeleteMaxDoc?: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/info-model/${info_model_id}/things/${things_id}/data`,
            method: 'delete',
            params: { start_date, end_date, event_name, property, value, op, cleanChild, limitDeleteMaxDoc }
        })
    }

    // 快速清除情况
    static dataCleaning_createQuickDeleteTask({ tenant_id, end_timestamp, things_id, delete_children }: {tenant_id: number, end_timestamp?: number, things_id: number, delete_children: number}) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/quick_delete_task`,
            method: "post",
            data: { end_timestamp, things_id, delete_children }
        })
    }

    /**
     * 当前执行的删除任务
     * @param param0 
     * @returns 
     */
    static dataCleaning_getDocumentRemaining({ tenantId, taskIds }: {tenantId: number, taskIds: string}) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/task/document_remaining`,
            method: 'get',
            params: { taskIds }
        })
    }

    /**
     * 等待执行的删除任务
     * @param tenantId 
     * @returns 
     */
    static dataCleaning_getTodoCleanTaskList(tenantId: number) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/todo_clean_task_list`,
            method: 'get',
        })
    }

    /************* dataTransform ******************************************************************************/

    // 可用转换插件列表
    static dataTransform_getPluginsByType({ tenant_id, info_model_id, type }: { tenant_id: number, info_model_id: number, type: DataTransformType }) {
        let infoModelId = info_model_id
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/data-transform-plugin`,
            method: 'get',
            params: { infoModelId, type }
        }, "data-mgmt:data-transform-plugin:query")
    }

    // 创建转换插件
    static dataTransform_createSinglePlugin({ tenantId, dataTransform }: { tenantId: number, dataTransform: DataTransform }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/data-transform-plugin`,
            method: 'post',
            data: dataTransform
        },"data-mgmt:data-transform-plugin:create")
    }

    // 更新插件
    static dataTransform_updateSinglePlugin({ tenantId, id, dataTransform }: { tenantId: number, id: number, dataTransform: DataTransform }) {
        if (typeof dataTransform.status == 'boolean') {
            dataTransform = JSON.parse(JSON.stringify(dataTransform))
            dataTransform.status = dataTransform.status ? DataTransformStatus.Enable : DataTransformStatus.Disable
        }

        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/data-transform-plugin/${id}`,
            method: 'put',
            data: dataTransform
        },"data-mgmt:data-transform-plugin:update")
    }

    // 插件
    static dataTransform_deleteSinglePlugin({ tenantId, id }: { tenantId: number, id: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/data-transform-plugin/${id}`,
            method: 'delete',
        },"data-mgmt:data-transform-plugin:delete")
    }

    // 获取插件列表中的一页
    static dataTransform_getPagePlugins({ tenantId, infoModelId, type, name = '', current_page, page_size }: { tenantId: number, infoModelId: number, type: DataTransformType, name: string, current_page: number, page_size: number, dataTransform: DataTransform }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/data-transform-plugin/page`,
            method: 'get',
            params: { name, infoModelId, type, current_page, page_size }
        }, "data-mgmt:data-transform-plugin:query").then((res: GetPagePluginsResp) => {
            res.list = res.list ?? []
            res.list.forEach((data: DataTransform) => {
                data.status = data.status ? true : false
            })

            return res
        })
    }

    /************* StatisticsExportTransform ***************************************************************************************/
    /**
     * 查询脚本列表
     * @param {*} params
     * @param {number} params.statistics_method_id - 统计方法id
     * @param {number} params.type - 1查询; 2下载
     */
    static statisticsExportTransform_getStatisticsExportTransformPluginList({ current_page, page_size, statistics_method_id, tenant_id, type, name }: { current_page: number, page_size: number, statistics_method_id: number, tenant_id: number, type?: number, name?: string }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/statistics-export-transform-plugin`,
            method: 'get',
            params: { current_page, name, page_size, statistics_method_id, type }
        })
    }
    /**
     * 创建脚本
     */
    static statisticsExportTransform_addDataStatisticsExportTransformPlugin({ tenant_id, engine_name, name, script, statistics_method_id, type, file_suffix, export_template } : { tenant_id: number, engine_name: string, name: string, script: string, statistics_method_id: number, type: number, file_suffix: string, export_template: string }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/statistics-export-transform-plugin`,
            method: 'post',
            data: { engine_name, name, script, statistics_method_id, type, file_suffix, export_template }
        })
    }
    /**
     * 获取脚本详情
     */
    static statisticsExportTransform_getStatisticsExportTransformPlugin({ id, tenant_id }: { id: number, tenant_id: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/statistics-export-transform-plugin/${id}`,
            method: 'get',
        })
    }
    /**
     * 更新脚本详情
     */
    static statisticsExportTransform_updateStatisticsExportTransformPlugin({ id, tenant_id, engine_name, name, script, statistics_method_id, type, file_suffix, export_template }: { id: number, tenant_id: number, engine_name: string, name: string, script: string, statistics_method_id: number, type: number, file_suffix: string, export_template: string }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/statistics-export-transform-plugin/${id}`,
            method: 'put',
            data: { engine_name, name, script, statistics_method_id, type, file_suffix, export_template }
        })
    }
    /**
     * 删除脚本详情
     */
    static statisticsExportTransform_deleteStatisticsExportTransformPlugin({ id, tenant_id }: { id: number, tenant_id: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/statistics-export-transform-plugin/${id}`,
            method: 'delete',
        })
    }
    /**
     * 下载统计结果
     */
    static statisticsExportTransform_downloadData({ tenantId, methodId, taskId, begin_time, end_time, data_transform_id, user_id, statisticalDimension, dimensionIds, statistical_time_type }: { tenantId: number, methodId: number, taskId: number, begin_time: number, end_time: number, data_transform_id: number, user_id: number, statisticalDimension?:any, dimensionIds?:any, statistical_time_type?:any }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/statistics/method/${methodId}/tasks/${taskId}/data/download`,
            method: 'post',
            data: { method_id: methodId, tenant_id: tenantId, task_id: taskId, begin_time, end_time, data_transform_id, user_id, statistical_dimension:statisticalDimension, dimension_id_list:dimensionIds, statistical_time_type }
        },"data-mgmt:statistic-data:download")
    }
    /**
     * 查询下载结果
     */
    static statisticsExportTransform_getDownloadInfo({ downloadId , tenantId, methodId, id }: { id: number, tenantId: number, methodId: number, taskId: number, downloadId: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/statistics/method/${methodId}/tasks/${id}/data/downloading/${downloadId}`,
            method: 'get',
        })
    }

    /************* jobHandler ****************************************************************************************/
    /************* statistics ***************************************************************************************/
    /**
     * 历史查询
     * @param {*} params
     * @param {string} params.interval - 自定义间隔(毫秒) <= 1天
     * @param {string} params.calendar_interval - 自定义间隔 >=1天, 1y一年,1M一个月,1d一天
     */
    static statistics_getHistory({ begin_time, end_time, tenant_id, third_things_id, things_id_list, info_model_id, interval = 0, calendar_interval }: { begin_time: number, end_time: number, tenant_id: number, third_things_id: String, things_id_list: string, info_model_id: number, interval: number, calendar_interval: string }) {
        return this.fetch({
            url: `/data-mgmt/statistics/history`,
            method: 'get',
            params: { begin_time, end_time, tenant_id, third_things_id, interval, info_model_id, things_id_list, calendar_interval }
        })
    }
    /**
     * 获取租户用量排行
     */
    static statistics_getTenantFlow({ begin_time, end_time, current_page, page_size, tenant_name }: { begin_time: number, end_time: number, current_page: number, page_size: number, tenant_name: String }) {
        return this.fetch({
            url: `/data-mgmt/statistics/tenant`,
            method: 'get',
            params: { begin_time, end_time, current_page, page_size, tenant_name }
        })
    }
    /**
     * 查询租户下设备排行
     */
    static statistics_getTenantDeviceRanking({ begin_time, end_time, current_page, page_size, tenant_id }: { begin_time: number, end_time: number, current_page: number, page_size: number, tenant_id: number }) {
        return this.fetch({
            url: `/data-mgmt/statistics/tenant/${tenant_id}/things`,
            method: 'get',
            params: { begin_time, end_time, current_page, page_size }
        })
    }
    /************* tenantUsage ***********************************************************************************************/
    /**
     * 查询租户的当前累计用量
     * @param {string} name - 名称，模糊匹配
     * @param {number} status - 状态。1：正常， 2：待审核3：停用
     * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
     */
    static tenantUsage_getTenantDatamgmtUsageLatest({ tenantId, _timeout }: any) {
        // debugger
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/usage/latest`,
            method: 'get',
            timeout: _timeout || 120000
        })
    }
    /**
     * 查询租户一段时间的使用明细
     * @param {number} params.tenantId  租户id
     * @param {number} params.agg_type 查询类型 1:年，2:月，3:日，4:小时(查询时间24小时内)
     * @param {number} parms.end_date 结束日期
     * @param {number} parms.start_date 起始日期
     * @param {number} params.page_size 每页条数
     * @param {number} params.current_page 当前页数
     * @returns {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
     */
    static tenantUsage_getTenantUsageListByTime({ tenantId, agg_type, end_date, start_date, page_size, current_page }: any) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/usage/jrnl`,
            method: 'get',
            params: { agg_type, end_date, start_date, page_size, current_page }
        })
    }

    /**
     * 查询租户的用量百分比用于页面告警
     * @param tenantId 
     * @returns 
     */
    static tenantUsage_getTenantpercentage(tenantId: number) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/usage/percentage`,
            method: 'get'
        })
    }

    /************* usageStatistics ******************************************************************************************/

    static usageStatistics_getUsageStatisticsByTenant(tenant_id: string) {
        return this.fetch({
            url: '/data-mgmt/tenants/all/tenant-usage-statistics',
            method: 'get',
            params: { tenant_id }
        })
    }

    /** 
     *  查询全部租户的用量统计
     */
    static usageStatistics_getAllUsageStatistics() {
        return this.fetch({
            url: '/data-mgmt/tenants/all/usage-statistics',
            method: 'get'
        })
    }
    /************* userResource *******************************************************************************/
    /**
    * 获取指定用户绑定的大盘id列表
    * @param {*} params
    * @param {string} params.devtype - 设备类型
    * @param {string} params.devid - 设备ID
    * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
    */
    static userResource_getBindDashbord({ tenantId, userId }: { tenantId: number, userId: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/users/${userId}/dashboards`,
            method: 'get',
        }, 'data-mgmt:user-dashboard:query')
    }
    /**
    * 更新指定用户绑定的大盘id列表
    * @param {*} params
    * @param {string} params.devtype - 设备类型
    * @param {string} params.devid - 设备ID
    * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
    */
    static userResource_updateBindDashbord({ tenantId, userId, data }: { tenantId: number, userId: number, data: any }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/users/${userId}/dashboards`,
            method: 'put',
            data: data
        }, 'data-mgmt:user-dashboard:update')
    }
    /************* dashboardController *****************************************************************************************/
    /**
     * 新增大盘
     * @param {*} params
     * @param {string} params.devtype - 设备类型
     * @param {string} params.devid - 设备ID
     * @param {string} [params.filter] - 查询条件，参考{@link https://docs.mongodb.com/manual/reference/operator/query-comparison/}
     * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
     */
    static dashboardController_addDataDashbord({ tenant_id, name, business_type = '' }: any) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/dashboards`,
            method: 'post',
            data: { name, business_type }
        }, "data-mgmt:dashboard:create")
    }

    /**
    * 编辑大盘
    * @param {*} params
    * @param {string} params.devtype - 设备类型
    * @param {string} params.devid - 设备ID
    * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
    */
    static dashboardController_updateDataDashbord({ tenant_id, id, name, business_type }: {
        tenant_id: number, id: number, name: string, business_type: string
    }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/dashboards/${id}`,
            method: 'put',
            data: { name, business_type }
        }, "data-mgmt:dashboard:update")
    }

    /**
     * 删除大盘
     */
    static dashboardController_deleteDataDashbord({ tenant_id, id }: { tenant_id: number, id: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/dashboards/${id}`,
            method: 'delete'
        }, 'data-mgmt:dashboard:delete')
    }

    /**
   * 获取大盘列表
   * @param {*} params
   * @param {string} params.devtype - 设备类型
   * @param {string} params.devid - 设备ID
   * @param {string} [params.filter] - 查询条件，参考{@link https://docs.mongodb.com/manual/reference/operator/query-comparison/}
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
   */
    static dashboardController_getBatchDashbords({ tenant_id, business_type }: any): Promise<GetDataDashbordPanelResp | any> {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/dashboards`,
            method: 'get',
            params: { business_type }
        }, 'data-mgmt:dashboard:query')
    }

    /**
     * 查询单个大盘
     * @param {*} params
     * @param {string} params.id - 大盘ID
     * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
     */
    static dashboardController_getDashboard({ id, tenant_id }: { id: number, tenant_id: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/dashboards/${id}`,
            method: 'get',
            params: {}
        })
    }


    /**
    * 添加看板
    * @param {*} params
    * @param {string} params.devtype - 设备类型
    * @param {string} params.devid - 设备ID
    * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
    */
    static dashboardController_addDataDashbordPanel({ tenant_id, chart_type, dashboard_id, name, business_type = "", description, panel_order, x_axis_type }: any) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/dashboard-panels`,
            method: 'post',
            data: { chart_type, dashboard_id, name, business_type, description, panel_order, x_axis_type }
        }, "data-mgmt:dashboard-panel:create")
    }
    /**
* 删除看板
* @param {*} params
* @param {string} params.devtype - 设备类型
* @param {string} params.devid - 设备ID
* @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
*/
    static dashboardController_deleteDataDashbordPanel({ tenant_id, panel_id }: { tenant_id: number, panel_id: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/dashboard-panels/${panel_id}`,
            method: 'delete',
        }, "data-mgmt:dashboard-panel:delete")
    }
    /**
* 编辑看板
* @param {*} params
* @param {string} params.devtype - 设备类型
* @param {string} params.devid - 设备ID
* @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
*/
    static dashboardController_updateDataDashbordPanel({ tenant_id, panel_id, chart_type, dashboard_id, name, business_type = "", description, panel_order, x_axis_type }: any) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/dashboard-panels/${panel_id}`,
            method: 'put',
            data: { panel_id, chart_type, dashboard_id, name, business_type, description, panel_order, x_axis_type }
        }, "data-mgmt:dashboard-panel:update")
    }
    /**
* 查询看板
* @param {*} params
* @param {string} params.devtype - 设备类型
* @param {string} params.devid - 设备ID
* @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
*/
    static dashboardController_getDataDashbordPanel({ tenant_id, dashboard_id, business_type, chart_type, prefix }: any) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/dashboard-panels`,
            method: 'get',
            params: { dashboard_id, chart_type, business_type, prefix }
        })
    }
    /**
* 删除面板中包含的指标信息
* @param {*} params
* @param {string} params.devtype - 设备类型
* @param {string} params.devid - 设备ID
* @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
*/
    static dashboardController_getDataDashbordPanelThingsMetrics({ tenant_id, panel_id }: { tenant_id: number, panel_id: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/panels/${panel_id}/panel-thingsmetrics`,
            method: 'get'
        })
    }
    /**
    * 新增面板中包含的指标信息
    * @param {*} params
    * @param {string} params.devtype - 设备类型
    * @param {string} params.devid - 设备ID
    * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
    */
    static dashboardController_addDataDashbordPanelThingsMetrics({ tenant_id, panel_id, dashboardPanelMetric }: any) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/panels/${panel_id}/panel-thingsmetrics`,
            method: 'post',
            data: dashboardPanelMetric
        })
    }
    /**
* 删除面板中包含的指标信息
* @param {*} params
* @param {string} params.devtype - 设备类型
* @param {string} params.devid - 设备ID
* @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: { removedCount: 1 } }
*/
    static dashboardController_deleteDataDashbordPanelThingsMetrics({ tenant_id, panel_id }: any) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/panels/${panel_id}/panel-thingsmetrics`,
            method: 'delete'
        })
    }
    /************* thingsDataController **************************************************************************************************/

    /**
     * 获取文件下载列表
     * @param param0 
     * @returns 
     */
    static thingsDataController_getDataDownloadList({ tenantId } : { tenantId: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/data/downloading/list`,
            method: 'get'
        })
    }

    /**
     * 修改文件下载状态
     * @param param0 
     * @returns 
     */
    static thingsDataController_updateDataDownloadStatus({ recordId, status, tenantId }: { recordId: number, status: number, tenantId: number }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenantId}/data/downloading/status`,
            method: 'put',
            params: { recordId, status }
        })
    }
  
    /**
  * 获取设备的数据聚合
  * @param {string}  - 
  * @param { number }  - 
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '' }
  * 
  */
     static thingsDataController_getThingsDataAggregation({
        tenant_id,
        info_model_id,
        things_id,
        category_id,
        begin_time,
        end_time,
        event_type,
        event_name,
        data_points,
        points_num,
        sort_property,
        sort_property_order,
        sort_property_type,
    }
        : {
            tenant_id: number,
            info_model_id: number,
            things_id: number,
            category_id: number,
            begin_time: number,
            end_time: number,
            event_type:string,
            event_name:string,
            data_points: Array<any>,
            points_num: number,
            sort_property: string,
            sort_property_order: string,
            sort_property_type: string,
        }
    ) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/info-model/${info_model_id}/things/${things_id}/history-aggregation`,
            method: 'post',
            data: { begin_time, end_time, category_id,
            data_points,event_type,event_name,
            points_num, sort_property, sort_property_order, sort_property_type }
        }, "data-mgmt:thing-data:history-aggregation")

    }

    /**
  * 获取设备的数据
  * @param {string} table - 对应table的类型
  * @param { number } sort - sort 1或者空格为倒叙，2为正序
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '' }
  * 
  */
    static thingsDataController_getThingsData({
        tenant_id,
        info_model_id,
        things_id,
        category_id,
        begin_time,
        end_time,
        event_name,
        op,
        page_size,
        property,
        sort,
        value,
        data_type,
        current_page,
        sort_property_type,
        sort_property,
        sort_property_order
    }
        : {
            tenant_id: number,
            info_model_id: number,
            things_id: number,
            category_id: number,
            begin_time: number,
            end_time: number,
            event_name?: string,
            op?: string,
            page_size?: number,
            property?: string,
            sort?: number,
            value?: string,
            data_type?: string,
            current_page?: number,
            sort_property_type?: string,
            sort_property?: string,
            sort_property_order?: string
        }
    ) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/info-model/${info_model_id}/things/${things_id}/data`,
            method: 'get',
            params: { begin_time, end_time, event_name, op, page_size, property, value, data_type, sort, category_id, current_page, sort_property_type, sort_property, sort_property_order }
        }, "data-mgmt:thing-data:query")

    }
    /**
      * 更新设备数据的处理状态
      * @param {string} table - 如果要批量修改event_name , begin_time , end_time
      * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '' }
    */ 
  static thingsDataController_updateDeviceDataStatus({
    begin_time,
    category_id,
    _id,
    deal_user,
    deal_way,
    timestamp,
    end_time,
    event_name,
    info_model_id,
    tenant_id,
    things_id,
    third_things_id,
    property,
    value,
    op,
    
    group_id,
    query_by_group,
    batch_es_requests
}: DeviceDataReq  
) {
    var url = `/data-mgmt/tenants/${tenant_id}/info-model/${info_model_id}/things/${things_id}/log-handle`
    if (event_name) {
        url = url + '?event_name =  '+ event_name
    }
    return this.fetch({
        url: url,
        method: 'post',
        data: { batch_es_requests, begin_time, category_id, _id, deal_user,
            deal_way, timestamp, end_time, info_model_id, event_name, tenant_id, things_id, third_things_id, property, value, op,
            group_id,
            query_by_group }
    },"data-mgmt:thing-data:log-handle")
}
    /**
      * 发起下载设备数据的请求
      * @param {string} table - 对应table的类型
      * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '' }
     */
    static thingsDataController_issueRequestDownloadDeviceData({
        begin_time,
        category_id,
        data_transform_id,
        download_template_id,
        end_time,
        event_name,
        info_model_id,
        group_id,
        query_by_group,
        tenant_id,
        things_id,
        user_id,
        property,
        value,
        op,
        sort_property,
        sort_property_order,
        sort_property_type
    }:
        {
            begin_time: number,
            category_id: number,
            data_transform_id: number,
            download_template_id: number,
            end_time: number,
            event_name: string,
            info_model_id: number,
            group_id?: number,
            query_by_group?: number,
            tenant_id: number,
            things_id: number,
            user_id: number,
            property?: string,
            value?: string,
            op?: string,
            sort_property?: string,
            sort_property_order?: string
            sort_property_type?: string
        }
    ) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/info-model/${info_model_id}/things/${things_id}/data/download`,
            method: 'post',
            data: { begin_time, category_id, end_time, data_transform_id, download_template_id, info_model_id, event_name, group_id,
                query_by_group, tenant_id, things_id, user_id, property, value, op, sort_property, sort_property_order, sort_property_type }
        })
    }

    /**
      * 下载设备数据
      * @param {string} table - 对应table的类型
      * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '' }
    */
    static thingsDataController_getQueryDownloadDeviceData({ tenant_id, info_model_id, dowaload_id }: any) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/info-model/${info_model_id}/data/downloading/${dowaload_id}`,
            method: 'get',
        })
    }

    /**
     * 获取设备数据的信号周期
     * @param {string}  - 
     * @param { number }  - 
     * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '' }
     * 
     */
        static thingsDataController_getSignalPeriod({
            tenant_id,
            info_model_id,
            things_id,
            category_id,
            begin_time,
            end_time,
            event_type,
            event_name,
            data_points,
            points_num
        }
            : {
                tenant_id: number,
                info_model_id: number,
                things_id: number,
                category_id: number,
                begin_time: number,
                end_time: number,
                event_type:string,
                event_name:string,
                data_points: Array<any>,
                points_num: number
            }
        ) {
            return this.fetch({
                url: `/data-mgmt/tenants/${tenant_id}/info-model/${info_model_id}/things/${things_id}/signal-period`,
                method: 'post',
                data: { begin_time, end_time, category_id,
                data_points,event_type,event_name,
                points_num }
            }, "data-mgmt:thing-data:query")

        }

    /**
  * 查询设备响应数据
  * @param {string} table - 对应table的类型
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '' }
*/
    static thingsDataController_getThingsMessageTraces({ tenant_id, info_model_id, things_id, begin_time, end_time, message_trace_id }: any) {
        return this.fetch({
            url: `/datamgmt/tenants/${tenant_id}/info-models/${info_model_id}/things/${things_id}/message-traces`,
            method: 'get',
            params: { begin_time, end_time, message_trace_id }
        })
    }
    /**
     * 查询时间范围内每小时的设备数据
     * @param {number} tenant_id - 租户id
     * @param {number} info_model_id - 设备类型
     * @param {number} things_id - 设备id
     * @param {number} begin_time - 开始时间
     * @param {number} category_id - 设备大类 id
     * @param {number} end_time - 结束时间
     * @param {string} data_type - 
     * @param {string} event_name - 
     * @returns {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '' }
     */
    static thingsDataController_getSingleThingsInfoByTimeRange({ tenant_id, info_model_id, things_id, begin_time, category_id, end_time, event_name, data_type, agg_type, agg_field }: { tenant_id: number, info_model_id: number, things_id: number, begin_time: number, category_id: number, end_time: number, event_name: string, data_type: string, agg_type: string, agg_field: string }) {
        return this.fetch({
            url: `/data-mgmt/tenants/${tenant_id}/info-model/${info_model_id}/things/${things_id}/_aggregations`,
            method: 'get',
            params: { begin_time, category_id, end_time, event_name, data_type, agg_type, agg_field }
        })
    }
    /************* OpLog **************************************************************************************************/
    /**
     * 查询操作日志
     * @param {number} params.tenantId  租户id
     * @param {number} params.start_date  查询起始时间
     * @param {number} params.end_date  查询结束时间
     * @param {number} params.current_page  
     * @param {number} params.page_size  
     */
    static opLog_getOpLog({ tenant_id, start_date, end_date, current_page, page_size, module_name, user_id, payload, opCodeCn }: { tenant_id: number, start_date: number, end_date: number, current_page: number, page_size: number, module_name: string, user_id: number, payload: string, opCodeCn: string }) {
        return this.fetch({
            url: `/data-mgmt/maintenance/op-log`,
            method: 'get',
            params: { tenant_id, start_date, end_date, current_page, page_size, module_name, user_id, payload, opCodeCn }
        })
    }


    /************* StatisticsMethods **************************************************************************************************/
    /**
     * 更新方法
     * @param {number} params.tenantId  租户id
     * @param {number} params.
     * @param {number} params.
     * @param {number} params.open_access  1 全部可用; 0 管理员可用
     * @param {number} params.type 1平台方法; 2自定义
     */
    static statisticsMethods_updateMethods({ tenant_id, description, id, name, open_access, param_mapping, result_mapping, script, script_type, type }: { tenant_id: number, description: number, id: number, name: number, open_access: number, param_mapping: any, result_mapping: any, script: number, script_type: string, type: string }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics-methods/${id}`,
            method: 'put',
            data: { description, id, name, open_access, param_mapping, result_mapping, script, script_type, tenant_id, type }
        },'data-mgmt:data-statistic-method:update')
    }

    /**
     * 创建方法 
     * @param {number} params.tenantId  租户id
     * @param {number} params. 
     * @param {number} params. 
     * @param {number} params.open_access  1 全部可用; 0 管理员可用
     * @param {number} params.type 1平台方法; 2自定义  
     */
     static statisticsMethods_creatMethods({ tenant_id, description, id, name, open_access, param_mapping, result_mapping, script, script_type, type }: { tenant_id: number, description: number, id: number, name: number, open_access: number, param_mapping: any, result_mapping: any, script: number, script_type: string, type: string }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics-methods`,
            method: 'post',
            data: { description, id, name, open_access, param_mapping, result_mapping, script, script_type, tenant_id, type }
        },'data-mgmt:data-statistic-method:create')
    }

    /**
     * 获取方法 
     * @param {number} params.tenantId  租户id
     * @param {number} params.start_date  查询起始时间
     * @param {number} params.end_date  查询结束时间
     * @param {number} params.current_page  
     * @param {number} params.page_size  
     */
     static statisticsMethods_getPageMethods({ tenant_id, id, end_date, current_page, page_size, module_name, user_id, payload, opCodeCn }: { tenant_id: number, id: number, end_date: number, current_page: number, page_size: number, module_name: string, user_id: number, payload: string, opCodeCn: string }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics-methods/page`,
            method: 'get',
            params: { tenant_id, id, end_date, current_page, page_size, module_name, user_id, payload, opCodeCn }
        },'data-mgmt:data-statistic-method:page')
    }

    /**
     * 获取方法 
     * @param {number} params.tenantId  租户id
     * @param {number} params.start_date  查询起始时间
     * @param {number} params.end_date  查询结束时间
     * @param {number} params.current_page  
     * @param {number} params.page_size  
     */
     static statisticsMethods_getSingleMethods({ tenant_id, id, end_date, current_page, page_size, module_name, user_id, payload, opCodeCn }: { tenant_id: number, id: number, end_date: number, current_page: number, page_size: number, module_name: string, user_id: number, payload: string, opCodeCn: string }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics-methods/${id}`,
            method: 'get',
            params: { tenant_id, id, end_date, current_page, page_size, module_name, user_id, payload, opCodeCn }
        },"data-mgmt:data-statistic-method:get")
    }


    /**
     * 删除方法 
     * @param {number} params.tenantId  租户id
     */
     static statisticsMethods_deleteSingleMethods({ tenant_id, id }: { tenant_id: number, id: number }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics-methods/${id}`,
            method: 'delete',
            params: { tenant_id, id }
        },'data-mgmt:data-statistic-method:delete')
    }


    
    /************* StatisticsTasks **************************************************************************************************/
    /**
     * 创建实例任务 
     * @param {number} params.tenantId  租户id
     * @param {number} params. 
     * @param {number} params. 
     * @param {number} params.open_access  1 全部可用; 0 管理员可用
     * @param {number} params.type 1平台方法; 2自定义  
     * @param {number} params.period_type 循环方式, 1日, 2周, 3月
     */
     static statisticsTask_creatTask({ tenant_id, end_time, method_id, name, start_time, task_type, param, period_type, cron_expression, data_start_time, data_end_time, data_time_type, time_interval}: { tenant_id: number, end_time: number, method_id: number, name: number, start_time: number, param: any, task_type: any, period_type?: any ,cron_expression: string, data_start_time: any,data_end_time: any,data_time_type: any,time_interval: any }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics/method/${method_id}/tasks`,
            method: 'post',
            data: { end_time, method_id, name, start_time, task_type, param, period_type, cron_expression, data_start_time, data_end_time, data_time_type,time_interval }
        },'data-mgmt:data-statistic-task:create')
    }
    static statisticsTask_updateTask({ is_simple_task, execute_interval, tenant_id, end_time, method_id, task_id, name, start_time, task_type, param, period_type, cron_expression, data_start_time, data_end_time, data_time_type, time_interval}: { is_simple_task?: boolean,  execute_interval: number, tenant_id: number, end_time: number, method_id: number, task_id: number, name: number, start_time: number, param: any, task_type: any, period_type?: any, cron_expression: string, data_start_time: any,data_end_time: any,data_time_type: any,time_interval: any }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics/method/${method_id}/tasks/${task_id}`,
            method: 'put',
            data: { is_simple_task, execute_interval, end_time, method_id, name, start_time, task_type, param, period_type, cron_expression, data_start_time, data_end_time, data_time_type,time_interval }
        },'data-mgmt:data-statistic-task:update')
    }
    /**
     * 获取实例任务 
     * @param {number} params.tenantId  租户id
     * @param {number} params.task_type  1 手动执行;2 循环执行
     * @param {number} params.end_date  查询结束时间
     * @param {number} params.current_page  
     * @param {number} params.page_size  
     */
     static statisticsTask_getPageTask({ tenant_id, method_id, task_type, current_page, page_size }: { tenant_id: number, method_id: number, task_type: number, current_page: number, page_size: number }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics/method/${method_id}/tasks/page`,
            method: 'get',
            params: { tenant_id, method_id, task_type, current_page, page_size }
        },'data-mgmt:data-statistic-task:page')
    }

    /**
     * 获取任务
     * @param {number} params.tenantId  租户id
     * @param {number} params.start_date  查询起始时间
     * @param {number} params.end_date  查询结束时间
     * @param {number} params.current_page  
     * @param {number} params.page_size  
     */
     static statisticsTask_getSingleTask({ tenant_id, id, end_date, current_page, page_size, module_name, user_id, payload, opCodeCn }: { tenant_id: number, id: number, end_date: number, current_page: number, page_size: number, module_name: string, user_id: number, payload: string, opCodeCn: string }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics-methods/${id}`,
            method: 'get',
            params: { tenant_id, id, end_date, current_page, page_size, module_name, user_id, payload, opCodeCn }
        },'data-mgmt:data-statistic-task:get')
    }


    /**
     * 删除任务
     * @param {number} params.tenantId  租户id
     */
     static statisticsTask_deleteSingleTask({ tenant_id, method_id, task_id}: { tenant_id: number, method_id: number, task_id: number }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics/method/${method_id}/tasks/${task_id}`,
            method: 'delete',
            params: {}
        },'data-mgmt:data-statistic-task:delete')
    }

 

    /**
     * 执行任务 
     * @param {number} params.tenantId  租户id
     */
     static statisticsTask_executeSingleTask({ tenant_id, method_id, task_id}: { tenant_id: number, method_id: number, task_id: number }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics/method/${method_id}/tasks/${task_id}/_execute`,
            method: 'post',
            params: {}
        },"data-mgmt:data-statistic-task:execute")
    }

    

    /**
     * 更新任务 
     * @param {number} params.tenantId  租户id
     * @param {number} params.status  状态,1,开启执行,2停止执行
     */
     static statisticsTask_updateSingleTask({ tenant_id, method_id, task_id, status}: { tenant_id: number, method_id: number, task_id: number, status: number }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics/method/${method_id}/tasks/${task_id}/status/${status}`,
            method: 'put',
            params: {}
        },"data-mgmt:data-statistic-task:updateStatus")
    }

    
    
    /**
     * 新版周期任务 
     * @param {number} params.tenantId  租户id
     *  "execute_interval": 1-6范围,
        "name": "string",
        "param": {},
        "time_interval": 1-6范围
    */
     static statisticsTask_createSingleTimerTask({ tenant_id, method_id, execute_interval, param, name, time_interval}: { tenant_id: number, method_id: number, execute_interval: number, param: number, name: number, time_interval: number }) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics/method/${method_id}/timer-task`,
            method: 'post',
            
            data: { execute_interval, param, name, time_interval },
        },"data-mgmt:data-statistic-task:create")
    }

    /**
     * 获取结果 /v1/data-mgmt/tenant/{tenantId}/statistics-methods
     * @param {number} params.tenantId  租户id
     * @param {number} params.task_type  1 手动执行;2 循环执行
     * @param {number} params.end_date  查询结束时间
     * @param {number} params.current_page  
     * @param {number} params.page_size  
     */
     static statisticsTask_getPageTaskResult({ tenant_id, methodId, taskId, current_page, page_size, start_date, end_date }: { tenant_id: number, methodId: number, taskId: number, current_page: number, page_size: number, start_date: number, end_date: number}) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics/tasks_jrnl/page`,
            method: 'get',
            params: { tenant_id, methodId, taskId, current_page, page_size, start_date, end_date }
        },"data-mgmt:data-statistic-jrnl:page")
    }


    
    static statisticsTask_getPageTaskResultWithDimention({ tenant_id, methodId, taskId, statistical_time_type, current_page, page_size, start_date, end_date, statisticalDimension, dimensionIds}: { tenant_id: number, methodId: number, taskId: number, statistical_time_type: number, current_page: number, page_size: number, start_date: number, end_date: number, statisticalDimension:any, dimensionIds:any}) {
        return this.fetch({
            url: `/data-mgmt/tenant/${tenant_id}/statistics/tasks_jrnl/page-with-dimension`,
            method: 'get',
            params: { tenant_id, method_id: methodId, statistical_time_type, task_id: taskId, current_page, page_size, start_date, end_date, statistical_dimension: statisticalDimension, dimension_ids: dimensionIds }
        },"data-mgmt:data-statistic-jrnl:page")
    }

}
export default ZWSDataMgmt
