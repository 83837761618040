/**
 * ECU升级
 */
export default {
  obtainECUListFailed: '获取ECU列表失败',
  obtainDeviceStautsFailed: '获取设备状态失败，{error}',
  tryAgain: 'ECU固件升级失败，请重新尝试',
  firmwareUpgradeSuccess: 'ECU固件升级成功',
  sendUpgradeCommandFailed: '发送升级指令失败',
  generateCommandFailed: '生成ECU固件升级指令失败',
  obtainURLFailed: '获取ECU固件URL失败',
  bindChannelFailed: '绑定升级通道失败，{error}',
  uploadFirmwareFailed: '上传固件文件失败',
  responseAddrOutOfRange2: '响应地址超出7FF的标准帧地址范围',
  physicalAddrOutOfRange2: '物理地址超出7FF的标准帧地址范围',
  functionAddrOutOfRange2: '功能地址超出7FF的标准帧地址范围',
  responseAddressOutOfRange: '响应地址超出1FFFFFFF的扩展帧地址范围',
  physicalAddrOutOfRange: '物理地址超出1FFFFFFF的扩展帧地址范围',
  functionAddrOutOfRange: '功能地址超出1FFFFFFF的扩展帧地址范围',
  pleaseSelectChannel: '请选择通道',
  repeatOperation: '请等待升级完成，且勿重复操作',
  deviceOffline: '设备不在线，暂无法进行ECU升级',
  obtainDevLogFailed: '获取设备日志出错',
  obtainDevStatusFailed: '获取设备状态失败',
  sendResetStatusCommandFailed: '发送复位设备状态指令失败',
  noPermissionToResetStatus: '该模块用户没有权限，获取复位设备状态失败',
  firmwareUpgrading: '固件升级中',
  ecuFirmwareUpgrade: '升级ECU固件',
  outOfExtendedFrame: '超出扩展帧的地址范围',
  outOfStandardFrame: '超出标准帧的地址范围',
  extendedFrame: '扩展帧',
  functionAddress: '功能地址',
  responseAddress: '响应地址',
  physicalAddress: '物理地址',
  address0x: '地址(0x)',
  pleaseSelectECU: '请选择ECU',
  canChannel: 'CAN通道',
  ecuUpgrade: 'ECU升级',
  debugInfo: '调试信息',
  unlockFile: '解锁文件',
  paramConfig: '参数配置',
  firmwareFile: '固件文件',
  proversion: '协议版本',
  fd: 'CANFD加速'
}
