/**
 *  
 * */
import '../css/clickWaterAnimation.css';
export const debounce = {
    mounted(el: HTMLElement, binding: any) {
        el.addEventListener('click', (e: any) => { 
            if (!(el as any).disabled) {
                (el as any).disabled = true;  
                el.classList.add('is-disabled')
                setTimeout(() => {
                    (el as any).disabled = false;
                    el.classList.remove('is-disabled')
                }, binding.value || 500)
              }

        }, false);
    }
};