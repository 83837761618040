/**
 * 工单 workOrder
 */
export default {
  addWorkOrder: '添加工单',
  deleteOrder: '确定要删除工单{name}吗？',
  orderManage: '工单管理',
  workName: '工单名称',
  feedbackType: '反馈类型',
  createTime: '创建时间',
  workStatus: '工单状态',
  deleteOrderSuccess: '删除工单成功',
  deleteOrderFail: '删除工单失败',
  consult: '咨询',
  problem: '问题',
  suggest: '建议',
  other: '其他',
  pending: '待处理',
  process: '处理中',
  wait: '待验证',
  done: '已完成',
  abandon: '未通过',
  refuse: '拒单',
  orderDetail: '工单详情',
  image: '图片信息',
  initiator: '发起人：',
  handlingInformation: '处理情况',
  remark: '备注信息',
  handler: '处理人：',
  acceptance: '验收情况',
  finish: '完成情况',
  inputRemark: '请输入备注信息',
  identifier: '验证人：',
  belongFunction: '归属功能',
  phone: '联系电话',
  updateStatusSuccessfully: '更新状态成功',
  updateStatusFail: '更新状态失败',
  getOrderDetailFail: '获取工单详情错误',
  getInitiatorFail: '获取发起人失败',
  inputOrderName: '请输入工单名称',
  inputFeedback: '请输入反馈类型',
  inputBelongFunction: '请输入归属功能',
  inputDesc: '请输入描述',
  uploadImgage: '上传图片',
  phoneTip: '请输入正确位数的手机号码，只能是数字',
  selectFeedback: '请选择反馈类型',
  phoneNotNull: '联系电话不能为空',
  addOrderSuccess: '添加工单成功',
  addOrderFail: '添加工单失败',
  uploadImageFail: '上传图片失败'
}
