<template>
  <el-popover v-model:visible="userMessageShow" :width="400" placement="bottom" trigger="click" @show="handleShow">
    <template #reference>
      <el-badge class="user-message-badge" type="danger" :hidden="!unRead" :value="unRead" :max="99">
        <el-icon :size="20" color="#fff"><BellFilled /></el-icon>
      </el-badge>
    </template>
    <div class="message-list-container">
      <el-tabs v-model="activeIndex" @tab-click="handleSelect">
        <el-tab-pane :label="t('zws.ZUserMessageIconArea.unread')" :name="ReadStatus.UnRead"></el-tab-pane>
        <el-tab-pane :label="t('zws.ZCommon.all')" :name="ReadStatus.All"></el-tab-pane>
      </el-tabs>
      <el-dropdown ref="elDropdownRef" trigger="click" @command="handleCommand">
        <el-icon :size="20" color="#80b33f"><Setting /></el-icon>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :command="ReadStatus.Read">{{ t('zws.ZUserMessageIconArea.allRead') }}</el-dropdown-item>
            <el-dropdown-item :command="ReadStatus.Delete">{{ t('zws.ZUserMessageIconArea.allDelete') }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div v-loading="loading">
        <div class="message-scroll" v-if="messageList.length" v-infinite-scroll="load" :infinite-scroll-immediate="false">
          <div
            class="message-item-container"
            :class="{ read: item.read_status === ReadStatus.Read }"
            v-for="(item, index) in messageList"
            @click="handleMessageDetailClicked(item, index)"
          >
            <div class="message-title">{{ item.messageTitle }}</div>
            <div class="message-content">{{ item.messageContent }}</div>
            <div class="message-time">{{ item.time }}</div>
          </div>
        </div>
        <div v-else class="no-data-container">{{ t('zws.ZUserMessageIconArea.noData') }}</div>
      </div>
    </div>
  </el-popover>
  <ZDialogCtrl ref="detailDialogRef" v-model:show="detailDialogShow" :title="t('zws.ZUserMessageIconArea.message')" width="600px" :buttonList="tableButtons">
    <div class="detail-container">
      <div class="detail-header">
        {{ handleMessage?.messageTitle }}
      </div>
      <div class="detail-type">
        <span>{{ handleMessage?.messageType }}</span>
        <span class="date">{{ handleMessage?.time }}</span>
      </div>
      <div class="content">
        {{ handleMessage?.messageContent }}
      </div>
    </div>
  </ZDialogCtrl>
  <ZDialogCtrl :title="t('zws.ZCommon.tips.tip')" v-model:show="updateAllTipsDialog" @buttonClicked="handleUpdateAllTipsButtonClicked">
    <div>{{ updateAllTips }}</div>
  </ZDialogCtrl>
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useLocale } from '../../hooks/useLocale'
import { BellFilled, Setting } from '@element-plus/icons-vue'
import { getUserMessageByUser, updateUserMessageByUser, UserMessageByUser } from '@zws/axios-fetch'
import { ReadStatus, ReminderMode } from './enums'
import { ZDateTool } from '@zws/tools'
import ZMessage from '../../ctrl/ZMessage'
import ZDialogCtrl from '../../ctrl/ZDialogCtrl.vue'

interface UserMessage extends UserMessageByUser {
  time: string
}

const props = defineProps({
  // 平台
  platform: {
    type: String,
    default: 'CAN'
  },
  // 实时轮询间隔
  interval: {
    type: Number,
    default: 60000
  }
})

const { t } = useLocale()

const store = useStore()
const userInfo = computed(() => store.getters.userInfo)
// 单次加载条数
const LoadPageSize: number = 10

const unRead = ref<number>(0)
const activeIndex = ref<number>(ReadStatus.UnRead)
const handleSelect = async (val: number) => {
  loading.value = true
  messageList.value = []
  currentPage = 1
  setTimeout(async () => {
    messageList.value = await getUserMessage(currentPage)
  }, 500)
}

const loading = ref<boolean>(false)
const messageList = ref<UserMessage[]>([])
let totalSize: number = 0
let currentPage: number = 1
const getUserMessage = (current_page: number) => {
  return new Promise<UserMessage[]>((resolve, rejected) => {
    getUserMessageByUser({
      tenant_id: userInfo.value.tenant_id,
      user_id: userInfo.value.id,
      platform: props.platform,
      read_status: activeIndex.value,
      current_page,
      page_size: LoadPageSize
    })
      .then((result: any) => {
        totalSize = result.pagination.total_size
        const _messageList = result.list.map((item: any) => {
          const _message = item
          _message.time = item.send_time ? ZDateTool.dateFormat(item.send_time, 'yyyy-MM-dd hh:mm:ss') : '--'
          return _message
        })
        resolve(_messageList)
      })
      .catch((err: any) => {
        console.log(err, 'err')
        ZMessage.message.error(t('zws.ZUserMessageIconArea.getMessageFail'))
        rejected(err)
      })
      .finally(() => (loading.value = false))
  })
}
const load = async () => {
  if (Math.floor(totalSize / LoadPageSize) > currentPage - 1) {
    loading.value = true
    const _newUserMessage = await getUserMessage(++currentPage)
    messageList.value.push(..._newUserMessage)
  }
}
const handleShow = async (val: boolean) => {
  loading.value = true
  messageList.value = []
  currentPage = 1
  setTimeout(async () => {
    messageList.value = await getUserMessage(currentPage)
  }, 500)
}

const updateAllTipsDialog = ref<boolean>(false)
const updateAllStatus = ref<number>(ReadStatus.Read)
const handleCommand = (val: number) => {
  updateAllStatus.value = val
  updateAllTipsDialog.value = true
}
const updateAllTips = computed(() => {
  return updateAllStatus.value === ReadStatus.Delete ? t('zws.ZUserMessageIconArea.sureAllDelete') : t('zws.ZUserMessageIconArea.sureAllRead')
})
const handleUpdateAllTipsButtonClicked = (val: any) => {
  if (val.index === 1) {
    updateUserMessageByUser({
      tenant_id: userInfo.value.tenant_id,
      user_id: userInfo.value.id,
      update_all: true,
      read_status: updateAllStatus.value
    })
      .then(result => {
        getUnreadNumber()
      })
      .catch(err => {})
  }
}

// 查看详情
const tableButtons = computed(() => [
  {
    text: t('zws.ZUserMessageIconArea.close'),
    type: 'info',
    plain: true
  }
])
const handleMessage = ref<UserMessage>()
const detailDialogShow = ref<boolean>(false)
const userMessageShow = ref<boolean>(false)
const handleMessageDetailClicked = (val: UserMessage, index: number) => {
  handleMessage.value = val
  detailDialogShow.value = true
  userMessageShow.value = false
  if (val.read_status === ReadStatus.UnRead) {
    updateUserMessageByUser({
      tenant_id: userInfo.value.tenant_id,
      user_id: userInfo.value.id,
      user_message_id: val.id,
      read_status: ReadStatus.Read
    })
      .then(result => {
        unRead.value--
      })
      .catch(err => {})
  }
}

// 获取未读
const getUnreadNumber = () => {
  getUserMessageByUser({
    tenant_id: userInfo.value.tenant_id,
    user_id: userInfo.value.id,
    platform: props.platform,
    read_status: ReadStatus.UnRead,
    current_page: 1,
    page_size: 2
  })
    .then((result: any) => {
      unRead.value = result?.pagination?.total_size
      if (result.list && result.list.length) {
        const _message = result.list[0]
        if (_message?.reminderMode === ReminderMode.Dialog) {
          // 弹出对话框
          handleMessage.value = result.list[0]
          handleMessage.value!.time = handleMessage.value?.send_time
            ? ZDateTool.dateFormat(handleMessage.value.send_time, 'yyyy-MM-dd hh:mm:ss')
            : '--'
          detailDialogShow.value = true
          updateUserMessageByUser({
            tenant_id: userInfo.value.tenant_id,
            user_id: userInfo.value.id,
            user_message_id: handleMessage.value!.id,
            read_status: ReadStatus.Read
          })
            .then(result => {
              unRead.value--
            })
            .catch(err => {})
        }
      }
    })
    .catch()
}

// 定时请求
getUnreadNumber()
let timer = setInterval(() => {
  getUnreadNumber()
}, props.interval)

onUnmounted(() => {
  clearInterval(timer)
})
</script>

<style lang="less" scoped>
.user-message-badge {
  .el-icon {
    cursor: pointer;
  }
}
.message-list-container {
  width: 400px;
  position: relative;
  :deep(.el-dropdown) {
    position: absolute;
    right: 6px;
    top: 6px;
  }
  .el-icon {
    cursor: pointer;
  }
  .no-data-container {
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
}
.detail-container {
  font-size: 14px;
  text-align: left;
  .detail-header {
    font-weight: bold;
  }
  .detail-type {
    margin: 10px 0;
    .date {
      margin-left: 20px;
    }
  }
  .content {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
.message-scroll {
  max-height: 400px;
  overflow: auto;
  padding-right: 10px;
}
.message-item-container {
  text-align: left;
  font-size: 14px;
  padding: 10px 0;
  border-bottom: 1px solid #d9dce3;
  cursor: pointer;
  &:hover {
    color: #80b33f;
  }
  .message-title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .message-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-break: break-all;
    // 溢出用省略号显示
    text-overflow: ellipsis;
  }
  .message-time {
    color: #a8abb2;
  }
}
.read {
  color: #a8abb2;
}
</style>
