<template>
    <div class="six-digit-wrapper">
        <!-- oninput="value=value.replace(/[^\d]/g,'')" -->
        <input class="input" :style="inputStyle" v-for="(item, index) in digits" :ref="setItemRef" :key="index"
            v-model="item.value" type="text" @input="onInput(index, item.value)" @blur="onBlur"
            @keyup.delete="onDelete(index)" autocomplete="off" />
        <span class="error-tips">{{ errorTips }}</span>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, reactive, watch, ref } from 'vue'
import { useLocale } from '../hooks/useLocale'
export default defineComponent({
    props: {
        modelValue: {},
        backgroundColor: {
            type: String,
            default: '#ffffff',
        },
        errorText: {
            type: String,
        },
        lang: {}
    },
    setup(props, context) {
        let { t } :any = useLocale()
        const digits = reactive([
            {
                value: '',
            },
            {
                value: '',
            },
            {
                value: '',
            },
            {
                value: '',
            },
            {
                value: '',
            },
            {
                value: '',
            },
        ])
        let errorTips: any = ref('')
        const itemRefs: any = []
        const isKeyupDel = ref(false)
        watch([() => props.modelValue, () => props.errorText], (newValue, oldValue) => {
            if (!isKeyupDel.value) {
                // 如果不做新旧对比，props.errorText在改变时会将digits的值置空
                if (newValue[0] === '' && newValue[0] !== oldValue[0]) {
                    digits.forEach((item: any) => {
                        item.value = ''
                    })
                }
                errorTips.value = newValue[1]
            }
            isKeyupDel.value = false
        })
        const setItemRef = (el: any) => {
            itemRefs.push(el)
        }
        const inputStyle = computed(() => {
            return {
                backgroundColor: props.backgroundColor,
            }
        })
        let inputValue = ''
        let keydownDelTimer: NodeJS.Timer | null = null
        function onInput(index: number, value: string | number) {
            if (value.toString().length > 1) {
                const valueArr = value.toString().split('')
                digits[index].value = value = valueArr[0]
                if (index < 5) {
                    digits[index + 1].value = valueArr[1]
                }
            }
            const reg = /[^\d]/g
            if (reg.test(value.toString())) {
                digits[index].value = ''
                return
            }
            // index < 5 ，如果是第6格，不触发光标移动至下一个输入框。
            if (digits[index].value && index < 5) {
                itemRefs[index + 1].focus()
            }
            inputValue = ''
            // 如果输入完成，则触发传值
            digits.forEach((item) => {
                inputValue = inputValue + item.value
            })
            errorTips.value = ''
            context.emit('update:errorText', '')
            if (inputValue === '' || inputValue.length < 6) {
                errorTips.value = t('zws.CommonValidTipOrText.sixNumValidTip')
                context.emit('update:errorText', t('zws.CommonValidTipOrText.sixNumValidTip'))
                // 因为事件触发顺序为 onInput =》 onDelete，所以无法在 onDelete事件中进行赋值，只能将不足6位时都假设进行了删除操作
                // 用来处理del时，因为watch的原因将input框全部置空的情况
                isKeyupDel.value = true
                // 如果500毫秒内没有触发watch，则修改状态，以免影响其他情况
                clearTimeout(Number(keydownDelTimer))
                keydownDelTimer = setTimeout(() => {
                    isKeyupDel.value = false
                }, 500)
                // 如果是删除，因为小于6位，默认v-model的值为空，这样外部调用时，只需判断不为空就发请求即可。
                context.emit('update:modelValue', '')
                return
            }
            context.emit('update:modelValue', inputValue)
        }
        function onDelete(index: number) {
            // 如果是第1格，不触发光标移动至上一个输入框
            if (index > 0) {
                itemRefs[index - 1].focus()
            }
        }
        function onBlur() {
            if (inputValue === '') {
                errorTips.value = t('zws.CommonValidTipOrText.sixNumValidTip')
                context.emit('update:errorText', t('zws.CommonValidTipOrText.sixNumValidTip'))
                return
            }
        }
        function clear() {
            errorTips.value = ''
            context.emit('update:errorText', '')
            digits.forEach((item) => {
                item.value = ''
            })
        }
        function changeWord() {
            if (errorTips.value) {
                errorTips.value = t('zws.CommonValidTipOrText.sixNumValidTip')
               
                return
            }
        }
         watch(
            () => props.lang,
            () => {
                changeWord()
               
            },
            {
                immediate: true
            }
        )
        return {
            digits,
            errorTips,
            inputStyle,
            onBlur,
            setItemRef,
            onInput,
            onDelete,
            clear,
        }
    },
})
</script>

<style lang="less" scoped>
.six-digit-wrapper {
    display: flex;
    flex-direction: row;
}

.input {
    display: flex;
    width: 25px;
    margin-right: 10px;
    height: 39px;
    font-size: 18px;
    color: #333333;
    text-align: center;
    outline: none; // 去除选中状态边框
    border: solid 1px #d2d2d2;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    padding: 0;
}

.error-tips {
    color: var(--el-color-danger);
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
}
</style>
