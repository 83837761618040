<template>
<div class="zws-header">
    <span v-if="systemType === 'billing'" class="zws-header-left" :class="{ 'can-click': homePath }" @click="toHome">
        <el-image class="zws-billing-header-left-logo" :src="activePlatformInfo.homepageLogo"
                  fit="scale-down"></el-image>
        <span class="zws-header-text">{{ t('zws.ZHeaderArea.userCenter') }}</span>
    </span>
    <span v-else class="zws-header-left" :class="{ 'can-click': homePath }" @click="toHome">
        <el-image class="zws-header-left-logo" :src="activePlatformInfo.homepageLogo" fit="scale-down"></el-image>
        <span class="zws-header-text">{{ activePlatformInfo.projectName }}</span>
    </span>
    <div class="zws-header-right">
        <a v-if="isStudioShow" class="monitor" @click="toZwstudio">{{ topTagName.lowCode }}</a>
        <a v-if="isMonitorShow" class="monitor" @click="toScreenMonitor">{{ topTagName.monitor }}</a>
        <a class="justText" @click="toHelp">{{ topTagName.help }}</a>
        <span v-if="isMessageShow" class="msgIconArea">
        <UserMessageIconArea :platform="'IOT'"></UserMessageIconArea></span>
        <!-- <a v-if="isStudioShow" class="monitor" @click="toZwstudio">{{ t('zws.ZHeaderArea.lowCode' || 'IoT低代码开发平台')
            }}</a>
            <a v-if="isMonitorShow" class="monitor" @click="toScreenMonitor">{{ t('zws.ZHeaderArea.monitor' || '监控大屏')
            }}</a>
            <a class="justText" @click="toHelp">{{ t('zws.ZHeaderArea.help' || '帮助') }}</a> -->
        <el-dropdown v-if="userDropdown" @command="handleCommand">
            <span class="zws-header-right-user">
                <el-image class="zws-header-right-avatar" :src="userInfo.avatar ? userInfo.avatar : avatarImgUrl">
                </el-image>
                <span class="zws-header-right-username">{{ userInfo.name }}</span>
                <i class="el-icon-arrow-down zws-header-right-icon-setting"></i>
            </span>
            <template #dropdown>
                <el-dropdown-menu>
                    <!-- <el-dropdown-item command="userManager">个人中心</el-dropdown-item> -->
                    <el-dropdown-item command="billing">{{ t('zws.ZHeaderArea.userCenter' || '用户中心')
                    }}</el-dropdown-item>
                    <el-dropdown-item v-if="!isSubUser" command="systemSetting">{{ t('zws.ZHeaderArea.sysSetting' ||
                        '系统设置') }}</el-dropdown-item>
                    <el-dropdown-item command="logout">{{ t('zws.ZHeaderArea.logout' || '退出登录') }}</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
        <span v-else class="zws-header-right-user">
            <el-image class="zws-header-right-avatar" :src="userInfo.avatar ? userInfo.avatar : avatarImgUrl">
            </el-image>
            <span class="zws-header-right-username">{{ userInfo.name }}</span>
            <i class="zws-header-right-icon-setting"></i>
        </span>
    </div>
</div>
</template>
<script lang="ts">
import logoHeadImgUrl from '../ZImageArea/home/logo_head.png'
import avatarImgUrl from '../ZImageArea/user/ic_avatar.png'
import ZAppUrlTool from '../../../../sdk/ts/tool/ZAppUrlTool'
import { projectConfig } from '@/assets/ts/project_config'
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { remarkOperationMonitoring } from 'tool/ZDirectives/directives/monitoring'
import { useLocale, setLanguage } from '../../hooks/useLocale'
import UserMessageIconArea from '../ZUserMessageIconArea/ZUserMessageIconArea.vue'

export default defineComponent({
    components: {
        UserMessageIconArea
    },
    props: {
        homePath: {
            type: String,
            default: ''
        },
        userDropdown: {
            type: Boolean,
            default: true
        },
        helpPath: {
            type: String,
            default: 'https://www.zlgcloud.com:20000/web/#/19/185'
        },
        isStudioShow: {
            type: Boolean,
            default: true
        },
        isMonitorShow: {
            type: Boolean,
            default: true
        },
        isMessageShow: {
            type: Boolean,
            default: true
        },
        systemType: {
            type: String
        }
    },
    setup(props) {
        let { t } = useLocale()
        const store = useStore()
        const router = useRouter()
        let defaultSystemInfo: any = reactive({
            systemName: 'Iot-Web-Cloud',
            systemTheme: 'light',
            systemLogo: '',
        })
        defaultSystemInfo.systemName = projectConfig.projectName
        let systemInfo = computed(() => {
            return store.getters.tenantCustomizationInfo
        })
        let userInfo = computed(() => {
            return store.getters.userInfo
        })
        // 子用户隐藏 系统设置
        const userPromissionInfo = store.getters.tenantPermissionInfo
        const isSubUser = ref(false)
        if (userPromissionInfo && !userPromissionInfo.tenant_manager) {
            isSubUser.value = true
        }

        let activePlatformInfo: any = reactive({
            homepageLogo: null,
            projectName: '',
        })
        let topTagName = reactive({
            lowCode: t('zws.ZHeaderArea.lowCode' || 'IoT低代码开发平台'),
            monitor: t('zws.ZHeaderArea.monitor' || '监控大屏'),
            help: t('zws.ZHeaderArea.help' || '帮助'),
        })
        watch(() => systemInfo, () => {
            setLanguage(systemInfo.value.systemLanguage)
            topTagName.lowCode = t('zws.ZHeaderArea.lowCode' || 'IoT低代码开发平台')
            topTagName.monitor = t('zws.ZHeaderArea.monitor' || '监控大屏')
            topTagName.help = t('zws.ZHeaderArea.help' || '帮助')
        }, {
            immediate: true,
            deep: true
        })

        initPlatformInfo()

        // 根据目前定死的规则来组装跳转路径
        const getUrl = (curDevPath: string, outWebPath: string, onlinePath: string) => {
            const pathname = window.location.pathname
            let realPathname = ''
            // out-web debug路径
            if (pathname.indexOf('v1/out-web/debug') !== -1) {
                realPathname = `/v1/out-web/debug/${outWebPath}/`
            } else if (pathname.indexOf('v1/out-web') !== -1) { // out-web 非debug路径
                realPathname = `/v1/out-web/${outWebPath}/`
            } else { // 当前开发路径
                realPathname = `/v1/${curDevPath}/`
            }

            // 通过判断源来判断当前的环境
            const origin = window.location.origin
            let realOrigin = origin
            if (origin.indexOf('localhost') !== -1) {
                realOrigin = 'http://192.168.24.11:30880'
                realPathname = `/v1/${curDevPath}/`
            } else if (origin.indexOf('192.168') !== -1) {

            } else {
                // 正式环境
                realOrigin = 'https://zws.zlgcloud.com'
                realPathname = `/${onlinePath}/`
            }

            return realOrigin + realPathname
        }

        const handleCommand = (command: string) => {
            switch (command) {
                case 'userManager':
                    router.push('/Container/UserManager/UserCenter')
                    break
                case 'billing':
                    // http://192.168.24.11:30880/v1/web-billing/
                    // window.open(
                    //     `${getUrl('web-billing', 'billing', 'billing')}index.html#/?ujwt=${localStorage.getItem('ujwt')}&token=${localStorage.getItem(
                    //         'token'
                    //     )}&loginUrl=${encodeURIComponent(window.location.origin + window.location.pathname)}#/Login`,
                    //     '_blank'
                    // )
                    ZAppUrlTool.getUrlByKey('billing').then((res: string) => {
                        window.open(
                            `${res}#/?ujwt=${localStorage.getItem('ujwt')}&token=${localStorage.getItem(
                                'token'
                            )}&loginUrl=${encodeURIComponent(window.location.origin + window.location.pathname)}#/Login`,
                            '_blank'
                        )
                    })
                    monitor.action = '查看用户中心'
                    remarkOperationMonitoring(monitor)
                    break
                case 'systemSetting':
                    router.push('/Container/SystemManager/SystemSetting')
                    break
                case 'logout':
                    window.ZWSApi.ZWSControl.session_deleteSessions({
                        sessionId: localStorage.getItem('token'),
                        ujwt: localStorage.getItem('ujwt'),
                    }).finally(() => {
                        /* 暂不使用，考虑语言设置 localStorage.clear() */
                        router.push('/Login')
                    })
                    break
                default:
                    break
            }
        }
        const toScreenMonitor = () => {
            const routeUrl = router.resolve({
                path: '/Watch',
            })
            window.open(routeUrl.href, '_blank')
        }
        let monitor = {
            platform: 'ZWS-Cloud',
            module: '首页',
            action: '查看IoT低代码开发平台',
        }
        const toHelp = () => {
            // window.open(`https://www.zlgcloud.com:20000/web/#/19/185`)
            window.open(props.helpPath)
            monitor.action = '查看帮助'
            remarkOperationMonitoring(monitor)
        }
        const toZwstudio = () => {
            // http://192.168.24.11:30880/v1/studio-web/manage/
            // window.open(`${getUrl('out-web/debug/zwstudio/manage', 'manage', 'zwstudio/manager')}index.html#/?token=${localStorage.getItem('token')}`, '_blank')
            ZAppUrlTool.getUrlByKey('manage').then((res: string) => {
                window.open(`${res}#/?token=${localStorage.getItem('token')}`, '_blank')
            })
            monitor.action = '查看IoT低代码开发平台'
            remarkOperationMonitoring(monitor)
        }

        function initPlatformInfo() {
            setPlatformInfo()
        }
        function setPlatformInfo() {
            let globalPermissionInfo = store.getters.globalPermissionInfo
            if (!globalPermissionInfo && !systemInfo.value) return
            activePlatformInfo.homepageLogo = systemInfo.value.systemLogo
                ? systemInfo.value.systemLogo
                : globalPermissionInfo?.homepageLogo
                    ? globalPermissionInfo.homepageLogo
                    : logoHeadImgUrl
            activePlatformInfo.projectName = systemInfo.value.systemName
                ? systemInfo.value.systemName
                : globalPermissionInfo?.cloudname
                    ? globalPermissionInfo.cloudname
                    : defaultSystemInfo.systemName
        }

        watch(
            () => store.getters.globalPermissionInfo,
            () => {
                setPlatformInfo()
            }
        )

        watch(
            () => store.getters.tenantCustomizationInfo,
            () => {
                setPlatformInfo()
            }
        )

        const homePath = props.homePath
        const toHome = () => {
            if (homePath) {
                router.push({
                    // path: '/Container/Home/HomePage'
                    path: homePath
                })
            }

        }

        return {
            homePath,
            defaultSystemInfo,
            logoHeadImgUrl,
            avatarImgUrl,
            systemInfo,
            userInfo,
            activePlatformInfo,
            isSubUser,
            handleCommand,
            toScreenMonitor,
            toZwstudio,
            toHelp,
            toHome,
            t,
            topTagName,
        }
    },
})
</script>
<style lang="less" scoped>
.zws-header {
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    // background-color: #80b33f;
    background-color: #79a61a;
}

.zws-header-text {
    font-size: 18px;
    margin-left: 10px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}

.zws-header-left {
    height: 50px;
    display: flex;
    text-align: left;
    align-items: center;

    &.can-click {
        cursor: pointer;
    }
}

.zws-header-left-logo {
    width: 42px;
    height: 42px;
}

.zws-billing-header-left-logo {
    height: 42px;
}

.zws-header-right {
    height: 50px;
    display: flex;
    align-items: center;
}

.zws-header-right-user {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.zws-header-right-avatar {
    width: 30px;
    height: 30px;
    border-radius: 30px;
}

.zws-header-right-username {
    font-size: 14px;
    margin-left: 10px;
    font-weight: bold;
    color: #ffffff;
}

.zws-header-right-icon-setting {
    margin-left: 5px;
    color: #ffffff;
}

.monitor {
    margin-top: 2px;
    margin-right: 8px;
    font-size: 14px;
    color: #ffffff;
    border: 1px solid #ffffff;
    padding: 4px 10px;
    border-radius: 4px;
    cursor: pointer;

    &:link {
        color: white;
    }

    &:hover {
        // color: #dcdcdc !important;
        // border: 1px solid #dcdcdc;
    }

    &:visited {
        color: white;
    }
}

.justText {
    margin-top: 2px;
    margin-right: 15px;
    font-size: 14px;
    color: #ffffff;
    padding: 4px 10px;
    cursor: pointer;

    &:link {
        color: white;
    }

    &:hover {
        color: #fff !important;
    }

    &:visited {
        color: white;
    }
}

.msgIconArea{ 
    margin: 10px 30px 0 0;
}
</style>
