// ctrl
import ZTablePaginationCtrlComponent from './ctrl/ZTablePaginationCtrl.vue'
import ZCheckTableCtrlComponent from './ctrl/ZCheckTableCtrl.vue'
import ZButtonListCtrlComponent from './ctrl/ZButtonListCtrl.vue'
import ZDialogCtrlComponent from './ctrl/ZDialogCtrl.vue'
import ZMainHeaderCtrlComponent from './ctrl/ZMainHeaderCtrl.vue'
import ZFileSelectorCtrlComponent from './ctrl/ZFileSelectorCtrl.vue'
import ZTreeCtrlComponent from './ctrl/ZTreeCtrl.vue'
import ZSelectWithInputCtrlComponent from './ctrl/ZSelectWithInputCtrl.vue'
import ZButtonWithLoadingCtrlComponent from './ctrl/ZButtonWithLoadingCtrl.vue'
import ZFormListCtrlComponent from './ctrl/ZFormListCtrl.vue'
import ZTipWithOverflowHideCtrlComponent from './ctrl/ZTipWithOverflowHideCtrl.vue'
import ZDatePickerCtrlComponent from './ctrl/ZDatePickerCtrl.vue'
import ZAreaMobileInputCtrlComponent from './ctrl/ZAreaMobileInputCtrl.vue'
import ZCountDownCtrlComponent from './ctrl/ZCountDownCtrl.vue'
import ZSixDigitPasswordCtrlComponent from './ctrl/ZSixDigitPasswordCtrl.vue'
import ZLoadingFollowCtrlComponent from './ctrl/ZLoadingFollowCtrl.vue'
import ZStepWithProcessCtrlComponent from './ctrl/ZStepWithProcessCtrl.vue'
import ZToolTipPlusCtrlComponent from './ctrl/ZToolTipPlusCtrl.vue'
import ZToolTipPlusCtrlSinComponent from './ctrl/ZToolTipPlusCtrlSin.vue'
import ZNumberAnimationCtrlComponent from './ctrl/ZNumberAnimationCtrl.vue'
import ZActiveRenderCtrlComponent from './ctrl/ZActiveRenderCtrl.vue'
import ZVirtualScrollListCtrlComponent from './ctrl/ZVirtualScrollListCtrl.vue'
import ZTransferCtrlComponent from './ctrl/ZTransferCtrl.vue'
// import ZCheckTableCtrlComponent from './ctrl/ZCheckTableCtrl.vue'

// area
import ZInfoModelsSelectorAreaComponent from './area/ZCommonArea/ZInfoModelsSelectorArea.vue'
import ZThingsSelectorAreaComponent from './area/ZCommonArea/ZThingsSelectorArea.vue'
import ZContainerAreaComponent from './area/ZContainerArea/ZContainerArea.vue'
import ZAuthPageAreaComponent from './area/ZAuthArea/ZAuthPageArea.vue'
import ZLoginAreaComponent from './area/ZAuthArea/ZLoginArea.vue'
import ZForgetPasswordAreaComponent from './area/ZAuthArea/ZForgetPasswordArea.vue'
import ZRegisterStepsAreaComponent from './area/ZAuthArea/ZRegisterStepsArea/ZRegisterStepsArea.vue'
import ZDataDownloadProgressAreaComponent from './area/ZCommonArea/ZDataDownloadProgressArea.vue'
import ZDataTransformDialogAreaComponent from './area/ZCommonArea/ZDataTransformDialogArea.vue'
import ZValidatePasswordDialogAreaComponent from './area/ZCommonArea/ZValidatePasswordDialogArea.vue'
import ZComboFormAreaComponent from "./area/ZCommonArea/ZComboFormArea.vue";
import ZComboTpsAreaComponent from "./area/ZCommonArea/ZComboTpsArea.vue";
import ZInputPasswordAreaComponent from "./area/ZCommonArea/ZInputPasswordArea.vue";
import ZRecallListAreaComponent from "./area/ZFileReCallArea/ZRecallListArea.vue";
import ZRecallThirdLinkListAreaComponent from "./area/ZFileReCallArea/ZRecallThirdLinkListArea.vue";
import ZRecallDownProcessAreaComponent from "./area/ZFileReCallArea/ZRecallDownProcessArea.vue";
import ZDeviceHighOrderAreaComponent from "./area/ZDeviceHighOrderArea/ZDeviceHighOrderArea.vue";
import ZDevInfoLabelsAreaComponent from "./area/ZDevInfoLabelsArea/ZDevInfoLabelsArea.vue";
import ZPackageToBuyAreaComponent from './area/ZCommonArea/ZPackageToBuyArea.vue'
import ZThirdLoginLoadingAreaComponent from './area/ZAuthArea/ZThirdLoginLoadingArea.vue'
import ZCapacitystatisticsTableArea from './area/ZCapacitystatisticsTableArea/ZCapacitystatisticsTableArea.vue'
import ZUserMessageIconArea from './area/ZUserMessageIconArea/ZUserMessageIconArea.vue'
import Directives from "../../sdk/ts/tool/ZDirectives/ZDirectives";
import './css/scrollbar.css'

export default {
    install(Vue: any) {
        Vue.use(Directives);
        Vue.component('ZTablePaginationCtrl', ZTablePaginationCtrlComponent)
        Vue.component('ZCheckTableCtrl', ZCheckTableCtrlComponent)
        Vue.component('ZButtonListCtrl', ZButtonListCtrlComponent)
        Vue.component('ZDialogCtrl', ZDialogCtrlComponent)
        Vue.component('ZMainHeaderCtrl', ZMainHeaderCtrlComponent)
        Vue.component('ZFileSelectorCtrl', ZFileSelectorCtrlComponent)
        Vue.component('ZTreeCtrl', ZTreeCtrlComponent)
        Vue.component('ZSelectWithInputCtrl', ZSelectWithInputCtrlComponent)
        Vue.component('ZButtonWithLoadingCtrl', ZButtonWithLoadingCtrlComponent)
        Vue.component('ZFormListCtrl', ZFormListCtrlComponent)
        Vue.component('ZTipWithOverflowHideCtrl', ZTipWithOverflowHideCtrlComponent)
        Vue.component('ZInfoModelsSelectorArea', ZInfoModelsSelectorAreaComponent)
        Vue.component('ZThingsSelectorArea', ZThingsSelectorAreaComponent)
        Vue.component('ZContainerArea', ZContainerAreaComponent)
        Vue.component('ZAuthPageArea', ZAuthPageAreaComponent)
        Vue.component('ZLoginArea', ZLoginAreaComponent)
        Vue.component('ZForgetPasswordArea', ZForgetPasswordAreaComponent)
        Vue.component('ZRegisterStepsArea', ZRegisterStepsAreaComponent)
        Vue.component('ZDataDownloadProgressArea', ZDataDownloadProgressAreaComponent)
        Vue.component('ZDataTransformDialogArea', ZDataTransformDialogAreaComponent)
        Vue.component('ZValidatePasswordDialogArea', ZValidatePasswordDialogAreaComponent)
        Vue.component('ZDatePickerCtrl', ZDatePickerCtrlComponent)
        Vue.component('ZComboFormArea', ZComboFormAreaComponent)
        Vue.component('ZComboTpsArea', ZComboTpsAreaComponent)
        Vue.component('ZInputPasswordArea', ZInputPasswordAreaComponent)
        Vue.component('ZAreaMobileInputCtrl', ZAreaMobileInputCtrlComponent)
        Vue.component('ZCountDownCtrl', ZCountDownCtrlComponent)
        Vue.component('ZSixDigitPasswordCtrl', ZSixDigitPasswordCtrlComponent)
        Vue.component('ZRecallListArea', ZRecallListAreaComponent)
        Vue.component('ZRecallThirdLinkListArea', ZRecallThirdLinkListAreaComponent)
        Vue.component('ZRecallDownProcessArea', ZRecallDownProcessAreaComponent)
        Vue.component('ZDeviceHighOrderArea', ZDeviceHighOrderAreaComponent)
        Vue.component('ZDevInfoLabelsArea', ZDevInfoLabelsAreaComponent)
        Vue.component('ZLoadingFollowCtrl', ZLoadingFollowCtrlComponent)
        Vue.component('ZStepWithProcessCtrl', ZStepWithProcessCtrlComponent)
        Vue.component('ZToolTipPlusCtrl', ZToolTipPlusCtrlComponent)
        Vue.component('ZToolTipPlusCtrlSin', ZToolTipPlusCtrlSinComponent)
        Vue.component('ZNumberAnimationCtrl', ZNumberAnimationCtrlComponent)
        Vue.component('ZActiveRenderCtrl', ZActiveRenderCtrlComponent)
        Vue.component('ZPackageToBuyArea', ZPackageToBuyAreaComponent)
        Vue.component('ZThirdLoginLoadingArea', ZThirdLoginLoadingAreaComponent)
        Vue.component('ZCapacitystatisticsTableArea', ZCapacitystatisticsTableArea)
        Vue.component('ZUserMessageIconArea', ZUserMessageIconArea)
        Vue.component('ZVirtualScrollListCtrl', ZVirtualScrollListCtrlComponent)
        Vue.component('ZTransferCtrl', ZTransferCtrlComponent)
        // Vue.component('ZCheckTableCtrl', ZCheckTableCtrlComponent)

    }
}
