<template>
    <!-- text-color="#b8c0cc" background-color="#1f334d" -->
    <!-- :default-active="router.currentRoute.value.path" -->
    <el-menu class="zws-aside-menu" :default-active="asideDefaultActive" :collapse="isClose"
             :collapse-transition="false" router>
        <div class="zws-aside-collapse">
            <i :class="['iconfont zws-aside-collapse-iconSetting', isClose ? `zlg-icon-side-expand` : `zlg-icon-side-shrink`]"
                   :title="isClose ? t('zws.ZAsideArea.showAside' || '显示侧边栏') : t('zws.ZAsideArea.hideAside' || '隐藏侧边栏')" @click="toggleIsCollapse"></i>
        </div>
        <template v-for="(item, index) in asideList" :key="index">
            <el-menu-item v-if="!item.childrens" :index="item.index" v-clickWaterAnimation>
                <div class="zws-aside-menu-item">
                    <i :class="`zws-aside-menu-item-iconSetting iconfont ${item.icon}`"></i>
                            <slot name="singelLevelMenu" :item="item">
                                    <span>{{ item.title }}</span>
                                </slot>
                        </div>
                    </el-menu-item>
                    <el-submenu v-else :index="String(index)" :key="index">
                        <template #title>
                            <div class="zws-aside-menu-item">
                                <i :class="`zws-aside-menu-item-iconSetting iconfont ${item.icon}`"></i>
                                <slot name="multilevelMenu" :item="item">
                                    <span>{{ item.title }}</span>
                                </slot>
                            </div>
                        </template>
                        <div v-for="(v, i) in item.childrens" :key="i">
                            <el-menu-item style="padding-left:18px" :index="v.index" v-clickWaterAnimation>
                                <slot name="subMenu" :v="v">
                                    <span>{{ v.title }}</span>
                                </slot>
                    </el-menu-item>
                </div>
            </el-submenu>
        </template>
    </el-menu>
</template>
<script lang="ts">
import { defineComponent, nextTick, ref, watch } from 'vue'
import { projectConfig } from '@/assets/ts/project_config'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useLocale } from '../../hooks/useLocale'
export default defineComponent({
    setup() {
        let { t } = useLocale()
        let store = useStore()
        let router = useRouter()
        let route = useRoute()
        let isClose = ref(false)

        let asideList: any = ref(projectConfig.asideList)
        let globalPermissionInfo = store.getters.globalPermissionInfo
        let userPromissionInfo = store.getters.tenantPermissionInfo

        // 如果是子用户，需要根据菜单权限，隐藏菜单
        if (userPromissionInfo && !userPromissionInfo.tenant_manager) {
            let asideListcopy: any = JSON.parse(JSON.stringify(asideList.value))
            //处理子用户菜单
            const menus = userPromissionInfo.menus[0].menu.replace('系统设置','')
            const menusList = menus.split(',')
            let menuObj: any = {}
            //将菜单数组展开扁平化，变成对象后方便进行菜单过滤
            asideListcopy.map((item: any) => {
                menuObj[item.title] = item.title
                if (item.childrens) {
                    item.childrens.map((val: any) => {
                        menuObj[val.title] = item.title
                    })
                }
            })
            let userMenus: any = {}
            menusList.map((val: any) => {
                if (menuObj[val]) {
                    userMenus[val] = menuObj[val]
                }
            })
            asideList.value = []
            //进行菜单过滤
            asideListcopy.map((item: any, key: number) => {
                if (item.childrens) {
                    let havaChild = false
                    let children: any = []
                    let childObj: any = {}
                    item.childrens.map((val: any, index: number) => {
                        if (userMenus[val.title]) {
                            havaChild = true
                            childObj[val.title] = val
                        }
                    })
                    for (let n in childObj) {
                        children.push(childObj[n])
                    }
                    if (havaChild) {
                        item.childrens = children
                        asideList.value.push(item)
                    }
                } else {
                    if (userMenus[item.title]) {
                        asideList.value.push(item)
                    }
                }


            })

            // // 拷贝菜单来删减
            // let asideListcopy: any = JSON.parse(JSON.stringify(asideList.value))
            // const menus = userPromissionInfo.menus[0].menu
            // console.log('menus: ', menus);
            // const menusList = menus.split(',')
            // // const menusList = menus.split(',').filter((item: string) => {
            // //     return item !== '系统设置'
            // //     return item !== '角色管理' && item !== '子用户管理' && item !== '系统设置'
            // // })
            // // 删除没有权限的二级菜单
            // for (let i = 0; i < asideListcopy.length; i++) {
            //     const itemValue = asideListcopy[i]
            //     // 如果不存在该模块权限
            //     if (menusList.indexOf(itemValue.title) == -1) {
            //         if (itemValue.childrens) {
            //             for (let index = 0; index < itemValue.childrens.length; index++) {
            //                 const child = itemValue.childrens[index]
            //                 if (menusList.indexOf(child.title) == -1) {
            //                     itemValue.childrens.splice(index, 1)
            //                     index--
            //                 }
            //             }
            //         }
            //     } else if (itemValue.childrens) {
            //         for (let index = 0; index < itemValue.childrens.length; index++) {
            //             const child = itemValue.childrens[index]
            //             if (menusList.indexOf(child.title) == -1) {
            //                 itemValue.childrens.splice(index, 1)
            //                 index--
            //             }
            //         }
            //     }
            // }
            // console.log('asideListcopy: ', asideListcopy);
            // //删除没有权限的一级菜单
            // for (let i = 0; i < asideListcopy.length; i++) {
            //     const itemValue = asideListcopy[i]
            //     // 如果不存在该模块权限
            //     if (menusList.indexOf(itemValue.title) == -1) {
            //         if (itemValue.childrens?.length === 0) {
            //             asideListcopy.splice(i, 1)
            //             i--
            //         }
            //     }
            // }
            // console.log('asideListcopy: ', asideListcopy);
            // asideList.value = asideListcopy
        } else {
            // 如果是不子用户，就按 后台配置，如果没后台配置就 使用 项目默认配置
            if (globalPermissionInfo?.menu) {
                let globalMenuList = JSON.parse(globalPermissionInfo.menu)
                if (globalMenuList && Array.isArray(globalMenuList) && globalMenuList.length > 0) {
                    let globalMenuFilterList = globalMenuList
                        .filter((item: any) => item.visible)
                        .map((item: any) => {
                            return {
                                ...item,
                                childrens:
                                    item.childrens && Array.isArray(item.childrens) ? item.childrens.filter((subItem: any) => subItem.visible) : null,
                            }
                        })
                    asideList.value = globalMenuFilterList
                }
            }
        }
        let asideDefaultActive: any = ref('')
        asideDefaultActive.value = getActiveIndex(route.path, asideList.value)
        watch(
            () => route.path,
            (to, previous) => {
                asideDefaultActive.value = getActiveIndex(to, asideList.value)
                router.currentRoute.value.path = asideDefaultActive.value
            }
        )
        function getActiveIndex(path: string, asideList: any) {
            let indexValue = ''
            let getIndexValue = (pat: string, asideList: any) => {
                asideList.forEach((item: any) => {
                    if (path.indexOf(item.index) !== -1) {
                        indexValue = item.index
                    }
                    if (item.childrens) {
                        getIndexValue(pat, item.childrens)
                    }
                })
            }
            getIndexValue(path, asideList)
            return indexValue
        }
        function toggleIsCollapse() {
            isClose.value = !isClose.value
        }
        return {
            router,
            isClose,
            asideList,
            asideDefaultActive,
            toggleIsCollapse,
            t,
        }
    },
})
</script>
<style lang="less" scoped>
.zws-aside-collapse {
    height: 20px;
    margin-right: 3px;
    text-align: right;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.zws-aside-collapse-iconSetting {
    font-size: 8px;
}

.zws-aside-menu-item-iconSetting {
    font-size: 22px;
    margin-right: 16px;
}
</style>
