<template>
    <div class="step-with-process-contain" v-if="modelValue">
        <el-dialog
                :show-close="false"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                :append-to-body="false"
                :modelValue="modelValue"
                :title="stepProcessTitle_"
                style="padding:10px;"
                :destroy-on-close="true"
        >
            <div class="step-with-process-content">
                <el-steps v-if="props.stepInfoList && props.stepInfoList.length > 1" class="step-item" :active="props.stepActive" align-center finish-status="success">
                    <template v-for="item in props.stepInfoList">
                        <el-step :title="item.title" :description="item.description" />
                    </template>
                </el-steps>
                <div class="process-item">
                    <el-progress
                            :text-inside="true"
                            :stroke-width="20"
                            :percentage="percentageComputed"
                            :key="props.stepActive * +new Date()"
                    >
                    </el-progress>
                    <div class="process-item-bottom">
                        <div class="process-item-bottom-tip">
                            <template v-if="stepInfoList && props.stepInfoList.length > 1 && props.stepInfoList[props.stepActive]?.tip">
                                <p class="process-item-bottom-tip-item">{{props?.stepInfoList[props.stepActive]?.tip}}，{{t('zws.ZStep.wait')||'请稍等'}}</p>
                                <ZLoadingFollowCtrl :loadingWidth="5" :loadingMargin="2" loadingColor="#76787a"></ZLoadingFollowCtrl>
                            </template>
                        </div>
                        <div class="process-item-bottom-rate" v-if="1||props.sizeUnit">{{t('zws.ZStep.done')||'已完成'}} {{sizeCompleteComputed}} / {{t('zws.ZStep.total')||'共'}} {{sizeTotalComputed}}</div>
                    </div>
                </div>
                <div class="button-item">
                    <el-button size_="mini" @click="processCancel">
                        {{processCancelButtonTxt_}}
                    </el-button>
                </div>
            </div>
        </el-dialog>
        <ZDialogCtrl :title="t('zws.ZCommon.tips.tip')" width="400px"
                     @buttonClicked="recallProcessDlgBtnClicked"
                     :show-close="false"
                     :close-on-press-escape="false"
                     :close-on-click-modal="false"
                     :show="stepWithProcessInfo.visible"
                     :append-to-body="true"
        >
            <p style="text-align: center">
                {{processCancelTipTxt_}}
            </p>
        </ZDialogCtrl>
    </div>
</template>

<script setup lang="ts">
    import { ref, reactive, withDefaults, defineProps, defineEmits, defineExpose, watch, computed, onMounted } from "vue";
    import ZFormatConversionTool from '../../../sdk/ts/tool/ZFormatConversionTool';

    import { useLocale } from '../hooks/useLocale'

    interface StepInfoList {
        title: string
        description?: string
        tip?: string
    }
    interface Props {
        modelValue: boolean
        stepProcessTitle?: (string|number) // 组件标题
        processCancelButtonTxt?: string
        processCancelTipTxt?: string
        percentage: number // 进度百分比
        sizeTotal: number // 总数
        sizeUnit?: string | undefined // 数据单位
        stepInfoList: Array<StepInfoList> // 步骤信息
        stepActive: number

    }
    interface RecallProcessInfo {
        visible: boolean
        visibility: string
    }
    let { t } = useLocale()
    const props = withDefaults(defineProps<Props>(), {
        modalValue: false,
        stepProcessTitle: '提示',
        processCancelButtonTxt: '取消',
        processCancelTipTxt: '是否取消操作',
        percentage: 0,
        stepActive: 0,
    });
    let stepProcessTitle_ = ref(t('zws.ZCommon.tips.tip'))
    let processCancelButtonTxt_ = ref(t('zws.ZButtonListCtrl.cancel'))
    let processCancelTipTxt_ = ref(t('zws.ZCommon.tips.cancelOperate')+'?')//: '是否取消操作',
    const emits = defineEmits(['midwayCancelClick']);
    const converByteUnitList = ['B', 'KB', 'MB', 'GB'];

    const percentageComputed = computed(() => {
        if (props.percentage < 0) return 0;
        if (props.percentage > 100) return 100;
        return props.percentage;
    });
    const sizeTotalComputed = computed(() => {
        if (props.sizeUnit && converByteUnitList.includes(props.sizeUnit)) return ZFormatConversionTool.converByteUnit(props.sizeTotal, props.sizeUnit);
        return `${(props.sizeTotal).toFixed(2)} ${props.sizeUnit}`;
    });
    const sizeCompleteComputed = computed(() => {
        let sizeSplitArr: any = sizeTotalComputed.value.split(' ');
        if (!sizeSplitArr || sizeSplitArr.length !== 2) return '--';
        return `${(sizeSplitArr[0] * percentageComputed.value / 100).toFixed(2)} ${sizeSplitArr[1]}`
    });

    let stepWithProcessInfo: RecallProcessInfo = reactive({
        visible: false,
        visibility: 'visible',
    });

    function processCancel() {
        stepWithProcessInfo.visibility = 'hidden';
        stepWithProcessInfo.visible = true;
    }
    function recallProcessDlgBtnClicked(ev: any) {
        stepWithProcessInfo.visible = false;
        if (ev.index == 1) { // 确定
            emits('midwayCancelClick', false);
        } else {
            stepWithProcessInfo.visibility = 'visible';
        }
    }

    watch(() => props.modelValue, (newVal) => {
        if (!newVal) {
            stepWithProcessInfo.visible = false;
        }
    })

</script>

<style lang="less" scoped>
    p {
        margin: 0;
    }
    .step-with-process-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 70px;
        position: relative;

        .step-item {
            width: 100%;
        }
        .process-item {
            margin-top: 46px;
            width: 86%;

            &-bottom {
                display: flex;
                justify-content: space-between;

                &-tip {
                    display: flex;
                    align-items: center;
                    margin-top: 4px;
                    min-height: 16px;
                    &-item {
                        color: #606266;
                        font-size: 13px;
                        margin-right: 6px;
                    }
                }

                &-rate {
                    font-size: 13px;
                    color: #909399;
                    padding: 8px 0;
                }
            }
        }

        .button-item {
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
        }
    }

</style>
