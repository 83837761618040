<template>
    <el-form ref="renderFormRef" label-width="0" :model="ruleForm" :rules="rules" style="position: relative">
        <div class="render-form-required-start" v-if="rules?.[type]?.[0]?.required">*</div>
        <el-form-item v-if="type === 'anyIntInput'" prop="anyIntInput">
            <el-input v-model="ruleForm.anyIntInput" @change="emitFormRefEvent" v-bind="$attrs"></el-input>
        </el-form-item>
        <el-form-item v-if="type === 'positiveIntInput'" prop="positiveIntInput">
            <el-input v-model="ruleForm.positiveIntInput" @change="emitFormRefEvent" v-bind="$attrs"></el-input>
        </el-form-item>
        <el-form-item v-if="type === 'anyNumberInput'" prop="anyNumberInput">
            <el-input v-model="ruleForm.anyNumberInput" @change="emitFormRefEvent" v-bind="$attrs"></el-input>
        </el-form-item>
        <el-form-item v-if="type === 'anyStringInput'" prop="anyStringInput">
            <el-tooltip :content="ruleForm.anyStringInput" placement="top" v-if="hasToolTip">
            <el-input v-model="ruleForm.anyStringInput" @change="emitFormRefEvent" v-bind="$attrs"></el-input>
            </el-tooltip>
            <el-input v-else v-model="ruleForm.anyStringInput" @change="emitFormRefEvent" v-bind="$attrs"></el-input>
        </el-form-item>
        <el-form-item v-if="type === 'booleanSelect'" prop="booleanSelect">
            <el-select v-model="ruleForm.booleanSelect" :placeholder="$t('common.select.pleaseChoose') || '请选择'"
                v-bind="$attrs" style="width: 100%" @change="emitFormRefEvent">
                <el-option v-for="item in booleanSelectOptions" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item v-if="type === 'enumSelect'" prop="enumSelect">
            <el-select v-if="ZValidateTool.checkArrayAndLength(enumOptionList)" v-model="ruleForm.enumSelect"
                :placeholder="$t('common.select.pleaseChoose') || '请选择'" v-bind="$attrs" style="width: 100%"
                @change="emitFormRefEvent">
                <el-option v-for="item in enumOptionList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <span v-else style="color: #909399; font-size: 14px">{{ $t_('zws.ZActiveRenderCtrl.noData') }}</span>
        </el-form-item>
    </el-form>
</template>
<script setup lang="ts">
import { ref, withDefaults, defineProps, defineEmits, reactive, onMounted, defineExpose } from "vue";

import { ZValidateDataTool as ZValidateTool } from '@zws/tools'
import { useLocale } from '../hooks/useLocale'
/**
 * 提供基础表单类型和自带类型校验
 * 支持自定义校验规则，传入校验函数
 * 支持枚举类型 select，自定义选择内容(枚举)
 * */
// 'anyIntInput':  整数input
// 'positiveIntInput':  正整数input
// 'anyNumberInput': 小数input(任意数字)
// 'anyStringInput':  字符串input(任意字符)
// 'booleanSelect':  布尔select
// 'array': 字符串input(任意字符),
// 'object': 字符串input(任意字符),
// 'enumSelect': 枚举 select

interface Props {
    hasToolTip?: boolean
    type: string
    modelValue: string
    enumOptionList?: any[]
    customValidateRule?: any[]
    customValidateRequiredLeft: string
    customValidateRequired?: boolean
    customValidateFunction?: Function
}

const props = withDefaults(defineProps<Props>(), {
    type: 'anyStringInput',
    modelValue: undefined,
    customValidateRequiredLeft: '-12px',
    customValidateRequired: false
});
const emits = defineEmits(['update:modelValue', 'getRenderFormRefList', 'update:refObj',]);

const { t } = useLocale()
let $t_ = t
const renderFormRef: any = ref(null);

const booleanSelectOptions = [
    { label: $t_('zws.ZActiveRenderCtrl.yes'), value: true },
    { label: $t_('zws.ZActiveRenderCtrl.no'), value: false }
];

const ruleForm: any = reactive({
    anyIntInput: props.modelValue,
    positiveIntInput: props.modelValue,
    anyNumberInput: props.modelValue,
    anyStringInput: props.modelValue,
    booleanSelect: props.modelValue,
    enumSelect: props.modelValue,
});

const rules: any = reactive({
    anyIntInput: props.customValidateRule || [{ required: props.customValidateRequired, message: $t_('zws.ZActiveRenderCtrl.pleaseEnterInteger'), validator: anyIntInput, trigger: 'change' }],
    positiveIntInput: props.customValidateRule || [{ required: props.customValidateRequired, message: $t_('zws.ZActiveRenderCtrl.pleaseEnterPositiveInteger'), validator: positiveIntInput, trigger: 'change' }],
    anyNumberInput: props.customValidateRule || [{ required: props.customValidateRequired, message: $t_('zws.ZActiveRenderCtrl.pleaseEnterNumber'), validator: anyNumberInput, trigger: 'change' }],
    anyStringInput: props.customValidateRule || [{ required: props.customValidateRequired, message: $t_('zws.ZActiveRenderCtrl.pleaseEnterTheCorrectContent'), trigger: 'change' }],
    booleanSelect: props.customValidateRule || [{ required: props.customValidateRequired, message: $t_('zws.ZActiveRenderCtrl.pleaseSelect'), trigger: 'change' }],
    enumSelect: props.customValidateRule || [{ required: props.customValidateRequired, message: $t_('zws.ZActiveRenderCtrl.pleaseSelect'), trigger: 'change' }],
});

function anyIntInput(rule: any, value: any, callback: Function) {
    if (!rule.required && !value) return callback();
    if (typeof props.customValidateFunction === 'function' ? props.customValidateFunction(value) : !ZValidateTool.checkNumber(value, 'integer')) {
        return callback(new Error(rule.message))
    } else callback()
}
function positiveIntInput(rule: any, value: any, callback: Function) {
    if (!rule.required && !value) return callback();

    if (typeof props.customValidateFunction === 'function' ? props.customValidateFunction(value) : !ZValidateTool.checkNumber(value, 'positiveInteger')) {
        return callback(new Error(rule.message))
    } else callback()
}
function anyNumberInput(rule: any, value: any, callback: Function) {
    if (!rule.required && !value) return callback();

    if (typeof props.customValidateFunction === 'function' ? props.customValidateFunction(value) : !ZValidateTool.checkNumber(value, 'floatNumber')) {
        return callback(new Error(rule.message))
    } else callback()
}
function emitFormRefEvent(e: any) {
    emits('update:modelValue', e);
    emits('update:refObj', e);
}

onMounted(() => {
    emits('getRenderFormRefList', renderFormRef.value);
})

defineExpose({ $t_ })
</script>

<style lang="less" scoped>
.el-form-item {
    margin-bottom: 0 !important;
}

/deep/ .el-form-item__error {
    top: 92% !important;
}

.render-form-required-start {
    color: red;
    position: absolute;
    font-size: 16px;
    top: 9px;
    z-index: 999;
    left: v-bind(customValidateRequiredLeft);
    opacity: .5;
}
</style>
