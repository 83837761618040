export default {
  tenantPermissionInfo(state: { tenantPermissionInfo: any }) {
    let tenantPermissionInfo: any = state.tenantPermissionInfo
    if (Object.keys(tenantPermissionInfo).length === 0) {
      let info: any = localStorage.getItem('tenantPermissionInfo')
      if (info) {
        tenantPermissionInfo = JSON.parse(info)
      }
    }
    return tenantPermissionInfo
  },
  currentDashbordInfo(state: { currentDashbordInfo: any }) {
    let currentDashbordInfo: any = state.currentDashbordInfo
    if (Object.keys(currentDashbordInfo).length === 0) {
      let info: any = localStorage.getItem('currentDashbordInfo')
      if (info) {
        currentDashbordInfo = JSON.parse(info)
      }
    }
    return currentDashbordInfo
  },
  deviceDetailsCurrentDeviceInfo(state: { deviceDetailsCurrentDeviceInfo: any }) {
    let deviceDetailsCurrentDeviceInfo: any = state.deviceDetailsCurrentDeviceInfo
    if (Object.keys(deviceDetailsCurrentDeviceInfo).length === 0) {
      let info: any = localStorage.getItem('deviceDetailsCurrentDeviceInfo')
      if (info) {
        deviceDetailsCurrentDeviceInfo = JSON.parse(info)
      }
    }
    return deviceDetailsCurrentDeviceInfo
  },
  tenantCustomizationInfo(state: { tenantCustomizationInfo: any }) {
    let tenantCustomizationInfo: any = state.tenantCustomizationInfo
    if (Object.keys(tenantCustomizationInfo).length === 0) {
      let info: any = localStorage.getItem('tenantCustomizationInfo')
      if (info) {
        tenantCustomizationInfo = JSON.parse(info)
      }
    }
    return tenantCustomizationInfo
  },
  userInfo(state: { userInfo: any }) {
    let userInfo: any = state.userInfo
    if (Object.keys(userInfo).length === 0) {
      let getUserInfo: any = localStorage.getItem('userInfo')
      if (getUserInfo) {
        userInfo = JSON.parse(getUserInfo)
      }
    }
    return userInfo
  },
  registerInputUserInfo(state: { registerInputUserInfo: any }) {
    return state.registerInputUserInfo
  },
  headerMenuActiveIndex(state: { headerMenuActiveIndex: string }) {
    let info: any = state.headerMenuActiveIndex
    if (info === '') {
      let getInfo: any = localStorage.getItem('headerMenuActiveIndex')
      if (getInfo) {
        info = getInfo
      }
    }
    return info
  },
  getUserPackage(state: { userPackage: any }) {
    return state.userPackage
  },
  globalPermissionInfo(state: { globalPermissionInfo: any }) {
    return state.globalPermissionInfo
  },
  getEchartsListChange(state: any) {
    return state.echartsListChange
  },
  getRuleListChange(state: any) {
    return state.ruleListChange
  },
  getDevDetailLoading(state: any) {
    return state.devDetailLoading
  },
  getRefreshDevList(state: any) {
    return state.refreshDevList
  },
  getDetailCurrentPart(state: any) {
    return state.detailCurrentPart
  },
  getEcuListChange(state: any) {
    return state.ecuListChange
  },
  getAnnouncementShow(state: any) {
    return state.announcementShow
  }
}
