import ZWSBaseModule from '../httpBase/ZWSBaseModule'
import { Pagination } from './ZWSPagination'

export interface Tenant {
  id: number //租户id
  name: string //租户名
  [propName: string]: any
}

export interface UserInfo {
  avatar: string
  create_time: number
  department: string
  description: string
  email: string
  gender: string
  id: number
  job: string
  mobile: string
  name: string
  nick_name: string
  status: number
  tenant_id: number
  type: number
}

export interface GetTenantListResp {
  list: Array<Tenant>
  [propName: string]: any
}

export interface TenantSpecification {
  tenant_id: number //租户id
  name: string //租户名
  [propName: string]: any
}

export type AddSingleSubUserReq = {
  tenantId: number,
  name: string,
  mobile: string,
  department: string,
  description: string,
  email: string,
  gender: string,
  job: string,
  nick_name: string,
  last_login_ip: string,
  last_login_time: number,
  parent_user_id: number,
  type: number,
  user_flag: number,
}
export type UpdateSingleSubUserReq = {
  tenantId: number,
  userId: number,
  name: string,
  mobile: string,
  department: string,
  description: string,
  email: string,
  gender: string,
  job: string,
  nick_name: string,
  last_login_ip: string,
  last_login_time: number,
  parent_user_id: number,
  type: number,
  user_flag: number,
}
/************* labels ****************************************************************/

export interface Label {
  id?: number  // 标签id
  label: string //标签名
  description?: string //标签描述
  value?: string //标签值
  [propName: string]: any
}

export interface GetLabelsResp {
  pagination: {
    total_size: number,//结果集总数
    current_page: number,//当前页码
    page_size: number//每页记录数
  }
  list: Array<Label>
}

export type AddSingleLabelResp = number

/************* tenantUsers ***********************************************************/

export interface GetBatchSubUsersResp {
  list: Tenant[]
  pagination: Pagination
}

/**
  * 租户管理
  */
class ZWSTenant extends ZWSBaseModule {

  /************* labels ****************************************************************/

  /**

  * 添加标签
  * @param {number} tenantId 租户id
  * @param {Label} label 标签 
  * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
  */
  static labels_addSingleLabel({ tenant_id, label }: { tenant_id: number, label: Label }) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/labels`,
      method: 'post',
      data: label
    })
  }

  /**
   * 删除标签
   * @param {number} tenantId 租户id
   * @param {string} ids 标签id，逗号分隔
   * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
   */
  static labels_deleteBatchLabels({ tenant_id, ids }: { tenant_id: number, ids: string }) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/labels/${ids}`,
      method: 'delete',
      params: { ids }
    })
  }
  /**
* 查询租户标签
* @param {string} name - 名称，模糊匹配
* @param {number} status - 状态。1：正常， 2：待审核3：停用
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
*/
  static labels_getLabels({ tenant_id, current_page, page_size, prefix, scope }: any) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/labels`,
      method: 'get',
      params: { current_page, page_size, prefix, scope }
    })
  }
  /**
     * 获取租户下特定的标签
     * @param {number} tenantId 租户id
     * @param {number} id 标签id
     * @param {Label} label 标签
     * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
     */
  static labels_modifySingleLabel({ tenant_id, id, label }: { tenant_id: number, id: number, label: Label }) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/labels/${id}`,
      method: 'put',
      data: label
    })
  }

  /**
    * 获取租户下特定的标签
    * @param {number} tenant_id 租户id
    * @param {number} id 标签id
    */
  static labels_getSingleLabel({ tenant_id, id }: { tenant_id: number, id: number }) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/labels/${id}`,
      method: 'get',
    })
  }

  /**
  * 批量查询租户下的标签
  * @param {number} tenantId 租户id
  * @param {string} prefix 标签名称前缀
  * @param {string} scope 查询范围，默认all，global 只查全局，tenant 只查当前租户，all 全局+当前租户
  * @param {any} params 额外参数
  * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
  */
  static labels_getBatchLabels({ tenant_id, prefix = "", scope = 'all', current_page = 1, page_size = 10000, exact = false }: { tenant_id: number, prefix?: string, scope?: string, current_page?: number, page_size?: number, exact?: boolean }) {
    if (!page_size) {
      page_size = 10000
    }

    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/labels`,
      method: 'get',
      params: { prefix, scope, current_page: current_page, page_size: page_size }
    }).then((res: GetLabelsResp) => {
      if (exact) {//精确匹配名字
        res.list = res.list.filter((l => {
          return l.label == prefix
        }))
      }

      return Promise.resolve(res)
    })
  }

  /**
     * 批量查询租户下的标签
     * @param {number} tenantId 租户id
     * @param {string} ids 标签id，逗号分隔
     * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link User} }
     */
  static labels_getBatchLabelByIds({ tenant_id, ids }: { tenant_id: number, ids: string }) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/labels/batch`,
      method: 'get',
      params: { ids }
    })
  }

  /************* tenantExport **********************************************************/
  /************* tenantUsers ***********************************************************/
  /**
   * 查询租户下的子用户
   * @param {Object} [params]
   * @param {number} params.current_page - 当前页。从第1页开始,Default value : 1
   * @param {number} params.page_size - 每页数量,Default value : 20
   * @param {string} params.search - 关键字查询，模糊匹配。涉及名称和描述
   * @param {string} params.status - 状态
   * @param {number} params.parent_id - 父节点id
   * @param {string} params.tenantId - * 租户id
   * @param {boolean} params.only_sub_user - 是否仅当前账号下一级的子用户
   * @param {number} params.type - 子用户类别 1 普通子用户 2 权限子用户（用于单独做权限管理的）
   * @param {string} params.mobile - 手机
   * @param {string} params.name - 用户名
   * @param {number} params.user_flag - 自定义用户标记；默认0，其它值可自行定义存取
   * @return {Promise<Response, Error>} - response: { result: true, message: '', jwt: '', data: [{@link Subuser}] }
   */
  static tenantUsers_getBatchSubUsers({ current_page, page_size, search, status, tenant_id, only_sub_user, type, parent_id, mobile, name, user_flag }: {
    current_page: number, page_size: number, search?: string, status?: number, tenant_id: number, only_sub_user?: boolean, type?: number, parent_id?: number, mobile?: string, name?: string, user_flag?: number
  }) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/users`,
      method: 'get',
      params: { current_page, page_size, search, status, only_sub_user, type, parent_id, mobile, name, user_flag }
    })
  }
  /**
   * 查询租户下的指定用户信息
   * @param {Object} [params]
   * @return {Promise<Response, Error>} - response: { result: true, message: '', jwt: '', data: [{@link Subuser}] }
   */
  static tenantUsers_getSingleSubUsers({ tenant_id, user_id }: { tenant_id: number, user_id: number }) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/users/${user_id}`,
      method: 'get',
    })
  }
  /**
   * 添加子用户
   * @param {number} params.tenantId - 租户ID
   * @param {string} params.name - 用户名
   * @param {string} params.mobile - 手机
   * @param {string} params.department - 部门
   * @param {string} params.description - 个人说明
   * @param {string} params.email - 邮箱
   * @param {string} params.gender - 性别
   * @param {string} params.job - 职位
   * @param {string} params.nick_name - 昵称
   * @param {string} params.last_login_ip - 最后登录ip
   * @param {number} params.last_login_time - 最后登录时间
   * @param {number} params.parent_user_id - 用户的上级用户ID
   * @param {number} params.type - 子用户类别 1 普通子用户 2 权限子用户（用于单独做权限管理的）
   * @param {number} params.user_flag - 自定义用户标记；默认0，其它值可自行定义存取
   * @return {Promise<Response, Error>} - response: { result: true, message: '', jwt: '', data: {@link Subuser} }
   */
  static tenantUsers_addSingleTenantUser({ tenantId, name, mobile, department, description, email, gender, job, nick_name, last_login_ip, last_login_time, parent_user_id, type, user_flag }: AddSingleSubUserReq) {
    return this.fetch({
      url: `/tenant/tenants/${tenantId}/users`,
      method: 'post',
      data: { department, description, email, mobile, name, nick_name, gender, job, last_login_ip, last_login_time, parent_user_id, type, user_flag }
    })
  }
  /**
   * 更新子用户信息
   * @param {string} params.tenantId - 租户ID
   * @param {string} params.name - 用户名
   * @param {string} params.mobile - 手机
   * @param {string} params.department - 部门
   * @param {string} params.description - 个人说明
   * @param {string} params.email - 邮箱
   * @param {string} params.gender - 性别
   * @param {string} params.job - 职位
   * @param {string} params.nick_name - 昵称
   * @param {string} params.last_login_ip - 最后登录ip
   * @param {number} params.last_login_time - 最后登录时间
   * @param {number} params.parent_user_id - 用户的上级用户ID
   * @param {number} params.type - 子用户类别 1 普通子用户 2 权限子用户（用于单独做权限管理的）
   * @param {number} params.user_flag - 自定义用户标记；默认0，其它值可自行定义存取
   * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: {@link Subuser} }
   */
  static tenantUsers_updateSingleTenantUser({ tenantId, userId, name, mobile, department, description, email, gender, job, nick_name, last_login_ip, last_login_time, parent_user_id, type, user_flag }: UpdateSingleSubUserReq) {
    return this.fetch({
      url: `/tenant/tenants/${tenantId}/users/${userId}`,
      method: 'put',
      data: { name, mobile, department, description, email, gender, job, nick_name, last_login_ip, last_login_time, parent_user_id, type, user_flag }
    })
  }

  /**
   * 重置子用户密码
   * @param {Object} params
   * @param {string[]} params.tenantId - 租户id
   * @param {string[]} params.userId - 子用户id
   * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '' }
   */
  static tenantUsers_updateSingleUserPassword({ tenantId, userId }: { tenantId: number, userId: number }) {
    return this.fetch({
      url: `/tenant/tenants/${tenantId}/users/${userId}/reset-password`,
      method: 'post',
    })
  }
  /**
   * 删除单个子用户
   * @param {Object} params
   * @param {string} params.tenantId  - 租户ID
   * @param {string} params.userId  - 用户ID
   * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '' }
   */
  static tenantUsers_deleteSingleTenantUser({ tenantId, userId }: any) {
    return this.fetch({
      url: `/tenant/tenants/${tenantId}/users/${userId}`,
      method: 'delete'
    })
  }
  /**
   * 批量删除子用户
   * @param {Object} params
   * @param {string} params.tenantId  - 租户ID
   * @param {array} params.ids  - 用户ID
   * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '' }
   */
  static tenantUsers_deleteBatchTenantUsers({ tenantId, ids }: { tenantId: number, ids: any }) {
    return this.fetch({
      url: `/tenant/tenants/${tenantId}/users`,
      method: 'delete',
      params: { ids }
    })
  }
  /************* tenants ***************************************************************/

  /** 
   *  查询全部租户的规格
   */
  static tenants_getAllSpecification() {
    return this.fetch({
      url: '/tenant/tenants/all/specification',
      method: 'get'
    })
  }
  /**
* 查询租户规格
* @param {string} name - 名称，模糊匹配
* @param {number} status - 状态。1：正常， 2：待审核3：停用
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
*/
  static tenants_getTenantSpecification({ tenant_id }: any) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/specification`,
      method: 'get',
      data: {}
    })
  }
  /** 
   *  查询全部租户的用量统计
   */
  static tenants_getAllUsageStatistics() {
    return this.fetch({
      url: '/tenant/tenants/all/usage-statistics',
      method: 'get'
    })
  }
  /** 
   *  查询租户定制化信息
   */
  static tenants_getCustomization({ tenant_id }: any) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/customization`,
      method: 'get'
    })
  }
  /** 
   *  更新租户的定制化信息
   */
  static tenants_updateCustomization({ tenant_id, body }: any) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/customization`,
      method: 'put',
      data: body
    })
  }
  /**
   * 条件查询租户
   * audited 默认空查询全部。true 只查已审核，false 只查待审核。
   * ids 租户id列表，逗号分隔
   */
  static tenants_gettenants({ audited, current_page = 1, email, ids, mobile, name, page_size = 20, status }: { audited: boolean, current_page: number, email: string, ids: string, mobile: string, name: string, page_size: number, status: number }) {
    return this.fetch({
      url: `/tenant/tenants`,
      method: 'get',
      params: { audited, current_page, email, ids, mobile, name, page_size, status }
    })
  }
  /**
   *  查询单个租户详情,查询参数name和id二选一 
   */
  static tenants_getSingletenant({ id, name }: { id: number, name: string }) {
    return this.fetch({
      url: `/tenant/tenant-detail`,
      method: 'get',
      params: { id, name }
    })
  }

   /**
    * 更新租户信息 （系统管理员）
    * @param {string} name - 名称，模糊匹配
    * @param {string} audited - 默认空查询全部。true 只查已审核，false 只查待审核。
    * @param {number} status - 状态。1：正常， 2：待审核 3：停用
    * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
    */
    static tenants_updateTenantInfo({ tenant_id, address, belong_platform, city, country, industry, organization_name, province, description, platform, status, priority, return_visit_time }: any) {
      return this.fetch({
        url: `/tenant/tenants/${tenant_id}`,
        method: 'put',
        data: { address, city, belong_platform, country, industry, organization_name, province, description, platform, status, priority, return_visit_time }
      })
    }

  /************* userTenants ***********************************************************/
  /**
   * 用户注册信息校验，mobile、name、email
   * @param params 
   * @returns 
   */
  static userTenants_checkUserTenantValidate({ field, value }: any) {
    return this.fetch({
      url: '/tenant/user-tenant-validate',
      method: 'get',
      params: { field, value },
      headers: {}
    })
  }
  /**
* 注册新账号

 "address": "string",
  "city": "string",
  "country": "string",
  "department": "string",
  "description": "string",
  "email": "string",
  "email_verification_code": "string",
  "gender": "string",
  "industry": "string",
  "job": "string",
  "mobile": "string",
  "mobile_verification_code": "string",
  "name": "string",
  "namespace": "string",
  "nick_name": "string",
  "organization_name": "string",
  "password": "string",
  "province": "string",
  "username": "string"
  "platform": "string"
*/
  static userTenants_addUserTenants(params: any) {
    return this.fetch({
      url: '/tenant/user-tenants',
      method: 'post',
      data: params,
      headers: {}
    })
  }

  /************* users *****************************************************************/
  /**
  * 使用验证码重置密码
  * @param {string} new_password - 发送方式：1 短信,2 邮件
  * @param {string} source - 手机号码
  * @param {string} type - 用途 login/register/modify（修改信息）
  * @param {string} verification_code - 用途 login/register/modify（修改信息）
  * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '' }
  * @param {string} user_type - 用户类型：1.租户 2.子用户 -1不指定
  */
  static users_updateResetPassword({ type, source, verification_code, new_password, user_type }: any) {
    return this.fetch({
      url: '/tenant/reset-password',
      method: 'post',
      data: { type, source, verification_code, new_password, user_type }
    })
  }
  /**
*  更新当前用户密码
* @param {string} new_password - 发送方式：1 短信,2 邮件
* @param {string} source - 手机号码
* @param {string} type - 用途 login/register/modify（修改信息）
* @param {string} verification_code - 用途 login/register/modify（修改信息）
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '' }
*/
  static users_updateUserSelfCurrentPassword({ old_password, password, code, mobile }: { old_password: string, password: string, code: string, mobile: string }) {
    return this.fetch({
      url: '/tenant/users/self/password',
      method: 'put',
      data: { old_password, password, code, mobile }
    })
  }
  /**
*  更新当前用户手机号码
* @param {string} new_mobile - 新的手机号码
* @param {string} new_code - 验证码
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '' }
*/
  static users_updateUserSelfMobile({ new_mobile, new_code, old_mobile }: any) {
    return this.fetch({
      url: '/tenant/users/self/mobile',
      method: 'put',
      data: { new_mobile, new_code, old_mobile }
    })
  }
  /**
*  更新当前用户邮箱
* @param {string} new_email - 新的邮箱地址
* @param {string} new_code - 邮箱验证码
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '' }
*/
  static users_updateUserSelfEmail({ new_email, new_code, old_email }: any) {
    return this.fetch({
      url: '/tenant/users/self/email',
      method: 'put',
      data: { new_email, new_code, old_email }
    })
  }
  /**
* 更新用户头像
* @param {Object} params
* @param {string} params.username - 用户名
* @param {string} params.file - WEB 提供通过 input 获得的 file
* @param {string} params.url - APP 提供文件的 url
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '' }
*/
  static users_updateUserSelfAvatar({ file }: any) {
    let uploadFile = file.target.files[0]
    let imgFormdata = new FormData()
    imgFormdata.append('avatar', uploadFile, file.name)
    return this.fetch({
      url: '/tenant/users/self/avatar',
      method: 'put',
      data: imgFormdata
    })
  }
  /**
   * 查询当前用户信息
   */
  static users_getSelfUserInfo() {
    return this.fetch({
      url: '/tenant/users/self',
      method: 'get',
      params: {}
    })
  }
  /**
   * 用户信息更新
   */
  static users_updateSelfUserInfo(data: { department?: string, industry?: string, description?: string, address?: string, gender?: string, job?: string, nick_name?: string }) {
    return this.fetch({
      url: '/tenant/users/self',
      method: 'put',
      data: data
    })
  }
  /**
   * 判断用户是否是租户管理员
   * @param { number } type - 1 用户名 2 手机号
   * @param { string } username
   */
  static users_checkTenantIsManager(params: { type: number, username: string }) {
    return this.fetch({
      url: '/tenant/is-manager',
      method: 'get',
      params
    })
  }

  /**
   * 查询当前租户信息
   */
  static users_getUsersSelfTenant() {
    return this.fetch({
      url: '/tenant/users/self/tenant',
      method: 'get',
      params: {}
    })
  }

  /************* verificationCodes *****************************************************/
  /**
   * 发送验证码
   * @param {string} type - 发送方式：1 短信,2 邮件
   * @param {string} destination - 手机号或邮箱地址
   * @param {string} purpose - 用途 login/register/modify（修改信息）
   * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '' }
 */
  static verificationCodes_getVerificationCodes({ type, destination, purpose }: any) {
    return this.fetch({
      url: '/tenant/verification-codes',
      method: 'post',
      data: { type, destination, purpose },
      headers: ''
    })
  }

  /**
   * 查询满足条件的租户信息
   * @param {string} name - 名称，模糊匹配
   * @param {string} audited - 默认空查询全部。true 只查已审核，false 只查待审核。
   * @param {number} status - 状态。1：正常， 2：待审核 3：停用
   * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
   */
  static getTenantList({ current_page, page_size, name, mobile, audited, status, sort_property, sort_order, platform, priority_expression, ids, return_visit_time_start, return_visit_time_end }: any) {
    return this.fetch({
      url: '/tenant/tenants',
      method: 'get',
      params: { current_page, page_size, name, mobile, audited, status, sort_property, sort_order, platform, priority_expression, ids, return_visit_time_start, return_visit_time_end }
    })
  }
  /**
 * 查询租户审核记录统计信息
 * @param {string} name - 名称，模糊匹配
 * @param {string} audited - 默认空查询全部。true 只查已审核，false 只查待审核。
 * @param {number} status - 状态。1：正常， 2：待审核 3：停用
 * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
 */
  static getTenantAauditStatistic() {
    return this.fetch({
      url: '/tenant/tenant-audit-statistic',
      method: 'get'
    })
  }
  /**
 * 查询租户审核记录
 * @param {string} name - 名称，模糊匹配
 * @param {string} audited - 默认空查询全部。true 只查已审核，false 只查待审核。
 * @param {number} status - 状态。1：正常， 2：待审核 3：停用
 * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
 */
  static getTenantAudits({ current_page, page_size, name, mobile, status }: any) {
    return this.fetch({
      url: '/tenant/tenant-audits',
      method: 'get',
      params: { current_page, page_size, name, mobile, status }
    })
  }

  /**
 * 查询单个租户详情
 * @param {string} name - 名称，模糊匹配
 * @param {string} audited - 默认空查询全部。true 只查已审核，false 只查待审核。
 * @param {number} status - 状态。1：正常， 2：待审核 3：停用
 * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
 */
  static getSingleTenantInfo({ id, name }: any) {
    return this.fetch({
      url: '/tenant/tenant-detail',
      method: 'get',
      params: { id, name, }
    })
  }
  /**
* 查询租户下指定的用户信息
* @param {string} name - 名称，模糊匹配
* @param {string} audited - 默认空查询全部。true 只查已审核，false 只查待审核。
* @param {number} status - 状态。1：正常， 2：待审核 3：停用
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
*/
  static getTenantSingleUserInfo({ tenantId, userId }: any) {
    return this.fetch({
      url: `/tenant/tenants/${tenantId}/users/${userId}`,
      method: 'get',
      params: {}
    })
  }
 
  /**
* 更新租户信息-规格（租户管理员）
* @param {string} name - 名称，模糊匹配
* @param {string} audited - 默认空查询全部。true 只查已审核，false 只查待审核。
* @param {number} status - 状态。1：正常， 2：待审核 3：停用
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
*/
  static updateCurrentTenantInfo({ address, city, country, description, industry, organization_name, province }: any) {
    return this.fetch({
      url: `/tenant/users/self/tenant`,
      method: 'put',
      data: { address, city, country, description, industry, organization_name, province }
    })
  }
  /**
* 更新租户信息-规格（租户管理员）
* @param {string} name - 名称，模糊匹配
* @param {string} audited - 默认空查询全部。true 只查已审核，false 只查待审核。
* @param {number} status - 状态。1：正常， 2：待审核 3：停用
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
*/
  static updateCurrentTenantSpecInfo({ reclaim_policy, max_reserves_duration }: any) {
    return this.fetch({
      url: `/tenant/users/self/tenant-spec`,
      method: 'put',
      data: { reclaim_policy, max_reserves_duration }
    })
  }

  /**
* 更新租户下的指定用户信息
* @param {string} name - 名称，模糊匹配
* @param {string} audited - 默认空查询全部。true 只查已审核，false 只查待审核。
* @param {number} status - 状态。1：正常， 2：待审核 3：停用
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
*/
  static updateTenantSingleUserInfo({ tenant_id, id, department, description, email, gender, job, mobile, name, nick_name, organization_name }: any) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/users/${id}`,
      method: 'put',
      data: { department, description, email, gender, job, mobile, name, nick_name, organization_name }
    })
  }
  /**
   * 审核租户-对自主注册的租户进行审核。
   * @param {string} name - 名称，模糊匹配
   * @param {number} status - 状态。1：正常， 2：待审核3：停用
   * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
   */
  static checkTenant({ tenant_id, pass, notify }: any) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/audit/${pass}`,
      method: 'put',
      params: { notify }
    })
  }
  /**
  * 查询租户规格-能使用资源的额度数
  * @param {string} name - 名称，模糊匹配
  * @param {number} status - 状态。1：正常， 2：待审核3：停用
  * @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
  */
  static checkTenantSpecification({ tenant_id }: any) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/specification`,
      method: 'get'
    })
  }

  /**
* 更新租户的规格
* @param {string} name - 名称，模糊匹配
* @param {number} status - 状态。1：正常， 2：待审核3：停用
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
*/
  static updateTenantSpecification({ tenant_id, bytes_quota, things_quota, documents_download_quota, labels_quota, rules_quota, users_quota, reclaim_policy }: any) {
    return this.fetch({
      url: `/tenant/tenants/${tenant_id}/specification`,
      method: 'put',
      data: { tenant_id, bytes_quota, things_quota, documents_download_quota, labels_quota, rules_quota, users_quota, reclaim_policy }
    })
  }
  /**
* 下载租户信息
* @param {string} name - 名称，模糊匹配
* @param {number} status - 状态。1：正常， 2：待审核3：停用
* @return {Promise<Response, Error>} - response: { result: true, message: 'OK', jwt: '', data: [{@link User}] }
*/
  static downloadTenantCSVFile({ page_size }: any) {
    return this.fetch({
      url: '/tenant/tenants-csv-file',
      method: 'get',
      params: { page_size },
      headers: {
        'Accept': 'application/csv', "Authorization": `Bearer ${localStorage.getItem('token') as string}`
      }
    })
  }

}
export default ZWSTenant
