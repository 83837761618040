/**
 * GPS轨迹
 */
export default {
  endTime: '结束时间：',
  startTime: '开始时间：',
  realtimeSpeed: '实时速度：',
  averageSpeed: '平均速度：',
  totalDistance: '总里程：',
  endPoint: '终点',
  startPoint: '起点',
  obtainHistoryTrackFailed: '获取历史轨迹失败',
  historyDataLimit: '最多显示从搜索起始时间开始的 200000 个点位以内的数据',
  pleaseInputTimeRange: '请输入时间范围',
  noDataInTheTime: '该时间范围内无数据',
  systemUpdateTip: '由于系统升级，{date}之前的GPS数据可能会存在兼容性问题，将会影响轨迹绘画，建议您从这个时间往后筛选数据显示。',
  errorTimeTip: '该时间范围内设备时间存在异常，可能会导致历史轨迹计算的平均速度异常',
  dataDisplayLimit: '最多显示从搜索起始时间开始的5000个点位以内的数据',
  obtainTrackFailed: '获取轨迹失败，尝试重新获取中',
  obtainingTrackData: '正在获取轨迹数据',
  realtimeTrack: '实时轨迹',
  historicalTrack: '历史轨迹'
}
