import ZWSBaseModule from "../httpBase/ZWSBaseModule";

export interface TableResult {
  data: Dev[] | DownloadLog[] | UploadLog[],
  total: number
}

export interface DownloadLog {
  id: number,
  infoModelName: string,
  message: string,
  tenantId: number,
  updateTime: number,
  createTime: number,
  _id: string
}

export interface UploadLog {
  _id: string,
  type: string,
  data: string,
  createTime: number
}

export interface Dev {
  dataTime: number,
  id: number,
  infoModelId: number,
  infoModelName: string,
  isConnect: number,
  isTimer: number,
  logTime: number,
  name: string,
  ownerId: number,
  ownerName: string,
  parentThingsId: number,
  rawTime: number,
  schemas: Schemas[],
  statusTime: number,
  tenantId: number,
  thirdThingsId: string,
  _id: string
}

export interface Schemas {
  name: string,
  schema: Schema
}

export interface Schema {
  configuration: Object,
  events: Event[],
  operations: Operation[],
  status: Status
}

export interface Operation {
  name: string,
  response: string,
  description: string,
  inputs: []
}

export interface Status {
  properties: Propertie[]
}

export interface Event {
  event_type: string,
  name: string,
  properties: Propertie[]
}

export interface Propertie {
  name: string,
  identifier: string,
  description: string,
  data_type: DataType,
  value: string,
  type: number
}

export interface DataType {
  specs: Object,
  type: string
}

export interface ErrorResult {
  errorCode: string,
  err: Err
}

export interface Err {
  status: number,
  errorMsg: string,
  data: string
}

class ZWSFunctionTest extends ZWSBaseModule {

  /**
   * 登录信息
   * @param param0 登录信息
   * @returns 
   */
  static getLogin(user: { url: string, username: string, password: string }) {
    return this.fetch({
      url: '/auth/login',
      method: 'post',
      data: user
    })
  }

  /**
   * 用户登出
   * @returns 
   */
  static getLogout() {
    return this.fetch({
      url: '/auth/logout',
      method: 'get'
    })
  }

  /**
   * 获取设备列表
   * @returns 
   */
  static getDevices(skip?: string, limit?: string, filter?: any) {
    return this.fetch({
      url: '/devices',
      method: 'get',
      params: { skip, limit, filter }
    })
  }

  /**
   * 获取设备信息
   * @param devId 设备Id
   * @returns 
   */
  static getDevice(devId: string) {
    return this.fetch({
      url: '/device',
      method: 'get',
      params: { devId }
    })
  }

  /**
   * 连接设备
   * @param devId 设备id
   * @returns 
   */
  static connect(devId: string) {
    return this.fetch({
      url: '/device/connect',
      method: 'get',
      params: { devId }
    })
  }

  /**
   * 断开设备
   * @param devId 设备id
   * @returns 
   */
  static disconnect(devId: string) {
    return this.fetch({
      url: '/device/disconnect',
      method: 'get',
      params: { devId }
    })
  }

  /**
   * 开始定时上报数据
   * @param devId 设备id
   * @param canFlag can设备标记
   * @returns 
   */
  static startTimer(devId: string, canFlag?: string) {
    return this.fetch({
      url: '/timer/start',
      method: 'get',
      params: { devId, canFlag }
    })
  }

  /**
   * 手动上报数据
   * @param devId 设备id
   * @param canFlag can设备标记
   * @returns 
   */
  static handData(devId: string, canFlag?: string) {
    return this.fetch({
      url: '/report/hand',
      method: 'put',
      params: { devId, canFlag }
    })
  }

  /**
   * 设置定时上报频率
   * @param devId 设备id
   * @param value 定时频率
   * @returns 
   */
  static updateTimer(devId: string, value: any) {
    return this.fetch({
      url: '/timer/update',
      method: 'put',
      params: { devId },
      data: { ...value }
    })
  }

  /**
   * 停止定时上报数据
   * @param devId 设备id
   * @returns 
   */
  static stopTimer(devId: string) {
    return this.fetch({
      url: '/timer/stop',
      method: 'get',
      params: { devId }
    })
  }

  /**
   * 修改raw
   * @param devId 
   * @param rawSet 
   * @returns 
   */
  static setRaw(devId: string, rawSet: any) {
    return this.fetch({
      url: '/schema/raw',
      method: 'put',
      params: { devId },
      data: rawSet
    })
  }

  /**
   * 修改operation
   * @param devId 
   * @param operations 
   * @returns 
   */
  static setOperation(devId: string, operations: any) {
    return this.fetch({
      url: '/schema/operation',
      method: 'put',
      params: { devId },
      data: operations
    })
  }

  /**
   * 
   * @param devId 
   * @param events 
   * @returns 
   */
  static setEvent(devId: string, events: any) {
    return this.fetch({
      url: '/schema/event',
      method: 'put',
      params: { devId },
      data: events
    })
  }

  /**
   * 
   * @param devId 
   * @param status 
   * @returns 
   */
  static setStatus(devId: string, status: any) {
    return this.fetch({
      url: '/schema/status',
      method: 'put',
      params: { devId },
      data: status
    })
  }

  /**
   * 查询下发的设备指令
   * @param skip 
   * @param limit 
   * @param filter 
   * @returns 
   */
  static getCommandLog(skip?: string, limit?: string, filter?: any) {
    return this.fetch({
      url: '/command_log',
      method: 'get',
      params: { skip, limit, filter }
    })
  }

  /**
   * 查询设备上报的数据
   * @param skip 
   * @param limit 
   * @param filter 
   * @returns 
   */
  static getReportLog(skip?: string, limit?: string, filter?: any) {
    return this.fetch({
      url: '/report_log',
      method: 'get',
      params: { skip, limit, filter }
    })
  }


  /**
   * 获取当前用户信息
   * @returns 
   */
  static getUser() {
    return this.fetch({
      url: '/user',
      method: 'get',
    })
  }

  /**
   * 修改用户日志标记
   * @param userId 用户id
   * @param userInfo 状态信息
   * @returns 
   */
  static setUser(userId: string, userInfo: any) {
    return this.fetch({
      url: '/user',
      method: 'put',
      params: { userId },
      data: userInfo
    })
  }
}

export default ZWSFunctionTest