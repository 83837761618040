export interface ZError {
    errorCode: string               // 错误码
    err: {
        status: number              // 状态码，比如 200、400 等
        errorMsg: string            // 错误信息，说明是哪类错误
        data: {
            timestamp: string       // 错误发生的时间
            status: number          // 状态码，比如 200、400 等
            path: string            // 错误发生的 http 请求路径
            code: string            // 错误码
            error: string           // 错误信息，说明是哪类错误
            message: string         // 错误详细信息，说明具体是什么错误
        }
    }
}

export interface ZErrorInfo {
    errorCode: string                // 错误码
}

const defaultError = {
    errorCode: '',
    err: {
        status: -1,
        errorMsg: '',
        data: {
            timestamp: '',
            status: -1,
            path: '',
            code: '',
            error: '',
            message: ''
        }
    }
}

const errors = {
    // 物模型错误
    "iot-mapping.10002": { errorMsg: "功能块名重复" },
    "iot-mapping.10008": { errorMsg: "实体名重复" },
    "iot-mapping.10009": { errorMsg: "实体正在被使用" },
    "iot-mapping.10003": { errorMsg: "功能块正在被使用" },
    "iot-mapping.10004": { errorMsg: "该设备类型名系统中已存在" },
    "iot-mapping.10029": { errorMsg: "DBC文件已关联物模型" },
    "iot-mapping.999990": { errorMsg: "关联过多功能块" },
    // candtu错误
    "can-dtu.10005": { errorMsg: "故障码文件不存在" },
    // 链接错误
    "connection.10000": { errorMsg: "链接名已存在" },
    // 设备错误
    "iot-things.999991": { errorMsg: "统计设备类型下设备数失败" },
    // candtu
    "can-dtu-10011": { errorMsg: "故障码文件正在被使用" },
    "can-dtu-10012": { errorMsg: "名称已被使用" }
}

export function generateError(err: ZErrorInfo) {
    let error = JSON.parse(JSON.stringify(defaultError))
    error.errorCode = err.errorCode
    return error
}

export function getErrorMessage(err: ZError) {
    switch (err?.errorCode) {
        /* case "iot-mapping.10002": {
            return "功能块名重复"
        }
        case "iot-mapping.10008": {
            return "实体名重复"
        }
        case "iot-mapping.10009": {
            return "实体正在被使用"
        }
        case "iot-mapping.10003": {
            return "功能块正在被使用"
        }
        case "iot-mapping.10004": {
            return "设备类型名已存在"
        } */
        case "can-dtu.10005": {
            return "故障码文件不存在"
        }
        case "connection.10000": {
            return "链接名已存在"
        }
        /* case "iot-mapping.10029": {
            return "DBC文件已关联物模型"
        }
        case "iot-mapping.999990": {
            return "关联过多功能块"
        } */
        case "iot-things.999991": {
            return "统计设备类型下设备数失败"
        }
        case "can-dtu-10011": {
            return "故障码文件正在被使用"
        }
        case "can-dtu-10012": {
            return "名称已被使用"
        }
        /* case "iot-mapping.10034": {
            return "DBC文件已经被使用,不可以修改或删除"
        } */
        default:
            {
                if (err?.err?.data?.error) {
                    return err.err.data.error
                } else {
                    return "error unknown"
                }
            }
    }
}

export function isErrorNotExists(err: ZError) {
    switch (err.errorCode) {
        case "iot-mapping.10022": {
            return true
        }
        default: {
            return false
        }
    }
}