/**
 * 设备管理
 */
export default {
  currentDevice: 'Current device',
  devStatus: {
    title: 'Device Status',
    basic: 'Basic Information',
    edit: 'edit',
    input: 'input',
    collect: 'collect',
    notCollect: 'not collect',
    devClass: 'Equipment Class',
    notNull: 'not be null',
    startRec: 'start recorder',
    syncTime: 'synchronization time',
    clrDev: 'Clear the SD card record data',
    conDevice: 'Conventional Device',
    gateDevice: 'Gateway equipment',
    stopRec: 'Stop recording',
    sendOrder: 'Sending device commands...',
    responseOvertime: 'Device response times out, please try again later!',
    sendSuccess: 'The device command was successfully sent. Procedure',
    sendFailed: 'Failed to send the device command. Procedure',
    leisure: 'leisure',
    busy: 'Busy, device refresh firmware',
    invalid: 'invalid',
    swiSuccess: 'Swipe success',
    swiFailed: 'Brush failure',
    getBasicFailed: 'Failed to obtain basic device information',
    editSuccess: 'modify successfully',
    editFailed: 'fail to modify',
    channelError: 'Failed to get channel signals'
  },
  command: {
    title: 'Command Sending',
    send: 'Send Now',
    add: 'Add to List',
    sendList: 'List Send',
    idInvalid: 'Please input a valid frame ID. Standard frame ID 0x0 ~ 0x7FF, extended frame ID 0x0 ~ 0x1FFFFFFF',
    upToNum: 'Up to 100 pieces of data can be added to the list',
    tipForEven: 'The data content must be an even number of characters',
    tipForCanLen: 'Please input data with valid length, CAN range from 0~8',
    tipForCanfdLen: 'Please input data with valid length, CANFD range from 0~8， 12， 16， 20， 24， 32， 48， 64'
  },
  devLog: {
    title: 'Device Log',
    selectTimeTip: 'Please select a time period',
    online: 'online',
    offline: 'offline'
  },
  firmwareUpgrade: {
    selectFile: 'Select File',
    obtainSoftwareGroupFailed: 'Failed to obtain the software group',
    descCannotBeEmpty: 'The description cannot be empty',
    uploadFileCannotBeEmpty: 'The uploaded file cannot be empty',
    firmwareVersionCannotBeEmpty: 'The firmware version cannot be empty',
    pleaseInputXX: 'Please enter XX.XX.XX (xx is 1 to 2 digits)',
    pleaseInput2256: 'Please enter 2-256',
    pleaseUploadDeviceFirmware: 'Please upload device firmware',
    uploadFirmware: 'Upload Firmware',
    pleaseInputFirmwareVersion: 'Please enter the firmware version',
    addTimeNoticUpgradeFail: 'Added timing notice firmware upgrade failed',
    addTimeNoticUpgradeSuccess: 'Added timing notice firmware upgrade success',
    waitDeviceUpdate: 'After the upgrade succeeds, wait until the automatic upgrade succeeds',
    deleteFirmwareFailed: 'Failed to delete firmware',
    deleteFirmwareSuccess: 'Firmware deleted successfully',
    modifyStatusSuccess: 'Successfully modified status',
    selfDevicefirmware: 'Self-device Firmware',
    externaldevicefirmware: 'External Device Firmware',
    firmwareType: 'Firmware Type',
    firmwareVersion: 'Firmware Version',
    sureToDeleteFirmware: 'Are you sure to delete the firmware whose firmware version is {version}?',
    deleteFirmware: 'Delete Firmware',
    informFirmwareUpgrade: '{version} inform firmware upgrade {time}',
    subDeviceType: 'Subdevice(type:{type}) of {gateway}',
    gatewayGroup: 'Gateway Group',
    upgradeTimeCannotBeEmpty: 'The upgrade time cannot be empty',
    upgradeObjCannotBeEmpty: 'The upgrade object cannot be empty',
    additionalInformation: 'Additional Information',
    pleaseSelectWhetherDifferentialUpgrade: 'Please select whether to upgrade differentially',
    differentialUpgrade: 'Differential Upgrade',
    pleaseSelectDate: 'Please select a date',
    upgradeTime: 'Upgrade Time',
    scheduledUpgrade: 'Scheduled Upgrade',
    upgradeNow: 'Upgrade Now',
    pleaseSelectUpgradeMode: 'Please select an upgrade mode',
    upgradeMode: 'Upgrade Mode',
    pleaseSelectUpgradeObject: 'Please select upgrade object',
    upgradeObject: 'Upgrade Object',
    typeGroup: 'Type Group',
    softwareGroup: 'Software Group',
    singleDevice: 'Single Device',
    upgradeRange: 'Upgrade Range',
    upgradeVersion: 'Upgrade Version',
    upgradeFirmware: 'Upgrade Firmware',
    title: 'Firmware Upgrade',
    standardFirmware: 'Standard Firmware',
    customFirmware: 'Custom Firmware',
    addFirmware: 'Add Firmware',
    addFirmwareSuccess: 'Adding firmware succeeded'
  },
  devConfig: {
    condition: 'Condition',
    frameIdCannotBeEmpty: 'The frame ID cannot be empty',
    acceptCodeCannotBeEmpty: 'The acceptance code cannot be empty',
    max8BitHex: 'Please enter a maximum of 8 hexadecimal digits',
    maskCodeCannotBeEmpty: 'The mask code cannot be empty',
    extFrameIdOutRange: 'Input extended frame ID out of range, normal range [0-0x1fffffff]',
    standardFrameIdOutRange: 'Input standard frame ID out of range, normal range [0-0x7ff]',
    customData: 'Custom data',
    networkParamConfig: 'Network parameter',
    flipRecordConfig: 'Flip record',
    saveDevConfigFail: 'The device configuration failed to be saved',
    saveDevConfigSuccess: 'The device configuration is saved successfully',
    devTimeout: 'The device timed out. Please try again later',
    deliveredDevConfigError: 'The delivered device configuration item is incorrect. Please enter it again',
    exportDevConfigFail: 'Failed to export device configurations',
    exportDevConfigSuccess: 'The device configuration is successfully exported',
    realtimeDataConfig: 'Real-time data',
    netTransmissConfig: 'Network transmission',
    netConnectConfig: 'Network connection',
    smsAlarmConfig: 'SMS alarm',
    gpsRecordConfig: 'GPS recording',
    storageSpaceConfiguration: 'Storage space',
    canRecordModeConfig: 'CAN record mode',
    devRecordConfig: 'Device record',
    diChannelConfig: 'DI channel',
    doChannelConfig: 'DO channel',
    linChannelConfig: 'LIN channel',
    canChannelConfig: 'CAN channel',
    radiusRange: 'Radius range [50,10000]',
    pleaseInputRadius: 'Please enter radius',
    latitudeRange: 'Latitude range [-90,90]',
    pleaseInputLatitude: 'Please enter latitude',
    longitudeRange: 'Longitude range [-180,180]',
    pleaseInputLongitude: 'Please enter the longitude',
    outsideSpecifyArea: 'Outside',
    withinSpecifyArea: 'Inside',
    searchLoc: 'Search Location',
    centerPointRadius: 'Radius (m)',
    gpsLatitude: 'Latitude(°)',
    gpsLongitude: 'Longitude (°)',
    gpsAlarmRange: 'Alarm range',
    pleaseInputEndFrameId: 'Please input end frame ID',
    endFrameId: 'End frame ID',
    pleaseInputStartFrameId: 'Please input start frame ID',
    startFrameId: 'Start frame ID',
    specifiedId: 'Specified ID',
    filterComputer: 'Filter Computer',
    importFileContentEmpty: 'The content of the imported file is empty',
    pleaseImportCorrectFile: 'Please import the correct file content, JSON conversion error',
    devConfigImportSuccess: 'The device configuration is imported successfully',
    uploadFileSizeExceed: 'The size of the uploaded file cannot exceed 10MB',
    uploadFileFormatError: 'Format error, only JSON file format is supported. Please upload again',
    dontModifyExportFileContent: 'Do not modify the content of the exported file without permission, as it may cause errors during import',
    periodRecordIdConfig: 'Periodic record ID configuration',
    saveCustomData: 'Save custom data',
    saveLatstData: 'Save the latest data',
    pleaseInputHexIn16Char: 'Please enter a hexadecimal number within sixteen characters',
    pleaseInputCustomData: 'Please enter custom data',
    timerPeriodRange: 'Time period range for timer [10, 86400000]',
    pleaseInputTimerPeriod: 'Please enter the time period of the timer',
    conditonNotSupportEdit: 'This condition does not support editing',
    fallingEdgeTrigger: 'Falling edge trigger',
    riseEdgeTrigger: 'Rising edge trigger',
    idDupicated: 'The configured ID is duplicated with an existing configuration ID. Please add a new one',
    pretriggerIDConfig: 'Pre-trigger ID configuration',
    diTrigger: 'DI trigger',
    keyTrigger: 'Key trigger',
    errFrameTrigger: 'Error frame trigger',
    idTrigger: 'ID trigger',
    recordTimeRange: 'Record time range [1, 8640000]',
    pretriggerRecordFrameCountRange: 'Pre-trigger recording frame count range [10, 100000]',
    pleaseInputPretriggerFrameCount: 'Please enter pre-trigger recording frame Count',
    stopRecordEndId: 'Stop recording end ID',
    stopRecordingStartId: 'Stop recording start ID',
    stopRecordCondition: 'Stop recording condition',
    stopRecordFrameType: 'Stop recording frame type',
    stopRecordChannel: 'Stop recording channel',
    stopRecordingTrigger: 'Stop recording trigger',
    startRecordEndID: 'Start recording end ID',
    startRecordStartID: 'Start recording start ID',
    startRecordCondition: 'Start recording condition',
    startRecordFrameType: 'Start recording frame type',
    startRecordChannel: 'Start recording channel',
    startRecordTrigger: 'Start recording trigger',
    noRecording: 'No recording',
    scheduledRecording: 'Scheduled recording',
    pretriggerRecording: 'Pre-trigger recording',
    conditionRecording: 'Condition recording',
    longTermRecording: 'Long-term recording',
    triggerRecordEndId: 'Trigger record end ID',
    triggerRecordStartId: 'Trigger record start ID',
    triggerRecordFrameType: 'Trigger record frame type',
    triggerRecordCondition: 'Trigger recording condition',
    triggerMode: 'Trigger mode',
    triggerRecordChannel: 'Trigger recording channel',
    diTriggerConfig: 'DI trigger configuration',
    recordingChannel: 'Recording Channel',
    errFrameTriggerConfig: 'Error frame trigger configuration',
    noDataInScheduledTime: 'No data in time',
    timingTimePeriod: 'Timing period (ms)',
    recordingDuration: 'Recording duration (ms)',
    preTriggerRecordFrames: 'Pre-trigger recording frames',
    recordMode: 'Record mode',
    pleaseInputCorrectMobileNumber: 'Please enter the correct mobile number',
    pleaseInputAlarmPhoneNumber: 'Please enter the alarm phone number',
    gpsAreaWarning: 'GPS area warning',
    sdCardException: 'SD card exception',
    canBusError: 'CAN bus error',
    triggerAlarmFunction: 'Trigger alarm function',
    alarmPhoneNumber: 'Alarm phone number',
    smsChannel: 'SMS channel ',
    recordFileSizeRange: 'Record file size range [1,512]',
    pleaseInputRecordFileSize: 'Please enter the record file size',
    merge: 'Merge',
    noMerge: 'No merge',
    record: 'Record',
    doNotRecord: 'Do not record',
    stopRecording: 'Stop recording',
    loopRecord: 'Loop record',
    canChannelDataMerge: 'CAN data merging',
    whetherLogCANErr: 'Log CAN errors',
    recordFileSize: 'Record size (MB)',
    storageSpaceFullHandle: 'Storage full',
    enableTransparentTransmission: ' Enable transmission',
    storageTotalRange: 'Storage space total allocation range [0,100]',
    clickAdjustTo100per: 'Click to scale it to 100%',
    curTotalAlloc: 'Current total allocation',
    pleaseInputCorrectAddress: 'Please enter the correct address',
    domainNameServer: 'Server',
    defaultGateway: 'Gateway',
    subnetMask: 'Subnet Mask',
    ipAddress: 'IP Address',
    automaticIPAcquisition: 'Automatic IP acquisition',
    manuallySetIP: 'Manually set IP',
    enableLTE: 'Enable LTE',
    pleaseInputTimeout: 'Please enter a timeout time between 500 and 60000',
    pleaseInputTransCompressTimeout: 'Please enter the timeout time for transmission compression',
    pleaseInputResumableCacheSize: 'Please enter a resumable cache between 1 and 100',
    pleaseInputOfflineResumableCacheSize: 'Please enter the size of the offline resumable cache',
    transmissionCompressionTimeout: 'Transmission compression timeout',
    enableTransmissionCompression: 'Enable transmission compression',
    resumableUploadCacheSize: 'Disconnection cache',
    enableContinuousTrans: 'Enable continuous transmission',
    protocolNumber: 'Protocol number: 001',
    unencryption: 'Unencrypted',
    times: 'times',
    nonContinuousTransmission: 'Non-continuous transmission',
    enableNetworkDebugLog: 'Enable network debugging logs',
    enableAbsoluteTimestamp: 'Enable data carrying device ID and absolute timestamp',
    encryptionConfig: 'Encryption Configuration',
    successivePassesNumber: 'Continuation',
    startTimeLessThanEndTime: 'The start time must be smaller than the end time',
    pleaseInputCorrectExtFrameEndId: 'Please enter the correct extension frame end ID range [0-0x1FFFFFFF]',
    pleaseInputCorrectStandardFrameEndId: 'Please enter the correct standard frame end ID range [0-0x7FF]',
    pleaseInputEndId: 'Please enter the end ID',
    pleaseInputCorrectExtFrameStartId: 'Please enter the correct extension frame start ID range [0-0x1FFFFFFF]',
    pleaseInputCorrectStandardFrameStartId: 'Please enter the correct standard frame start ID range [0-0x7FF]',
    pleaseInputCorrectHexNum: 'Please enter the correct hexadecimal number',
    pleaseInputStartId: 'Please enter the start ID',
    timePeriodFilter: 'Time Period Filter',
    idFilter: 'ID Filter',
    extendedFrame: 'Extended Frame',
    standardFrame: 'Standard Frame',
    outsideRange: 'Outside Range',
    withinRange: 'Within Range',
    greaterOrEqual: 'Greater Or Equal',
    greater: 'Greater',
    lessOrEqual: 'Less Or Equal',
    less: 'Less',
    unequal: 'Unequal',
    equal: 'Equal',
    outsideTimeRange: 'Outside the time range',
    withinTimeRange: 'Within the time range',
    filterEndID: 'Filter End ID',
    filterStartID: 'Filter Start ID',
    filterFrameType: 'Filter Frame Type',
    filterCondition: 'Filter Condition',
    filterChannel: 'Filter Channel',
    filterType: 'Filter Type',
    triggerCondition: 'Trigger Condition',
    addFilterCondition: 'Add Filter Condition',
    uploadDataFiltering: 'Upload Data Filtering',
    remotePortRange: 'Remote port range [1,65535]',
    pleaseInputRemotePort: 'Please enter a remote port',
    pleaseInputCorrectAddr: 'Please enter the correct address',
    localPortRange: 'Local port range [0,65535]',
    pleaseInputLocalPort: 'Please enter the local port',
    remotePort: 'Remote Port',
    remoteAddress: 'Remote Address',
    localPort: 'Local Port',
    connect: 'Connect',
    dataLength: 'Data Length',
    recordPeriodRange: 'The range of the recording period is [1,1800]',
    pleaseInputRecordPeriod: 'Please enter a recording period',
    bigDipper: 'Big Dipper',
    autoSelect: 'Auto Select',
    recordCycle: 'Record Cycle (s)',
    positionSystemType: 'Location type',
    max64Filters: 'You can only set a maximum of 64 filter entries, please delete some entries and try again',
    enableRolloverLog: 'Enable Rollover Log',
    close: 'Close',
    break: 'Break',
    gpsArea: 'GPS Area',
    memoryEtatusException: 'Memory Status Exception',
    busError: 'Bus Error',
    recordFull: 'Record Full',
    doAlarmOutputFunction: 'DO Alarm Output Function',
    doAlarmOutputValue: 'DO Alarm Output Value',
    recordDIInput: 'Record DI Input',
    simulateKeyboard: 'Simulate Keyboard',
    recordGap: 'Record Gap',
    diFunction: 'DI Function',
    standardFrameGroupId: 'Standard Frame Group ID',
    standardFrameId: 'Standard Frame ID',
    extendedFrameGroupId: 'Extended Frame Group ID',
    extendedFrameId: 'Extended Frame ID',
    endId: 'End ID',
    startId: 'Start ID',
    filterFormat: 'Filter Format',
    blackList: 'Black List',
    whiteList: 'White List',
    editFilterId: 'Edit Filter ID',
    addFilterId: 'Add Filter ID',
    filterMode: 'Filter Mode',
    enableFiltering: 'Enable Filtering',
    max8Filters: 'You can only set a maximum of 8 filter entries, please delete some entries and try again',
    customBaudRate: 'Custom Baud Rate',
    standardFrameIdFreeSetting: 'Standard frame ID REM free setting',
    extendedFrameIdFreeSetting: 'Extended frame ID REM free setting',
    extendedFrameIdId: 'Extended frame ID ID',
    standardFrameIdId: 'Standard frame ID ID',
    acceptAllData2: 'Accept all data',
    acceptAllData: 'Accept All Data',
    maskCode: 'Mask Code (HEX)',
    acceptanceCode: 'Acceptance Code (HEX)',
    onlyListen: 'Only Listen',
    normal: 'Normal',
    addFilterRule: 'Add Filter Rule',
    enableTerminalResistance: 'Enable Terminal Resistance',
    baudRate: 'Baud Rate',
    canWorkMode: 'CAN working mode',
    channelEnable: 'Channel Enable',
    pleaseSelectBaudRate: 'Please select baud rate',
    error: 'Error',
    actualValue: 'Actual Value',
    samplePoint: 'Sample Point',
    complyWithTSEG2: 'Complies with TSEG2 >= SJW rules',
    tertiarySampling: 'Tertiary Sampling',
    syncJumpWidth: 'Sync Jump Width',
    mhzValue: 'MHz Value',
    canClock: 'CAN Clock',
    calculate: 'Calculate',
    expectedBaudRate: 'Expected Baud Rate',
    baudrateCalculator: 'Baud Rate Calculator',
    title: 'Device Config'
  }
}
