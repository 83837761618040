/**
 * 点击波纹动画  v-clickWaterAnimation
 * */
import '../css/clickWaterAnimation.css';
export const clickWaterAnimation = {
    mounted(el: HTMLElement, binding: any) {
        el.addEventListener('click', (e: any) => {
            // 这里可以在 use / install 的时候 动态传参
            // const customOpts = Object.assign(options, binding.value);
            const customOpts = binding.value;
            const opts = Object.assign({
                    ele: el, // 波纹作用元素
                    type: 'hit', // hit点击位置扩散 / center中心点扩展
                    color: 'rgba(0, 0, 0, 0.15)' // 波纹颜色
                }, customOpts),
                targetEle = opts.ele;
            // 绑定的元素
            if (targetEle) {
                targetEle.style.position = 'relative';
                targetEle.style.overflow = 'hidden';
                const targetEleRect = targetEle.getBoundingClientRect();
                let waterRipple = targetEle.querySelector('.water-ripple');
                if (!waterRipple) {
                    // 波纹不存才通过 span 创建，span 的宽高就是绑定 DOM 的宽高

                    // 1. 创建 span
                    waterRipple = document.createElement('span');
                    // 2. 给 span 类名
                    waterRipple.className = 'water-ripple';
                    // 3. 给 span 宽高
                    waterRipple.style.height = waterRipple.style.width = Math.max(targetEleRect.width, targetEleRect.height) + 'px';
                    // 4. span 插入绑定的 DOM
                    targetEle.appendChild(waterRipple);
                } else {
                    // 存在直接给类名就行
                    waterRipple.className = 'water-ripple';
                }
                // 波纹动画起始位置，默认值 hit，点击哪里从哪里开始
                switch (opts.type) {
                    // center 中心开始
                    case 'center':
                        waterRipple.style.top = (targetEleRect.height / 2 - waterRipple.offsetHeight / 2) + 'px';
                        waterRipple.style.left = (targetEleRect.width / 2 - waterRipple.offsetWidth / 2) + 'px';
                        break;
                    default:
                        // 默认点哪里从哪里开始扩散
                        waterRipple.style.top = (e.pageY - targetEleRect.top - waterRipple.offsetHeight / 2 - document.body.scrollTop) + 'px';
                        waterRipple.style.left = (e.pageX - targetEleRect.left - waterRipple.offsetWidth / 2 - document.body.scrollLeft) + 'px';
                }
                waterRipple.style.backgroundColor = opts.color; // 波纹颜色
                waterRipple.className = 'water-ripple z-active';
                return false;
            }
        }, false);
    }
};