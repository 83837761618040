import i18n from '@/language/index'
import { computed, ref } from 'vue'

const { t } = i18n.global
const name = ref(t('header.system'))

export const projectConfig: any = {
  projectName: computed({
    get() {
      // 暂时不读系统返回
      return name.value || t('header.system')
    },
    set(newVal) {
      name.value = newVal
    }
  }),
  // projectName: computed(() => t('header.system')),
  // projectName: t('header.system'),
  // projectName: 'CANDTU云数据平台',
  projectThemeColor: '',
  asideList: [
    {
      title: '设备管理',
      index: '/Container/Home/HomePage'
    },
    {
      title: '设备数据',
      index: '/Container/Device/DeviceList'
    },
    {
      title: '曲线分析',
      index: '/Container/Data/RealTimeData'
    },
    {
      title: '触发管理',
      index: '/Container/User/UserCenter'
    },
    {
      title: 'GPS轨迹',
      index: '/Container/Data/HistoricalData'
    }
  ]
}
