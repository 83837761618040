<template>
  <div class="zws-header">
    <span class="zws-header-logo" @click="toHomePageClicked">
      <el-image class="zws-header-left-logo" :src="logoSrc" fit="scale-down"></el-image>
    </span>
    <div class="zws-header-content">
      <span class="zws-header-left" @click="toHomePageClicked">
        <!-- <el-image class="zws-header-left-logo" :src="logoSrc" fit="scale-down"></el-image> -->
        <!-- {{ systemInfo }} -->
        <span class="zws-header-text">
          <!-- {{ systemInfo.systemName ? systemInfo.systemName : defaultSystemInfo.systemName }} -->
          {{ systemName }}
        </span>
      </span>
      <div class="zws-header-right">
        <a
          v-for="(item, index) in homeMenuList"
          :class="['a-menu-item', curMenuIndex == item.props ? 'selected' : '']"
          :key="String(index)"
          @click="selectMenuClicked(item.props)"
          >{{ item.title }}</a
        >
        <span class="capacity-application-text" @click="onToStudio" v-monitoring="toStudioMonitoring">{{
          $t('menu.lowCodePlatform')
        }}</span>
        <span class="capacity-application-text" @click="onCapacityApplicationClicked" v-monitoring="capacityApplication">{{
          $t('menu.capacityRequest')
        }}</span>
        <span class="work-text" @click="onWorkListClicked" v-monitoring="capacityApplication">{{ $t('menu.workOrder') }}</span>
        <el-dropdown @command="handleScreenCommand">
          <span class="dropdown-cursor">
            <span class="text">{{ $t('header.screen') }}</span>
            <el-icon><CaretBottom /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="monitor">{{ $t('header.monitorScreen') }}</el-dropdown-item>
              <el-dropdown-item command="operation">{{ $t('header.operationScreen') }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown @command="handleLanguageCommand">
          <span class="dropdown-cursor">
            <span class="text">{{ language }}</span>
            <el-icon><CaretBottom /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="zh">中文</el-dropdown-item>
              <el-dropdown-item command="en">English</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- <UserMessageIconArea></UserMessageIconArea> -->
        <ZUserMessageIconArea></ZUserMessageIconArea>
        <i
          class="iconfont zlg-icon-pie iconfont-pie-style"
          :style="{ color: isOver ? 'red' : '#fff' }"
          @click="cloudDiskCapacityClicked"
          v-monitoring="capacityMonitoring"
        ></i>
        <!-- <span class="capacity-application-text" @click="onCapacityApplicationClicked">容量申请</span> -->
        <i class="iconfont zlg-icon-system-help iconfont-help-style" @click="helpClicked" v-monitoring="helpMonitoring"></i>
      </div>
    </div>
    <div class="zws-user-dropdown">
      <el-dropdown @command="handleCommand">
        <span class="zws-header-right-user">
          <el-image class="zws-header-right-avatar" :src="userInfo.avatar ? userInfo.avatar : avatarImgUrl"> </el-image>
          <span class="zws-header-right-username">{{ userInfo.name }}</span>
          <i class="el-icon-arrow-down zws-header-right-icon-setting"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu style="text-align: left">
            <el-dropdown-item v-for="(item, index) in dropdownCommands" :key="index" :command="item.command" v-monitoring="item.monitoring">
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <el-dialog
      center
      v-model="dialogShow"
      width="1000px"
      :title="$t('header.statisticsTitle')"
      top="5vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="capacity-info-wrapper" v-loading="pieLoading" element-loading-background="#fff">
        <div class="capacity-info-echarts">
          <div ref="echartRef" id="cloudDiskCapacityEchartsId" style="width: 350px; height: 350px"></div>
          <div class="capacity-info-echarts-text">
            <span class="capacity-info-echarts-text-size"
              >{{ capacityInfo.unusedPercent }}<span class="capacity-info-echarts-text-symbol">%</span></span
            >
            <span class="capacity-info-echarts-text-describe">{{ $t('header.remainingCapacity') }}</span>
          </div>
        </div>
        <div class="capacity-info-explain">
          <span>
            <span class="title">{{ $t('header.total') }}</span>
            <span class="capacity-info-explain-text-content">
              {{ capacityInfo.total }}
            </span>
            MB
            <span class="capacity-info-explain-application" @click="onCapacityApplicationClicked">{{ $t('menu.capacityRequest') }}</span>
          </span>
          <span style="margin-top: 20px">
            <span class="title">{{ $t('header.used') }}</span>
            <span class="capacity-info-explain-text-content">{{ capacityInfo.used }}</span>
            MB</span
          >
          <span style="margin-top: 20px">
            <span class="title">{{ $t('header.remain') }}</span>
            <span class="capacity-info-explain-text-content">{{ capacityInfo.unused }}</span
            >MB</span
          >
        </div>
      </div>
      <div class="capacity-table-container">
        <ZCapacitystatisticsTableArea
          v-if="dialogShow"
          :userPackage="userPackage"
          :monitoringObject="quickDeleteMonitoring"
          @onToUserCenter="handleToUserCenter"
          @onCleanDevData="handleCleanDevData"
        >
        </ZCapacitystatisticsTableArea>
      </div>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import logoHeadImgUrl from '../ZImageArea/home/logo_head.png'
import avatarImgUrl from '../ZImageArea/user/ic_avatar.png'
import { defineComponent, getCurrentInstance, reactive, ref, nextTick, onUnmounted, computed, watchEffect } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { setLanguage } from 'ui/hooks/useLocale'
import * as echarts from 'echarts'
import { projectConfig } from '@/assets/ts/project_config'
// import UserMessageIconArea from '@/components/Common/UserMessageIconArea/UserMessageIconArea.vue'
import ZUserMessageIconArea from 'ui/area/ZUserMessageIconArea/ZUserMessageIconArea.vue'
import ZAppUrlTool from 'tool/ZAppUrlTool'
import { languageMap } from 'ts/Common/map'
import {
  updateSingleCustomization,
  getTenantDatamgmtUsageLatest,
  getSinglePackageTenant,
  PackageInfoCom,
  FetchError,
  getTenantUsagePercentage
} from '@zws/axios-fetch'
import { setLibLocale } from '@/assets/ts/Common/useLocaleHelper'
import { CaretBottom } from '@element-plus/icons-vue'
import { locale } from 'element-plus'
// import CapacitystatisticsTable from '../../../src/components/Common/CapacitystatisticsTable.vue'
export default defineComponent({
  components: {
    // CapacitystatisticsTable
    // UserMessageIconArea,
    ZUserMessageIconArea,
    CaretBottom
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { t, locale } = useI18n()
    const { proxy }: any = getCurrentInstance()

    let userInfo: any = store.getters.userInfo
    let tenantPermissionInfo = store.getters.tenantPermissionInfo
    // let curMenuIndex: any = ref('')
    // curMenuIndex.value = store.getters.headerMenuActiveIndex
    const userPackage = computed(() => store.getters.getUserPackage)
    const logoSrc = computed(() => {
      const globalPermissionInfo = store.getters.globalPermissionInfo
      return systemInfo.value.systemLogo || globalPermissionInfo?.homepageLogo || logoHeadImgUrl
    })
    const systemName = computed(() => {
      const globalPermissionInfo = store.getters.globalPermissionInfo
      return systemInfo.value.systemName || globalPermissionInfo?.cloudname || t('header.system')
    })

    const homeMenuList = computed(() => [
      {
        title: t('menu.homePage'),
        props: 'homePage'
      },
      {
        title: t('menu.dbcManagement'),
        props: 'dbcManager'
      },
      {
        title: t('menu.ecuManagement'),
        props: 'ecuManager'
      },
      {
        title: t('menu.faultCodeManagement'),
        props: 'breakdownCodeManager'
      }
    ])
    let defaultSystemInfo: any = reactive({
      systemName: '',
      systemTheme: 'light',
      systemLogo: ''
    })
    defaultSystemInfo.systemName = projectConfig.projectName
    // let systemInfo: any = ref({
    //   systemLogo: ''
    // })
    const systemInfo = computed(() => {
      const tenantCustomizationInfo = store.getters.tenantCustomizationInfo
      // 设置标签页icon
      // if (tenantCustomizationInfo?.systemLogo) {
      // const link = document.createElement('link')
      // link.rel = 'icon'
      // link.type = 'image/png'
      // link.href = tenantCustomizationInfo.systemLogo

      // // 获取旧的link元素
      // const oldLink = document.querySelector('link[rel="icon"]') || document.querySelector('link[rel="shortcut icon"]')
      // if (oldLink) oldLink.parentNode?.replaceChild(link, oldLink)
      // else document.head.appendChild(link)
      // console.log(document, 'document')
      // }
      return tenantCustomizationInfo
    })

    const dropdownCommands = computed(() => [
      {
        label: t('menu.usercenter'),
        command: 'userCenter',
        monitoring: {
          platform: 'ZWS-Candtu',
          module: '首页',
          action: '个人中心'
        }
      },
      {
        label: t('menu.subuserManagement'),
        command: 'subuserManagement'
      },
      {
        label: t('menu.handleLog'),
        command: 'handleLog',
        monitoring: {
          platform: 'ZWS-Candtu',
          module: '首页',
          action: '操作日志'
        }
      },
      {
        label: t('menu.updateLog'),
        command: 'systemConfig',
        monitoring: {
          platform: 'ZWS-Candtu',
          module: '首页',
          action: '系统配置'
        }
      },
      {
        label: t('menu.logout'),
        command: 'logout',
        monitoring: {
          platform: 'ZWS-Candtu',
          module: '首页',
          action: '退出登录'
        }
      }
    ])

    // 如果是子用户
    if (!tenantPermissionInfo.system_manager && !tenantPermissionInfo.tenant_manager) {
    }

    const curMenuIndex = computed(() => {
      const { path } = route
      let _curMenu = ''
      switch (path) {
        case '/Container/HomePage':
          _curMenu = 'homePage'
          break
        case '/Container/DBCManager':
          _curMenu = 'dbcManager'
          break
        case '/Container/ECUManager':
          _curMenu = 'ecuManager'
          break
        case '/Container/BreakdownCodeManager':
          _curMenu = 'breakdownCodeManager'
          break
      }
      return _curMenu
    })
    function selectMenuClicked(props: string) {
      // curMenuIndex.value = props
      switch (props) {
        case 'homePage':
          router.push('/Container/HomePage')
          store.commit('headerMenuActiveIndex', 'homePage')
          break
        case 'dbcManager':
          router.push('/Container/DBCManager')
          store.commit('headerMenuActiveIndex', 'dbcManager')
          break
        case 'ecuManager':
          router.push('/Container/ECUManager')
          store.commit('headerMenuActiveIndex', 'ecuManager')
          break
        case 'breakdownCodeManager':
          router.push('/Container/BreakdownCodeManager')
          store.commit('headerMenuActiveIndex', 'breakdownCodeManager')
          break
        default:
          break
      }
    }
    let capacityInfo: any = reactive({
      total: 1024,
      used: 0,
      unused: 0,
      unusedPercent: 0
    })
    const pieLoading = ref<boolean>(false)
    function getCapacityInfo() {
      pieLoading.value = true
      // window.ZWSApi.ZWSDataMgmt.tenantUsage_getTenantDatamgmtUsageLatest({
      getTenantDatamgmtUsageLatest({
        tenant_id: userInfo.tenant_id
      })
        .then((usage: any) => {
          console.log(usage, 'usage')

          capacityInfo.total = userPackage.value.content.database_storage_space * 1024
          // capacityInfo.used = (1000000000 / 1024 / 1024).toFixed(0)
          capacityInfo.used = (usage.total_disk_usage / 1024 / 1024).toFixed(0)
          capacityInfo.unused = capacityInfo.total - capacityInfo.used
          // 如果未使用的为0或者负数，默认是0
          if (capacityInfo.unused <= 0) {
            capacityInfo.unused = 0
            capacityInfo.unusedPercent = 0
          }
          capacityInfo.unusedPercent = ((capacityInfo.unused / capacityInfo.total) * 100).toFixed(2)
          let capacity = [
            { name: t('header.used'), value: capacityInfo.used },
            { name: t('header.remain'), value: capacityInfo.unused }
          ]
          option.series[0].data = capacity
          myChart?.setOption(option)
          pieLoading.value = false
        })
        .catch((err: any) => {
          myChart?.setOption(option)
          pieLoading.value = false
        })
    }
    function toHomePageClicked() {
      // curMenuIndex.value = 'homePage'
      store.commit('headerMenuActiveIndex', 'homePage')
      router.push('/Container/HomePage')
    }
    const handleCommand = (command: string) => {
      store.commit('headerMenuActiveIndex', '')
      switch (command) {
        case 'userCenter':
          // window.open(
          //     `https://zws.zlgcloud.com/billing/index.html#/?ujwt=${localStorage.getItem('ujwt')}&token=${localStorage.getItem(
          //         'token'
          //     )}`,
          //     '_blank'
          // )
          ZAppUrlTool.getUrlByKey('billing', '/can').then((res: string) => {
            // console.log(`${res}#/?ujwt=${localStorage.getItem('ujwt')}&token=${localStorage.getItem(
            //     'token'
            // )}&loginUrl=${window.location.origin + window.location.pathname}#/Login`, 'url');
            const _url = `${res}#/?ujwt=${localStorage.getItem('ujwt')}&token=${localStorage.getItem(
              'token'
            )}&loginUrl=${encodeURIComponent(window.location.origin + window.location.pathname)}#/Login&belongPlatform=candtu`

            window.open(_url, '_blank')
          })
          break
        case 'subuserManagement':
          router.push('/Container/UserManager/SubuserManagement')
          break
        case 'handleLog':
          router.push('/Container/UserManager/Log')
          break
        case 'systemConfig':
          router.push('/Container/UserManager/SystemConfig')
          break
        case 'logout':
          // 清除用户自定义配置
          store.commit('tenantCustomizationInfo', {})
          localStorage.clear()
          localStorage.setItem('language', locale.value === 'en' ? 'English' : '中文')
          clearInterval(percentageInterval)
          router.push('/Login')
          break
        default:
          break
      }
    }

    let myChart: echarts.ECharts | null = null
    let option = {
      tooltip: {
        trigger: 'item'
      },
      // #80b33f #40b0ff
      color: ['#40b0ff', '#80b33f'],
      series: [
        {
          name: '',
          type: 'pie',

          radius: ['60%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: [
            { name: t('header.used'), value: 0 },
            { name: t('header.remain'), value: 1024 }
          ]
        }
      ]
    }

    const dialogShow = ref<boolean>(false)
    const echartRef = ref<HTMLElement | null>(null)
    function cloudDiskCapacityClicked() {
      dialogShow.value = true
      getTenantpercentage()
      nextTick(() => {
        myChart = echarts.init(echartRef.value as HTMLElement)
        getCapacityInfo()
      })
    }
    function helpClicked() {
      const baseUrl = window.location.origin + window.location.pathname
      window.open(baseUrl + '#/doc?type=help')
    }

    const onCapacityApplicationClicked = () => {
      ZAppUrlTool.getUrlByKey('billing', '/can').then((res: string) => {
        const _url = `${res}#/Container/Billing/BillingInfo?ujwt=${localStorage.getItem('ujwt')}&token=${localStorage.getItem(
          'token'
        )}&loginUrl=${window.location.origin + window.location.pathname}#/Login&belongPlatform=candtu`

        window.open(_url, '_blank')
      })
    }
    const onToStudio = () => {
      ZAppUrlTool.getUrlByKey('manage', '/can').then((res: string) => {
        window.open(`${res}#/?token=${localStorage.getItem('token')}`, '_blank')
      })
    }

    const isOver = ref<boolean>(false)
    const getTenantpercentage = () => {
      getTenantUsagePercentage({ tenant_id: userInfo.tenant_id })
        .then((result: any) => {
          isOver.value = result.disk_usage_rate >= 10000
        })
        .catch((err: any) => {
          console.log(err)
        })
    }

    const handleCleanDevData = () => {
      getCapacityInfo()
      getTenantpercentage()
    }

    const handleToUserCenter = () => {
      const baseUrl = window.location.origin + window.location.pathname
      window.open(baseUrl + '#/doc?type=quickDelete')
    }

    const onWorkListClicked = () => {
      router.push('/Container/WorkOrderList')
    }

    onUnmounted(() => {
      myChart?.dispose()
      clearInterval(percentageInterval)
    })

    // window.ZWSApi.ZWSOutBilling.package_getPackage({
    getSinglePackageTenant({
      tenant_id: userInfo.tenant_id
    })
      .then((res: PackageInfoCom) => {
        store.commit('setUserPackage', res)
      })
      .catch((err: FetchError) => {
        console.log(err)
      })
    getTenantpercentage()
    let percentageInterval = setInterval(() => getTenantpercentage(), 1000 * 60 * 5)

    const lowCodeShow = computed(() => {
      const tenantCustomizationInfo = store.getters.tenantCustomizationInfo
      if (
        tenantCustomizationInfo &&
        tenantCustomizationInfo?.systemWebBottomCompany &&
        !tenantCustomizationInfo?.systemWebBottomCompany?.includes('广州致远电子股份有限公司')
      ) {
        return false
      } else return true
    })

    const language = ref('中文')
    const handleLanguageCommand = (val: string) => {
      const newConfig = {
        ...systemInfo.value,
        systemLanguage: val
      }
      const systemInfoArray = [
        'systemName',
        'systemTheme',
        'systemLogo',
        'systemWebBottomCompany',
        'systemWebBottomPhone',
        'systemWebBottomEmail'
      ]
      const dataArray = [
        {
          key: 'systemLanguage',
          value: val
        }
      ]
      const tenantCustomizationInfo = store.getters.tenantCustomizationInfo
      systemInfoArray.forEach(item => {
        dataArray.push({
          key: item,
          value: tenantCustomizationInfo[item] || ''
        })
      })

      localStorage.setItem('language', val === 'en' ? 'English' : '中文')
      store.commit('tenantCustomizationInfo', newConfig)
      setLanguage(val)
      // nextTick(() => (document.title = t('header.system')))
      // 发送请求
      updateSingleCustomization({
        tenant_id: userInfo.tenant_id,
        body: {
          data: dataArray
        }
      })
    }
    const handleScreenCommand = (command: string) => {
      const token = localStorage.getItem('token')
      if (!token) return
      const href = `https://${window.location.host}/can_${command}/index.html?token=${token}&locale=${locale.value}&tenantId=${userInfo.tenant_id}&userId=${userInfo.id}`
      window.open(href, '_blank')
    }
    const nameWidth = computed(() => (locale.value === 'en' ? '110px' : '70px'))

    const toStudioMonitoring = {
      platform: 'ZWS-Candtu',
      module: '首页',
      action: '低代码平台'
    }
    const capacityApplication = {
      platform: 'ZWS-Candtu',
      module: '首页',
      action: '容量申请'
    }
    const helpMonitoring = {
      platform: 'ZWS-Candtu',
      module: '首页',
      action: '帮助文档'
    }
    const capacityMonitoring = {
      platform: 'ZWS-Candtu',
      module: '首页',
      action: '云盘存储容量统计'
    }
    const quickDeleteMonitoring = {
      platform: 'ZWS-Candtu',
      module: '云盘存储容量统计'
    }

    function setDefaultLanguage() {
      language.value = '中文'
      proxy.$i18n.locale = 'zh'
      setLibLocale('zh')
    }

    watchEffect(() => {
      const tenantCustomizationInfo = store.getters.tenantCustomizationInfo
      const globalPermissionInfo = store.getters.globalPermissionInfo
      if (tenantCustomizationInfo?.systemLanguage) {
        if (languageMap.get(tenantCustomizationInfo?.systemLanguage)) {
          language.value = languageMap.get(tenantCustomizationInfo?.systemLanguage) as string
          proxy.$i18n.locale = tenantCustomizationInfo?.systemLanguage
          setLibLocale(tenantCustomizationInfo?.systemLanguage)
        } else setDefaultLanguage()
      } else if (globalPermissionInfo?.link) {
        const link = JSON.parse(globalPermissionInfo.link)
        language.value = link.systemLanguage === 'en' ? 'English' : '中文'
        proxy.$i18n.locale = link.systemLanguage
        setLibLocale(link.systemLanguage)
      } else setDefaultLanguage()
    })

    return {
      userPackage,
      logoSrc,
      systemName,
      dropdownCommands,
      curMenuIndex,
      homeMenuList,
      capacityInfo,
      defaultSystemInfo,
      logoHeadImgUrl,
      avatarImgUrl,
      systemInfo,
      userInfo,
      dialogShow,
      pieLoading,
      echartRef,
      isOver,
      toStudioMonitoring,
      language,
      capacityApplication,
      capacityMonitoring,
      helpMonitoring,
      quickDeleteMonitoring,
      lowCodeShow,
      nameWidth,
      // cloudDiskCapacityRef,
      toHomePageClicked,
      selectMenuClicked,
      handleCommand,
      cloudDiskCapacityClicked,
      helpClicked,
      onCapacityApplicationClicked,
      onToStudio,
      handleCleanDevData,
      handleToUserCenter,
      onWorkListClicked,
      handleLanguageCommand,
      handleScreenCommand
    }
  }
})
</script>
<style lang="less" scoped>
.zws-header {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--project-theme-color);
}

.zws-header-content {
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 var(--project-page-width);
}

.zws-header-text {
  font-size: 18px;
  margin-left: 10px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}

.zws-header-left {
  height: 50px;
  display: flex;
  text-align: left;
  align-items: center;
  cursor: pointer;
}

.zws-header-left-logo {
  // width: 42px;
  height: 42px;
}

.zws-header-right {
  height: 50px;
  display: flex;
  align-items: center;

  .a-menu-item {
    min-width: 80px;
    padding: 0 5px;
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    color: #ffffff;
    font-size: 14px;

    &:hover {
      color: #ffffff;
    }
  }

  .a-menu-item.selected {
    // width: 88px;
    height: 30px;
    color: white;
    border: 1px solid #fff;
    border-radius: 16px;
  }

  .capacity-application-text {
    display: inline-block;
    color: white;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    width: 82px;
    text-align: center;
    border-radius: 16px;
    cursor: pointer;
  }
  .work-text {
    display: inline-block;
    color: white;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    padding-left: 20px;
    border-left: 1px solid #99c265;
    // width: 20px;
    cursor: pointer;
  }
  :deep(.user-message-badge) {
    margin-left: 10px;
  }
}
.zws-header-logo {
  display: flex;
  justify-content: flex-end;
  flex: 1 0 calc((100% - var(--project-page-width)) / 2);
  cursor: pointer;
}
.zws-user-dropdown {
  flex: 1 0 calc((100% - var(--project-page-width)) / 2);
  box-sizing: border-box;
  padding-left: 20px;
}

.zws-header-right-user {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.zws-header-right-avatar {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.zws-header-right-username {
  display: block;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-left: 10px;
  font-weight: bold;
  color: #ffffff;
}

.zws-header-right-icon-setting {
  margin-left: 5px;
  color: #ffffff;
}

.iconfont-pie-style {
  font-size: 20px;
  color: #ffffff;
  // border-left: 1px solid #99c265;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 4px;
  // padding-left: 20px;
  cursor: pointer;
}

.iconfont-help-style {
  font-size: 20px;
  color: #ffffff;
  border-right: 1px solid #99c265;
  // margin-right: 20px;
  padding-right: 20px;
  margin-bottom: 4px;
  cursor: pointer;
}

.capacity-info-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 130px;
}

.capacity-info-echarts {
  position: relative;
}

.capacity-info-echarts-text {
  width: 150px;
  position: absolute;
  left: 30%;
  top: 40%;
  display: flex;
  flex-direction: column;
}

.capacity-info-echarts-text-size {
  font-size: 34px;
  color: #2f3032;
  text-align: center;
}

.capacity-info-echarts-text-symbol {
  font-size: 12px;
}

.capacity-info-echarts-text-describe {
  font-size: 14px;
  color: #2f3032;
  text-align: center;
  border-top: 1px solid #d9dce3;
  padding-top: 6px;
}

// .capacity-info-line {
//     border-left: 1px solid #dcdfe6;
//     height: 250px;
// }

.capacity-info-explain {
  border-left: 1px solid #dcdfe6;
  width: 350px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 80px;
  color: #818489;

  .title {
    display: inline-block;
    width: v-bind(nameWidth);
  }
}

.capacity-info-explain-text-content {
  font-size: 16px;
  color: #2f3032;
  margin-right: 5px;
}

.capacity-info-explain-application {
  display: inline-block;
  padding-bottom: 2px;
  margin-bottom: -2px;
  margin-left: 1.5em;
  // font-size: 16px;
  color: #99c265;
  cursor: pointer;
  // text-decoration: underline;
  border-bottom: 1px solid;
}

.capacity-table-container {
  padding: 0 20px 20px;
}
.dropdown-cursor {
  display: flex;
  color: #fff;
  cursor: pointer;
  margin-left: 15px;
  .text {
    display: inline-block;
    text-align: right;
    margin-right: 6px;
    white-space: nowrap;
  }
}
</style>
