/**
 * 通用
 */
export default {
  trigger: {
    onlyOneCodeAllow: 'Only one {code} is allowed',
    val1Val2: '[Val1, Val2]',
    inputLen: 'Input length',
    condition: 'Condition',
    addAtLeastOneCondition: 'Add at least one condition',
    pleaseInputValidId: 'Please enter a valid id value. Standard frame ID0x0~0x7ff, extended frame ID0x0~0x1fffffff',
    pleaseInputValidLen: 'Please enter a valid length of 0 to 64',
    betweenOperationFormat: 'Input format reference for the operator between: [0,5] or (0,5)',
    leftMustLowerThanRight: 'The signal value on the left cannot be greater than that on the right',
    signalValRange: 'Signal value valid range',
    pleaseSelectSignal: 'Please select signal',
    pleaseSelectOperator: 'Please select an operator',
    ruleNameLenLimit: 'Rule names are 1 to 50 characters in length',
    dbcSignal: 'DBC Signal',
    max1DBCSigCondition: 'Maximum 1 dbc signal condition',
    frameFormat: 'Frame Format',
    frameType: 'Frame Type',
    frameId: 'Frame ID',
    addTriggerCondition: 'Add Trigger Condition',
    triggerCondition: 'Trigger Condition',
    pleaseSelectCANChannel: 'Please select the CAN channel',
    triggerData: 'Trigger Data',
    pleaseInputRuleName: 'Please enter a rule name',
    ruleName: 'Rule Name',
    editTriggerRule: 'Edit Trigger Rule',
    addTriggerRule: 'Add Trigger Rule'
  },
  calculate: 'Calculate',
  pleaseInput: 'Please enter ',
  deleteConfirm: 'Are you sure to delete?',
  deleteSuccess: 'Delete success',
  deleteFailed: 'Delete failed',
  startTime: 'Start Time',
  endTime: 'End Time',
  custom: 'Custom',
  uploadTime: 'Upload Time',
  ge: '',
  enableSuccess: 'Enable success',
  disableSuccess: 'Disable success',
  number: 'Number',
  editFail: 'Edit failed',
  editSuccess: 'Edit success',
  nameInUse: 'The name is already in use',
  addFail: 'Add failed',
  addSuccess: 'Add success',
  data: 'Data',
  dataName: 'Data Name',
  length: 'Length',
  process: 'Process',
  unprocessed: 'Unprocessed',
  processed: 'Processed',
  systemTime: 'System Time',
  downloadSuccess: 'Download Success',
  saveSuccess: 'Save Success',
  lastWeek: 'Last week',
  disable: 'Disable',
  enable: 'Enable',
  exportSuccess: 'Export success',
  rawData: 'Raw Data',
  content: 'Content',
  status: 'Status',
  channel: 'Channel',
  export: 'Export',
  import: 'Import',
  fail: 'Fail',
  success: 'Success',
  service: 'Service',
  copySuccess: 'Copy success',
  copy: 'Copy',
  addDataPointFirst: 'Data points of a custom template cannot be empty. You need to add data points first',
  deviceTypeName: 'Device Type Name',
  eventName: 'Event Name',
  eventType: 'Event Type',
  deviceTypeId: 'Device Type ID',
  deviceId: 'Device ID',
  tenantName: 'Tenant Name',
  tenantId: 'Tenant ID',
  suffix: 'Suffix',
  pleaseInputSuffix: 'Please enter suffix',
  pleaseInputCharacters10: 'Please enter a maximum length of ten characters',
  prefix: 'Prefix',
  pleaseInputPrefix: 'Please enter prefix',
  binaryToHexadecimal: 'Binary To Hexadecimal',
  binaryToDecimal: 'Binary To Decimal',
  hexadecimalToBinary: 'Hexadecimal To Binary',
  hexadecimalToDecimal: 'Hexadecimal To Decimal',
  decimalToBinary: 'Decimal To Binary',
  decimalToHexadecimal: 'Decimal To Hexadecimal',
  format: 'Format',
  pleaseSelectConversionFormat: 'Please select format',
  pleaseInputCalculationFormula: 'Please enter formula',
  year: 'Year',
  month: 'Month',
  day: 'Day',
  hour: 'Hour',
  minute: 'Minute',
  Second: 'Second',
  microsecond: 'Microsecond',
  pleaseSelectDateFormat: 'Please select date format',
  dateFormat: 'Date Format',
  rename: 'Rename',
  moveDown: 'Down',
  moveUp: 'Up',
  pleaseInputCharacters20: 'Please enter a maximum length of 20 characters',
  pleaseInputTemplateName: 'Please enter a template name',
  starndardDataPoint: 'Starndard Data Point',
  pleaseSelectDataPoint: 'Please select data point',
  dataPoint: 'Data Point',
  editCustomTemplateFailed: 'Failed to edit a custom template',
  editCustomTemplateSuccess: 'The custom template is successfully edited',
  deleteCustomTemplateFailed: 'Failed to delete the custom template',
  deleteCustomTemplateSuccess: 'Deleting the custom template succeeded',
  addCustomTemplateFailed: 'Failed to add the custom template',
  addCustomTemplateSuccess: 'Adding the custom template succeeds',
  obtainCustomTemplateFailed: 'Failed to obtain a custom template',
  downloadDeviceDataFailed: 'Failed to download device data',
  selectStandardTemplate: 'Please select standard template',
  selectCustomTemplate: 'Please select custom template',
  csvDefaultFormat: 'csv(Default Format)',
  fileFormat: 'File Format',
  templateName: 'Template Name',
  waitingDownloadDeviceData:
    'You are downloading data from {devName} to {startTime}. Wait until the download process completes and create a new download task.',
  sureToDeleteTemplate: 'Are you sure to delete template({templateName})?',
  addTemplate: 'Add Template',
  customTemplate: 'Custom Template',
  standardTemplate: 'Standard Template',
  selectDataDownloadTemplate: 'Select Template',
  userCancelsDownload: 'User cancels download',
  waitToDownload: 'Waiting to download',
  generating: 'Generating',
  querying: 'Querying',
  finish: 'Finish',
  dataCollation: 'Data collation',
  initialize: 'Initialize',
  downloadingDeviceData: 'Downloading device {name} Data from {time}...',
  cancelDownload: 'Cancel Download',
  downloadProgress: 'Download Progress',
  cancelDataFileDownloadFailed: 'Downloading the data file of the {name} device failed',
  cancelDataFileDownloadSuccess: 'Downloading the data file of the {name} device succeeds',
  downloadDataFileSuccess: 'The data file of the device is downloaded successfully',
  downloadFormatNotSupport: 'This format is not supported. Please select another format',
  downloadFileSuccess: 'File download successful',
  displayDownloadProgress: 'Display the file download progress',
  dateFromTo: '{date1} to {date2}',
  delHistoryDataFromToSuccess: 'Delete the historical data from {date1} to {date2}',
  whoseChannel: 'CAN{channel} channel of {parentId}',
  showDeletionProgress2: 'Show Deletion Progress',
  close: 'Close',
  confirm: 'Confirm',
  deleteDataFailed: 'Failed to delete data',
  noDataInQueryTime: 'No data found, please modify query time',
  deletingDeviceData: 'Deleting device {tip} Data from {tipTime}...',
  deletionProgress: 'Deletion Progress',
  hide: 'Hide',
  showDeletionProgress: 'Show deletion progress',
  blockDeletion:
    'Deleting a device {tip} From {tipTime} Device data of the time range. Wait until the deletion process is complete before creating a new deletion task.',
  error: 'Error',
  deleteHistoryDataError:
    'The deleting historical data task of channel {channel} failed. Please deliver the deleting historical data task again.',
  deleteHistoryDataLimit:
    'If you delete more than 1 million pieces of data in this time range, the first 1 million pieces of data will be deleted by default. Do you want to continue?',
  deleteHistoryDataTip:
    'Deleting historical data is an <span style="color: red">irreversible operation</span>. Once the user password is entered, the operation cannot be terminated. Even if the operation is canceled, the data will continue to be deleted.',
  deleteHistoryDataTip1:
    '1.Deleting historical data is an <span style="color: red">irreversible operation</span>. Once the user password is entered, the operation cannot be terminated. Even if the operation is canceled, the data will continue to be deleted.',
  deleteHistoryDataTip2:
    '2. This delete function is slow delete, if you want to quickly delete all data of the device, you can click the "delete" button. ',
  colon: ':',
  announcements: 'attention',
  searchHistoryDataFirst: 'The current number of historical data is 0. Please query the data you want to save first',
  downloadLimit: 'The download limit is {limit}',
  yes: 'Yes',
  no: 'No',
  start: 'Start',
  suggestion: 'Suggestion',
  addFile: 'Add File',
  begin: 'Begin',
  pause: 'Pause',
  username: 'Username',
  cellPhoneNumber: 'Cell-phone Number',
  email: 'Email',
  creationTime: 'Creation Time',
  operation: 'Operation',
  edit: 'Edit',
  delete: 'Delete',
  resetPasswords: 'Reset Passwords',
  homePage: 'Home Page',
  desc: 'Description',
  permission: 'Permission',
  inputUsername: 'Please enter your username',
  inputDescription: 'Please enter a description',
  inputEmail: 'Please enter email',
  inputDevice: 'Please enter the search device',
  device: 'Device',
  pleaseSelect: 'Please select',
  devicePermissionNotEmpty: 'The device permission cannot be empty',
  readable: 'Readable',
  readWrite: 'Read-write',
  viewDevice: 'View device',
  viewDeviceLogAndStatus: 'View device logs and status',
  viewAndDownloadDeviceData: 'View and download device data',
  viewAndDownloadDBCCurves: 'View and download DBC Curves',
  viewTrigger: 'View trigger rule management',
  viewGPSTrack: 'Check the GPS track',
  viewDBCAndSignal: 'View DBC and signal policy',
  viewECUFile: 'View ECU file',
  viewFaultCodeFile: 'View the fault code file',
  viewAndRemoveDevices: 'View, add and remove devices',
  issueDeviceCommand: 'Issue device command',
  viewDownloadAndDeleteDeviceData: 'View, download and delete device data',
  viewDownlaodAndDeleteDBCCurves: 'View, download, add and delete DBC curves',
  viewAddRemoveRule: 'View, add and remove trigger rule management',
  performUDSAndECUOperation: 'Perform UDS diagnostics and ECU upgrades',
  allPermissionForDBC: 'View, add, update, and remove DBC and Signal policies',
  allPermissionForECU: 'View, add, update and delete ECU files',
  allPermissionForFaultCodeFile: 'View, add, update and delete fault code files',
  all: 'All',
  obtainDeviceTypeFailed: 'Failed to obtain the device type',
  serialNumber: 'Serial',
  deviceName: 'Device Name',
  deviceType: 'Device Type',
  deviceLoadingFailure: 'Device loading failure',
  checkAll: 'Check All',
  clear: 'Clear',
  update: 'Update',
  refresh: 'refresh',
  inputDevName: 'Please enter the device name',
  devId: 'Device ID',
  inputDevId: 'Please enter the device ID',
  devDesc: 'Description',
  inputDevDesc: 'Please enter a device description',
  selectDevType: 'Please select the device type',
  view: 'View',
  user: 'User',
  allUser: 'All User',
  masterUser: 'Master User',
  subUser: 'Subuser',
  pleaseSelectUser: 'Please select user',
  time: 'Time',
  pleaseInputSearchContent: 'Please enter your search content',
  search: 'Search',
  version: 'Version',
  download: 'Download',
  tip: 'Tip',
  file: 'File',
  protocolType: 'Protocol Type',
  message: 'Message',
  msgID: 'Message ID',
  signal: 'Signal',
  type: 'Type',
  back: 'Back',
  add: 'Add',
  modify: 'Modify',
  save: 'Save',
  cancel: 'Cancel',
  uploadImageLimitTip: 'You can upload {limit} images',
  selected: 'Selected'
}
