<template>
    <el-tooltip
            class="tip-with-hide"
            effect="light"
            placement="top-start"
    >
        <template #content>
            <slot></slot>
        </template>
        <div class="tip-with-hide-content">
            <slot></slot>
        </div>
    </el-tooltip>
</template>
<script setup lang="ts">
    import { ref, withDefaults, defineProps, onMounted } from "vue";

    interface Props {
        maxWidth : number
    }

    const props = withDefaults(defineProps<Props>(), {
        maxWidth: 120
    });

    const targetWidth = `${props.maxWidth}px`;

</script>

<style lang="less" scoped>
    .tip-with-hide {
        &-content {
            max-width: v-bind(targetWidth);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
</style>
