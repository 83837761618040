/**
 * 故障码管理
 */
export default {
  pleaseEnterDTCDisplayName: 'Please enter the DTC display name',
  pleaseEnterFaultCode: 'Please enter the fault code',
  faultCode: 'Fault Code',
  dtcDisplayName: 'DTC Display Name',
  downloadTemplate: 'Download Template',
  DTCFile: 'DTC File',
  deleteDTCFileFailed: 'Failed to delete the DTC file, {error}',
  deleteDTCFileSuccess: 'The DTC file is deleted successfully',
  obtainDTCConfigFailed: 'Failed to obtain the DTC configuration',
  updateDTCFileFailed: 'Failed to update the DTC file',
  fileUpdatedSuccess: 'The DTC file is updated successfully',
  fileNameInconsistent: 'The DTC file name is inconsistent',
  fileAbnormal: 'The file is abnormal and failed to be added',
  addDTCFileFailed2: 'Failed to add the DTC file, {error}',
  addDTCFileSuccess: 'Adding the DTC file succeeded',
  addDTCFileFailed: 'Add failed, the DTC file content is incorrect',
  downloadTemplateFailed: 'Failed to download the DTC file template',
  obtainDTCFileListFailed: 'Failed to obtain the DTC file list',
  sureToDeleteDTCFile: 'Are you sure you want to delete the DTC file?'
}
