/**admin、cloud共用的套餐TPS计算组件 */
<template>
    <div>
        <el-form ref="formRef" :model="form" label-width="120px" :rules="rules" label-position="left">
            <el-row>
                <el-form-item label="设备数:" prop="deviceNum">
                    <el-input class="input-value" v-model.number="form.deviceNum" />
                    <span>个</span>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="消息上报间隔:" prop="sendFrequency">
                    <el-input class="input-value" v-model.number="form.sendFrequency" />
                    <span>s</span>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="设备上报字节:" prop="sendByte">
                    <el-input class="input-value" v-model.number="form.sendByte" />
                    <span>B/s</span>
                </el-form-item>
            </el-row>
            <el-row style="display: flex;justify-content: center;margin-bottom: 10px;">
                <el-button type="primary" @click="compute" :loading="computeClock">计算</el-button>
            </el-row>
        </el-form>
    </div>
    <div>
        <div style="text-align: left; margin-bottom: 5px;">
            <span>推荐TPS指标</span>
        </div>
        <el-table :data="tableData" border>
            <el-table-column prop="minimum" label="最低指标" />
            <el-table-column prop="recommend" label="稳定指标" />
        </el-table>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'

const formRef = ref()
const validateNumber = (rule: any, value: any, callback: any) => {
    if (!Number.isInteger(value) || value < 0) {
        callback(new Error('必须为正整数'))
    }
    callback()
}
const validateStep10 = (rule: any, value: any, callback: any) => {
    if (value % 10 !== 0) {
        callback(new Error('必须为10的倍数'))
    }
    callback()
}
const validateStep100 = (rule: any, value: any, callback: any) => {
    if (value % 100 !== 0) {
        callback(new Error('必须为100的倍数'))
    }
    callback()
}
const validateScope100_1000000 = (rule: any, value: any, callback: any) => {
    if (value < 100 || value > 1000000) {
        callback(new Error('取值区间为[100,1000000]'))
    }
    callback()
}
const validateScope1_604800 = (rule: any, value: any, callback: any) => {
    if (value < 1 || value > 604800) {
        callback(new Error('取值区间为[1,604800]'))
    }
    callback()
}
const validateMaxFactory = (max: number) => {
    return (rule: any, value: any, callback: any) => {
        if (value > max) {
            callback(new Error(`最大为${max}`))
        }
        callback()
    }
}
const rules = reactive({
    deviceNum: [
        { required: true, message: '非空', trigger: 'blur' },
        { validator: validateNumber, trigger: 'blur' },
        { validator: validateMaxFactory(1000000), trigger: 'blur' },
        // { validator: validateScope100_1000000, trigger: 'blur' },
    ],
    sendFrequency: [
        { required: true, message: '非空', trigger: 'blur' },
        { validator: validateNumber, trigger: 'blur' },
        { validator: validateScope1_604800, trigger: 'blur' },
    ],
    sendByte: [
        { required: true, message: '非空', trigger: 'blur' },
        { validator: validateNumber, trigger: 'blur' },
    ],
})
const form = reactive({
    deviceNum: 0,
    sendFrequency: 0,
    sendByte: 0,
})
const tableData = reactive<
    Array<{
        minimum: string
        recommend: string
    }>
>([])
const computeClock = ref(false)
function compute() {
    formRef.value.validate((validate: boolean) => {
        if (validate) {
            computeClock.value = true
            window.ZWSApi.ZWSOutBilling.tools_assessTps({
                msg_interval: form.sendFrequency,
                msg_size: form.sendByte,
                things_num: form.deviceNum,
            })
                .then((res: any) => {
                    computeClock.value = false
                    tableData.length = 0
                    const { minimum, recommend } = res
                    tableData.push({
                        minimum: `上下行TPS：${minimum.up_down_stream_connections}条/s`,
                        recommend: `上下行TPS：${recommend.up_down_stream_connections}条/s`,
                    })
                    tableData.push({
                        minimum: `推送TPS：${minimum.push_tps}条/s`,
                        recommend: `推送TPS：${recommend.push_tps}条/s`,
                    })
                    tableData.push({
                        minimum: `告警TPS：${minimum.alert_tps}条/s`,
                        recommend: `告警TPS：${recommend.alert_tps}条/s`,
                    })
                })
                .catch((error: any) => {
                    computeClock.value = false
                    console.log(error)
                })
        }
    })
}

function resetForm() {
    form.deviceNum = 0
    form.sendFrequency = 0
    form.sendByte = 0
    tableData.length = 0
    formRef.value.clearValidate()
}

defineExpose({ resetForm })
</script>

<style lang="less" scoped>
.input-value {
    width: 200px;
    margin-right: 5px;
}
</style>
