import ZPermissionTool from '../../ZPermissionTool'
import { hasPermission } from '@zws/axios-fetch'
/**
 * disabled
 */
const buttonPermission = {
  buttonDisabled(el: HTMLElement, binding: any) {
    const { value } = binding
    if (!value) return
    // let hasPermissionState: boolean = true
    // const userApiRoleListMap = localStorage.getItem('userApiRoleListMap')
    //   ? JSON.parse(localStorage.getItem('userApiRoleListMap') as string)
    //   : undefined

    // if (userApiRoleListMap) {
    //   hasPermissionState = userApiRoleListMap[value]
    // }
    if (!hasPermission(value)) {
      el.className += ' is-disabled'
      el.setAttribute('disabled', '')
    }
    // 没必要
    // else {
    //   el.removeAttribute('disabled')
    //   if (el.className.includes(' is-disabled')) {
    //     el.className.slice(el.className.indexOf(' is-disabled'), el.className.indexOf(' is-disabled') + 12)
    //   }
    // }
  }
}

export default {
  mounted(el: HTMLElement, binding: any) {
    buttonPermission.buttonDisabled(el, binding)
  },
  beforeUpdate(el: HTMLElement, binding: any) {
    buttonPermission.buttonDisabled(el, binding)
  }
}
