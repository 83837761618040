<template>
    <el-menu class="zws-aside-menu" mode="horizontal" :default-active="router.currentRoute.value.path" :collapse="isClose" :collapse-transition="false" text-color="" background-color="" router>
        <template v-for="(item, index) in asideList" :key="index">
            <el-menu-item v-if="!item.childrens" :index="item.index">
                <div class="zws-aside-menu-item">
                    <i :class="`zws-aside-menu-item-iconSetting iconfont ${item.icon}`"></i>
                    <span>{{ item.title }}</span>
                </div>
            </el-menu-item>
            <el-submenu v-else :index="String(index)" :key="index">
                <template #title>
                    <div class="zws-aside-menu-item">
                        <i :class="`zws-aside-menu-item-iconSetting iconfont ${item.icon}`"></i>
                        <span>{{ item.title }}</span>
                    </div>
                </template>
                <div v-for="(v, i) in item.childrens" :key="i">
                    <el-menu-item style="padding-left:18px" :index="v.index">
                        <span>{{ v.title }}</span>
                    </el-menu-item>
                </div>
            </el-submenu>
        </template>
    </el-menu>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { projectConfig } from '@/assets/ts/project_config'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup() {
        const isClose = ref(false)
        function toggleIsCollapse() {
            isClose.value = !isClose.value
        }
        const asideList: any = ref(projectConfig.asideList)
        const router = useRouter()
        return {
            isClose,
            toggleIsCollapse,
            asideList,
            router,
        }
    },
})
</script>
<style lang="less" scoped>
.zws-aside-collapse {
    height: 20px;
    margin-right: 3px;
    text-align: right;
    cursor: pointer;
}
.zws-aside-collapse-iconSetting {
    font-size: 8px;
    color: #b8c0cc;
}
.zws-aside-collapse-iconSetting:hover {
    font-size: 8px;
    color: #ffffff;
}
.zws-aside-menu-item {
    text-align: left;
}
.zws-aside-menu-item-iconSetting {
    font-size: 22px;
    margin-right: 16px;
}
.zws-aside-menu-item-iconSetting:hover {
    font-size: 22px;
    margin-right: 16px;
    color: #ffffff;
}
</style>
