<template>
<div class="z-main-header-area">
    <div>
        <span class="left-title" :title="mainTitle">{{ mainTitle }}</span>
        <span class="left-title extra-text">{{ extraText }}</span>
    </div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="(item, index) in modulenameList" :key="index"
                            :to="item.path ? { path: item.path } : { name: item.routeName, params: item.params }"
                            :title="item.name">
            <span @click="onClicked(item.name, item)">{{ item.name }}</span>
        </el-breadcrumb-item>
    </el-breadcrumb>
</div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
    props: {
        modulename: {
            type: Array,
            default: () => {
                return [
                    {
                        path: '/',
                        name: '',
                        routeName: '',
                        params: {},
                    },
                ]
            },
        },
        mainTitle: {
            type: String,
            dafault: '',
        },
        extraText: {
            type: String,
            default: '',
        }
    },
    setup(props, context) {
        let mainTitle = ref('')
        let extraText = ref('')
        let modulenameList: any = ref([])
        props.modulename.forEach((item: any) => {
            modulenameList.value.push(item)
        })
        watch(() => props.modulename, (newV: any) => {
            modulenameList.value.splice(0, modulenameList.value.length)
            props.modulename.forEach((item: any) => {
                modulenameList.value.push(item)
                init()
            })
        },
            {
                immediate: true,
                deep: true
            })
        watch(() => props.mainTitle, (newV: any) => {
            mainTitle.value = newV
        })
        watch(() => props.extraText, (newV: any) => {
            extraText.value = newV
        }, {
            immediate: true
        })
        init()
        function init() {
            if (!props.mainTitle) {
                mainTitle.value = modulenameList.value[modulenameList.value.length - 1].name
            } else {
                mainTitle.value = props.mainTitle
            }
        }

        function onClicked(name: string, item: any) {
            context.emit('onClicked', name)
            context.emit('control', { name: 'addOrEdit', value: item.location })
        }
        return {
            modulenameList,
            mainTitle,
            onClicked,
            extraText,
        }
    },
})
</script>

<style lang="less" scoped>
.z-main-header-area {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    height: 32px;
    line-height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f6f9fc;
    color: #262626;
    border-bottom: 1px solid #dae0e7;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.left-title {
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    // min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

.extra-text{
    font-size: 12px;
    color: #8a9199;
    margin-left:14px;
    // color: #dadde0;
}

.el-breadcrumb {
    text-align: right;
    float: right;
    font-weight: 700;
    margin-top: 8px;
    margin-right: 10px;
    color: #6c6e72;
    font-size: 14px;
}
</style>
