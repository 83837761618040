<template>
    <el-date-picker v-model="selectDateValue" type="datetimerange" unlink-panels :shortcuts="shortcuts"
        :range-separator="to" :start-placeholder="startDate" :end-placeholder="endDate" @change="datePickerChange"
        v-bind="$attrs">
    </el-date-picker>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import type { PropType } from 'vue'
import ZDateTool from '../../../sdk/ts/tool/ZDateTool'
import { useLocale } from '../hooks/useLocale'
import { useI18n } from 'vue-i18n'

export interface ZDatePickerCtrlShortcut {
    text: string,
    value: () => [Date, Date]
}

export interface ZDatePickerCtrlOptions {
    shortcuts: (ZDatePickerCtrlShortcut | string)[]
}

export default defineComponent({
    emits: ['getTimestamp', 'getDefultDateValue', 'update:modelValue'],
    props: {
        modelValue: {},
        defultDate: {
            type: String
        },
        options: {
            type: Object as PropType<ZDatePickerCtrlOptions>,
            default: { shortcuts: ['last-hour', 'today', 'last-week', 'last-month', 'last-three-month', 'last-half-year', 'last-year'] }
        }
    },
    setup(prop: any, context) {
        const { t } = useLocale()
        const { locale } = useI18n()
        let selectDateValue: any = ref('')
        let shortcuts = reactive([] as ZDatePickerCtrlShortcut[])
        let defaultOptions = {
            shortcuts: ['last-hour', 'today', 'last-week', 'last-month', 'last-three-month', 'last-half-year', 'last-year']
        }
        const startDate = computed(() => t('zws.ZDatePickerCtrl.startDate'))
        const endDate = computed(() => t('zws.ZDatePickerCtrl.endDate'))
        const to = computed(() => t('zws.ZDatePickerCtrl.to'))
        watch(() => prop.modelValue, (newValue, oldValue) => {
            selectDateValue.value = newValue
        })

        watch(() => prop.defultDate, (newValue: any, oldValue) => {
            getDefultDateValue(newValue as string)
        })

        watch([() => prop.options, locale], (newValue: any) => {
            let options = {} as ZDatePickerCtrlOptions

            if (!newValue) Object.assign(options, defaultOptions)
            else Object.assign(options, defaultOptions, newValue)

            // 初始化快捷选项
            if (Array.isArray(options?.shortcuts)) {
                shortcuts.length = 0
                options.shortcuts.forEach((shortcut) => {
                    if (typeof shortcut == 'string') {// 内置快捷项
                        switch (shortcut) {
                            case 'last-hour':
                                shortcuts.push({
                                    text: t('zws.ZDatePickerCtrl.lasthour'),
                                    value: () => {
                                        const end = new Date()
                                        const start = new Date()
                                        start.setTime(start.getTime() - getTimeDifference('hour'))
                                        return [start, end]
                                    }
                                })
                                break;
                            case 'the-day-before-yesterday':
                                shortcuts.push({
                                    text: '前天',
                                    value: () => {
                                        const end = new Date(new Date().getTime() - 1 * getTimeDifference('day'))
                                        end.setHours(0, 0, 0, 0)
                                        const start = new Date(new Date().getTime() - 2 * getTimeDifference('day'))
                                        start.setHours(0, 0, 0, 0)
                                        return [start, end]
                                    }
                                })
                                break;
                            case 'yesterday':
                                shortcuts.push({
                                    text: '昨天',
                                    value: () => {
                                        const end = new Date()
                                        end.setHours(0, 0, 0, 0)
                                        const start = new Date(new Date().getTime() - getTimeDifference('day'))
                                        start.setHours(0, 0, 0, 0)
                                        return [start, end]
                                    }
                                })
                                break;
                            case 'today':
                                shortcuts.push({
                                    text: t('zws.ZDatePickerCtrl.today'),
                                    value: () => {
                                        const end = new Date()
                                        const start = new Date(new Date(new Date().toLocaleDateString()).getTime())
                                        return [start, end]
                                    },
                                })
                                break;
                            case 'last-week':
                                shortcuts.push({
                                    text: t('zws.ZDatePickerCtrl.lastweek'),
                                    value: () => {
                                        const end = new Date()
                                        const start = new Date()
                                        start.setTime(start.getTime() - getTimeDifference('week'))
                                        return [start, end]
                                    }
                                })
                                break;
                            case 'last-month':
                                shortcuts.push({
                                    text: t('zws.ZDatePickerCtrl.lastMonth'),
                                    value: () => {
                                        const end = new Date()
                                        const start = new Date()
                                        start.setTime(start.getTime() - getTimeDifference('month'))
                                        return [start, end]
                                    }
                                })
                                break;
                            case 'last-three-month':
                                shortcuts.push({
                                    text: t('zws.ZDatePickerCtrl.lastThreeMonths'),
                                    value: () => {
                                        const end = new Date()
                                        const start = new Date()
                                        start.setTime(start.getTime() - getTimeDifference('trimester'))
                                        return [start, end]
                                    }
                                })
                                break;
                            case 'last-half-year':
                                shortcuts.push({
                                    text: t('zws.ZDatePickerCtrl.lastSixMonths'),
                                    value: () => {
                                        const end = new Date()
                                        const start = new Date()
                                        start.setTime(start.getTime() - getTimeDifference('halfyearly'))
                                        return [start, end]
                                    },
                                })
                                break;
                            case 'last-year':
                                shortcuts.push({
                                    text: t('zws.ZDatePickerCtrl.nearlyYear'),
                                    value: () => {
                                        const end = new Date()
                                        const start = new Date()
                                        start.setTime(start.getTime() - getTimeDifference('year'))
                                        return [start, end]
                                    }
                                })
                                break;
                            default:
                                break;
                        }
                    } else {
                        shortcuts.push(shortcut)
                    }
                })
            }
        }, {
            immediate: true
        })

        init()

        function init() {
            // 如果设置了默认值
            if (prop.defultDate !== undefined && prop.defultDate !== '') {
                getDefultDateValue(prop.defultDate)
            }
            // 如果设置了v-model，并且其值不为空，则覆盖defultDate的设置
            if (prop.modelValue !== undefined && prop.modelValue !== '' && Object.keys(prop.modelValue as Object).length !== 0) {
                selectDateValue.value = prop.modelValue
                // 初始化时，触发@getTimestamp返回时间戳的值
                datePickerChange(selectDateValue.value)
            }
        }

        function getDefultDateValue(timeFormat: string) {
            if (timeFormat != 'yesterday') {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - getTimeDifference(timeFormat))
                selectDateValue.value = [start, end]
            } else {
                const end = new Date()
                end.setHours(0, 0, 0, 0)
                const start = new Date(new Date().getTime() - getTimeDifference('day'))
                start.setHours(0, 0, 0, 0)
                selectDateValue.value = [start, end]
            }

            let startTime = ZDateTool.formatDateTimeToTimestamp(selectDateValue.value[0])
            let endTime = ZDateTool.formatDateTimeToTimestamp(selectDateValue.value[1])
            //初始化时，触发@getDefultDateValue返回时间戳的值
            context.emit('getDefultDateValue', { startTime: startTime, endTime: endTime })
        }

        function getTimeDifference(timeFormat: string) {
            let difference = 0
            switch (timeFormat) {
                case 'hour':
                    difference = 3600 * 1000 * 1
                    break;
                case 'day':
                    difference = 3600 * 1000 * 24 * 1
                    break;
                case 'week':
                    difference = 3600 * 1000 * 24 * 7
                    break;
                case 'month':
                    difference = 3600 * 1000 * 24 * 30
                    break;
                case 'trimester':
                    difference = 3600 * 1000 * 24 * 90
                    break;
                case 'halfyearly':
                    difference = 3600 * 1000 * 24 * 180
                    break;
                case 'year':
                    difference = 3600 * 1000 * 24 * 365
                    break;
                default:
                    break;
            }
            return difference
        }

        function datePickerChange(value: any) {
            // 清空时处理
            if (!value) {
                context.emit('getTimestamp', { startTime: 0, endTime: 0 })
                context.emit('update:modelValue', value)
                return
            }
            let startTime = ZDateTool.formatDateTimeToTimestamp(value[0])
            let endTime = ZDateTool.formatDateTimeToTimestamp(value[1])
            context.emit('getTimestamp', { startTime: startTime, endTime: endTime })
            context.emit('update:modelValue', value)
        }

        return {
            to,
            shortcuts,
            startDate,
            endDate,
            selectDateValue,
            datePickerChange,
        }
    },
})
</script>
