import common from './common'
import subuserManagement from './subuserManagement'
import handleLog from './handleLog'
import updateLog from './updateLog'
import homePage from './homePage'
import dbcManagement from './dbcManagement'
import ecuManagement from './ecuManagement'
import faultCode from './faultCode'
import workOrder from './workOrder'
import deviceManagement from './deviceManagement'
import deviceData from './deviceData'
import dbcData from './dbcData'
import triggerManagement from './triggerManagement'
import udsDiagnose from './udsDiagnose'
import ecuUpgrade from './ecuUpgrade'
import gpsTrack from './gpsTrack'
import lib from './lib'

export default {
  menu: {
    homePage: 'Home Page',
    dbcManagement: 'Signaling Manage',
    ecuManagement: 'ECU Manage',
    faultCodeManagement: 'Fault Code',
    lowCodePlatform: 'Low Code',
    capacityRequest: 'Space Apply',
    workOrder: 'Work Order',
    usercenter: 'Personal center',
    subuserManagement: 'Subuser management',
    handleLog: 'Operation log',
    updateLog: 'Update log',
    logout: 'Log out'
  },
  detailMenu: {
    deviceManagement: 'Device Management',
    deviceData: 'Device Data',
    dbcData: 'DBC Data',
    triggerManagement: 'Trigger Management',
    udsDiagnose: 'UDS Diagnose',
    ecuUpgrade: 'ECU Upgrade',
    gpsTrack: 'GPS Track'
  },
  header: {
    statisticsTitle: 'Cloud disk storage capacity statistics',
    remainingCapacity: 'Percentage of remaining capacity',
    total: 'Total capacity:',
    used: 'Used capacity:',
    remain: 'Surplus capacity:',
    system: 'CANDTU Data Platform',
    screen: 'Large Screen',
    monitorScreen: 'Monitor Large Screen',
    operationScreen: 'Operation Large Screen'
  },
  footer: {
    phone: 'Tel:',
    email: 'Email:',
    company: 'zhiyuan electronics Co. Ltd'
  },
  common,
  subuserManagement,
  handleLog,
  updateLog,
  homePage,
  dbcManagement,
  ecuManagement,
  faultCode,
  workOrder,
  deviceManagement,
  deviceData,
  dbcData,
  triggerManagement,
  udsDiagnose,
  ecuUpgrade,
  gpsTrack,
  lib
}
