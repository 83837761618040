import ZWSBaseModule from '../httpBase/ZWSBaseModule'

class ZWSAlertMgmt extends ZWSBaseModule {

  /*******************************************************  alertEvents  *****************************************************/
  /**
   * 查询告警事件列表
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.start_time] 开始时间（毫秒）
   * @param {number} [params.end_time] 结束时间（毫秒）
   * @param {number} [params.page_size] 每页条数
   * @param {number} [params.current_page] 跳过多少页数据
   * @param {string} [params.name] 规则名称
   * @param {number} [params.rule_type] 规则类型,1数据推送，2告警
   * @param {number} [params.last_update_time] 最后一次更新时间
   * @param {number} [params.info_model_id] 模型id
   * @param {number} [params.rule_id] 规则id列表，逗号分隔
   * @param {string} [params.rule_name] 规则名称（模糊匹配）
   * @param {number} [params.things_id] 设备id  
   * @param {number} [params.third_things_id] 设备串号  
   * @param {number} [params.handler] 处理人 右模糊  
   * @param {number} [params.handler_status] 处理状态 
   * @param {number} [params.source_type] 告警来源类型,1:普通上报数据;2:双生设备告警. 
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static alertEvents_getBatchAlertEvents({ tenant_id, start_time, end_time, page_size, current_page, name, rule_type, last_update_time, info_model_id, rule_id, rule_name, things_id, third_things_id, handler, handler_status, event_data, source_type }: any) {
    return this.fetch({
      url: `/alert-data-mgmt/tenants/${tenant_id}/alert-events`,
      method: 'get',
      params: { start_time, end_time, page_size, current_page, name, rule_type, last_update_time, info_model_id, rule_id, rule_name, things_id, third_things_id, handler, handler_status, event_data, source_type }
    }, 'alert:alert-data-event:query')
  }
  /**
   * 告警事件的删除
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.end_time] 结束时间（毫秒）
   * @param {string} [params.event_id] 事件id,多个使用逗号分隔
   * @param {number} [params.info_model_id] 模型id
   * @param {number} [params.rule_id] 规则id
   * @param {number} [params.rule_name] 规则id
   * @param {number} [params.start_time] 开始时间（毫秒）
   * @param {number} [params.things_id] 设备ID
   * @param {string} [params.third_things_id] 设备串号
   * @param {number} [params.handler] 处理人 右模糊  
   * @param {number} [params.handler_status] 处理状态 
   * @param {array} [params.id_list] 数据id,多个使用逗号分隔
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static alertEvents_deleteAlertEvents({ tenant_id, end_time, event_id, info_model_id, rule_id, start_time, things_id, third_things_id, handler, handler_status, id_list }: { tenant_id: number, end_time: number, event_id: string, info_model_id: number, rule_id: number, start_time: number, things_id: number, third_things_id: string, handler: string, handler_status: number, id_list: string }) {
    return this.fetch({
      url: `/alert-data-mgmt/tenants/${tenant_id}/alert-events`,
      method: 'delete',
      params: { end_time, info_model_id, rule_id, event_id, start_time, things_id, third_things_id, handler, handler_status, id_list }
    }, 'alert:alert-data-event:delete')
  }

  /**
   * 获取告警事件详情
   * @param {number} [params.tenant_id] 租户id
   * @param {number} [params.id] 记录ID
   * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
   */
  static alertEvents_getBatchEdgeComputingRules({ tenant_id, id }: { tenant_id: number, id: number }) {
    return this.fetch({
      url: `/alert-data-mgmt/tenants/${tenant_id}/alert-events/{id}`,
      method: 'get',
      params: { id }
    }, 'alert:alert-data-event:query')
  }

  /**
  * 处理告警事件
  * @param {number} [params.tenant_id] 租户id
  * @param {number} [params.id] 记录ID
  * @param {object} [params.handler] 处理人
  * @param {object} [params.handler_description] 处理描述
  * @param {object} [params.handler_status] 处理状态, 0:未处理, 1已处理, 2忽略
  * @return {Promise<Response, Error>} - Response: { result: true, message: '', jwt: '', data: {} }
  */
  static alertEvents_updateSingleEventsStatus({ tenant_id, id, handler, handler_description, handler_status }: { tenant_id: number, id: number, handler: string, handler_description: string, handler_status: number }) {
    return this.fetch({
      url: `/alert-data-mgmt/tenants/${tenant_id}/alert-events/${id}/_handle`,
      method: 'post',
      data: { handler, handler_description, handler_status }
    }, 'alert:alert-data-event:handle')
  }

  /**
  * 删除全部告警事件(仅租户管理员可用)
  * @param {number} [tenant_id ] - 租户id
  */
  static edgeComputing_deleteSingleEdgeComputingRule({ tenant_id }: { tenant_id: number }) {
    return this.fetch({
      url: `/alert-data-mgmt/tenants/${tenant_id}/alert-events/all`,
      method: 'delete',
    }, 'alert:alert-data-event:delete')
  }

}

export default ZWSAlertMgmt
