import { outWebConfigConfigInfo } from '@zws/axios-swagger-fetch'
import { setLibLocale } from '@/assets/ts/Common/useLocaleHelper'

export default {
  async getSystemConfig({ commit }: { commit: Function }) {
    const isDebug = window.location.pathname.includes('debug') ? 1 : 0
    await outWebConfigConfigInfo({
      query: {
        resourcePath: '/can',
        isDebug
      }
    })
      .then(res => {
        if (res.data) {
          commit('globalPermissionInfo', res.data)
          try {
            const link = JSON.parse((res.data as Record<string, string>).link)
            setLibLocale(link.systemLanguage === 'en' ? 'English' : '中文')
          } catch (error) {
            console.log(error)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
}
