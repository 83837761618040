<template>
    <el-button class="button-loading-default" v-bind="$attrs" @click="btnClick" :disabled="isLoading">
        <div v-if="$slots.default && !isLoading">
            <slot></slot>
        </div>
        <div class="button-loading-item" v-if="isLoading">
            <!--<el-icon class="is-loading">-->
                <!--<loading color="#ffffff"/>-->
            <!--</el-icon>-->
            <p>{{loadingTip}}</p>
        </div>
    </el-button>
</template>

<script setup lang="ts">
// import { Loading } from '@element-plus/icons-vue';

const props = defineProps({
    isLoading: {
        type: Boolean,
        default: () => false
    },
    loadingTip: {
        type: String,
        default: 'Loading...'
    }
});
const emit = defineEmits(['btnClick']);

function btnClick() {
    !props.isLoading && btnClick && typeof btnClick === 'function' && emit('btnClick');
}

</script>
<style lang="less" scoped>
    .button-loading-default {
        margin-left: 0;
    }

    .button-loading-item {
        display: flex;
        align-items: center;
        justify-content: center;

        >p {
            margin: 0 0 0 8px;
            color: rgba(255,255,255,.7);
        }
    }

</style>