import ZWSBaseModule from '../httpBase/ZWSBaseModule'

export interface Firmware {
  create_time: number,
  update_time: number,
  description: string,
  firmware_type: number,
  id: number,
  model_id: number,
  name: string,
  owner_id: number,
  size: number,
  tenant_id: number,
  url: string,
  version: string,
  version_num: number,
  to_public: boolean,
  [propName: string]: any
}

/**
 * 固件管理
 */
class ZWSOutFirmwareMgmt extends ZWSBaseModule {

  /************* download ********************************************************************************************/
  /************* firmware ********************************************************************************************/


  /**
   * 查询固件
   * @param param0 
   * @returns 
   */
  static firmware_getFirmwares({ tenantId, model_id }: { tenantId: number, model_id: number }) {
    return this.fetch({
      url: `/out-firmware-mgmt/tenants/${tenantId}/firmwares`,
      method: 'get',
      params: { model_id }
    }, "firmware:firmware:query")
  }

  /**
   * 上传固件
   * @param tenantId 
   * @param param1 
   * @returns 
   */
  static firmware_addFirmware({ tenantId, file, version, version_num, model_id, firmware_type, to_public, desc, sub_dev_id }: {
    tenantId: number,
    file: File
    version_num: string
    model_id: string
    version: string
    firmware_type: string
    to_public: string
    desc: string
    sub_dev_id?: string
  }) {
    let formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('version_num', version_num)
    formData.append('model_id', model_id)
    formData.append('version', version)
    formData.append('firmware_type', firmware_type)
    formData.append('to_public', to_public)
    formData.append('desc', desc)
    if (sub_dev_id) {
      formData.append('sub_dev_id', sub_dev_id)
    }

    return this.fetch({
      url: `/out-firmware-mgmt/tenants/${tenantId}/firmwares`,
      method: 'post',
      data: formData,
    },"firmware:firmware:upload")
  }

  /**
   * 删除固件
   * @param param0 
   * @returns 
   */
  static firmware_deleteFirmware({ tenantId, firmware_id, model_id, version }: { tenantId: number, firmware_id: number, model_id: number, version: string }) {
    return this.fetch({
      url: `/out-firmware-mgmt/tenants/${tenantId}/firmwares`,
      method: 'delete',
      params: { firmware_id, model_id, version }
    },"firmware:firmware:delete")
  }

  /**
   * 发布固件
   * @param param0 
   * @returns 
   */
  static firmware_updateToPublic({ firmwareId, tenantId, toPublic }: { firmwareId: number, tenantId: number, toPublic: boolean }) {
    return this.fetch({
      url: `/out-firmware-mgmt/tenants/${tenantId}/firmwares/${firmwareId}/${toPublic}`,
      method: 'put'
    },"firmware:firmware:public")
  }

  /**
   * 通知设备有新的固件
   * @param {Object} params
   * @param {string} params.devtype - 设备类型
   * @param {string} params.version - 固件的版本号。保留两位小数，如1.00, 2.01
   * @param {string} [params.devid] - 设备ID
   * @param {string} [params.sub_dev_id] - null 标识用于主设备，not null 标识子设备id; 当仅用于区分主子设备时，子设备传0即可，不需要具体的id
   * @return {Promise<Response, Error>} -
   */
  static firmware_updateFirmware({ author_id, dev_id, gw_id, is_diff, label_id, label_value, sw_id, thing_type, upgrade_type, version_num, version, tenantId, model_id, sub_dev_id, opt }: any) {
    return this.fetch({
      url: `/out-firmware-mgmt/tenants/${tenantId}/firmwares/${model_id}/${version}/notify`,
      method: 'post',
      data: { author_id, dev_id, gw_id, is_diff, label_id, label_value, sw_id, thing_type, upgrade_type, version_num, sub_dev_id, opt }
    },"firmware:firmware:notify-upgrade")
  }
  /**
   * 查询指定设备类型某个版本的固件信息
   * @param {Object} params
   * @param {string} params.devtype - 设备类型
   * @param {string} params.version - 固件的版本号。保留两位小数，如1.00, 2.01
   * @return {Promise<Response, Error>} -
   */
  static getFirmwareInfoByVersion({ devtype, version }: any) {
    return this.fetch({
      url: `/firmware/${devtype}/${version}/desc`,
      method: 'get'
    },"firmware:firmware:get-desc")
  }
}

export default ZWSOutFirmwareMgmt
