export default class ZAppUrlTool {
    static link: any
    static getUrlByKey(key: string, resourcePath: string = '/web'): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.link && this.link[key]) {
                resolve(this.link[key])
            } else {
                let isDebug = window.location.pathname.includes('debug') ? 1 : 0;
                // @ts-ignore
                window?.ZWSApi?.ZWSOutWeb.config_getConfigInfo({
                    resourcePath,
                    isDebug
                }).then((res: any) => {
                    if (res.data?.link) {
                        const link = JSON.parse(res.data.link)
                        this.link = link
                        resolve(link[key])
                    }
                }).catch((error: any) => {
                    reject(error)
                })
            }

        })
    }
} 