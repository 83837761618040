/**
 * 设备数据
 */
export default {
  realTimeData: {
    title: 'Real time data',
    dataFormatter: 'Formatter'
  },
  historyData: {
    title: 'Historical data',
    inputMessageId: 'Please enter the message id',
    showDeleteProcess: 'The deletion progress is displayed',
    quickDeleteTip: 'The device does not support quick deletion, please go to {0} to upgrade the package.',
    usercenter: 'user center',
    attention: 'attention:',
    deleteTip:
      'Deleting historical data is a {0} operation. Once the user password is entered and confirmed, the operation cannot be terminated. Even if the operation is canceled, the data will continue to be deleted. ',
    cleanTip:
      'The data is cleared as {irreversible} operation by default, all {data} of the device will be cleared by default. Once the user password is entered for confirmation, the data will continue to be cleared even after cancellation. ',
    irreversible: 'irreversible',
    data: 'historical data and DBC data',
    deleteProcess: 'Progress of deleting historical data',
    deleteingTip: 'Deleting data from device {deletingProgressTip} from {deletingProgressTipTime}... ',
    hide: 'hide',
    error: 'Error',
    maximumTip:
      'If you delete more than 1 million pieces of data in this time range, the first 1 million pieces of data will be deleted by default. Do you want to continue? ',
    deleteDeviceErrorTip:
      'Deleting the historical data task of device {tip} failed. Please deliver the deleting historical data task again.',
    deleteingOverTip:
      'Deleting device data from {deletingProgressTip} in the time range from {deletingProgrestime}, wait for the deletion process to complete before creating a new deletion task. ',
    cleanDataSuccess: 'Clearing device data succeeded.',
    cleanDataError: 'Failed to clear device data.',
    close: 'close',
    showDeletingProcess: 'The deletion progress is displayed',
    to: 'to',
    channel: 'channel',
    deleteDataError: 'Failed to delete data',
    deleteDataSuccess: 'Deleting historical data from {0} {1} to {2} succeeded',
    editSearchTime: 'No data found, please modify query time.',
    systemTime: 'system time',
    timestamp: 'timestamp',
    hexadecimal: 'hexadecimal',
    decimalism: 'decimalism',
    binary: 'binary',
    center: 'Default (center justified)',
    left: 'SJA1000(left justified)',
    right: 'Real ID(right justified)',
    timeFormatter: 'Time format',
    messageIdFormatter: 'Message ID format',
    idJustified: 'Message ID alignment',
    dataFormatter: 'Data format',
    messageChannel: 'Channel',
    messageId: 'Message id',
    standard: 'standard',
    expand: 'expand',
    dataFrame: 'data',
    externalFrame: 'remote'
  },
  deviceStorageData: {
    title: 'Device Storage Data',
    search: 'Search',
    offlineTip: 'If the device is not online, refresh the device list to confirm that the cloud device is online and try again. ',
    recall: 'recall',
    batchRecall: 'Batch recall',
    recallProgress: 'Recall Progress',
    can400OversizeTip:
      'Your current device firmware only supports 60M file recall, if you need to recall larger files, please go to the device management page, select firmware upgrade to V1.20 or above and upgrade the package. ',
    can200OversizeTip:
      'Your current device firmware only supports 60M file recall, if you need to recall larger files, please go to the device management page, select firmware upgrade to V4.01 or above and upgrade the package. ',
    oversizeRecallTip:
      'The size of the recalled file is greater than {device_file_traffic_management} MB specified in the package. Upgrade the size of the recalled file in the user center and then perform operations. ',
    fileName: 'File Name',
    size: 'Size',
    date: 'Date',
    commandSend: 'Command Send',
    commandSending: 'Command Sending',
    fileGet: 'File Get',
    timeoutCode1: 'Failed to obtain the file, please try again (code: 001)',
    timeoutCode2: 'Request timed out, please try again (code: 002)',
    timeoutCode4: 'Request timed out, please try again (code: 004)',
    timeoutCode5: 'Device response times out, please try again (code: 005)',
    overtimeAndRuntimeTip: 'The sending of the command times out. Check the online and running status of the device and try again. ',
    recallingTip: 'The device is recalling the file. Wait until the file is recalled and then recall the file again ',
    commandSendError: 'Failed to send command, please try again!',
    firmwareVersionError: 'Failed to obtain the file because the firmware version is abnormal',
    downloadFailedTip: 'Download failed, please try again',
    startDownload: 'Start downloading',
    uploadFileOvertime: 'Upload file timeout',
    batchRecallNull: 'Select the files that you want to recall in bulk',
    can400BatchRecall:
      'Your current device firmware does not support batch file recall, if you need to recall larger files, please go to the device management page, select firmware upgrade to V1.20 or higher and upgrade the package. ',
    can200BatchRecall:
      'Your current device firmware does not support batch file recall, if you need to recall larger files, please go to the device management page, select firmware upgrade to V4.01 or higher and upgrade the package. ',
    batchRecallToBuy: 'The maximum number of recalled files has exceeded {number}, please go to the user center to upgrade the package ',
    fileGetting: 'File Getting...',
    batchRecallProcess: 'File acquisition progress ({recallFileUrlArray} / {selectionArray}) ',
    batchRecallTip: 'Volume recall not supported, please go to {0} for package upgrade.',
    batchRecallSingleTip: 'Recall progress of {number} files {singleDownloadProcess} % '
  }
}
