<template>
    <el-tooltip
            effect="light"
            :content="props.tooltipContent || props.defaultContent"
            :disabled="_disabledTooltip || !isShowToolTipByHand"
            :placement="_placement"
            v-bind="$attrs"
    >
        <template #content>
            <div class="tooltip-content">
                <slot name="tooltipSlot">{{props.tooltipContent || props.defaultContent}}</slot>
            </div>
        </template>
        <div class="default-content" :style="{maxWidth: props.width}" @mouseover="isShowTooltip">
            <span ref="contentRef">
                <slot>{{props.defaultContent}}</slot>
            </span>
        </div>
    </el-tooltip>
</template>
<script setup lang="ts">
    import { ref, withDefaults, onMounted } from 'vue'

    // 文本内容：默认插槽 || defaultContent
    // tooltip内容：tooltipSlot 插槽 || tooltipContent || defaultContent

    interface props {
        width: string, // 容器宽度，以便于超出隐藏
        defaultContent?: string, // 默认展示的文本
        tooltipContent?: string // toolTip 展示的文本
        isShowToolTipByHand?: boolean, // 【手动】控制是否需要悬浮提示
        isShowToolTipByAuto?: boolean, // 是否需要根据宽度 【自动】显示隐藏悬浮提示
        placement?: string // toolTip 方向
    }
    const props = withDefaults(defineProps<props>(), {
        defaultContent: '',
        width: '60px',
        tooltipContent: '',
        isShowToolTipByHand: true,
        isShowToolTipByAuto: true,
        placement: 'top'
    });
    const emit = defineEmits(['hoverText']);
    const _placement = ref();

    let _disabledTooltip = ref<boolean>(false); // 控制 tooltip 是否显示
    const contentRef  = ref();

    const isShowTooltip = (e: any) => {
        _placement.value = e.clientY < 400 ? 'bottom' : props.placement;
        // 超出宽度才展示 。。。 和 tooltip
        if (props.isShowToolTipByAuto) {
            _disabledTooltip.value = contentRef?.value?.parentNode.offsetWidth >= contentRef?.value?.offsetWidth;
        }
        emit('hoverText', props.defaultContent);
    }

    onMounted(() => {
        _placement.value = props.placement;
    })
</script>
<style lang="less" scoped>
    .tooltip-content {
        max-width: 600px;
        max-height: 400px;
        min-height: 18px;
        overflow: auto;
    }
    .default-content {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }
</style>
