<template>
    <el-dialog :title="$t('common.tip.downloading') || '正在下载'" v-model="progressDialogVisible" width="30%"
        :close-on-click-modal="false" :before-close="cancelClicked" center>
        <div class="z-data-download-prgress-area">
            <!-- :percentage="progressData.data_count ? (100 * progressData.output_count / progressData.data_count).toFixed(2) : 0" -->
            <el-progress :percentage="currentProcessRate">
            </el-progress>
            <!-- 暂时屏蔽 status 4时 一直为0 progressData.process_rate"></el-progress> -->
            <div class="z-data-download-prgress-area-size">
                <span v-show="progressData.status !== 3">{{ getProgressStutas(progressData.status) }}</span>
                <span v-show="progressData.status === 3">{{ Math.ceil(progressData.upload_bytes / 1024) }} KB /
                    {{ Math.ceil(progressData.data_bytes / 1024) }} KB</span>
            </div>
        </div>
    </el-dialog>
    <ZDialogCtrl :title="t('zws.ZCommon.tips.tip') || '提示'" v-model:show="cancelDialogShow" ref="addDeviceDialogRef"
        width="300px" @buttonClicked="onCancelButtonClicked" :buttonClickedClosed="false">
        <div>{{ t('zws.ZDataTransformDialogArea.cancelDownload') || '是否取消下载' }}</div>
    </ZDialogCtrl>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, watch } from 'vue'
import ZDialogCtrl, { ClickedButtonEvent } from '../../ctrl/ZDialogCtrl.vue'
import { useLocale } from '../../hooks/useLocale'

export default defineComponent({
    components: {
        ZDialogCtrl
    },
    emits: ['cancelClicked', 'finishClicked'],
    props: {
        dialogVisible: {
            type: Boolean,
        },
        data: {
            type: Object,
        },
    },
    setup(prop, context) {
        let { t } = useLocale()
        let progressDialogVisible = ref(false)
        let progressData: any = reactive({
            data_bytes: 0,
            data_path: '',
            estimated_time: 0,
            expired_time: '',
            id: 0,
            info_model_id: 0,
            process_rate: 0,
            status: 0,
            tenant_id: 0,
            things_id: 0,
            upload_bytes: 0,
            upload_start_time: '',
        })
        let finishDisabled = ref(true)
        let currentProcessRate = ref<number>(0)
        watch(
            () => prop.dialogVisible,
            (newValue, oldValue) => {
                progressDialogVisible.value = newValue
                if (!newValue) {
                    emptyProgressData()
                }
            }
        )
        watch(
            () => prop.data,
            (newValue, oldValue) => {
                progressData = Object.assign(progressData, newValue)
                // 下载进度
                setCurrentProcessRateValue(progressData)
                if (progressData.process_rate === 100) {
                    setTimeout(() => {
                        finishClicked()
                    }, 2000)
                }
            })
        function setCurrentProcessRateValue(data: any) {
            const rateValue: any = ((data.output_count / data.data_count) * 100).toFixed(2)
            if (rateValue >= 0 && rateValue <= 100) {
                currentProcessRate.value = rateValue
            }
            if (rateValue > 100) {
                currentProcessRate.value = 100
            }
        }
        function finishClicked() {
            emptyProgressData()
            progressDialogVisible.value = false
            context.emit('finishClicked', progressData)
        }
        function cancelClicked() {
            // emptyProgressData()
            // progressDialogVisible.value = false
            // context.emit('cancelClicked')
            cancelDialogShow.value = true
        }
        function emptyProgressData() {
            progressData.output_count = 0
            progressData.process_rate = 0
            progressData.upload_bytes = 0
            progressData.data_bytes = 0
            progressData.data_bytes = 0
            progressData.status = 0
            currentProcessRate.value = 0
        }
        function getProgressStutas(status: number) {
            let statusText = ''
            switch (status) {
                case 1:
                    statusText = t('zws.ZDataTransformDialogArea.statusIni')||'初始化'
                    break
                case 4:
                    statusText =  t('zws.ZDataTransformDialogArea.statusQuery')||'查询中'
                    break
                case 2:
                    statusText =  t('zws.ZDataTransformDialogArea.statusProcess')||'数据整理中'
                    break
                case 5:
                    statusText =  t('zws.ZDataTransformDialogArea.statusGenerate')||'生成中'
                    break
                case 3:
                    statusText =  t('zws.ZDataTransformDialogArea.statusDone')||'完成'
                    break
                // case 6:
                //     statusText = '不支持该格式下载'
                //     break
                default:
                    break
            }
            if (statusText !== '') {
                return statusText + '...'
            }
        }

        const cancelDialogShow = ref<boolean>(false)
        const onCancelButtonClicked = (button: ClickedButtonEvent) => {
            cancelDialogShow.value = false
            if (button.index === 1) {
                emptyProgressData()
                progressDialogVisible.value = false
                context.emit('cancelClicked')
            }
        }

        return {
            t,
            progressDialogVisible,
            currentProcessRate,
            progressData,
            finishDisabled,
            cancelClicked,
            finishClicked,
            getProgressStutas,
            cancelDialogShow,
            onCancelButtonClicked
        }
    },
})
</script>
<style lang="less" scoped>
.z-data-download-prgress-area {
    text-align: center;
}

.z-data-download-prgress-area-size {
    margin-top: 10px;
    text-align: left;
    height: 20px;
}

.z-data-download-prgress-area-finish {
    margin-top: 30px;
}
</style>
