<template>
    <div></div>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex'
import { useZThirdLoginHook } from '../../../../sdk/ts/tool/ZThirdLoginHook'
import ZAppUrlTool from '../../../../sdk/ts/tool/ZAppUrlTool'
import { ElLoading } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { getPrivilegeSelf, getSelfUserInfo, getSingleCustomization, getUsersSelfTenant } from '@zws/axios-fetch'

const store = useStore()
const router = useRouter()
const route = useRoute()

const loading = ElLoading.service({
    lock: true,
    text: '正在加载用户信息',
    background: 'rgba(0, 0, 0, 0.7)',
})
const { useThirdLogin, getQueryStrToObj } = useZThirdLoginHook()
useThirdLogin()
    .then((res: any) => {
        loading.close()
        localStorage.setItem('token', res.token)
        localStorage.setItem('ujwt', res.ujwt)
        getTenantInfo()
        getPrivilegeSelfLocal()
        getUserInfo()
    })
    .catch((err: any) => {
        if (route.query.loginUrl) {
            window.open(decodeURIComponent(route.query.loginUrl as string), '_self')
        } else if (sessionStorage.getItem('loginUrl')) {
            const loginUrl = decodeURIComponent(sessionStorage.getItem('loginUrl') as string)
            window.open(loginUrl, '_self')
        } else {
            // const origin = window.location.origin
            // if (origin.indexOf('localhost') !== -1) {
            //     window.open('http://192.168.24.11:30880/v1/web-cloud/index.html#/', '_self')
            // } else if (origin.indexOf('192.168.') !== -1) {
            //     window.open(`${origin}/v1/web-cloud/index.html#/`, '_self')
            // } else {
            //     window.open('https://zws.zlgcloud.com/web/index.html#/login', ' _self')
            // }
            ZAppUrlTool.getUrlByKey('cloud').then((res: string) => {
                window.open(res, '_self')
            })
        }
    })
const emits = defineEmits(['loginClicked'])
// 获取用户权限信息
function getPrivilegeSelfLocal() {
    getPrivilegeSelf({platform: ''})
        .then((res: any) => {
            store.commit('tenantPermissionInfo', res)
        })
        .catch((err: any) => {})
}
function getUserInfo() {
    getSelfUserInfo()
        .then((res: any) => {
            if (res.avatar) res.avatar = res.avatar + '?' + Math.random()
            store.commit('userInfo', res)
            getTenantCustomization(res)
        })
        .catch((err: any) => {})
}
function getTenantCustomization(userInfo: any) {
    getSingleCustomization({
        tenant_id: userInfo.tenant_id,
    })
        .then((res: any) => {
            let systemInfo: any = {}
            res.data.forEach((item: any) => {
                systemInfo[item.key] = item.value
            })
            store.commit('tenantCustomizationInfo', systemInfo)
            //
            const path = decodeURIComponent(route.query.prePath as string)
            if (path === route.path) {
                emits('loginClicked')
            } else {
                router.replace({
                    path: path,
                    query: getQueryStrToObj(route.query.preQuery as string),
                })
            }
        })
        .catch((error: any) => {})
}
// 获取租户信息
function getTenantInfo() {
    getUsersSelfTenant().then((result) => {
        console.log(result, 'tenantInfo');
        store.commit('tenantInfo', result)
    }).catch((err) => {
        console.log(err);
    })
}
</script>

<style lang="less" scoped>
</style>
