<template>
    <div ref="contentContRef" @mouseleave="tipBoxLeave($event)">
        <div :class="['too-tip-content-p', optionMaxWidth ? 'max-width-' + optionMaxWidth : '']" style="display: none;">
            <slot name="tooltipSlot"></slot>
        </div>
        <!-- <template #content> -->
        <div class="tooltip-content" style="display: none;">
            <slot name="tooltipSlotn">{{ tooltipContent || defaultContent}}</slot>
        </div>
        <!-- </template> -->
        <div class="default-content" :style="{ maxWidth: width }" @mouseover="tipBoxEnter($event)"
            @mouseleave="tipBoxLeave_($event)">
            <span ref="contentRef">
                <slot>{{ defaultContent }}</slot>
            </span>
        </div>
    </div>
</template>
<script>
import { createApp } from 'vue'
export default {
    props: {
        // 最大宽度，可忽略
        optionMaxWidth: {
            type: Number
        },
        width: {
            type: String,
            default: '60px'
        },
        comp: {
            type: Object
        },
        jsonData: {
            type: String,
            default: ''
        },
        tooltipContent: {
            type: String,
            default: ''
        },
        // 内容
        defaultContent: {
            type: String,
            default: ''
        },
        // 位置
        placement: {
            type: String,
            default: 'bottom'
        }
    },
    methods: {
        tipBoxEnter(e) {
            // 获取挂载的p标签e.target.parentNode.

            sessionStorage.setItem('leaveSourceSin', 'false')
            this.leave = false
            sessionStorage.setItem('JSON_TOOL_TIP', this.jsonData)
            this.$store.commit('JSON_TOOL_TIP', this.jsonData)



            var cotainer = this.$refs["contentContRef"]
            let p_Node = cotainer.querySelector('.default-content')
            // 如果文本溢出

            if (0 || p_Node.scrollWidth > p_Node.offsetWidth) {

                this.$store.commit('SH_TOOL_TIP', false)

                setTimeout(() => {
                    this.$store.commit('SH_TOOL_TIP', true)
                }, 20)
                setTimeout(() => {
                    // 初始化全局toolTip

                    var isObject = this.defaultContent && typeof this.defaultContent === 'object'
                    var content
                    if (isObject) {
                        content = createApp(this.comp)
                    }
                    this.$store.commit('INIT_TOOL_TIP', {
                        isObject: isObject,
                        node: p_Node,
                        content: content,
                        tips: this.defaultContent + '',
                        placement: this.placement
                    })

                }, 40)
                this.setLock()
            }
        },
        tipBoxLeave(e) {
            // 销毁toolTip
            this.setLock()
        },
        tipBoxLeave_(e) {
            // 销毁toolTip
            this.leave = true
            sessionStorage.setItem('leaveSourceSin', 'true')
            this.setLock()
        },
        setLock(e) {
            setTimeout(() => {
                var leave = sessionStorage.getItem('leaveSourceSin') == 'true'
                var outTipSin = sessionStorage.getItem('leaveTipSin') == 'true'
                leave && outTipSin && this.$store.commit('CLOSE_TOOL_TIP', {
                })

            }, 1540)
        }
    }
}
</script>
<style lang="scss" scoped>
.too-tip-content-p {
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

.default-content {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}
</style>