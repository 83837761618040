<template>
    <div class="device-info-label">
        <transition-group name="list" tag="div">
            <div :key="label" v-for="(label, index) in devInfoLabelList">
                <el-select v-model="label.tenantLabelId" :placeholder="$t_('zws.ZDevInfoLabelsArea.selectLabel') || '请选择租户标签'" :disabled="disabled">
                    <el-option v-for="item in tenantLabelsOptions" :key="item.id" :label="item.label" :value="item.id">
                    </el-option>
                </el-select>
                <el-input class="device-info-label-value-input" v-model="label.labelValue" :placeholder="$t_('zws.ZDevInfoLabelsArea.inputLabel') || '请输入设备标签'"
                    :disabled="disabled">
                </el-input>
                <el-button type="primary" plain @click="deleteLabelClicked(index)" :disabled="disabled">-</el-button>
            </div>
        </transition-group>
        <div style="margin-top: 10px">
            <el-button type="primary" plain @click="addLabelClicked" :disabled="disabled" v-debounce>+</el-button>

            <el-button type="primary" plain @click="saveLabelClicked" :disabled="disabled || disabledSave" v-debounce>{{ $t_('zws.ZTablePaginationCtrl.save') }}</el-button>

        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive, withDefaults, defineProps, defineEmits, defineExpose, watch, toRef } from "vue";
import Msg from '../../ctrl/ZMessage'
import { useLocale } from '../../hooks/useLocale'

const { t } = useLocale()
let $t_ = t
export interface TenantLabelsOptions {
    id: number | string,
    label: number | string
}

export interface TenantLabel {
    tenantLabelId: number
    [propName: string]: any
}

interface Props {
    devInfoLabels: any
    disabled: boolean
    disabledSave: boolean
    tenantLabelsOptions: TenantLabelsOptions[]
}

const props = withDefaults(defineProps<Props>(), {
    disabled: false
});

let devInfoLabelList: any = toRef(props, 'devInfoLabels');

const emits = defineEmits(['saveLabelClicked']);

function saveLabelClicked() {
    emits('saveLabelClicked');
}

function deleteLabelClicked(index: number) {
    devInfoLabelList.value.splice(index, 1)
}

function addLabelClicked() {
    devInfoLabelList.value.push({ tenantLabelId: '', labelValue: '', description: '' })
}

</script>

<style lang="less" scoped>
.device-info-label-value-input {
    width: 204px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
}

.list-enter-active,
.list-leave-active {
    transition: all .5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(60px);
}
</style>
