<template>
    <el-select ref="selectRef" @change="devTypeSelect" @visible-change="devTypeSelectVisibleChange" v-model="devType"
        :loading="loading" :loading-text="loadingText" :disabled="disabled" :clearable="clearable" filterable remote
        :placeholder="t('zws.ZCommon.tips.selectDevType') || '请选择设备类型'" :remote-method="remoteDevTypes"
        :popper-append-to-body="appendToBody" :popper-class="popperClass">
        <el-option-group v-for="group in devTypes" :key="group.label" :label="group.label">
            <el-option v-for="item in group.options" :key="item.id" :label="item.name" :value="item"
                :disabled="getDisabled(item)">
            </el-option>
        </el-option-group>
    </el-select>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { DevType, DevTypeSResp } from '../../../../sdk/ts/api/http/httpModules/ZWSIotMappingManager'
import ZWSApi from '../../../../sdk/ts/api/http/ZWSApi'
import ZPermissionTool from '../../../../sdk/ts/tool/ZPermissionTool'
import { useI18n } from 'vue-i18n'
import { useLocale } from '../../hooks/useLocale'


interface Options {
    label: string
    options: DevTypeOption[]
}

export interface DevTypeOption extends DevType {
    value?: string
}

export default defineComponent({
    props: {
        // 父组件v-model的入参
        modelValue: {},
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        showAll: {
            type: Boolean,
            default: false
        },
        appendToBody: {
            type: Boolean,
            default: false
        },
        placement: {
            type: Array,
            default: () => {
                return []
            }
        },
        popperClass: {
            type: String,
            default: ''
        },
        disabledReason: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        let getLanguage = function (data: any) {
            return t(data)
        }
        let { t } = useLocale()

        const $t = t
        const store = useStore()
        const api: any = ZWSApi
        const loadingText = ref<string>('')
        const loading = ref<boolean>()
        const devType = ref<DevTypeOption | null | { id: -1 }>()
        const devTypes = ref<Array<Options>>([])
        const all = ref<any>(null)

        const selectRef = ref<any>(null)
        const userInfo = computed(() => store.getters.userInfo)

        // 设备类型下拉选择
        const devTypeSelect = (curDevType: DevTypeOption) => {
            // 响应父组件v-model
            emit('update:modelValue', curDevType.id)
            // 抛出选中事件（设备类型id，设备类型对象）
            emit('typeChange', { devTypeId: curDevType.id, devType: curDevType })
        }

        const devTypeSelectVisibleChange = (visible: any) => {
            if (visible && !devType.value) {
                remoteDevTypes('');
            }
        }

        // 远程搜索
        const remoteDevTypes = (keyword: string) => {
            // if (!hasPermission('mapping:info-model:query')) return;

            let requestArr: Array<any> = [
                api.ZWSIotMappingManager.iotInfoModel_getPrivateInfoModels({ keyword, tenantId: userInfo.value.tenant_id, page_size: 100 }),
                api.ZWSIotMappingManager.iotInfoModel_getPublicInfoModels({ keyword, page_size: 100 }),
            ]
            loadingText.value = 'Loading'
            loading.value = true
            Promise.all(requestArr)
                .then((result: Array<DevTypeSResp>) => {
                    loading.value = false
                    let _devTypes: Options[] = []
                    _devTypes = [
                        {
                            label: $t('zws.ZCommon.custom') || '自定义类型',
                            options: result[0].list,
                        },
                        {
                            label: $t('zws.ZCommon.standard') || '标准类型',
                            options: result[1].list,
                        },
                    ]
                    let allIds: number[] = []
                    _devTypes.forEach((item) => {
                        item.options.forEach((it: DevTypeOption) => {
                            it.value = it.id + item.label
                            allIds.push(it.id)
                        })
                    })
                    if (props.showAll) {
                        allIds = [...new Set(allIds)]
                        let _option: any = {
                            id: allIds.join(','),
                            name: getLanguage('zws.ZCommon.all')|| '全部',
                            value: "all"
                        }
                        _devTypes.unshift({
                            label: getLanguage('zws.ZCommon.all')|| '全部',
                            options: [_option]
                        })
                    }
                    devTypes.value = _devTypes
                    if (props.modelValue && !devType.value) {
                        let signal = false
                        fori: for (let i = 0; i < devTypes.value.length; i++) {
                            for (let j = 0; j < devTypes.value[i].options.length; j++) {
                                if (devTypes.value[i].options[j].id === props.modelValue) {
                                    devType.value = devTypes.value[i].options[j]
                                    signal = true

                                    emit('typeChange', { devTypeId: devType.value.id, devType: devType.value })
                                    break fori
                                }
                            }
                        }
                        if (!signal && devTypes.value.length) {
                            devType.value = { id: -1 } // 如果没匹配到 -2 
                            emit('typeChange', { devTypeId: -2, devType: devType.value })
                        }
                    }
                })
                .catch((err: any) => {
                    console.log(err)
                    // ElMessage.error('请求设备类型错误')
                    // devTypes.value = []
                    // loadingText.value = '设备类型请求异常'
                })
        }
        let getDisabled = (item: any) => {
            let value = false
            if ('chooseSubDevForZigbee' == props.disabledReason) {
                // if (item && item.thing_type !== 0) {//禁掉网关类型
                if (item && item.name == 'LoRaWAN_GATEWAY' ||  item.name == 'CATCOM-100' ||  item.name == 'gw_zigbee') {
                    value = true
                }
            }
            return value
        }
        watch(
            () => props.modelValue,
            (newValue) => {
                if (newValue) {
                    if (newValue != devType.value?.id) {
                        let signal = false
                        fori: for (let i = 0; i < devTypes.value.length; i++) {
                            for (let j = 0; j < devTypes.value[i].options.length; j++) {
                                if (devTypes.value[i].options[j].id == newValue) {
                                    devType.value = devTypes.value[i].options[j]
                                    signal = true
                                    emit('initSelect', { devTypeId: devType.value.id, devType: devType.value })
                                    break fori
                                }
                            }
                        }

                        if (!signal && devTypes.value.length) {
                            devType.value = { id: -1 } // 如果没匹配到 -2 
                            emit('typeChange', { devTypeId: -2, devType: devType.value })
                        }
                    }
                } else devType.value = null
            },
            {
                immediate: true,
            }
        )

        onMounted(() => {
            remoteDevTypes('')
            if (props.placement && props.placement.length) {
                selectRef.value && selectRef.value.popper && (selectRef.value.popper.fallbackPlacements.splice(0, 4, ...props.placement))
            }
        })

        return {
            t,
            devType,
            devTypes,
            loading,
            loadingText,
            all,
            devTypeSelect,
            devTypeSelectVisibleChange,
            remoteDevTypes,
            selectRef,
            getDisabled
        }
    },
})
</script>

<style lang="less" scoped>
.el-select {
    width: 300px;
}
</style>