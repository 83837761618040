/**admin、cloud共用的套餐表单组件 */
<template>
    <div class="main">
        <el-form ref="packageForm" :disabled="isLock" :model="ruleForm" label-position="left" label-width="35px"
            :rules="rules">
            <!-- <el-form-item :label="`${t('zws.ZComboFormArea.devClass' || '接入设备类别')}：`">
                                    <el-radio-group v-model="ruleForm.device_type" @change="deviceTypeChange">
                                        <el-radio label="can">{{ t('zws.ZComboFormArea.canDev' || 'CAN设备') }}</el-radio>
                                        <el-radio label="iot">{{ t('zws.ZComboFormArea.iotDev' || 'IoT设备(非CAN)') }}</el-radio>
                                </el-radio-group>
                            </el-form-item> -->
            <!-- can设备套餐 -->
            <!-- <div class="packageForm" v-show="ruleForm.device_type === 'can'">

                                <div class="basicPackage">
                                    <div class="packageFormItem">
                                        <div class="packageFormItemTitle">{{ t('zws.ZComboFormArea.currentPackage' || '当前套餐') }}</div>
                                        <div class="packageFormItemContent">
                                            <p>{{ t('zws.ZDiskManagementArea.canDevNum' || 'CAN设备数') }}：{{ basePackage.can_base_things_num }}{{ t('zws.ZDiskManagementArea.ge' || '个') }}</p>
                                            <p>{{ t('zws.ZDiskManagementArea.dataStorageSpace' || '数据存储空间') }}：{{ basePackage.base_database_storage_space }}GB</p>
                                        </div>
                                    </div>
                                    <div class="packageFormItem">
                                        <div class="packageFormItemTitle">{{ t('zws.ZComboFormArea.upgradeTo' || '升级至') }}</div>
                                        <el-form-item class="packageFormItemNumber" prop="can_things_num">
                                            <el-input-number v-model="ruleForm.can_things_num" size="small" :precision="0" :step="basePackage.canThingsStep" :min="basePackage.can_base_things_num" :max="100000" @change="packageChange"></el-input-number> {{ t('zws.ZDiskManagementArea.ge' || '个') }}
                                        </el-form-item>
                                        <el-form-item class="packageFormItemNumber" prop="database_storage_space">
                                            <el-input-number v-model="ruleForm.database_storage_space" size="small" :precision="0" :step="basePackage.spaceStep" :min="basePackage.base_database_storage_space" :max="100000" @change="packageChange"></el-input-number> GB
                                        </el-form-item>
                                    </div>
                                </div>
                                <i class="el-icon-arrow-down" @click="showHigherPackage"></i>

                                <div class=" basicPackage higherPackageArea" v-if="higherPackageShow">
                                    <div class="packageFormItem">
                                        <div class="packageFormItemContent">
                                            <p>{{ t('zws.ZDiskManagementArea.limitNumDown' || '单次下载数据量') }}：{{ basePackage.single_data_export }}{{ t('zws.ZDiskManagementArea.tenThousand' || '万条') }}</p>
                                            <p>{{ t('zws.ZDiskManagementArea.storageCycle' || '数据存储周期') }}：{{ basePackage.maximum_data_retention_days }}{{ t('zws.ZDiskManagementArea.months' || '个月') }}</p>
                                            <p>{{ t('zws.ZDiskManagementArea.maxRecallFileSize' || '最大召回文件大小') }}：{{ basePackage.device_file_traffic_management }}MB</p>
                                            <p>{{ t('zws.ZDiskManagementArea.quickDelDevNum' || '快速删除数据设备数量') }}：{{ basePackage.quick_delete_things_num }}{{ t('zws.ZDiskManagementArea.ge' || '个') }}</p>
                                </div>
                            </div>
                            <div class="packageFormItem">
                                <el-form-item class="packageFormItemNumber higherAreaFirst" prop="can_things_num">
                                    <el-input-number v-model="ruleForm.single_data_export" size="small" :precision="0" :step="basePackage.dataExportStep" :min="basePackage.single_data_export" :max="100000" @change="packageChange"></el-input-number> {{ t('zws.ZDiskManagementArea.tenThousand' || '万条') }}
                                </el-form-item>
                                <el-form-item class="packageFormItemNumber" prop="can_things_num">
                                    <el-input-number v-model="ruleForm.maximum_data_retention_days" size="small" :precision="0" :step="basePackage.dataRetentionDayStep" :min="basePackage.maximum_data_retention_days" :max="100000" @change="packageChange"></el-input-number> {{ t('zws.ZDiskManagementArea.months' || '个月') }}
                                </el-form-item>
                                <el-form-item class="packageFormItemNumber" prop="can_things_num">
                                    <el-input-number v-model="ruleForm.device_file_traffic_management" size="small" :precision="0" :step="basePackage.deviceFileTrafficManagementStep" :min="basePackage.device_file_traffic_management" :max="100000" @change="packageChange"></el-input-number> MB
                                </el-form-item>
                                <el-form-item class="packageFormItemNumber" prop="can_things_num">
                                    <el-button class="quick-delete-button" size="small" @click="handleQuickDeleteThings" >
                                        {{ ruleForm.quick_delete_things_num }}</el-button> {{ t('zws.ZDiskManagementArea.ge' || '个') }}
                                </el-form-item>
                            </div>
                        </div>
                    </div> -->
            <!-- iot设备套餐 -->
            <div class="packageForm">
                <div class="basicPackage">
                    <div class="packageFormItem">
                        <div class="packageFormItemTitle">{{ t('zws.ZComboFormArea.currentPackage' || '当前套餐') }}</div>
                        <div class="packageFormItemContent">
                            <p v-if="belongPlatform === 'candtu'">
                                {{ t('zws.ZDiskManagementArea.canDevNum' || 'CAN设备数') }}：
                                {{ basePackage.can_base_things_num }}{{ t('zws.ZDiskManagementArea.ge' || '个') }}
                            </p>
                            <p v-else-if="belongPlatform === 'ies'">
                                {{ t('zws.ZDiskManagementArea.iesDevNum' || 'CAN设备数') }}：
                                {{ basePackage.ies_base_things_num }}{{ t('zws.ZDiskManagementArea.ge' || '个') }}
                            </p>
                            <p v-else>
                                {{ t('zws.ZDiskManagementArea.iotDevNum' || 'IoT设备数') }}：
                                {{ basePackage.iot_base_things_num }}{{ t('zws.ZDiskManagementArea.ge' || '个') }}</p>
                            <p>{{ t('zws.ZDiskManagementArea.dataStorageSpace' || '数据空间') }}：{{
                                basePackage.base_database_storage_space }}GB</p>
                            <p>{{ t('zws.ZDiskManagementArea.dataStorageService' || '数据存储服务') }}：
                                {{ storageText }}</p>
                            <p v-if="ruleForm.special_storage == 2">{{ t('zws.ZDiskManagementArea.quickDelDevNum' || '快速删除数据设备数量') }}：{{
                                basePackage.quick_delete_things_num }}{{ t('zws.ZDiskManagementArea.ge' || '个') }}</p>
                        </div>
                    </div>
                    <div class="packageFormItem">
                        <div class="packageFormItemTitle">{{ t('zws.ZComboFormArea.upgradeTo' || '升级至') }}</div>
                        <el-form-item v-if="belongPlatform === 'candtu'" class="packageFormItemNumber"
                            prop="can_things_num">
                            <el-input-number v-model="ruleForm.can_things_num" size="small" :precision="0"
                                :step="basePackage.canThingsStep" :min="basePackage.can_base_things_num" :max="100000"
                                @change="packageChange"></el-input-number> {{
                                    t('zws.ZDiskManagementArea.ge' || '个') }}
                        </el-form-item>
                        <el-form-item v-else-if="belongPlatform === 'ies'" class="packageFormItemNumber"
                            prop="ies_things_num">
                            <el-input-number v-model="ruleForm.ies_things_num" size="small" :precision="0"
                                :step="basePackage.iesThingsStep" :min="basePackage.ies_base_things_num" :max="100000"
                                @change="packageChange"></el-input-number> {{
                                    t('zws.ZDiskManagementArea.ge' || '个') }}
                        </el-form-item>
                        <el-form-item v-else class="packageFormItemNumber" prop="things_num">
                            <el-input-number v-model="ruleForm.things_num" size="small" :precision="0"
                                :step="basePackage.iotThingsStep" :min="basePackage.iot_base_things_num" :max="100000"
                                @change="packageChange">
                            </el-input-number> {{ t('zws.ZDiskManagementArea.ge' || '个') }}
                            <!-- <el-icon @click="handleShowTipsClick"><question-filled /></el-icon> -->
                            <el-tooltip
                                effect="light"
                                :content="t('zws.ZDiskManagementArea.iotTip')"
                                placement="top"
                            >
                            <el-icon><question-filled /></el-icon>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item class="packageFormItemNumber" prop="database_storage_space">
                            <el-input-number v-model="ruleForm.database_storage_space" size="small" :precision="0"
                                :step="basePackage.spaceStep" :min="basePackage.base_database_storage_space" :max="100000"
                                @change="packageChange('specialStorage')"></el-input-number> GB
                        </el-form-item>
                        <el-form-item class="packageFormItemNumber" prop="can_things_num">
                            <el-radio-group v-model="ruleForm.special_storage" @change="specialStorageChange">
                                <el-radio :disabled="generalDisabled" :label="0">{{ t('zws.ZDiskManagementArea.general') }}</el-radio>
                                <el-radio v-if="belongPlatform === 'candtu'" :label="2">{{ t('zws.ZDiskManagementArea.deviceFastDelete') }}</el-radio>
                                <el-radio :label="1">{{ t('zws.ZDiskManagementArea.only') }}</el-radio>
                            </el-radio-group>
                            <el-icon @click="handleShowTipsClick"><question-filled /></el-icon>
                        </el-form-item>
                        <el-form-item v-if="ruleForm.special_storage == 2" class="packageFormItemNumber" prop="can_things_num">
                            <el-button class="quick-delete-button" size="small" @click="handleQuickDeleteThings">
                                {{ ruleForm.quick_delete_things_num }}</el-button> {{ t('zws.ZDiskManagementArea.ge' || '个') }}
                        </el-form-item>
                    </div>
                </div>
                <i class="el-icon-arrow-down" @click="showHigherPackage"></i>
                <!-- 高级套餐 -->
                <div class=" basicPackage higherPackageArea" v-if="higherPackageShow">
                    <div class="packageFormItem">
                        <div class="packageFormItemContent">
                            <p>{{ t('zws.ZDiskManagementArea.limitNumDown' || '单次下载数据量') }}：{{
                                basePackage.single_data_export }}{{ t('zws.ZDiskManagementArea.tenThousand' || '万条') }}</p>
                            <p>{{ t('zws.ZDiskManagementArea.storageCycle' || '数据存储周期') }}：{{
                                basePackage.maximum_data_retention_days }}{{ t('zws.ZDiskManagementArea.months' || '个月') }}
                            </p>
                            <p>{{ t('zws.ZDiskManagementArea.maxRecallFileSize' || '最大召回文件大小') }}：{{
                                basePackage.device_file_traffic_management }}MB</p>
                            <p>{{ t('zws.ZDiskManagementArea.maxBatchRecallSize' || '最大召回文件大小') }}：{{
                                basePackage.max_batch_recall_size }}个</p>
                            
                            
                        </div>
                    </div>
                    <div class="packageFormItem">
                        <el-form-item class="packageFormItemNumber higherAreaFirst" prop="can_things_num">
                            <el-input-number v-model="ruleForm.single_data_export" size="small" :precision="0"
                                :step="basePackage.dataExportStep" :min="basePackage.single_data_export" :max="100000"
                                @change="packageChange"></el-input-number> {{
                                    t('zws.ZDiskManagementArea.tenThousand' || '万条') }}
                        </el-form-item>
                        <el-form-item class="packageFormItemNumber" prop="can_things_num">
                            <el-input-number v-model="ruleForm.maximum_data_retention_days" size="small" :precision="0"
                                :step="basePackage.dataRetentionDayStep" :min="basePackage.maximum_data_retention_days"
                                :max="100000" @change="packageChange('specialStorage')"></el-input-number> {{
                                    t('zws.ZDiskManagementArea.months' || '个月') }}
                        </el-form-item>
                        <el-form-item class="packageFormItemNumber" prop="can_things_num">
                            <el-input-number v-model="ruleForm.device_file_traffic_management" size="small" :precision="0"
                                :step="basePackage.deviceFileTrafficManagementStep"
                                :min="basePackage.device_file_traffic_management" :max="100000"
                                @change="packageChange"></el-input-number> MB
                        </el-form-item>
                        <el-form-item class="packageFormItemNumber" prop="can_things_num">
                            <el-input-number v-model="ruleForm.max_batch_recall_size" size="small" :precision="0"
                                :step="basePackage.deviceBatchRecallManagementStep"
                                :min="basePackage.max_batch_recall_size" :max="50"
                                @change="packageChange"></el-input-number> 个
                        </el-form-item>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
    <ZDialogCtrl top="10vh" v-model="selectDeviceShow" width="1000px" :title="t('zws.ZDiskManagementArea.selectDevice')"
        @button-clicked="handleSelectDeviceButtonClicked">
        <div class="search-dev-name-container">
            <span>{{ t('zws.ZCapacitystatisticsTableArea.devName') }}：</span>
            <el-input class="search-dev-name-input" v-model="devName" />
            <el-button @click="handleSearchDevButton()" type="primary">{{ t('zws.ZTablePaginationCtrl.search') }}</el-button>
        </div>
        <ZTablePaginationCtrl ref="tablePaginationRef" v-model="tablePaginationValue" :tableHeader="tableHeader"
            :tableData="devTableData" row-key="id" :reserve-selection="true" @paginationChange="handlePaginationSizeChange"
            @selection-change="handleSelectionChange">
        </ZTablePaginationCtrl>
        <div class="selected-dev-container">
            <span>{{ t('zws.ZDiskManagementArea.selected') }}: {{ ruleForm.quick_delete_things_list.length }}</span>
        </div>
        <ZTablePaginationCtrl :table-header="selectedTableHeader" :table-data="ruleForm.quick_delete_things_list"
            out-height="200"></ZTablePaginationCtrl>
    </ZDialogCtrl>
    <ZDialogCtrl top="10vh" v-model:show="showTipsDialog" width="600px" :title="t('zws.ZDiskManagementArea.showTipsTitle')" :buttonList="showTipsButtonList">
        <div class="item-container" v-for="item in storageTipsArray">
            <div class="item-sub-title">{{ item.key }}</div>
            <div class="item-row">
                <div class="row-key">{{ t('zws.ZDiskManagementArea.featureIntroduction') }}</div>
                <div class="row-value">
                    {{ item.value }}<span v-if="item.strong" class="strong">{{ item.strong }}</span>
                </div>
            </div>
            <div class="item-row">
                <div class="row-key">{{ t('zws.ZDiskManagementArea.applicableScene') }}</div>
                <div class="row-value">{{ item.tips }}</div>
            </div>
        </div>
    </ZDialogCtrl>
</template>

<script setup lang="ts">
import { ref, reactive, defineEmits, defineProps, computed, watch } from 'vue'
import { QuestionFilled } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import ZDialogCtrl from '../../ctrl/ZDialogCtrl.vue'
import ZMessage from '../../ctrl/ZMessage';
import ZTablePaginationCtrl from '../../ctrl/ZTablePaginationCtrl.vue'
import { useLocale } from '../../hooks/useLocale'
import {
    getBatchThingsWithoutPage,
    getBatchTenantInfomodels,
    getBatchThings,
} from '@zws/axios-fetch'


interface RuleForm {
    device_type: string
    can_things_num: number
    ies_things_num: number
    things_num: number
    database_storage_space: number
    single_data_export: number
    maximum_data_retention_days: number
    device_file_traffic_management: number
    max_batch_recall_size: number
    special_storage: number
    quick_delete_things_num: number
    quick_delete_things_list: any[]
    //以下参数暂未开放配置功能
    alert_rule: number
    alert_sms: number
    alert_tps: number
    data_analysis: number
    edge_computing: number
    hmi: number
    info_model: number
    label_num: number
    maximum_total_number_documents_stored: number
    number_simultaneous_connections: number
    platform: number
    push_rule: number
    push_tps: number
    sub_user_num: number
    twins_rule_on: number
    up_down_stream_connections: number
}



let { t } = useLocale()
const emit = defineEmits(['form-change'])
const prop: any = defineProps({
    subData: Object,
    isLock: {
        type: Boolean,
        default: false,
    },
    // 套餐有效时间
    packageTimeObj: {
        type: Object
    }
})

const store = useStore()
const userInfo = computed(() => store.getters.userInfo)
const belongPlatform = computed(() => store.getters.belongPlatform)

const basePackage = reactive<any>({
    // ${type}_base 为一份套餐的设备和数据储存空间值
    can_base_things_num: 2,
    ies_base_things_num: 2,
    iot_base_things_num: 10,
    base_database_storage_space: 2,
    single_data_export: 100,
    maximum_data_retention_days: 2,
    canThingsStep: 1,
    iesThingsStep: 1,
    iotThingsStep: 1,
    spaceStep: 1,
    dataExportStep: 1,
    dataRetentionDayStep: 1,
    canThingsBasic: 1,
    iesThingsBasic: 1,
    iotThingsBasic: 1,
    spaceBasic: 1,
    dataExportBasic: 1,
    dataRetentionDayBasic: 1,
    device_file_traffic_management: 60,
    max_batch_recall_size: 1,
    special_storage: 0,
    quick_delete_things_num: 0,
    quick_delete_things_list: [],
    quickDeleteThingsNumBasic: 0,
    quickDeleteThingsListBasic: [],
    deviceFileTrafficManagementBasic: 1,
    deviceFileTrafficManagementStep: 1,
    deviceBatchRecallManagementBasic: 1,
    deviceBatchRecallManagementStep: 1,
    specialStorageBasic: 0
})
const ruleForm = reactive<RuleForm>({
    device_type: 'can',
    can_things_num: 10,
    ies_things_num: 1,
    things_num: 1000,
    database_storage_space: 2,
    single_data_export: 100,
    maximum_data_retention_days: 2,
    device_file_traffic_management: 0,
    max_batch_recall_size: 0,
    special_storage: 0,
    quick_delete_things_num: 0,
    quick_delete_things_list: [],
    //以下参数暂未开放配置功能
    alert_rule: 0,
    alert_sms: 0,
    alert_tps: 0,
    data_analysis: 0,
    edge_computing: 0,
    hmi: 0,
    info_model: 0,
    label_num: 0,
    maximum_total_number_documents_stored: 0,
    number_simultaneous_connections: 0,
    platform: 0,
    push_rule: 0,
    push_tps: 0,
    sub_user_num: 0,
    twins_rule_on: 0,
    up_down_stream_connections: 0,
})

const rules = reactive({
    // can_things_num: [
    //     {
    //         validator: (rule: any, value: any, callback: any) => {
    //             if (value % 2 !== 0) callback(new Error('需要为2的倍数'))
    //             callback()
    //         },
    //         trigger: 'change',
    //     },
    // ],
    // iot_things_num: [
    //     {
    //         validator: (rule: any, value: any, callback: any) => {
    //             if (value % 10 !== 0) callback(new Error('需要为10的倍数'))
    //             callback()
    //         },
    //         trigger: 'change',
    //     },
    // ],
    // database_storage_space: [
    //     {
    //         validator: (rule: any, value: any, callback: any) => {
    //             if (value % 2 !== 0) callback(new Error('需要为2的倍数'))
    //             callback()
    //         },
    //         trigger: 'change',
    //     },
    // ],
})

const packageForm = ref()

function deviceTypeChange() {
    ruleForm.things_num = basePackage.iot_base_things_num
    ruleForm.can_things_num = basePackage.can_base_things_num
    ruleForm.ies_things_num = basePackage.ies_base_things_num
    ruleForm.database_storage_space = basePackage.base_database_storage_space
    ruleForm.single_data_export = basePackage.single_data_export
    ruleForm.maximum_data_retention_days = basePackage.maximum_data_retention_days
    ruleForm.device_file_traffic_management = basePackage.device_file_traffic_management
    ruleForm.max_batch_recall_size = basePackage.max_batch_recall_size
    ruleForm.special_storage = basePackage.special_storage
    ruleForm.quick_delete_things_num = basePackage.quick_delete_things_num
    ruleForm.quick_delete_things_list = basePackage.quick_delete_things_list
    packageNumChangeTimerHandle(false)
}

function packageChange(type?: string) {
    packageForm.value.validate((valid: boolean) => {
        if (valid) {
            packageNumChangeTimerHandle(ruleForm, type)
        } else {
            packageNumChangeTimerHandle(false)
        }
    })
}

// 数据存储服务变化
const specialStorageChange = (val: number) => {
    console.log(ruleForm, 'ruleForm', val);
    packageForm.value.validate((valid: boolean) => {
        if (valid) {
            if (val == 2) {
                ruleForm.quick_delete_things_num = basePackage.quick_delete_things_num
                ruleForm.quick_delete_things_list = basePackage.quick_delete_things_list
            } else {
                ruleForm.quick_delete_things_num = 0
                ruleForm.quick_delete_things_list = []
            }
            packageNumChangeTimerHandle(ruleForm)
        } else {
            packageNumChangeTimerHandle(false)
        }
    })
}

let timer: NodeJS.Timer | null = null
function packageNumChangeTimerHandle(ruleForm: RuleForm | boolean, type?: string) {
    clearTimeout(Number(timer))
    timer = setTimeout(() => {
        // let _ruleForm = typeof ruleForm === 'boolean' ? ruleForm : {
        //     ...ruleForm,
        //     // 快删不是专属存储
        //     special_storage: ruleForm.special_storage == 2 ? 0 : ruleForm.special_storage,
        //     quick_delete_things_list: ruleForm.quick_delete_things_list.map(item => ({
        //         third_things_id: item.third_things_id,
        //         info_model_name: item.info_model_name,
        //         info_model_id: item.info_model_id
        //     }))
        // }
        let _ruleForm
        if (typeof ruleForm === 'boolean') {
            _ruleForm = ruleForm
        } else {
            if (type === 'specialStorage' && belongPlatform.value !== 'candtu' && ruleForm.special_storage === 0 && (ruleForm.maximum_data_retention_days > 3 || ruleForm.database_storage_space > 2)) {
                // 特判非can平台升级空间和存储时间
                ruleForm.special_storage = 1
                return packageChange()
            } else if (ruleForm.special_storage == 2 && ruleForm.quick_delete_things_num === 0) {
                // 没有选择快删设备
                _ruleForm = false
            } else {
                _ruleForm = {
                    ...ruleForm,
                    // 快删不是专属存储
                    special_storage: ruleForm.special_storage == 2 ? 0 : ruleForm.special_storage,
                    quick_delete_things_list: ruleForm.quick_delete_things_list.map(item => ({
                        third_things_id: item.third_things_id,
                        info_model_name: item.info_model_name,
                        info_model_id: item.info_model_id
                    }))
                }
            }
        }
        
        emit('form-change', _ruleForm)
    }, 200)
}

/** 设置表单初始数据 */
let hasBuy = computed(() => {
    if (
        basePackage.can_base_things_num !== basePackage.canThingsBasic ||
        basePackage.ies_base_things_num !== basePackage.iesThingsBasic ||
        basePackage.iot_base_things_num !== basePackage.iotThingsBasic ||
        basePackage.base_database_storage_space !== basePackage.spaceBasic ||
        basePackage.maximum_data_retention_days !== basePackage.dataRetentionDayBasic / 30 ||
        basePackage.single_data_export !== basePackage.dataExportBasic / 10000 ||
        basePackage.device_file_traffic_management !== basePackage.deviceFileTrafficManagementBasic ||
        basePackage.max_batch_recall_size !== basePackage.deviceBatchRecallManagementBasic ||
        basePackage.special_storage !== basePackage.specialStorageBasic ||
        basePackage.quick_delete_things_num !== basePackage.quickDeleteThingsNumBasic ||
        basePackage.quick_delete_things_list.length
    ) {
        return true
    } else {
        return false
    }
})
const setFormData = (formData: RuleForm) => {
    let specialStorage = formData.special_storage
    if (specialStorage === 0 && formData.quick_delete_things_num > 0) {
        specialStorage = 2
    }
    ruleForm.ies_things_num = basePackage.ies_base_things_num = formData.ies_things_num
    ruleForm.can_things_num = basePackage.can_base_things_num = formData.can_things_num
    ruleForm.things_num = basePackage.iot_base_things_num = formData.things_num
    ruleForm.database_storage_space = basePackage.base_database_storage_space = formData.database_storage_space
    ruleForm.single_data_export = basePackage.single_data_export = formData.single_data_export / 10000
    ruleForm.maximum_data_retention_days = basePackage.maximum_data_retention_days = formData.maximum_data_retention_days / 30
    ruleForm.special_storage = basePackage.special_storage = specialStorage
    ruleForm.device_file_traffic_management = basePackage.device_file_traffic_management = formData.device_file_traffic_management
    ruleForm.max_batch_recall_size = basePackage.max_batch_recall_size = formData.max_batch_recall_size
    ruleForm.quick_delete_things_num = basePackage.quick_delete_things_num = formData.quick_delete_things_num
    ruleForm.quick_delete_things_list = basePackage.quick_delete_things_list = formData.quick_delete_things_list
    //以下参数页面上未开放配置，因此仅需要存到ruleForm中
    ruleForm.alert_rule = formData.alert_rule
    ruleForm.alert_sms = formData.alert_sms
    ruleForm.alert_tps = formData.alert_tps
    ruleForm.data_analysis = formData.data_analysis
    ruleForm.edge_computing = formData.edge_computing
    ruleForm.hmi = formData.hmi
    ruleForm.info_model = formData.info_model
    ruleForm.label_num = formData.label_num
    ruleForm.maximum_total_number_documents_stored = formData.maximum_total_number_documents_stored
    ruleForm.number_simultaneous_connections = formData.number_simultaneous_connections
    ruleForm.platform = formData.platform
    ruleForm.push_rule = formData.push_rule
    ruleForm.push_tps = formData.push_tps
    ruleForm.sub_user_num = formData.sub_user_num
    ruleForm.twins_rule_on = formData.twins_rule_on
    ruleForm.up_down_stream_connections = formData.up_down_stream_connections
    selectedArray = []
    if (formData.quick_delete_things_list && formData.quick_delete_things_list.length) {
        let idsArr = formData.quick_delete_things_list.map(item => {
            return `${item.info_model_id},${item.third_things_id}`
        })
        let things_ids = idsArr.join('|')
        // 隐藏被删除的设备
        // getBatchThingsWithoutPage({ tenant_id: userInfo.value.tenant_id, things_ids, type: 1 })
        //     .then((result: any[]) => {
        //         console.log(result, 'result');
        //         ruleForm.quick_delete_things_list = basePackage.quick_delete_things_list = result.map((item, index) => {
        //             selectedArray.push(item.id)
        //             return {
        //                 devName: item.name,
        //                 id: item.id,
        //                 third_things_id: item.third_things_id,
        //                 info_model_name: ruleForm.quick_delete_things_list[index].info_model_name,
        //                 info_model_id: item.info_model_id
        //             }
        //         })
        //     }).catch((err: any) => {
        //         console.log(err);

        //     });
        const allDevObject = {}
        getBatchThingsWithoutPage({ tenant_id: userInfo.value.tenant_id, things_ids, type: 1 })
            .then((result: any[]) => {
                result.forEach((item: any) => {
                    allDevObject[`${item.info_model_id},${item.third_things_id}`] = item
                })
            }).catch((err: any) => {
                console.log(err);
            }).finally(() => {
                ruleForm.quick_delete_things_list = basePackage.quick_delete_things_list = formData.quick_delete_things_list.map((item) => {
                    const _devObj = allDevObject[`${item.info_model_id},${item.third_things_id}`]
                    if (_devObj) {
                        selectedArray.push(_devObj.id)
                    }
                    return {
                        devName: _devObj?.name,
                        id: _devObj?.id,
                        third_things_id: item.third_things_id,
                        info_model_name: item.info_model_name,
                        info_model_id: item.info_model_id
                    }
                })
            })
    }

}

let higherPackageShow = ref<boolean>(false)
function showHigherPackage() {
    higherPackageShow.value = !higherPackageShow.value
}

function getPublicPackages(packageList: any) {
    packageList.map((item: any) => {
        switch (item.key) {
            case 'thingsNum':
                basePackage.iotThingsStep = item.step
                basePackage.iotThingsBasic = item.basic
                break
            case 'canThingsNum':
                basePackage.canThingsStep = item.step
                basePackage.canThingsBasic = item.basic
                break
            case 'iesThingsNum':
                basePackage.iesThingsStep = item.step
                basePackage.iesThingsBasic = item.basic
                break
            case 'maximumDataRetentionDays':
                basePackage.dataRetentionDayStep = item.step / 30
                basePackage.dataRetentionDayBasic = item.basic
                break
            case 'singleDataExport':
                basePackage.dataExportStep = item.step / 10000
                basePackage.dataExportBasic = item.basic
                break
            case 'databaseStorageSpace':
                basePackage.spaceStep = item.step
                basePackage.spaceBasic = item.basic
                break
            case 'deviceFileTrafficManagement':
                basePackage.deviceFileTrafficManagementBasic = item.basic
                basePackage.deviceFileTrafficManagementStep = item.step
                break
            case 'maxBatchRecallSize':
                basePackage.deviceBatchRecallManagementBasic = item.basic
                basePackage.deviceBatchRecallManagementStep = item.step
                break
            case 'quickDeleteThingsNum':
                basePackage.quickDeleteThingsNumBasic = item.basic
                break
            case 'specialStorage':
                basePackage.specialStorageBasic = item.basic
                break
            default:
                break
        }
    })
}

let quickDeleteDevArray: any[] = []
let selectedArray: number[] = []
const selectDeviceShow = ref<boolean>(false)
const tablePaginationRef = ref<any>()
const handleQuickDeleteThings = (e: any) => {
    let target = e.target
    if (target.nodeName == "SPAN" || target.nodeName == 'I') {
        target = target.parentNode
    }
    target.blur()
    devName.value = ""
    queryObj = {}
    tablePaginationValue.value.currentPage = 1
    tablePaginationValue.value.pageSize = 5
    // getBatchPublicInfomodels({ current_page: 1, page_size: 20, clean_type: 2 })
    getBatchTenantInfomodels({ current_page: 1, page_size: 20, clean_type: 2, tenant_id: userInfo.value.tenant_id, to_public: true })
        // window.ZWSApi.ZWSMappingMgmt.iotInfoModel_getBatchPublicInfomodels({ current_page: 1, page_size: 20, clean_type: 2 })
        .then((result: any) => {
            quickDeleteDevArray = result?.list?.map((item: any) => item.id)
            getDevList()
        }).catch((err: any) => {
            console.log(err);
        });
    selectDeviceShow.value = true
}
const handleSelectDeviceButtonClicked = (val: any) => {
    if (val.index === 1) {
        ruleForm.quick_delete_things_num = ruleForm.quick_delete_things_list.length
        packageChange()
    }
    selectDeviceShow.value = false
}
let queryObj: any = {}
const devName = ref<string>("")
const handleSearchDevButton = () => {
    queryObj = {
        prefix: devName.value || undefined
    }
    tablePaginationValue.value.currentPage = 1
    getDevList(tablePaginationValue.value.currentPage, tablePaginationValue.value.pageSize)
}
const tableHeader = computed(() => [
    {
        label: '',
        prop: '',
        width: '60',
        type: 'selection',
        align: 'center',
        "reserve-selection": true,
        selectable: (row: any, index: number) => {
            if (selectedArray.includes(row.id)) {
                return false
            } else {
                return true
            }
        }
    },
    {
        label: t('zws.ZCapacitystatisticsTableArea.order'),
        prop: 'index',
        width: '60',
        align: 'center',
    },
    {
        label: t('zws.ZCapacitystatisticsTableArea.devName'),
        prop: 'devName',
        align: 'center',
    },
    {
        label: t('zws.ZCapacitystatisticsTableArea.devType'),
        prop: 'info_model_name',
        align: 'center'
    },
    {
        label: t('zws.ZCapacitystatisticsTableArea.devId'),
        prop: 'third_things_id',
        align: 'center',
    },
])
const tablePaginationValue = ref<any>({
    currentPage: 1,
    pageSize: 5,
    pageSizes: [5, 10, 15, 20, 25],
})
const devTableData = ref<any[]>([])
const handlePaginationSizeChange = (val: any) => {
    getDevList(val.currentPage, val.pageSize)
}
const handleSelectionChange = (val: any[]) => {
    ruleForm.quick_delete_things_list = JSON.parse(JSON.stringify(basePackage.quick_delete_things_list))
    val.forEach(item => {
        if (!selectedArray.includes(item.id)) {
            ruleForm.quick_delete_things_list.push({
                devName: item.devName,
                id: item.id,
                third_things_id: item.third_things_id,
                info_model_name: item.info_model_name,
                info_model_id: item.info_model_id
            })
        }
    })
}
const getDevList = (current_page: number = 1, page_size: number = 5) => {
    getBatchThings({ current_page, page_size, tenant_id: userInfo.value.tenant_id, info_model_ids: quickDeleteDevArray.join(','), ...queryObj })
        // window.ZWSApi.ZWSThings.things_getThings({ current_page, page_size, tenant_id: userInfo.value.tenant_id, info_model_ids: quickDeleteDevArray.join(','), ...queryObj })
        .then((result: any) => {
            tablePaginationValue.value.total = result.pagination.total_size
            let _selectDevArray: any[] = []
            devTableData.value = result.list.map((item: any) => {
                let _data = {
                    devName: item.name,
                    id: item.id,
                    third_things_id: item.third_things_id,
                    info_model_name: item.info_model_name,
                    info_model_id: item.info_model_id
                }
                if (selectedArray.includes(item.id)) _selectDevArray.push(_data)
                return _data
            })
            _selectDevArray.forEach(item => {
                tablePaginationRef.value.multipleTable.toggleRowSelection(item, undefined)
            })
        }).catch((err: any) => {
            console.log(err);
            ZMessage.message.error(t('zws.ZCapacitystatisticsTableArea.getDevFail'))
        });
}
const selectedTableHeader = computed(() => [
    {
        label: t('zws.ZCapacitystatisticsTableArea.order'),
        prop: 'index',
        width: '60',
        align: 'center',
    },
    {
        label: t('zws.ZCapacitystatisticsTableArea.devName'),
        prop: 'devName',
        align: 'center',
    },
    {
        label: t('zws.ZCapacitystatisticsTableArea.devType'),
        prop: 'info_model_name',
        align: 'center'
    },
    {
        label: t('zws.ZCapacitystatisticsTableArea.devId'),
        prop: 'third_things_id',
        align: 'center',
    },
])
const storageText = computed(() => {
    let _data_storage_service = t('zws.ZDiskManagementArea.general')
    if (basePackage?.special_storage === 1) {
        _data_storage_service = t('zws.ZDiskManagementArea.only')
    } else if (basePackage?.quick_delete_things_num) {
        _data_storage_service = t('zws.ZDiskManagementArea.deviceFastDelete')
    }
    return _data_storage_service
})
const showTipsDialog = ref<boolean>(false)
const handleShowTipsClick = () => {
    showTipsDialog.value = true
}
const showTipsButtonList = [
    {
        text: t('zws.ZDiskManagementArea.close'),
        type: 'info',
        plain: true
    }
]

const storageTipsArray = computed(() => {
    const _arr = [
        {
            key: t('zws.ZDiskManagementArea.general'),
            value: t('zws.ZDiskManagementArea.generalValue'),
            tips: t('zws.ZDiskManagementArea.generalTips'),
            strong: ""
        }
    ]
    if (belongPlatform.value === 'candtu') {
        _arr.push({
            key: t('zws.ZDiskManagementArea.deviceFastDelete'),
            value: t('zws.ZDiskManagementArea.deviceFastDeleteValue'),
            tips: t('zws.ZDiskManagementArea.deviceFastDeleteTips'),
            strong: t('zws.ZDiskManagementArea.deviceFastDeleteStrong')
        })
    }
    _arr.push({
        key: t('zws.ZDiskManagementArea.only'),
        value: t('zws.ZDiskManagementArea.onlyValue'),
        tips: t('zws.ZDiskManagementArea.onlyTips'),
        strong: t('zws.ZDiskManagementArea.onlyStrong')
    })
    return _arr
})

const generalDisabled = computed(() => {
    return belongPlatform.value !== 'candtu' && (ruleForm.maximum_data_retention_days > 3 || ruleForm.database_storage_space > 2)
})

defineExpose({ setFormData, getPublicPackages })
</script>

<style lang="less" scoped>
.main {
    margin: 20px;

    :deep(.el-form-item__content) {
        text-align: left;
    }
}

.packageForm {
    width: 788px;
    // display: flex;
    border: 1px solid #ddd;
    padding: 20px;
}

.basicPackage {
    display: flex;
}

.packageFormItemTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.packageFormItem {
    flex: 1;
}

.packageFormItemContent {
    font-size: 14px;

    &>p {
        margin-top: 30px;
        margin-bottom: 44px;
    }
}

.packageFormItemNumber {
    padding-left: 10px;
    .el-input-number {
        width: 260px;
    }
    .el-radio {
        margin-right: 10px;
    }
    .el-icon {
        margin-left: 6px;
        margin-bottom: -10px;
        cursor: pointer;
    }
}

.el-icon-arrow-down {
    cursor: pointer;
}

.higherPackageArea {
    .higherAreaFirst {
        padding: 20px 0 0 10px;
    }
}

.quick-delete-button {
    width: 260px;
}

.search-dev-name-container {
    text-align: left;
    margin-bottom: 1em;

    .search-dev-name-input {
        width: 240px;
        margin-right: 0.5em;
    }
}

.selected-dev-container {
    text-align: left;
    margin: 2em 0 1em;
}
.item-row {
    display: flex;
    .row-value {
        flex: 1;
        // max-width: 350px;
        text-overflow: ellipsis;
        text-align: left;
    }
    .strong {
        color: red;
    }
    .row-key {
        width: 70px;
        font-weight: bold;
    }
}
.item-container {
    margin-bottom: 10px;
    .item-sub-title {
        text-align: left;
        font-weight: bold;
    }
}
.item-title {
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
}
</style>
