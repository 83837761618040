<template>
    <el-dialog :title="t('zws.ZCommon.tips.pwdTitle')" v-model="dialogVisible" width="480px" :before-close="cancelClicked"
        :close-on-click-modal="false" center>
        <div v-loading="loading">
            <el-form ref="validateFormRef" class="validate-form" label-position="left"
                :label-width="$i18n.locale === 'en' ? '130px' : '90px'" :model="loginInfo" :rules="rules" v-if="dialogVisible">
                <el-form-item :label="t('zws.ZCommon.info.userPwd')" prop="password" :error="errorMsg" key="inputpassword">
                    <ZInputPasswordArea v-model="loginInfo.password" :placeholder="t('zws.ZCommon.tips.password')"
                        auto-complete="off" :hasPrefix="false">
                    </ZInputPasswordArea>
                </el-form-item>
            </el-form>
            <slot name="tips"></slot>
            <div class="handle-buttons">
                <ZButtonListCtrl @buttonClicked="buttonClicked"></ZButtonListCtrl>
            </div>
        </div>
    </el-dialog>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, watch, nextTick } from 'vue'
import ZButtonListCtrl from '../../ctrl/ZButtonListCtrl.vue'
import ZWSApi from '../../../../sdk/ts/api/http/ZWSApi'
import { ZValidateDataTool as ZValidateTool } from '@zws/tools'
import ZInputPasswordArea from './ZInputPasswordArea.vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useLocale } from '../../hooks/useLocale'
export default defineComponent({
    emits: ['update:modelValue', 'confirm', 'cancel'],
    components: {
        ZButtonListCtrl,
        ZInputPasswordArea,
    },
    props: {
        modelValue: {},
        loading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: ''
        }
    },
    setup(prop, context) {
        let { t } = useLocale()
        let getLanguage = function (data: any) {

            return t(data)
        }
        let validateFormRef: any = ref('')
        let store = useStore()
        let userInfo = store.getters.userInfo
        let tenantPermissionInfo = store.getters.tenantPermissionInfo

        let dialogVisible: any = ref(false)
        let api: any = ZWSApi
        let loginInfo: any = reactive({
            username: '',
            password: '',
            type: 1,
        })
        let errorMsg = ref<string>('')
        let validatePassword = (rule: any, value: any, callback: Function) => {
            if (!ZValidateTool.passwordValidator(value)) {

                return callback(new Error(getLanguage('zws.ZCommon.tips.pwdTip')))
            } else {
                callback()
            }
        }
        let rules = {
            password: [
                { required: true, message: getLanguage('zws.ZCommon.tips.pwdNotNull'), trigger: 'blur' },
                // { validator: validatePassword, trigger: 'blur' },
            ],
        }

        watch(
            () => prop.modelValue,
            (newValue, oldValue) => {
                dialogVisible.value = newValue
                // !prop.modelValue && validateFormRef.value.resetFields()
                if (!prop.modelValue) {
                    validateFormRef.value.resetFields()
                    errorMsg.value = ""
                }
            }
        )

        init()

        function init() {
            loginInfo.username = userInfo.name
        }
        function buttonClicked(value: any) {
            switch (value.index) {
                case 0:
                    context.emit('cancel')
                    cancelClicked()
                    break
                case 1:
                    validateFormRef.value.validate(async (validate: any) => {
                        if (validate) {
                            // 如果是子用户
                            if (!tenantPermissionInfo.system_manager && !tenantPermissionInfo.tenant_manager) {
                                subUserRequest()
                            } else {
                                tenantManagerRequest()
                            }
                        }
                    })
                    break
                default:
                    break
            }
        }
        function tenantManagerRequest() {
            api.ZWSControl.session_addSessionsForTenantManager(loginInfo)
                .then((res: any) => {
                    if (res.token) {
                        context.emit('confirm')
                        // 外面控制
                        // cancelClicked()
                    }
                })
                .catch((err: any) => {
                    errorMsg.value = ''
                    nextTick(() => {
                        errorMsg.value = getLanguage('zws.ZCommon.tips.pwdError')
                    })
                })
        }
        function subUserRequest() {
            loginInfo.username = `${userInfo.name}@${userInfo.tenantInfo.name}`
            api.ZWSControl.session_addSessions(loginInfo)
                .then((res: any) => {
                    if (res.token) {
                        context.emit('confirm')
                        cancelClicked()
                    }
                })
                .catch((err: any) => {
                    errorMsg.value = ''
                    nextTick(() => {
                        errorMsg.value = getLanguage('zws.ZCommon.tips.pwdError')
                    })
                })
        }
        function cancelClicked() {
            validateFormRef.value.resetFields()
            errorMsg.value = ""
            dialogVisible.value = false
            context.emit('update:modelValue', false)
        }
        return {
            t,
            rules,
            loginInfo,
            errorMsg,
            dialogVisible,
            validateFormRef,
            buttonClicked,
            cancelClicked,
        }
    },
})
</script>
<style lang="less" scoped>
.validate-form {
    padding-left: 10px;
    padding-right: 10px;

    :deep(.el-form-item) {
        margin-bottom: 22px !important;
    }
}

.handle-buttons {
    text-align: center;
    margin-top: 20px;

    :deep(.el-button) {
        min-height: 40px;
        padding: 12px 20px;
    }
}
</style>