<template>
  <ZAuthPageArea>
    <template v-slot>
      <ZLoginArea @forgetClicked="forgetClicked" @loginClicked="loginClicked" @registerClicked="registerClicked" resourcePath="/can">
      </ZLoginArea>
    </template>
  </ZAuthPageArea>
  <UpgradeNotice
    v-model="showNotice"
    :upgradeTime="upgradeTime"
    :upgrade-content="upgradeContent"
    system="CANDTU云数据平台"
    version="v1.3.1"
  >
  </UpgradeNotice>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import UpgradeNotice from 'comp/Common/UpgradeNotice.vue'
import {
  FetchError,
  getBatchDashbords,
  GetBatchDashbordsResp,
  addSingleDataDashbord,
  AddSingleDataDashbordResp,
  updateSingleCustomization
} from '@zws/axios-fetch'

export default defineComponent({
  components: {
    UpgradeNotice
  },
  setup() {
    let router = useRouter()
    let store = useStore()
    let forgetClicked = () => {
      router.push('/ForgetPassword')
    }
    const { locale } = useI18n()
    const systemInfo = computed(() => store.getters.tenantCustomizationInfo)
    let loginClicked = (userInfo: any) => {
      // localStorage.setItem('language', '中文')

      // 有修改才进逻辑
      if (systemInfo.value?.systemLanguage && systemInfo.value.systemLanguage != locale.value) {
        // 进来之前先将登录页面的语言设置为用户语言
        const newConfig = {
          ...systemInfo.value,
          systemLanguage: locale.value
        }
        store.commit('tenantCustomizationInfo', newConfig)
        // 保存用户配置
        const systemInfoArray = [
          'systemName',
          'systemTheme',
          'systemLogo',
          'systemWebBottomCompany',
          'systemWebBottomPhone',
          'systemWebBottomEmail'
        ]
        const dataArray = [
          {
            key: 'systemLanguage',
            value: locale.value
          }
        ]
        systemInfoArray.forEach(item => {
          dataArray.push({
            key: item,
            value: newConfig[item] || ''
          })
        })
        updateSingleCustomization({
          tenant_id: userInfo.tenant_id,
          body: {
            data: dataArray
          }
        })
      }

      router.push('/Container/HomePage')
      store.commit('headerMenuActiveIndex', 'homePage')
      // 登录成功后，默认创建数据大盘
      getDefaultDashbords(userInfo)
    }
    function registerClicked() {
      router.push('/Register')
    }
    function getDefaultDashbords(userInfo: any) {
      //   window.ZWSApi.ZWSDataMgmt.dashboardController_getBatchDashbords({
      getBatchDashbords({
        tenant_id: userInfo.tenant_id,
        business_type: 'candtu_dashboard_default'
      })
        .then((res: GetBatchDashbordsResp) => {
          // 如果没有大盘，新建默认大盘
          if (res.length === 0) {
            if (userInfo.isManager) {
              // 主用户才新建
              addDashboard()
            }
          } else {
            // candtu项目，默认只有一个大盘
            store.commit('currentDashbordInfo', res[0])
          }
        })
        .catch((error: FetchError) => {})
      function addDashboard() {
        // window.ZWSApi.ZWSDataMgmt.dashboardController_addDataDashbord({
        addSingleDataDashbord({
          tenant_id: userInfo.tenant_id,
          business_type: 'candtu_dashboard_default',
          name: `${userInfo.id}_candtu_dashboard_default`
        })
          .then((res: AddSingleDataDashbordResp) => {
            store.commit('currentDashbordInfo', {
              business_type: 'candtu_dashboard_default',
              id: res.id,
              name: `${userInfo.id}_candtu_dashboard_default`,
              owner_id: userInfo.id,
              tenant_id: userInfo.tenant_id
            })
          })
          .catch((error: FetchError) => {})
      }
    }

    const upgradeTime = ['2023-01-06 09:00:00', '2023-01-06 14:00:00']
    const upgradeContent = [
      '支持ZCANPRO工具使用主用户账号接入；',
      '优化历史数据删除的用户体验；',
      '兼容candtu-200新旧固件升级处理，新固件支持使用HTTP方式文件召回；',
      '优化历史数据导出，过滤部分错误帧处理；',
      '修复若干已知bug。'
    ]
    const showNotice = ref<boolean>(false)

    return {
      upgradeTime,
      upgradeContent,
      showNotice,
      loginClicked,
      forgetClicked,
      registerClicked
    }
  }
})
</script>
<style lang="sass" scoped></style>
