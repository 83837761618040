<template>
    <div class="z-loading-follow-contain">
        <div class="z-loading-follow-item">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, withDefaults, defineProps, computed } from "vue";

    interface Props {
        loadingWidth?: string | number,
        loadingMargin?: string | number,
        loadingColor?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        loadingWidth: 6,
        loadingMargin: 2,
        loadingColor: '#606266',
    });

    const loadingWidthComputed = computed(() => {
        return props.loadingWidth + 'px';
    });
    const loadingMarginComputed = computed(() => {
        return props.loadingMargin + 'px';
    });

</script>


<style lang="less" scoped>
    .z-loading-follow-item,
    .z-loading-follow-item > div {
        position: relative;
        box-sizing: border-box;
    }

    .z-loading-follow-item {
        display: block;
        font-size: 0;
        color: v-bind('loadingColor');
    }

    .z-loading-follow-item > div {
        display: inline-block;
        float: none;
        background-color: currentColor;
        border: 0 solid currentColor;
    }


    .z-loading-follow-item > div:nth-child(1) {
        animation-delay: -200ms;
    }

    .z-loading-follow-item > div:nth-child(2) {
        animation-delay: -100ms;
    }

    .z-loading-follow-item > div:nth-child(3) {
        animation-delay: 0ms;
    }

    .z-loading-follow-item > div {
        width: v-bind('loadingWidthComputed');
        height: v-bind('loadingWidthComputed');
        margin: v-bind('loadingMarginComputed');
        border-radius: 100%;
        animation: ball-pulse 1s ease infinite;
    }

    @keyframes ball-pulse {
        0%,
        60%,
        100% {
            opacity: 1;
            transform: scale(1);
        }

        30% {
            opacity: 0.1;
            transform: scale(0.01);
        }
    }
</style>
