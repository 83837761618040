/**
 * 子用户管理
 */
export default {
  addSubuser: 'Add Subuser',
  addSubuserSuccess: 'Adding the subuser succeeded',
  editSubuserSuccess: 'Editing the subuser succeeded',
  editSubuserFail: 'Editing the subuser failed',
  editRoleFail: 'Edit role failure',
  resetPassword: 'The password of subuser {name} has been reset',
  delSubuserFailed: 'Failed to delete the subuser',
  delSubuserSuccess: 'Deleting the subuser succeeded',
  obtainSubuserListFailed: 'Failed to obtain the subuser list',
  obtainSubuserFailed: 'Failed to obtain the subuser',
  obtainDefaultRoleFailed: 'Failed to obtain CANDTU default role',
  subuserManagement: 'Subuser Management',
  loginAgain: 'After the rights are changed, the subuser needs to log in again',
  currentDisplay: 'Current Display',
  devices: 'Devices',
  selected: 'Selected',
  usernameRegularErrorTip: 'Please enter 4-32 characters, including only alphanumeric underscores',
  cellPhoneRegularErrorTip: 'Please enter the correct number of digits, only digits',
  emailRegularErrorTip: 'Please enter your email address in the correct format',
  usernameCantbeEmpty: 'The user name cannot be empty',
  passwordCantbeEmpty: 'The password cannot be empty',
  mobilePhoneCantbeEmpty: 'The mobile phone number cannot be empty',
  permissionsCantbeEmpty: 'Permissions cannot be empty',
  obtainSubuserInfFailed: 'Failed to obtain the subuser information',
  editSubuser: 'Edit Subuser',
  sureToResetSubuserPasswords: "Are you sure you want to reset the {name}'s password ?",
  deleteSubuser: 'Delete Subuser',
  sureToDeleteSubuser: 'Are you sure you want to delete subuser {name} ?',
  tips: "The initial password of a subuser is 8 digits after the last number of the mobile phone. The login name format is subuser{'@'}primary account"
}
