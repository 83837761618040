/**
 * 子用户管理
 */
export default {
  addSubuser: '添加子用户',
  addSubuserSuccess: '添加子用户成功',
  editSubuserSuccess: '编辑子用户成功',
  editSubuserFail: '编辑子用户失败',
  editRoleFail: '编辑角色失败',
  resetPassword: '已重置子用户{name}的密码',
  delSubuserFailed: '删除子用户失败',
  delSubuserSuccess: '删除子用户成功',
  obtainSubuserListFailed: '获取子用户列表失败',
  obtainSubuserFailed: '获取子用户失败',
  obtainDefaultRoleFailed: '获取CANDTU默认角色失败',
  subuserManagement: '子用户管理',
  loginAgain: '权限切换后，子用户需要重新登录才生效',
  currentDisplay: '当前显示',
  devices: '台设备',
  selected: '已选',
  usernameRegularErrorTip: '请输入4-32位，只可以包含字母数字下划线',
  cellPhoneRegularErrorTip: '请输入正确位数的手机号码，只能是数字',
  emailRegularErrorTip: '请输入正确格式的邮箱地址',
  usernameCantbeEmpty: '用户名不能为空',
  passwordCantbeEmpty: '密码不能为空',
  mobilePhoneCantbeEmpty: '手机号码不能为空',
  permissionsCantbeEmpty: '权限不能为空',
  obtainSubuserInfFailed: '获取子用户信息失败',
  editSubuser: '编辑子用户',
  sureToResetSubuserPasswords: '确定要重置子用户 {name} 的密码 ？',
  deleteSubuser: '删除子用户',
  sureToDeleteSubuser: '确定要删除子用户 {name} 吗 ？',
  tips: "子用户的初始密码：手机尾号后8位，登录名格式：子用户{'@'}主账号"
}
