import { App } from 'vue'
import { domPerm } from './directives/domPerm';
import { hasPermission } from './directives/hasPermission';
import { clickWaterAnimation } from './directives/clickWaterAnimation';
import { tooltips } from './directives/tooltips';
import buttonPermission from './directives/buttonPermission'
import buttonBlur from './directives/buttonBlur'
import monitoring from './directives/monitoring'
import {debounce} from './directives/debounce'


const directivesList: any = {
    domPerm,
    hasPermission,
    clickWaterAnimation,
    buttonPermission,
    buttonBlur,
    tooltips,
    monitoring,
    debounce
};

const directives = {
    install: function (app: App<Element>) {
        Object.keys(directivesList).forEach((key) => {
            app.directive(key, directivesList[key]); // 注册
        });
    }
};

export default directives;
