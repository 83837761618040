<template>
  <div :class="['z-register-wrapper', $i18n.locale]">
    <div class="bg">
      <div class="top">
        <div class="register">
          <div class="logo-wrapper">
            <!-- <img class="logo-img" :src="activePlatformInfo.loginLogo" /> -->
            <span class="logo-text">{{ activePlatformInfo.projectName }}</span>
          </div>
          <div class="step-content">
            <span class="tips-text">{{ t('lib.register.name') }}</span>
            <el-steps class="zws-steps" :active="active" :space="123" :align-center="true" finish-status="finish">
              <el-step :title="t('lib.register.fillInInformation')"></el-step>
              <el-step :title="t('lib.register.emailAddress')"></el-step>
              <el-step :title="t('lib.register.informationRegistration')"></el-step>
              <el-step :title="t('lib.register.finishRegistration')"></el-step>
            </el-steps>
            <div class="module">
              <InputUserInfo
                ref="inputUserInfo"
                v-show="showView === 'userInfo'"
                @loginTextClick="loginTextClick"
                @nextStep="userInfoNextStep"
                @protocolClicked="registerProtocol"
              ></InputUserInfo>
              <InputEmailInfo
                ref="inputEmailInfo"
                v-show="showView === 'emailInfo'"
                @skip="emailInfoSkip"
                @backStep="emailInfoBackStep"
                @nextStep="emailInfoNextStep"
              ></InputEmailInfo>
              <InputCompanyInfo
                v-show="showView === 'companyInfo'"
                @skip="companyInfoSkip"
                @backStep="companyInfoBackStep"
                @register="companyInfoRegister"
              ></InputCompanyInfo>
              <SuccessTips v-show="showView === 'successTips'" @promptlyLogin="promptlyLogin"> </SuccessTips>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, nextTick, ref, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import InputUserInfo from './ZInputUserInfo.vue'
import InputEmailInfo from './ZInputEmailInfo.vue'
import InputCompanyInfo from './ZInputCompanyInfo.vue'
import SuccessTips from './ZSuccessTips.vue'
import { ElMessage } from 'element-plus'
// import { useLocale } from '../../../hooks/useLocale'
import { useI18n } from 'vue-i18n'
import { projectConfig } from '@/assets/ts/project_config'
import defaultLoginLogo from '@/assets/images/login/login_logo.png'
import { addSingleUserTenants } from '@zws/axios-fetch'

export default defineComponent({
  components: {
    InputUserInfo,
    InputEmailInfo,
    InputCompanyInfo,
    SuccessTips
  },
  props: {
    platform: String
  },
  setup(props, context) {
    // let { t } = useLocale()
    let { t } = useI18n()
    const store = useStore()
    const inputUserInfo = ref()
    const inputEmailInfo = ref()
    const active = ref(1)
    const showView = ref('userInfo')

    let activePlatformInfo: any = reactive({
      loginLogo: defaultLoginLogo,
      projectName: projectConfig.projectName
    })
    initPlatformInfo()
    initRegisterInfo()

    function userInfoNextStep(value: any) {
      active.value = 2
      showView.value = 'emailInfo'
    }
    const loginTextClick = () => {
      context.emit('loginTextClick')
    }
    function companyInfoBackStep() {
      active.value = 2
      showView.value = 'emailInfo'
    }
    function emailInfoBackStep() {
      active.value = 1
      showView.value = 'userInfo'
    }
    function emailInfoSkip() {
      active.value = 3
      showView.value = 'companyInfo'
    }
    function emailInfoNextStep() {
      active.value = 3
      showView.value = 'companyInfo'
    }

    function companyInfoRegister(value: any) {
      value.platform = props.platform
      const resgisterInfo = Object.assign(store.getters.registerInputUserInfo, value)
      addSingleUserTenants(resgisterInfo)
        .then((res: any) => {
          // 验证成功，删去确认密码字段
          delete resgisterInfo.confirmPassword
          active.value = 4
          showView.value = 'successTips'
          // 注册成功后清空信息
          initRegisterInfo()
        })
        .catch((error: any) => {
          if (error.err.data.message === 'invalid SMS verification code') {
            ElMessage({
              showClose: true,
              message: t('lib.login.error.018') || '手机验证码无效或已过期，请重新获取',
              type: 'error'
            })
            active.value = 1
            showView.value = 'userInfo'
            nextTick(() => {
              inputUserInfo.value.setSmsErrorText(t('lib.login.error.018') || '手机验证码无效或已过期，请重新获取')
            })
          } else if (error.err.data.message === 'invalid Email verification code') {
            ElMessage({
              showClose: true,
              message: t('lib.login.error.019') || '邮箱验证码无效或已过期，请重新获取',
              type: 'error'
            })
            active.value = 2
            showView.value = 'emailInfo'
            nextTick(() => {
              inputEmailInfo.value.setEmailErrorText(t('lib.login.error.019') || '邮箱验证码无效或已过期，请重新获取')
            })
          } else {
            ElMessage({
              showClose: true,
              message: error.errMessage,
              type: 'error'
            })
          }
        })
    }
    function companyInfoSkip() {
      active.value = 4
    }
    function promptlyLogin() {
      context.emit('promptlyLogin')
    }
    function registerProtocol() {
      context.emit('registerProtocol')
    }
    function initRegisterInfo() {
      store.commit('registerInputUserInfo', {
        avatar: '',
        mobile: '',
        mobile_verification_code: '',
        password: '',
        name: '',
        namespace: '',
        description: '',
        organization_name: '',
        industry: '',
        address: '',
        country: '',
        province: '',
        city: '',
        username: '',
        email: undefined,
        email_verification_code: undefined,
        department: '',
        gender: '男',
        job: '',
        tenant_type: 0
      })
    }
    function initPlatformInfo() {
      setPlatformInfo()
    }
    function setPlatformInfo() {
      let globalPermissionInfo = store.getters.globalPermissionInfo
      if (!globalPermissionInfo) return
      activePlatformInfo.loginLogo = globalPermissionInfo.loginLogo ? globalPermissionInfo.loginLogo : defaultLoginLogo
      activePlatformInfo.projectName = globalPermissionInfo.cloudname ? globalPermissionInfo.cloudname : projectConfig.projectName
    }

    watch(
      () => store.getters.globalPermissionInfo,
      () => {
        setPlatformInfo()
      }
    )

    return {
      t,
      inputUserInfo,
      projectConfig,
      active,
      showView,
      activePlatformInfo,
      userInfoNextStep,
      loginTextClick,
      emailInfoBackStep,
      emailInfoSkip,
      emailInfoNextStep,
      companyInfoBackStep,
      companyInfoRegister,
      promptlyLogin,
      companyInfoSkip,
      registerProtocol
    }
  }
})
</script>

<style lang="less" scoped>
.z-register-wrapper.en {
  :deep(.el-form-item.is-error) {
    margin-bottom: 30px;
  }
}
.z-register-wrapper.en {
  .bg {
    height: 760px;
  }
}
.z-register-wrapper {
  overflow-y: auto;
  overflow-x: hidden;

  .bg {
    width: 550px;
    height: 690px;
    background-color: #ffffff;
    border-radius: 4px;

    .top {
      width: 550px;
      height: 142px;
      background-color: #f2f2f2;
      border-radius: 4px;
    }
  }

  .register {
    width: 550px;
    height: 690px;

    .logo-wrapper {
      display: flex;
      width: 550px;
      height: 54px;
      margin-left: 27px;

      .logo-img {
        width: 65px;
        height: 23px;
        margin-top: 24px;
        margin-right: 13px;
      }

      .logo-text {
        margin-top: 18px;
        font-size: 21px;
        line-height: 36px;
        font-weight: bold;
        // color: #ffffff;
        color: #333333;
      }
    }

    .step-content {
      width: 550px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .el-steps--horizontal {
        display: flex;
        justify-content: center;
      }

      .tips-text {
        font-size: 24px;
        line-height: 76px;
        color: #333333;
      }

      .zws-steps {
        width: 550px;
        margin-left: 5px;
      }

      .module {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 40px;
      }
    }
  }
}
</style>
<style>
body .zws-steps .el-step__title {
  font-size: 14px;
}

body .zws-steps .el-step__head.is-process {
  color: #333333;
  border-color: #333333;
}

body .zws-steps .el-step__head.is-wait {
  color: #333333;
  border-color: #333333;
}

body .zws-steps .el-step__title.is-wait {
  color: #333333;
}
</style>
