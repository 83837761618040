<template>
    <div class="recall-list">
        <div class="recall-list-content" v-loading="recallListInfo.loading">
            <ZTablePaginationCtrl v-loading="isSearching" v-model="tablePaginationValue" :tableHeader="tableHeader"
                :tableData="recallListInfo.tableData" @paginationChange="paginationChange">
                <template v-slot:size="scope">
                    <span>{{ ((scope.data.size > (1024 * 1024)) ? ((scope.data.size / (1024 * 1024)).toFixed(2) + 'MB') :
                        ((scope.data.size / 1024).toFixed(2) + 'KB')) }}</span>
                </template>
                <template v-slot:deal="scope">
                    <el-button @click="recallClick(scope.data)" type="primary" size="mini" plain
                        :disabled="(scope.data.size == 0)">{{ getLanguage('zws.ZRecall.fileBack') }}</el-button>
                    <el-button @click="deleteClick(scope.data)" type="danger" size="mini" plain
                        :disabled="(scope.data.size == 0)">{{ getLanguage('zws.ZCommon.delete') }}</el-button>
                </template>
            </ZTablePaginationCtrl>
        </div>
        <!-- <ZRecallDownProcessArea
                                        processTitle="进度"
                                        :abruptEnable="true"
                                        :totalSize="thisTableRow.size" pbtntxt="取消"
                                        :isProcessVisible="recallDownProcessInfo.isProcessVisible"
                                        :percentage="recallDownProcessInfo.percentage"
                                        :currentSize="recallDateInfo.offset"
                                        processCloseTipTxt="是否中断文件召回？"
                                        @interruptRecall="interruptRecall"
                                        @continueRecall="continueRecall"
                                        @breakRecall="breakRecall">
                </ZRecallDownProcessArea> -->

        <ZStepWithProcessCtrl :stepProcessTitle="getLanguage('zws.ZRecall.recallProgress') || '召回进度'"
            v-model="stepWithProcessInfo.stepWithProcessVisible" :stepInfoList="stepWithProcessInfo.stepInfoList"
            :stepActive="stepWithProcessInfo.stepActive" :sizeUnit="stepWithProcessInfo.sizeUnit"
            :sizeTotal="stepWithProcessInfo.sizeTotal" :percentage="stepWithProcessInfo.percentage"
            @midwayCancelClick="midwayCancelClick">
        </ZStepWithProcessCtrl>
        <ZDialogCtrl :title="getLanguage('zws.ZCommon.tips.tip')" width="400px"
            @buttonClicked="delDataFieldConfirmDlgBtnClicked" :show="deleteLogDialogVisible">
            {{ getLanguage('zws.ZRecall.sureDelete') }}？
        </ZDialogCtrl>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive, withDefaults, defineProps, defineEmits, defineExpose, watch, nextTick, onMounted } from "vue";
import { ElMessage } from 'element-plus'
import recallFileMap from '../../../../sdk/ts/tool/ZAreaRecallFileMap'
import ZFileTool from '../../../../sdk/ts/tool/ZFileTool'
import { ZDateTool } from '@zws/tools'
import { useI18n } from 'vue-i18n'
import { useLocale } from '../../hooks/useLocale'
import { remarkOperationMonitoring } from '../../../../sdk/ts/tool/ZDirectives/directives/monitoring'
import { issueAsyncMessage, sendMessagesToDevice, getFiles, getMessagesResponse } from '@zws/axios-fetch'

export interface RecallListPropsInfo {
    devtype: string,
    info_model_id: number | string,
    tenant_id: number | string,
    things_type: number | string,
    things_id: number | string,
    third_things_id: number | string,
}
interface Props {
    initFlagValue: any
    recallListPropsInfo: RecallListPropsInfo
    isCanContinueRecall: boolean
    platform: string
}
interface RecallListInfo {
    opacity: number,
    loading: boolean,
    tableData: Array<any>,
}
interface RecallDownProcessInfo {
    isProcessVisible: boolean,
    percentage: number,
}
interface RecallDateInfo {
    offset: number,
    recallFile: string,
    recallContinueFlag: boolean,// 中途取消 标识
    repeat: number,
    errRepeat: number
}
interface StepInfoList {
    title: string,
    description?: string,
}
interface StepWithProcessInfo {
    sizeTotal: number,
    sizeUnit: string | undefined,
    stepInfoList: Array<StepInfoList>,
    stepActive: number,
    percentage: number,
    stepWithProcessVisible: boolean
}


const props = withDefaults(defineProps<Props>(), {
    initFlagValue: null,
});
const emits = defineEmits(['recallListTimeOut']);

let { t } = useI18n()
const $t = t
t = useLocale().t
let getLanguage = function (data: any, tip?: any) {

    return t(data)
}
let tablePaginationValue: any = ref('');
let tableHeader: any = reactive([
    { label: getLanguage('zws.ZRecall.document', '文件名'), prop: 'filename', width: '', type: '', align: 'center', },
    { label: getLanguage('zws.ZRecall.size', '大小'), prop: 'size', width: '200', type: 'custom', align: 'center', },
    { label: getLanguage('zws.ZCommon.time', '时间'), prop: 'time', width: '200', type: '', align: 'center', },
    { label: getLanguage('zws.ZCommon.operate', '操作'), prop: 'deal', width: '200', type: 'custom', align: 'center', },
])


// 设备日志列表
let recallListInfo: RecallListInfo = reactive({
    opacity: 1,
    loading: false,
    tableData: [],
});
let deleteLogDialogVisible = ref<boolean>(false)
let thisTableRow: any = reactive({ file_map: '', size: 0, filename: '', id: 0 });
let recallSendMessagesRep: any = {
    rep: null,
    repTime: null
};
// 召回进度
let recallDownProcessInfo: RecallDownProcessInfo = reactive({
    isProcessVisible: false,
    percentage: 0,
});

let findThingsListInterval: any = null;
let findThingsListIntervalCount: any = 0;
let deviceLogParamInfo: any = props.recallListPropsInfo;

let recallDateInfo: RecallDateInfo = reactive({
    offset: 0,
    recallFile: '',
    recallContinueFlag: true,
    repeat: 0,
    errRepeat: 0
});
let currentTime = reactive<any>({
    starttime: 0,
    endtime: 0,
})
let isSearching = ref<boolean>(false)

defineExpose({ sendMessagesToDeviceLocal })
/** 点击文件召回， 1-发送设备命令(获取文件列表) */
function sendMessagesToDeviceLocal(time: any) {
    currentTime.starttime = time.startTime
    currentTime.endtime = time.endTime
    let str = JSON.stringify({ starttime: time.startTime, endtime: time.endTime })
    if (!time.startTime) {
        str = "{}"
    }
    let payload = {
        "operation_name": 'common_fn.recallFileList',
        "operation_params": {
            page_size: tablePaginationValue.value.pageSize,
            current_page: tablePaginationValue.value.currentPage,
            filter: str,
        }
    };
    const info = {
        correlation_id: ``,
        description: 'string',
        headers: '{}',
        info_model_id: deviceLogParamInfo.info_model_id,
        message_type: 0,
        payload: JSON.stringify(payload),
        tenant_id: deviceLogParamInfo.tenant_id,
        things_id: deviceLogParamInfo.things_id,
        third_things_id: deviceLogParamInfo.third_things_id,
    };
    isSearching.value = true

    issueAsyncMessage({
        tenant_id: deviceLogParamInfo.tenant_id,
        things_id: deviceLogParamInfo.things_id,
        body: info,
        timeout: 100000,
    })
        .then((res: any) => {
            console.log('res: ', res);
            isSearching.value = false
            let messages = JSON.parse(res.messages)
            console.log('messages: ', messages);
            if(messages.list[0]?.data) {
            let msg = JSON.parse(messages.list[0].data.msg)
            console.log('msg: ', msg);
            let fileList = msg.fileList
            tablePaginationValue.value.total = msg.total_size
            let tableDataTem: any = []
            fileList.map((item: any) => {
                let singleFile = {
                    size: item.size,
                    filename: item.name,
                    originSize: item.size,
                    realName: '',
                    time: ZDateTool.timestampToFormatDate(item.time)
                }
                tableDataTem.push(singleFile)
            })
            recallListInfo.tableData = tableDataTem
            console.log('recallListInfo.tableData: ', recallListInfo.tableData);
            }
            let monitor = {
                platform: props.platform||'-',
                module: '文件召回',
                action: '查询文件',
            }
            props.platform && remarkOperationMonitoring(monitor)
        })
        .catch((err: any) => {
            isSearching.value = false
            recallListInfo.loading = false;
            ElMessage.error(err.errorMsg);
        })
}

/** 列表中召回事件， 1-发送设备命令 */
function sendRecallMessagesToDevice(row: any) {
    let url = window.location.host
    if (url.indexOf('localhost') > -1) {
        url = 'http://192.168.24.10'
    }
    url = '/v1/filemanager/upload/commonfile/' + deviceLogParamInfo.things_id + '_' + row.filename
    row.realName = url
    let payload = {
        "operation_name": "common_fn.recallFile",
        "operation_params": {
            "filename": row.filename,
            "url": url
        }
    };
    const info = {
        correlation_id: `correlation_id_${new Date().getTime()}`,
        "description": '',
        "headers": "{}",
        "info_model_id": deviceLogParamInfo.info_model_id,
        "message_type": 0,
        "payload": JSON.stringify(payload),
        "tenant_id": deviceLogParamInfo.tenant_id,
        "things_id": deviceLogParamInfo.things_id,
        "things_type": deviceLogParamInfo.things_type,
        "third_things_id": deviceLogParamInfo.third_things_id,
    };

    sendMessagesToDevice({ tenant_id: deviceLogParamInfo.tenant_id, things_id: deviceLogParamInfo.things_id, info })
        .then((res: any) => {
            recallSendMessagesRep.res = res;
            recallSendMessagesRep.resTime = Date.now();
            checkCommand()
        })
        .catch((err: any) => {
            recallListInfo.loading = false;
            ElMessage.error(err?.data?.error);
        })
}

/** 列表中召回事件， 2-1 确认命令发送的状态 */

let commandCheckCount: any = 0;
let fileCheckCount: any = 0;
// 召回进度弹窗配置
let stepWithProcessInfo: StepWithProcessInfo = reactive({
    sizeTotal: 0,
    sizeUnit: '',
    stepInfoList: [
        { title: getLanguage('zws.ZRecall.orderSend') || '命令发送', tip: getLanguage('zws.ZRecall.orderSending') || '命令发送中' },
        { title: getLanguage('zws.ZRecall.fileFetch') || '文件获取', tip: getLanguage('zws.ZRecall.fileFetching') || '文件获取中' },
    ],
    stepActive: 0, // 当前步骤
    percentage: 0, // 当前任务进度
    stepWithProcessVisible: false
});
function checkCommand() {
    commandCheckCount += 1;
    if (commandCheckCount >= 15) {
        commandCheckCount = 15
    }
    stepWithProcessInfo.percentage = Number((commandCheckCount * (100 / 15.1)).toFixed(2)); // 确认命令的进度
    getMessagesResponse({
        tenant_id: deviceLogParamInfo.tenant_id,
        message_ids: recallSendMessagesRep.res.messageId,
        start_date: recallSendMessagesRep.res.timestamp - 1000,
        offset: recallDateInfo.offset,
    })
        .then((res: any) => {
            if (!recallDateInfo.recallContinueFlag) return;
            let isCommandOk = dealFragmentFile(res);
            if (isCommandOk) {
                stepWithProcessInfo.percentage = 100;
                // 2-2 获取上传进度，上传完毕返回下载地址
                setTimeout(async () => {
                    setStepInfo(1, 0);
                    commandCheckCount = 0;
                    loopGetFileProcessOrUrl();
                }, 1000)
            } else {
                recallDateInfo.offset += 8192;
                setTimeout(() => {
                    recallDateInfo.recallContinueFlag && checkCommand()
                }, 1000);

                recallDateInfo.errRepeat = 0;
            }
        })
        .catch((err: any) => {
            if (recallDateInfo.errRepeat <= 3) {
                checkCommand();
                recallDateInfo.errRepeat += 1
            } else {
                // 命令发送失败
                ElMessage.error('命令发送失败，请重试！');
                stepWithProcessControl(false);
            }
        })
}
function dealFragmentFile(res: any) {
    let result = null;
    if (res && res[0] && res[0].messages) {
        let responseList = JSON.parse(res[0].messages).list;
        if (responseList && responseList[0] && responseList[0].data && responseList[0].data.msg) {
            result = responseList[0].data.result;
        }
    }
    return result;
}
function setStepInfo(stepActive: number, percentage: number, visible?: boolean) {
    stepWithProcessInfo.stepActive = stepActive;
    stepWithProcessInfo.percentage = percentage;
    if (visible != null) {
        stepWithProcessControl(visible);
    }
}
async function loopGetFileProcessOrUrl() {
    const resMsg: any = await getFileProcessOrUrl();
    if (resMsg === 'oss-404' && fileCheckCount < 600 && recallDateInfo.recallContinueFlag) {
        setTimeout(() => {
            loopGetFileProcessOrUrl();
        }, 1000)
    }
}
function stepWithProcessControl(type: boolean) {
    stepWithProcessInfo.stepWithProcessVisible = type;
    if (type) {
        recallDateInfo.recallContinueFlag = true;
    }
}


/** 列表中召回事件，2-2 获取文件上传进度，上传完毕会返回下载 URL */
async function getFileProcessOrUrl() {
    fileCheckCount += 1;
    let errMsg: any = 'ok';
    let fileRes: any = await getRecallFile().catch((err: any) => {
        errMsg = 'oss-404';
    });
    if (errMsg !== 'ok' && fileCheckCount < 600) return errMsg;

    if (!fileRes) {
        ElMessage.error('文件获取失败，请重试(code: 001)');
        setStepInfo(0, 0, false);
        return;
    }

    let targetUrl = fileRes?.url;
    if (!targetUrl && !fileRes.curPart && !fileRes.totalPart) {
        ElMessage.error('文件获取失败，请重试(code: 002)');
        setStepInfo(0, 0, false);
        return;
    }

    if (targetUrl && targetUrl.length > 0) {
        getFinishAndDownFile(targetUrl);
    } else {
        if (stepWithProcessInfo.stepWithProcessVisible) {
            stepWithProcessInfo.percentage = fileRes.curPart / fileRes.totalPart * 100;
            setTimeout(() => {
                recallDateInfo.recallContinueFlag && getFileProcessOrUrl(); // 每个2秒获取一次进度
            }, 2000)
        }
    }
}
/** 下载文件 */
function getFinishAndDownFile(targetUrl: string) {
    stepWithProcessInfo.percentage = 100;
    if (!targetUrl) {
        ElMessage.error('下载失败，请重试');
        setStepInfo(0, 0, false);
        return;
    }
    ElMessage.success('开始下载...');
    ZFileTool.downFileFormBlobUrl({
        url: targetUrl,
        fileName: thisTableRow.filename,
        cb: (() => {
            stepWithProcessInfo.stepActive = 2;
            setTimeout(() => {
                setStepInfo(0, 0, false);
            }, 1000)
        })
    });

}
// 召回中途 关闭弹窗 退出
let oldFileRecallBeginTime = 0
function midwayCancelClick() {
    recallDateInfo.recallContinueFlag = false;
    setStepInfo(0, 0, false);
    oldFileRecallBeginTime = 0
    resetRecallData();
}





/** 获取设备召回的文件 */
function getRecallFile() {
    return new Promise((resolve, reject) => {
        let fileName = deviceLogParamInfo.things_id + '_' + thisTableRow.filename

        getFiles({
            tenantId: deviceLogParamInfo.tenant_id,
            name: fileName,
            type: 'dev'
        })
            .then((res: any) => {
                resolve(res);
            })
            .catch((error: any) => {
                reject(error);
            })
    })
}
/** 列表中召回事件， 2-召回下载 */
function recallSource() {
    if (recallDateInfo.offset < thisTableRow.size) {
        getMessagesResponse({
            tenant_id: deviceLogParamInfo.tenant_id,
            message_ids: recallSendMessagesRep.res.messageId,
            start_date: recallSendMessagesRep.res.timestamp - 1000,
            file_index: thisTableRow.file_map,
            offset: recallDateInfo.offset,
        })
            .then((res: any) => {
                let fragmentfFile = dealFragmentfFile(res);
                recallDateInfo.recallFile += fragmentfFile;

                recallDateInfo.offset += 8192;
                recallDownProcessInfo.percentage = Math.floor((recallDateInfo.offset / thisTableRow.size) * 100);

                setTimeout(() => {
                    recallDateInfo.recallContinueFlag && recallSource()
                }, 200);

                recallDateInfo.repeat = 0;
            })
            .catch((err: any) => {
                if (recallDateInfo.repeat <= 3) {
                    recallSource();
                    recallDateInfo.repeat += 1
                } else {
                    // 弹是否中断召回
                    recallDownProcessDialogControl(true);
                }
            })
    } else {
        downloadRecallFile()
    }
}

/** 删除召回文件 命令 */
function deleteRecallMessagesToDevice() {
    isSearching.value = true
    let payload = {
        "operation_name": 'common_fn.recallFileDel',
        "operation_params": {
            filter: JSON.stringify({ name: thisTableRow.filename })
        }
    };
    const info = {
        description: '',
        "headers": "{}",
        "info_model_id": deviceLogParamInfo.info_model_id,
        "message_type": 0,
        "payload": JSON.stringify(payload),
        "tenant_id": deviceLogParamInfo.tenant_id,
        "things_id": deviceLogParamInfo.things_id,
        // "things_type": deviceLogParamInfo.things_type,
        "third_things_id": deviceLogParamInfo.third_things_id,
    };

    issueAsyncMessage({
        tenant_id: deviceLogParamInfo.tenant_id,
        things_id: deviceLogParamInfo.things_id,
        body: info,
        timeout: 100000,
    })
        .then((res: any) => {
            // isSearching.value = false
            ElMessage.success($t('common.tip.deleteSuccess') || '删除成功');
            sendMessagesToDeviceLocal(currentTime)
            // init();
        })
        .catch((err: any) => {
            isSearching.value = false
            ElMessage.error(err?.data?.error);
        })
}

function dealFragmentfFile(res: any) {
    let result = null;
    if (res && res[0] && res[0].messages) {
        let responseList = JSON.parse(res[0].messages).list;
        if (responseList && responseList[0] && responseList[0].data && responseList[0].data.msg) {
            result = responseList[0].data.msg;
        }
    }
    return result;
}



function recallDownProcessDialogControl(type: boolean) {
    recallListInfo.opacity = Number(!type);
    recallDownProcessInfo.isProcessVisible = type;
}

// 召回/删除 点击按钮当前行
function setTableRow(row: any) {
    let thisTableRowKeys = Object.keys(thisTableRow);
    for (let item of thisTableRowKeys) {
        thisTableRow[item] = row[item]
    }
}

// 日志-召回点击事件
async function recallClick(row: any) {
    fileCheckCount = 0;
    setTableRow(row);
    const targetUrl: any = await checkIsFileExist();
    if (targetUrl) {
        getFinishAndDownFile(targetUrl);
    } else {
        stepWithProcessControl(true);
        sendRecallMessagesToDevice(row);
    }
    let monitor = {
        platform: props.platform||'-',
        module: '文件召回',
        action: '召回',
    }
    props.platform && remarkOperationMonitoring(monitor)
}
// 确认是否已经存在该文件，既存在 URL
async function checkIsFileExist() {
    let fileRes: any = null;
    try {
        fileRes = await getRecallFile()
    } catch (e) {

    }
    return fileRes && fileRes.url ? fileRes.url : '';
}
// 日志-删除点击事件
function deleteClick(row: any) {
    setTableRow(row);
    deleteLogDialogVisible.value = true;
}
// 日志-删除事件-确认删除
function delDataFieldConfirmDlgBtnClicked(ev: any) {
    deleteLogDialogVisible.value = false;
    if (ev.index == 1) {
        deleteRecallMessagesToDevice();
    }
}

// 下载召回文件
function downloadRecallFile() {
    recallDateInfo.recallContinueFlag = false;

    let blob = new Blob([recallDateInfo.recallFile], { type: 'text/plain' });
    let a = document.createElement('a');
    a.download = `${thisTableRow.filename}.txt`;
    a.href = URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    downloadRecallFileAfter();
}

function resetRecallData() {
    recallDownProcessDialogControl(false);
    recallDateInfo.recallContinueFlag = true;
    recallDateInfo.recallFile = '';
    recallDateInfo.offset = 0;
    recallDateInfo.repeat = 0;
    recallDownProcessInfo.percentage = 0;
}
function downloadRecallFileAfter() {
    recallListInfo.loading = false;
    ElMessage.success(`下载设备${thisTableRow.filename}日志成功`);
    resetRecallData();
}

// 中断关闭召回
function interruptRecall() {
    recallDateInfo.recallContinueFlag = false;
}
// 恢复继续召回
function continueRecall() {
    recallDateInfo.recallContinueFlag = true;
    recallSource();
}
// 确认取消召回
function breakRecall() {
    resetRecallData();
}

function paginationChange(val: any) {
    sendMessagesToDeviceLocal(currentTime)
}

</script>

<style lang="less" scoped>
.recall-list {
    &-content {
        label {
            width: 60px;
            text-align: right;
            margin-right: 8px;
        }

        &-footer {
            text-align: center;
            margin-top: 20px;
        }
    }
}
</style>
