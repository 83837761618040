/**
 * uds诊断
 */
export default {
  delayMS: 'Delay(ms)',
  sendCommandError: 'Command sending error',
  unitMillisecond: 'Unit: millisecond',
  delay: 'Delay',
  pleaseInputName: 'Please input name',
  editCommand: 'Edit Command',
  stopSend: 'Stop Send',
  listSend: 'List Send',
  addDelay: 'Add Delay',
  addCommand: 'Add Command',
  debugInfo: 'Debug Information',
  pleaseInputSingleByteHex: 'Please enter a hexadecimal number in a single byte separated by a space',
  pleaseInputHexOrSpace: 'Please enter a hexadecimal number or space',
  requestDelay: 'Request PDU/Delay(ms)',
  name: 'Name',
  bindUpgradeChannelFailed: 'Failed to bind the upgrade channel, {error}',
  pleaseSelectECU: 'Please select ECU',
  responseAddrOutOfRange2: 'The response address is outside the standard frame address range of 7FF',
  physicalAddrOutOfRange2: 'The physical address is outside the standard frame address range of 7FF',
  functionAddrOutOfRange2: 'The functional address is outside the standard frame address range of 7FF',
  responseAddressOutOfRange: 'The response address is outside the extended frame address range of 1FFFFFFF',
  physicalAddrOutOfRange: 'The physical address is outside the extended frame address range of 1FFFFFFF',
  functionAddrOutOfRange: 'The functional address is outside the extended frame address range of 1FFFFFFF',
  sendUDSRequestFailed: 'Failed to send a UDS request, {error}',
  genUDSCommandFailed: 'Failed to generate the UDS command',
  suppressResponse: 'Suppress Response',
  functionAddress: 'Function Address',
  physicalAddress: 'Physical Address',
  requestAddress: 'Request Address',
  pleaseInputPDU: 'Please input PDU',
  UDSDiag: 'UDS Diagnosis',
  requestPDU: 'Request PDU',
  diagServ: 'Diagnostic Service',
  paramConfig: 'Parameter Configuration',
  tranConfig: 'Transmission configuration',
  proVersion: 'protocol version',
  fillByte: 'Fill byte (hex)',
  fCTimeout: 'FCTimeout(ms)',
  p2timeout: 'Timeout(ms)',
  p2_timeout: 'EnhancedTimeout(ms)',
  fillByteTips: 'The fill byte range is 0x00-0xFF',
  RemoteStMinTips: 'The RemoteStMin range is 0ms-127ms ',
  fCTimeoutTips: 'Flow control timeout (ms) ranges from 0 to 2147483647',
  p2timeoutTips: 'Timeout (ms) ranges from 0 to 2147483647',
  p2_timeoutTips: 'EnhancedTimeout (ms) ranges from 0 to 2147483647'
}
