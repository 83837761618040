import ZWSBaseModule from '../httpBase/ZWSBaseModule'

export interface addCoupon {
    belong_type: number, // 归属类型
    count: number,   //单租户领取次数
    coupon_file_name: string // 优惠券文件名称
    desc: string,    //备注
    discount: number,    //优惠券金额、折率   
    end_time: number,   //活动结束时间
    name: string,    //优惠券名
    remark: string, // 备注
    start_time: number,  //活动起始时间
    tenant_ids: string,  //指定租户领取 为空时全部都可以领取
    threshold: number,   //门槛（满金额立减,0为无门槛）
    type: number, //优惠类型 1 现金券 2 折扣券(现只支持现金券)
    usage: number // 1 package 2 sass 3 deploy
}

export interface PackageContent {
    alert_sms: number,
    alert_tps: number,
    database_storage_space: number,
    device_file_traffic_management: number,
    edge_computing: number,
    info_model: number,
    label_num: number,
    maximum_data_retention_days: number,
    maximum_total_number_documents_stored: number,
    number_simultaneout_connections: number,
    push_rule: number,
    single_data_export: number,
    sub_user_num: number,
    things_num: number,
    up_down_stream_connections: number
    push_tps: number
    alert_rule: number
    hmi: number
    data_analysis: number
}

class ZWSOutBilling extends ZWSBaseModule {

    /************* stageDiscount ****************************************************************/

    /**
     * 获取阶段折扣列表
     * @param param0 
     * @returns 
     */
    static stageDiscount_getStageDiscount({ current_page, page_size }: { current_page: number, page_size: number }) {
        return this.fetch({
            url: '/out-billing/stage-dicount',
            method: 'get',
            params: { current_page, page_size }
        })
    }

    /**
     * 更新一个阶段折扣
     * @param param0 
     * @returns 
     */
    static stageDiscount_updateStageDiscount({ discount, id, name, stage_start, stage_end }: { discount: number, id: number, name: string, stage_start: number, stage_end: number }) {
        return this.fetch({
            url: `/out-billing/stage-dicount/${id}`,
            method: 'put',
            data: { discount, id, name, stage_start, stage_end }
        })
    }

    /************* couponSys ****************************************************************/

    /**
     *  添加优惠券 
     */
    static couponSys_addSingleCoupon({ addCoupon }: { addCoupon: addCoupon }) {
        return this.fetch({
            url: `/out-billing/sys/coupon`,
            method: 'post',
            data: addCoupon
        })
    }

    /**
     * 删除优惠券
     */
    static couponSys_deleteSingleCoupon({ id }: { id: number }) {
        return this.fetch({
            url: `/out-billing/sys/coupon`,
            method: 'delete',
            params: { id }
        })
    }

    /**
     * 获取单个优惠券
     * @param param0 
     * @returns 
     */
    static couponSys_getSingleCouponById({id}: {id: number}) {
        return this.fetch({
            url: `/out-billing/sys/coupon/${id}`,
            method: 'get',
        })
    }

    /**
     * 查询优惠券
     */
    static couponSys_getCoupons({ current_page, page_size, belong_type }: { current_page: number, page_size: number, belong_type: number }) {
        return this.fetch({
            url: `/out-billing/sys/coupon`,
            method: 'get',
            params: { current_page, page_size, belong_type }
        })
    }

    /**
     * 更新system优惠券
     * @returns 
     */
    static couponSys_updateCoupons({ id, belong_type, coupon_file_name, remarks }: { id: number, belong_type: number, coupon_file_name: string, remarks: string }) {
        return this.fetch({
            url: `/out-billing/sys/coupon/${id}`,
            method: 'put',
            data: { belong_type, coupon_file_name, remarks }
        })
    }

    /**
     * 上传优惠券图片
     * @param param0 
     * @returns 
     */
    static couponSys_uploadCouponImage({ sys_coupon_id, imageFile }: { sys_coupon_id: string, imageFile: File }) {
        let formData = new FormData()
        formData.append('imageFile', imageFile, imageFile.name)
        formData.append('sys_coupon_id', sys_coupon_id)
        return this.fetch({
            url: '/out-billing/sys/coupon/file',
            method: 'post',
            data: formData
        })
    }


    /************* couponTenant ****************************************************************/
    /**
     * 查询租户已领取优惠券列表
     * @param { number } usage - 1 公有云 2 sass 3 私有云
     */
    static couponTenant_getCoupons({ current_page, page_size, tenant_id, usage }: { current_page: number, page_size: number, tenant_id: string, usage: number }) {
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/coupon`,
            method: 'get',
            params: { current_page, page_size, usage }
        })
    }
    /**
     * 获取租户可领取优惠券列表
     */
    static couponTenant_getCanReceiveCoupons({ tenant_id }: { tenant_id: string }) {
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/couponSys`,
            method: 'get'
        })
    }
    /**
     * 租户领取优惠券
     */
    static couponTenant_receiveCoupons({ tenant_id, couponSysIds }: { tenant_id: string, couponSysIds: string }) {
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/coupon`,
            method: 'post',
            params: {
                couponSysIds
            }
        })
    }
    /************* order ****************************************************************/
    /**
     * 查询订单(管理员接口)
     */
    static order_getOrdersAdmin({ base, current_page, page_size, end_date, start_date, state, tenant_ids, trade_no }: { base?: number, current_page: number, page_size: number, end_date: number, start_date: number, state: number, tenant_ids: string, trade_no?: string }) {
        return this.fetch({
            url: `/out-billing/order`,
            method: 'get',
            params: { base, current_page, page_size, end_date, start_date, state, tenant_ids, trade_no }
        })
    }

    /**
     * 下载订单
     * @param param0 
     * @returns 
     */
    static order_downloadOrdersByAdmin({ base, end_date, start_date, state, tenant_ids, trade_no }: { base?: number, end_date: number, start_date: number, state: number, tenant_ids: string, trade_no?: string }) {
        return this.fetch({
            url: '/out-billing/order/download',
            method: 'post',
            params: { base, end_date, start_date, state, tenant_ids, trade_no }
        })
    }

    /**
     * 退订订单
     */
    static order_refundForOrder({ tenant_id, order_id }: { tenant_id: number, order_id: number }) {
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/order/${order_id}/refund`,
            method: 'post'
        })
    }
    /**
     * 查询订单（云平台接口）
     */
    static order_getOrdersCloud({ current_page, page_size, tenant_id, state }: { current_page: number, page_size: number, tenant_id: string, state: number }) {
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/order`,
            method: 'get',
            params: { current_page, page_size, state }
        })
    }
    /**
     * 添加订单
     */
    static order_addOrder({ tenant_id, coupon_id, duration, pattern, pay_type, to_package_content, type, from_package_id }: { tenant_id: number, coupon_id: number, duration: number, pattern: number, pay_type: number, to_package_content: PackageContent, type: number, from_package_id: string }) {
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/order`,
            method: 'post',
            data: {
                coupon_id,
                duration,
                pattern,
                pay_type,
                to_package_content,
                type,
                from_package_id
            }
        })
    }
    /**
     * 获取订单金额
     */
    static order_getOrderAmount({ tenant_id, from_package_id, duration, pattern, type, apiPackageContent }: { tenant_id: number, from_package_id: number, duration: number, pattern: number, type: number, apiPackageContent: PackageContent }) {
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/order/amount?duration=${duration}&pattern=${pattern}&type=${type}&from_package_id=${from_package_id}`,
            method: 'post',
            data: apiPackageContent
        })
    }
    /**
     * 支付订单
     */
    static order_payForOrder({ tenant_id, order_id }: { tenant_id: number, order_id: number }) {
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/order/${order_id}/pay`,
            method: 'post'
        })
    }
    /**
     * 取消订单
     */
    static order_cancelOrder({ tenant_id, order_id }: { tenant_id: number, order_id: number }) {
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/order/${order_id}/cancel`,
            method: 'delete'
        })
    }
    /**
     * 上传发票
     */
    static order_addSingleReceipt({ tenant_id, order_id, file }: { tenant_id: number, order_id: number, file: any }) {
        let formData = new FormData()
        formData.append('file', file)
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/order/${order_id}/uploadReceipt`,
            method: 'post',
            data: formData
        })
    }
    /************* package ****************************************************************/

    /**
     * 查询套餐列表（管理员接口）
     */
    static package_getPackagesAdmin({ current_page, page_size, tenant_id, type, base, state }: { current_page: number, page_size: number, tenant_id: number, type: number, base: number, state: number }) {
        return this.fetch({
            url: `/out-billing/packages`,
            method: 'get',
            params: { current_page, page_size, tenant_id, type, base, state }
        })
    }

    /**
     * 查询租户单个套餐
     * @param { number } type - 默认不填返回当前生效Package套餐 1 付费Package套餐 2 Sass套餐 3 私有化套餐
     */
    static package_getPackage({ tenant_id, type, package_id }: { tenant_id: number, type: number, package_id?: number }) {
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/package`,
            method: 'get',
            params: { type, packageId: package_id }
        })
    }
    /**
     * 查询套餐列表（cloud接口）
     */
    static package_getPackages({ current_page, page_size, tenant_id, type, base, state }: { current_page: number, page_size: number, tenant_id: number, type: number, base: number, state: number }) {
        return this.fetch({
            url: `/out-billing/tenants/${tenant_id}/packages`,
            method: 'get',
            params: { current_page, page_size, type, base, state }
        })
    }
    /**
     * 查询当前系统下已生效的套餐统计 
     */
    static package_getUsageStatistics() {
        return this.fetch({
            url: `/out-billing/all/usage-statistics`,
            method: 'get'
        })
    }
    /************* packageItem ****************************************************************/


    /**
     * 获取套餐规格列表
     * @param { number } type - 默认不填返回当前套餐规格列表 1 付费Package套餐 2 Sass套餐 3 私有化套餐
     * @param { number } platform - 部署系统 0 标准IoT、1 智慧CAN、2 智慧光伏、3 智能照明。
     */
    static packageItem_getPackageItem({ current_page, type, page_size, platform }: { current_page?: number, type?: number, page_size?: number, platform: number }) {
        return this.fetch({
            url: `/out-billing/content/item`,
            method: 'get',
            params: { current_page, type, page_size, platform }
        })
    }
    /**
     * 更新套餐规格项
     * @param {number} [params.discount] 折扣 ，默认不打折
     * @param {string} [params.expression] 计费项公式
     * @param {number} [params.id] 
     * @param {string} [params.key] 计费参数 键 转换驼峰式
     * @param {string} [params.name] 计费参数描述
     * @param {number} [params.type] 
     * @param {number} [params.step] 步进数
     * @param {number} [params.basic] 基础免费套参数
     * @param {number} [params.value] 对应type值(结合后端packagetype定义，云套餐参数不可重复)
     */
    static packageItem_updatePackageContenItem({ discount, expression, id, key, name, type, value, itemId, step, basic }: { discount: number, number_simultaneous_connections: number, expression: string, id: number, key: string, name: string, type: number, value: number, itemId: number, step: number, basic: number }) {
        return this.fetch({
            url: `/out-billing/content/item/${itemId}`,
            method: 'put',
            data: { discount, expression, id, key, name, type, value, step, basic }
        })
    }

    /************* tools ****************************************************************/
    /**
     * 私有部署-评估配置
     */
    static tools_assessDeploy({ database_storage_space, number_simultaneous_connections, things_num, up_down_stream_connections }: { database_storage_space: number, number_simultaneous_connections: number, things_num: number, up_down_stream_connections: number }) {
        return this.fetch({
            url: `/out-billing/tools/assessDeploy`,
            method: 'post',
            data: {
                database_storage_space,
                number_simultaneous_connections,
                things_num,
                up_down_stream_connections
            }
        })
    }
    /**
     * TPS计算
     */
    static tools_assessTps({ msg_interval, msg_size, things_num }: { msg_interval: number, msg_size: number, things_num: number }) {
        return this.fetch({
            url: `/out-billing/tools/assessTps`,
            method: 'post',
            data: {
                msg_interval,
                msg_size,
                things_num
            }
        })
    }
}
export default ZWSOutBilling
