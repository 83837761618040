<template>
  <div class="z-button-container" v-for="(button, index) in buttonList" :key="index">
    <el-tooltip v-if="button?.showText" effect="light" :content="button?.showText" placement="top">
      <el-button :type="button.type" :plain="button.plain" :size="button.size" :icon="button.icon"
        :disabled="button.disabled" v-buttonPermission="button.permission" v-buttonBlur v-monitoring="button.monitoring"
        v-bind="button" @click="buttonClicked($event, button, index)">
        <template #default v-if="button.text">
          {{ button.text }}
        </template>
      </el-button>
    </el-tooltip>
    <el-button v-else :type="button.type" :plain="button.plain" :size="button.size" :icon="button.icon"
      :disabled="button.disabled" v-buttonPermission="button.permission" v-buttonBlur v-monitoring="button.monitoring"
      v-bind="{ ...button, text: undefined }" @click="buttonClicked($event, button, index)">
      <template #default v-if="button.text">
        {{ button.text }}
      </template>
    </el-button>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, computed } from "vue";
import { Monitoring } from '../../../sdk/ts/tool/ZDirectives/directives/monitoring'
import { debounce } from '../../../sdk/ts/tool/ZFirmwareTool'
import { useI18n } from 'vue-i18n'

import { useLocale } from '../hooks/useLocale'
export interface Button {
  index?: number;
  type?: string;
  plain?: boolean;
  size?: string;
  text?: string;
  icon?: string;
  disabled?: boolean;
  showText?: string; // 开启悬浮
  permission?: string | string[] // 按钮权限
  monitoring?: Monitoring
}

export default defineComponent({
  inheritAttrs: false,
  emits: ["buttonClicked"],
  props: {
    buttonList: {
      type: Array as PropType<Array<Button>>,
      default: () => null,
    },
    // 防抖时间
    clickDelay: {
      type: Number,
      default: 300
    }
  },
  setup(props, { emit }) {
    const { t } = useLocale()
    const $t = t

    const defaultButtonList = computed(() => [
      {
        text: t('zws.ZButtonListCtrl.cancel'),
      },
      {
        text: t('zws.ZButtonListCtrl.confirm'),
        type: "primary",
      },
    ])
    const propsButtonList = computed(() => props.buttonList)
    const buttonList = computed(() => propsButtonList.value ?? defaultButtonList.value as any)

    function debounce(fn: Function, delay: number | undefined) {
      let timer: any
      return function (this: any, ...params: any[]) {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          fn.call(this, ...arguments)
        }, delay)
      }
    }

    const buttonClicked = (e: any, button: any, index: number) => {
      button.index = index
      clicked(e, button, index)
      // emit("button-click", button);
    };
    const clicked = debounce((e: any, button: any, index: number) => {
      let target = e.target
      if (target.nodeName == "SPAN" || target.nodeName == 'I') {
        target = target.parentNode
      }
      target.blur()
      button.index = index

      emit("buttonClicked", button);
    }, props.clickDelay);

    return {
      defaultButtonList,
      buttonList,
      buttonClicked,
    }
  },
});
</script>
<style lang="less" scoped>
.z-button-container {
  display: inline-block;
  margin-left: 10px;

  &:first-child {
    margin-left: 0px;
  }
}
</style>
