<template>
  <div class="z-input-user-info">
    <div class="z-input-user-info-main">
      <el-form ref="inputUserInfoFrom" :model="inputUserInfo" label-position="left" label-width="80px" :rules="rules">
        <el-form-item prop="mobile" label-width="120px">
          <template #label>
            <!-- <span class="required-asterisk">*</span> -->
            <span class="label-must-text">{{ t('lib.register.info.mobile') }}</span>
          </template>
          <div>
            <ZAreaMobileInputCtrl v-model="inputUserInfo.mobile" :lang="$i18n.locale"></ZAreaMobileInputCtrl>
          </div>
        </el-form-item>
        <el-form-item prop="smscode" label-width="120px" :class="errorText ? 'is-error' : ''">
          <template #label>
            <span class="required-asterisk">*</span>
            <span class="label-must-text">{{ t('lib.register.info.smsCode') }}</span>
          </template>
          <div class="input-wrapper">
            <ZSixDigitPasswordCtrl v-model="inputUserInfo.mobile_verification_code" v-model:errorText="errorText" backgroundColor="#ffffff">
            </ZSixDigitPasswordCtrl>
            <ZCountDownCtrl v-model:isSendCode="isSendCode" @getVerificationCodeClicked="getVerificationCodeClicked"></ZCountDownCtrl>
          </div>
        </el-form-item>
        <el-form-item prop="name" label-width="120px">
          <template #label>
            <span class="label-must-text">{{ t('lib.register.info.username') }}</span>
          </template>
          <div class="input-wrapper">
            <el-input class="input password-input" v-model="inputUserInfo.name" :placeholder="t('lib.register.info.username')"> </el-input>
          </div>
        </el-form-item>
        <!-- <el-form-item prop="namespace" label-width="120px">
                        <template #label>
                            <span class="label-must-text">namespace</span>
                        </template>
                        <div class="input-wrapper">
                            <el-input class="input" v-model="inputUserInfo.namespace" placeholder="请输入namespace"></el-input>
                        </div>
                    </el-form-item> -->
        <el-form-item prop="password" label-width="120px" class="long-tips">
          <template #label>
            <span class="label-must-text">{{ t('lib.register.info.password') }}</span>
          </template>
          <div class="input-wrapper">
            <!-- <el-input class="input password-input" :readonly="inputReadonly" placeholder="请输入密码" v-model="inputUserInfo.password" clearable show-password @focus="inputFocus"></el-input> -->
            <ZInputPasswordArea
              class="input password-input"
              :readonly="inputReadonly"
              :placeholder="t('lib.register.info.password')"
              v-model="inputUserInfo.password"
              clearable
              show-password
              @focus="inputFocus"
            >
            </ZInputPasswordArea>
          </div>
        </el-form-item>
        <el-form-item prop="confirmPassword" label-width="120px" class="long-tips">
          <template #label>
            <span class="label-must-text">{{ t('lib.register.info.confirmPassword') }}</span>
          </template>
          <div class="input-wrapper">
            <!-- <el-input class="input password-input" :readonly="inputReadonly" placeholder="请再次输入密码" v-model="inputUserInfo.confirmPassword" clearable show-password @focus="inputFocus"></el-input> -->
            <ZInputPasswordArea
              class="input password-input"
              :readonly="inputReadonly"
              :placeholder="t('lib.register.tips.confirmPassword')"
              v-model="inputUserInfo.confirmPassword"
              clearable
              show-password
              @focus="inputFocus"
            >
            </ZInputPasswordArea>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div :class="['z-input-user-info-protocol', $i18n.locale]">
      <el-checkbox v-model="protocolChecked"></el-checkbox>
      <span style="font-size: 14px; margin-left: 10px">{{ t('lib.register.agree') }}</span>
      <el-button link type="text" @click="protocolClicked">{{ t('lib.register.protocol') }}</el-button>
    </div>
    <div :class="['button-wrapper', $i18n.locale]">
      <el-button class="nextStepButton" type="primary" icon="iconfont iconxiayibu" @click="nextStep"
        >{{ t('lib.register.next') }}
      </el-button>
      <div class="login-tips">
        <span class="tip-text">{{ t('lib.register.isExist') }}</span>
        <el-button link type="text" @click="loginTextClick">{{ t('lib.register.login') }}</el-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
// import ZAreaMobileInputCtrl from '../../../ctrl/ZAreaMobileInputCtrl.vue'
// import ZCountDownCtrl from '../../../ctrl/ZCountDownCtrl.vue'
// import ZSixDigitPasswordCtrl from '../../../ctrl/ZSixDigitPasswordCtrl.vue'

import { ZValidateDataTool as ZValidateTool } from '@zws/tools'
// import ZInputPasswordArea from '../../ZCommonArea/ZInputPasswordArea.vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
// import { useLocale } from '../../../hooks/useLocale'
import { useI18n } from 'vue-i18n'
import { checkUserTenantValidate, getVerificationCodes } from '@zws/axios-fetch'
export default defineComponent({
  components: {
    // ZAreaMobileInputCtrl,
    // ZCountDownCtrl,
    // ZSixDigitPasswordCtrl,
    // ZInputPasswordArea,
  },
  setup(prop, context) {
    // let { t } = useLocale()
    let { t } = useI18n()
    const store = useStore()
    let router = useRouter()
    const inputUserInfo = reactive(store.getters.registerInputUserInfo)

    let validateMobile = async (rule: any, value: any, callback: Function) => {
      if (!ZValidateTool.phoneNumberValidator(value)) {
        return callback(new Error(t('lib.login.error.004') || '请输入手机号码，国内为11位，国际为2-16位'))
      } else {
        await checkUserTenantValidate({
          field: 'mobile',
          value: value
        })
          .then((res: any) => {
            if (!res) {
              return callback(new Error(t('lib.login.error.007') || '手机号码已存在'))
            } else {
              return callback()
            }
          })
          .catch((error: any) => {
            ElMessage.error(error.err.data.message)
          })
      }
    }
    let validateName = async (rule: any, value: any, callback: Function) => {
      if (!ZValidateTool.nameValidator(value, 2, 20)) {
        return callback(new Error(t('lib.login.error.008') || '请输入2-32位，只可以包含字母数字下划线'))
      } else {
        return callback()
      }
    }
    let validateUser = async (rule: any, value: any, callback: Function) => {
      if (!ZValidateTool.nameValidator(value, 2, 20)) {
        return callback(new Error(t('lib.login.error.008') || '请输入2-20位，只可以包含字母数字下划线'))
      } else if (!ZValidateTool.notNumberHeadValidator(value)) {
        return callback(new Error(t('lib.login.error.008') || '用户名首个字符不能是数字'))
      } else {
        await checkUserTenantValidate({
          field: 'name',
          value: value
        })
          .then((res: any) => {
            if (!res) {
              return callback(new Error(t('lib.login.error.009') || '用户名已存在'))
            } else {
              return callback()
            }
          })
          .catch((error: any) => {
            ElMessage.error(error.err.data.message)
          })
      }
    }
    let validatePassword = (rule: any, value: any, callback: Function) => {
      if (!ZValidateTool.passwordValidator(value)) {
        return callback(
          new Error(t('lib.login.error.010') || '密码必须包含8-16位大小写字母、数字和指定特殊符号(!@#$%^&*0)，且至少包含2种不同类型的字符')
        )
      } else callback()
    }
    let validateConfirmPassword = (rule: any, value: any, callback: Function) => {
      if (!ZValidateTool.passwordValidator(value)) {
        return callback(
          new Error(t('lib.login.error.010') || '密码必须包含8-16位大小写字母、数字和指定特殊符号(!@#$%^&*0)，且至少包含2种不同类型的字符')
        )
      } else if (inputUserInfo.confirmPassword !== inputUserInfo.password) {
        return callback(new Error(t('lib.login.error.011') || '两次输入的密码不一致'))
      } else callback()
    }
    const rules = {
      mobile: [
        { required: true, message: t('lib.login.error.002') || '手机号码不能为空', trigger: 'change' },
        { validator: validateMobile, trigger: 'change' }
      ],
      name: [
        { required: true, message: t('lib.login.error.013') || '用户名不能为空', trigger: 'blur' },
        { validator: validateName, trigger: 'change' },
        { validator: validateUser, trigger: 'blur' }
      ],
      // namespace: [
      //     { required: true, message: '命名空间不能为空', trigger: 'blur' },
      //     { validator: validateNamespace, trigger: 'change' },
      // ],
      password: [
        { required: true, message: t('lib.login.error.006') || '密码不能为空', trigger: 'change' },
        { validator: validatePassword, trigger: 'change' }
      ],
      confirmPassword: [
        { required: true, message: t('lib.login.error.012') || '确认密码不能为空', trigger: 'change' },
        { validator: validateConfirmPassword, trigger: 'change' }
      ]
    }
    let errorText: any = ref('')
    let inputUserInfoFrom: any = ref(null)
    let inputReadonly: any = ref(true)
    let isSendCode: any = ref(false)
    let isSendCodeing = false
    function getVerificationCodeClicked() {
      if (isSendCodeing) return
      isSendCodeing = true
      inputUserInfoFrom.value.validateField(['mobile'], (error: any) => {
        if (!error) {
          getVerificationCodes({
            type: 1,
            destination: inputUserInfo.mobile,
            purpose: 'register'
          })
            .then((res: any) => {
              isSendCode.value = true
              ElMessage.success(t('lib.register.tips.getCodeSuccess') || '获取手机验证码成功')
            })
            .catch((error: any) => {
              ElMessage.error(error?.data?.error)
            })
            .finally(() => {
              isSendCodeing = false
            })
        } else {
          isSendCodeing = false
        }
      })
    }
    let protocolChecked: any = ref(true)
    function protocolClicked() {
      context.emit('protocolClicked')
    }
    function nextStep() {
      inputUserInfoFrom.value.validate((validate: any) => {
        if (inputUserInfo.mobile_verification_code === '') {
          errorText.value = t('lib.login.error.014') || '请输入6位验证码'
          return
        }
        if (validate) {
          if (!protocolChecked.value) {
            ElMessage.warning(t('lib.register.tips.checkAgreement') || '请阅读并勾选云平台服务和隐私协议')
            return
          }
          inputUserInfo.username = inputUserInfo.name
          // 默认设置inputUserInfo.namespace 为 name
          inputUserInfo.namespace = inputUserInfo.name
          store.commit('registerInputUserInfo', inputUserInfo)
          context.emit('nextStep')
        }
      })
    }
    function loginTextClick() {
      context.emit('loginTextClick')
    }
    function inputFocus() {
      inputReadonly.value = false
    }
    function setSmsErrorText(text: string) {
      errorText.value = text
    }
    return {
      t,
      inputUserInfo,
      errorText,
      rules,
      isSendCode,
      protocolChecked,
      inputUserInfoFrom,
      inputReadonly,
      getVerificationCodeClicked,
      protocolClicked,
      nextStep,
      loginTextClick,
      inputFocus,
      setSmsErrorText
    }
  }
})
</script>
<style lang="less" scoped>
.z-input-user-info.en {
  :deep(.el-form-item.is-error) {
    margin-bottom: 30px;
  }
}

.z-input-user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.z-input-user-info-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 330px;
}

.label-must-text {
  font-size: 14px;
  color: #999999;
}

.label-optional-text {
  padding-left: 10px;
  font-size: 14px;
  color: #999999;
}

.required-asterisk {
  padding-right: 4px;
  color: #f56c6c;
}

.input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 305px;
}

.input {
  width: 305px;
  height: 44px;
}

.z-input-user-info-protocol {
  margin-left: 65px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.z-input-user-info-protocol.en {
  margin-left: 105px;
}

.button-wrapper {
  width: 550px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 70px;
  margin-top: 20px;
}

.nextStepButton {
  width: 98px;
  height: 40px;
  margin-right: 102px;
}

.button-wrapper.en {
  .nextStepButton {
    margin-right: 68px;
  }
}

.login-tips {
  font-size: 14px;
  margin-top: 11px;
  color: #333333;

  .tip-text {
    padding-top: 150px;
  }

  .link {
    font-size: 14px;
    margin-bottom: 5px;
    color: #80b33f;
    cursor: pointer;
  }
}
.long-tips {
  margin-bottom: 30px;
}
</style>
