import { XMLParser } from './xmlParser'

const num2pow32 = Math.pow(2, 32)

function getU8Bits (u8, offset, length) {
  // 这个是按照小端来解析的，u8必须重新创建。
  if (u8 instanceof Uint8Array) {
    if (offset >= u8.length * 8) {
      throw new Error('offset大于等于u8的位长度')
    }
    if (offset + length - 1 >= u8.length * 8) {
      throw new Error('获取的位数超过u8的尾部')
    }
    const endbit = offset + length
    let value = 0
    let culLen = 0
    while (offset < endbit) {
      const offbits = offset % 8
      const startByte = Number.parseInt(offset / 8)
      let temEnd = (startByte + 1) * 8
      if (temEnd >= endbit) {
        temEnd = endbit
      }
      const len = temEnd - offset
      const mask = (1 << len) - 1
      const temValue = (u8[startByte] >> offbits) & mask
      value += temValue << culLen
      culLen += len
      offset = temEnd
    }
    return value
  }
  throw new Error('u8必须是Uint8Array')
}

function hexadecimalToU8(raw) {
  const typedArray = new Uint8Array(raw.match(/[\da-f]{2}/gi).map(function (h) {
      return parseInt(h, 16)
  }))
  return typedArray
}

class DataType {
  static CANRealTime = 0x01
  static GPSRealTime = 0x02
  static CAN = 0x11
  static GPS = 0x12
  static Log = 0x21
  static Record = 0x31
  static CancelRecord = 0x32
  static Unknow = 0x00
}

class DeviceRaw {
  id = 0 // 每次传输递增，防止收到包重复，收到包后应对该包判断是否与上一包的编号重复，若重复过滤。
  reseve = 0 // 保留，为 0
  type = DataType.Unknow // 该透传数据包类型，类型定义如表 1.35 所示
  flag = 0 // Bit0 : 数据是否压缩，为 1 压缩 Bit1~7 : 保留
  totalNum = 0 // 本次传输总包数
  num = 0 // 本次上传中该包的包号
  dataLength = 0 // 数据域长度
  data = [] // 数据域 包数据 注:当数据包类型为 CAN 数据包(不包含 LIN、 DI 等)，且为上传到服务器操作时，数据域前 8 字节为基准 CAN 时间戳，单位为 ms。
  CRC16 = 0 //  从包编号到数据区最后一个字节的 CRC 校验，其 CRC16 格式为 CRC16 Modbus 格式
  Tag = 0 // 当数据包为记录文件数据时，数据域前 4 字节为文件标签， 由客户端请求获取文件时下发。
  constructor (...param) {
    if (param.length > 0) {
      let u8 = param[0]
      // 兼容can-common获取文件
      let isCommon = !!param[1]
      const offset = (param.filter(v => v).length > (isCommon ? 2 : 1)) ? param[1] : 4
      if (u8 instanceof Uint8Array) {
        // 前12字节为“服务器规定包头”，暂时不使用，跳过
        u8 = new Uint8Array(u8.buffer, offset)
        const dataView = new DataView(u8.buffer, u8.byteOffset, u8.length)
        this.id = u8[0]
        this.resever = u8[1]
        this.type = u8[2]
        this.flag = u8[3]
        this.totalNum = dataView.getUint32(4)
        this.num = dataView.getUint32(8)
        this.dataLength = dataView.getUint32(12)
        this.CRC16 = dataView.getUint16(dataView.byteLength - 2)
        // 基准时间单位是毫秒（CANFrame中的时间是相对时间，相对时间单位为100微秒，相对时间+基准时间为绝对时间）
        const baseTime = dataView.getUint32(16) * num2pow32 + dataView.getUint32(20)
        let newU8 = new Uint8Array(dataView.buffer, 24 + dataView.byteOffset, dataView.byteLength - 26)
        if (this.type === DataType.Record) {
          this.Tag = dataView.getUint32(16, true)
          newU8 = new Uint8Array(dataView.buffer, 20 + dataView.byteOffset, dataView.byteLength - 22)
        }
        if (this.type === DataType.CAN || this.type === DataType.CANRealTime) {
          for (let i = 0; i < newU8.length; i += 20) {
            const canData = new CANData(new Uint8Array(newU8.buffer, i + newU8.byteOffset, 20), baseTime, false)
            this.data.push(canData)
          }
        } else if (this.type === DataType.GPS || this.type === DataType.GPSRealTime) {
          for (let i = 0; i < newU8.length; i += 24) {
            const gpsData = new GPSData(new Uint8Array(newU8.buffer, i + newU8.byteOffset, 24), baseTime, false)
            this.data.push(gpsData)
          }
        } else if (this.type === DataType.Record) {
          this.data = newU8
        }
      } else {
        throw new Error('u8必须是Uint8Array')
      }
    }
  }
}

class CRFFile {
  type = 0
  ver = 0
  len = 0
  time = 0
  fst = 0
  lst = 0
  data = []
  totalNum = 0
  constructor (...param) {
    if (param.length > 0) {
      let [u8, progressFun, progressCount] = param
      if (u8 instanceof Uint8Array) {
        const dataView = new DataView(u8.buffer, u8.byteOffset, u8.length)
        this.type = dataView.getUint16(0, true)
        this.ver = dataView.getUint16(2, true)
        this.len = dataView.getUint32(4, true)
        this.time = dataView.getUint32(12, true) * num2pow32 + dataView.getUint32(8, true)
        this.fst = dataView.getUint32(16, true)
        this.lst = dataView.getUint32(20, true)
        let offset = 24
        const newU8 = new Uint8Array(dataView.buffer, 24 + dataView.byteOffset, dataView.byteLength - 24)
        const baseTime = this.time
        let i = 0
        while (i < newU8.length) {
          let size = dataView.getUint32(offset + 4 + i, true)
          if (i + size >= newU8.length) {
            size = newU8.length - i
          }
          this.totalNum += Number.parseInt(size / 20)
          i = i + 8 + size
        }
        i = 0
        while (i < newU8.length) {
          let size = dataView.getUint32(offset + 4, true)
          if (i + size >= newU8.length) {
            size = newU8.length - i
          }
          try {
            this._parseCrfFileData(new Uint8Array(newU8.buffer, i + newU8.byteOffset, size), baseTime, progressFun, progressCount)
          } catch (er) {
            progressFun(this.data.length, this.totalNum, 'error')
          }
          i = i + 8 + size
          offset = offset + 8 + size
        }
        if (typeof progressFun === 'function') {
          progressFun(this.totalNum, this.totalNum)
        }
      } else {
        throw new Error('u8必须是Uint8Array')
      }
    }
  }
  _parseCrfFileData (u8, baseTime, progressFun = null, progressCount = 1000) {
    if (u8.length < 28) {
      return
    }
    progressCount = progressCount || 1000
    const dataView = new DataView(u8.buffer, u8.byteOffset, u8.length)
    const offset = dataView.getUint32(0, true)
    let progressNum = this.data.length
    for (let i = 8 + offset; i < u8.length; i += 20) {
      const canData = new CANData(new Uint8Array(u8.buffer, i + u8.byteOffset, 20), baseTime, true)
      this.data.push(canData)
      progressNum += 1
      if (progressNum % progressCount === 0 && typeof progressFun === 'function') {
        progressFun(progressNum, this.totalNum)
      }
    }
    if (offset > 0) {
      for (let i = 8; i < offset; i += 20) {
        const canData = new CANData(new Uint8Array(u8.buffer, i + u8.byteOffset, 20), baseTime, true)
        this.data.push(canData)
        progressNum += 1
        if (progressNum % progressCount === 0 && typeof progressFun === 'function') {
          progressFun(progressNum, this.totalNum)
        }
      }
    }
  }
}

class GPXFile {
  data = []
  constructor (...param) {
    if (param.length > 0) {
      const content = param[0]
      const gpx = XMLParser.parserXML(content)
      if (gpx) {
        const trkpts = XMLParser.getXMLNodeByName(gpx, 'trkpt')
        for (const trkpt of trkpts) {
          const gpsData = new GPSData()
          for (const attr of trkpt.attrs) {
            if (attr.name === 'lat') {
              gpsData.latitude = Number.parseFloat(attr.value)
            } else if (attr.name === 'lon') {
              gpsData.longitude = Number.parseFloat(attr.value)
            }
          }
          const times = XMLParser.getXMLNodeByName(trkpt, 'time')
          if (times.length > 0) {
            const time = times[0]
            const timeString = time.value.replace(/-/g, '/').replace(/T/g, ' ').replace(/Z/g, '')
            gpsData.timestamp = Date.parse(timeString)
          }
          const speeds = XMLParser.getXMLNodeByName(trkpt, 'gpxtpx:speed')
          if (speeds.length > 0) {
            const speed = speeds[0]
            gpsData.speed = Number.parseFloat(speed.value)
          }
          this.data.push(gpsData)
        }
      }
    }
  }
}

class CANData {
  timestamp = 0 // 时间戳
  baseTime = 0 // 基准时间戳
  _id = 0
  id1 = 0 // CAN 帧 ID、或 LIN Ident [bit28:0]: 帧 ID，或 DI 值
  id2 = 0 // [bit31:29]: 0:CAN 帧 1:DI 输入 2:LIN 帧 3:错误帧
  /**
   * CAN 报文标志:
    [bit7]: 1:错误报文 0:正常报文
    [bit6]: 1:扩展帧，0:标准帧
    [bit5]: 1:远程帧，0:数据帧
    [bit3:0] :发送类型: 0:正常发送 1:单次发送 2:自发自收 3:单次自发自收
    为LIN 帧时，该标志为 0
   */
  _flag = 0
  errorFlag = 0
  extendFlag = 0
  remoteFlag = 0
  sendFlag = 0
  channel = 0 // CAN 通道
  dataLength = 0 // CAN/LIN 报文数据长度
  data = new Uint8Array(8) // CAN/LIN 报文数据
  set id (val) {
    this._id = val
    const u8 = new Uint8Array(Uint32Array.of(val).buffer)
    this.id1 = getU8Bits(u8, 0, 29)
    this.id2 = getU8Bits(u8, 29, 3)
  }
  get id () {
    return this._id
  }
  set flag (val) {
    this._flag = val
    const u8 = new Uint8Array(Uint16Array.of(val).buffer)
    this.errorFlag = getU8Bits(u8, 7, 1)
    this.extendFlag = getU8Bits(u8, 6, 1)
    this.remoteFlag = getU8Bits(u8, 5, 1)
    this.sendFlag = getU8Bits(u8, 0, 3)
  }
  get flag () {
    return this._flag
  }
  constructor (...param) {
    if (param.length === 3) {
      const u8 = param[0]
      const baseTime = param[1]
      const flag = param[2]
      if (u8 instanceof Uint8Array) {
        const dataView = new DataView(u8.buffer, u8.byteOffset, u8.length)
        this.timestamp = baseTime + Math.floor(dataView.getUint32(0, flag) / 10) // 当前报文接收 /发送时间,该时间是相对时间，单位为100微秒
        this.baseTime = baseTime
        this.id = dataView.getUint32(4, flag)
        this.flag = dataView.getUint16(8, flag)
        this.channel = u8[10]
        this.dataLength = u8[11]
        this.data = new Uint8Array(u8.buffer, 12 + u8.byteOffset, 8)
      } else {
        throw new Error('u8必须是Uint8Array')
      }
    }
  }
}

class GPSData {
  _timestamp = 0 // 时间戳
  get timestamp () {
    return this._timestamp
  }
  set timestamp (val) {
    this._timestamp = val
    const date = new Date(val)
    this.year = date.getFullYear()
    this.month = date.getMonth() + 1
    this.day = date.getDate()
    this.hour = date.getHours()
    this.minute = date.getMinutes()
    this.second = date.getSeconds()
  }
  latitude = 0 // 纬度，float 类型
  longitude = 0 // 经度，float 类型
  speed = 0 // 速度，float 类型
  year = 0 // 该定位数据时间-年
  month = 0 // 该定位数据时间-月
  day = 0 // 该定位数据时间-日
  hour = 0 // 该定位数据时间-时
  minute = 0 // 该定位数据时间-分
  second = 0 // 该定位数据时间-秒
  constructor (...param) {
    if (param.length === 3) {
      const u8 = param[0]
      const baseTime = param[1]
      const flag = param[2]
      if (u8 instanceof Uint8Array) {
        const dataView = new DataView(u8.buffer, u8.byteOffset, u8.length)
        this._timestamp = baseTime
        this.latitude = dataView.getFloat32(0, flag)
        this.longitude = dataView.getFloat32(4, flag)
        this.speed = dataView.getFloat32(8, flag)
        this.year = dataView.getUint16(12, flag)
        this.month = dataView.getUint16(14, flag)
        this.day = dataView.getUint16(16, flag)
        this.hour = dataView.getUint16(18, flag)
        this.minute = dataView.getUint16(20, flag)
        this.second = dataView.getUint16(22, flag)
        const date = new Date()
        date.setUTCFullYear(this.year)
        date.setUTCMonth(this.month - 1) // js中的月份是从0开始的
        date.setUTCDate(this.day)
        date.setUTCHours(this.hour)
        date.setUTCMinutes(this.minute)
        date.setUTCSeconds(this.second)
        this._timestamp = date.getTime()
      } else {
        throw new Error('u8必须是Uint8Array')
      }
    }
  }
}

export { DeviceRaw, CANData, GPSData, DataType, CRFFile, GPXFile, getU8Bits, hexadecimalToU8 }
