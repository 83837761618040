<template>
  <div class="zws-footer">
    <span v-if="systemWebBottomCompany" class="zws-footer-text">{{ systemWebBottomCompany }}</span>
    <span v-if="systemWebBottomPhone" class="zws-footer-text zws-footer-margin">{{ $t('footer.phone') }}{{ systemWebBottomPhone }}</span>
    <span v-if="systemWebBottomEmail" class="zws-footer-text">{{ $t('footer.email') }}{{ systemWebBottomEmail }}</span>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const { t, locale } = useI18n()
    // const companyInfo = computed(() => {
    //   console.log(store.getters.tenantCustomizationInfo, 'store.getters.tenantCustomizationInfo')
    //   let systemName = '广州致远电子股份有限公司'
    //   if (store.getters.tenantCustomizationInfo?.systemName) {
    //     systemName = store.getters.tenantCustomizationInfo?.systemName
    //   }
    //   return systemName
    // })
    // const companyInfo = ref<string>('')
    const systemWebBottomPhone = ref('400-888-4005')
    const systemWebBottomEmail = ref('zws@zlg.cn')
    const systemWebBottomCompany = ref('')

    watch(
      [() => store.getters.tenantCustomizationInfo, locale],
      newVal => {
        const tenantCustomizationInfo = store.getters.tenantCustomizationInfo
        systemWebBottomPhone.value = tenantCustomizationInfo?.systemWebBottomPhone || '400-888-4005'
        systemWebBottomEmail.value = tenantCustomizationInfo?.systemWebBottomEmail || 'zws@zlg.cn'
        systemWebBottomCompany.value = tenantCustomizationInfo?.systemWebBottomCompany
          ? tenantCustomizationInfo?.systemWebBottomCompany?.includes('广州致远电子股份有限公司')
            ? t('footer.company')
            : tenantCustomizationInfo?.systemWebBottomCompany
          : t('footer.company')

        // if (JSON.stringify(newVal) !== '{}') {
        //   systemWebBottomCompany.value = newVal?.systemWebBottomCompany
        //   systemWebBottomPhone.value = newVal?.systemWebBottomPhone || '400-888-4005'
        //   systemWebBottomEmail.value = newVal?.systemWebBottomEmail || 'zws@zlg.cn'
        // } else {
        //   systemWebBottomPhone.value = '400-888-4005'
        //   systemWebBottomEmail.value = 'zws@zlg.cn'
        //   systemWebBottomCompany.value = t('footer.company')
        // }
      },
      {
        deep: true,
        // immediate: true
        immediate: true
      }
    )

    return {
      systemWebBottomPhone,
      systemWebBottomEmail,
      systemWebBottomCompany
    }
  }
})
</script>
//
<style lang="less" scoped>
// .zws-footer {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }
.zws-footer-text {
  font-size: 14px;
  color: #b6b6b6;
  margin: 0 20px;
}

// .zws-footer-margin {
//   margin-left: 40px;
//   margin-right: 40px;
// }
</style>
