import ZWSBaseModule from '../httpBase/ZWSBaseModule'
import { Pagination } from './ZWSPagination'
import { Label } from './ZWSTenant'

/************* connection-controller ****************************************************************/

enum LinkType {
    Http,
    Mqtt3x,
    Mqtt5x,
    RabbitMq,
    Kafka
}

export interface LinkLabel {
    connection_id: number,
    id: number,
    label_id: number,
    label_value: string
}

export interface Connection {
    id: number                 //链接ID
    name: string                //链接名称
    description: string         //链接描述
    type: LinkType              //链接类型
    uri: string                 //远程消息通道的链接字符串
    cnumber: number             //需要链接这个通道的服务数
    configuration: string       //和链接类型相关的配置（JSON形式）
    tenant_ids: Array<number>   //租户ID列表
    labels: Array<Label>        //标签列表
}

export interface ConnectionsResp {
    total: number //数据集总个数
    list: Array<Connection>
}

export interface QueryParams {
    prefix?: string         //模糊查询名称
    label?: string           //标签名称，查询打了特定标签的链接
    label_value?: string     //标签的值，查询打了特定标签和值的链接
    tenant?: string          //租户名称，查询特定租户下的链接
}

export interface PaginationParams {
    page_size?: number, //每页记录数，不填则由后端决定，可默认设定20，最大不超过100
    current_page: number//当前的页码，从1开始
}

export interface Target {
    id?: number              //目标id
    name: string,           //目标名称
    connection_id?: number,  //链接ID
    order?: number,          //
    address_expr: string,   //链接地址，和connection的类型相关，具体中间件上数据存放的位置，比如：RabbitMq的queue 队列名称，Kafka和Mqtt的topic信息
    route_expr: string      //一组路由规则，这组规则作用在每个接入的消息上。对于消息的Envople信息进行规则匹配，一旦规则匹配成功则选择当前的Target进行消息下发处理。
    [propName: string]: any
}

/************* connection-target-controller ****************************************************************/
/************* connection-label-controller ****************************************************************/


/**
 * 系统管理相关
 */
class ZWSConnection extends ZWSBaseModule {

    /************* connection-controller ****************************************************************/

    // 查询链接（模糊查询）
    static connectionController_getConnections(params?: QueryParams, pparams?: PaginationParams) {
        return this.fetch({
            url: `/connection/connections`,
            method: 'get',
            params: { ...params, ...pparams },
        })
    }

    // 查询链接（精确查询）
    static connectionController_getSingleConnectionByID(connection_id: number, pparams?: PaginationParams) {
        return this.fetch({
            url: `/connection/connections/${connection_id}`,
            method: 'post',
            params: { ...pparams }
        })
    }

    // 创建一条新链接
    static connectionController_addSingleConnection(info: Connection) {
        return this.fetch({
            url: `/connection/connections`,
            method: 'post',
            data: { ...info }
        })
    }

    // 修改一条链接
    static connectionController_updateSingleConnection(info: Connection) {
        return this.fetch({
            url: `/connection/connections/${info.id}`,
            method: 'put',
            data: { ...info }
        })
    }

    // 删除一条链接
    static connectionController_deleteConnections(ids: number[]) {
        let idss = '';

        if (Array.isArray(ids)) {
            ids.forEach((id) => {
                idss += id + ','
            });
        }

        return this.fetch({
            url: `/connection/connections/${idss}`,
            method: 'delete',
        })
    }

    /************* connection-target-controller ****************************************************************/

    // 查询一个链接下的目标对象
    static connectionTargetController_getConnectionTargets(connectionId: number, targetId?: number) {
        let url = '';

        if (targetId !== undefined) {
            url = `/connection/connections/${connectionId}/targets/${targetId}`
        } else {
            url = `/connection/connections/${connectionId}/targets`
        }

        return this.fetch({
            url: url,
            method: 'get'
        })
    }

    // 创建一条链接下的目标
    static connectionTargetController_addSingleConnectionTarget(connectionId: number, target: Target) {
        return this.fetch({
            url: `/connection/connections/${connectionId}/targets`,
            method: 'post',
            data: { ...target }
        })
    }

    // 删除链接目标
    static connectionTargetController_deleteConnectionTargets(connectionId: number, ids: number[]) {
        let idss = '';

        if (Array.isArray(ids)) {
            ids.forEach((id) => {
                idss += id + ','
            });
        }

        return this.fetch({
            url: `/connection/connections/${connectionId}/targets/${idss}`,
            method: 'delete'
        })
    }

    // 修改一条链接下的目标
    static connectionTargetController_updateSingleConnectionTarget(connectionId: number, targetId: number, target: Target) {
        return this.fetch({
            url: `/connection/connections/${connectionId}/targets/${targetId}`,
            method: 'put',
            data: { ...target }
        })
    }

    /************* connection-label-controller ****************************************************************/

    // 获取标签的值
    static connectionLabelController_getLabelVals(label_id: number) {
        return this.fetch({
            url: `/connection/connections/labels/${label_id}`,
            method: 'get',
        }).then((labels: Array<LinkLabel>) => {
            return labels.map(l => {
                return l.label_value
            })
        })
    }
}

export default ZWSConnection
