<template>
            <ZDialogCtrl :title="title || t('zws.ZCommon.tips.tip') || '提示'" :buttonList="buttonList" @buttonClicked="buttonClicked"
                     v-bind="$attrs">
        <div>{{ tip }}{{t('zws.ZCommon.tips.toUserCenterBuy')|| '已达上限，请先到用户中心升级后再操作。'}}</div>
    </ZDialogCtrl>
</template>
<script lang="ts" setup>
import ZDialogCtrl from '../../ctrl/ZDialogCtrl.vue'
import ZAppUrlTool from '../../../../sdk/ts/tool/ZAppUrlTool'
import { useLocale } from '../../hooks/useLocale'

let { t } = useLocale()
const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    tip: String
})

const title = props.title
const tip = props.tip
const buttonList = [
    {
        text: t('zws.ZButtonListCtrl.cancel') || '取消'
    },
    {
        text: t('zws.ZCommon.tips.buy') || '去购买',
        type: 'primary'
    }
]
const buttonClicked = (item: any) => {
    if (item.index === 1) {
        ZAppUrlTool.getUrlByKey('billing').then((res: string) => {
            window.open(`${res}#/Container/Billing/BillingInfo/BillingPurchase?ujwt=${localStorage.getItem('ujwt')}&token=${localStorage.getItem('token')}`)
        })
    }
}
</script>
<style lang="scss" scoped></style>