<template>
    <div class="z-auth-page-area">
        <slot />
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
    setup() {},
})
</script>
<style lang="less" scoped>
.z-auth-page-area {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('@/assets/images/login/login_bg.png');
}
</style>