import * as bcrypt from 'bcryptjs';
// @ts-ignore
import md5 from 'js-md5';

/**
 * 特殊格式转换类工具
 */
class ZFormatConversionTool {
    /**
     * 字符串转32位hex
     */
    static stringTohex32(val: string) {
        let currentVal = val.replace(/[^a-zA-Z0-9]/g, '')
        if (currentVal.length > 32) {
            currentVal = currentVal.substring(0, 32)
        } else {
            let length = currentVal.length
            let addStr = ""
            for (let i = 0; i < 32 - length; i++) {
                addStr = `${addStr}0`
            }
            currentVal = `${currentVal}${addStr}`
        }
        let formatStr = ""
        for (let i = 0; i < 16; i++) {
            let bar = currentVal.substring(i * 2, i * 2 + 2)
            formatStr = `${formatStr}${bar} `
        }
        return formatStr
    }
    /**
     * 去掉字符串中的所有空格
     */
    static deleteStringBlank(val: string) {
        return val.replace(/\s+/g, "")
    }
    /**
     * 限定整数格式及长度
     */
    static limitIntegerAndLength(val: number, length: number) {
        // /^d+$/
        let str = val + ""
        let currentStr = str.replace(/[^[1-9]\d*$]/, "")
        console.log('currentStr: ', currentStr);
    }

    /**
     * bcrypt 加密 验证
     * @param plaintext：明文
     * @param saltLength：salt 复杂度
     * @return 密文
     */
    static bcryptEncrypt(plaintext: string, saltLength = 10) {
        const value = plaintext + '';
        const salt = bcrypt.genSaltSync(saltLength);
        return bcrypt.hashSync(value, salt); // 对明文加密
    }

    /**
     * 验证密文是否正确
     * @param ciphertext 密文
     * @param plaintext：明文
     * @param saltLength：salt 复杂度
     * @return boolean：true 表示正确一致
     */
    static bcryptDecrypt(ciphertext: string, plaintext: string, saltLength = 10) {
        return bcrypt.compareSync(plaintext, ciphertext);
    }

    /**
     * md5 加密 验证
     * count: md5 嵌套次数
     * @return 密文
     */
    static md5Encrypt(plaintext: any, count: number = 1) {
        let result: string = plaintext + '';
        for (let i = 0; i < count; i++) {
            result = md5(result);
        }
        return result;
    }

    /**
     * 输入B/KB，自动根据大小转换为 B/KB/MB/GB
     * */
    static converByteUnit(nativeSize: number, type: string = 'B') {
        if (!nativeSize || typeof nativeSize !== 'number' || nativeSize < 0) return '--';
        let typeMap: any = {
            'B': 1,
            'KB': 1024,
            'MB': 1024 * 1024,
            'GB': 1024 * 1024 * 1024
        }
        nativeSize = nativeSize * typeMap[type] || 0;
        let size = "";
        if( nativeSize < 1024 ){
            // 如果小于0.1KB转化成B
            size = nativeSize.toFixed(2) + " B";
        } else if(nativeSize < 1024 * 1024 ){
            // 如果小于0.1MB转化成KB
            size = (nativeSize / 1024).toFixed(2) + " KB";
        } else if(nativeSize <  1024 * 1024 * 1024){
            // 如果小于0.1GB转化成MB
            size = (nativeSize / (1024 * 1024)).toFixed(2) + " MB";
        }else{
            // 其他转化成GB
            size = (nativeSize / (1024 * 1024 * 1024)).toFixed(2) + " GB";
        }
        return size;
    }


    /**
     *  [ {} , {} ... ]
     *  在数组中，按对象属性 的首字母大小进行排序，默认是 升序
     *  type: up 升序，down： 降序
     * */
    static compare(property: string, type: string = 'up'){
        return function(a: any, b: any){
            const value1 = a[property].toUpperCase();
            const value2 = b[property].toUpperCase();

            let t = type === 'up' ? 1 : -1;

            if (value1 < value2) {
                return t * -1;
            }
            if (value1 > value2) {
                return t;
            }
            return 0;
        }
    }

}
export default ZFormatConversionTool