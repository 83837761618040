/**
 * lib 库
 */
export default {
  ZCommon: {
    desc: '描述',
    upgrade: '升级',
    delete: '删除',
    inputContent: '请输入内容',
    standard: '标准类型',
    custom: '自定义',
    pleaseEnter: '请输入',
    pleaseSelect: '请选择',
    noData: '暂无数据',
    yes: '是',
    no: '否',
    fomat: '数据格式',
    csv: 'csv默认格式',
    pleaseEnterInteger: '请输入整数',
    pleaseEnterPositiveInteger: '请输入正整数',
    pleaseEnterNumber: '请输入数字',
    notSet: '暂未设置',

    total: '汇总',
    all: '全部',
    number: '序号',
    time: '时间',
    devId: '设备ID',
    devName: '设备名称',
    devType: '设备类型',
    uploadTime: '上传时间',
    dataTime: '数据时间',
    fresh: '刷新',
    status: '状态',
    offline: '离线',
    online: '在线',
    operate: '操作',
    tips: {
      tip: '提示',
      username: '请输入用户名',
      password: '请输入密码',
      confirmPassword: '请再次输入密码',
      email: '请输入邮箱地址',
      pwdTitle: '用户密码',
      pwdNotNull: '请输入用户密码',
      pwdTip: '请输入8-16位非中文的密码',
      noDataToSave: '暂无数据需要保存',
      pwdError: '用户密码错误',
      devSelect: '请选择设备',
      searchContent: '请输入搜索内容',
      selectDevType: '请选择设备类型',
      fail: '失败',
      cancelOperate: '是否取消操作',
      toUserCenterBuy: '已达上限，请先到用户中心升级后再操作。',
      buy: '购买'
    },
    info: {
      mobile: '手机号码',
      smsCode: '验证码',
      username: '用户名/ 带区号手机号',
      userPwd: '用户密码',
      password: '密码',
      confirmPassword: '确认密码',
      email: '邮箱',
      emailCode: '邮箱验证码',
      flag: '标识'
    }
  },

  login: {
    name: '登录',
    loginSuccess: '登录成功',
    logining: '登录中..',
    experiment: '体验账号',
    pwdLogin: '密码登录',
    codeLogin: '验证码登录',
    remember: '记住密码',
    forgetPassword: '忘记密码？',

    thirdLogin: '第三方登录',
    help: '帮助文档',
    footerPhone: '联系电话：',
    footerEmail: '邮箱：',
    error: {
      '001': '用户名/区号+手机号不能为空',
      '002': '手机号码不能为空',
      '003': "请输入2-65位，只可以包含{'@'}字母数字下划线",
      '004': '请输入正确位数的手机号码，只能是数字',
      '005': '请输入8-16位非中文字符，并且不能有空格',
      '006': '密码不能为空',
      '007': '手机号码已存在',
      '008': '请输入2-32位，只可以包含字母数字下划线',
      '009': '用户名已存在',
      '010': "密码必须包含8-16位大小写字母、数字和指定特殊符号(!{'@'}#$%^&*0)，且至少包含2种不同类型的字符",
      '011': '两次输入的密码不一致',
      '012': '确认密码不能为空',
      '013': '用户名不能为空',
      '014': '请输入6位验证码',
      '015': '请输入正确格式的邮箱地址',
      '016': '邮箱已存在',
      '017': '邮箱不能为空',
      '018': '手机验证码无效或已过期，请重新获取',
      '019': '邮箱验证码无效或已过期，请重新获取',
      '020': '无效商家ID',
      '021': '请输入6位验证码',
      409: '账号冲突，请联系管理员进行处理！'
    },
    resetPassword: '重置密码',
    clickThisBack: '点击这里，返回',
    uncheckedTipsTitle: '登录提示',
    uncheckedTips:
      '尊敬的用户，您的账号正在审核中，审核期间我们会向您致电，请注意接听。如您有账号进度疑问，也可直接致电0202815699转接分机号8919进行咨询，或者联系致远电子销售/技术支持。',
    uncheckedButton: '关闭'
  },
  register: {
    fillInInformation: '填写信息',
    emailAddress: '邮箱地址',
    informationRegistration: '信息登记',
    finishRegistration: '完成注册',
    toRegist: '去注册',
    toRegistTips: '该手机号还未有账号，请先注册账号',
    toActiveTips: '该账号未激活，请先进行登录操作',
    name: '注册',
    bind: '绑定',
    bindTips: '首次使用第三方登录，您需要完善以下信息，进行云平台账号绑定',
    back: '上一步',
    next: '下一步',
    login: '立即登录',
    isExist: '已有账号？',
    role: '选择角色',
    account: '账户信息',
    email: '邮箱地址',
    finish: '提交成功',
    userType: '用户类型',
    businessId: '商家ID',
    merchant: '个人商家(设备厂家)',
    owner: '业主单位(分销商以及终端业主等)',
    ownerTip: '请输入商家ID',
    agree: '我已同意',
    protocol: '《云平台服务和隐私协议》',
    bindEmail: '绑定邮箱',
    skipEmail: '跳过，暂不绑定邮箱',
    info: {
      mobile: '手机号码',
      smsCode: '验证码',
      username: '用户名',
      userPwd: '用户密码',
      password: '密码',
      confirmPassword: '确认密码',
      email: '邮箱',
      emailCode: '邮箱验证码',
      flag: '标识'
    },
    tips: {
      phoneUnRegist: '手机号码未注册',
      userType: '用户类型必填',
      limitMerchantRegister: "该角色暂不开放，如有需要请发送邮件至zws{'@'}zlg.cn",
      businessId: '请输入商家ID',
      getCodeSuccess: '获取手机验证码成功',
      getEmailCodeSuccess: '获取邮箱验证码成功',
      confirmPassword: '确认密码',
      checkAgreement: '请阅读并勾选云平台服务和隐私协议',
      success: '注册成功',
      subSuccess: '您好！你的账号已成功注册，可立即登录进行尝试。',
      userSuccess: '您好！您的账号注册已提交，待管理员审批通过后，即可成功登录。',

      successThank:
        '非常感谢您注册云平台账号，我们将在1~3个工作日内向您致电审核，请注意接听。若您有账号进度疑问，也可直接致电0202815699转接分机号8919进行咨询，或者联系致远电子销售/技术支持。',

      backToLogin: '返回登录页'
    }
  },

  ZPersonalInformation: {
    personalInformationTabTitle: '个人信息',
    editUserInformation: '编辑用户信息',
    name: '姓名',
    uid: 'UId',
    company: '公司(院所)',
    industry: '所属行业',
    department: '部门',
    position: '职务',
    country: '国家/地区',
    address: '公司地址',
    namePlaceholder: '请输入真实姓名',
    companyPlaceholder: '请输入公司名称',
    industryPlaceholder: '请输入所属行业',

    departmentPlaceholder: '请输入部门名称',
    positionPlaceholder: '请输入职务名称',
    addressPlaceholder: '请输入公司地址',
    accountSecurityTabTitle: '账号安全',
    changePassword: '修改密码',
    changePasswordSucced: '修改密码成功',
    currentPassword: '当前密码',
    newPassword: '新密码',
    confirmPassword: '确认密码',
    passwordPlaceholder: '请输入密码',
    currentPasswordPlaceholder: '请输入当前密码',
    newPasswordPlaceholder: '请输入新密码',
    confirmPasswordPlaceholder: '请再次输入新密码',
    userAuthentication: '用户身份验证',
    userPhoneAuthentication: '验证当前手机号',
    changeMobile: '修改手机号码',
    changeMobileSucced: '更新手机号码成功',
    changeEmail: '修改电子邮箱',
    changeEmailSucced: '更新电子邮箱成功',
    newMobilePlaceholder: '请输入新的手机号码',
    accountSecurityTips: '首次成功绑定邮箱后，可获得额外2个月的 Designer使用期限。',

    username: '用户名',
    mobile: '手机号码',
    smscode: '验证码',
    getSmscode: '获取并正确填写验证码',
    password: '密码',
    nickname: '昵称',
    gender: '性别',
    signature: '个性签名',
    coLtd: '公司',
    trade: '行业',
    detailedAddress: '详细地址',
    cannotBeEmpty: '不能为空',
    sysHelp: '系统帮助',
    male: '男',
    female: '女',
    genderScope: [
      {
        value: '男',
        label: '男'
      },
      {
        value: '女',
        label: '女'
      }
    ],
    consistOfChars: '请输入2-32位，只可以包含中文字母数字、_'
  },
  ZButtonListCtrl: {
    confirm: '确定',
    cancel: '取消'
  },
  ZSelectWithInputCtrl: {
    pleaseSelect: '请选择',
    custom: '自定义'
  },
  ZActiveRenderCtrl: {
    pleaseSelect: '请选择',
    noData: '暂无数据',
    yes: '是',
    no: '否',
    pleaseEnterInteger: '请输入整数',
    pleaseEnterPositiveInteger: '请输入正整数',
    pleaseEnterNumber: '请输入数字',
    pleaseEnterTheCorrectContent: '请输入正确内容'
  },
  ZAreaMobileInputCtrl: {
    enterMobilePhone: '请输入手机号码',
    selectArea: '请选择区号'
  },
  ZCountDownCtrl: {
    getCode: '获取验证码',
    resend: '重新发送'
  },
  ZTreeCtrl: {
    input: '请输入'
  },
  ZDatePickerCtrl: {
    lasthour: '最近一小时',
    today: '当天',
    lastweek: '最近一周',
    lastMonth: '最近一个月',
    lastThreeMonths: '最近三个月',
    lastSixMonths: '最近半年',
    nearlyYear: '近一年',
    startDate: '开始日期',
    endDate: '结束日期',
    to: '至'
  },
  ZStep: {
    done: '已完成',
    wait: '请稍等',
    total: '共'
  },
  ZRecall: {
    sureDelete: '确定要删除吗',
    sureClose: '是否关闭？',
    fileBack: '文件召回',
    recallProgress: '召回进度',
    orderSend: '命令发送',
    orderSending: '命令发送中',
    fileFetch: '文件获取',
    fileFetching: '文件获取中',
    document: '文件名',
    size: '大小',
    receivedSize: '已接收文件大小',
    fileSize: '文件总大小'
  },
  ZFirmware: {
    firmwareVersion: '固件版本',
    firmwareType: '固件类型',
    deleteFirmware: '确定删除固件',
    deleteFirwareSuccess: '删除固件成功',
    deleteFirwareFail: '删除固件失败',
    editSuccess: '成功修改状态',

    selfFirmware: '自身固件',
    outerFirmware: '外接固件',
    issueUpdateSuccess: '通知升级成功，请等待设备自动升级',
    addScheduUpdateSuccess: '新增定时通知固件升级成功',
    addScheduUpdateFail: '新增定时通知固件升级失败',

    firmwareUpgrade: '固件升级',

    upgradeVersion: '升级版本',
    upgradeScope: '升级范围',
    upgradeTarget: '升级对象',
    upgradeTargetNotNull: '升级对象不能为空',
    upgradeExtraInfo: '附加信息',
    upgradeWay: '升级方式',

    upgradeImmediate: '立即升级',
    upgradeScheduled: '定时升级',
    upgradeTime: '升级时间',
    upgradeTimeNotNull: '升级时间不能为空',
    upgradeDiff: '差分升级',
    softGroup: '软件分组',
    singleDevice: '单个设备',
    typeGroup: '类型分组',
    gateway: '网关',
    gatewayGroup: '网关分组',

    addFirmwares: '添加固件',
    uploadFirmware: '上传固件',
    pleaseInputVersion: '请输入固件版本',
    tipsForVersionFormat: '请输入xx.xx.xx （xx为1至2位数字）',
    descNotNull: '描述不能为空',

    description: '描述',
    descriptionInput: '请输入固件描述信息',
    chooseFile: '请选择文件',
    firmwareFileNotNull: '固件文件不能为空',
    firmwareVersionNotNull: '固件版本不能为空'
  },
  ZFileSelectorCtrl: {
    browser: '浏览',
    upload: '上传',

    chooseFile: '请选择文件',
    formatAfter: '格式',
    onlySupport: '格式错误，仅支持',
    fileSizeSmaller: '上传的文件大小不能超过'
  },
  ZFileUploaderCtrl: {
    fileNotEmpty: '文件不能为空',
    uploadFileSuccess: '上传文件成功',
    uploadFailError: '上传文件失败，错误',
    deleteFileSuccess: '删除文件成功',
    deleteFailError: '删除文件失败，错误'
  },
  ZBaiduMapCtrl: {
    searchAddrPlaceholder: '请输入想要检索的地址'
  },
  ZCheckTablePaginationCtrl: {
    showAll: '显示全部',
    showSelected: '显示选中'
  },
  ZTablePaginationCtrl: {
    over10thou: '数据已超过一万条，请精确查询条件',
    saveSuccessfully: '保存成功',
    saveMax: '最多保存30个选项点',
    search: '搜索',
    save: '保存',
    noData: '暂无数据'
  },
  ValidableInput: {
    enterCorrectHexData: '请输入正确的16进制数据',
    consistOfCharsNum: '请输入字母和数字',
    consistOfChars: '请输入字母、数字或下划线',

    pleaseEnterNumber: '请输入数字',
    minValue: '最小整数为',
    maxValue: '最大整数为',
    pleaseInput: '请输入',
    countChars: '个以内字符',
    pleaseInputVersion: '请输入固件版本'
  },
  ZDevInfoLabelsArea: {
    selectLabel: '请选择租户标签',
    inputLabel: '请输入设备标签'
  },
  CommonValidTipOrText: {
    phoneEmptyTip: '手机号码不能为空',
    usernameEmptyTip: '用户名或区号加手机号不能为空',
    addZoneForPhone: '请在手机号码前加区号，如86187xxxxxxxx',
    usernameValidTip: "请输入2-65位，只可以包含{'@'}字母数字下划线",
    phoneValidTip: '请输入正确位数的手机号码，只能是数字',
    phoneValiErr: '手机号码错误，请重新输入',
    pwdValidTip: '请输入8-16位，并且不能有空格和中文',
    pwdEmptyTip: '密码不能为空',
    smsLogin: '短信登录',
    pwdLogin: '密码登录',
    newPwdValidTip: '请输入8-16位，并且不能有空格和中文',
    pwdNotSameTip: '两次输入的密码不一致',
    confirmPwdEmptyTip: '确认密码不能为空',
    getPhoneCodeTip: '获取手机验证码成功',
    sixNumValidTip: '请输入6位验证码',
    resetPwdTip: '重置密码成功',
    forgotPwd: '忘记密码',
    phone: '手机号码',
    verificationCode: '验证码',
    password: '密码',
    confirmPwd: '确认密码',
    inputNewPwd: '请输入新密码',
    inputNewPwdAg: '请再次输入新密码',
    resetPwd: '重置密码',
    clickHereToReturn: '点击这里，返回',
    login: '登录',
    username: '用户名',
    inputUsernameTip: '请输入用户名',
    inputPwdTip: '请输入密码',
    rememberPwd: '记住密码',
    noAccountTip: '没有账号？马上注册',
    logining: '登陆中...',
    register: '注册',
    help: '帮助',
    thirdAaccountLogin: '第三方账号登录'
  },
  ZDataTransformDialogArea: {
    cancelDownload: '是否取消下载',
    statusIni: '初始化',

    statusQuery: '查询中',

    statusProcess: '数据整理中',

    statusGenerate: '生成中',

    statusDone: '完成'
  },
  ZHeaderArea: {
    lowCode: 'IoT低代码开发平台',
    monitor: '监控大屏',
    help: '帮助',
    userCenter: '用户中心',
    sysSetting: '系统设置',
    logout: '退出登录'
  },
  ZUserCenterArea: {
    capacityInfo: '容量信息',
    personalInfo: '个人信息',
    accountSecurity: '账号安全'
  },
  ZDiskManagementArea: {
    beenUsed: '已使用',
    upperLimit: '上限',
    showDetails: '显示详情',
    canDevNum: 'can设备数',
    iesDevNum: 'IES设备',
    iotDevNum: 'IoT设备数',
    dataStorageSpace: '数据存储空间',
    limitNumDown: '下载限制条数',
    storageCycle: '存储周期',
    maxRecallFileSize: '最大召回文件大小',
    maxBatchRecallSize: '最大批量召回文件数',
    quickDelDevNum: '快速删除数据设备数量',
    dataStorageService: '数据存储服务',
    months: '个月',
    tenThousand: '万条',
    days: '天',
    ge: '个',
    tiao: '条',
    fileDownloadProgress: '显示文件下载进度',
    attention: '注意事项',
    deleteDataTo: '数据删除为',
    irreversible: '不可逆',
    passwordEntered: '操作，一旦输入用户密码确认后，不能终止，即使取消了，数据也将会继续清除，请谨慎操作！',
    loading: '正在下载',
    from: '从',
    createTask: '时间段的数据，请等待该下载进程完成后再创建新的下载任务。',
    tip: '提示',
    loopDelete: '空间满循环删除',
    dataStorage: '数据存储服务',
    general: '常规存储',
    only: '用户专属存储',
    deviceFastDelete: '设备快删存储',
    fileName: '文件名称',
    storageStartTime: '存储开始时间',
    storageEndTime: '存储结束时间',
    download: '下载',
    delete: '删除',
    deviceDeleteSuccess: '设备数据删除成功',
    deviceDeleteFail: '设备数据删除失败',
    to: '到',
    downloadFail: '下载设备数据失败',
    notSupportedFormat: '不支持该格式下载，请选择其他格式',
    close: '关闭',
    showDownload: '显示正在下载进度',
    deviceDownloadSuccess: '设备的数据文件下载成功',
    cancelDownloadSuccess: '设备的数据文件下载失败',
    switchSuccess: '切换自动删除状态失败',
    data: '的数据...',
    hide: '隐藏',
    initialize: '初始化',
    dataCollation: '数据整理中',
    finish: '完成',
    query: '查询中',
    formation: '生成中',
    waitDownload: '等待下载',
    cancelDownload: '用户取消下载',
    compressing: '压缩中',
    downloadProcess: '下载进度',
    storage: '存储',
    deviceFastDeleteValue: '设备数据以设备为单位独立数据库存储，',
    deviceFastDeleteTips:
      '适合设备数不多，但是单个设备数据量很大的场景。如某用户名下有5台 candtu设备，单个设备一天内就存储了8000万条数据。',
    deviceFastDeleteStrong: '可快速清空单个设备全部数据，以便马上释放空间。',
    generalValue: '设备数据存入统一管理的公共数据库，支持常规查询和删除操作。',
    generalTips: '适合总设备数不多，且单个设备数据量不大的用户。如某用户名下设备总数少于1000台，设备上报一条数据间隔10分钟以上。',
    onlyValue: '用户全部设备的数据，以用户为单位独立多个数据库存储，',
    onlyTips:
      '适合需要自动循环删除功能的用户，或者是设备数很多，数据量较大的大客户，可以方便的备份及删除早期的数据，如某用户名下有2万设备，每分钟1条数据。',
    onlyStrong: '当用户存储空间满时，可实现自动循环删除早期数据的功能。',
    cyclicDeletionTips: '开启循环删除数据，在数据满时，将会自动循环删除数据，并不发送数据删除告警短信。',
    showTipsTitle: '三种数据存储方式说明',
    featureIntroduction: '特点介绍：',
    applicableScene: '适用场景：',
    selected: '已选设备',
    selectDevice: '选择设备',
    iotTip: '注：仅包含普通IoT设备，不包含CAN设备、储能设备'
  },
  ZUserInfoArea: {
    lessThan2M: '图片不能大于2M',
    updateUserSuccess: '更新用户信息成功',
    updateAvatarS: '更新头像成功'
  },
  ZCapacitystatisticsTableArea: {
    notQuickDel: '该设备不支持快速删除功能，请前往',
    userCenter: '用户中心',
    updatePlan: '进行套餐升级',
    attention: '注意事项：',
    emptyHistory: '此操作将会清空该设备的历史存储数据，清空为',
    irreversibleOp: '不可逆',
    cantCancel: '操作，一旦输入用户密码确认后，不能终止，即使取消了，数据也将会继续清除，请谨慎操作！',
    emptyFail: '清空设备数据失败',
    order: '序号',
    devName: '设备名称',
    devType: '设备类型',
    devId: '设备ID',
    usedCapacity: '已用容量',
    updateTime: '更新时间',
    getDevFail: '获取设备列表失败'
  },
  ZComboFormArea: {
    devClass: '接入设备类别',
    canDev: 'CAN设备',
    iotDev: 'IoT设备(非CAN)',
    currentPackage: '当前套餐',
    upgradeTo: '升级至'
  },
  ZAsideArea: {
    showAside: '显示侧边栏',
    hideAside: '隐藏侧边栏'
  },
  ZUserMessageIconArea: {
    unread: '未读',
    allRead: '全部已读',
    allDelete: '全部删除',
    noData: '暂无数据',
    message: '消息',
    getMessageFail: '获取用户消息失败',
    sureAllDelete: '确定将全部消息删除？',
    sureAllRead: '确定将全部消息标记为已读？',
    close: '关闭'
  }
}
