export default {
  mounted(el: HTMLElement, binding: any) {
    el.addEventListener('click', () => {
      el.blur()
    })
  },
  unmounted(el: HTMLElement, binding: any) {
    el.removeEventListener('click', () => {
      el.blur()
    })
  }
}
