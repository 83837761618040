import ZWSBaseModule from '../httpBase/ZWSBaseModule'
class ZWSNotification extends ZWSBaseModule {

  /*******************************************************  Mail  ***********************************************************/
  /*******************************************************  RuleAction  *****************************************************/
  /*******************************************************  SMS  ************************************************************/
  /**
   * 短信发送记录列表
   */
  static sms_getBatchSms({ tenant_id, params }: {
    tenant_id: number, params: {
      current_page?: number,
      end_date: number,
      page_size?: number,
      start_date: number,
      template_code?: string
    }
  }) {
    return this.fetch({
      url: `/notification/tenants/${tenant_id}/sms`,
      method: 'get',
      params
    })
  }
  /*******************************************************  pushLogs  ******************************************************/

  /**
   * 查询日志
   * @param param0 
   * @returns 
   */
  static pushLogs_getLogs({ action_id, info_mode_id, start_date, end_date, rule_id, status, tenant_id, third_things_id, current_page = 1, page_size = 10 }: { action_id: number, info_mode_id: number, log_date: string, rule_id: number, status: number, tenant_id: number, third_things_id: string, current_page: number, page_size: number, start_date: number, end_date: number }) {
    return this.fetch({
      url: `/notification/tenants/${tenant_id}/push-log/message`,
      method: 'get',
      params: { action_id, info_mode_id, rule_id, status, third_things_id, current_page, page_size, start_date, end_date }
    })
  }

  static pushLogs_deleteLogs({ action_id, info_mode_id, start_date, end_date, rule_id, status, tenant_id, third_things_id, current_page = 1, page_size = 10 }: { action_id: number, info_mode_id: number, log_date: string, rule_id: number, status: number, tenant_id: number, third_things_id: string, current_page: number, page_size: number, start_date: number, end_date: number }) {
    return this.fetch({
      url: `/notification/tenants/${tenant_id}/push-log/message`,
      method: 'delete',
      params: { action_id, info_mode_id, rule_id, status, third_things_id, current_page, page_size, start_date, end_date }
    })
  }
}

export default ZWSNotification
