<template>
    <div class="z-third-login">
        <div class="z-third-login-label">{{t('zws.login.thirdLogin')}}</div>
        <div class="z-third-login-list">
            <div class="z-third-login-item" v-for="item in loginUrlArr">
                <svg class="z-third-login-icon iconfont" aria-hidden="true" @click="toThirdLogin(item)">
                    <use :xlink:href="`#zlg-icon-${item.type}`"></use>
                </svg>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { useLocale } from '../../hooks/useLocale'
import { getBatchThirdSupport } from '@zws/axios-fetch'

interface ThirdItem {
    type: string
    thrid_uri: string
}
let { t } = useLocale()
const loginUrlArr = reactive<Array<ThirdItem>>([])
getThirdSupport()
function getThirdSupport() {
    let url = window.location.href
    if (url.indexOf('?') === -1) {
        url += '?sysType=0'
    } else {
        url += '&sysType=0'
    }
    getBatchThirdSupport({
        backPage: url,
    }).then((res: Array<ThirdItem>) => {
        loginUrlArr.length = 0
        res?.forEach((item: ThirdItem) => {
            if (item.type === 'weixin') {
                loginUrlArr.push(item)
            }
        })
        // loginUrlArr.push(...res)
    })
}
function toThirdLogin(item: ThirdItem) {
    window.location.href = item.thrid_uri
}
</script>

<style lang="less" scoped>
.z-third-login {
    width: 304px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    // margin-top: 20px;
}

.z-third-login-list {
    display: flex;
    align-items: center;
    height: 30px;
}

.z-third-login-label {
    /* flex: 0 0 70px; */
}

.z-third-login-icon {
    width: 24px;
    height: 24px;
    margin-left: 16px;
    cursor: pointer;
}
</style>