import * as xlsx from 'excel-tool'

/**
 * 日期相关的工具类
 */
class ZFileTool {
    /**
     * 
     * @param res 从服务器返回的数据，需携带headers等等信息
     * @param fileType 文件类型，比如csv、xlsx等等
     */
    static downloadFile(res: any, fileType: string) {
        let acceptType = ''
        switch (fileType) {
            case 'xlxs':
                acceptType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                break;
            default:
                break;
        }
        let a = window.document.createElement('a')
        let blob = new Blob([res.data], { type: acceptType })
        let patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
        let contentDisposition = decodeURI(res.headers['content-disposition'])
        let content: any = patt.exec(contentDisposition)
        let fileName = content[1]
        fileName = fileName.replace(/\"/g, '')
        a.href = URL.createObjectURL(blob)
        a.setAttribute('download', fileName)
        window.document.body.appendChild(a)
        a.click()
        window.document.body.removeChild(a)
    }

    // 读取Execl表格内容并以Json格式返回读取的内容
    static readExeclFile(file: any) {
        let fileReader = new FileReader();

        let content: any = {}

        return new Promise((resolve, reject) => {
            fileReader.onload = function (ev) {
                if (!ev.target) { resolve(content); return }

                try {
                    let data = ev.target.result
                    let workbook = xlsx.read(data, {
                        type: 'binary'
                    })

                    // 遍历每张sheet表并读取其内容
                    for (let sheet in workbook.Sheets) {
                        if (workbook.Sheets.hasOwnProperty(sheet)) {
                            content[sheet] = xlsx.utils.sheet_to_json(workbook.Sheets[sheet], { raw: true });
                        }
                    }

                    resolve(content)

                } catch (e) {
                    reject(e)
                }
            }

            // 以二进制方式打开文件
            fileReader.readAsBinaryString(file);
        })
    }

    // 传入 url 过 iframe 下载
    static downByIframe({ url }: {
        url: string,
    }) {
        // 获得 id 为 downLoadListFrame 的 frame
        let divFrame: any = document.getElementById("downLoadListFrame");
        // 判断是否存在，如果存在先移除，再重新创建
        if (divFrame != null) {
            document.body.removeChild(divFrame)
        }
        // 重新创建
        const iframe = document.createElement("iframe");
        iframe.setAttribute("id", "downLoadListFrame");
        // download_file.id = "downFrame";
        document.body.appendChild(iframe);

        divFrame = document.getElementById("downLoadListFrame");
        divFrame.src = url;
        divFrame.style.display = "none";
    }

    // 通过流的形式下载文件
    static downFileFormBlobUrl({ url, fileName, cb }: {
        url: string,
        fileName: string,
        cb?: any
    }) {
        let xhr = new XMLHttpRequest();
        xhr.open('get', url, true);
        // xhr.setRequestHeader('Content-Type', `application/octet-stream`);
        xhr.responseType = "blob";
        xhr.onload = function () {
            if (this.status == 200) {
                // 接受二进制文件流
                const blob = this.response;
                const blobUrl = window.URL.createObjectURL(blob);
                // 这里的文件名根据实际情况从响应头或者url里获取
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = `${fileName}`;
                a.click();
                window.URL.revokeObjectURL(blobUrl);
                cb && typeof cb === 'function' && cb();
            }
        };
        xhr.send();
    }

    // 通过 url 新窗口形式下载文件，新窗口会在打开后快速自动关闭
    static downFileFormUrl({ url, fileName, cb }: {
        url: string,
        fileName: string,
        cb?: any
    }) {
        const a = document.createElement('a');
        a.rel = "nofollow noreferrer";
        a.target = "_blank";
        a.download = `${fileName}`;
        a.href = url;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        cb && typeof cb === 'function' && cb();
    }

    // 从Url导出数据
    static exportDataFromUrl(url: string) {
        let a = window.document.createElement('a')
        a.href = url
        window.document.body.appendChild(a)
        a.click()
        window.document.body.removeChild(a)
    }

    // 导出数据
    static exportData(data: any, filename: string) {
        let a = window.document.createElement('a')
        let blob = new Blob([data])
        a.href = URL.createObjectURL(blob)
        a.setAttribute('download', filename)
        window.document.body.appendChild(a)
        a.click()
        window.document.body.removeChild(a)
    }

    // 将数组以excel的格式导出
    static exportArrayDataInExcel({ arrayData, filename }: { arrayData: any[], filename: string }) {
        let wb = xlsx.utils.book_new();
        let ws = xlsx.utils.json_to_sheet(arrayData)

        xlsx.utils.book_append_sheet(wb, ws, "Sheet0");
        xlsx.writeFile(wb, filename)
    }
}

export default ZFileTool